const PhoneNumberConfig = [
    {
        "isdCode": "+62",
        "isdCodeWithoutSign": "62",
        "startsFrom": 8,
        "minLength": 9,
        "maxLength": 12
    },
    {
        "isdCode": "+63",
        "isdCodeWithoutSign": "63",
        "startsFrom": 9,
        "minLength": 9,
        "maxLength": 12
    }
];

export default PhoneNumberConfig;