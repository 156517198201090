export const API_URL = {
    // GETWAY_API: "http://localhost:7010/",
    // INVENTORY_API: "http://localhost:4021/",
    // DEALER_API: "http://localhost:4022/",
    //GETWAY_API: process.env.REACT_APP_GETWAY_API
    // GETWAY_API: "https://int-gcloud.gaadi.com/"
    // GETWAY_API: "http://192.168.72.36:8626/"
    // GETWAY_API: "http://localhost:8626/"
}








