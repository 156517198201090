import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
//import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// import DateFormat from 'dateformat';
import CityService from '../../../../service/cityService';
import _  from 'lodash';

class SearchResult extends Component {
    cityListData=[]
    constructor(props) {
        super(props);
        this.state = {
            cityListData: props.cityListData,
            loading: false,
            pageloading: false,
            statusClassname: {
                'active': 'nav-item active',
                'inactive': 'nav-item',
                'incomplete': 'nav-item',
                'blacklist': 'nav-item',
                'all': 'nav-item'
            },
            filterData: {
            },
            usersRecord:[],
            currentIndex: null,
            current_org_name: null
        };
    }

    componentDidMount = async () => {
        await this.getUserList();
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         cityListData: nextProps.cityListData,
    //         loading: nextProps.loading,
    //         pageloading: nextProps.pageloading
    //     });
    // }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};

        if(nextProps.cityListData.length){
            updatedStateData['cityListData'] = nextProps.cityListData;
        }

        if(nextProps.loading !== prevState.loading){
            updatedStateData['loading'] = nextProps.loading;
        }

        if(nextProps.pageloading !== prevState.pageloading){
            updatedStateData['pageloading'] = nextProps.pageloading;
        }

        return updatedStateData;

    }

    updateStatus(userData){
        if (typeof this.props.onUpdateStatus === 'function') {
            this.props.onUpdateStatus(userData);
        }

    }

    getUserName = (data,keyToMatch,keyToReturn) => {
        const user = _.find(this.state.usersRecord,{'id':data[keyToMatch]});
        if(user)
            return user[keyToReturn]
        else
            return null;
    }

    getUserList() {
        var thisObj = this;
        CityService.getAllUserList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                    thisObj.setState({ usersRecord: response.data.data });
                } else {
                    thisObj.setState({ usersRecord: []});
                }
            }
        })
        .catch(function (response) {
            
        });
    }
    
    render() {
        return (
            <div className="table-responsive">
                    <table className="table table-bordered table-hover table-striped table-category">
                        <thead>
                            <tr>
                                <th>{this.props.t('List.details.cityId') }</th>
                                <th>{this.props.t('List.details.cityName') }</th>
                                {/* <th>{this.props.t('List.details.createdDate') }</th>
                                <th>{this.props.t('List.details.createdBy') }</th>
                                <th>{this.props.t('List.details.updatedDate') }</th>
                                <th>{this.props.t('List.details.updatedBy') }</th>
                                <th>{this.props.t('List.details.status') }</th>
                                <th>{this.props.t('List.details.action') }</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {(!this.state.cityListData.length && !this.state.loading) ? <tr><td align="center" colSpan="8"><h6 className="text-center text-danger text-bold">{this.props.t('List.SearchCity.NoRecordFound')}</h6></td></tr> : null}
                            {(this.state.cityListData && this.state.cityListData.length !== 0) && this.state.cityListData.map((citydata, k) => {
                            return (<tr key={k}>
                                    <td>{citydata.id}</td>
                                    <td>{citydata.name}</td>
                                </tr>)
                            }
                            )}
                            {
                                (this.state.loading) ? <tr><td className="loading" colSpan="8"></td></tr> : null
                            }
                            
                        </tbody>
                    </table>
                </div>
                
            
        )
    }
}


 export default withTranslation('city') (SearchResult);
