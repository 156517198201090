export const InventoryConstants = {
    REQUEST_CAR_MAKE_SUCCESS: 'REQUEST_CAR_MAKE_SUCCESS',
    REQUEST_CAR_MAKE_FAILURE: 'REQUEST_CAR_MAKE_FAILURE',

    REQUEST_CAR_MODEL_SUCCESS: 'REQUEST_CAR_MODEL_SUCCESS',
    REQUEST_CAR_MODEL_FAILURE: 'REQUEST_CAR_MODEL_FAILURE',

    CITY_LIST_SUCCESS: 'CITY_LIST_SUCCESS',
    CITY_LIST_FAILURE: 'CITY_LIST_FAILURE',

    FILTER_SUBMIT_SUCCESS: 'FILTER_SUBMIT_SUCCESS',
    FILTER_SUBMIT_FAILURE: 'FILTER_SUBMIT_FAILURE',

    FILTER_LIST_SUCCESS: 'FILTER_LIST_SUCCESS',
    FILTER_LIST_FAILURE: 'FILTER_LIST_FAILURE'
};