import React from 'react';
import { Link } from 'react-router-dom';
// import logo_inspection from '../../webroot/images/oto-logo-2x.png';

class Brandlogo extends React.Component {
    
    render() {
    return (
        <Link className="navbar-brand" to="/"><img src={this.props.siteLogo} alt="" /></Link>
               
        );
    }
}
export default Brandlogo;