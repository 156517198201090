import MasterService from './MasterService';

const ReportService = {
    ...MasterService,
    
    getClusterList(postdata){
        return this.post('/cluster/listCluster',postdata) 
    },
    getSourceList(postdata){
        return this.post('/source/listSource',postdata) 
    },
    getSubSourceList(postdata){
        return this.post('/source/listSubSource',postdata) 
    },
    getSalesFunnelReport(postdata){
        return this.post('/report/getSalesFunnelReport',postdata) 
    },
    getLeadSnapshotReport(postdata){
        return this.post('/report/getLeadSnapshotReport',postdata) 
    },
    getLocationWiseLeadReport(postdata){
        return this.post('/report/getLocationWiseLeadCountReport',postdata) 
    },
    getLocationList(postdata){
        return this.post('/city/getLocationList',postdata) 
    },
    getL1CallingReport(postdata){
        return this.post('/report/getL1CallingReport',postdata) 
    }

}

export default ReportService;
