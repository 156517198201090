import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as helper from '../../../../config/helper';
import { default as config } from './../../../../config/config';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';

class SearchLocationWiseLead extends Component {
    constructor(props) {
        super(props);
        this.multiselectRef={
            'location':React.createRef(),
            'city':React.createRef(),
            'cluster':React.createRef()
        }
        this.state = {
        selectedOption: null,
        date: new Date(),
        advanceSearchEnable: false,
        formData: {
            
        },
        filterData: props.prefilledData,
        citieslist:[],
        clusterlist:[],
        locationList:[]
      };
    }
    ToogleAdvanceSearch = () => {
        let advanceSearchEnable = this.state.advanceSearchEnable;
        if (advanceSearchEnable) {
          this.setState({ advanceSearchEnable: false });
        } else {
          this.setState({ advanceSearchEnable: true });
        }
      };

    handleDateChange = (selectedKey,date) =>{
        let formData = {...this.state.formData};
        formData[selectedKey] = date;
        this.setState({ formData:formData });
    }
    handleChange = (key, event) => {
        let formData = {...this.state.formData};
        formData[key] = event.value
        this.setState({ formData: formData });      
        
      };
      searchFilter = async() => {
        //let formValid = await this.validateSearchInput();
        if(typeof this.props.getFilterList === 'function') {
            this.props.getFilterList(this.state.formData)
        }        
    }
    static getDerivedStateFromProps(nextprops, prevState) {
        var updatedObj = {}
        if (prevState.citieslist !== nextprops.prefilledData.cities) {
            updatedObj['citieslist'] = helper.getDataInKeyValueFormat(nextprops.prefilledData.cities, 'city');
        }
        if (prevState.clusterlist !== nextprops.prefilledData.cluster) {
            updatedObj['clusterlist'] = helper.getDataInKeyValueFormat(nextprops.prefilledData.cluster, 'cluster');
        }
        if (prevState.locationList !== nextprops.prefilledData.locationList) {
            updatedObj['locationList'] = helper.getDataInKeyValueFormat(nextprops.prefilledData.locationList, 'source');
        }
        return updatedObj;
    }

    onSelect(key,selectedList, selectedItem) {
        let formData = this.state.formData;
        let existingVal = (formData[key]) ? ((typeof formData[key] === 'string') ? formData[key].split(',') : formData[key]) : []
        existingVal.push(selectedItem.value);
        formData[key] = existingVal;
        this.setState({ formData: formData });
        
        
    }
     
    onRemove(key,selectedList, removedItem) {
        let formData = this.state.formData;
        let existingVal = (formData[key]) ? formData[key] : [];
        existingVal = _.filter(existingVal, function(currentVal) {
            return currentVal !== removedItem.value;
        });
        formData[key] = existingVal;
        this.setState({ formData: formData });
        
    }

    searchReport = ()=>{
        let formData = this.state.formData;
        if(typeof this.props.getReportData === 'function'){
            this.props.getReportData(formData)
        }
        
    }

    reset = async() => {
        let formData = {
        }
        this.multiselectRef.city.current.resetSelectedValues();
        this.multiselectRef.cluster.current.resetSelectedValues();
        this.multiselectRef.location.current.resetSelectedValues();
        await this.setState({formData:formData,date:null})
        this.searchReport();
    }

    render() {
        const {formData,citieslist,clusterlist,locationList}  = this.state;
        return (
            
            <div className="search-wrap">
            <ul className="search-flex">
            
                
                <li className="searchitems doublew form-group" >
                    <label>{this.props.t('locationWiseLead.search.cluster')}</label>
                    <Multiselect
                        id='cluster'
                        options={clusterlist}
                        onSelect={this.onSelect.bind(this,'cluster')} 
                        onRemove={this.onRemove.bind(this,'cluster')} 
                        displayValue="label" 
                        showCheckbox={false}
                        closeOnSelect={false}
                        //selectedValues={(formData && formData.selected_fuel && formData.selected_fuel.length) ? formData.selected_fuel : ''}
                        ref={this.multiselectRef.cluster}
                    />
                </li>
                <li className="searchitems doublew form-group " >
                    <label>{this.props.t('locationWiseLead.search.city')}</label>
                    <Multiselect
                        id='city'
                        options={citieslist}
                        onSelect={this.onSelect.bind(this,'city')} 
                        onRemove={this.onRemove.bind(this,'city')} 
                        displayValue="label" 
                        showCheckbox={false}
                        closeOnSelect={false}
                        //selectedValues={(formData && formData.selected_fuel && formData.selected_fuel.length) ? formData.selected_fuel : ''}
                        ref={this.multiselectRef.city}
                    />
                </li>

               <li className="searchitems">
                    <label>{this.props.t('locationWiseLead.search.location')}</label>
                    <Multiselect
                        id='location'
                        options={locationList}
                        onSelect={this.onSelect.bind(this,'location')} 
                        onRemove={this.onRemove.bind(this,'location')} 
                        displayValue="label" 
                        showCheckbox={false}
                        closeOnSelect={false}
                        //selectedValues={(formData && formData.selected_fuel && formData.selected_fuel.length) ? formData.selected_fuel : ''}
                        ref={this.multiselectRef.location}
                    />
                </li>
                <li className="searchitems doublew form-group">
                    <label>{this.props.t('locationWiseLead.search.startDate')}</label>
                    <DatePicker 
                        className="form-control"
                        onChange={this.handleDateChange.bind(this,'added_date_from')}
                        selected={(formData && formData.added_date_from) ? formData.added_date_from : this.state.date}
                        dateFormat={config.constants.dateFormatDMY}                                    
                        onKeyDown={e => e.preventDefault()}
                        placeholderText={this.props.t('locationWiseLead.from')}
                    />  
                </li>
                <li className="searchitems doublew form-group">
                    <label>{this.props.t('locationWiseLead.search.endDate')}</label>
                    <DatePicker 
                        className="form-control"
                        onChange={this.handleDateChange.bind(this,'added_date_to')}
                        selected={(formData && formData.added_date_to) ? formData.added_date_to : this.state.date}
                        dateFormat={config.constants.dateFormatDMY}                                    
                        onKeyDown={e => e.preventDefault()}
                        placeholderText={this.props.t('locationWiseLead.to')}
                
                    />
                </li>
                
                <li className="searchitems form-group">
                    <label> &nbsp;</label>
                    <div>
                        <button type="submit" className="btn btn-primary mrg-r15 undefined" onClick={this.searchReport.bind(this)}>{this.props.t('locationWiseLead.search.search')}</button>
                        <button type="reset" className="btn btn-default btn-reset" onClick={this.reset.bind(this)}>{this.props.t('locationWiseLead.search.reset')}</button>
                    </div>
                </li>
            </ul>
            
        </div>
        );
    }
}

export default withTranslation('report')(SearchLocationWiseLead);
