import React, { Component } from 'react';
import SearchL1Calling from './search-l1-calling';
import L1CallingResult from './l1-calling-result';
//import InputField from '../../../elements/InputField';
import ReportService from './../../../../service/reportService';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { LeadActions } from "../../../../store/action";
import { CSVLink } from "react-csv";
import _ from 'lodash';
import { toast } from 'react-toastify';
import * as helper from '../../../../../src/config/helper';

class L1CallingList extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            saveFilter: false,
            reportData: [],
            searchByList: [],
            page: 0,
            totalRecord: 0,
            grandTotal: 0,
            filterData: {},
            loading: false,
            pageloading: true,
            next_page: false,
            allFliterData: {
                cluster: [],
                teleCallerUser: [],
            },
            searchResult: [],
            totalCalculationObject: {},
            CsvJsonData: [],
            subStatusTotal: {},
            importingData: false
        }
        this.handleChangetext = this.handleChangetext.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this._isMounted = true;
        this.onScroll('Mounter');
        window.addEventListener('scroll', this.onScroll);
        // await this.getReportData({})
        await this.getClusterList();
        await this.getUserList();
    }   

    onScroll(event) {
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            if (this.flag) {
                let current_page = this.state.page;
                let loading = false, pageloading = false;
                if (current_page === 0) {
                    pageloading = true;
                } else {
                    loading = true;
                }
                if (this._isMounted && this.state.next_page) {
                    this.setState({ page: current_page + 1, loading: loading, pageloading: pageloading }, () => {
                        this.getReportData();
                    });
                }
            }
            this.flag = false;
        }
    }

    handleChangetext = () => {
        // let formData = { ...this.state.formData };   
    }
    ToogleSaveFilter = () => {
        this.setState({ saveFilter: event.target.value });
    };

    getClusterList = async () => {
        const { allFliterData } = this.state;
        let result = await this.props.actions.getClusterList({});
        let clusterList = []
        if (result && result.status === 200) {
            clusterList = (result && result.data && result.data.length) ? result.data : []
            allFliterData.cluster = clusterList
        }
        this.setState({ allFliterData: allFliterData });

    }

    getUserList = async () => {
        const { allFliterData } = this.state;
        this.props.actions.getUserList({ role_id: 25 }).then(async (response) => {
            if (response.status === 200) {
                let userList = (response['data'] && response['data'].length) ? await helper.getDataInKeyValueFormat(response['data'], '') : [];
                allFliterData.teleCallerUser = userList
            }
            this.setState({ allFliterData: allFliterData });
        }).catch(err => {
            if (this._isMounted) this.setState({ loading: false });
        });
    }

    getReportData = async (searchParams) => {
        var thisObj = this;

        var filterData = thisObj.state.filterData;
        filterData.page_number = (thisObj.state.page === 0) ? 1 : thisObj.state.page;
        thisObj.setState({ page: filterData.page_number, loading: true })
        ReportService.getL1CallingReport(filterData)
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({ loading: false, pageloading: false })
                if (response.data.status === 200) {
                    if (response.data.data.length) {
                        let reportData = response.data.data;
                        let pagination = (response.data && response.data.pagination) ? response.data.pagination : {}
                        let next_page = (pagination) ? pagination.next_page : false
                        let totalRecord = (pagination) ? pagination.total : 0
                        let grandTotalAttempt = (pagination) ? pagination.grand_total : 0;
                        let subStatusTotalLeads = (pagination) ? pagination.subStatusTotal : {};
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ reportData: reportData, loading: false, pageloading: false, next_page: next_page, totalRecord: totalRecord, grandTotal: grandTotalAttempt, subStatusTotal: subStatusTotalLeads }, () => {
                                thisObj.flag = true;
                            });
                        }
                        else {
                            thisObj.setState({
                                reportData: thisObj.state.reportData.concat(reportData)
                                , loading: false, pageloading: false, next_page: next_page, totalRecord: totalRecord, grandTotal: grandTotalAttempt, subStatusTotal: subStatusTotalLeads
                            }, () => {
                                thisObj.flag = true;
                            });
                        }
                        thisObj.getTotalObject();
                        thisObj.setJSONObjectForReport();
                    } else {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ reportData: [], next_page: false, totalRecord: 0 });
                        }
                    }
                }
            })
            .catch(function (response) {
                thisObj.setState({ loading: false, pageloading: false })
            });
    };

    getFilterReport = async (searchParam) => {
        await this.setState({ filterData: searchParam, page: 0 });
        this.getReportData();
    }

    getTotalObject = async () => {
        let { totalCalculationObject, subStatusTotal } = this.state;
        totalCalculationObject['sumTotalLead'] = this.state.grandTotal;
        totalCalculationObject['sumTotalContacted'] = subStatusTotal.totalContactLeads;
        totalCalculationObject['sumTotalXsell'] = subStatusTotal.totalCrossSellLeads;
        totalCalculationObject['sumTotalVerifiedLead'] = subStatusTotal.totalVerifiedLeads;
        totalCalculationObject['sumTotalWalkinSchd'] = subStatusTotal.totalWSLeads;
        totalCalculationObject['sumTotalWalkinDone'] = subStatusTotal.totalWDLeads;
        totalCalculationObject['sumTotalClose'] = subStatusTotal.totalClLeads;
        totalCalculationObject['sumTotalPurchased'] = subStatusTotal.totalPchsLeads;
        totalCalculationObject['sumTotalLostPurchased'] = subStatusTotal.totalLpLeads;

        this.setState({ totalCalculationObject });

    }

    calculateTotal = async (key, reportData) => {
        let total = 0
        let totalAllLeads = await (reportData && reportData.length && reportData).map(reportObj => {
            return total += +(reportObj[key])
        })
        let totalVal = (totalAllLeads && totalAllLeads.length) ? totalAllLeads[totalAllLeads.length - 1] : 0;
        return totalVal;
    }

    setJSONObjectForReport = () => {

        let reportList = Object.assign({}, this.state.reportData);
        let reportObj = {}, LeadsList = [];
        _.forEach(reportList, (reportData) => {
            reportObj = {}
            reportObj['Agent Name'] = (reportData.name) ? reportData.name : '';
            reportObj['Cluster'] = (reportData.cluster_name) ? reportData.cluster_name : ''
            reportObj['Total leads'] = (reportData.totalLead) ? reportData.totalLead : '';
            reportObj['Contacted Leads'] = (reportData.totalContactedLead) ? reportData.totalContactedLead : null + (reportData.totalContactedLead && reportData.totalLead) ? ' (' + ((reportData.totalContactedLead / reportData.totalLead) * 100) + '%)' : null;;
            reportObj['Xsell Leads'] = (reportData.totalXsell) ? reportData.totalXsell : null + (reportData.totalVerifiedLead && reportData.totalXsell) ? ' (' + ((reportData.totalXsell / reportData.totalVerifiedLead) * 100) + '%)' : null;
            reportObj['Verified'] = (reportData.totalVerifiedLead) ? reportData.totalVerifiedLead : null + (reportData.totalVerifiedLead && reportData.totalContactedLead) ? ' (' + ((reportData.totalVerifiedLead / reportData.totalContactedLead) * 100) + '%)' : null;
            reportObj['Walkin Scheduled'] = (reportData.totalWalkinSchd) ? reportData.totalWalkinSchd : null + (reportData.totalVerifiedLead && reportData.totalWalkinSchd) ? ' (' + ((reportData.totalWalkinSchd / reportData.totalVerifiedLead) * 100) + '%)' : null;
            reportObj['Walkin Done'] = (reportData.totalWalkinDone) ? reportData.totalWalkinDone : null + (reportData.totalWalkinSchd && reportData.totalWalkinDone) ? ' (' + ((reportData.totalWalkinDone / reportData.totalWalkinSchd) * 100) + '%)' : null;
            reportObj['Closed'] = (reportData.totalClose) ? reportData.totalClose : null + (reportData.totalClose && reportData.totalLead) ? ' (' + ((reportData.totalClose / reportData.totalLead) * 100) + '%)' : null;

            reportObj['LostPurchased'] = (reportData.totalLostPurchased) ? reportData.totalLostPurchased : null + (reportData.totalClose && reportData.totalLostPurchased) ? ' (' + ((reportData.totalLostPurchased / reportData.totalClose) * 100) + '%)' : null;

            reportObj['Purchased'] = (reportData.totalPurchased) ? reportData.totalPurchased : null + (reportData.totalWalkinDone && reportData.totalPurchased) ? ' (' + ((reportData.totalPurchased / reportData.totalWalkinDone) * 100) + '%)' : null;
            // reportObj['Location']=(reportData.totalLocVerifiedLead) ? reportData.totalLocVerifiedLead : null+(reportData.totalVerifiedLead && reportData.totalLocVerifiedLead) ? ' ('+((reportData.totalLocVerifiedLead/reportData.totalVerifiedLead)*100)+'%)' : null;
            // reportObj['Language']=(reportData.totalLangVerifiedLead) ? reportData.totalLangVerifiedLead : null+(reportData.totalVerifiedLead && reportData.totalLangVerifiedLead) ? ' ('+((reportData.totalLangVerifiedLead/reportData.totalVerifiedLead)*100)+'%)' : null;


        })
        this.setState({ CsvJsonData: LeadsList })
    }

    reportDetail = async (arg) => {        

         let postData = {...this.state.filterData, ...arg};
        
         if(this._isMounted) await this.setState({importingData: true});
        
        this.props.actions.importXls(postData).then(res=>{
            if(this._isMounted) this.setState({importingData: false});
            if(res && res.data){
                // let host = (config.API_URL.GETWAY_API).replace('ub/','');
                const link = document.createElement('a');
                link.href = `${res.data}`;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                toast.success(res.message);
            }           

        });
    }


    render() {              
        return (
            <div className="container-fluid">
                <div>
                    <div className="savefilterwrap">
                        <h1 className="mrg-rauto">{this.props.t('l1Calling.title')}</h1>

                        {(this.state.CsvJsonData && this.state.CsvJsonData.length) ?
                            <button type="button" className="btn btn-default btn-sm">
                                <CSVLink
                                    data={this.state.CsvJsonData}
                                    filename={"l1calling-report.csv"}
                                    className="btn btn-primary"
                                    target="_blank"
                                >{this.props.t('l1Calling.exportCsv')}
                                </CSVLink>
                            </button> : ''}
                    </div>
                </div>
                <SearchL1Calling prefilledData={this.state.allFliterData} getReportData={this.getFilterReport} getSubSourceList={this.getSubSourceList} loading={this.state.loading} />
                <L1CallingResult pageloading={this.state.pageloading} loading={this.state.loading} searchResult={this.state.reportData}
                    totalRecord={this.state.totalRecord} totalCalculationObject={this.state.totalCalculationObject} grandTotal={this.state.grandTotal} exportCsv={this.reportDetail} importingData={this.state.importingData} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            getClusterList: bindActionCreators(
                LeadActions.getClusterList,
                dispatch
            ),
            getUserList: bindActionCreators(
                LeadActions.getUsersByRoleId,
                dispatch
            ),
            importXls: bindActionCreators(
                LeadActions.downloadReport,
                dispatch
            )

        }
    }
}

export default withTranslation('report')(connect(mapStateToProps, mapDispatchToProps)(L1CallingList));
