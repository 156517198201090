import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import DateFormat from 'dateformat';
import ClusterService from '../../../../service/clusterService';
import _  from 'lodash';

class SearchResult extends Component {
    clusterListData=[]
    constructor(props) {
        super(props);
        this.state = {
            clusterListData: props.clusterListData,
            loading: false,
            pageloading: false,
            statusClassname: {
                'active': 'nav-item active',
                'inactive': 'nav-item',
                'incomplete': 'nav-item',
                'blacklist': 'nav-item',
                'all': 'nav-item'
            },
            filterData: {
            },
            usersRecord:[],
            cityDetails:[],
            currentIndex: null,
            current_org_name: null
        };
    }

    componentDidMount = async () => {
        await this.getUserList();
        //await this.getCityList({})
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         clusterListData: nextProps.clusterListData,
    //         loading: nextProps.loading,
    //         pageloading: nextProps.pageloading
    //     });
    // }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};

        if(nextProps.clusterListData){
            updatedStateData['clusterListData'] = nextProps.clusterListData;
        }

        if(nextProps.loading !== prevState.loading){
            updatedStateData['loading'] = nextProps.loading;
        }

        if(nextProps.pageloading !== prevState.pageloading){
            updatedStateData['pageloading'] = nextProps.pageloading;
        }

        return updatedStateData;

    }

    updateStatus(userData){
        if (typeof this.props.onUpdateStatus === 'function') {
            this.props.onUpdateStatus(userData);
        }

    }

    getUserName = (data,keyToMatch,keyToReturn) => {
        const user = _.find(this.state.usersRecord,{'id':data[keyToMatch]});
        if(user)
            return user[keyToReturn]
        else
            return null;
    }

    getUserList() {
        var thisObj = this;
        ClusterService.getAllUserList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                    thisObj.setState({ usersRecord: response.data.data });
                    
                } else {
                    thisObj.setState({ usersRecord: []});
                    
                }
            }
        })
        .catch(function (response) {
            
        });
    }

    getCityList(params) {
        var thisObj = this;
        ClusterService.getCityList(params)()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                        thisObj.setState({ cityDetails: response.data.data });
                    
                } else {
                        thisObj.setState({ cityDetails: []});
                    
                }
            }
        })
        .catch(function (response) {
            
        });
    }

    // getCityName = (data) => {
    //     const user = _.find(this.state.cityDetails,{'id':data[keyToMatch]});
    //     if(user)
    //         return user[keyToReturn]
    //     else
    //         return null;
    // }
    
    render() {
        return (
            <div className="table-responsive">
                    <table className="table table-bordered table-hover table-striped table-category list-cluster-table">
                        <thead>
                            <tr>
                                <th>{this.props.t('List.details.city') }</th>
                                <th>{this.props.t('List.details.clusterName') }</th>
                                <th>{this.props.t('List.details.updatedDate') }</th>
                                <th>{this.props.t('List.details.updatedBy') }</th>
                                <th>{this.props.t('List.details.status') }</th>
                                <th>{this.props.t('List.details.action') }</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(this.state.clusterListData.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="6"><h6 className="text-center text-danger text-bold">{this.props.t('List.Search.NoRecordFound')}</h6></td></tr> : null}
                            {(this.state.clusterListData && this.state.clusterListData.length !== 0) && this.state.clusterListData.map((clusterdata, k) =>
                            <tr key={k}>
                                <td>{clusterdata.city}</td>
                                <td>{clusterdata.name}</td>
                                <td>{(clusterdata.updated_at)? (DateFormat(clusterdata.updated_at,"dd/mm/yyyy")): ''}</td>
                                <td>{this.getUserName(clusterdata,'updated_by','username')}</td>
                                <td>
                                    <label className="switch-btn btn btn-link ml-5" htmlFor={"active"+clusterdata.id} >
                                        <input checked={(clusterdata.status === "1") ? true : false} className="switch-btn" id={"active"+clusterdata.id} value="open" name={"active"+clusterdata.id} type="checkbox"  onChange={this.updateStatus.bind(this,clusterdata)}  />
                                        <div className="slider round"></div>
                                        <span className="switch-label"></span>
                                        {(clusterdata.status === "1") ? this.props.t('List.details.active'):this.props.t('List.details.inactive')}
                                    </label>
                                </td>
                                <td>
                                    <div className="btn-group">
                                    <Link to={`/edit-cluster/${clusterdata.cluster_id_hash}`} className="btn btn-default"><i className="ic-createmode_editedit mrg-r5"></i></Link>
                                    </div>
                                </td>
                            </tr>
                            )}
                            {
                                (this.state.loading) ? <tr><td className="loading" colSpan="6"></td></tr> : null
                            }
                            
                        </tbody>
                    </table>
                </div>
                
            
        )
    }
}

export default withTranslation('cluster')(SearchResult);
