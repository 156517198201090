import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../elements/Input";
import { default as config } from '../../../config/config';
import { withTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import moment from "moment";
class ClosedPurchaseCar extends Component{
    constructor(props){
        super(props)
        this.state={
            vehicleList:[],
            selectedOptions:{},
            error:{},
            leadData : []
        }
    }
    componentDidMount=()=>{
        /////get make model list for closed lead //////
        
        let {vehicleList} = this.state;
        let {mmvList:{make=[],model=[]}={},leadData} = this.props;
        let makeModelList = make.map(function(e){
            return model.filter(f=>f.mk_id===e.id).map(function(d){
                return {label:`${e.make} ${d.m}`, value:d.id,mk_id:e.id}
            })
        });
        let tempMMVList = [];
        for(let i of makeModelList) tempMMVList.push(...i)
      
        vehicleList.push({label:"Make Model List",options:tempMMVList})       
        this.setState({vehicleList:vehicleList, leadData : leadData})
    }
    validateRegistration=(regno)=>{
        ////check validity here////
        return true;
    }
    validateDetails=(selectedOptions)=>{
        const {error}=this.state;
        if(!selectedOptions['make_model']) error['make_model'] = this.props.t('closedPurchaseCar.error.makeModel');
        if(!selectedOptions['purchased_date']) error['purchased_date'] = this.props.t('closedPurchaseCar.error.purchaseDate'); 
        if(!selectedOptions['purchased_amount']) error['purchased_amount'] = this.props.t('closedPurchaseCar.error.purchaseAmount');
        if(!selectedOptions['registration_no']) error['registration_no'] = this.props.t("closedPurchaseCar.error.registrationNo")
        if(selectedOptions['purchased_amount'] && Number.isNaN(+selectedOptions['purchased_amount'].replace(/,/g,""))) error['closedPurchaseCar.error.purchased_amount'] = this.props.t('purchaseNumeral')
        if(selectedOptions['registration_no'] && !this.validateRegistration(selectedOptions['registration_no'])) error['closedPurchaseCar.error.registration_no'] = this.props.t('registationCorrect');
        if(Object.keys(error).length) this.setState({error:error})
        else return true;
        return false;
    }
    submitPurchasedDetails=()=>{
        let {selectedOptions}=this.state;
        let {leadData} =this.state; 
        if(this.validateDetails(selectedOptions)){
            ////submit details here
            let data={
                make:selectedOptions['make_model'].hasOwnProperty('mk_id')?selectedOptions['make_model'].mk_id:'',
                model:selectedOptions['make_model'].hasOwnProperty('value')?selectedOptions['make_model'].value:'',
            }
            if(selectedOptions['purchased_date']) data['purchased_date'] = moment(selectedOptions['purchased_date']).format("YYYY-MM-DD")
            if(selectedOptions['purchased_amount']) data['purchased_amount'] = +selectedOptions['purchased_amount'].replace(/,/g,"")
            if(selectedOptions['registration_no']) data['registration_no'] = selectedOptions['registration_no']
            data['lead_id'] = leadData.id
            this.props.submitPurchaseDetails(data);
        }
    }
    handleChangeItems=(key,value)=>{
        let {selectedOptions,error} = this.state
        delete(error[key]);
        let {target:{value:actualValue} = {}} = value
        value = actualValue || actualValue===""?actualValue:value
        selectedOptions[key] = value
        this.setState({selectedOptions:selectedOptions})
    }
    render(){
        const {vehicleList,selectedOptions,error} = this.state
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="col-sm-12">
                            <h6>{this.props.t('closedPurchaseCar.label.namePurchase')}</h6>
                            <span>{this.props.t('closedPurchaseCar.label.modelContext')}</span>
                            <label>{this.props.t('closedPurchaseCar.label.purchaseCar')}</label>  
                            <Select
                                id="makeModelList"
                                options={vehicleList}
                                onChange={this.handleChangeItems.bind(this,"make_model")}
                                value={selectedOptions.make_model?selectedOptions.make_model:''}
                            />
                            {error.make_model && <div className="error-block">{error.make_model}</div>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="col-sm-4">
                            <label>{this.props.t('closedPurchaseCar.label.purchaseDate')}</label>   
                            <DatePicker
                                id="purchase_date"
                                name="purchase_date"
                                maxDate={new Date()}
                                className="form-control"
                                autocompleate="off"
                                placeholder={this.props.t('closedPurchaseCar.placeHolder.purchaseDate')}
                                onChange={this.handleChangeItems.bind(this,"purchased_date")}
                                dateFormat={config.constants.dateFormatDMY}
                                selected={selectedOptions.purchased_date?selectedOptions.purchased_date:''}
                            />
                            {error.purchased_date && <div className="error-block">{error.purchased_date}</div>}
                        </div>
                        <div className="col-sm-4">
                            <InputField
                                id="purchase_amount"
                                name="purchase_amount"
                                label={this.props.t('closedPurchaseCar.label.purchaseAmount')}
                                type="text"
                                placeholder={this.props.t('closedPurchaseCar.placeHolder.purchaseAmount')}
                                onChange={this.handleChangeItems.bind(this,"purchased_amount")}
                                error={error.purchased_amount}
                                value={selectedOptions.purchased_amount?selectedOptions.purchased_amount:''}
                            />
                            
                            {/* <span className="add-currency-symbol">$</span> */}
                        </div>
                        <div className="col-sm-4">
                            <InputField
                                id="registration_no"
                                name="registration_no"
                                label={this.props.t('closedPurchaseCar.label.registrationNo')}
                                type="text"
                                placeholder={this.props.t('closedPurchaseCar.placeHolder.registrationNo')}
                                onChange={this.handleChangeItems.bind(this,"registration_no")}
                                error={error.registration_no}
                                value={selectedOptions.registration_no?selectedOptions.registration_no:''}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <input type="button" className="btn btn-primary" value={this.props.t("closedPurchaseCar.label.submit")} onClick={this.submitPurchasedDetails}/>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation('lead')(ClosedPurchaseCar)