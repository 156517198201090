import React, { Component } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { default as config } from './../../../../config/config';
import _ from 'lodash';
import {UserService} from './../../../../service';

class SearchUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
		filterData: {
			'name':''
		}
		
	};
  }

  componentDidMount = async() => {
	 await this.getRoleDetails()
  }

handleChangeEvent = (key,event) => {
	let filterData = {...this.state.filterData};
	if(key === 'name'){
		filterData.name = event.target.value;
	}else if(key === 'status'){
		filterData.status = event ? event.value : '';
	}
	else if(key === 'role_id'){
		filterData.role_id = event ? event.value : '';
	}
	this.setState({ filterData:filterData });
	if (typeof this.props.filterNameChange === 'function') {
		this.props.filterNameChange(filterData);
	}
	
}

submitFilterForm = (event) => {
	event.preventDefault();
	if (typeof this.props.onSubmitFilter === 'function') {
		this.props.onSubmitFilter(event);
	}
}
getRoleDetails(params) {
	var thisObj = this;
	UserService.getRoleList(params)
	.then(function (response) {
		if (response.data.status === 200) {
			const roleArr = [];
			if (response.data.data.length) {
				_.forEach((response.data.data),element => {
					let rolebj={}
					rolebj.value = element.id;
					rolebj.label = element.name;
					roleArr.push(rolebj);
				});
				thisObj.setState({ roleDetails: roleArr });
			} else {
				thisObj.setState({ roleDetails: roleArr});
			}
		}
	})
	.catch(function (response) {
		thisObj.setState({loading:false,pageloading:false})
	});
}
  
  render() {
	const  {filterData,roleDetails}  = this.state;
	return (
        <div className="card-heading">
			<div className="row">
				<div className="col-sm-4 col-md-6">
					<h2 className="card-title pad-t7">{this.props.t('List.title')}</h2>
				</div>
				<div className="col-sm-3 col-md-2">
						<input
							className="form-control"
							type="text"
							id="name"
							value={filterData.name}
							name="name"
							placeholder={this.props.t('List.searchUser.searchByKey')}
							onChange={this.handleChangeEvent.bind(this,'name')}
						/>
				</div>
				<div className="col-sm-3 col-md-2">
						<Select
							id="status"
							onChange={this.handleChangeEvent.bind(this,'status')}
							options={config.constants.status}
							placeholder={this.props.t(
								"formData.status"
							)}
							isClearable
						/>
				</div>
				<div className=" col-sm-3 col-md-2">
					<Select
						id="role_id"
						options={roleDetails}
						name="role_id"
						onChange={this.handleChangeEvent.bind(this,'role_id')}
						value={roleDetails && roleDetails.filter(({ value }) => value === filterData.role_id)}
						placeholder={this.props.t(
							"formData.roleName"
						)}
						isClearable
					/>
				
				</div>
			</div>
		</div>
       
    )
  }
}

export default withTranslation('user')(SearchUser);
