import React, { Component } from "react";

import InputField from "../../elements/Input";
import CheckBox from "../../elements/Radiobox";
import DatePicker from "react-datepicker";
import Rating from "react-rating";
import { Range, getTrackBackground } from "react-range";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../../common/LanguageSelector';
import queryString from 'query-string';
import { default as config } from '../../../config/config';

import * as helper from '../../../config/helper';

class FeedBackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackForm: {
        testDriveDate: null,
        testDriveTime: 0,
        consultantBehaviour:0,
        carDetailsExplained:'',
        carFeaturesExploreIsAllowed:'',
        wantTobuyCar:'',
        overallExperience:0,
        otherTime:0
      },
      errors:{},
      loading: false,
      queryParams:{},
      STEP: 1,
      MIN: 0,
      MAX: 5
    };
  }


  componentDidMount = async () => {
    this._isMounted = true;
    let queryParams = queryString.parse(this.props.location.search);
    await this.setState({queryParams});

    //GET NOTIFICATION DETAILS
    this.getDetails();
  }


  getDetails = async () => {
        
    let details = await this.props.actions.getDetails(this.state.queryParams);
    if(details.status === 200 && details.data && +details.data.status === 200){
      this.props.history.push('/thankyou-page?msg=feedback_already_submitted');
    }else if(details.status === 200 && details.data && +details.data.status === 300){
        this.props.history.push('/error-page?msg=link_expired');
    }
  }

  handleStarClick(event) {
    this.setState({ value: undefined });
  }

  handleChange = (name, value) => async (event) => {
    let updatedValue = {...this.state.feedbackForm};

    if(name === 'testDriveTimeOption'){
      updatedValue['testDriveTimeOption']   = value.id 
      updatedValue['otherTime']             = (value.id === '4') ? 1 : 0 
      updatedValue['testDriveTime']         = value.value 
    }else if(name === 'carDetailsExplained' || name === 'carFeaturesExploreIsAllowed' || name === 'wantTobuyCar'){
      updatedValue[name]         = (name === 'wantTobuyCar') ? value.id : value.value 
    }else if(name === 'overallExperience'){
      updatedValue[name]         = event 
    }else{
      updatedValue[name] = (name === 'testDriveDate') ? await helper.dateFormat(event)/*new Date(event).toLocaleDateString()*/ : event.target.value || value; 
    }
    updatedValue[name] = (updatedValue[name]).toString();
    await this.setState({feedbackForm: updatedValue});
  };


  submitForm = async () => {
    let { feedbackForm, errors, queryParams } = this.state;
    Object.keys(feedbackForm).forEach((el,key)=>{
      delete errors[el];
      if(['testDriveDate', 'consultantBehaviour', 'wantTobuyCar', 'overallExperience'].includes(el)){
        if(!feedbackForm[el]){
          errors[el] = true
        }
      }else if(el === 'testDriveTime' && feedbackForm[el] && (feedbackForm[el] > 240 || feedbackForm[el] < 1)){
        errors[el] = true
      }
    });
    this.setState({errors});

    if(Object.keys(errors).length){
      toast.error('Please select required fields')
    }else{
      this.setState({loading:true});
      feedbackForm = {...feedbackForm, ...queryParams}
      this.props.actions.leadFeedbackSubmit(feedbackForm).then(udpateStatus=>{

        if(udpateStatus && udpateStatus.data && +udpateStatus.data.status === 100){
            this.setState({loading:false});
            this.props.history.push('/thankyou-page?msg=feedback_submitted');
        }else if(udpateStatus && udpateStatus.data && +udpateStatus.data.status === 200){
            this.setState({loading:false});
            this.props.history.push('/thankyou-page?msg=feedback_already_submitted');
        }else if(udpateStatus && udpateStatus.data && +udpateStatus.data.status === 300){
          this.setState({loading:false});
          this.props.history.push('/error-page?msg=link_expired');
        }else{
            this.setState({loading:false});
        }
      }).catch(err=>{
        this.setState({loading:false});
      });
    }
  }

  render() {
    const { feedbackForm, loading, STEP, MIN, MAX } = this.state;
    const { YES_NO, WANT_TO_BUY_CAR_OPTION, TIMINGS } = config.constants;

    return (
      <div className="container">
        <div className="card feedbackForm">
          <div className="card-heading">
            <h2 className="card-titile">Test Drive Feedback Survey</h2>
          </div>
          <div className="card-body">
            <form className="feedback-form">
              <div className="form-group">
                <label className="headLabel">Date of the test drive <span>*</span></label>
                <DatePicker
                  id="testDriveDate"
                  className="form-control"
                  dateFormat={"dd-MM-yyyy"}
                  selected={feedbackForm['testDriveDate'] ? new Date(feedbackForm['testDriveDate']) : null}
                  onChange={this.handleChange('testDriveDate')}
                  placeholderText="Select Date"
                  maxDate={new Date()}
                />
                {!loading && this.state.errors.testDriveDate && <div className="error-block">This field is required</div> }

              </div>
              <div className="form-group">
                <label className="headLabel">
                  How long was your test drive?
                </label>
                <ul>
                  {TIMINGS.map((el, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={el.label}
                        name="drivetime"
                        id={'drivetime'+el.id}
                        checked={el.id === feedbackForm['testDriveTimeOption'] ? true : false}
                        value={feedbackForm['testDriveTimeOption']}
                        onChange={ this.handleChange('testDriveTimeOption', el)}
                      />
                    </li>
                  ))}
                  {
                    feedbackForm['otherTime']
                    ?
                    <div>
                      <li className="nolabel">
                        <InputField
                          id="testDriveTime"
                          name="testDriveTime"
                          type="text"
                          label="Enter time in minutes"
                          value={feedbackForm['testDriveTime']}
                          onChange={this.handleChange('testDriveTime')}
                        />
                      </li>
                  {!loading && this.state.errors.testDriveTime && <div className="error-block">Time should be between 1 to 240 minutes</div> } 

                    </div>
                      
                  :
                    ''
                  }
                  
                </ul>
              </div>
              <div className="form-group">
                <label className="headLabel">
                  How would you rate the sales consultant's behavior? <span>*</span>
                </label>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      marginTop: "4em",
                    }}
                  >
                    <Range
                      step={STEP}
                      min={MIN}
                      max={MAX}
                      values={[feedbackForm.consultantBehaviour]}
                      onChange={(rangeValues)=> this.setState({ 'feedbackForm': {...feedbackForm, 'consultantBehaviour': ''+rangeValues[0]}} )}
                      renderTrack={({ props, children }) => (
                        <div
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          style={{
                            ...props.style,
                            height: "36px",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <div
                            ref={props.ref}
                            style={{
                              height: "5px",
                              width: "100%",
                              borderRadius: "12px",
                              background: getTrackBackground({
                                values: [feedbackForm.consultantBehaviour],
                                colors: ["#e46536", "#ccc"],
                                min: MIN,
                                max: MAX,
                              }),
                              alignSelf: "center",
                            }}
                          >
                            {children}
                          </div>
                        </div>
                      )}
                      renderThumb={({ props, isDragged }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "36px",
                            width: "36px",
                            border: "solid 2px #e46536",
                            borderRadius: "50%",
                            backgroundColor: "#FFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            outline: "none",
                            boxShadow: isDragged
                              ? "0px 0px 0px 12px rgba(228, 101, 54, 0.2)"
                              : "0px 0px 0px 1px  rgba(228, 101, 54, 0.0)",
                          }}
                        >
                          <div className="rangeoutput">
                            <output
                              style={{
                                ...props.style,
                                height: "36px",
                                width: "36px",
                                border: "solid 2px #e46536",
                                borderRadius: "8px",
                                fontSize: "16px",
                                fontWeight: "bold",
                                backgroundColor: "#e46536",
                                color: "#fff",
                                marginTop: "-62px",
                                marginLeft: "-16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                outline: "none",
                                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
                              }}
                              id="output"
                            >
                              {[feedbackForm.consultantBehaviour]}
                            </output>
                          </div>
                          <div
                            style={{
                              height: "5px",
                              width: "5px",
                              backgroundColor: isDragged
                                ? "#e46536"
                                : "#e46536",
                              boxShadow: isDragged
                                ? "0px 12px 6px rgba(228, 101, 54, 0.2)"
                                : "0px 12px 6px rgba(228, 101, 54, 0.2)",
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                {!loading && this.state.errors.consultantBehaviour && <div className="error-block">This field is required</div> }

              </div>
              <div className="form-group">
                <label className="headLabel">
                  Did they address your doubts and properly brief you about the
                  car?
                </label>
                <ul>
                  {YES_NO.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="doubts"
                        id={'carDetailsExplained'+item.id}
                        checked={item.value === feedbackForm['carDetailsExplained'] ? true : false}
                        value={feedbackForm['carDetailsExplained']}
                        onChange={this.handleChange('carDetailsExplained', item)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="form-group">
                <label className="headLabel">
                  Were you allowed to explore all the features of the car
                  comfortably?
                </label>
                <ul>
                  {YES_NO.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="carFeaturesExploreIsAllowed"
                        id={'carFeaturesExploreIsAllowed'+item.id}
                        checked={item.value === feedbackForm['carFeaturesExploreIsAllowed'] ? true : false}
                        value={feedbackForm['carFeaturesExploreIsAllowed']}
                        onChange={this.handleChange('carFeaturesExploreIsAllowed', item)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="form-group">
                <label className="headLabel">
                  Rate the car on the following grounds: <span>*</span>
                </label>
              </div> */}
              <div className="form-group ratingbox">
                
              </div>
              <div className="form-group">
                <label className="headLabel">
                  After taking the test drive, were you more likely to buy the car ? <span>*</span>
                </label>
                <ul>
                  {WANT_TO_BUY_CAR_OPTION.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="wantTobuyCar"
                        id={'wantTobuyCar'+item.id}
                        checked={item.id === feedbackForm['wantTobuyCar'] ? true : false}
                        value={feedbackForm['wantTobuyCar']}
                        onChange={this.handleChange('wantTobuyCar', item)}
                      />
                    </li>
                  ))}
                </ul>
                {!loading && this.state.errors.wantTobuyCar && <div className="error-block">This field is required</div> }

              </div>
              <div className="form-group ratingbox">
                <label className="headLabel">
                  How would you rate your test drive experience overall? <span>*</span>
                </label>
                <div className="rating10">
                  <Rating
                    stop={10}
                    initialRating={feedbackForm['overallExperience']}
                    emptySymbol={[
                      <span className="ratingPoints">1</span>,
                      <span className="ratingPoints">2</span>,
                      <span className="ratingPoints">3</span>,
                      <span className="ratingPoints">4</span>,
                      <span className="ratingPoints">5</span>,
                      <span className="ratingPoints">6</span>,
                      <span className="ratingPoints">7</span>,
                      <span className="ratingPoints">8</span>,
                      <span className="ratingPoints">9</span>,
                      <span className="ratingPoints">10</span>,
                    ]}
                    fullSymbol={[
                      <span className="ratingPoints filled">1</span>,
                      <span className="ratingPoints filled">2</span>,
                      <span className="ratingPoints filled">3</span>,
                      <span className="ratingPoints filled">4</span>,
                      <span className="ratingPoints filled">5</span>,
                      <span className="ratingPoints filled">6</span>,
                      <span className="ratingPoints filled">7</span>,
                      <span className="ratingPoints filled">8</span>,
                      <span className="ratingPoints filled">9</span>,
                      <span className="ratingPoints filled">10</span>,
                    ]}
                    onChange={this.handleChange('overallExperience')}
                  />
                </div>
                {!loading && this.state.errors.overallExperience && <div className="error-block">This field is required</div> }

              </div>
              <div className="form-group">
                {
                  loading
                  ?
                    <button type="button" name="submit" className="btn btn-primary btn-lg" disabled>
                      {this.props.t('walkin_reschedule.please_wait')}
                    </button>
                  :
                    <button type="button" name="submit" className="btn btn-primary btn-lg" onClick={this.submitForm}>
                      {this.props.t('walkin_reschedule.submit')}
                    </button>
                }
              </div>
            </form>
          </div>
        </div>

        <footer>
            <LanguageSelector />
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenExpired: state.auth.tokenExpired
  }
}

const mapDispatchToProps = dispatch => {
  return {
            actions: {
              getDetails: bindActionCreators(
                LeadActions.feedbackFormValidity,
                dispatch
              ),
              leadFeedbackSubmit: bindActionCreators(
                LeadActions.leadFeedbackSubmit,
                dispatch
              )
            }
          }
}

export default withTranslation('common') (connect(mapStateToProps, mapDispatchToProps)(FeedBackForm));
