import React, { Component } from 'react';
import Select from 'react-select';
import InputField from '../../../elements/InputField';
import SourceService from './../../../../service/sourceService';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import DateFormat from 'dateformat';
import _  from 'lodash';
import { default as config } from './../../../../config/config';
import { Redirect } from "react-router-dom";

class EditSource extends Component {
    source_id;
    constructor(props) {
        super(props);
        this.state = {
            editFormData: {},
            successResponse: false,
            redirect: false,
            errors: {},
            show: false,
            renderSection: 'category',
            loaderClass: '',
            searchParams:{},
            sourceTypeDetails:[],
            usersRecord:[],
            redirectTolist: false,
        }
        this.source_id = this.props.match.params.id;
        if(this.source_id)
            this.getSourceDetails = this.getSourceDetails.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this)
        this.getSourceTypeDetails({'status':'1'});
    }

    componentDidMount = async () => {
        if(this.source_id){
            let searchParams = this.state.searchParams;
            searchParams.id = this.source_id;
            this.setState({searchParams:searchParams})
            await this.getSourceDetails();
            await this.getUserList();
        }else
            this.setState({editFormData:{}})
    }

    getSourceDetails() {
        var thisObj = this;
        SourceService.getSourceListData(this.state.searchParams)
        .then(function (response) {
            thisObj.flag = false;
            thisObj.setState({loading:false,pageloading:false})
            if (response.data.status === 200) {
                if (response.data.data.length) {
                    thisObj.setState({ editFormData: response.data.data[0] ,loading:false,pageloading:false}, () => {
                        thisObj.flag = true;
                    });
                } else {
                    thisObj.setState({ editFormData: response.data.data});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    getSourceTypeDetails(postData) {
        var thisObj = this;
        SourceService.getSourceTypeList(postData)
        .then(function (response) {
            if (response.data.status === 200) {
                const scrTypeArr = [];
                if (response.data.data.length) {
                    _.forEach((response.data.data),element => {
                        let srcTypeObj={}
                        srcTypeObj.value = element.id;
                        srcTypeObj.label = element.name;
                        scrTypeArr.push(srcTypeObj);
                    });
                    thisObj.setState({ sourceTypeDetails: scrTypeArr });
                } else {
                    thisObj.setState({ sourceTypeDetails: scrTypeArr});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    handleOptionChange = (key,event) => {
        let editFormData = this.state.editFormData;
        let errors = this.state.errors;
        if(key && key === 'status')
            editFormData.status = (editFormData.status === '0') ? '1' : '0';
        else
            editFormData[key] = event ? event.value : '';
            errors[key] = '';
        this.setState({editFormData:editFormData})
        this.setState({errors:errors});
    };
    
    submitForm = async (event) => {
        event.preventDefault();
        if (this.validForm()) {
            this.setState({ loaderClass : 'loading' });
            let postData = await this.filterPostData();
            if(postData.id){
                SourceService.updateSourceDetails(postData)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                        this.setState({ redirectTolist: true })
                    } else if (response.status === 401 && response.data.status === 401) {
                        this.setState({ redirect: true })
                    }
                })
                .catch((error) => {
                    
                });
            }else{
                SourceService.saveSourceDetails(postData)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                        this.setState({ redirectTolist: true })
                    } else if (response.status === 401 && response.data.status === 401) {
                        this.setState({ redirect: true })
                    }
                })
                .catch((error) => {
                });
            }
        }
    }

    filterPostData = () => {
        let params={};
        if(this.state.editFormData.id)
            params.id = this.state.editFormData.id;
        if(this.state.editFormData.source_type_id)
            params.source_type_id = this.state.editFormData.source_type_id;
        if(this.state.editFormData.lang_id)
            params.lang_id = this.state.editFormData.lang_id;
        if(this.state.editFormData.name)
            params.name = this.state.editFormData.name;
        if(this.state.editFormData.description)
            params.description = this.state.editFormData.description;
        if(this.state.editFormData.status)
            params.status = this.state.editFormData.status;
        return params;
    }

    validForm = () => {
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        let formIsValid = true;
        if (!editFormData["name"]) {
            errors['name'] = this.props.t('source.validation.name_required')
            formIsValid = false;
        }
        if (!editFormData["source_type_id"]) {
            errors['source_type_id'] = this.props.t('source.validation.source_type_required')
            formIsValid = false;
        }
        if (!editFormData["lang_id"]) {
            errors['lang_id'] = this.props.t('source.validation.lang_id_required')
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleChangeEvent = event => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        editFormData[event.target.name] = event.target.value;
        if(fieldRequired === 'yes'){
            if (event.target.value !== '') {
                delete errors[event.target.name];
            } else {
                errors[event.target.name] = this.props.t('sourceType.validation.'+event.target.name)+this.props.t('sourceType.validation.is_required');
            }
        }
        this.setState({ editFormData: editFormData, errors: errors });
    }

    getUserName = (userData,keyToMatch,keyToReturn) => {
        const user = _.find(this.state.usersRecord,{'id':userData[keyToMatch]});
        if(user)
            return user[keyToReturn]
        else
            return null;
    }
    
    getUserList = () => {
        var thisObj = this;
        SourceService.getAllUserList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                        thisObj.setState({ usersRecord: response.data.data });
                    
                } else {
                        thisObj.setState({ usersRecord: []});
                    
                }
            }
        })
        .catch(function (response) {
            
        });
    }
    
    render() {
          const   {editFormData,redirectTolist,errors}   = this.state;
          if(!editFormData.hasOwnProperty('status') && !this.source_id)
            editFormData.status = "1";
          if (redirectTolist) return <Redirect to="/list-source" />;
        return (
            <div className="container-fluid">
                <h1>{(editFormData.id) ? this.props.t('source.formDetails.editSource'):this.props.t('source.formDetails.addSource') }</h1>                
                <div className="card">
                    <div className="card-body">
                        <form method="post" id="formDetails" onSubmit={this.submitForm}>
                            <div className="row">
                                <div className=" col-sm-4 form-group">
                                    <label>{this.props.t('sourceType.formDetails.sourceType')}</label><span className="required">*</span>
                                    <Select
                                        id="source_type_id"
                                        options={this.state.sourceTypeDetails}
                                        name="source_type_id"
                                        onChange={this.handleOptionChange.bind(this,'source_type_id')}
                                        value={this.state.sourceTypeDetails.filter(({ value }) => value === editFormData.source_type_id)}
                                        dataerror={errors.name || ''}
                                        
                                    /><span className="text-danger">{errors.source_type_id || ''}</span>
                                    </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "name",
                                    type: "text",
                                    name: "name",
                                    label: this.props.t('source.formDetails.name'),
                                    value: editFormData.name || '',
                                    dataerror: errors.name || '',
                                    validationreq: "yes"
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-sm-4 form-group">
                            <label>{this.props.t('source.formDetails.description')}</label>
                                    <textarea
                                        className="form-control" 
                                        type="text"
                                        name="description"
                                        id="description"
                                        value={editFormData.description || ''}
                                        onChange={this.handleChangeEvent}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-sm-4 form-group">
                                    <label>{this.props.t('source.formDetails.language')}</label>
                                    <span className="required">*</span>
                                    <Select
                                        id="lang_id"
                                        options={config.constants.language}
                                        name="lang_id"
                                        onChange={this.handleOptionChange.bind(this,'lang_id')}
                                        value={config.constants.language.filter(({ value }) => Number(value) === Number(editFormData.lang_id))}
                                        
                                    />
                                    <span className="text-danger">{errors.lang_id || ''}</span>
                                </div>
                            </div>
                            {(editFormData.id) ?  
                            <div className="row form-group" >
                                <ul className="maindetail-list">
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('source.formDetails.createdDate')}</div>
                                <div className="sub-value">{DateFormat(editFormData.created_at,"dd/mm/yyyy")}</div>
                                    </li>
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('source.formDetails.createdBy')}</div>
                                        <div className="sub-value">{this.getUserName(editFormData,'added_by','username')}</div>
                                    </li>
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('source.formDetails.updatedDate')}</div>
                                        <div className="sub-value">{(editFormData.updated_at) ? (DateFormat(editFormData.updated_at,"dd/mm/yyyy")) : ''}</div>
                                    </li>

                                </ul>
                            </div>
                            : ''}
                            
                            <div>
                                <label>{this.props.t('source.formDetails.status')} </label>
                                <div>  
                                    <label className="switch-btn btn btn-link ml-5" htmlFor="active" >
                                        <input checked={(editFormData.status === '1') ? true : false} className="switch-btn" id="active" value="open" name="active" type="checkbox" onChange={this.handleOptionChange.bind(this,'status')}  />
                                        <div className="slider round"></div>
                                        <span className="switch-label"></span>
                                        {(editFormData.status === "1") ? this.props.t('source.formDetails.active'):this.props.t('source.formDetails.inactive')}
                                    </label>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className=" col-sm-4 form-group text-center">
                                    <button type="submit" onClick={this.submitForm} className="btn btn-primary">{this.props.t('source.formDetails.save')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('source')(EditSource);
