import { AuthConstants } from '../constants/auth.constants';
import secureStorage from '../../config/encrypt';
// secureStorage.clear();

let authUser = '';
let initialState = '';
try{
  authUser = secureStorage.getItem('authUserInfo');
  initialState = authUser ? { loggedIn: true, authUser } : {};
}catch(err){
  
}

//FETCH REQUIRED QUERY PARAM FROM URL
let search = window.location.search;
let params = new URLSearchParams(search);
let redirectTo = params.get('redirectUrl') || "/";

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case AuthConstants.LOGIN_CAPTCHA:
      return {
        captchaImage: (action.captcha && action.captcha.svg) || "",
        captchaValue: (action.captcha && action.captcha.value) || "",
        captchaError: (action.captcha && action.captcha.error) || ""
      };
    case AuthConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        authUser: action.user,
        ...state
      };
    case AuthConstants.LOGIN_SUCCESS:
      return {
        loggingIn: true,
        loggedIn: true,
        authUser: action.user,
        redirectTo: '/',
        ...state
      };
    case AuthConstants.LOGIN_FAILURE:
      return {...state, loggingIn: false, invalidLogin: true};
    case AuthConstants.FORGOT_PASSWORD:
      return {
        formSubmit: false,
        otpSent: true,
        // ...state
      };
    case AuthConstants.FORGOT_PASSWORD_ERROR:
      return {
        formSubmit: false,
        otpSent: false,
        // ...state
      };  
    case AuthConstants.LOGOUT:
      return {
        loggedIn: false,
        loggedOut: true,
      };
    case AuthConstants.REFRESH_TOKEN:
      return {
        loggingIn: true,
        loggedIn: true,
        authUser: action.user,
        redirectTo: redirectTo,
      };
    case AuthConstants.REFRESH_TOKEN_ERROR:
      return {
        loggingIn: false,
        loggedIn: false,
        loggedOut: true,
        redirectTo: '/logout',
      };      
    case AuthConstants.RESET_PASSWORD_TOKEN:
      return {
        formSubmit: false,
        resetToken: action.user.reset_token,
        tokenExpired: false,
        otpSent: true,
        otpMatchPerform: action.error && action.error.otpMatchPerform && action.error.otpMatchPerform 
      };
    case AuthConstants.RESET_PASSWORD_TOKEN_ERROR:
      return {
        formSubmit: false,
        resetToken: null,
        tokenExpired: true,
        otpSent: true,
        otpMatchPerform: action.error && action.error.otpMatchPerform && action.error.otpMatchPerform
      };
      
    case AuthConstants.RESET_PASSWORD:
      return {
        formSubmit: false,
        tokenExpired: false,
        resetPassword: true
      };
    case AuthConstants.RESET_PASSWORD_ERROR:
      return {
        formSubmit: false,
        tokenExpired: true,
        resetPassword: false,
        error: action.error
      };
    case AuthConstants.SITE_SETTINGS_SUCCESS:
      return {
        siteSettings: action.data,
        ...state
      };
    case AuthConstants.SITE_SETTINGS_ERROR:
      return {
        siteSettings: [],
        ...state
      };  
      case AuthConstants.USER_LIST_SUCCESS:
      return {
        userList: action.data,
        ...state
      };
      case AuthConstants.USER_LIST_ERROR:
      return {
        userList: [],
        ...state
      }; 
    case AuthConstants.ROLE_PRIVILEGE_SUCCESS:
      return {
        rolePrivilegeList: action.data,
        ...state
      };
      case AuthConstants.ROLE_PRIVILEGE_ERROR:
      return {
        rolePrivilegeList: [],
        ...state
      }; 

    default:
      return state
  }
}