import React, { Component } from 'react';

import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import * as helper from '../../../config/helper';
import { toast } from 'react-toastify';
import InputField from '../../elements/InputField';

class AddFbLeads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // selectedFile: null,
            loading:false,
            action: 'leadBulkUpload',
            ccEmails: ''
        }
    }

    /**
     * Change Handler
     */
     onChangeHandler= async event=>{

        if(event.target.files[0]){
            const file = event.target.files[0];

            this.setState({ selectedFile: file })

        }
        
        
    
    }

    /**
     * On Submit filter
     */
    submitUpload = async () => {
        this.setState({loading: true});
        
        let postData = {};
        let fileName = (this.state.selectedFile) ? this.state.selectedFile.name : '';

        if(this.state.selectedFile && fileName && (/\.(csv|xls|xlsx)$/.test(fileName))){            
            await helper.getBase64(this.state.selectedFile, async (res)=>{
            
                postData['myfile'] = res;
                postData['filename'] = fileName;
                postData['action'] = this.state.action;

                //validate emails
                if(this.state.ccEmails){
                    postData['ccEmails'] = await this.validateEmails(this.state.ccEmails);
                    if(!postData['ccEmails'].length){
                        toast.error('Please enter valid emails');
                        this.setState({loading: false});
                        return false;
                    }
                }
    
                let searchResult = await this.props.actions.submitBulkUploadFbLeads(postData);
                
                if(searchResult.status === 200){
                    if(searchResult.data && searchResult.data.encodingError){
                        toast.error(searchResult.message);
                    }else{
                        toast.success(searchResult.message);
                    }
                    
                    this.setState({loading: false, selectedFile: '', ccEmails: ''});
                    this.fileInput.value = "";
                }
    
            });  
        }else{
            toast.error(this.props.t('blockingLead.please_select_file_to_upload'));
            this.setState({loading:false});
            this.fileInput.value = "";
        }
          
    }

    validateEmails = async(ccEmails) => {
        let re = /\S+@\S+\.\S+/;

        let emails = ccEmails.split(',');
        let validatedEmails = emails.filter(el=> re.test(el) )

        if(emails.length === validatedEmails.length){
            return emails
        }else{
            return []
        }

    }

    /**
     * On Input Change Handler
     */
    onInputChange = async e => {
        await this.setState({ccEmails: (e && e.target ? e.target.value : '')});
    }

    render() {
        const {  loading, ccEmails } = this.state;

        return (
            <div className="container-fluid">
                <div className="col-md-8 col-md-offset-2 bulkUploadLeads">
                    <h1 className="col-md-offset-4">{this.props.t('fbLeads.import_fb_leads')}</h1>
                    <div className="row">

                        <div className=" fbleadscsv">
                            <div className="row">
                                <div className="col-md-offset-2 col-md-8">
                                    <span className='addFbLeadNote'>{this.props.t('fbLeads.fb_csv_file_note')}</span>
                                    <form method="post" action="#" id="#">

                                        <div className="form-group files">
                                            <input type="file" className="form-control" name="myfile" onChange={this.onChangeHandler} ref={ref=> this.fileInput = ref} />
                                        </div>
                                        
                                        
                                    </form>
                                    
                                    
                                </div>

                                <div className="col-md-offset-2 col-md-8">

                                    <InputField
                                        inputProps={{
                                            id: 'ccEmails',
                                            type: "text",
                                            placeholder: this.props.t('fbLeads.email_placeholder'),
                                            name: 'ccEmails',
                                            label: this.props.t('fbLeads.cc_email'),
                                            value: ccEmails
                                        }}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                           
                            </div>
                            
                        </div>                          

                            
                            <div className="clearfix mrg-T20 form-group">
                                <div className="col-sm-3 text-right"></div>
                                <div className="col-sm-4">
                                    {
                                        loading
                                        ?
                                        <button type="submit" className="btn btn-lg btn-primary btn- pull-right" disabled>{this.props.t('blockingLead.uploading')}</button>
                                        :
                                        <button type="submit" className="btn btn-lg btn-primary btn- pull-right" onClick={this.submitUpload}>{this.props.t('blockingLead.upload')}</button>
                                        
                                    }
                                </div>
                            </div>


                    </div>	                                
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    // getBlockedClosedLeads: state.lead.makeModelVersionList
})

const mapDispatchToProps = dispatch => {
    return {
            actions: {
                submitBulkUploadFbLeads: bindActionCreators(
                    LeadActions.submitBulkUploadFbLeads,
                    dispatch
                )
            }
        }
}

export default withTranslation('lead') (withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFbLeads)));