import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as helper from '../../../../config/helper';
import { default as config } from './../../../../config/config';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import Select from 'react-select';
import { toast } from 'react-toastify';

class SearchL1Calling extends Component {
    constructor(props) {
        super(props);
        this.state = {
        selectedOption: null,
        date: new Date(),
        formData: {
            added_date_from: new Date().toISOString().split('T')[0],
            added_date_to: new Date().toISOString().split('T')[0],
            leadType: '',
            leadsDateFilter:'2'
        },
        defaultLeadDateOption: config.constants.l1ReportLeadDateFilter,
        filterData: props.prefilledData,
        clusterlist:[],
        telecalleruserlist: [],
        loading: true
      };
    }

    componentDidMount = () => {
        this.searchReport();
    }
    
    handleDateChange = (selectedKey) => async e => {
        let formData = {...this.state.formData};
        formData[selectedKey] = e ? await helper.dateFormat(e) : '';
        this.setState({ formData:formData });
    }
    handleChange = (key, event) => async e => {
        let formData = {...this.state.formData};
        formData[key] = e ? e.value : 0
        this.setState({ formData: formData });      
        
    };

    searchFilter = async() => {
        //let formValid = await this.validateSearchInput();
        if(typeof this.props.getFilterList === 'function') {
            this.props.getFilterList(this.state.formData)
        }        
    }

    static getDerivedStateFromProps(nextprops, prevState) {
        var updatedObj = {}
        // if (prevState.clusterlist !== nextprops.prefilledData.cluster) {
        //     updatedObj['clusterlist'] = helper.getDataInKeyValueFormat(nextprops.prefilledData.cluster, 'cluster');
        // }  
        if(prevState.telecalleruserlist !== nextprops.prefilledData.teleCallerUser)  {
            updatedObj['telecalleruserlist'] = nextprops.prefilledData.teleCallerUser;
        }    
        updatedObj['btnStatus'] = !nextprops.loading;
        return updatedObj;
    }

    onSelect(key,selectedList, selectedItem) {
        let formData = this.state.formData;
        let existingVal = (formData[key]) ? ((typeof formData[key] === 'string') ? formData[key].split(',') : formData[key]) : []
        existingVal.push(selectedItem.value);
        formData[key] = existingVal;
        this.setState({ formData: formData });
    }
     
    onRemove(key,selectedList, removedItem) {
        let formData = this.state.formData; 
        let existingVal = (formData[key]) ? formData[key] : [];        
        existingVal = selectedList.map(currentVal=>currentVal.value);
        formData[key] = existingVal;
        this.setState({ formData: formData });
    }

    searchReport = ()=>{
        let formData = this.state.formData;

        let postData = {}
        Object.keys(formData).forEach(el=>{
            if(formData[el] && formData[el].length){
                postData[el] = formData[el]
            }
        })

        if(new Date(postData['added_date_from']).getTime() > new Date(postData['added_date_to']).getTime()){
            toast.error(this.props.t('locationWiseLead.end_date_date_should_be_greater_than_start_date'));
            return;
        }

        if(!this.state.formData.leadsDateFilter){
            this.setState({leadsDateFilter:'2'})
            postData['leadsDateFilter'] = '2'
        }

        if(typeof this.props.getReportData === 'function'){
            this.setState({btnStatus: false})
            this.props.getReportData(postData)
        }
        
    }

    render() {
        const {formData,telecalleruserlist,defaultLeadDateOption, btnStatus}  = this.state;
        return (
            
            <div className="search-wrap">
            <ul className="search-flex">
            
                
                {/* <li className="searchitems doublew form-group" >
                    <label>{this.props.t('locationWiseLead.search.cluster')}</label>
                    <Multiselect
                        id='cluster'
                        options={clusterlist}
                        onSelect={this.onSelect.bind(this,'cluster')} 
                        onRemove={this.onRemove.bind(this,'cluster')} 
                        displayValue="label" 
                        showCheckbox={true}
                        closeOnSelect={false}
                    />
                </li> */}
                <li className="searchitems doublew form-group" >
                    <label>{this.props.t('locationWiseLead.search.agent')}</label>
                    <Multiselect
                        id='telecalleruser'
                        options={telecalleruserlist}
                        onSelect={this.onSelect.bind(this,'telecalleruser')} 
                        onRemove={this.onRemove.bind(this,'telecalleruser')} 
                        displayValue="label" 
                        showCheckbox={true}
                        closeOnSelect={false}
                    />
                </li>
                <li className="searchitems doublew form-group">
                    <label>{this.props.t('locationWiseLead.search.startDate')}</label>
                    <DatePicker 
                        className="form-control"
                        onChange={this.handleDateChange('added_date_from')}
                        // selected={(formData && formData.added_date_from) ? formData.added_date_from : this.state.date}
                        dateFormat={config.constants.dateFormatDMY}
                        // value={formData.added_date_from}                           
                        onKeyDown={e => e.preventDefault()}
                        placeholderText={this.props.t('locationWiseLead.from')}
                        selected={formData.added_date_from ? new Date(formData.added_date_from) : null}
                        maxDate={new Date()}
                        isClearable
                        />  
                </li>
                <li className="searchitems doublew form-group">
                    <label>{this.props.t('locationWiseLead.search.endDate')}</label>
                    <DatePicker 
                        className="form-control"
                        onChange={this.handleDateChange('added_date_to')}
                        // selected={(formData && formData.added_date_to) ? formData.added_date_to : this.state.date}
                        dateFormat={config.constants.dateFormatDMY}
                        // value={formData.added_date_to}
                        onKeyDown={e => e.preventDefault()}
                        placeholderText={this.props.t('locationWiseLead.to')}
                        selected={formData.added_date_to ? new Date(formData.added_date_to) : null}
                        minDate={formData.added_date_from ? new Date(formData.added_date_from) : null}
                        maxDate={new Date()}
                        isClearable
                
                    />
                </li>

                <li className="searchitems doublew form-group">
                    <label>{this.props.t('locationWiseLead.search.lead_type')}</label>
                    <Select
                        id="lead_type"
                        // value={el.value}
                        onChange={this.handleChange('leadType')}
                        options={config.constants.l1ReportLeadType}
                        isClearable={true}
                    />
                </li>

                <li className="searchitems doublew form-group">
                    <label>{this.props.t('locationWiseLead.search.filter_by_date')}</label>
                    <Select
                        id="filter_by_date"
                        value={+formData.leadsDateFilter===1 ? defaultLeadDateOption[0] : defaultLeadDateOption[1]}
                        onChange={this.handleChange('leadsDateFilter')}
                        options={config.constants.l1ReportLeadDateFilter}
                        isClearable={true}
                        // selected={defaultLeadDateOption}
                    />
                </li>
                
                <li className="searchitems form-group">
                    <label> &nbsp;</label>
                    <div>
                    {
                            btnStatus
                            ?
                            <button type="submit" className="btn btn-primary mrg-r15 undefined" onClick={this.searchReport.bind(this)}>{this.props.t('locationWiseLead.search.search')}</button>
                            :
                            <button type="submit" className="btn btn-primary mrg-r15 undefined" disabled onClick={this.searchReport.bind(this)}>{this.props.t('locationWiseLead.search.search')}</button>
                            
                        }
                        {/* <button type="submit" className="btn btn-primary mrg-r15 undefined" onClick={this.searchReport.bind(this)}>{this.props.t('locationWiseLead.search.search')}</button> */}
                    </div>
                </li>
            </ul>
            
        </div>
        );
    }
}

export default withTranslation('report')(SearchL1Calling);
