import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import _  from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LeadActions } from "../../../store/action";
import FilterBox from '../helpers/filter-box';
import FilterTable from '../helpers/filter-table';
import { default as config } from '../../../config/config';
import { toast } from 'react-toastify';
//import { useParams } from 'react-router-dom';
import queryString from 'query-string'
const moment = require("moment");

const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();

const startOfDayTimeStamp = moment.utc().startOf('day').format('YYYY-MM-DD HH:mm:ss');
const endOfDayTimeStamp = moment.utc().endOf('day').format('YYYY-MM-DD HH:mm:ss');

class LeadFinder extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        let queryParam = queryString.parse(props.location.search);

        this.multiselectRef = React.createRef();
        this.state = {
            advanceSearchEnable: false,
            totalLengthWithoutPagination: 0,
            tableData: [],
            leadList:[],
            filterFieldsData:[
                {name: 'search_by', label: 'search.searchBy', placeholder:'search.placeholder.searchBy', type: 'select', option: config.constants.leadFinderSearchBy, value: '', advanceSearch : false },
                {name: 'search_input', label: 'search.search_input', type: 'input', disabled: true, value: '', advanceSearch : false },
                {name: 'cluster', label: 'dealerDeliveryReport.cluster', multiRef: React.createRef(), type: 'multiselect', option:[], value: '', advanceSearch : false },
                {name: 'city', label: 'search.clusterCity', multiRef: React.createRef(), type: 'multiselect', showCheckBox: true, option: [], value: '', advanceSearch : false },
                {name: 'leadFinderStatus', label: 'search.status',  placeholder:'search.placeholder.status', option:[], type: 'multiselect', value:[], advanceSearch : false },
                {name: 'sub_status', groupBy:'groupByLabel', label: 'search.subStatus', placeholder: 'search.placeholder.subStatus', type: 'multiselect', option:[],  value:[], advanceSearch : false },
                {name: 'calling_status',groupBy:'groupByLabel', label: 'search.callingStatus', placeholder: 'search.placeholder.callingStatus', type: 'select',  value:'', advanceSearch : false },
                {name: 'selectCar', label: 'search.selectCar', multiRef: React.createRef(), type: 'multiselect', option: [],  value:'', advanceSearch : false, style:{ chips: { background: "red" }} },
                {name: 'follow_up_from_date', label: 'search.follow_up_dateFrom', type: 'dateTime', placeholder: 'search.placeholder.selectDate', value: ((this.props.location.search !== '') ? '' : startOfDayTimeStamp), advanceSearch : false },
                {name: 'follow_up_to_date', label: 'search.follow_up_dateTo',  placeholder:'search.placeholder.selectDate', value: ((this.props.location.search !== '') ? '' : endOfDayTimeStamp), type: 'dateTime', advanceSearch : false },
                {name: 'created_from_date', label: 'search.createdDateFrom',  placeholder:'search.placeholder.selectDate', value:(queryParam && queryParam['createdFrom'] ? moment.utc(queryParam['createdFrom']).startOf('day').format('YYYY-MM-DD HH:mm') : ''), type: 'dateTime', advanceSearch : false, maxDate: new Date(), maxTime: now.setHours(0, 0, 0), minTime:now.setHours(currhour, currmin, 0) },
                {name: 'created_to_date', label: 'search.createdDateTo',  placeholder:'search.placeholder.selectDate', value:(queryParam && queryParam['createdTo'] ? moment.utc(queryParam['createdTo']).endOf('day').format('YYYY-MM-DD HH:mm') : ''), type: 'dateTime', advanceSearch : false, maxDate: new Date(), maxTime: now.setHours(0, 0, 0), minTime:now.setHours(currhour, currmin, 0) },
                {name: 'updated_from_date', label: 'search.updatedDateFrom',  placeholder:'search.placeholder.selectDate', value:'', type: 'dateTime', advanceSearch : false, maxDate: new Date(), maxTime: now.setHours(0, 0, 0), minTime:now.setHours(currhour, currmin, 0)},
                {name: 'updated_to_date', label: 'search.updatedDateTo', placeholder:'search.placeholder.selectDate', value:'', type: 'dateTime', advanceSearch : false, maxDate: new Date(), maxTime: now.setHours(0, 0, 0), minTime:now.setHours(currhour, currmin, 0)  },
                {name: 'minBudgetRange', label: 'search.minBudgetRange',  placeholder:'search.placeholder.priceMin', value:'', type: 'select', advanceSearch : true },
                {name: 'maxBudgetRange', label: 'search.maxBudgetRange',  placeholder:'search.placeholder.priceMax', value:'', type: 'select', advanceSearch : true },
                {name: 'source', label: 'search.source',  placeholder:'search.placeholder.source', option: [], value:'', type: 'select', advanceSearch : true },
                {name: 'subSource', label: 'search.subSource',  placeholder:'search.placeholder.subSource', option: [], value:'', type: 'select', advanceSearch : true },
                {name: 'quickFilter', label: 'search.quickFilter',multiRef: React.createRef(), option: config.constants.quickFilter, placeholder:'search.placeholder.quickFilter', value:'', type: 'multiselect', advanceSearch : true },
                {name: 'attempt', label: 'search.attempt', option: config.constants.numAttemptArray,  placeholder:'search.placeholder.attempt', value:'', type: 'select', advanceSearch : true },
                {name: 'priority', label: 'search.dealerType',multiRef: React.createRef(), option: config.constants.dealerPriority, placeholder:'search.placeholder.priority', value:(queryParam && queryParam['priority'] ? config.constants.dealerPriority.filter(el=>''+el.value===queryParam['priority']) : ''), type: 'multiselect', advanceSearch : true },
                {name: 'modified_by', label: 'search.modified_by', placeholder: 'search.placeholder.modified_by', type: 'select',  value:'', advanceSearch : true },
                {name: 'walk_in_schedule_date_from', label: 'search.walk_in_schedule_from',  placeholder:'search.placeholder.selectDate', value:'', type: 'date', advanceSearch : true },
                {name: 'walk_in_schedule_date_to', label: 'search.walk_in_schedule_to', placeholder: 'search.placeholder.selectDate', type: 'date',  value:'', advanceSearch : true },
                {name: 'walk_in_completed_date_from', label: 'search.walk_in_completed_from',  placeholder:'search.placeholder.selectDate', value:'', type: 'date', advanceSearch : true },
                {name: 'walk_in_completed_date_to', label: 'search.walk_in_completed_to', placeholder: 'search.placeholder.selectDate', type: 'date',  value:'', advanceSearch : true },
                {name: 'rating', label: 'search.rating', multiRef: React.createRef(), option: config.constants.AGENT_RATINGS, placeholder:'search.placeholder.rating', value:'', type: 'multiselect', advanceSearch : true },
                {name: 'search', label: 'search.search', type: 'button' },
                {name: 'utm_source', label: 'search.utm_source', option: config.constants.utm_source, placeholder: 'search.placeholder.utm_source', type: 'select',  value:'', advanceSearch : true },
                {name: 'utm_campaign', label: 'search.utm_campaign', option: config.constants.utm_campaign, placeholder: 'search.placeholder.utm_campaign', type: 'select',  value:'', advanceSearch : true },
                {name: 'utm_medium', label: 'search.utm_medium', option: config.constants.utm_medium, placeholder: 'search.placeholder.utm_medium', type: 'select',  value:'', advanceSearch : true },
                {name: 'dialer_status', label: 'search.dialer_status', option: config.constants.dialer_history_status, placeholder: 'search.placeholder.dialer_status', type: 'select',  value:'', advanceSearch : true },
                {name: 'car_enquiry_from_date', label: 'search.car_enquiry_from',  placeholder:'search.placeholder.selectDate', value:(queryParam && queryParam['carEnquiryFrom'] ? moment.utc(queryParam['carEnquiryFrom']).startOf('day').format('YYYY-MM-DD') : ''), type: 'date', advanceSearch : true, maxDate: new Date(), maxTime: now.setHours(0, 0, 0), minTime:now.setHours(currhour, currmin, 0) },
                {name: 'car_enquiry_to_date', label: 'search.car_enquiry_to',  placeholder:'search.placeholder.selectDate', value:(queryParam && queryParam['carEnquiryTo'] ? moment.utc(queryParam['carEnquiryTo']).startOf('day').format('YYYY-MM-DD') : ''), type: 'date', advanceSearch : true, maxDate: new Date(), maxTime: now.setHours(0, 0, 0), minTime:now.setHours(currhour, currmin, 0) }
                // {name: 'reset', label: 'conversionPanel.search', type: 'button' },
            ],
            tableHeaders: [
                {key:'list.leadId', sortKey: 'ubl.id', sort: true},
                {key:'list.leadDetails'},
                {key:'list.interestedIn'},
                {key:'list.city'},
                {key:'list.status'},
                {key:'list.dueDate', sortKey: 'ubl.due_date', sort: true},
                {key:'list.updatedDate', sortKey: 'ubl.updated_at', sort: true},
                {key:'list.modifiedBy'},
                {key:'list.score', sortKey: 'ubl.score', sort: true}
            ],
            pagination: [],
            loading: false,
            importingData: false,
            importDataBtn: true,
            page: 0,
            formAction:'',
            selectedFiltersCache:{},
            addToDialerPleaseWait: false,
            addToDialerResp:{},
            cachedFilterFields:[],
            cachedPostData: {},
            resetFilterBoxPagination: false,
            sortedArray:[]
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
        
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }
    
    getLeadList = async (data) => {
        if(this._isMounted) await this.setState({loading: true});
        let searchResult = await this.props.actions.getLeadList(data);
        if(this._isMounted) await this.setState({cachedPostData: Object.assign({}, data),selectedFiltersCache: data, loading: false,leadList: searchResult.data || [], pagination: searchResult.pagination || {}, totalLengthWithoutPagination: ((searchResult['pagination'] && searchResult['pagination']['total']) || 0), runPaginationFunc: true});
    }


    getFilterList = async (searchParams) => {
        let validateForm = await this.validateFilterObj(searchParams); 
        let postData = this.state.filterData;        
        postData['page_number'] = searchParams['page_number'];

        if(searchParams['sort']) {
            postData['sort'] = searchParams['sort'];
        }
        if(searchParams['dialer_status']) {
            postData['dialer_history_status'] = searchParams['dialer_status'].value;
        }

        if(validateForm){
            this.getLeadList(postData);
        }
    }

    validateFilterObj = async (params) => {
        let retFilter = {};
        let isSearchFormValid = true;        
        // if(!params.follow_up_from_date || !params.follow_up_to_date){
        //     isSearchFormValid=false;
        //     if(!params.follow_up_from_date && params.follow_up_to_date) toast.error(this.props.t('search.validation.from_followUp_date_required'));
        //     else if(!params.follow_up_to_date && params.follow_up_from_date) toast.error(this.props.t('search.validation.to_followUp_date_required'));
        //     else toast.error(this.props.t('search.validation.followUp_date_required'));
        // }else{
        //     if(new Date(params.follow_up_from_date).getTime() > new Date(params.follow_up_to_date).getTime()){
        //         isSearchFormValid=false;
        //         toast.error(this.props.t('search.validation.to_followUp_date_should_be_greater_than_from_followUp_date'));
        //     }
        // }

        let filterKeys = Object.keys(params);
        let checkFollowUpCond = false;
        //CHECK FOLLOWUP DATE IF NO OTHER FILTERS SELECTED
        if(filterKeys.length === 1){
            checkFollowUpCond = true;
        }else if(filterKeys.length <= 3 && (params.follow_up_from_date || params.follow_up_to_date )){
            checkFollowUpCond = true;
        }
        if(checkFollowUpCond){
            if(!params.follow_up_from_date || !params.follow_up_to_date){
                isSearchFormValid=false;
                if(!params.follow_up_from_date && params.follow_up_to_date) toast.error(this.props.t('search.validation.from_followUp_date_required'));
                else if(!params.follow_up_to_date && params.follow_up_from_date) toast.error(this.props.t('search.validation.to_followUp_date_required'));
                else toast.error(this.props.t('search.validation.followUp_date_required'));
            }else{
                if(new Date(params.follow_up_from_date).getTime() > new Date(params.follow_up_to_date).getTime()){
                    isSearchFormValid=false;
                    toast.error(this.props.t('search.validation.to_followUp_date_should_be_greater_than_from_followUp_date'));
                }
            }

        }
        
        if(new Date(params.created_from_date).getTime() > new Date(params.created_to_date).getTime()){
            isSearchFormValid=false;
            toast.error(this.props.t('search.validation.to_created_date_should_be_greater_than_from_created_date'));
        }
        if(new Date(params.car_enquiry_from).getTime() > new Date(params.car_enquiry_from).getTime()){
            isSearchFormValid=false;
            toast.error(this.props.t('search.validation.car_enquiry_date_validation'));
        }
        if(new Date(params.updated_from_date).getTime() > new Date(params.updated_to_date).getTime()){
            isSearchFormValid=false;
            toast.error(this.props.t('search.validation.to_updated_date_should_be_greater_than_from_updated_date'));
        }
        if(!params.walk_in_schedule_date_from && params.walk_in_schedule_date_to){
            isSearchFormValid=false;
            toast.error(this.props.t('search.validation.from_walkin_date_is_required'));
        } else if(new Date(params.walk_in_schedule_date_from).getTime() > new Date(params.walk_in_schedule_date_to).getTime()){
            isSearchFormValid=false;
            toast.error(this.props.t('search.validation.to_walkin_date_should_be_greater_than_from_walkin_date'));
        }
        
        if(!params.walk_in_completed_date_from && params.walk_in_completed_date_to){
            isSearchFormValid=false;
            toast.error(this.props.t('search.validation.from_walkin_completed_date_is_required'));
        } else if(new Date(params.walk_in_completed_date_from).getTime() > new Date(params.walk_in_completed_date_to).getTime()){
            isSearchFormValid=false;
            toast.error(this.props.t('search.validation.to_walkin_completed_date_should_be_greater_than_from_walkin_completed_date'));
        }

        if(params.min_budget_range > params.max_budget_range){
            isSearchFormValid=false;
            toast.error(this.props.t('search.validation.max_price_should_be_greater_than_min_price'));
        }

        if(params.search_by && params.search_input){
            retFilter[params.search_by['value']] = params.search_input;
        }

        if(params.email){
            retFilter.email = params.email
        }
        if(params.lead_id){
            retFilter.lead_id = params.lead_id
        }
        if(params.name){
            retFilter.name = params.name
        }
        if(params.mobile_number){
            retFilter.mobile_number = params.mobile_number
        }

        if(params.cluster){           
            let cluster_id = params.cluster.map(cls=>cls.value);
            retFilter.cluster = cluster_id;            
        }

        if(params.city){
            retFilter.customer_city_id = params.city.length ? params.city.map(city =>  city.value ) : [];
        }
        if(params.customer_city_id){
            retFilter.customer_city_id = params.customer_city_id;
        }
        if(params.leadFinderStatus && Array.isArray(params.leadFinderStatus)){
            retFilter.status_id = (params.leadFinderStatus).map(el=>el.value);
        }
        if(params.sub_status && Array.isArray(params.sub_status)){
            retFilter.sub_status_id = (params.sub_status).map(el=>el.value);
        }
        if(params.calling_status){
            retFilter.call_status_id = params.calling_status['value'] || '';
        }
        if(params.selectCar){
            retFilter.car_make_model = params.selectCar.length ? params.selectCar.map(car =>  car.value ) : [];
        }
        if(params.quickFilter && params.quickFilter.length){
            params.quickFilter.forEach(el=>{
                retFilter[el.value] = '1';
            })
        }
       let d_prio = [];
        if(params.priority && params.priority.length){
            params.priority.forEach(el=>{ 
                d_prio.push(el.value);
                
            })
            retFilter['priority']=d_prio;
        }

        if(params.attempt){
            retFilter.num_attempt = params.attempt['value'] || '';
        }
        if(params.minBudgetRange){
            retFilter.budget_range_from = params.minBudgetRange['value'] || 0;
        } 
        if(params.maxBudgetRange){
            retFilter.budget_range_to = params.maxBudgetRange['value'] || 0;
        }
        if(params.follow_up_from_date){
            retFilter.follow_up_from_date = params.follow_up_from_date;
        } 
        if(params.follow_up_to_date){
            retFilter.follow_up_to_date = params.follow_up_to_date;
        }
        if(params.created_from_date){
            retFilter.created_from_date = params.created_from_date;
        }        
        if(params.created_to_date){
            retFilter.created_to_date = params.created_to_date;
        }
        if(params.car_enquiry_from_date){
            retFilter.car_enquiry_from_date = params.car_enquiry_from_date;
        }
        if(params.car_enquiry_to_date){
            retFilter.car_enquiry_to_date = params.car_enquiry_to_date;
        }
        if(params.updated_from_date) {
            retFilter.updated_from_date = params.updated_from_date;
        } 
        if(params.updated_to_date){
            retFilter.updated_to_date = params.updated_to_date;
        }
        if(params.source){
            retFilter.source_id = params.source['value'] || '';
        }
        if(params.subSource){
            retFilter.sub_source_id = params.subSource['value'] || '';
        }
        if(params.modified_by){
            retFilter.modified_by = params.modified_by['value'] || '';
        }
        if(params.walk_in_schedule_date_from){
            retFilter.walk_in_schedule_date_from = params.walk_in_schedule_date_from;
        }
        if(params.walk_in_schedule_date_to){
            retFilter.walk_in_schedule_date_to = params.walk_in_schedule_date_to;
        }
        if(params.walk_in_completed_date_from){
            retFilter.walk_in_completed_date_from = params.walk_in_completed_date_from;
        }
        if(params.walk_in_completed_date_to){
            retFilter.walk_in_completed_date_to = params.walk_in_completed_date_to;
        }
        if(params.dealer_id){
            retFilter.dealer_id = params.dealer_id || '';
        }
        if(params.rating){
            retFilter.rating = params.rating || '';
        }
        if(params.utm_source){
            retFilter.utm_source = params.utm_source['value'] || '';
        }
        if(params.utm_campaign){
            retFilter.utm_campaign = params.utm_campaign['value'] || '';
        }
        if(params.utm_medium){
            retFilter.utm_medium = params.utm_medium['value'] || '';
        }
        
        if(this._isMounted) await this.setState({filterData:retFilter, loading: (isSearchFormValid) ? true : false});
        
        return isSearchFormValid;

    }


    handleRequestData = async (data) => {        
        let page = data.page_number;
        
        if(page === 1 && data.sort){
            await this.setState({resetFilterBoxPagination: true});
        }

        if(data['action'] && data['action'] === 'resetAll' && this._isMounted){
            await this.setState({sortedArray:[], formAction:'resetAll', loading: false,leadList: [],page: page, pagination: {}, totalLengthWithoutPagination:  0, runPaginationFunc: false});
            return;
        }

        if(this._isMounted){
            let queryParams = queryString.parse(this.props.location.search);            
            if(queryParams.did){
                data['dealer_id']=queryParams.did;
            }
            let isDataValid = await this.checkForEmptyToDate(data);
            
            if(isDataValid){
                let cachedFilterFields=[];
                this.state.filterFieldsData.forEach(el=>{
                    cachedFilterFields.push({...el, value: data[el.name]});
                });

                if(this.state.sortedArray.length){
                    cachedFilterFields.push({'sort': this.state.sortedArray});
                    data['sort'] = this.state.sortedArray;
                }

                await this.setState({cachedFilterFields: cachedFilterFields, formAction:'', loading: true,leadList: [],page: page, pagination: {}, totalLengthWithoutPagination:  0, runPaginationFunc: false});
                this.getFilterList(data);
            }
        }
    }

    //HANDLE PAGINATE DATA FLAG RECIEVED FROM FilterTable SEND IT TO FilterBox COMPONENT
    callbackFunction = async (childData) => {
        if(this._isMounted) await this.setState({runPaginationFunc: childData['runPaginationFunc']});
    }

    importXls = async () => {

        let postData = this.state.cachedPostData;

        if(postData['page_number']){
            delete postData['page_number'];
        }
        
        let getSearchedDid = new URLSearchParams(this.props.location.search).get("did");
        if(getSearchedDid){
            postData['dealer_id'] = getSearchedDid;
        }
        if(this._isMounted) await this.setState({importingData: true});
        
        this.props.actions.importXls(postData).then(res=>{
            if(this._isMounted) this.setState({importingData: false});

            if(res && res.data){
                // let host = (config.API_URL.GETWAY_API).replace('ub/','');
                const link = document.createElement('a');
                link.href = `${res.data}`;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                toast.success(res.message);
            }
            

        });
    }

    checkForEmptyToDate = (data) => {
        let isValid = true;
        if(data && data.created_from_date && !data.created_to_date) {
            toast.error(this.props.t('search.validation.CREATED_TO_DATE_IS_REQUIRED'));
            isValid=false;
        }
        if(data && data.follow_up_from_date && !data.follow_up_to_date) {
            toast.error(this.props.t('search.validation.FOLLOWUP_TO_DATE_IS_REQUIRED'));
            isValid=false;
        }
        if(data && data.updated_from_date && !data.updated_to_date) {
            toast.error(this.props.t('search.validation.UPDATED_TO_DATE_IS_REQUIRED'));
            isValid=false;
        }
        if(data && data.car_enquiry_from && !data.car_enquiry_to) {
            toast.error(this.props.t('search.validation.CAR_ENQUIRY_TO_DATE_IS_REQUIRED'));
            isValid=false;
        }
        return isValid;
    }

    addToDialer = async () => {
        const {selectedFiltersCache} = this.state;

        selectedFiltersCache['dialer_type'] = config.DIALER_TYPE;

        this.setState({addToDialerPleaseWait: true})
        
        this.props.actions.getDialerLeads(selectedFiltersCache).then(res=>{
            this.setState({addToDialerPleaseWait: false, addToDialerResp: res});
            if(res && res.data && res.data.file){
                const link = document.createElement('a');
                link.href = `${res.data.file}`;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },error=>{
            this.setState({addToDialerPleaseWait: false, addToDialerResp: error})
        })
    }

    resetDialerResponse = () => {
        this.setState({ addToDialerResp: {}})
    }

    updateDataBySort = async (params) => {
        let postData = this.state.cachedPostData;
        postData['page_number'] = 1;
        postData['sort']        = params;
        await this.setState({sortedArray: params, page: 1});

        this.getLeadList(postData);

    }

    render() {
        const { resetFilterBoxPagination, totalLengthWithoutPagination, leadList, tableHeaders, filterFieldsData, pagination , cachedFilterFields, loading, importingData, page, runPaginationFunc, formAction, addToDialerPleaseWait,addToDialerResp, importDataBtn} = this.state;
        
        return (
            <div className="container-fluid">
                <div className="savefilterwrap"> 
                    <h1 className="mrg-rauto">{this.props.t('title')}</h1>
                </div>

                <FilterBox filterBox={{resetFilterBoxPagination, filterFields: filterFieldsData, showAdvanceSearchToggle: true, page: page,runPaginationFunc: runPaginationFunc, loading: loading, 'tableData': leadList} } action='leadFinder' searchResults={this.handleSearchedData} sendRequestDataToParent={this.handleRequestData} />
                <div className="result-wrap">
                    <FilterTable filterTableData={{addToDialerResp: addToDialerResp, filterFields: cachedFilterFields,addToDialerPleaseWait: addToDialerPleaseWait, formAction: formAction, importDataBtn: importDataBtn, title:'title', loading: loading, importingData: importingData, 'totalLengthWithoutPagination': totalLengthWithoutPagination, 'tableData': leadList, page: page, 'tableHeaders': tableHeaders, action:'leadFinder', pagination: pagination, runPaginationFunc:runPaginationFunc }} parentCallback={this.callbackFunction} updateConversionData={this.updateConversion} addToDialer={this.addToDialer} resetDialerResponse={this.resetDialerResponse} importExcelFile={this.importXls} updateDataBySort={this.updateDataBySort}/>
                </div>
            </div>
                
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    filterList: state.lead.filterList
    
    
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            getFilter: bindActionCreators(
                LeadActions.getFilter,
                dispatch
            ),
            getLeadList: bindActionCreators(
                LeadActions.getLeadList,
                dispatch
            ),
            getDialerLeads: bindActionCreators(
                LeadActions.getDialerLeads,
                dispatch
            ),
            importXls: bindActionCreators(
                LeadActions.importLeadFinderData,
                dispatch
            ),
        }
    }
}

export default withTranslation('lead')(connect(mapStateToProps, mapDispatchToProps)(LeadFinder));
