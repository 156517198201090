import React, { Component } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { default as config } from './../../../../config/config';

class SearchCategory extends Component {
  	constructor(props) {
		super(props);
		this.state = {
			filterData: {
				'name':''
			}
			
		};
   }

handleChangeEvent = (key,event) => {
	let filterData = {...this.state.filterData};
	if(key === 'name'){
		filterData.name = event.target.value;
	}else if(key === 'status'){
		filterData.status = event ? event.value : '';
	}
	this.setState({ filterData:filterData });
	if (typeof this.props.filterNameChange === 'function') {
		this.props.filterNameChange(filterData);
	}
	
}  

submitFilterForm = (event) => {
	event.preventDefault();
	if (typeof this.props.onSubmitFilter === 'function') {
		this.props.onSubmitFilter(event);
	}
}
  
resetFilterForm = (event) => {
	window.location.reload(false); 
}

render() {
	const  filterData  = this.state.filterData;
	return (
        <div className="card-heading">
			<div className="row">
				<div className="col-sm-4 col-md-6">
					<h2 className="card-title pad-t7">{this.props.t('List.title')}</h2>
				</div>
				<div className="col-sm-4 col-md-3">
						<input
							className="form-control"
							type="text"
							id="name"
							value={filterData.name}
							name="name"
							placeholder={this.props.t('List.Search_Category.Search_By_Placeholder')}
							onChange={this.handleChangeEvent.bind(this,'name')}
						/>
				</div>
				<div className="col-sm-4 col-md-3">
						<Select
							id="status"
							onChange={this.handleChangeEvent.bind(this,'status')}
							options={config.constants.status}
							isClearable
						/>
				</div>
			</div>
		</div>
       
    )
  }
}

export default withTranslation('category') (SearchCategory);
