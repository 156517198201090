import { DealerService, CityService, LeadService } from '../../service';
import {InventoryConstants} from '../constants/inventory.constants';

export const InventoryActions = {
    getCarMake,
    getCarModel,
    listCity,
    submitFilter,
    inventoryFilters,
    inventoryCsvExport
};

function getCarMake(postData) {
  
    return dispatch => {
      return new Promise((resolve, reject) => {
        DealerService.getCarMake(postData)
          .then(
            resp => {
              if(resp.data && resp.data.status === 200){
                dispatch(success(resp.data));
                resolve(resp.data);
              }
              else{
                resolve(resp.data);
                dispatch(failure('not_found'));
              }
            },
            error => {
              reject(error);
              dispatch(failure(error));
            }
          );
      });
    };

    function success(resp) { return { type: InventoryConstants.REQUEST_CAR_MAKE_SUCCESS, resp } }
    function failure(error) { return { type: InventoryConstants.REQUEST_CAR_MAKE_FAILURE, error } }

}

function getCarModel(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getCarMakeModelList(postData)
        .then(
          resp => {
            if(resp.data &&  resp.data.status === 200){
              resolve(resp.data);
              dispatch(success(resp.data));
            }
            else{
              resolve(resp.data);
              dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            dispatch(failure(error));
          }
        );
    });
  };

  function success(resp) { return { type: InventoryConstants.REQUEST_CAR_MODEL_SUCCESS, resp } }
  function failure(error) { return { type: InventoryConstants.REQUEST_CAR_MODEL_FAILURE, error } }

}

function listCity(postData={}) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      CityService.getCityState(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              resolve(resp.data);
              dispatch(success(resp.data));
            }
            else{
              resolve(resp ? resp.data : []);
              dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            dispatch(failure(error));
          }
        );
    });
  };

  function success(resp) { return { type: InventoryConstants.CITY_LIST_SUCCESS, resp } }
  function failure(error) { return { type: InventoryConstants.CITY_LIST_FAILURE, error } }

}

function submitFilter(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      
        DealerService.submitFilter(postData)
          .then(
            resp => {
              if(resp && resp.data.status === 200){
                dispatch(success(resp.data));
                return resolve(resp.data);
              }
              else{
                dispatch(failure('not_found'));
                return resolve((resp && resp['data']) || []);
              }
            },
            error => {
              
              dispatch(failure(error));
              return reject(error);
            }
          );
    });
  };

  function success(resp) { return { type: InventoryConstants.FILTER_SUBMIT_SUCCESS, resp } }
  function failure(error) { return { type: InventoryConstants.FILTER_SUBMIT_FAILURE, error } }

}

function inventoryFilters() {

  return dispatch => {
    return new Promise((resolve, reject) => {
        LeadService.getfilterData()
          .then(
            resp => {
              if(resp && resp.data.status === 200){
                dispatch(success(resp.data));
                return resolve(resp.data);
              }
              else{
                dispatch(failure('not_found'));
                return resolve((resp && resp['data']) || []);
              }
            },
            error => {
              dispatch(failure(error));
              return reject(error);
            }
          );
    });
  };

  function success(resp) { return { type: InventoryConstants.FILTER_LIST_SUCCESS, resp } }
  function failure(error) { return { type: InventoryConstants.FILTER_LIST_FAILURE, error } }

}

function inventoryCsvExport(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.inventoryCsvExport(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}