import React, { Component } from 'react';
import { default as config } from '../../../config/config';
import FilterTable from '../helpers/filter-table';

import FilterBox from '../helpers/filter-box';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class BlockingLeads extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                searchMobile: '',
                selectedReason: ''
            },
            errors:[],
            submitted: false,
            getBlockedClosedLeads: [],
            tableHeaders: [
                {key:'blockingLead.customer_detail'},
                {key:'blockingLead.reason'},
                {key:'blockingLead.blocked_on'},
                {key:'blockingLead.blocked_by'},
                {key:'blockingLead.action'},
            ],
            totalLengthWithoutPagination:0,
            filterFieldsData:[
                {name: 'customerMobile',  placeholder:'search.placeholder.enter_mobile',label: 'blockingLead.search_by_mobile', value:'', type: 'input', advanceSearch : false },
                {name: 'blockingReason', label:'blockingLead.blocking_reason', type: 'select', option: config.constants.blockReasonMapping, value: '', advanceSearch : false },
                {name: 'search', label: 'blockingLead.search',type: 'button' },
            ],
            page:0,
            tableData: [],
            loading: false,
            importingData: false,
            importDataBtn: false,
            formAction:''

        }
    }

    componentDidMount = async () => {
        this._isMounted = true;
        
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    importXls = async (data) => {
        this.props.history.push('/blockedLead-bulkUpload');
    }

    validateData = async data => {
        await Object.keys(data).forEach(el=> {
                        if(data[el] && data[el]['value']){
                            data[el] = data[el]['value']
                        }else if(data[el] && Array.isArray(data[el])){
                            data[el] = data[el].map(elm=>{ return elm.value});
                        }else{
                            data[el] = data[el]
                        }
                });
                return data;
    }

    handleRequestData = async (data) => {
        let postData = await this.validateData(data);
        let page = data.page_number;
        

        if(data['action'] && data['action'] === 'resetAll'){
            if(this._isMounted) await this.setState({formAction:'resetAll', loading: false,leadList: [],page: page, pagination: {}, totalLengthWithoutPagination:  0, runPaginationFunc: false});
            
            return;
        }

        if(postData && this._isMounted){ 
            if(this._isMounted)  await this.setState({formAction:'', loading: true,getBlockedClosedLeads: [],page: page, pagination: {}, totalLengthWithoutPagination:  0, runPaginationFunc: false});
            
            let searchResult = await this.props.actions.getBlockedClosedLeads(postData);
            
            if(this._isMounted) await this.setState({loading: searchResult.loading || false,getBlockedClosedLeads: searchResult.data || [], pagination: searchResult.pagination || {}, totalLengthWithoutPagination: ((searchResult['pagination'] && searchResult['pagination']['total']) || 0), runPaginationFunc: true});
        }

    }

    //HANDLE PAGINATE DATA FLAG RECIEVED FROM FilterTable SEND IT TO FilterBox COMPONENT
    callbackFunction = async (childData) => {
        if(this._isMounted) await this.setState({runPaginationFunc: childData['runPaginationFunc']});
    }

    render() {
          
          const {  getBlockedClosedLeads, tableHeaders, totalLengthWithoutPagination, filterFieldsData,page, pagination , loading, importingData, runPaginationFunc , formAction} = this.state;

        return (
            <div className="container-fluid">
                <h1>{this.props.t('blockingLead.title')}</h1>
                <FilterBox filterBox={{filterFields: filterFieldsData, showAdvanceSearchToggle: false, page: page,runPaginationFunc: runPaginationFunc, loading: loading, 'tableData': getBlockedClosedLeads}} action='blockingLeads' searchResults={this.handleSearchedData} sendRequestDataToParent={this.handleRequestData}/>

                
                <div className="result-wrap">
                    <FilterTable filterTableData={{formAction: formAction, importDataBtn: false,title:'blockingLead.title', loading: loading,page: page, importingData: importingData,'totalLengthWithoutPagination': totalLengthWithoutPagination, 'tableData': getBlockedClosedLeads, 'tableHeaders': tableHeaders, action:'blockingLeads', pagination: pagination, runPaginationFunc: runPaginationFunc}} parentCallback={this.callbackFunction} importExcelFile={this.importXls}/>
                </div> 
            </div>
        )
    }
}

const mapStateToProps = state => ({
    
});
const mapDispatchToProps = dispatch => {
    return {
            actions: {
                getBlockedClosedLeads: bindActionCreators(
                    LeadActions.getBlockedClosedLeads,
                    dispatch
                )
            }
            }
}

export default withTranslation('lead') (withRouter(connect(mapStateToProps, mapDispatchToProps)(BlockingLeads)));