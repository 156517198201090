import React, { Component } from 'react';
import InputField from '../../elements/InputField';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { InventoryActions, DealerActions, LeadActions, SourceActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import * as helper from '../../../config/helper';
import { Multiselect } from 'multiselect-react-dropdown';
import _  from 'lodash';
import { default as config } from './../../../config/config';
import CheckBox from './../../elements/CheckBox';
import queryString from 'query-string'

const moment = require("moment");

class FilterBox extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            iterate: 0,
            selectedOption: null,
            advanceSearchEnable: false,
            filterFields:[],
            cityListData:[],
            action:'',
            showAdvanceSearchToggle: false,
            page:1,
            runPaginationFunc: false,
            totalLengthWithoutPagination: 0,
            autoSubmitActions: ['leadFinder', 'conversionPanel', 'blockingLeads','dealerDeliveryReport','leadControlPanel'],
            isResetClicked: false,
            cachedPostData:{}
        }

    }

    componentDidMount = async () => {
        this._isMounted = true;

        this.state.filterFields.forEach((el,key) => {

            if(el.name === 'dealer'){
                if(this.props.listDealerData && !this.props.listDealerData['data'])
                this.listDealers();
            }else if(el.name === 'leadFinderStatus'){
                this.leadFinderStatus(key);
            }else if(el.name === 'selectCar'){
                this.listCars(key);
            }else if(el.name === 'source') { //SOURCE LISTING
                this.listSource(key);
            }else if(el.name === 'minBudgetRange') { //INVENTORY FILTER LIST
                this.listFilterData(key);
            }else if(el.name === 'cluster' || el.name === 'city') {
                this.getCities(el.name);
            }else if(el.name === 'modified_by'){
                this.getUserList(key);
            }

        });

        //CALL SUBMIT API BY DEFAULT TO THIS ACTIONS 
        if(this.state.autoSubmitActions.includes(this.state.action)){
            this.filterSubmit();
        }

        window.addEventListener('scroll', this.onScroll);

    }

    componentWillUnmount = async () => {
        this._isMounted = false;
    }

    /**
     * List clusters
     */
    getCities = (type) => {
        let postData = {};
        postData['isPaginationRequired']  = false;
        if(type === 'cluster'){
            if(!this.props.clusterData || !this.props.clusterData['data'])
            this.props.actions.getClusterList(postData);
        }else{
            if(!this.props.cityListData || !this.props.cityListData['data'])
            this.listCities();
        }
    }

    getUserList = (index) =>{
        let filterFields = this.state.filterFields;
        let userList = this.props.userList || [];
        this.props.actions.getUserList().then(async (response)=>{
            if(response.status === 200){
                userList = (response['data'] && response['data'].length) ? await helper.getDataInKeyValueFormat(response['data'], '') : [];
                filterFields[index]['option'] = userList;
                if(this._isMounted) this.setState({filterFields: filterFields});
            }
        }).catch(err=>{
            if(this._isMounted) this.setState({loading: false});
        });
    }

    /**
     * List Dealers
     */

    listDealers = async () => {
        let params = {};
        params['_with'] = ['id', 'organization'];

        if(!this.props.listDealerData.data){

            if(this._isMounted) await this.setState({loading: true});

            this.props.actions.listDealer(params).then(val=>{
                if(val['status'] === 200){
                    if(this._isMounted) this.setState({loading: false});
                }
            }).catch(err=>{
                if(this._isMounted) this.setState({loading: false});
            });
        }

    }

    /**
     * Lead Status
     */
    leadFinderStatus = async (index) => {
        let filterFields = this.state.filterFields;
        
        let leadStatus = this.props.listLeadStatus || [];

        if(!this.props.listLeadStatus){
            let params = {isPaginationRequired: false};
            leadStatus = await this.props.actions.getLeadFinderStatus(params);
        }

        leadStatus = (leadStatus['data'] && leadStatus['data'].length) ? await helper.getDataInKeyValueFormat(leadStatus['data'], '') : [];
        filterFields[index]['option'] = leadStatus;
        if(this._isMounted) this.setState({filterFields: filterFields});
    }

    /**
     * listCars
     */
    listCars = async (index) => {
        let makeModelData = this.props.makeModelVersionList || [];
        let filterFields = this.state.filterFields;

        if(!makeModelData.make){
            makeModelData = await this.props.actions.getCarVariant();
        }
        const arrObj = [];
        // makeModelData = response.data.data;
        if (makeModelData && makeModelData.make && makeModelData.make.length) {
            _.forEach((makeModelData.model),(modelObj) => {
                let tempObj={};
                const makeObj = _.find(makeModelData.make, { 'id': modelObj.mk_id });
                if(makeObj){
                    tempObj.value = modelObj.id;
                    tempObj.label = makeObj.make+' '+modelObj.m;
                    arrObj.push(tempObj);
                }
            });
        } else {
            // thisObj.setState({ carMakeModelList: arrObj});
        }
        filterFields[index]['option'] = arrObj;
        if(this._isMounted) this.setState({'filterFields': filterFields});
    }

    /**
     * Change Handler
     */
    handleChange = (name, index) => async e => {

        let val = '';
        if(name.indexOf('Date') !== -1 || name.indexOf('date') !== -1){
            val = (e) ? (this.state.action === 'leadFinder' ? new Date(e).toISOString() : await helper.dateFormat(e)) : '';
        }else if(e && typeof e.value !== 'undefined') {
            val = e 
        }else if(name === 'premium_only') {
            val = !this.state.filterFields.premium_only
        }else{
            // val = (e.target.value) ? e.target.value : (typeof e.value != 'undefined' ? e.value);
            if(e && typeof e.value !== 'undefined'){
                val =  e.value;
            }else if(e && typeof e.target.value !== 'undefined'){
                val =  e.target.value;
            }else{
                val =  ''
            }
        } 
        
        let updateState = this.state.filterFields;
        let {isResetClicked} = this.state;
        updateState = updateState.map(el => {
             el.value = (el.name === name) ? val : el.value;
             return el;
        });
        if(name === "searchBy"){ //FOR BLOCKED LEAD
            updateState[index+1]['disabled'] = false;
            updateState = updateState.map(el => {
                if(val.value === 'leadId' && el.name === 'search_input'){
                    el.placeholder = 'conversionPanel.searched_by_lead_id';
                    el.label = 'conversionPanel.searched_by_lead_id';
                }else if(val.value === 'mobile_number' && el.name === 'search_input'){
                    el.placeholder = 'conversionPanel.search_by_mobile';
                    el.label = 'conversionPanel.search_by_mobile';
                }
                return el;
           });

            if(!e){
                updateState[index+1]['value'] = '';
                updateState[index+1]['disabled'] = true;
            }

        }else if(name === "search_by"){ //FOR LEAD FINDER
            updateState[index+1]['disabled'] = false;

            if(val.value === 'lead_id')
                updateState[index+1]['label'] = 'search.search_by_lead_id';
            else if(val.value === 'name')
                updateState[index+1]['label'] = 'search.search_by_name';
            else if(val.value === 'email')
                updateState[index+1]['label'] = 'search.search_by_email';
            else if(val.value === 'mobile_number')
                updateState[index+1]['label'] = 'search.search_by_mobile_number';
            
            if(!e){
                updateState[index+1]['value'] = '';
                updateState[index+1]['disabled'] = true;
            }

        }else if(name === 'source') {
            if(!e){
                updateState[index+1]['value'] = '';
                updateState[index+1]['option'] = [];
            }else{
                this.listSubSource(val['value'], (index+1)); //+1 is next index of sub source select
            }
        }else if(name === 'leadFinderStatus'){
            //GET SUB STATUS LISTING WITHOUT PAGINATION
            let params= {
                'status_id':val.value,
                'isPaginationRequired':false
            }
            updateState[index+1]['value'] = '';
            updateState[index+2]['value'] = '';
            this.getSubStatusList(params, (index+1)); //sub status index
        }else if(name === 'sub_status'){
            //GET CALLING STATUS LISTING WITHOUT PAGINATION
            let params= {
                'sub_status_id':val.value,
                'isPaginationRequired':false
            }
            updateState[index+1]['value'] = '';
            this.getCallingStatusList(params, (index+1)); //calling status index
        }
        //HANDLE TO DATE WHEN FROM DATE SELECTED
        else if(name.indexOf('from_date') !== -1 || name.indexOf('start_date') !== -1){
            updateState[index+1]['minDate'] = new Date(val);
            isResetClicked = false
        }
        //HANDLE FROM DATE WHEN TO DATE SELECTED
        else if(name.indexOf('to_date') !== -1 || name.indexOf('end_date') !== -1){
            // updateState[index-1]['maxDate'] = new Date(val);                       
        } else if(name === 'minBudgetRange' && updateState[index]['option'].length) {
            let array = [...updateState[index]['option']];
            updateState[index+1]['option'] = array;
            let selectedPriceIndex = array.findIndex(obj => obj.value ===  updateState[index]['value'].value || '');
            updateState[index+1]['option'] = updateState[index+1]['option'].splice(selectedPriceIndex + 1, updateState[index+1]['option'].length - (selectedPriceIndex + 1) );
        }

        if(this._isMounted) await this.setState({filterFields: updateState,isResetClicked:isResetClicked});

    }

    /**
     * List cities
     */

    listCities = async (param={}) => {
        // if(!this.props.cityListData['data'])
        if(param['type']){

            let clusterIds = param['value'].map(cl=> { return cl.value }); //GET SELECTED CLUSTER
            let cityListObj = [];

            //GET CITIES FROM SELECTED CLUSTER IDs
            await this.props.clusterData['data'].forEach(elm => {
                if(clusterIds.includes(elm.id))  cityListObj = [...cityListObj, ...elm.cityObj];
            })

            //NOW UPDATE CITY DROPDOWN 
            let updatedStateData = this.state.filterFields.map(el=> { 
                if(el.name==='city') {
                    el.option = [ ...cityListObj];
                    let values = [];

                    let selectedCity = el.option[0]; //first value
                    let idx = 0;
                    let matchCount=0;

                    //REMOVE SELECTED CITY IF CLUSTER IS REMOVED
                    el.value.length && el.value.forEach((val,key)=>{
                            if(selectedCity && val.value!==selectedCity.value){
                                matchCount++;
                            }
                            if(key === (el.value.length-1)){ 
                                if(matchCount) values.push(val);
                                idx++;
                                selectedCity = el.option[idx]
                            }

                        })
                    el.value = values;
                }
                return el; 
            } );

            if(this._isMounted) await this.setState({filterFields: updatedStateData});

        }else{

            this.props.actions.listCity();
        }
    }

    /**
     * Sub Status Listing
     */

    getSubStatusList = async (params, index) => {
        let filterFields = this.state.filterFields;
        await this.props.actions.getSubStatusList(params).then(data => {
            if(data.length){
                let response = helper.getDataInKeyValueFormat(data,'', 'status_name', true);
                filterFields[index]['option'] = response || [];
                if(this._isMounted) this.setState({filterFields: filterFields});
            }
        });
    }

    /**
     * Calling Status Listing
     */

    getCallingStatusList = async (params, index) => {
        let filterFields = this.state.filterFields;
        await this.props.actions.getCallingStatusList(params).then(data => {
            if(data.length){
                let response = helper.getDataInKeyValueFormat(data,'','sub_status_name');
                filterFields[index]['option'] = response || [];
                if(this._isMounted) this.setState({filterFields: filterFields});
            }
        });
    }

    /**
     * Source Listing
     */

    listSource = async (index) => {
        let filterFields = this.state.filterFields;
        let getList = this.props.sourceListData || [];

        if(!this.props.sourceListData){
            let params = {isPaginationRequired: false, status: '1'}
            getList = await this.props.actions.sourceListData(params);
        }
        getList = (getList['data'] && getList['data'].length) ? await helper.getDataInKeyValueFormat(getList['data'], '') : [];
        filterFields[index]['option'] = getList;
        if(this._isMounted) this.setState({filterFields: filterFields});
    }

    /**
     * Sub Source listing
     */

    listSubSource = async (sourceId, index) => {
        let filterFields = this.state.filterFields;

        await this.props.actions.subSourceListData({"source_id": sourceId, status: '1'}).then(d => {
            if(d.status === 200){
                let subSourceListArr = helper.getDataInKeyValueFormat(d.data,'');
                filterFields[index]['option'] = subSourceListArr || [];

                if(this._isMounted) this.setState({filterFields: filterFields});
            }
        });
    }
    

    /**
     * Inventory Filter API to get Min Max Car Price
     */

    listFilterData = async (index) => {
        let filterFields = this.state.filterFields;

        let getList = this.props.inventoryFilters || [];

        let minList = [];
        let maxList = [];
        
        if(!this.props.inventoryFilters){
            getList = await this.props.actions.inventoryFilters();
        }
        getList['data'] && (getList['data']).forEach(el => {
            if(el.key === "min_car_price"){
                minList =   helper.getDataInKeyValueFormat(el.list, '');
            }else if(el.key === "max_car_price"){
                maxList =   helper.getDataInKeyValueFormat(el.list, '');
            }
        })

        filterFields[index]['option'] = minList; //Min Price Filter
        filterFields[index+1]['option'] = maxList; // Max Price Filter

        if(this._isMounted) this.setState({filterFields: filterFields});
        
    }

    /**
     * Toggle Change
     */
    ToogleAdvanceSearch = () => {
        let advanceSearchEnable = this.state.advanceSearchEnable;
        if (advanceSearchEnable) {
            if(this._isMounted) this.setState({ advanceSearchEnable: false });
        } else {
            if(this._isMounted) this.setState({ advanceSearchEnable: true });
        }
    };

    /**
     * Update State
     */
    static getDerivedStateFromProps = (nextProps, prevState) => {

        let updatedStateData = {};
            prevState.iterate++;
        let queryParam = queryString.parse(nextProps.location.search);
            
            updatedStateData['filterFields'] = prevState.filterFields || [];
            
            if(nextProps.filterBox.runPaginationFunc) { 
                updatedStateData['runPaginationFunc'] = false;
            }
            
            //UPDATE FILTER FILEDS
            if(prevState.iterate === 1  && nextProps.filterBox.filterFields && nextProps.filterBox.filterFields.length){
                updatedStateData['filterFields']  = [...updatedStateData['filterFields'] , ...nextProps.filterBox.filterFields ];
                updatedStateData['showAdvanceSearchToggle'] = nextProps.filterBox.showAdvanceSearchToggle;
            }
            if(prevState.action !== nextProps.action){
                updatedStateData['action']  = nextProps.action;
            }

            if(nextProps.filterBox.page){
                updatedStateData['page'] = nextProps.filterBox.page;
            }

            updatedStateData['filterFields'].forEach((el,key)=>{

                //UPDATE CITY DROPDOWN IF "dependent" KEY NOT FOUND, MEANS CITY LIST NOT DEPENDS ON CLUSTER
                if(el.name === 'city' && !el.option.length && !el.dependent){
                    let cityList = [];

                    //POPULATE CITY LIST
                    if(nextProps.cityListData && nextProps.cityListData['data'] && nextProps.cityListData['data']['city']){
                        cityList = helper.getDataInKeyValueFormat(nextProps.cityListData['data']['city'],'');
                    
                    }                
                    updatedStateData['filterFields'][key]['option'] = cityList;

                    //PRE SELECTED CITIES
                    if(queryParam['city']){
                        queryParam['city'] = queryParam['city'].split(',');
                        let selectedCities = cityList.filter(el=>queryParam['city'].includes(''+el.value));
                        updatedStateData['filterFields'][key]['value'] = selectedCities;

                    }
                }

                //LIND FINDER STATUS
                if(el.name === 'leadFinderStatus' && !el.option.length){
                    let statusList = [];
                    //POPULATE STATUS LIST
                    if(nextProps.listLeadStatus && nextProps.listLeadStatus['data'] && nextProps.listLeadStatus['data']){
                        statusList = helper.getDataInKeyValueFormat(nextProps.listLeadStatus['data'],'');
                    }
                    
                    updatedStateData['filterFields'][key]['option'] = statusList;
                }
                //POPULATE DEALER LIST
                if(el.name === 'dealer' && !el.option.length){
                    let dealerList = [];
                    
                    if(nextProps.listDealerData && nextProps.listDealerData['data']){
                        dealerList = helper.getDataInKeyValueFormat(nextProps.listDealerData['data'],'');
                    }
                    updatedStateData['filterFields'][key]['option'] = dealerList;
                }

                //POPULATE CLUSTER LIST

                if(el.name === 'cluster' && !el.option.length){
                    let clusterList = [];
                    
                    if(nextProps.clusterData && nextProps.clusterData['data']){
                        clusterList = helper.getDataInKeyValueFormat(nextProps.clusterData['data'],'');
                    }
                    updatedStateData['filterFields'][key]['option'] = clusterList;

                    //PRE SELECTED CLUSTERS
                    if(queryParam['cluster']){
                        queryParam['cluster'] = queryParam['cluster'].split(',');
                        let selectedClusters = clusterList.filter(el=>queryParam['cluster'].includes(''+el.value));
                        updatedStateData['filterFields'][key]['value'] = selectedClusters;
                    }
                }
            });
            
        return updatedStateData;
    }

    /**
     * SUBMIT FILTER HANDLER
     */
    filterSubmit = async (params={}) => {
        let postData = {page_number: params['page'] || 1};
        let checkNumber = new RegExp(/^\d+$/);
        let errors = {};
        let ratingVal=[];
        let queryParam = queryString.parse(this.props.location.search);


        //CHECK VALIDATIONS
        this.state.filterFields.forEach((el,key) => {

            if(el.name === 'searchMobile' || el.name === 'customerMobile' || (el.name === 'search_input' && this.state.filterFields[key-1]['value']['value'] && this.state.filterFields[key-1]['value']['value'] === 'mobile_number')){
                //Disable length check of mobile for track conversion page
                if((el.value.length || this.state.action === 'addConversion') && !checkNumber.test(el.value)){
                    errors[el.name] = this.props.t('search.validation.search_mobile_required');
                }
                if((el.value.length || this.state.action === 'addConversion') && ( !el.value.startsWith(config.constants.ISD_CODE[0]['startsFrom']) || el.value.length < config.constants.ISD_CODE[0]['minLength'] || el.value.length > config.constants.ISD_CODE[0]['maxLength'])){
                    errors[el.name] = this.props.t('search.validation.mobile_invalid');
                }
            }
        
            postData[el.name] = el.value;
               
            if(el.name === 'rating' && el.value){
              // eslint-disable-next-line
                el.value.filter(ratings => {ratingVal.push(ratings.value)});
                postData[el.name] = ratingVal;
            }
            
            if(el.type === 'select' && (!el.value || !Object.keys(el.value).length)){ 
                delete postData[el.name]
            }else if(el.type !== 'select' && (!el.value || !el.value.length)){
                delete postData[el.name]
            }

            if((el.name === 'follow_up_from_date' || el.name === 'follow_up_to_date') && el.value) {
                postData[el.name] = new Date(el.value).toISOString();
            }

            // if(el.type === 'multiselect'){
            //     if(el.value) {
            //         postData[el.name] = (el.value).map(elm=> elm.value);
            //     }else{
            //         delete postData[el.name]
            //     }
            // }
        });

        if(!postData['city'] && queryParam['city']){
            postData['customer_city_id'] = queryParam['city'].split(',');
        }
        //VALIDATE MOBILE
        if(Object.keys(errors).length){
            await this.setState({
                errors: errors,
                submitted: false,
                page: postData['page_number'] || 1
            });
        }else{
            await this.setState({        
                errors: errors,
                submitted: false,
                page: postData['page_number'] || 1
            });
                
            // this.serviceCall(postData);
            if(!Object.keys(errors).length){
                if(params.callingFrom && params.callingFrom === 'pagination'){
                    let pageNum = postData['page_number'];
                    postData = Object.keys(this.state.cachedPostData).length ? Object.assign({}, this.state.cachedPostData) : postData 
                    postData['page_number'] = pageNum
                }else{                    
                    let cachedPostData = Object.assign({}, postData);
                    await this.setState({cachedPostData:cachedPostData});
                }
                this.props.sendRequestDataToParent(postData);
            }
        }
    }

    //TRACK CONVERSION ADD CUSTOMER
    addNewCustomer = async () => {
        this.props.sendRequestDataToParent({typeof:'addCustomer'});
    }

    serviceCall = async (data) => { 
        let searchResult = '';
        this.handleSearchedData({loading: true});

        if(this.state.action === 'blockingLeads'){
            searchResult = await this.props.actions.getBlockedClosedLeads(data);
        }else if(this.state.action === 'conversionPanel'){
            searchResult = await this.props.actions.getConversionInfoList(data);
        }else if(this.state.action === 'dealerDeliveryReport'){
            searchResult = await this.props.actions.getConversionInfoList(data);
        }

        if(searchResult.status === 200){
                this.handleSearchedData(searchResult);
        }
    }

    

    /**
     * Send Data to Parent
     */
    callbackFunction = (childData) => {
        this.serviceCall({...childData, ...this.state.filterFields});
    }

    /**
     * Handle searched data
     */
    handleSearchedData = (data) => {
        this.props.searchResults(data);            
    }

    /**
     * On multi select option select
     */
    onSelect = type => (selectedList , selectedItem) => {
        this.updateStateFun(type[0], selectedList);
        if(type[0] === 'cluster') {
            this.listCities({type:'cluster', value: selectedList});
        } else if(type[0] === 'leadFinderStatus') {

            let params= {
                'isPaginationRequired':false
            }

            params['status_id'] = selectedList.map(el=>el.value);
            this.getSubStatusList(params, (type[1]+1)); //sub status index
        } else if(type[0] === 'sub_status') {
            let params= {
                'isPaginationRequired':false
            }
            params['sub_status_id'] = selectedList.map(el=>el.value);

            this.getCallingStatusList(params, (type[1]+1)); //calling status index
        }
    }
    
    /**
     * On multi select option remove
     */
    onRemove = type => async (selectedList , selectedItem) => {
        this.updateStateFun(type[0], selectedList);

        let updateState = this.state.filterFields;

    
        if(type[0] === 'cluster') {
            this.listCities({type:'cluster', value: selectedList});
        } else if(type[0] === 'leadFinderStatus') {
            let params= {
                // 'status_id':val.value,
                'isPaginationRequired':false
            }

            params['status_id'] = selectedList.map(el=>el.value);
            updateState[type[1]+1]['value'] = [];
            updateState[type[1]+1]['option'] = [];
            updateState[type[1]+2]['value'] = [];
            updateState[type[1]+2]['option'] = [];

            if(params['status_id'].length){

                this.getSubStatusList(params, (type[1]+1)); //sub status index
            }
            // if(this._isMounted) await this.setState({filterFields: updateState});

        } else if(type[0] === 'sub_status') {
            let params= {
                // 'status_id':val.value,
                'isPaginationRequired':false
            }
            params['sub_status_id'] = selectedList.map(el=>el.value);
            
            updateState[type[1]+1]['value'] = [];
            updateState[type[1]+1]['option'] = [];
            if(params['sub_status_id'].length){
                this.getCallingStatusList(params, (type[1]+1)); //calling status index
            }
            if(this._isMounted) await this.setState({filterFields: updateState});

        }
    }

    updateStateFun = async (type, value) => {
        let updateState = this.state.filterFields;
        updateState = updateState.map(el => {
             el.value = (el.name === type) ? value : el.value;
             return el;
        });
        
        if(this._isMounted) await this.setState({filterFields: updateState});
    }

    /**
     * Reset Filter
     */
    filterReset = async () => {
        //REMOVE QUERY STRING FROM URL
        if(this.props.location.search !== ''){
            this.props.history.push(this.props.location.pathname);
        }
        let updateState = this.state.filterFields;
        updateState = updateState.map(el => { 
            if(el.multiRef) {
                el.multiRef.current.resetSelectedValues();
            }
            if(el.name === 'search_input'){
                el.disabled = true;
                el.label = 'search.search_input';
            }
            el.value = (el.type === "multiselect") ? [] : '';

            if(el.name === 'follow_up_from_date'){
                // el.value = new Date().toISOString();
                el.value = moment.utc().startOf('day').format('YYYY-MM-DD HH:mm:ss');
            }
            if(el.name === 'follow_up_to_date'){
                el.value = moment.utc().endOf('day').format('YYYY-MM-DD HH:mm:ss');
            }
            if(el.name === 'sub_status') {
                el.option = [];
            }
            if(el.name === 'calling_status') {
                el.option = [];
            }
            return el;
        });
        if(this._isMounted) await this.setState({filterFields: updateState, errors: {},isResetClicked:true});
        this.props.sendRequestDataToParent({'action':'resetAll',page_number:1});
        // this.props.searchResults({filterFields: updateState, page_number: 1})
    }



    onScroll = (event) => { 
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300) && this._isMounted) {
            try {
                //Handling multiple API call on single scroll & if total results are showing
                let tableDataLen = (this.props.filterBox.tableData) ? this.props.filterBox.tableData.length : 0;
                if (!this.state.runPaginationFunc && this.state.totalLengthWithoutPagination !== tableDataLen) {
                    
                    const { page } = this.state;
                    let loading = false;

                    if (page !== 0) {
                        loading = true;
                    }
                    
                    //CALL FOR API & SENT runPaginationFunc TRUE WHICH SHOWS IF TRUE THEN UPDATE DATA IN TABLE
                    if(this._isMounted) {
                        this.setState({ page: page+1, loading: loading, runPaginationFunc: true }, ()=>{

                        });
                    }
                    this.filterSubmit({page:page+1, callingFrom:'pagination'});
                }
            } catch (err) {
                //
            }

        }
    }

    //SINGLE SELECT FOR GROUPING VALUES
    groupedOptions = (optionsParam) => {
        let optionsArr = []
        
        if(optionsParam){

            optionsParam.forEach(el=>{
                let findExists = optionsArr.filter(fl=>fl.label===el['groupByLabel']);
                    if(!findExists.length){
                        optionsArr.push( {
                            label: el['groupByLabel'],
                            options: optionsParam.filter(op=> op.groupByLabel === el.groupByLabel),
                          });
                    }
                });
        }
        return optionsArr
    }
    formSubmitonEnter = (event) => {
        if (event.key === 'Enter') {
            this.filterSubmit();
        }
    }
    render() {
          const { filterFields, showAdvanceSearchToggle, errors, action,isResetClicked } = this.state;
        return (
            <div className="">
              <div className="search-wrap">
                    <ul className="search-flex" onKeyUp={this.formSubmitonEnter}>
                        {
                            (filterFields).map((el, key) => {
                                if(el.type === 'input'){
                                    return(<li className={ el.advanceSearch && !this.state.advanceSearchEnable ? "searchitems form-group hide" : "searchitems form-group " }  key={el.name}>
                                                <div className={'form-group' + (errors && errors[el.name] ? ' has-error' : '')}>
                                                    <InputField
                                                        inputProps={{
                                                            id: el.name,
                                                            type: "text",
                                                            placeholder: this.props.t(el.placeholder),
                                                            name: el.name,
                                                            label: this.props.t(el.label),
                                                            value: el.value,
                                                            disabled: el.disabled || false
                                                        }}
                                                        onChange={this.handleChange(el.name)}
                                                    /> 
                                                    {errors && errors[el.name] && <div className="error-block">{errors[el.name]}</div> }
                                                </div>
                                            </li>)
                                }else if(el.type === 'dateTime'){
                                    return(<li key={el.name} className={ el.advanceSearch && !this.state.advanceSearchEnable ? "searchitems form-group doublew hide" : "searchitems form-group doublew " } >
                                                <label>{this.props.t(el.label)}</label>
                                                <DatePicker 
                                                    className="form-control"
                                                    onChange={this.handleChange(el.name, key, el.type)}
                                                    placeholderText={this.props.t(el.placeholder)}
                                                    dateFormat={"dd-MM-yyyy HH:mm"}
                                                    selected={el.value ? new Date(el.value) : null}
                                                    startDate={el.startDate || null}
                                                    maxDate={el.maxDate || null}
                                                    minDate={!isResetClicked ? el.minDate : null}
                                                    minTime={!isResetClicked ? el.minTime : null}
                                                    maxTime={!isResetClicked ? el.maxTime : null }
                                                    isClearable
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                />
                                            </li>)
                                }else if(el.type === 'date'){
                                    return(<li key={el.name} className={ el.advanceSearch && !this.state.advanceSearchEnable ? "searchitems form-group doublew hide" : "searchitems form-group doublew " } >
                                                <label>{this.props.t(el.label)}</label>
                                                <DatePicker 
                                                    className="form-control"
                                                    onChange={this.handleChange(el.name, key, el.type)}
                                                    placeholderText={this.props.t(el.placeholder)}
                                                    dateFormat="dd-MM-yyyy"
                                                    selected={el.value ? new Date(el.value) : null}
                                                    startDate={el.startDate || null}
                                                    maxDate={el.maxDate || null}
                                                    minDate={!isResetClicked ? el.minDate : null}
                                                    minTime={!isResetClicked ? el.minTime : null}
                                                    maxTime={!isResetClicked ? el.maxTime : null }
                                                    isClearable
                                                />
                                            </li>)
                                }else if(el.type === 'select'){
                                    return(<li className={ el.advanceSearch && !this.state.advanceSearchEnable ? "searchitems form-group hide" : "searchitems form-group"} key={el.name}>
                                                <label>{this.props.t(el.label)}</label>
                                                {
                                                    (action === 'leadFinder' && el.name === 'calling_status')
                                                    ?
                                                        <Select
                                                            id={el.name}
                                                            value={el.value}
                                                            onChange={this.handleChange(el.name, key)}
                                                            options={this.groupedOptions(el.option)}
                                                            isClearable={true}

                                                        />
                                                    :
                                                        <Select
                                                            id={el.name}
                                                            value={el.value}
                                                            onChange={this.handleChange(el.name, key)}
                                                            options={el.option}
                                                            isClearable={true}
                                                            groupBy={el.groupBy||false}

                                                        />
                                                }
                                                
                                                
                                            </li>)
                                }else if(el.type === 'multiselect'){
                                    return(<li className={ el.advanceSearch && !this.state.advanceSearchEnable ? "searchitems form-group hide" : "searchitems form-group"} key={el.name}>
                                                <label>{this.props.t(el.label)}</label>
                                                {/* <Multiselect
                                                    id={el.name}
                                                    value={el.value}
                                                    onChange={this.handleChange(el.name)}
                                                    options={el.option}
                                                /> */}
                                                <Multiselect
                                                    id={el.name}
                                                    options={el.option} 
                                                    
                                                    // onSelect={this.onSelect.bind(this,'car_make_model')}
                                                    onSelect={this.onSelect([el.name, key])}
                                                    // onRemove={this.onRemove.bind(this,'car_make_model')} 
                                                    onRemove={this.onRemove([el.name, key])} 
                                                    selectedValues={el.value} 
                                                    displayValue="label" 
                                                    showCheckbox={true}
                                                    closeOnSelect={false}
                                                    // styl={ chips= { background: "red" }}
                                                    ref={el.multiRef}
                                                />
                                            </li>)
                                
                                }else if(el.type === 'checkbox'){
                                    return(<li key={el.name} className={ el.advanceSearch && !this.state.advanceSearchEnable ? "searchitems form-group hide" : "searchitems form-group"}>
                                                <label>{this.props.t(el.label)}</label>
                                                    <CheckBox type="checkbox" checked={el.isPremium}  onChange={ this.handleChange(el.name, key, el.type) }  name={el.name} id={el.name} label={this.props.t(el.label)}></CheckBox>
                                            </li>)
                                }
                                else{
                                    return ''
                                }


                                
                            })
                        }
                        <li className={(action==='addConversion' ? "searchitems doublew form-group addConversionBtns " : " ") + "searchitems doublew form-group"}>
                            <label> &nbsp;</label>
                            <div>
                                <button type="submit" onClick={this.filterSubmit} className="btn btn-primary">{this.props.t('search.search')}</button>
                                {
                                    (action === 'addConversion')
                                    ?
                                    (<button type="button" onClick={this.addNewCustomer} className="btn btn-success">{this.props.t('search.addNew')}</button>)
                                    :
                                    (<button type="reset" onClick={this.filterReset} className="btn btn-warning">{this.props.t('search.reset')}</button>)
                                }
                            </div>
                        </li>
                        
                    </ul>
                    {
                        (showAdvanceSearchToggle)
                        ?
                        <span  className="advancesearch btn btn-link"  onClick={this.ToogleAdvanceSearch} >
                            <span>{this.state.advanceSearchEnable ? "- "+this.props.t('search.lessSearch') : "+ "+ this.props.t('search.advanceSearch')}</span>
                        </span>
                        :
                        ''
                    }
                    
                </div>
               
               </div>
        )
    }
}

const mapStateToProps = state => ({
    cityListData: state.inventory.cityListData || {},
    listDealerData: state.dealer.dealerListData || {},
    listLeadStatus: state.lead.leadStatus,
    makeModelVersionList: state.lead.makeModelVersionList,
    sourceListData: state.source.sourceListData,
    inventoryFilters: state.inventory.inventoryFilters,
    clusterData: state.dealer.clusterData

});

const mapDispatchToProps = dispatch => {
    return {
            actions: {
                listCity: bindActionCreators(
                    InventoryActions.listCity,
                    dispatch
                ),
                listDealer: bindActionCreators(
                    DealerActions.listDealer,
                    dispatch
                ),
                getBlockedClosedLeads: bindActionCreators(
                    LeadActions.getBlockedClosedLeads,
                    dispatch
                ),
                getLeadFinderStatus: bindActionCreators(
                    LeadActions.getLeadFilterStatusList,
                    dispatch
                ),
                getCarVariant: bindActionCreators(
                    LeadActions.getMakeModelVersion,
                    dispatch
                ),
                sourceListData: bindActionCreators(
                    SourceActions.listSource,
                    dispatch
                ),
                subSourceListData: bindActionCreators(
                    SourceActions.listSubSource,
                    dispatch
                ),
                inventoryFilters: bindActionCreators(
                    InventoryActions.inventoryFilters,
                    dispatch
                ),
                getSubStatusList: bindActionCreators(
                    // LeadActions.getSubStatusList,
                    LeadActions.getSubStatus,
                    dispatch
                ),
                getClusterList: bindActionCreators(
                    DealerActions.getClusterList,
                    dispatch
                ),
                listClusterCity: bindActionCreators(
                    LeadActions.getClusterCities,
                    dispatch
                ),
                getDealerDeliveryReport: bindActionCreators(
                    DealerActions.getDealerDeliveryReport,
                    dispatch
                ),
                getUserList: bindActionCreators(
                    LeadActions.getAllUserList,
                    dispatch
                ),
                getCallingStatusList: bindActionCreators(
                    LeadActions.getCallingStatus,
                    dispatch
                )
            }
            }
}

export default withTranslation('lead') (withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterBox)));