import { DealerConstants } from '../constants/dealer.constants';

export default function DealerReducer(state = {}, action) {
  switch (action.type) {
    case DealerConstants.REQUEST_CLUSTER_LIST_SUCCESS:
      return {
        clusterData: action.resp , ...state 
      };
    case DealerConstants.REQUEST_CLUSTER_LIST_FAILURE:
      return {
        clusterData: action.resp , ...state 
      };
      case DealerConstants.DEALER_LIST_SUCCESS:
      return {
         dealerListData: action.resp , ...state
      };
      case DealerConstants.DEALER_LIST_FAILURE:
      return {
        dealerListData: {success: false, data: []} , ...state 
      };
    default:
      return state
  }
}