export const LeadConstants = {
    ADD_LEAD_SUCCESS: 'ADD_LEAD_SUCCESS',
    ADD_LEAD_FAILURE: 'ADD_LEAD_FAILURE',
    
    REQUEST_MAKE_MODEL_VERSION_SUCCESS: 'REQUEST_MAKE_MODEL_VERSION_SUCCESS',
    REQUEST_MAKE_MODEL_VERSION_FAILURE: 'REQUEST_MAKE_MODEL_VERSION_FAILURE',

    CITY_LIST_SUCCESS: 'CITY_LIST_SUCCESS',
    CITY_LIST_FAILURE: 'CITY_LIST_FAILURE',
    
    LEAD_LIST_SUCCESS: 'LEAD_LIST_SUCCESS',
    LEAD_LIST_FAILURE: 'LEAD_LIST_FAILURE',

    FILTER_LIST_SUCCESS: 'FILTER_LIST_SUCCESS',
    FILTER_LIST_FAILURE: 'FILTER_LIST_FAILURE',

    CUSTOMER_UPDATE_SUCCESS: 'CUSTOMER_UPDATE_SUCCESS',
    CUSTOMER_UPDATE_FAILURE: 'CUSTOMER_UPDATE_FAILURE',

    REQUIREMENT_SAVE_SUCCESS: 'REQUIREMENT_SAVE_SUCCESS',
    REQUIREMENT_SAVE_FAILURE: 'REQUIREMENT_SAVE_FAILURE',

    GET_AGENT_DETAILS_SUCCESS: 'GET_AGENT_DETAILS_SUCCESS',
    GET_AGENT_DETAILS_FAILURE: 'GET_AGENT_DETAILS_FAILURE',

    DEALER_LIST_SUCCESS: 'DEALER_LIST_SUCCESS',
    DEALER_LIST_FAILURE: 'DEALER_LIST_FAILURE',

    STATUS_LIST_SUCCESS: 'STATUS_LIST_SUCCESS',
    STATUS_LIST_FAILURE: 'STATUS_LIST_FAILURE',

    TIMELINE_DETAILS_SUCCESS:"TIMELINE_DETAILS_SUCCESS:",
    TIMELINE_DETAILS_FAILURE:"TIMELINE_DETAILS_FAILURE:",

    LEADS_CAR_LIST_SUCCESS:"LEADS_CAR_LIST_SUCCESS:",
    LEADS_CAR_LIST_FAILURE:"LEADS_CAR_LIST_FAILURE:",

    STOCK_CAR_DETAILS_SUCCESS:"STOCK_CAR_DETAILS_SUCCESS:",
    STOCK_CAR_DETAILS_FAILURE:"STOCK_CAR_DETAILS_FAILURE:",
    
    GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
    GET_CUSTOMER_FAILURE: 'GET_CUSTOMER_FAILURE',

    GET_BLOCKED_CLOSED_LEADS_SUCCESS: 'GET_BLOCKED_CLOSED_LEADS_SUCCESS',
    GET_BLOCKED_CLOSED_LEADS_FAILURE: 'GET_BLOCKED_CLOSED_LEADS_FAILURE',

    SUB_STATUS_LIST_SUCCESS: 'SUB_STATUS_LIST_SUCCESS',
    SUB_STATUS_LIST_FAILURE: 'SUB_STATUS_LIST_FAILURE',
    
    LEAD_FINDER_STATUS_SUCCESS: 'LEAD_FINDER_STATUS_SUCCESS',
    LEAD_FINDER_STATUS_FAILURE: 'LEAD_FINDER_STATUS_FAILURE',

    SAVE_MAIN_LEAD_SUCCESS: 'SAVE_MAIN_LEAD_SUCCESS',
    SAVE_MAIN_LEAD_ERROR: 'SAVE_MAIN_LEAD_ERROR',

    CITY_STATE_LIST_SUCCESS: 'CITY_STATE_LIST_SUCCESS',
    CITY_STATE_LIST_FAILURE: 'CITY_STATE_LIST_FAILURE',

    RTO_LIST_SUCCESS: 'RTO_LIST_SUCCESS',
    RTO_LIST_FAILURE: 'RTO_LIST_FAILURE',

    CUSTOMER_LOCATITY_SUCCESS: 'CUSTOMER_LOCATITY_SUCCESS',
    CUSTOMER_LOCATITY_FAILURE: 'CUSTOMER_LOCATITY_FAILURE',

    LEAD_REOPEN_DATA: 'LEAD_REOPEN_DATA',

    CONVERSION_STATUS_SUCCESS: 'CONVERSION_STATUS_SUCCESS',
    CONVERSION_STATUS_FAILURE: 'CONVERSION_STATUS_FAILURE',

    WALKIN_FEEDBACK_SUCCESS: 'WALKIN_FEEDBACK_SUCCESS',
    WALKIN_FEEDBACK_FAILURE: 'WALKIN_FEEDBACK_FAILURE',

    CALL_STATUS_LIST_SUCCESS: 'CALL_STATUS_LIST_SUCCESS',
    CALL_STATUS_LIST_FAILURE: 'CALL_STATUS_LIST_FAILURE',

    CALLING_STATUS_LIST_SUCCESS: 'CALLING_STATUS_LIST_SUCCESS',
    CALLING_STATUS_LIST_FAILURE: 'CALLING_STATUS_LIST_FAILURE'
};