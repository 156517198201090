import MasterService from './MasterService';

const PrivilegeService = {
    ...MasterService,
    
    getPrivilegeList(postdata){
        return this.post('/privilege/listPrivilege',postdata) 
    },
    updatePrivilegeDetails(postdata){
        return this.post('/privilege/editPrivilege',postdata) 
    },
    savePrivilegeDetails(postdata){
        return this.post('/privilege/savePrivilege',postdata) 
    },
    getCategoryList(postdata){
        return this.post('/category/listCategory',postdata) 
    },
    updateStatus(postdata){
        return this.post('/privilege/updateStatus',postdata) 
    },
    getAllUserList(){
        return this.post('/user/listAllUser') 
    }

}

export default PrivilegeService;
