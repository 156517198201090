import React, { Component } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import * as helper from '../../../config/helper';
import secureStorage from '../../../config/encrypt';
import { default as config } from '../../../config/config';

class LeadInfo extends Component {
    roleId=0;
    constructor(props) {
        super(props);
        this.state = {
        selectedOption: null,
        date: new Date(),
        advanceSearchEnable: false,
        leadDetails:{},
        cityDetails:[]
      };
      let authUserInfo = secureStorage.getItem('authUserInfo');
      this.roleId = (authUserInfo && authUserInfo['authUser'] && authUserInfo['authUser']['id']) ? authUserInfo['authUser']['id'] : 0;
      
    }

    // componentWillReceiveProps(nextProps) {
        // this.setState({
        //     leadDetails: (nextProps.leadList && nextProps.leadList.length) ? nextProps.leadList[0] : [],
        //     cityDetails: (nextProps.cityDetails && nextProps.cityDetails.length) ? (nextProps.cityDetails) : []
            
        // });
    // }

    /**
     * Update State
     */
    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};
        updatedStateData['leadDetails'] = (nextProps.leadList && nextProps.leadList.length) ? nextProps.leadList[0] : [];
        
        if(nextProps.cityDetails  && nextProps.cityDetails['city']){
            updatedStateData['cityDetails'] = helper.getDataInKeyValueFormat(nextProps.cityDetails['city'],'city');
        
        }
        return updatedStateData;
    }

    ToogleAdvanceSearch = () => {
        let advanceSearchEnable = this.state.advanceSearchEnable;
        if (advanceSearchEnable) {
          this.setState({ advanceSearchEnable: false });
        } else {
          this.setState({ advanceSearchEnable: true });
        }
      };

    onChange = date => this.setState({ date })
    
    handleChange = (key,event) => {
        const {leadDetails} = this.state;
        if(['customer_name'].includes(key)){
            leadDetails[key] = event.target.value;
        }
        else if(['chat_source','customer_city_id'].includes(key)){
            leadDetails[key] = event ? event.value : '';
        }
        this.setState({ leadDetails });
    };

    updateCustomeName = (event) => {
        const {leadDetails} = this.state;
        const params = {
            id:leadDetails.customer_id,
            name:leadDetails.customer_name
        }
        
        if(typeof this.props.updateCustomer === 'function')
            this.props.updateCustomer(params)
    }
    updateCustomerCity = () => {
        const {leadDetails} = this.state;
        if(leadDetails.customer_city_id){
            const params = {
                id : leadDetails.customer_id,
                customer_city_id : leadDetails.customer_city_id
            }
            if(typeof this.props.updateCustomer === 'function')
                this.props.updateCustomer(params)
        }
    }
    getSourceName = (leadDetailData) =>{
        let sourceStr='';
        if(Number(leadDetailData['source_id']) === 131) {
            sourceStr = 'Knowlarity';
        } else if(Number(leadDetailData['source_id']) === 371) {
            sourceStr = 'BTL';
        } else {
            sourceStr = 'Dealer';
        }
        if(leadDetailData['organization']) {
            sourceStr += ' | '+(leadDetailData['organization']);
        }
        return sourceStr;
    }
      
    render() {
        const {leadDetails,cityDetails } = this.state;
        leadDetails.customer_city_id = (leadDetails.customer_city_id && leadDetails.customer_city_id !== '') ? leadDetails.customer_city_id : (leadDetails.reg_place_city_id || '')
        const {chatSourceArr,CLOSED_GAADI_INDIVIDUAL,CLOSED_NON_GAADI_INDIVIDUAL,CLOSED_NON_GAADI_DEALER} = config.constants;
        return (
            
            <div>
                <div className="main_details sticky mrg-b0">
                    
                    <div className="container-fluid">
                        <ul className="maindetail-list clearfix">
                            <li className="maindetail-item">
                                <h3 className="subheading">{this.props.t('leadInfo.customerName')}</h3>
                                <div>
                                <input className="name-input"
                                    id="customer_name"
                                    type="text"
                                    
                                    name="customer_name"
                                    autoComplete="off"
                                    value={leadDetails.customer_name || ''}
                                    label="Customer Name"
                                    onChange={this.handleChange.bind(this,"customer_name")}
                                    onBlur={this.updateCustomeName.bind(this)}
                                />
                                </div>
                            </li>
                            <li className="maindetail-item">
                                <h3 className="subheading">{this.props.t('leadInfo.leadId')}</h3>
                                <div>
                                <span>{leadDetails.id}</span>
                                </div>
                            </li>
                            <li className="maindetail-item">
                                <h3 className="subheading">{this.props.t('leadInfo.customerMobile')}</h3>
                                <div>
                                <span>{leadDetails.customer_mobile}</span>
                                </div>
                            </li>
                            <li className="maindetail-item">
                                <h3 className="subheading">{this.props.t('leadInfo.source')}</h3>
                                <div>{(Number(leadDetails['source_id']) === 131 || Number(leadDetails['source_id']) === 7 || (Number(leadDetails['source_id']) === 371 && leadDetails['organization']))? this.getSourceName(leadDetails) : 
                                (this.roleId === 3 || this.roleId === 4) ? leadDetails['friendly_name']:
                                (leadDetails.tempLead) ?
                                <span> 
                                <Select
                                    id="chat_source"
                                    name="chat_source"
                                    onChange={this.handleChange.bind(this,"chat_source")}
                                    options={chatSourceArr}
                                    value={chatSourceArr.filter((chat) =>chat.value === leadDetails.source_id)}
                                    
                                /> {leadDetails['sub_source_name']}</span>:(leadDetails['source_name'] || '')+'|'+(leadDetails['sub_source_name'] || '')}</div>
                            </li>
                            <li className="maindetail-item">
                                <h3 className="subheading">{this.props.t('leadInfo.status')}</h3>
                                <div>
                                    <span>{(leadDetails.status_name || '')} | {([CLOSED_GAADI_INDIVIDUAL,CLOSED_NON_GAADI_INDIVIDUAL,CLOSED_NON_GAADI_DEALER].indexOf(leadDetails.sub_status_id) !== -1) ?
                                    'Purchased Used Car' : (leadDetails['sub_status_name'] || '')}</span>
                                    {/* <span className="label blue-status mrg-r5">{leadDetails.status_name}</span>
                                    <span className="label yellow-status">{leadDetails.sub_status_name}</span> */}
                                </div>
                            </li>
                            <li className="maindetail-item">
                                <h3 className="subheading">{this.props.t('leadInfo.interestedIn')}</h3>
                                <div className="sub-value">
                                    <ul className="sub-value-list">
                                        {leadDetails.make_name ? <li>{leadDetails.make_name}</li> :''}
                                        {leadDetails.model_name ? <li>{leadDetails.model_name}</li> : ''}
                                    </ul>
                                </div>                            
                            </li>
                            <li className="maindetail-item">
                                <h3 className="subheading">{this.props.t('leadInfo.dialer_status')}</h3>
                                <div>
                                    <span>{leadDetails.lead_dialer_history_call_status}</span>
                                </div>                          
                            </li>
                            <li className="maindetail-item">
                                <h3 className="subheading">{this.props.t('leadInfo.city')} 
                                    {/* <ToolTip  title="Choose city where customer lives" > */}
                                        <i className="ic-error" title="Choose city where customer lives"></i>
                                    {/* </ToolTip> */}
                                </h3>
                                <div className="cityuserleave">
                                
                                    <Select
                                        id="city"
                                        name="customer_city_id"
                                        onChange={this.handleChange.bind(this,"customer_city_id")}
                                        options={cityDetails}
                                        value={cityDetails.filter((city) =>city.value === leadDetails.customer_city_id)}
                                        onBlur={this.updateCustomerCity.bind(this)}
                                        
                                    />
                                </div>                            
                            </li>
                            <li className="maindetail-item">
                                <h3 className="subheading">{this.props.t('leadInfo.score')}</h3>
                                <div>
                                <span>{(leadDetails.score) ? parseFloat(leadDetails.score).toFixed(2) : 0}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    // leadList: state.lead.listLead,
    cityDetails:state.lead.cityStateList
    
});

export default withTranslation('lead') (connect(mapStateToProps)(LeadInfo));
