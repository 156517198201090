import React from 'react';
import InputField from '../../elements/InputField';
// import logoub from "../../assets/images/logo-ub-ph.png";
import { connect } from 'react-redux';
import { SourceActions, InventoryActions, LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import CheckBox from './../../elements/CheckBox';
import * as helper from '../../../config/helper';
import { default as config } from './../../../config/config';

class AddLead extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = this.initialState();
        this.multiselectRef = React.createRef();

    }

    initialState = () => {
        return {
            selectedOption: null,
            formFields: {
                userName: '',
                userEmail: '',
                userMobile: '',
                city: '',
                source: '',
                subSource: '',
                isPremium: true,
            },
            errors:{},
            submitted: false,
            cityListData: [],
            sourceListData: [],
            subSourceListData: [],
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.listCity();
        this.listSource();
    }

    listCity = () => {
        if(!this.props.cityListData.data){
            this.props.actions.listCity();
        }
    }

    listSource = () => {
        if(!this.props.sourceListData.data){
            this.props.actions.sourceListData({status:'1'});
        }
    }

    listSubSource = async (sourceId) => {
        await this.props.actions.subSourceListData({"source_id": sourceId, status: '1'}).then(d => {
            if(d.status === 200){
                let subSourceListArr = helper.getDataInKeyValueFormat(d.data,'');
                this.setState({subSourceListData: subSourceListArr || []});
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Update Sub Source Select box
     */
    updateSubSourceSelect(type, sourceId) {
        this.setState({subSourceListData: []});
        if(type === 'remove'){
            this.setState({formFields: {...this.state.formFields, subSource:''}})
        }else{
            this.listSubSource(sourceId);
        }
    }

    /**
     * Input Handler
     * @param e (synthatic element)
     */
    handleInputChange = type =>async (e) => {
        let formFields = this.state.formFields;
        formFields[type] = (type === 'isPremium') ? !this.state.formFields.isPremium : (e ? ((!e.target) ? e: e.target.value) : '');
        
        
        await this.setState({
            formFields
        });
    
        if(type === 'source'){
            if(!e){
                this.updateSubSourceSelect('remove', '');
            }else{
                this.updateSubSourceSelect('add', this.state.formFields.source.value);
            }
        }
    }

    /**
     * Submit Handler
     */    
    onSubmit = async (e) => {
        e.preventDefault();
        await this.setState({ submitted: true });
        if (await this.validateForm()) {
            let saveLeadArr = {};
            saveLeadArr.customer_name       = this.state.formFields.userName;
            if(this.state.formFields.userEmail){
                saveLeadArr.customer_email  = this.state.formFields.userEmail;
            }
            if(this.state.formFields.city.value){
                saveLeadArr.city_id = this.state.formFields.city.value;
            }
            saveLeadArr.customer_mobile     = this.state.formFields.userMobile;
            saveLeadArr.source_id           = this.state.formFields.source.value;
            saveLeadArr.sub_source_id       = this.state.formFields.subSource.value;
            saveLeadArr.is_premium          = (this.state.formFields.isPremium) ? 1 : 0;

            this.props.actions.addLead(saveLeadArr).then(res=>{
                if(res.status === 200){
                    this.setState({ submitted: false, formFields: {userName: '', userEmail: '', userMobile: '', city: '', source:'', subSource: '', isPremium: true} });
                }else{
                    this.setState({ submitted: false });
                }
            });
        }
    }

    /**
     * Form Validations
     */

    validateForm = async function() {
        
        let fields = this.state.formFields;
        let errors = {};
        let formIsValid = true;
        let emailRegex = new RegExp(/\S+@\S+\.\S+/); //eslint-disable-line
        
        if (!fields["userName"].trim()) {
            formIsValid = false;
            errors["userName"] = this.props.t('addLead.username_required');
        }
        
        // if(!fields["userEmail"].trim()){
        //     formIsValid = false;
        //     errors["userEmail"] = this.props.t('addLead.email_required');
        // } else
         if(fields["userEmail"].trim() && !emailRegex.test(fields['userEmail'])){
            formIsValid = false;
            errors["userEmail"] = this.props.t('addLead.email_invalid');
        }

        if (!fields["userMobile"].trim()) {
            formIsValid = false;
            errors["userMobile"] = this.props.t('addLead.mobile_required');
        } else if((!fields["userMobile"].startsWith(config.constants.ISD_CODE[0]['startsFrom']) || fields["userMobile"].length < config.constants.ISD_CODE[0]['minLength'] || fields["userMobile"].length > config.constants.ISD_CODE[0]['maxLength'])){
            formIsValid = false;
            errors["userMobile"] = this.props.t('addLead.mobile_invalid');
        }

        if (fields["source"] === '') {
            formIsValid = false;
            errors["source"] = this.props.t('addLead.source_required');
        }

        if (fields["subSource"] === '') {
            formIsValid = false;
            errors["subSource"] = this.props.t('addLead.sub_source_required');
        }

        // if (fields["city"] === '') {
        //     formIsValid = false;
        //     errors["city"] = this.props.t('addLead.city_required');
        // }

        await this.setState({
            errors: errors,
            submitted: formIsValid
        });

        return formIsValid;
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {

        let cityListArr = prevState.cityListData.data || [];
        let sourceListArr = prevState.sourceListData.data || [];

        if(Object.keys(nextProps.cityListData).length !== Object.keys(prevState.cityListData).length){
            if(nextProps.cityListData.status === 200) {
                nextProps.cityListData.data && nextProps.cityListData.data.city && nextProps.cityListData.data.city.forEach(el => {
                    cityListArr.push({"value": el.id, "label": el.name})
                })
            }
        }

        if(Object.keys(nextProps.sourceListData).length && nextProps.sourceListData.status === 200) {
            let sourceData = nextProps.sourceListData.data;
            sourceListArr = helper.getDataInKeyValueFormat(sourceData,'');
        }

        return ({cityListData: cityListArr, sourceListData: sourceListArr})
    }

    render() {
        const { submitted, cityListData, sourceListData, subSourceListData } = this.state;
        const { userName, userEmail, userMobile, city, source, subSource, isPremium } = this.state.formFields;
        return (

            <div className="container-fluid">
                <h1>{this.props.t('addLead.title')}</h1>                
                <div className="card">
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className={'col-sm-4 form-group' + (!submitted && this.state.errors.userName ? ' has-error' : '')}>
                                    <InputField
                                        inputProps={{
                                            id: "user_name",
                                            type: "text",
                                            name: "userName",
                                            label: this.props.t('addLead.userName'),
                                            placeholder: this.props.t('addLead.enter_userName'),
                                            value: userName,
                                            validationreq: 'yes',
                                            validation_error: this.state.errors.userName
                                        }}
                                        onChange={this.handleInputChange('userName')}
                                        />
                                        {/* {!submitted && this.state.errors.userName && <div className="error show">{this.state.errors.userName}</div> } */}
                                </div>

                                <div className={'col-sm-4 form-group' + (!submitted && this.state.errors.userEmail ? ' has-error' : '')}>
                                    <InputField
                                        inputProps={{
                                            id: "userEmail",
                                            type: "userEmail",
                                            name: "userEmail",
                                            label: this.props.t('addLead.email'),
                                            placeholder: this.props.t('addLead.enter_email'),
                                            value: userEmail,
                                            // validationreq: 'yes'
                                            validation_error: this.state.errors.userEmail
                                        }}
                                        onChange={this.handleInputChange('userEmail')}
                                        />
                                        {/* {!submitted && this.state.errors.userEmail && <div className="error show">{this.state.errors.userEmail}</div> } */}
                                </div>

                            <div className={'col-sm-4 form-group' + (!submitted && this.state.errors.userMobile ? ' has-error' : '')}>
                                <InputField
                                    inputProps={{
                                        id: "user_mobile",
                                        type: "text",
                                        name: "userMobile",
                                        label: this.props.t('addLead.mobile'),
                                        placeholder: this.props.t('addLead.enter_userMobile'),
                                        value: userMobile,
                                        validationreq: 'yes',
                                        validation_error: this.state.errors.userMobile

                                    }}
                                    onChange={this.handleInputChange('userMobile')}
                                    />
                                    {/* {!submitted && this.state.errors.userMobile && <div className="error show">{this.state.errors.userMobile}</div> } */}
                            </div>
                            
                            </div>
                            <div className="row">
                                <div className={'col-sm-4 form-group' + (!submitted && this.state.errors.city ? ' has-error' : '')}>
                                <label>{this.props.t('addLead.city')}</label>
                                    <Select
                                        id="city"
                                        value={city}
                                        onChange={this.handleInputChange('city')}
                                        options={cityListData}
                                        isClearable={true}
                                        
                                    />
                                        {!submitted && this.state.errors.city && <div className="error show">{this.state.errors.city}</div> }
                                </div>

                                <div className={'col-sm-4 form-group' + (!submitted && this.state.errors.source ? ' has-error' : '')}>
                                    <label>{this.props.t('addLead.source')} <span className="required" style={{ color: "red" }}>*</span></label>
                                    <Select
                                        id="source"
                                        options={sourceListData} // Options to display in the dropdown
                                        value={source} // Preselected value to persist in dropdown
                                        onChange={this.handleInputChange('source')}
                                        
                                    />
                                        {!submitted && this.state.errors.source && <div className="error show">{this.state.errors.source}</div> }
                                </div>

                                <div className={'col-sm-4 form-group' + (!submitted && this.state.errors.subSource ? ' has-error' : '')}>
                                    <label>{this.props.t('addLead.sub_source')} <span className="required" style={{ color: "red" }}>*</span></label>
                                    <Select
                                        id="subSource"
                                        options={subSourceListData} // Options to display in the dropdown
                                        value={subSource} // Preselected value to persist in dropdown
                                        onChange={this.handleInputChange('subSource')}
                                        
                                    />
                                        {!submitted && this.state.errors.subSource && <div className="error show">{this.state.errors.subSource}</div> }
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <CheckBox type="checkbox" checked={isPremium}  onChange={ this.handleInputChange('isPremium') }  name="isPremium" id="isPremium" label={this.props.t('addLead.premium')}></CheckBox>
                                </div>
                            </div>
                            <div>
                                
                            </div>
                            <div className="row">
                                <div className=" col-sm-12 form-group text-center">
                                    {
                                        !submitted 
                                        ?
                                        <button type="button" onClick={this.onSubmit} className="btn btn-primary">{this.props.t('addLead.save')}</button>
                                        :
                                        <button type="button" className="btn btn-primary">{this.props.t('addLead.please_wait')}</button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        cityListData: state.inventory.cityListData || {},
        sourceListData: state.source.sourceListData || {},
        subSourceListData: state.source.subSourceListData || {},

    }
 }

const mapDispatchToProps = dispatch => {
    return {
               actions: {
                    listCity: bindActionCreators(
                        InventoryActions.listCity,
                        dispatch
                    ),
                    sourceListData: bindActionCreators(
                        SourceActions.listSource,
                        dispatch
                    ),subSourceListData: bindActionCreators(
                        SourceActions.listSubSource,
                        dispatch
                    ),
                    addLead: bindActionCreators(
                        LeadActions.addLead,
                        dispatch
                    )
               }
            }
}

export default withTranslation('lead') (withRouter(connect(mapStateToProps, mapDispatchToProps)(AddLead)));