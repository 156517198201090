import React, { Component } from 'react';
import CityService from './../../../../service/cityService';
// import SearchCity from './searchCity';
import SearchResult from './searchResult';
import { withTranslation } from 'react-i18next';
import {debounce} from 'throttle-debounce';
import { toast } from 'react-toastify';
import _ from 'lodash';

class CityList extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            pageloading:true,
            cityListData: [],
            searchByList: [],
            page: 0,
            filterData: {
                
            },
            redirect: false
        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.getFilterList = debounce(500, this.getFilterList);
        this.updateStatus = this.updateStatus.bind(this);
    }

    onScroll(event) {
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            if (this.flag) {
                let current_page = this.state.page;
                let loading=false,pageloading=false;
                if(current_page===0) {
                    pageloading = true;
                }else{
                    loading = true;
                }
                if(this._isMounted){
                    this.setState({ page: current_page + 1,loading:loading,pageloading:pageloading}, () => {
                        this.getCityList();
                    });
                }
            }
            this.flag = false;
        }
    }
    
    getCityList(updatedStatus) {
        var thisObj = this;
        var filterData = thisObj.state.filterData;
        filterData.page_number = (thisObj.state.page === 0) ? 1 : thisObj.state.page;
        thisObj.setState({page:filterData.page_number,loading:true})
        CityService.getCityState(filterData)
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({loading:false,pageloading:false})
                if (response.data.status === 200) {
                    if (response.data.data['city'].length) {
                        let cityList = response.data.data['city'];
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ cityListData: cityList ,loading:false,pageloading:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                        else {
                            thisObj.setState({
                                cityListData: thisObj.state.cityListData.concat(cityList)
                            ,loading:false,pageloading:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                    } else {
                        if(updatedStatus){
                            let listData = this.state.cityListData;
                            let matchedIndex = _.findIndex(listData, function(data) { return data.id === updatedStatus.id })
                            if(matchedIndex !== -1){
                                listData[matchedIndex] = updatedStatus;
                                thisObj.setState({cityListData:listData})
                            }
                        }
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ cityListData: []});
                        }
                    }
                }
            })
            .catch(function (response) {
                thisObj.setState({loading:false,pageloading:false})
            });
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.onScroll('Mounter');
        // window.addEventListener('scroll', this.onScroll);
        await this.getCityList();
    }

    handleNameChange = async (searchParams) => {
        let validParams = this.validateSearchParams(searchParams);
        this.setState({ filterData:validParams });
        await this.getFilterList();
      
    }

    validateSearchParams(filterData){
        let returnFilter={};
        if(filterData.page_number)
            returnFilter.page_number = filterData.page_number;
        if(filterData.name){
            returnFilter.name = filterData.name;
        }
        if(filterData.status !== ''){
            returnFilter.status = filterData.status;
        }
        return returnFilter;
      
    }

    getFilterList = async (searchParams) => {
        this.setState({page:1})
        await this.getCityList();
    }

    submitFilterForm = () => {
        this.setState({page:1});
    }

    updateStatus(data){
        let params={};
        let thisObj=this;
        params.id=data.id;
        data.status = params.status = (data.status === '1') ? '0' : '1';
        CityService.updateStatus(params)
            .then(function (response) {
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    thisObj.getCityList(data);
                }else{
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });

    }

    render() {
        return (
            <div className="container-fluid">
                <div className="result-wrap">
                    <div className="card">
                        {/* <SearchCity  filterNameChange={this.handleNameChange} onSubmitFilter={this.submitFilterForm}/> */}
                        <div className="clearfix"/>
                        <SearchResult pageloading={this.state.pageloading} loading={this.state.loading} cityListData={this.state.cityListData} onUpdateStatus={this.updateStatus}/>
                        
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }
}

export default withTranslation('city') (CityList);
