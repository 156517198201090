import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import * as helper from '../../../../config/helper';

class SalesFunnelResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResult: props.searchResult,
            totalRecord: props.totalRecord,
            loading: false,
            pageloading: false,
            totalCalculationObject: props.totalCalculationObject,
            isCsvDownload:false,
            action:''
        }
    }
    static getDerivedStateFromProps(nextprops, prevState) {
        var updatedObj = {}
        if (prevState.searchResult !== nextprops.searchResult) {
            updatedObj['searchResult'] = nextprops.searchResult;
        }
        if (prevState.totalRecord !== nextprops.totalRecord) {
            updatedObj['totalRecord'] = nextprops.totalRecord;
        }
        if (nextprops.loading !== prevState.loading) {
            updatedObj['loading'] = nextprops.loading;
        }

        if (nextprops.pageloading !== prevState.pageloading) {
            updatedObj['pageloading'] = nextprops.pageloading;
        }
        if (nextprops.totalCalculationObject !== prevState.totalCalculationObject) {
            updatedObj['totalCalculationObject'] = nextprops.totalCalculationObject;
        }
        if(prevState.isCsvDownload && !nextprops.importingData){
            updatedObj['isCsvDownload'] = nextprops.importingData;
        }
        return updatedObj;
    }
    reportDetail = async (argument) => {
        if(!this.state.isCsvDownload){
            this.props.exportCsv(argument); 
            this.setState({isCsvDownload: true})
        }
            
    }
    

    render() {
        const { isCsvDownload } = this.state;
        let spanLinkCls = 'span-link';
        //Remove link class when downloading is in process
        if(isCsvDownload){
            spanLinkCls = ''
        }

        let allUnique = 0, allTotalAttempted = 0, allContacted = 0, allVerified = 0, allXsell = 0, allWalkinSchedule = 0, allWalkinDone = 0, allClosed = 0, allLostPurchased = 0, allPurchased = 0
        return (
            <div className="result-wrap">
                <div className="card">
                    <div className="card-heading">
                        <div className="row">
                            <div className="col-sm-4">
                                <h2 className="card-title pad-t7"> {this.state.totalRecord+' '+this.props.t('salesFunnel.resultsFound')}</h2>
                            </div>
                            <div className="col-sm-6">
                                <h4 className="card-title downloader-loading pad-t7 text-right"> 
                                    {
                                        isCsvDownload
                                        ?
                                            <React.Fragment>
                                                <span className="l1calling-results loading" ></span> 
                                                <span>{this.props.t('l1Calling.processing')}</span>
                                            </React.Fragment>
                                        :
                                            ''
                                    }
                                </h4>
                            </div>
                            <div className="col-sm-2 text-right" >
                           {(this.state.searchResult && this.state.searchResult.length !== 0) ?
                                (this.state.isCsvDownload)? 
                                <button  className="btn btn-success mrgin-r-10"  disabled >{this.props.t('please_wait')}</button>
                                :
                                <button  onClick={() => this.reportDetail({export_all:1})} className="btn btn-success mrgin-r-10"  >{this.props.t('salesFunnel.export_csv')}</button>
                                
                                : '' }
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover table-striped deliveryTable">
                            <thead>
                                <tr>
                                    {/* <th>{this.props.t('salesFunnel.cluster')}</th>
                                        <th>{this.props.t('salesFunnel.city')}</th> */}
                                    <th>{this.props.t('salesFunnel.source')}</th>
                                    <th>{this.props.t('salesFunnel.subSource')}</th>
                                    <th>{this.props.t('salesFunnel.category')}</th>
                                    <th>{this.props.t('salesFunnel.totalUnique')}</th>
                                    <th>{this.props.t('salesFunnel.totalAttempted')}</th>
                                    {/* <th>{this.props.t('salesFunnel.eligibleForCalling')}</th> */}
                                    <th>{this.props.t('salesFunnel.contacted')}</th>
                                    <th>{this.props.t('salesFunnel.verified')}</th>
                                    <th>{this.props.t('salesFunnel.xsell')}</th>
                                    <th>{this.props.t('salesFunnel.walkinScheduled')}</th>
                                    <th>{this.props.t('salesFunnel.walkinDone')}</th>
                                    <th>{this.props.t('salesFunnel.closed')}*</th>
                                    <th>{this.props.t('salesFunnel.lostPurchased')}*</th>
                                    <th>{this.props.t('salesFunnel.purchased')}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {(this.state.searchResult.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="15"><h6 className="text-center text-danger text-bold">{this.props.t('salesFunnel.NoRecordFound')}</h6></td></tr> : null}


                                {(this.state.searchResult && this.state.searchResult.length !== 0) && this.state.searchResult.map((reportData, k) => {
                                    //TOTAL UNIQUE 
                                    allUnique +=  reportData.total_unique

                                    //ATTEMPTED %age
                                    let attemptedPercent = !isFinite((100 * reportData.total_attempted / reportData.total_unique)) ? 0 : (100 * reportData.total_attempted / reportData.total_unique).toFixed(2);

                                    //CONTACTED COUNT
                                    let totalContacted = reportData.contacted ? reportData.contacted : 0;
                                    // totalContacted = totalContacted.length;

                                    allContacted +=  totalContacted

                                    //ATTEMPTED COUNT
                                    allTotalAttempted += reportData.total_attempted

                                    let contactedPercent = !isFinite((100 * totalContacted / reportData.total_attempted)) ? 0 : (100 * totalContacted / reportData.total_attempted).toFixed(2);

                                    //VERIFIED COUNT
                                    let totalVerified = reportData.verified ? reportData.verified : 0;
                                    // totalVerified = totalVerified.length;

                                    allVerified +=  totalVerified

                                    let verifiedPercent = !isFinite((100 * totalVerified / totalContacted)) ? 0 : (100 * totalVerified / totalContacted).toFixed(2)

                                    //XSELL COUNT
                                    let totalXsell = reportData.xsell ? reportData.xsell : 0;
                                    // totalXsell = totalXsell.length;

                                    allXsell += totalXsell

                                    let xsellPercent = !isFinite((100 * totalXsell / totalVerified)) ? 0 : (100 * totalXsell / totalVerified).toFixed(2)

                                    //WALKIN SCHEDULE COUNT
                                    let totalWalkinSchedule = reportData.walk_in_schedule ? reportData.walk_in_schedule : 0;
                                    // totalWalkinSchedule = totalWalkinSchedule.length;

                                    allWalkinSchedule +=  totalWalkinSchedule

                                    let walkinSchedulePercent = !isFinite((100 * totalWalkinSchedule / totalVerified)) ? 0 : (100 * totalWalkinSchedule / totalVerified).toFixed(2)

                                    //WALKIN DONE COUNT
                                    let totalWalkinDone = reportData.walk_in_done ? reportData.walk_in_done : 0;
                                    // totalWalkinDone = totalWalkinDone.length;

                                    allWalkinDone +=  totalWalkinDone

                                    let walkinDonePercent = !isFinite((100 * totalWalkinDone / totalWalkinSchedule)) ? 0 : (100 * totalWalkinDone / totalWalkinSchedule).toFixed(2)

                                    //CLOSED COUNT

                                    let totalClosed = reportData.closed ? reportData.closed : 0;
                                    // totalClosed = totalClosed.length;

                                    allClosed +=  totalClosed

                                    let closedPercent = !isFinite((100 * totalClosed / reportData.total_attempted)) ? 0 : (100 * totalClosed / reportData.total_attempted).toFixed(2)

                                    //LOST PURCHASED COUNT
                                    let totalLostPurchased = reportData.lost_purchased ? reportData.lost_purchased : 0;
                                    // totalLostPurchased = totalLostPurchased.length;

                                    allLostPurchased +=  totalLostPurchased

                                    let lostPurchasedPercent = !isFinite((100 * totalLostPurchased / totalClosed)) ? 0 : (100 * totalLostPurchased / totalClosed).toFixed(2)

                                    //PURCHASED COUNT
                                    let totalPurchased = reportData.purchased ? reportData.purchased : 0;
                                    // totalPurchased = totalPurchased.length;

                                    allPurchased +=  totalPurchased

                                    let purchasedPercent = !isFinite((100 * totalPurchased / totalWalkinDone)) ? 0 : (100 * totalPurchased / totalWalkinDone).toFixed(2)

                                    return (<tr key={k}>
                                        {/* <td>{reportData.cluster_name}</td>
                                            <td>{reportData.city_name}</td> */}
                                        <td>{reportData.source_name}</td>
                                        <td>{helper.subSourceNames[reportData.sub_source_name]}</td>
                                        <td>{reportData.category}</td>
                                        <td>{reportData.total_unique > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({ source_id: reportData.source_id, leads_sub_source: reportData.sub_source_name, priority: reportData.priority, car_user_type: reportData.car_user_type })}>{reportData.total_unique}</span> : reportData.total_unique }</td>

                                        <td>{reportData.total_attempted > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({ source_id: reportData.source_id, leads_sub_source: reportData.sub_source_name, priority: reportData.priority, car_user_type: reportData.car_user_type, call_attempt: 1 })}>{reportData.total_attempted} </span> : reportData.total_attempted } ({attemptedPercent}%) </td>

                                        <td>{totalContacted > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({ source_id: reportData.source_id, leads_sub_source: reportData.sub_source_name, priority: reportData.priority, car_user_type: reportData.car_user_type, status_id: 2 })}>{totalContacted}</span> : totalContacted } ({contactedPercent}%) </td>

                                        <td>{totalVerified >0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({ source_id: reportData.source_id, leads_sub_source: reportData.sub_source_name, priority: reportData.priority, car_user_type: reportData.car_user_type, status_id: 3 })}>{totalVerified}</span> : totalVerified } ({verifiedPercent}%) </td>

                                        <td>{totalXsell > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({ source_id: reportData.source_id, leads_sub_source: reportData.sub_source_name, priority: reportData.priority, car_user_type: reportData.car_user_type, status_id: 3, xsell:1 })}>{totalXsell}</span> : totalXsell} ({xsellPercent}%) </td>

                                        <td>{totalWalkinSchedule > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({ source_id: reportData.source_id, leads_sub_source: reportData.sub_source_name, priority: reportData.priority, car_user_type: reportData.car_user_type, status_id: 5, sub_status_id: 9 })}>{totalWalkinSchedule}</span> : totalWalkinSchedule} ({walkinSchedulePercent}%) </td>

                                        <td>{totalWalkinDone >0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({ source_id: reportData.source_id, leads_sub_source: reportData.sub_source_name, priority: reportData.priority, car_user_type: reportData.car_user_type, status_id: 5, sub_status_id: 38 })}>{totalWalkinDone}</span> : totalWalkinDone} ({walkinDonePercent}%) </td>

                                        <td>{totalClosed > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({ source_id: reportData.source_id, leads_sub_source: reportData.sub_source_name, priority: reportData.priority, car_user_type: reportData.car_user_type, status_id: 7})}>{totalClosed}</span> : totalClosed} ({closedPercent}%) </td>

                                        <td>{totalLostPurchased > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({ source_id: reportData.source_id, leads_sub_source: reportData.sub_source_name, priority: reportData.priority, car_user_type: reportData.car_user_type, status_id: 7, sub_status_id: [15, 16, 17, 20, 36] })}>{totalLostPurchased}</span> : totalLostPurchased} ({lostPurchasedPercent}%) </td>

                                        <td>{(totalPurchased > 0) ? <span className={spanLinkCls} onClick={() => this.reportDetail({ source_id: reportData.source_id, leads_sub_source: reportData.sub_source_name, priority: reportData.priority, car_user_type: reportData.car_user_type, status_id: 6, sub_status_id: 12 })}>{totalPurchased}</span> : totalPurchased } ({purchasedPercent}%) </td>

                                        {/* <td>
                                            {(reportData.eligibleDialerLead) ? reportData.eligibleDialerLead : ''}
                                                {(reportData.UniqueLead && reportData.eligibleDialerLead) ? ' ('+((reportData.eligibleDialerLead/reportData.UniqueLead)*100).toFixed(2)+'%)' : ''}</td> */}
                                        {/* <td> {(reportData.dialedLead) ? reportData.dialedLead : ''}
                                                {(reportData.dialedLead && reportData.totalLead) ?  ' ('+((reportData.dialedLead/reportData.totalLead)*100).toFixed(2)+'%)' : ''}</td>
                                            <td>{(reportData.totalContacted) ? reportData.totalContacted : ''}
                                                {(reportData.totalContacted && reportData.totalLead) ? ' ('+((reportData.totalContacted/reportData.totalLead)*100).toFixed(2)+'%)' : ''}</td>
                                            <td>
                                            {(reportData.totalVerified) ? reportData.totalVerified : ''}
                                                {(reportData.totalVerified && reportData.totalContacted) ? ' ('+((reportData.totalVerified/reportData.totalContacted)*100).toFixed(2)+'%)' : ''}</td>
                                            <td>
                                            {(reportData.totalWalinSchd) ? reportData.totalWalinSchd : ''}
                                                {(reportData.totalVerified && reportData.totalWalinSchd) ? '('+((reportData.totalWalinSchd/reportData.totalVerified)*100).toFixed(2)+'%)' : ''}</td>
                                            <td>
                                            {(reportData.totalWalinDone) ? reportData.totalWalinDone : ''}
                                                {(reportData.totalWalinDone && reportData.totalWalinSchd) ? ' ('+((reportData.totalWalinDone/reportData.totalWalinSchd)*100).toFixed(2)+'%)' : ''}</td>
                                            <td>
                                            {(reportData.totalConversion) ? reportData.totalConversion : ''}
                                                {(reportData.totalWalinDone && reportData.totalConversion) ? ' ('+((reportData.totalConversion/reportData.totalWalinDone)*100).toFixed(2)+'%)' : ''}</td>
                                            <td>{(reportData.totalConfConversion) ? (reportData.totalConfConversion) : ''}</td> */}
                                    </tr>)
                                }
                                )}
                                {
                                    (this.state.loading) ? <tr><td className="loading" colSpan="15"></td></tr> : null
                                }

                                {(this.state.searchResult.length) ?
                                    <tr className="totalvalue">
                                        <td>{this.props.t('salesFunnel.total')}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{allUnique > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail()}> {allUnique} </span> : allUnique } </td>
                                        <td>{allTotalAttempted > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({call_attempt: 1})}>{allTotalAttempted}</span> : allTotalAttempted } ({(allUnique ? (100 * allTotalAttempted / allUnique).toFixed(2) : 0)}%)</td>
                                        <td>{allContacted > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({status_id: 2})}>{allContacted}</span> : allContacted} ({(allTotalAttempted ? (100 * allContacted / allTotalAttempted).toFixed(2) : 0)}%)</td>

                                        <td>{allVerified > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({status_id: 3})}>{allVerified}</span> : allVerified} ({(allContacted ? (100 * allVerified / allContacted).toFixed(2) : 0)}%)</td>

                                        <td>{allXsell > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({status_id: 3, xsell:1})}>{allXsell}</span> : allXsell} ({(allVerified ? (100 * allXsell / allVerified).toFixed(2) : 0)}%)</td>

                                        <td>{allWalkinSchedule > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({status_id: 5, sub_status_id: 9})}>{allWalkinSchedule}</span> : allWalkinSchedule} ({(allVerified ? (100 * allWalkinSchedule / allVerified).toFixed(2) : 0)}%)</td>

                                        <td>{allWalkinDone > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({status_id: 5, sub_status_id: 38})}>{allWalkinDone}</span> : allWalkinDone} ({(allWalkinSchedule ? (100 * allWalkinDone / allWalkinSchedule).toFixed(2) : 0)}%)</td>

                                        <td>{allClosed > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({status_id: 7})}> {allClosed} </span> : allClosed} ({(allTotalAttempted ? (100 * allClosed / allTotalAttempted).toFixed(2) : 0)}%)</td>

                                        <td>{allLostPurchased > 0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({status_id: 7, sub_status_id: [15, 16, 17, 20, 36]})}> {allLostPurchased} </span> : allLostPurchased} ({(allClosed ? (100 * allLostPurchased / allClosed).toFixed(2) : 0)}%)</td>

                                        <td>{allPurchased >0 ? <span className={spanLinkCls} onClick={() => this.reportDetail({status_id: 6, sub_status_id: 12})}> {allPurchased} </span> : allPurchased} ({(allWalkinDone ? (100 * allPurchased / allWalkinDone).toFixed(2) : 0)}%)</td>
                                        
                                    </tr> : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation('report')(SalesFunnelResult);
