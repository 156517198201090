import React, { Component } from 'react';
import InputField from '../../elements/InputField';
import CheckBox from '../../elements/CheckBox';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { InventoryActions, DealerActions, SourceActions, LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { default as config } from '../../../config/config';
import * as helper from '../../../config/helper';
import _  from 'lodash';
import { toast } from 'react-toastify';
import FilterBox from '../helpers/filter-box';

class TrackConversion extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);  
        this.state ={
            formData: {
                // is_registered_car: 1,
                searchMobile:'',
                customerName: '',
                customerMobile: '',
                customerEmail: '',
                customerCity: '',
                isPremium: '',
                carMake: '',
                carModel: '',
                carVariant: '',
                carRegistrationNo: '',
                carPurchasePrice: '',
                sellerCity: '',
                sellerDealer: '',
                trackingSource: '',
                trackingSubSource: '',
                trackingPurchaseDate: '',
                trackingTrackBy: '',
                trackingConversionSource: '',
                
            },
            filterFieldsData:[
                {name: 'searchMobile', placeholder:'search.placeholder.enter_mobile', label: 'leadInfo.customerMobile', type: 'input', disabled: false, value: '' },
            ],
            carMakeList: [],
            carModelList: [],
            dealerList: [],
            cityList: [],
            sellerCityList: [],
            carVariantList: [],
            sourceList: [],
            subSourceList:[],
            trackByOption: [],
            errors:[],

          selectedOption: null,
          hideAddCustomerBox: true,
          date: new Date(),
        }
        this.multiselectRef = React.createRef();
    }

    componentDidMount = async() => {
        this._isMounted = true;
        this.gerCarsFeature('make'); //CALL CAR MAKE/MODEL/VARIANT API
        
        this.listCity(); //CALL CITY API
        this.listDealers();// CALL DEALER API
        this.listSource(); //CALL SOURCE API
       
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    /**
     * Get City List
     */

    listCity = async () => {

        let cityList = this.props.cityList || [];
        if(!this.props.cityList){
            cityList = await this.props.actions.listCity();
        }
        
        let cityData = helper.getDataInKeyValueFormat((cityList['data'] && cityList['data']['city']),'');
        if(this._isMounted){
            this.setState({cityList: cityData, sellerCityList: cityData});
        }
    }

    /**
     * List Dealers
     */

    listDealers = async () => {
        let dealerList = this.props.listDealerData;
        //IF NOT IN STATE THEN CALL API
        if(!dealerList || (dealerList && !dealerList['data'])){
            let params = {};
            params['_with'] = ['id', 'organization'];

            dealerList = await this.props.actions.listDealer(params);
        }

        let getList = (dealerList && dealerList.data) ? helper.getDataInKeyValueFormat(dealerList.data, '') : [];
        if(this._isMounted){
            this.setState({dealerList: getList || []});
        }
        
    }

    handleChange =   param => async e => {
        let val = (param === 'isPremium') ? !this.state.formData.isPremium : (e ? ((!e.target) ? e: e.target.value) : '');
        //set date
        if(param === 'trackingPurchaseDate'){
            val = e ? await helper.dateFormat(e) : '';
        }
        this.updateStateFun(param, val); //update state

        const {carMake, carModel} = this.state.formData;
        if(param === 'trackingSource') {
            this.listSubSource(val['value']);
        }
        else if(param === 'carMake'){
            const newState = {...this.state.formData, carModel: {}, carVariant: {}};
            this.setState({formData: newState})
            this.gerCarsFeature({make_id: val['value'], model_id: carModel.value});
        }else if(param === 'carModel'){
            const newState = {...this.state.formData, carVariant: {}};
            this.setState({formData: newState})
            this.gerCarsFeature({make_id: carMake.value, model_id: val['value']});
        }
    }

    /**
     * Update state
     */
    updateStateFun = async (type, val) => {
        const updateState = this.state.formData;
        updateState[type] = (val) ? val : '';
        await this.setState({filters: updateState});
    }


    listSource = async () => {
        let getList = this.props.sourceListData || [];
        
        if(!this.props.sourceListData){
            let params = {isPaginationRequired: false, status: '1'}
            getList = await this.props.actions.sourceListData(params);    
        }

        getList = (getList['data'] && getList['data'].length) ? helper.getDataInKeyValueFormat(getList['data'], '') : [];        
        this.setState({sourceList: getList || []});
    }

    listSubSource = async (sourceId) => {
        if(sourceId){
            await this.props.actions.subSourceListData({"source_id": sourceId, status: '1'}).then(d => {
                if(d.status === 200){
                    let subSourceListArr = helper.getDataInKeyValueFormat(d.data,'');
                    
                    this.setState({subSourceList: subSourceListArr || []});
                }
            });
        }else{
            this.setState({subSourceList: []});
            this.updateStateFun('trackingSubSource', '')
        }
        
    }

    gerCarsFeature = async (obj) => {

        let makeModelVersionList = this.props.makeModelVersionList || [];

        if(!makeModelVersionList.make){
            makeModelVersionList = await this.props.actions.getCarVariant();
        }

        // await this.props.actions.getCarVariant(data).then(resp => {
            
            if(obj === 'make'  && makeModelVersionList.make){
                let carMakeData = helper.getDataInKeyValueFormat(makeModelVersionList.make,'');
                this.setState({carMakeList: carMakeData});
            }
            else if(obj.make_id || obj.model_id) {
                let tempModelObj=[];
                let tempVersionObj=[];
                //GET CAR MODEL FROM MAKE ID
                _.forEach((makeModelVersionList.model),(makeObj) => {
                        if(makeObj.mk_id === obj.make_id){
                            let tempObj = {};
                            tempObj.value = makeObj.id;
                            tempObj.label = makeObj.m;
                            tempModelObj.push(tempObj);
                        }
                });

                //GET VERSION FROM MODEL & MAKE ID
                _.forEach((makeModelVersionList.version),(makeObj) => {
                    if(makeObj.md_id === obj.model_id && makeObj.mk_id === obj.make_id ){
                        let tempObj = {};
                        tempObj.value = makeObj.vn_id;
                        tempObj.label = makeObj.vn;
                        tempVersionObj.push(tempObj);
                    }
                });
                if(this._isMounted){
                    this.setState({carModelList: tempModelObj, carVariantList: tempVersionObj});
                }
            }
    }

    submitHandler = async (e) => {
        e.preventDefault();
        await this.setState({ submitted: true });
        
        if (await this.validateForm()) {
            let saveLeadArr = {};

            saveLeadArr.customerName                = this.state.formData.customerName;
            saveLeadArr.customerEmail               = this.state.formData.customerEmail;
            saveLeadArr.customerMobile              = this.state.formData.customerMobile;
            saveLeadArr.customerCity                = this.state.formData.customerCity['value'] || 0;
            saveLeadArr.customerCityName            = this.state.formData.customerCity['label'];
            saveLeadArr.isPremium                   = (this.state.formData.isPremium) ? 1 : 2;
            saveLeadArr.carMakeId                   = this.state.formData.carMake['value'] || 0;
            saveLeadArr.carMakeName                 = this.state.formData.carMake['label'] || '';
            saveLeadArr.carModelId                  = this.state.formData.carModel['value'] || 0;
            saveLeadArr.carModelName                = this.state.formData.carModel['label'] || '';
            saveLeadArr.carVariantId                = this.state.formData.carVariant['value'] || 0;
            saveLeadArr.carVariantName              = this.state.formData.carVariant['label'] || '';
            saveLeadArr.carRegistrationNo           = this.state.formData.carRegistrationNo ;
            saveLeadArr.carPurchasePrice            = this.state.formData.carPurchasePrice ;
            saveLeadArr.sellerCityId                = this.state.formData.sellerCity['value'] || 0;
            saveLeadArr.sellerCityName              = this.state.formData.sellerCity['label'] || '';
            saveLeadArr.sellerDealerId              = this.state.formData.sellerDealer['value'] || 0;
            saveLeadArr.sellerDealerName            = this.state.formData.sellerDealer['label'] || '';
            saveLeadArr.trackingSource              = this.state.formData.trackingSource['value'] || 0;
            saveLeadArr.trackingSubSource           = this.state.formData.trackingSubSource['value'] || 0;
            saveLeadArr.trackingPurchaseDate        = this.state.formData.trackingPurchaseDate;
            saveLeadArr.trackingTrackBy             = '1'; //Temporarily set 1, will remove later //this.state.formData.trackingTrackBy;
            saveLeadArr.trackingConversionSource    = this.state.formData.trackingConversionSource['value'] || '';
            saveLeadArr.trackingConversionSourceType    = this.state.formData.trackingConversionSource['label'] || '';

            
            //SAVE CONVERSION ACTION DISPATCH
            this.props.actions.addConversion(saveLeadArr).then(res=>{
                    toast.success(res.message);
                    this.setState({ submitted: false, formData: 
                        {   customerName: '', customerEmail: '', customerMobile: '', customerCity: '', isPremium: '', carMake: '', carModel: '', carVariant: '', carRegistrationNo: '', carPurchasePrice: '', sellerCity: '', sellerDealer: '', trackingSource: '', trackingSubSource: '', trackingPurchaseDate: '', trackingTrackBy: '', trackingConversionSource: ''} });   
            });
        }
    }

    /**
     * Form Validations
     */

    validateForm = async function() {
        
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        
        if (!fields["customerName"]) {
            formIsValid = false;
            errors["customerName"] = this.props.t('addConversion.customername_required');
        }
        
        if (!fields["customerCity"]) {
            formIsValid = false;
            errors["customerCity"] = this.props.t('addConversion.customercity_required');
        }

        
        if(!fields["carMake"]){
            formIsValid = false;
            errors["carMake"] = this.props.t('addConversion.carmake_required');
        }

        if (fields["carModel"] === '') {
            formIsValid = false;
            errors["carModel"] = this.props.t('addConversion.carmodel_required');
        }

        if (fields["sellerCity"] === '') {
            formIsValid = false;
            errors["sellerCity"] = this.props.t('addConversion.seller_city_required');
        }

        if (fields["sellerDealer"] === '') {
            formIsValid = false;
            errors["sellerDealer"] = this.props.t('addConversion.seller_dealer_required');
        }

        if (fields["trackingConversionSource"] === '') {
            formIsValid = false;
            errors["conversionSource"] = this.props.t('addConversion.conversion_source_required');
        }

        await this.setState({
            errors: errors,
            submitted: formIsValid
        });
        
        return formIsValid;
    }

    /**
     * Search By Mobile
     */
    searchMobile = async(param) => {

        let searchMobile = param.searchMobile;
        let checkNumber = new RegExp(/^\d+$/);
        let errors = {};
        errors["searchMobile"] = '';//this.props.t('addConversion.search_mobile_required');
        //VALIDATE MOBILE
        if(!checkNumber.test(searchMobile)){
            errors["searchMobile"] = this.props.t('addConversion.search_mobile_required');
            await this.setState({
                errors: errors,
                submitted: false
            });
        }else{
            await this.setState({
                errors: errors,
                submitted: false
            });

            let searchResult = await this.props.actions.getCustomer({'customer_mobile': +searchMobile});
            
            if(searchResult.length){
                const updateState = this.state.formData;
                let customerData = searchResult[0];

                updateState.customerName    = customerData['name'] || '';
                updateState.customerMobile  = customerData['customer_mobile'] || '';
                updateState.customerCity  = (customerData['customer_city_id']) ? {value: customerData['customer_city_id'], label: customerData['customer_city_name']} : '';
                updateState.customerEmail  = customerData['customer_email'];

                await this.setState({formData: updateState, hideAddCustomerBox: false});
            }else{
                    toast.error(this.props.t('addConversion.customer_not_found'));
            }
        }
    }

    handleRequestData = async (data) => {
        // await this.setState({hideAddCustomerBox: true});
        if(data['typeof'] && data['typeof'] === 'addCustomer'){
            await this.setState({ hideAddCustomerBox: false});
        }else{
            this.searchMobile(data);
        }

    }

    render() {
        const { hideAddCustomerBox, carMakeList, carModelList, dealerList, cityList, sellerCityList, carVariantList, loading, submitted, sourceList, subSourceList, trackByOption, filterFieldsData } = this.state;  
        const { customerName, customerMobile, customerEmail, customerCity, carMake, carModel, carVariant, carRegistrationNo, carPurchasePrice, sellerCity, sellerDealer, trackingSource, trackingSubSource, trackingPurchaseDate, trackingTrackBy, trackingConversionSource } = this.state.formData;  
        const { conversionSource } = config.constants;

          return (
            <div>
                <div className="container-fluid">
                    <h1>{this.props.t('addConversion.title')}</h1>

                    <FilterBox filterBox={{filterFields: filterFieldsData, showAdvanceSearchToggle: false} } action='addConversion' searchResults={this.handleSearchedData} sendRequestDataToParent={this.handleRequestData} />

                    <div className={ hideAddCustomerBox ? 'hide card' :  'card'}>
                        <div className="card-body">
                            <h6>{this.props.t('addConversion.customer_detail')}</h6>
                            <div className="row mrg-b30">
                                <div className={'col-sm-3 form-group' + (!submitted && this.state.errors.customerName ? ' has-error' : '')}>

                                    <InputField
                                        inputProps={{
                                            id: "searchName",
                                            type: "text",
                                            placeholder:this.props.t('addConversion.customer_name'),
                                            name: "customerName",
                                            label:this.props.t('addConversion.customer_name'),
                                            value: customerName,
                                            validationreq: 'yes'
                                        }}
                                        onChange={this.handleChange('customerName')}
                                    />
                                        {!submitted && this.state.errors.customerName && <div className="error show">{this.state.errors.customerName}</div> }
                                </div>
                                <div className={'col-sm-3 form-group' + (!submitted && this.state.errors.customerMobile ? ' has-error' : '')}>

                                    <InputField
                                        inputProps={{
                                            id: "CustomerMobile",
                                            type: "text",
                                            placeholder:this.props.t('addConversion.customer_mobile'),
                                            name: "customerMobile",
                                            label:this.props.t('addConversion.customer_mobile'),
                                            value: customerMobile
                                        }}
                                        onChange={this.handleChange('customerMobile')}
                                    />
                                        {!submitted && this.state.errors.customerMobile && <div className="error show">{this.state.errors.customerMobile}</div> }

                                </div>
                                <div className={'col-sm-2 form-group' + (!submitted && this.state.errors.customerEmail ? ' has-error' : '')}>

                                    <InputField
                                        inputProps={{
                                            id: "CustomerEmail",
                                            type: "text",
                                            placeholder:this.props.t('addConversion.customer_email'),
                                            name: 'customerEmail',
                                            label: this.props.t('addConversion.customer_email'),
                                            value: customerEmail
                                        }}
                                        onChange={this.handleChange('customerEmail')}
                                    />
                                        {!submitted && this.state.errors.customerEmail && <div className="error show">{this.state.errors.customerEmail}</div> }

                                </div>
                                <div className={'col-sm-2 form-group' + (!submitted && this.state.errors.customerCity ? ' has-error' : '')}>

                                    <label>{this.props.t('addConversion.customer_city')}<span className="required" style={{ color: "red" }}>*</span></label>
                                    <Select
                                        id="customerCity"
                                        name="customerCity"
                                        value={customerCity}
                                        onChange={this.handleChange('customerCity')}
                                        options={cityList}
                                        
                                    />
                                        {!submitted && this.state.errors.customerCity && <div className="error show">{this.state.errors.customerCity}</div> }
                                </div>
                                <div className="col-sm-2">
                                <label> </label>
                                    <div>
                                        <CheckBox type="checkbox" label={this.props.t('addConversion.is_premium')} onChange={ this.handleChange('isPremium') } name="isPremium" id="iussue-1"  />
                                    </div>
                                </div>
                            </div>

                            <h6>{this.props.t('addConversion.car_details')}</h6>
                            <div className="row mrg-b30">
                            <div className={'col-sm-3 form-group' + (!submitted && this.state.errors.carMake ? ' has-error' : '')}>

                                    <label>{this.props.t('addConversion.make')}<span className="required" style={{ color: "red" }}>*</span></label>
                                    <Select
                                        id="carMake"
                                        value={carMake}
                                        onChange={this.handleChange('carMake')}
                                        options={carMakeList || []}
                                        
                                    />
                                        {!submitted && this.state.errors.carMake && <div className="error show">{this.state.errors.carMake}</div> }
                                </div>
                                <div className={'col-sm-3 form-group' + (!submitted && this.state.errors.carModel ? ' has-error' : '')}>

                                    <label>{this.props.t('addConversion.model')}<span className="required" style={{ color: "red" }}>*</span></label>
                                    <Select
                                        id="carModelList"
                                        value={carModel}
                                        onChange={this.handleChange('carModel')}
                                        options={carModelList || []}
                                        
                                    />
                                        {!submitted && this.state.errors.carModel && <div className="error show">{this.state.errors.carModel}</div> }
                                </div>
                                <div className="col-sm-3">
                                    <label>{this.props.t('addConversion.variant')}</label>
                                    <Select
                                        id="variant"
                                        value={carVariant}
                                        onChange={this.handleChange('carVariant')}
                                        options={carVariantList}
                                        
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <InputField
                                        inputProps={{
                                            id: "Regno",
                                            type: "text",
                                            placeholder:this.props.t('addConversion.registration_number'),
                                            name: "regNo",
                                            label:this.props.t('addConversion.registration_number'),
                                            value: carRegistrationNo
                                        }}
                                        onChange={this.handleChange('carRegistrationNo')}
                                    />     
                                </div>

                                <div className="col-sm-3">
                                    <InputField
                                        inputProps={{
                                            id: "purchasePrice",
                                            type: "number",
                                            placeholder:this.props.t('addConversion.purchase_price'),
                                            name: "purchasePrice",
                                            label:this.props.t('addConversion.purchase_price'),
                                            value: carPurchasePrice
                                        }}
                                        onChange={this.handleChange('carPurchasePrice')}
                                    />     
                                </div>
                            </div>
                                    <h6>{this.props.t('addConversion.seller_details')}</h6>
                            <div className="row mrg-b30">
                            <div className={'col-sm-3 form-group' + (!submitted && this.state.errors.sellerCity ? ' has-error' : '')}>

                                    <label>{this.props.t('addConversion.city')}<span className="required" style={{ color: "red" }}>*</span></label>
                                    <Select
                                        id="sellerCity"
                                        name="sellerCity"
                                        value={sellerCity}
                                        onChange={this.handleChange('sellerCity')}
                                        options={sellerCityList}
                                        
                                    />
                                        {!submitted && this.state.errors.sellerCity && <div className="error show">{this.state.errors.sellerCity}</div> }
                                </div>

                                <div className={'col-sm-3 form-group' + (!submitted && this.state.errors.sellerDealer ? ' has-error' : '')}>

                                    <label>{this.props.t('addConversion.dealer')}<span className="required" style={{ color: "red" }}>*</span></label>

                                    <Select
                                        id="sellerDealer"
                                        name="sellerDealer"
                                        value={sellerDealer}
                                        onChange={this.handleChange('sellerDealer')}
                                        options={dealerList}
                                        
                                    />
                                        {!submitted && this.state.errors.sellerDealer && <div className="error show">{this.state.errors.sellerDealer}</div> }


                                </div>
                            </div>

                            <h6>{this.props.t('addConversion.tracking_details')}</h6>
                            <div className="row">
                                <div className="col-sm-3 form-group">
                                    <label>{this.props.t('addConversion.source')}</label>

                                    <Select
                                        id="trackingSource"
                                        name="trackingSource"
                                        value={trackingSource}
                                        onChange={this.handleChange('trackingSource')}
                                        options={sourceList}
                                        
                                    />

                                </div>
                                <div className="col-sm-3 form-group">
                                    <label>{this.props.t('addConversion.sub_source')}</label>
                                    <Select
                                        id="trackingSubSource"
                                        name="trackingSubSource"
                                        value={trackingSubSource}
                                        onChange={this.handleChange('trackingSubSource')}
                                        options={subSourceList}
                                        
                                    />

                                </div>
                                <div className="col-sm-3 form-group">
                                    <label>{this.props.t('addConversion.purchase_date')}</label>
                                    <DatePicker
                                        id="purchaseDate"
                                        className="form-control"
                                        onChange={this.handleChange('trackingPurchaseDate')}
                                        // value={trackingPurchaseDate}
                                        dateFormat="dd-MM-yyyy"
                                        selected={trackingPurchaseDate ? new Date(trackingPurchaseDate) : null}
                                        
                                    />
                                </div>
                                
                            </div>
                            <div className="row">
                                <div className="col-sm-3 form-group">
                                    <label>{this.props.t('addConversion.tracked_by')}</label>
                                    <Select
                                        id="trackBy"
                                        value={trackingTrackBy}
                                        onChange={this.handleChange('trackingTrackBy')}
                                        options={trackByOption}
                                        
                                    />
                                </div>
                                <div className={'col-sm-3 form-group' + (!submitted && this.state.errors.conversionSource ? ' has-error' : '')}>

                                    <label>{this.props.t('addConversion.conversion_source')}<span className="required" style={{ color: "red" }}>*</span></label>
                                    <Select
                                        id="conversionSource"
                                        value={trackingConversionSource}
                                        onChange={this.handleChange('trackingConversionSource')}
                                        options={conversionSource}
                                        
                                    />
                                        {!submitted && this.state.errors.conversionSource && <div className="error show">{this.state.errors.conversionSource}</div> }

                                </div>
                            </div>
                            <div className="form-group">
                                
                                { 
                                (loading)
                                ?
                                <button type="button" name="submit" disabled className="btn btn-primary">{this.props.t('addConversion.save_conversion')}</button>
                                :
                                <button type="button" name="submit" onClick={this.submitHandler} className="btn btn-primary">{this.props.t('addConversion.save_conversion')}</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>           
        );
    }
}

const mapStateToProps = state => ({
    cityList: state.inventory.cityListData,
    listDealerData: state.dealer.dealerListData,
    sourceListData: state.source.sourceListData,
    subSourceListData: state.source.subSourceListData,
    makeModelVersionList: state.lead.makeModelVersionList,

})

const mapDispatchToProps = dispatch => {
    return {
            actions: {
                getCarMake: bindActionCreators(
                    InventoryActions.getCarMake,
                    dispatch
                ),
                getCarModel: bindActionCreators(
                    InventoryActions.getCarModel,
                    dispatch
                ),
                listCity: bindActionCreators(
                    InventoryActions.listCity,
                    dispatch
                ),
                listDealer: bindActionCreators(
                    DealerActions.listDealer,
                    dispatch
                ),
                sourceListData: bindActionCreators(
                    SourceActions.listSource,
                    dispatch
                ),
                subSourceListData: bindActionCreators(
                    SourceActions.listSubSource,
                    dispatch
                ),
                getCarVariant: bindActionCreators(
                    LeadActions.getMakeModelVersion,
                    dispatch
                ),
                getCustomer: bindActionCreators(
                    LeadActions.getCustomer,
                    dispatch
                ),
                addConversion: bindActionCreators(
                    LeadActions.addConversion,
                    dispatch
                )
        }
            }
}

export default withTranslation('common') (withRouter(connect(mapStateToProps, mapDispatchToProps)(TrackConversion)));