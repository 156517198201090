import React, { Component } from "react";
import { BrowserRouter as Router} from "react-router-dom";
//Custom component

import Header from "./../view/common/Header";
import Footer from "./../view/common/Footer";
import LeadFinder from "./../view/lead/component/LeadFinder";
import LeadsDetails from "./../view/lead/component/LeadsDetails";
import CategoryList from "./../view/administration/component/category/categoryList";
import EditCategory from "../view/administration/component/category/editCategory";
import EditSourceType from "../view/administration/component/source-type/editSourceType";
import SourceTypeList from "../view/administration/component/source-type/sourceTypeList";
import EditSource from "../view/administration/component/source/editSource";
import SourceList from "../view/administration/component/source/sourceList";
import EditSubSource from "../view/administration/component/sub-source/editSubSource";
import SubSourceList from "../view/administration/component/sub-source/subSourceList";
import EditRole from "../view/administration/component/roles/editRole";
import RoleList from "../view/administration/component/roles/roleList";
import EditPrivilege from "../view/administration/component/privilege/editPrivilege";
import PrivilegeList from "../view/administration/component/privilege/privilegeList";
import EditUser from "../view/administration/component/user/editUser";
import UserList from "../view/administration/component/user/userList";
import TrackConversion from "./../view/lead/component/TrackConversion";
import AddLead from "./../view/lead/component/AddLead";
import BlockingLeads from "./../view/lead/component/BlockingLead";
import ConversionPanel from "./../view/lead/component/ConversionPanel";
import ClusterList from "./../view/administration/component/cluster/clusterList";
import EditCluster from "../view/administration/component/cluster/editCluster";
import { Inventory, LeadControlPanel } from "../view/dealers";
import DealerDeliveryList from "./../view/reports/component/dealer-report/dealer-delivery-list";
import SalesFunnelList from "./../view/reports/component/lead-report/sales-funnel-list";
import Login from "../view/auth/login/component/Login";
import ForgotPassword from "../view/auth/forgot_password/component/ForgotPassword";
import RefreshToken from "../view/auth/RefreshToken";
import ResetPassword from "../view/auth/ResetPassword";
import Logout from "../view/auth/Logout";
import BlockingLeadBulkUpload from "../view/lead/component/BlockingLeadBulkUpload";
import BulkUpload from "../view/lead/component/BulkUpload";
import LeadSnapshotList from "./../view/reports/component/lead-report/lead-snapshot-list";
import EditCity from "../view/administration/component/city/editCity";
import CityList from "../view/administration/component/city/cityList";
import LocationWiseLeadList from "./../view/reports/component/lead-report/location-wise-lead-list";
import L1CallingListList from "./../view/reports/component/consultant-report/l1-calling-list";
import WalkinReschedule from "../view/before-auth/component/WalkinReschedule";
import FeedBackForm from "../view/before-auth/component/leadFeedbackForm";
import ViewFeedBack from "../view/before-auth/component/viewLeadFeedbackForm";
import ThankyouPage from "../view/before-auth/component/thankyouPage";
import PurchaseFeedBackForm from "../view/before-auth/component/purchaseFeedbackForm";
import ViewPurchaseFeedBack from "../view/before-auth/component/viewPurchaseFeedback";
import ErrorPage from "../view/before-auth/component/errorPage";

import BeforeLogin from "./beforeLogin";
import PrivateRoute from "./privateRoute";

import AddFbLeads from "../view/lead/component/AddFbLeads"

class CustomRouter extends Component {
  constructor(props) {
    super(props);
    this.state = { ht: 500 };
  }
  componentDidMount() {
    this.setState({
      ht: window.innerHeight - 127,
    });
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          ht: window.innerHeight - 127,
        });
      },
      false
    );
  }

  render() {
    return (
      <Router>
        <Header />

        <div style={{ minHeight: this.state.ht }}>
          <BeforeLogin path="/walkin-reschedule" component={WalkinReschedule} />
          <PrivateRoute path="/" exact component={LeadFinder} />
          <PrivateRoute path="/Leads-list" component={LeadFinder} />
          <PrivateRoute path="/lead-details/:id" component={LeadsDetails} />
          <PrivateRoute path="/list-category" component={CategoryList} />
          <PrivateRoute path="/edit-category/:id" component={EditCategory} />
          <PrivateRoute path="/add-category" component={EditCategory} />
          <PrivateRoute path="/list-sourceType" component={SourceTypeList} />
          <PrivateRoute
            path="/edit-sourceType/:id"
            component={EditSourceType}
          />
          <PrivateRoute path="/add-sourceType" component={EditSourceType} />
          <PrivateRoute path="/edit-source/:id" component={EditSource} />
          <PrivateRoute path="/add-source" component={EditSource} />
          <PrivateRoute path="/list-source" component={SourceList} />
          <PrivateRoute path="/edit-sub-source/:id" component={EditSubSource} />
          <PrivateRoute path="/add-sub-source" component={EditSubSource} />
          <PrivateRoute path="/list-sub-source" component={SubSourceList} />
          <PrivateRoute path="/add-role" component={EditRole} />
          <PrivateRoute path="/edit-role/:id" component={EditRole} />
          <PrivateRoute path="/list-role" component={RoleList} />
          <PrivateRoute path="/add-privilege" component={EditPrivilege} />
          <PrivateRoute path="/edit-privilege/:id" component={EditPrivilege} />
          <PrivateRoute path="/list-privilege" component={PrivilegeList} />
          <PrivateRoute path="/add-user" component={EditUser} />
          <PrivateRoute path="/edit-user/:id" component={EditUser} />
          <PrivateRoute path="/list-user" component={UserList} />
          <PrivateRoute path="/add-conversion" component={TrackConversion} />
          <PrivateRoute path="/add-leads" component={AddLead} />
          <PrivateRoute path="/blocking-leads" component={BlockingLeads} />
          <PrivateRoute path="/Conversion-Panel" component={ConversionPanel} />
          <PrivateRoute path="/add-cluster" component={EditCluster} />
          <PrivateRoute path="/edit-cluster/:id" component={EditCluster} />
          <PrivateRoute path="/list-cluster" component={ClusterList} />
          <PrivateRoute
            path="/lead-control-panel"
            component={LeadControlPanel}
          />
          <PrivateRoute path="/inventory" component={Inventory} />
          <PrivateRoute
            path="/Dealer-Delivery-Report"
            component={DealerDeliveryList}
          />
          <PrivateRoute
            path="/detailed-sales-funnel-report"
            component={SalesFunnelList}
          />
          <PrivateRoute path="/add-city" component={EditCity} />
          <PrivateRoute path="/edit-city/:id" component={EditCity} />
          <PrivateRoute path="/list-city" component={CityList} />
          <PrivateRoute path="/refresh-token" component={RefreshToken} />
          <PrivateRoute path="/logout" component={Logout} />
          <PrivateRoute
            path="/blockedLead-bulkUpload"
            component={BlockingLeadBulkUpload}
          />
          <PrivateRoute path="/bulk-upload" component={BulkUpload} />
          <PrivateRoute
            path="/Lead-Snapshot-Report"
            component={LeadSnapshotList}
          />
          <PrivateRoute
            path="/Location-Wise-Lead"
            component={LocationWiseLeadList}
          />
          <PrivateRoute
            path="/L1-Calling-Report"
            component={L1CallingListList}
          />
          <BeforeLogin path="/Login" component={Login} />
          <BeforeLogin path="/forgot-password" component={ForgotPassword} />
          <BeforeLogin
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <BeforeLogin path="/feedback-form" component={FeedBackForm} />
          <BeforeLogin path="/view-feedback" component={ViewFeedBack} />
          <BeforeLogin path="/thankyou-page" component={ThankyouPage} />
          <BeforeLogin path="/purchase-feedback" component={PurchaseFeedBackForm} />
          <BeforeLogin path="/view-purchase-feedback" component={ViewPurchaseFeedBack} />
          <BeforeLogin path="/error-page" component={ErrorPage} />
          <PrivateRoute path="/add-fb-leads" component={AddFbLeads} />
          
        </div>
        <Footer />
      </Router>
    );
  }
}

export default CustomRouter;
