import React, { Component } from "react";

import { withTranslation } from 'react-i18next';
import queryString from 'query-string'
import { connect } from 'react-redux';
import { AuthActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { default as config } from './../../../config/config';


class ThankyouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams:{},
      siteSettings: null
    };
  }


  componentDidMount = async () => {
    if(!this.state.siteSettings){
      this.getSiteSettings();
    }

    let queryParams = queryString.parse(this.props.location.search);
    await this.setState({queryParams});
  }

  getSiteSettings() {
    this.props.actions.getSiteSettings().then(res => {
      if(res.status===200){
        this.setState({siteSettings: res['data']})          
      }
    });
  }


  render() {
    const { queryParams, siteSettings} = this.state;

    return (
      <div className="container">
        <div className="jumbotron text-center">
          <div className="mrg-b15 text-center">
              <img src={config.SUCCESS_PAGE_IMG} className="brand-logo" alt="Loan Box" />
          </div>
          <p className="thankyou-msg">{this.props.t('thankyoupage.'+queryParams['msg'])}</p>
          <p className="thankyou-btn">
            {
              siteSettings
              ?
                <a className="btn btn-primary btn-sm" href={siteSettings['frontendBaseUrl']} data-key="home">{this.props.t('thankyoupage.continue_homepage')}</a>
              :
                ''
            }          
          </p>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
     siteSettings: state.auth.siteSettings
  }
}

const mapDispatchToProps = dispatch => {
  return {
             actions: {
                getSiteSettings: bindActionCreators(
                  AuthActions.getSiteSettings,
                  dispatch
                )
             }
          }
}


export default withTranslation('common') (withRouter(connect(mapStateToProps, mapDispatchToProps)(ThankyouPage)));
