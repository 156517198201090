import React, { Component } from 'react';
import AssignedCarTab from './AssignedCarTab';
import RecommendCarTab from './RecommendTab';
import LeadInfo from './leadInfo';
import {withTranslation } from 'react-i18next';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LeadActions, InventoryActions, DealerActions } from "../../../store/action";

class LeadsDetails extends Component {
    lead_id;
    constructor(props) {
        super(props);
        
        this.state = {
            reqform:true,
            pricepopover:false,
            tabCondition:{'TAB-1':true,'TAB-2':false},
            leadList:[],
            cityList:[],
            cityListOption: [],
            selectedOption: null,
            date: new Date(),
            statusClassname: {
                'active': 'nav-item active',
                'removed': 'nav-item',
                'all': 'nav-item'
            },
            cityDetails:[],
            filterList:{
                'min_price_range':[],
                'max_price_range':[]
            },
            assignedCarList:[],
            recomendCarListCount:0,
            loading:false,
            leadReopenData : {},
            reopenLead:{},
            refreshAssignedCarsData: false,
            refreshRecommendsCarsData: false,
            isSaveMainLeadComment: false,
            updatedLeadCarFromFeedbackPopupToDetail:'',
            isRequirementFormUpdated:false
       };
      this.lead_id = this.props.match.params.id;
      this.pricepopShow = this.pricepopShow.bind(this);
      
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
       
        let updatedStateData = {};
        let {assignedCarList,leadReopenData} = prevState;
        const {leadsCarList} = nextProps;
        if(leadsCarList && assignedCarList.length !== leadsCarList.length){
            if(nextProps.leadsCarList && nextProps.leadsCarList.length){
                updatedStateData['assignedCarList'] =  (nextProps.leadsCarList.length > assignedCarList.length) ? nextProps.leadsCarList.length : assignedCarList;
            } 
        }
        if(nextProps.leadList && nextProps.leadList.length){
            updatedStateData['leadList'] = nextProps.leadList
        }
        if(leadReopenData !== nextProps.leadReopenData){
            updatedStateData['leadReopenData'] = nextProps.leadReopenData
        }
        return updatedStateData;

    }

    componentDidMount = async() => {
        await this.getLeadDetails(this.lead_id);
        await this.getCityDetails({});
        await this.getFilters();
        await this.getMakeModelVerionList()
        await this.getAgentDetails(this.lead_id);
        await this.getStatus();
        await this.getDealerLst();
        await this.getTimelineData(this.lead_id);
        await this.getLeadsCarDetails(this.lead_id);
        //await this.getRtoDetails();
        await this.setReopenLead({'lead_id':this.lead_id,'reopen_lead':0})
        
    }

    getLeadDetails = async(leadId) =>{
        
        let leadDetails = await this.props.actions.listLead({id:leadId});
        await this.setState({leadList:((leadDetails && leadDetails.data) ? leadDetails.data : [])});
        
        let cityId = (leadDetails && leadDetails.data && leadDetails.data.length) ? leadDetails.data[0].customer_city_id : 0;
        let params = {}
        if(cityId)
            params['city'] = [cityId];
        this.getCustomerLocality(params);

        if(leadDetails && leadDetails.data && leadDetails.data.length){
            this.getConversionStatus({lead_id:leadDetails.data[0].id})
        }
        
    }
    
    getCityDetails(params) {
        this.props.actions.getCityState(params)
    }

    getMakeModelVerionList = () => {
        this.props.actions.getMakeModelVersion()
    }

    getStatus = () => {
        this.props.actions.getStatus()
    }

    getDealerLst = () => {
        this.props.actions.listDealer()
    }

    handleClick = (index) => {
        let {tabCondition} = this.state;
        if(index === 1){
            tabCondition["TAB-1"] = true;
            tabCondition["TAB-2"] = false;
        }else if(index === 2){
            tabCondition["TAB-2"] = true;
            tabCondition["TAB-1"] = false;
        }
        this.setState( { tabCondition } );
    }
    addemail(e){
        this.setState({openemail: !this.state.openemail})
        }

    pricepopShow(e){
        this.setState({pricepopover: !this.state.pricepopover})
        }
    
    onChange = date => this.setState({ date })

    handleChange = selectedOption => {
      this.setState({ selectedOption });
    };

    getFilters = async(params) =>{
        this.props.actions.getFilter()
    }

    updateCustomer = (postData) => {
        this.props.actions.updateCustomer(postData)
    }

    getAgentDetails = (leadId) => {
        let params = {
            lead_id:leadId
        }
        this.props.actions.getReqAgentDetails(params)
    }

    getTimelineData = (leadId) => {
        let params = {
            lead_id:leadId
        }
        this.props.actions.getTimelineDetails(params)
    }

    getLeadsCarDetails = async (leadId, page=1) => {
        this.setState({loading:true})
        let params = {
            lead_id:leadId,
            page_number: page
        }; 
        this.handleClick(1); //FIRST TAB TRIGGER ACTIVE

        if(this.state.refreshAssignedCarsData){
            await this.setState({isSaveMainLeadComment:true});
        }

        await this.props.actions.getLeadsCarList(params).then(resp=>{
            this.setState({loading:false, assignedCarList: resp});
        });
    }

    getStockList() {
        this.props.actions.getStockList()
    }

    updateTitleCount = (totalCount) => {
        this.setState({recomendCarListCount:totalCount})
    }

    // getRtoDetails = () => {
    //     this.props.actions.getRTOList()
    // }

    getCustomerLocality = (postData) => {
        this.props.actions.getCustomerLocality(postData)
    }

    setReopenLead = (postData) => {
        this.props.actions.setReopenLead(postData)
    }

    getConversionStatus = async(postData) => {
        this.props.actions.getConversionStatus(postData)
    }
    checkDisplayStatus = () => {
        let {leadList,leadReopenData:{reopen_lead} = {}} = this.state;
        
        let reopen = reopen_lead && reopen_lead === 1?true:false
        
        if(leadList && leadList.length){
            
            leadList = leadList[0];
            
            if( ((+leadList.status_id !== 7 || +leadList.sub_status_id === 27) && (!(+leadList.status_id === 6 && +leadList.sub_status_id === 12))) || (reopen === true) ){
                return true;
            }else{
                return false;
            }
        }
       
    }
    updateReopenStatus = (reopen_data) => {
        this.setState({leadReopenData:reopen_data})
    }

    recommendCarSave = async () => {
        await this.setState({refreshAssignedCarsData: true,refreshRecommendsCarsData:true}); //SET TO TRUE FOR ASSIGNED CAR TABLE & RECOMMEND CAR TABLE DATA REFRESH
        await this.getLeadsCarDetails(this.lead_id);
    }

    getListLeadCars = (leadId, page) => {
        this.getLeadsCarDetails(leadId, page);
    }

    updatedLeadCarFromFeedbackPopupToDetail = (data) => {
        this.setState({updatedLeadCarFromFeedbackPopupToDetail: data});
    }

    updateSearchDetails = (filterData) => {
        this.setState({isRequirementFormUpdated:true})
    }

    mainSummaryStatus = async (data) => {
        // if(data == 'success'){
            await this.setState({isSaveMainLeadComment: false, refreshAssignedCarsData: false})
            await this.getLeadDetails(this.lead_id);
            await this.getLeadsCarDetails(this.lead_id);
        // }
    }

    render() {
        return (
                <div>
                    
               <LeadInfo  updateCustomer={this.updateCustomer} leadList={this.state.leadList}/>
                <div className="container-fluid">
                    <ul className="list-inline nav-tabs">
                        <li className={this.state.tabCondition["TAB-1"] ? "nav-item active " :"nav-item "}  onClick={() => this.handleClick(1)} >
                            <div>{this.props.t('details.assignedCar')}<span>({this.state.assignedCarList.length})</span></div>
                        </li>
                        {/* {} */}
                        {this.checkDisplayStatus() &&  <li className={this.state.tabCondition["TAB-2"] ? "nav-item active " :"nav-item "}   onClick={() => this.handleClick(2)} >
                            <div>{this.props.t('details.recomendCarDealer')} </div>
                        </li>}
                    </ul>
                    <div className="tab-content">
                    
                        <div  className={this.state.tabCondition["TAB-1"] ? " tab-pane active" :"tab-pane "}>
                            <AssignedCarTab loading={this.state.loading} updateReopenStatus={this.updateReopenStatus} refreshAssignedCarsData={this.state.refreshAssignedCarsData} leadId={this.lead_id} getListLeadCars={this.getListLeadCars} isSaveMainLeadComment={this.state.isSaveMainLeadComment} updatedLeadCarFromFeedbackPopupToDetail={this.updatedLeadCarFromFeedbackPopupToDetail} updateSearchDetails={this.updateSearchDetails} mainSummaryStatus={this.mainSummaryStatus} leadList={this.state.leadList}/>
                        </div>
                    
                        <div  className={this.state.tabCondition["TAB-2"] ? "tab-pane active " :"tab-pane "}>
                            <RecommendCarTab lead_id={this.lead_id} getAgentDetails={this.getAgentDetails} onSaveRecommendation={this.recommendCarSave} updateTitleCount={this.updateTitleCount} activeStatus={this.state.tabCondition["TAB-2"]} refreshRecommendsCarsData={this.state.refreshRecommendsCarsData}  updatedLeadCarFromFeedbackPopupToDetail={this.state.updatedLeadCarFromFeedbackPopupToDetail} isRequirementFormUpdated={this.state.isRequirementFormUpdated}/>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
    
    
        
}

const mapStateToProps = (state, ownProps) => {
    return ({
        // leadList: state.lead.listLead,
        cityList:state.lead.cityList,
        leadsCarList:state.lead.leadsCarList,
        leadReopenData : state.lead.leadReopenData
        
    });
}






const mapDispatchToProps = dispatch => {
    return {
        actions: {
            listLead: bindActionCreators(
                LeadActions.listLead,
                dispatch
            ),
            listCity: bindActionCreators(
                InventoryActions.listCity,
                dispatch
            ),
            getFilter: bindActionCreators(
                LeadActions.getFilter,
                dispatch
            ),
            getMakeModelVersion: bindActionCreators(
                LeadActions.getMakeModelVersion,
                dispatch
            ),
            updateCustomer:bindActionCreators(
                LeadActions.updateCustomer,
                dispatch
            ),
            getReqAgentDetails:bindActionCreators(
                LeadActions.getReqAgentDetails,
                dispatch
            ),
            listDealer:bindActionCreators(
                DealerActions.listDealer,
                dispatch
            ),
            getStatus:bindActionCreators(
                LeadActions.getStatus,
                dispatch
            ),
            getTimelineDetails:bindActionCreators(
                LeadActions.getTimelineDetails,
                dispatch
            ),
            getLeadsCarList:bindActionCreators(
                LeadActions.getLeadsCarList,
                dispatch
            ),
            getCityState: bindActionCreators(
                LeadActions.getCityState,
                dispatch
            ),
            // getRTOList: bindActionCreators(
            //     LeadActions.getRTOList,
            //     dispatch
            // ),
            getCustomerLocality:bindActionCreators(
                LeadActions.getCustomerLocality,
                dispatch
            ),
            setReopenLead:bindActionCreators(
                LeadActions.setReopenLead,
                dispatch
            ),
            getConversionStatus:bindActionCreators(
                LeadActions.getConversionStatus,
                dispatch
            ),
            /////// call get used car list here //////
        }
    }
}

export default withTranslation('lead')(connect(mapStateToProps, mapDispatchToProps) (LeadsDetails));
