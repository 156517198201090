import React, { Component } from 'react';
import InputField from '../elements/InputField';
import { connect } from 'react-redux';
import { AuthActions } from "../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../common/LanguageSelector';
import { Redirect } from "react-router-dom";
import { default as config } from './../../config/config';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            fields: {
                "password": "",
                "confirm_password": "",
                "resetToken": (this.props.match.params && this.props.match.params['token']) || ''
            },
            errors: {},
            submitted: false
        };

        
      
    }

    componentDidMount = () => {
        this.validateResetToken();
    }

    /**
     * Validate Token
     */
    validateResetToken =  () => {
        let resetToken = this.state.fields['resetToken'];
        this.props.actions.validateResetToken({"resetToken":resetToken});
    }

    /**
     * Input Handler
     * @param e (synthatic element)
     */
    handleInputChange = async (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;

        await this.setState({
          fields
        });
    }

    /**
     * Login Submit Handler
     */    
    submitLogin = async (e) => {
        e.preventDefault();
        this.setState({ submitted: true });
        if (await this.validateForm()) {
            this.props.actions.resetPassword(this.state.fields);
        }
    }

    /**
     * Form Validations
     */

    validateForm = async function() {
        
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["password"].trim().length < 6) {
            formIsValid = false;
            errors["password"] = this.props.t('resetPassword.enter_password');
        }

        if (fields["confirm_password"].trim() !== fields["password"].trim()  ) {
            formIsValid = false;
            errors["confirm_password"] = this.props.t('resetPassword.confirm_password_not_matched');
        }
        
        await this.setState({
            errors: errors,
            submitted: formIsValid
        });

        return formIsValid;
    }

    render() {
        const { tokenExpired, resetPassword, error } = this.props;
        const { submitted } = this.state;
        
        if((tokenExpired || resetPassword) && typeof error === 'undefined'){
            return <Redirect to={'/login'} />
        }

        return (
            <div className="container-fluid" id="homePage">
                <div className="loginwrap">
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-sm-offset-4 col-sm-offset-6">
                            <div className="mrg-b15 text-center">
                                <img src={config.constants.SITE_LOGO} className="brand-logo" alt="Loan Box" />
                            </div>
                            <div className="card">
                                <div className="card-body pd-30">
                                    <div className="signUp-container text-left">
                                        <h1 className="login-h1">{this.props.t('login.reset_password')}</h1>
                                        <form method="post" className="text-left os-animation animated fadeInDown">
                                            <div className="loginbox">
                                                <div className={'form-group' + (!submitted && this.state.errors.password ? ' has-error' : '')}>
                                                    <InputField
                                                        inputProps={{
                                                            id: "password",
                                                            type: "password",
                                                            name: "password",
                                                            label: this.props.t('login.password'),
                                                            placeholder: this.props.t('login.password_placeholder'),
                                                            value: this.state.fields.password
                                                        }}
                                                        onChange={this.handleInputChange}
                                                        />
                                                        {!submitted && this.state.errors.password && <div className="error-block">{this.state.errors.password}</div> }
                                                </div>

                                                <div className={'form-group' + (!submitted && this.state.errors.confirm_password ? ' has-error' : '')}>
                                                    <InputField
                                                        inputProps={{
                                                            id: "confirm_password",
                                                            type: "password",
                                                            name: "confirm_password",
                                                            label: this.props.t('login.confirm_password'),
                                                            placeholder: this.props.t('login.confirm_password_placeholder'),
                                                            value: this.state.fields.confirm_password
                                                        }}
                                                        onChange={this.handleInputChange}
                                                        />
                                                        {!submitted && this.state.errors.confirm_password && <div className="error-block">{this.state.errors.confirm_password}</div> }
                                                </div>
                                                        
                                                        <div className="">
                                                            {
                                                                !submitted 
                                                                ?
                                                                <button type="button" onClick={this.submitLogin} className="btn btn-primary btn-block">{this.props.t('login.submit')}</button>
                                                                :
                                                                <button type="button" className="btn btn-primary btn-block">{this.props.t('login.please_wait')}</button>
                                                            }
                                                        </div>
                                                </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <footer>
                                <LanguageSelector />
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tokenExpired: state.auth.tokenExpired,
        resetPassword: state.auth.resetPassword,
        error: state.auth.error
    }
 }

const mapDispatchToProps = dispatch => {
    return {
               actions: {
                validateResetToken: bindActionCreators(
                    AuthActions.validateResetToken,
                    dispatch
                  ),
                resetPassword: bindActionCreators(
                    AuthActions.resetPassword,
                    dispatch
                )
               }
            }
}

export default withTranslation('common') (connect(mapStateToProps, mapDispatchToProps)(ResetPassword));