import React, { Component } from 'react';
import InventoryFilter from './Inventory-Filter';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InventoryActions } from "../../../../store/action";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import FilterTable from './filter-table';
import queryString from 'query-string';
import { toast } from 'react-toastify';

class Inventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inventoryList :[],
            page: 1,
            runPaginationFunc : false,
            loading: false,
            cachedPostData: {},
            importingData: false
        }
    }

    /**
     * Listing API Handler
     */

    handleSearchSubmit = async (data={}, pageNo=1) => {
        let queryParams = queryString.parse(window.location.search);

        await this.setState({page:pageNo});
        if(data['action'] && data['action'] === 'resetAll'){
            await this.setState({loading: false, runPaginationFunc: false, inventoryList: [], pagination:[]});
            return;
        }

        if(pageNo === 1){
            await this.setState({inventoryList: []});
        } 
        //////////////// MAP DATA TO REQUEST DATA HERE /////////////////
        
        let requestData = {}
        let filterParams = {
            'keyword'  : 'search_text',
            'car_model': 'car_model',
            'car_status': 'car_status',
            'fuel_type_id': 'fuel_type_id',
            'max_car_price': 'max_price',
            'max_km_driven': 'max_km_driven',
            'max_make_year': 'max_make_year',
            'min_car_price': 'min_price',
            'min_km_driven': 'min_km_driven',
            'min_make_year': 'min_make_year',
            'uc_transmission_id': 'uc_transmission_id',
            'make' : 'car_make',
            'model' : 'car_model',
            'owner_type' : 'owner_type',
            'certification_status' : 'certification_status',
            'city' : 'city_ids',
            'dealer' : 'dealer_ids',
            'inventory_age' : 'car_age_filter',
            'color' : 'uc_colour_id',
            'body_type': 'body_type'
        }
        for(let i in data){
            if(filterParams.hasOwnProperty(i)){
                requestData[filterParams[i]] =  data[i]
            }
        }

        // console.log(requestData)
        ////// appending default aprams ////

        //IF DEALER NOT SELECTED BUT EXISTS IN QUERY PARAM
        if(!requestData['dealer_ids'] && queryParams.did){
            requestData['dealer_ids'] = [queryParams.did]
        }

        requestData['sort_on']='price';
        requestData['order_by']='DESC';
        requestData['page_no']=pageNo;
        // if(requestData['dealer_ids'] && requestData['dealer_ids'].length){ //commented due to count issue in report section
        //     requestData['source_id'] = 1
        // }
        if(requestData['uc_colour_id']){
            requestData['uc_colour_id'] = [requestData['uc_colour_id']]
        }

        let updatedPostData = {};

        Object.keys(requestData).forEach(el=>{
            if(requestData[el] && typeof requestData[el] === 'object' && requestData[el].length){
                updatedPostData[el] = requestData[el]
            } 
            else if(requestData[el] && typeof requestData[el] !== 'object'){
                updatedPostData[el] = requestData[el]
            } 
        });

        this.setState({cachedPostData: Object.assign({}, updatedPostData), runPaginationFunc: false, loading: true, inventoryList: ((this.state.page!==1) ? this.state.inventoryList: []) , pagination: this.state.pagination});
        //DISPATCH ACTION
        await this.detailsList(updatedPostData)
      
    }

    detailsList = async(updatedPostData,pageNo=1)=>{
        this.props.actions.submitFilter(updatedPostData).then(resp=>{
            this.setState({loading: false, runPaginationFunc: true});
            if(resp.status===200){
                if(resp.data.length){
                   this.setState({ inventoryList: this.state.inventoryList.concat(resp.data), pagination: ((resp.pagination && resp.pagination) || {})})
                }else{
                    let paginate = (this.state.page === 1) ? {} : {...this.state.pagination}
                   this.setState({ page:pageNo-1, pagination: paginate})
                }
            }
        }).catch(err=>{
            this.setState({loading: false, runPaginationFunc: true})
        });

    }

    componentDidMount = () => {
        this._isMounted = true;
        this.handleSearchSubmit(); 
       // window.addEventListener('scroll', this.onScroll);
    }

    onScroll = (event) => {  
       // if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            //Handling multiple API call on single scroll & if total results are showing
            if (this.state.runPaginationFunc && this.props.pagination !== this.state.inventoryList.length) {
                let current_page = this.state.page;
                let loading=false;

                if(current_page!==0) {
                    loading = true;
                } 
                if(this._isMounted){
                    this.setState({ page: current_page + 1,loading:loading},async (v) => {
                        //TRIGGER FILTER COMPONET SUBMIT HANDLER
                        let queryParams = queryString.parse(window.location.search);
                        let requestData = this.state.cachedPostData; 
                        if(!requestData['dealer_ids'] && queryParams.did){
                            requestData['dealer_ids'] = [queryParams.did]
                        }
                        requestData['sort_on']='price';
                        requestData['order_by']='DESC';
                        requestData['page_no']=this.state.page;
                        await this.detailsList(requestData,this.state.page) 
                    });
                }
            }
       // }
    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    importXls = async () => {

        let postData = this.state.cachedPostData;

        if(postData['page_no']){
            delete postData['page_no'];
        }

        if(this._isMounted) await this.setState({importingData: true});
        
        this.props.actions.importXls(postData).then(res=>{
            if(this._isMounted) this.setState({importingData: false});

            if(res && res.data){
                const link = document.createElement('a');
                link.href = `${res.data}`;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                toast.success(res.message);
            }

        });
    }
    
    render() {
        const {  inventoryList, loading , pagination, importingData, cachedPostData} = this.state;

        return (
            <div className="inventory-wraper">
                <div className="left-bar">
                    <InventoryFilter childRef={ref => (this.child = ref)} onSearchSubmit={this.handleSearchSubmit} dataToChild={this.state.loading}/>
                </div>
                <div className="right-bar">
                    <FilterTable filterTableData={{cachedPostData, 'inventoryList': inventoryList, 'pagination': pagination, 'loading': loading, 'csvDownloading': importingData,onScroll:this.onScroll}} importExcelFile={this.importXls}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    inventoryList: state.inventory.inventoryList,
    pagination: state.inventory.pagination,
});

const mapDispatchToProps = dispatch => {
    return {
                actions: {
                    submitFilter: bindActionCreators(
                        InventoryActions.submitFilter,
                        dispatch
                    ),
                    importXls: bindActionCreators(
                        InventoryActions.inventoryCsvExport,
                        dispatch
                    )
                }
    }
}
export default  withTranslation('common') (withRouter(connect(mapStateToProps, mapDispatchToProps)(Inventory)));