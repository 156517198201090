import React, { Component } from 'react';
import ModalPopup from './../../elements/Popup';
import FullTimeline from './FullTimeline';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import DateFormat from 'dateformat';
import { bindActionCreators } from 'redux';
import { LeadActions } from "../../../store/action";

class PreviousActivity extends Component {
    constructor(props) {
        super(props);
        
    
        this.state = {
            reqform:true,
            startDate: new Date(),
            open:false,
            openemail:false,
            openmobile:false,
            showhistory: false,
            timelineDetails:[],
            leadDetails:[]
      };
      this.togglePanel = this.togglePanel.bind(this);
      this.addemail = this.addemail.bind(this);
    }

    static getDerivedStateFromProps = (nextProps, prevState) => { 
        let updatedStateData = {};
        if(nextProps.timelineDetails !== prevState.timeline){
            updatedStateData['timelineDetails'] = (nextProps.timelineDetails && nextProps.timelineDetails.length) ? (nextProps.timelineDetails).slice(0,5) : [];
        }
        return updatedStateData;

    }

    historyCloseModal = () => {
        this.setState({ showhistory: false });
    }
    historyshowModal = () => {
        this.setState({ showhistory: true });
    }
    addemail(e){
        this.setState({openemail: !this.state.openemail})
        }
    addmobile(e){
        this.setState({openmobile: !this.state.openmobile})
        }
    togglePanel(e){
        this.setState({open: !this.state.open})
        }
    
 
    render() {
        const {timelineDetails } = this.state;
        return (
            <div>
                <div className={this.state.open ? "card timeline-panel open" :"card timeline-panel "}>
                    <div className="card-heading card-headflex" onClick={(e)=>this.togglePanel(e)}>
                        <h2 className="card-title">{this.props.t('previousActivity.title')}</h2>
                        <button type="button" className="btn btn-link timelinelink" onClick={this.historyshowModal}>{this.props.t('previousActivity.viewTimeline')}</button>
                    </div>
                    <div className="card-body">
                        <ul className="timeline activity-timeline">
                        {(timelineDetails && timelineDetails.length !== 0) && timelineDetails.map((tlData, k) => {
                            return (<li key={k}>
                                <div className="timeline-badge">
                                    {(tlData && tlData.icon && tlData.icon === 'user')?<i className="ic-person text-primary"></i> :''}
                                    {(tlData && tlData.icon && tlData.icon === 'call')?<i className="ic-call text-primary"></i> :''}
                                    {(tlData && tlData.icon && tlData.icon === 'computer')?<i className="ic-desktop_windows text-primary"></i> :''}
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading border-BL clearfix">
                                        <div className=" edit-secL width20">
                                            <h4 className="timeline-title">{(tlData.status_name || '')+' '+ (tlData.sub_status_name || '')+' '}{(tlData.calling_status_name) ? tlData.calling_status_name : ''}{(tlData.commentedDealerName) ? tlData.commentedDealerName : ''}  </h4>
                                        </div>
                                        <div className="edit-secR">
                                            <h4 className="timeline-title" dangerouslySetInnerHTML={{ __html: tlData.comment }}></h4>
                                            {(tlData.leadAddedDate) ? <p className="listingdate">{this.props.t('timeline.addedOn')} {(tlData.leadAddedDate) ? (DateFormat(tlData.leadAddedDate,"dd-mm-yyyy")+' '+ DateFormat(tlData.leadAddedDate,"HH:mm:ss")):''}</p> :''}
                                            <p className="listingdate">{this.props.t('timeline.updatedOn')} {(tlData.added_on) ? (DateFormat(tlData.added_on,"dd-mm-yyyy")+' '+ DateFormat(tlData.added_on,"HH:mm:ss")):''}
                                            {(+tlData.status_added_by_system === 1)? ' by System': ((tlData.commentedUserName) ? ' by '+tlData.commentedUserName : '')}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>)
                        }
                             )}
                            
                        </ul>
                    </div>
                </div>
                {this.state.showhistory === false ? null : <ModalPopup id="timelinePopup" className="modal modal-lg" title={this.props.t('timeline.title')}  modalClose={this.historyCloseModal}>
                <FullTimeline/>
                </ModalPopup>
                }
                
            </div>
            
        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    timelineDetails: state.lead.timelineDetails,
    leadDetails: state.lead.listLead
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            getUserNameByType:bindActionCreators(
                LeadActions.getUserNameByType,
                dispatch
            )
        }
    }
}

export default withTranslation('lead') (connect(mapStateToProps,mapDispatchToProps)(PreviousActivity));
