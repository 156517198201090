import React, { Component } from 'react';
import EditRequirements from './EditRequirements';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { LeadActions, InventoryActions } from "../../../store/action";
import { toast } from 'react-toastify';
import DateFormat from 'dateformat';
import * as helper from '../../../config/helper';
import { default as config } from '../../../config/config';

class RequirmentCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reqform:true,
            showEditButton: true,
            leadDetails:{},
            mmvList : [],
            filterData:{
                priceRange:[],
                fromYearRange:[],
                toYearRange:[],
                kmRange:[],
                bodyType:[],
                fuelType:[],
                owner:[],
                transmission:[],
                makeModel:[],
                leadDetails:[],
                agentDetails:{},
                cityListData: []
            }
        };
    }

    componentDidMount = async() => {
        this.listCity();
    }

    listCity = () => {
        let cityListArr = [];
        this.props.actions.listCity()
            .then(async (resp) => {
                if (resp.status === 200) {
                    resp.data && resp.data.city.forEach(el => {
                        
                        cityListArr.push({ "value": el.id, "label": el.name })
                    })
                }                
                this.setState({ cityListData: cityListArr });
            });        
    }

    UNSAFE_componentWillReceiveProps = async (nextProps) => {
        let {filterData, makeList} = this.state;
        const {makeModelVersionList, filterList, leadDetails,agentDetails} = nextProps;
        if(filterList && filterList.length){
            filterData.priceRange = await this.getRequiredKeyValue(filterList,'max_car_price');
            filterData.fromYearRange = await this.getRequiredKeyValue(filterList,'min_make_year');
            filterData.toYearRange = await this.getRequiredKeyValue(filterList,'max_make_year');
            filterData.kmRange = await this.getRequiredKeyValue(filterList,'max_km_driven');
            filterData.bodyType = await this.getRequiredKeyValue(filterList,'body_type');
            filterData.fuelType = await this.getRequiredKeyValue(filterList,'fuel_type_id');
            let colorsData = await this.getRequiredKeyValue(filterList,'color');
            filterData.color = await this.makeColorValueKeySame(colorsData);
            filterData.transmission = await this.getRequiredKeyValue(filterList,'uc_transmission_id');
            let ownerType = await this.getRequiredKeyValue(filterList,'owner_type');
            filterData.owner = await this.getOwnerLabel(ownerType);
        }
        if(makeModelVersionList && Object.keys(makeModelVersionList).length>0){
            
            let {make} = makeModelVersionList;
            makeList = make.map(function(e){
                return {label : e.make,value:e.id}
            })
        
        }
        filterData.makeModel = await this.getCarMakeModelList(makeModelVersionList);
        if(filterData.leadDetails !== leadDetails)
            filterData.leadDetails = leadDetails;
        if(filterData.agentDetails !== agentDetails)
            filterData.agentDetails = agentDetails
        this.setState({filterData:filterData,mmvList:makeModelVersionList,makeList:makeList})
        
    }

    makeColorValueKeySame = async(colors)=>{
       let colorsData =  colors.map(function(e){
            return {label : e.label, value : e.label}
        });
        return colorsData;
    }

    getOwnerLabel = async (ownerArr) => {
        const OWNER = config.constants.OWNER;
        let retArr=[];
        if (ownerArr && ownerArr.length) {
            _.forEach((ownerArr),(ownObj) =>{
                let tempObj={};
                const result = _.find(OWNER, { 'value': ownObj.value });
                if(result){
                    tempObj.value = ownObj.value;
                    tempObj.label = result.label;
                    retArr.push(tempObj);
                }
            })
        }
        return retArr;
    }

    getRequiredKeyValue = async (filterData,key) => {
        let retData=[];
        if (filterData && filterData.length) {
            const requiredData = helper.filterDataByKey(filterData, key);
            if(requiredData && requiredData.list.length)
            retData = helper.getDataInKeyValueFormat(requiredData.list,key);
        }
        return retData;
    }

    getCarMakeModelList = async (params) => {
        let retData=[];
        if (params && params.make && params.make.length && params.model && params.model.length) {
            retData = helper.getCarMakeModelList(params);
        }
        return retData;
    }

    submitReqForm = (postData) =>{
        this.props.actions.submitRequirementForm(postData)
        .then(async (resp) => {
            if(resp.status === 200){
                toast.success(resp.message);
                this.setState({reqform:true, showEditButton: true});
                let agentDetails = await this.props.actions.getReqAgentDetails({'lead_id':this.state.filterData.leadDetails[0].lead_id_hash});
                let requiredData = await this.props.actions.listLead({id:this.state.filterData.leadDetails[0].lead_id_hash});

                if(agentDetails){
                    let {filterData} = this.state;
                    filterData.agentDetails = agentDetails;
                    filterData.leadDetails = requiredData.data;
                    this.props.updateSearchDetails(filterData)
                    this.setState({filterData:filterData});
                }
            }
        })
    }
    render() {        
        let updated_at = '',updated_by='';
        const {PURPOSE_OF_PURCHASE_CAR, DRIVE_CAR, NO_SEAT_REQ, BUYER_TYPE} = config.constants;        
        let  { agentDetails} = this.state.filterData || {};        
        agentDetails = (typeof agentDetails === 'undefined') ? {} : agentDetails;
        if(agentDetails){
             updated_at = (agentDetails.updated_at) ? (agentDetails.updated_at) : (agentDetails.created_at);
             updated_by = (agentDetails.updated_by) ? (agentDetails.updated_by) : (agentDetails.added_by)
        }
        return (
            <div className="card">
                <div className="card-heading">
                    <div className="row">
                        <div className="col-sm-8"><h2 className="card-title">{this.props.t('requirement.reqTitle')}</h2></div>
                        { this.state.showEditButton ? 
                        <div className="col-sm-4 text-right">
                            <button type="button" name="edit" className="btn btn-link"  onClick={() => this.setState({ reqform: !this.state.reqform, showEditButton: false })}><i className="ic-createmode_editedit mrg-r5"></i>{this.props.t('requirement.edit')}</button>
                        </div> :''
                       }
                    </div>
                    <p className="card-subhead f12">{this.props.t('requirement.lastUpdatedBy')} {helper.getUserName(updated_by,'username')} {this.props.t('requirement.on')} {DateFormat(updated_at,"mmm dd,yyyy")} {this.props.t('requirement.at')} {DateFormat(updated_at,"HH:MM")}</p>
                </div>
                <div className="card-body">
                { (this.state.reqform && agentDetails) ?
                    (<div>
                        <ul className="requirements-list">
                            {
                                (agentDetails['budget'] && agentDetails['budget'] !== '' && helper.getValueByKey(this.state.filterData.priceRange,String(agentDetails.budget)))
                                ?
                                <li>{helper.getValueByKey(this.state.filterData.priceRange, String(agentDetails.budget))}</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['min_year'] && agentDetails['min_year'] !== '' && helper.getValueByKey(this.state.filterData.fromYearRange,String(agentDetails.min_year)))
                                ?
                                <li>{helper.getValueByKey(this.state.filterData.fromYearRange, String(agentDetails.min_year))} - {helper.getValueByKey(this.state.filterData.toYearRange, String(agentDetails.max_year))}</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['req_body_type'] && agentDetails['req_body_type'] !== '' && helper.getValueByKey(this.state.filterData.bodyType,String(agentDetails.req_body_type)))
                                ?
                                <li>{helper.getValueByKey(this.state.filterData.bodyType,String(agentDetails.req_body_type))}</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['model_ids'] && agentDetails['model_ids'] !== '' && helper.getValueByKey(this.state.filterData.makeModel,String(agentDetails.model_ids)))
                                ?
                                <li>
                                    {helper.getValueByKey(this.state.filterData.makeModel, String(agentDetails.model_ids))}                                
                                </li>
                                :
                                ''
                            }
                            {
                                (agentDetails['purpose'] && agentDetails['purpose'].trim() !== '' && helper.getValueByKey(PURPOSE_OF_PURCHASE_CAR,String(agentDetails.purpose)))
                                ?
                                <li>{helper.getValueByKey(PURPOSE_OF_PURCHASE_CAR,String(agentDetails.purpose))}</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['seat_num'] && agentDetails['seat_num'] !== '' && helper.getValueByKey(NO_SEAT_REQ,String(agentDetails.seat_num)))
                                ?
                                <li>{helper.getValueByKey(NO_SEAT_REQ,String(agentDetails.seat_num))}</li>
                                :
                                ''
                            }
                            
                            {
                                (agentDetails['drive_car'] && agentDetails['drive_car'] !== '' && helper.getValueByKey(DRIVE_CAR,String(agentDetails.drive_car)))
                                ?
                                <li>{helper.getValueByKey(DRIVE_CAR,String(agentDetails.drive_car))}</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['buyer_type'] && (agentDetails['buyer_type']) !== '' && helper.getValueByKey(BUYER_TYPE,String(agentDetails.buyer_type)))
                                ?
                                <li>{helper.getValueByKey(BUYER_TYPE,String(agentDetails.buyer_type))}</li>
                                :
                                ''
                            }
                            
                        </ul>
                        <ul className="requirements-list">
                            {
                                (agentDetails['max_km'] && agentDetails['max_km'].trim() !== '' && helper.getValueByKey(this.state.filterData.kmRange,String(agentDetails.max_km)))
                                ?
                                <li>{helper.getValueByKey(this.state.filterData.kmRange,String(agentDetails.max_km))} Km</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['fuel'] && agentDetails['fuel'].trim() !== '' && helper.getValueByKey(this.state.filterData.fuelType,String(agentDetails.fuel)))
                                ?
                                <li>{helper.getValueByKey(this.state.filterData.fuelType,String(agentDetails.fuel))}</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['owner'] && agentDetails['owner'].trim() !== '' && helper.getValueByKey(this.state.filterData.owner,String(agentDetails.owner)))
                                ?
                                <li>{helper.getValueByKey(this.state.filterData.owner,String(agentDetails.owner))}</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['transmission'] && agentDetails['transmission'].trim() !== '' && helper.getValueByKey(this.state.filterData.transmission,String(agentDetails.transmission))) 
                                ?
                                <li>{helper.getValueByKey(this.state.filterData.transmission,String(agentDetails.transmission))}</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['min_avg_daily_travel_km'] && agentDetails['min_avg_daily_travel_km'] !== '' && helper.getValueByKey(this.state.filterData.transmission,String(agentDetails.transmission))) 
                                ?
                                <li>{String(agentDetails.min_avg_daily_travel_km)} - {String(agentDetails.max_avg_daily_travel_km)}</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['color'] && agentDetails['color'].trim() !== '' && this.state.filterData.color && helper.getValueByKey(this.state.filterData.color,String(agentDetails.color)))
                                ?
                                <li>{helper.getValueByKey(this.state.filterData.color,String(agentDetails.color))}</li>
                                :
                                ''
                            }
                            {
                                (agentDetails['city_ids'] && agentDetails['city_ids'].trim() !== '' && helper.getValueByKey(this.state.cityListData,String(agentDetails.city_ids)))
                                ?
                                <li>{helper.getValueByKey(this.state.cityListData, String(agentDetails.city_ids))}</li>
                                :
                                ''
                            }

                            
                        </ul>
                    </div>)
                    :
                    <EditRequirements submitReqForm={this.submitReqForm} filterData={this.state.filterData} mmvList={this.state.mmvList} makeList={this.state.makeList} cancelBtn={() => this.setState({ reqform: !this.state.reqform, showEditButton: true })} cityList={this.state.cityListData} /> }
                </div>
            </div>
                                              
        );
    }
}

const mapStateToProps = (state, ownProps) => ({    
    agentDetails:state.lead.agentDetails,
    leadDetails: state.lead.listLead,
    filterList: state.lead.filterList,
    makeModelVersionList: state.lead.makeModelVersionList,
    requirementResponse: state.lead.requirementResponse
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            listCity: bindActionCreators(
                InventoryActions.listCity,
                dispatch
            ),
            submitRequirementForm:bindActionCreators(
                LeadActions.submitRequirementForm,
                dispatch
            ),
            getReqAgentDetails:bindActionCreators(
                LeadActions.getReqAgentDetails,
                dispatch
            ),
            listLead: bindActionCreators(
                LeadActions.listLead,
                dispatch
            )
        }
    }
}

export default withTranslation('lead')(connect(mapStateToProps, mapDispatchToProps)(RequirmentCard));