import MasterService from './MasterService';

const CategoryService = {
    ...MasterService,
    
    getCategoryList(postdata){
        return this.post('/category/listCategory',postdata) 
    },
    updateCategoryDetails(postdata){
        return this.post('/category/editCategory',postdata) 
    },
    saveCategoryDetails(postdata){
        return this.post('/category/saveCategory',postdata) 
    },
    updateStatus(postdata){
        return this.post('/category/updateStatus',postdata) 
    },
    getAllUserList(){
        return this.post('/user/listAllUser') 
    }

}

export default CategoryService;
