import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../elements/Input";
import { default as config } from '../../../config/config';
import { withTranslation } from 'react-i18next';

class ExchangeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            makeList: [],
            modelList: [],
            versionList: [],
            mmvList: [],
            yearRange : [],
            selectedOption : [],
            error : {},
            leadData : []
        }
    }

    componentDidMount = () => {
        
        let { mmvList: { make }, mmvList,yearRange ,leadData} = this.props;
        make = make.map(function (e) {
            return { label: e.make, value: e.id }
        })
        
        this.setState({
            makeList: make,
            mmvList: mmvList,
            yearRange : yearRange,
            leadData : leadData
        })
    }
    // getModelVariantBasedOnMake = () => {
    //     model = model.map(function(e){
    //         return {label : e.m,value : e.id}
    //     })
    //     version = version.map(function(e){
    //         return {label : e.vn,value : e.vn_id}
    //     })
    // }
    getModelVarient = (key,value) => {
        let {mmvList:{model,version},selectedOption,modelList,versionList} = this.state
        if(key === "make"){
            delete(selectedOption['model'])
            delete(selectedOption['version'])
            modelList = [];versionList = [];
            modelList = model.filter(e=>e.mk_id === value).map(function(e){
                return {
                    label : e.m,
                    value : e.id
                }
            });
        }else if (key === "model"){
            delete(selectedOption['version'])
            versionList = []
            versionList = version.filter(e=>e.md_id === value).map(function(e){
                return {label : e.vn,value : e.vn_id}
            })
        }
        this.setState({
            versionList : versionList,
            modelList : modelList,
            selectedOption : selectedOption
        })
    }
    handleChange = (selectedKey,selectedValue) => {
        
        let {selectedOption,error} = this.state;
        delete(error[selectedKey]);
        const {target:{value} = {}} = selectedValue
        if(value){
            ////// input element
            selectedOption[selectedKey] = value
        }else{
            selectedOption[selectedKey] = selectedValue
        }
        if(['make','model'].includes(selectedKey)){
            this.getModelVarient(selectedKey,selectedValue.value);
        }
        this.setState({
            selectedOption : selectedOption
        })
    }
    validateSubmit = () => {
        let {selectedOption:{make,model,version,manufacture_year,drive_kms,color},error} = this.state;
        error = {}
        if(!make) error['make'] = this.props.t("exchangeInfo.error.make")
        if(!model) error['model'] = this.props.t("exchangeInfo.error.model")
        if(!version) error['version'] = this.props.t("exchangeInfo.error.version")
        if(!manufacture_year) error['manufacture_year'] = this.props.t("exchangeInfo.error.manufacture_year")
        if(!drive_kms) error['drive_kms'] = this.props.t("exchangeInfo.error.drive_kms")
        if(!color) error['color'] = this.props.t("exchangeInfo.error.color")
        if(isNaN(drive_kms)) error['drive_kms'] = this.props.t("exchangeInfo.error.drive_kms_int")

        if(Object.keys(error).length){
            
            this.setState({
                error : error
            })
            return false;
        }
        return true;
    }
    saveExchange =  (e) => {
        e.preventDefault();
        if(this.validateSubmit()){
            let {selectedOption:{make,model,version,manufacture_year,drive_kms,color},leadData:{id,customer_city_id,customer_mobile,customer_name,customer_email}} = this.state
            let data  = {};
            data['make_id'] = make.value
            data['model_id'] = model.value
            data['version_id'] = version.value
            data['manufacture_year'] = manufacture_year.value
            data['drive_kms'] = drive_kms
            data['color'] = color.value
            data['customer_city_id'] = customer_city_id
            data['customer_mobile'] = customer_mobile
            data['customer_name'] = customer_name
            data['customer_email'] = customer_email;
            data['lead_id'] = id;
            ////send data to api
            this.props.makeExchangeRequest(data);
        }
    }
    render() {
        
        const { makeList, modelList, versionList,yearRange,selectedOption,error } = this.state;
        return (
            <div className="row">
                <div className="container-fliud">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="col-sm-4">
                                <label>{this.props.t("exchangeInfo.label.make")}</label>
                                <Select
                                    id="make"
                                    name="make"
                                    options={makeList}
                                    onChange={this.handleChange.bind(this,"make")}
                                    value={selectedOption.make?selectedOption.make:''}
                                />
                                {error.make && <div class="error-block">{error.make}</div>}
                            </div>
                            <div className="col-sm-4">
                            <label>{this.props.t("exchangeInfo.label.model")}</label>
                                <Select
                                    id="model"
                                    name="model"
                                    options={modelList}
                                    onChange={this.handleChange.bind(this,"model")}
                                    value={selectedOption.model?selectedOption.model:''}
                                />
                                {error.model && <div class="error-block">{error.model}</div>}
                            </div>
                            <div className="col-sm-4">
                            <label>{this.props.t("exchangeInfo.label.version")}</label>
                                <Select
                                    id="version"
                                    name="version"
                                    options={versionList}
                                    onChange={this.handleChange.bind(this,"version")}
                                    value={selectedOption.version?selectedOption.version:''}
                                />
                                {error.version && <div class="error-block">{error.version}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="col-sm-4">
                            <label>{this.props.t("exchangeInfo.label.manufacture_year")}</label>
                                <Select
                                    id="manufactureyear"
                                    name="manufactureyear"
                                    options={yearRange}
                                    onChange={this.handleChange.bind(this,"manufacture_year")}
                                    value={selectedOption.manufacture_year?selectedOption.manufacture_year:''}
                                />
                                {error.manufacture_year && <div class="error-block">{error.manufacture_year}</div>}
                            </div>
                            <div className="col-sm-4">
                                <InputField
                                    
                                    id="drivenkms"
                                    name="drive_kms"
                                    label={this.props.t("exchangeInfo.label.drive_kms")}
                                    onChange={this.handleChange.bind(this,"drive_kms")}
                                    error={error.drive_kms}
                                    value={selectedOption.drive_kms?selectedOption.drive_kms:''}
                                />
                            </div>
                            <div className="col-sm-4">
                            <label>{this.props.t("exchangeInfo.label.color")}</label>
                                <Select
                                    id="color"
                                    name="color"
                                    options={config.constants.COLOUR_ARR}
                                    onChange={this.handleChange.bind(this,"color")}
                                    value={selectedOption.color?selectedOption.color:''}
                                />
                                {error.color && <div class="error-block">{error.color}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <input type="submit" value="SAVE" className="btn btn-primary" onClick={this.saveExchange}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation('lead')(ExchangeInfo);
