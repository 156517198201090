import MasterService from './MasterService';

const SourceService = {
    ...MasterService,
    
    getSourceTypeList(postdata){
        return this.post('/source/listSourceType',postdata) 
    },
    updateSourceTypeDetails(postdata){
        return this.post('/source/editSourceType',postdata) 
    },
    saveSourceTypeDetails(postdata){
        return this.post('/source/saveSourceType',postdata) 
    },
    updateSourceTypeStatus(postdata){
        return this.post('/source/updateSourceTypeStatus',postdata) 
    },
    getSourceListData(postdata){
        return this.post('/source/listSource',postdata) 
    },
    updateSourceDetails(postdata){
        return this.post('/source/editSource',postdata) 
    },
    saveSourceDetails(postdata){
        return this.post('/source/saveSource',postdata) 
    }, 
    updateSourceStatus(postdata){
        return this.post('/source/updateSourceStatus',postdata) 
    },
    getSubSourceList(postdata){
        return this.post('/source/listSubSource',postdata) 
    },
    updateSubSourceDetails(postdata){
        return this.post('/source/editSubSource',postdata) 
    },
    saveSubSourceDetails(postdata){
        return this.post('/source/saveSubSource',postdata) 
    },
    updateSubSourceStatus(postdata){
        return this.post('/source/updateSubSourceStatus',postdata) 
    },
    getAllUserList(){
        return this.post('/user/listAllUser') 
    }

}

export default SourceService;
