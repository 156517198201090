import React, { Component } from "react";

import InputField from "../../elements/Input";
import CheckBox from "../../elements/Radiobox";
import DatePicker from "react-datepicker";
import Rating from "react-rating";
import { Range, getTrackBackground } from "react-range";
import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../../common/LanguageSelector';
import queryString from 'query-string';
import { default as config } from '../../../config/config';


class ViewFeedBackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackForm: {
        testDriveDate: null,
        testDriveTime: 0,
        consultantBehaviour:0,
        carDetailsExplained:'',
        carFeaturesExploreIsAllowed:'',
        wantTobuyCar:'',
        overallExperience:0,
        otherTime:0,
        testDriveTimeOption:''
      },
      errors:{},
      loading: false,
      queryParams:{},
      STEP: 1,
      MIN: 0,
      MAX: 5
    };
  }


  componentDidMount = async () => {
    this._isMounted = true;
    let queryParams = queryString.parse(this.props.location.search);
    await this.setState({queryParams});

    //GET NOTIFICATION DETAILS
    this.getDetails();
  }


  handleChange = (name, value) => async (event) => {

  }

  getDetails = async () => {
    let updatedValue = {...this.state.feedbackForm};

    let details = await this.props.actions.getDetails(this.state.queryParams);

    if(details.status === 200 && details.data && +details.data.status === 200){
      let feedbackData = details['data']['data'][0]['feedback_data'] ? JSON.parse(details['data']['data'][0]['feedback_data']) : {};
      updatedValue['testDriveDate'] = feedbackData['test_drive_date'];
      updatedValue['consultantBehaviour'] = feedbackData['consultant_behaviour_rating'];
      updatedValue['carDetailsExplained'] = feedbackData['car_details_explained'];
      updatedValue['carFeaturesExploreIsAllowed'] = feedbackData['car_features_explore_allowed'];
      updatedValue['wantTobuyCar'] = feedbackData['want_to_buy_car'];
      updatedValue['overallExperience'] = feedbackData['overall_experience'];
      updatedValue['testDriveTime'] = (feedbackData['test_drive_time_taken']) ? (feedbackData['test_drive_time_taken']).toString() : '';

      if(feedbackData['test_drive_time_taken']){
        if([10, 20, 30].includes(feedbackData['test_drive_time_taken'])){
          updatedValue['testDriveTimeOption'] = ((feedbackData['test_drive_time_taken']).toString()).replace('0','');
        }else{
          updatedValue['testDriveTimeOption'] = '4';
          updatedValue['otherTime'] = 1 
        }
      }
    }
    await this.setState({feedbackForm: updatedValue});

  }

  handleStarClick(event) {
    this.setState({ value: undefined });
  }     

  render() {
    const { feedbackForm, STEP, MIN, MAX } = this.state;
    const { YES_NO, WANT_TO_BUY_CAR_OPTION, TIMINGS } = config.constants;

    return (
      <div className="container">
        <div className="card feedbackForm">
          <div className="card-heading">
            <h2 className="card-titile">Test Drive Feedback Survey</h2>
          </div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label className="headLabel">Date of the test drive <span>*</span></label>
                <DatePicker
                  id="testDriveDate"
                  className="form-control"
                  dateFormat={"dd-MM-yyyy"}
                  selected={feedbackForm['testDriveDate'] ? new Date(feedbackForm['testDriveDate']) : null}
                  // onChange={this.handleChange('testDriveDate')}
                  placeholderText="Select Date"
                  readOnly
                />
              </div>
              <div className="form-group">
                <label className="headLabel">
                  How long was your test drive?
                </label>
                <ul>
                  {TIMINGS.map((el, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={el.label}
                        name="drivetime"
                        id={el.id}
                        checked={ (feedbackForm['testDriveTimeOption'] && el.id === feedbackForm['testDriveTimeOption']) ? true : false}
                        // value={feedbackForm['testDriveTimeOption']}
                        onChange={ this.handleChange('testDriveTimeOption', el)}
                        readonly
                      />
                    </li>
                  ))}
                  {
                    feedbackForm['otherTime']
                    ?
                      <li className="nolabel">
                        <InputField
                          id="otherReason"
                          name="otherReason"
                          type="text"
                          placeholder="Comment"
                          value={feedbackForm['testDriveTime']}
                          readOnly
                        />
                      </li>
                  :
                    ''
                  }
                  
                </ul>
              </div>
              <div className="form-group">
                <label className="headLabel">
                  How would you rate the sales consultant's behavior? <span>*</span>
                </label>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      marginTop: "4em",
                    }}
                  >
                    <Range
                      step={STEP}
                      min={MIN}
                      max={MAX}
                      values={[feedbackForm.consultantBehaviour]}
                      disabled
                      // onChange={(rangeValues)=> this.setState({ 'feedbackForm': {...feedbackForm, 'consultantBehaviour': ''+rangeValues[0]}} )}
                      renderTrack={({ props, children }) => (
                        <div
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          style={{
                            ...props.style,
                            height: "36px",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <div
                            ref={props.ref}
                            style={{
                              height: "5px",
                              width: "100%",
                              borderRadius: "12px",
                              background: getTrackBackground({
                                values: [feedbackForm.consultantBehaviour],
                                colors: ["#e46536", "#ccc"],
                                min: MIN,
                                max: MAX,
                              }),
                              alignSelf: "center",
                            }}
                          >
                            {children}
                          </div>
                        </div>
                      )}
                      renderThumb={({ props, isDragged }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "36px",
                            width: "36px",
                            border: "solid 2px #e46536",
                            borderRadius: "50%",
                            backgroundColor: "#FFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            outline: "none",
                            boxShadow: isDragged
                              ? "0px 0px 0px 12px rgba(228, 101, 54, 0.2)"
                              : "0px 0px 0px 1px  rgba(228, 101, 54, 0.0)",
                          }}
                        >
                          <div className="rangeoutput">
                            <output
                              style={{
                                ...props.style,
                                height: "36px",
                                width: "36px",
                                border: "solid 2px #e46536",
                                borderRadius: "8px",
                                fontSize: "16px",
                                fontWeight: "bold",
                                backgroundColor: "#e46536",
                                color: "#fff",
                                marginTop: "-62px",
                                marginLeft: "-16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                outline: "none",
                                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
                              }}
                              id="output"
                            >
                              {[feedbackForm.consultantBehaviour]}
                            </output>
                          </div>
                          <div
                            style={{
                              height: "5px",
                              width: "5px",
                              backgroundColor: isDragged
                                ? "#e46536"
                                : "#e46536",
                              boxShadow: isDragged
                                ? "0px 12px 6px rgba(228, 101, 54, 0.2)"
                                : "0px 12px 6px rgba(228, 101, 54, 0.2)",
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="headLabel">
                  Did they address your doubts and properly brief you about the
                  car?
                </label>
                <ul>
                  {YES_NO.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="doubts"
                        id={item.id}
                        checked={item.value === feedbackForm['carDetailsExplained'] ? true : false}
                        value={feedbackForm['carDetailsExplained']}
                        onChange={this.handleChange('carDetailsExplained', item)}
                        readOnly
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="form-group">
                <label className="headLabel">
                  Were you allowed to explore all the features of the car
                  comfortably?
                </label>
                <ul>
                  {YES_NO.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="carFeaturesExploreIsAllowed"
                        id={item.id}
                        checked={item.value === feedbackForm['carFeaturesExploreIsAllowed'] ? true : false}
                        value={feedbackForm['carFeaturesExploreIsAllowed']}
                        onChange={this.handleChange('carFeaturesExploreIsAllowed', item)}
                        readOnly
                      />
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="form-group">
                <label className="headLabel">
                  Rate the car on the following grounds: <span>*</span>
                </label>
              </div> */}
              <div className="form-group ratingbox">
                
              </div>
              <div className="form-group">
                <label className="headLabel">
                  After taking the test drive, were you more likely to buy the car ? <span>*</span>
                </label>
                <ul>
                  {WANT_TO_BUY_CAR_OPTION.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="wantTobuyCar"
                        id={item.id}
                        checked={item.id === feedbackForm['wantTobuyCar'] ? true : false}
                        value={feedbackForm['wantTobuyCar']}
                        onChange={this.handleChange('wantTobuyCar', item)}
                        readOnly
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="form-group ratingbox">
                <label className="headLabel">
                  How would you rate your test drive experience overall? <span>*</span>
                </label>
                <div className="rating10">
                  <Rating
                    stop={10}
                    initialRating={feedbackForm['overallExperience']}
                    readonly
                    emptySymbol={[
                      <span className="ratingPoints">1</span>,
                      <span className="ratingPoints">2</span>,
                      <span className="ratingPoints">3</span>,
                      <span className="ratingPoints">4</span>,
                      <span className="ratingPoints">5</span>,
                      <span className="ratingPoints">6</span>,
                      <span className="ratingPoints">7</span>,
                      <span className="ratingPoints">8</span>,
                      <span className="ratingPoints">9</span>,
                      <span className="ratingPoints">10</span>,
                    ]}
                    fullSymbol={[
                      <span className="ratingPoints filled">1</span>,
                      <span className="ratingPoints filled">2</span>,
                      <span className="ratingPoints filled">3</span>,
                      <span className="ratingPoints filled">4</span>,
                      <span className="ratingPoints filled">5</span>,
                      <span className="ratingPoints filled">6</span>,
                      <span className="ratingPoints filled">7</span>,
                      <span className="ratingPoints filled">8</span>,
                      <span className="ratingPoints filled">9</span>,
                      <span className="ratingPoints filled">10</span>,
                    ]}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <footer>
            <LanguageSelector />
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenExpired: state.auth.tokenExpired
  }
}

const mapDispatchToProps = dispatch => {
  return {
            actions: {
              getDetails: bindActionCreators(
                LeadActions.feedbackFormValidity,
                dispatch
              )
            }
          }
}

export default withTranslation('common') (connect(mapStateToProps, mapDispatchToProps)(ViewFeedBackForm));
