import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import L1LeadSnapshot from './l1-leads-snapshot-table';
import L2LeadSnapshot from './l2-leads-snapshot-table';
import ClosedLeadSnapshot from './closed-leads-snapshot-table';

class SnapShotResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
        searchResult:props.searchResult,
        totalRecord:props.totalRecord,
        loading: false,
        pageloading: false,
        totalCalculationObject:props.totalCalculationObject,
        statusClassname: {
            'active': 'nav-item active',
            'removed': 'nav-item',
            'all': 'nav-item'
        },
        tabCondition:{'TAB-1':true,'TAB-2':false,'TAB-3':false},
    }
  }
  static getDerivedStateFromProps(nextprops, prevState) {
    var updatedObj = {}
    if (prevState.searchResult !== nextprops.searchResult) {
      updatedObj['searchResult'] = nextprops.searchResult;
    }
    if (prevState.totalRecord !== nextprops.totalRecord) {
        updatedObj['totalRecord'] = nextprops.totalRecord;
    }
    if(nextprops.loading !== prevState.loading){
        updatedObj['loading'] = nextprops.loading;
    }

    if(nextprops.pageloading !== prevState.pageloading){
        updatedObj['pageloading'] = nextprops.pageloading;
    }
    if(nextprops.totalCalculationObject !== prevState.totalCalculationObject){
        updatedObj['totalCalculationObject'] = nextprops.totalCalculationObject;
    }
    return updatedObj;
}

handleClick = (index) => {
    let {tabCondition} = this.state;
    if(index === 1){
        tabCondition["TAB-1"] = !tabCondition["TAB-1"];
        tabCondition["TAB-2"] = !tabCondition["TAB-1"];
        tabCondition["TAB-3"] = !tabCondition["TAB-1"];
    }else if(index === 2){
        tabCondition["TAB-2"] = !tabCondition["TAB-2"];
        tabCondition["TAB-1"] = !tabCondition["TAB-2"];
        tabCondition["TAB-3"] = !tabCondition["TAB-2"];
    }
    else if(index === 3){
        tabCondition["TAB-3"] = !tabCondition["TAB-3"];
        tabCondition["TAB-2"] = !tabCondition["TAB-3"];
        tabCondition["TAB-1"] = !tabCondition["TAB-3"];
    }
    let selectedTab = (tabCondition["TAB-1"]) ? 'TAB-1' : (tabCondition["TAB-2"]) ? 'TAB-2' : 'TAB-3';
    if(typeof this.props.setSelectedTab === 'function'){
        this.props.setSelectedTab(selectedTab)
    }
    this.setState( { tabCondition } );
}


  render() {
    const {searchResult,totalCalculationObject} = this.state;
    return (
      <div className="result-wrap">
                    <div className="card">
                        <div className="card-heading">
                            <div className="tab-content">
                    
                            <ul className="list-inline nav-tabs">
                                <li className={this.state.tabCondition["TAB-1"] ? "nav-item active " :"nav-item "}  onClick={() => this.handleClick(1)} >
                                    <div>{' L1 LEADS SNAPSHOT'}</div>
                                </li>
                                <li className={this.state.tabCondition["TAB-2"] ? "nav-item active " :"nav-item "}   onClick={() => this.handleClick(2)} >
                                    <div>{' L2 LEADS SNAPSHOT'} </div>
                                </li>
                                <li className={this.state.tabCondition["TAB-3"] ? "nav-item active " :"nav-item "}   onClick={() => this.handleClick(3)} >
                                    <div>{' CLOSED LEADS SNAPSHOT'} </div>
                                </li>
                            </ul>
                            </div>
                        </div>

                        <div className="table-responsive">
                            {(this.state.tabCondition["TAB-1"]) ? 
                                <L1LeadSnapshot searchResult={searchResult} totalCalculationObject={totalCalculationObject}/> : ''}
                            {(this.state.tabCondition["TAB-2"]) ? 
                                <L2LeadSnapshot searchResult={searchResult} totalCalculationObject={totalCalculationObject}/> : ''}
                            {(this.state.tabCondition["TAB-3"]) ? 
                                <ClosedLeadSnapshot searchResult={searchResult} totalCalculationObject={totalCalculationObject}/> : ''}
                        </div>
                    </div>
                </div>
    );
  }
}
export default withTranslation('report') (SnapShotResult);
