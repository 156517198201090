import MasterService from './MasterService';

const CityService = {
    ...MasterService,
    
    updateCityDetails(postdata){
        return this.post('/city/editCity',postdata) 
    },
    saveCityDetails(postdata){
        return this.post('/city/saveCity',postdata) 
    },
    updateStatus(postdata){
        return this.post('/city/updateStatus',postdata) 
    },
    getAllUserList(){
        return this.post('/user/listAllUser') 
    },
    getCityState(){
        return this.get('/inventory/getStateCityList');
       // return this.post('/city/getCityState',postdata) 
    }

}

export default CityService;
