import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class ClosedLeadSnapshot extends Component {
  constructor(props) {
    super(props);
    this.state = {
        searchResult:props.searchResult,
        totalRecord:props.totalRecord,
        loading: false,
        pageloading: false,
        totalCalculationObject:props.totalCalculationObject
    }
  }
  static getDerivedStateFromProps(nextprops, prevState) {
    var updatedObj = {}
    if (prevState.searchResult !== nextprops.searchResult) {
      updatedObj['searchResult'] = nextprops.searchResult;
    }
    if (prevState.totalRecord !== nextprops.totalRecord) {
        updatedObj['totalRecord'] = nextprops.totalRecord;
    }
    if(nextprops.loading !== prevState.loading){
        updatedObj['loading'] = nextprops.loading;
    }

    if(nextprops.pageloading !== prevState.pageloading){
        updatedObj['pageloading'] = nextprops.pageloading;
    }

    if(nextprops.totalCalculationObject !== prevState.totalCalculationObject){
        updatedObj['totalCalculationObject'] = nextprops.totalCalculationObject;
    }
    return updatedObj;
}


  render() {
   // const {searchResult} = this.state;
    return (
      
        <table className="table table-bordered table-hover table-striped deliveryTable">
            <thead>
            <tr>
            	<th>{this.props.t('leadSnapshot.closedLeads.cluster')}</th>
            	<th colSpan="10">{this.props.t('leadSnapshot.closedLeads.closed')}</th>                  
            </tr>
            <tr>
            	<th>{this.props.t('leadSnapshot.closedLeads.cluster')}</th>
            	<th>{this.props.t('leadSnapshot.closedLeads.notInterested')}</th>
                <th>{this.props.t('leadSnapshot.closedLeads.wrongNumber')}</th>
                <th>{this.props.t('leadSnapshot.closedLeads.purchasedFromGaadiIndividual')}</th>
                <th>{this.props.t('leadSnapshot.closedLeads.purchasedFromNonGaadiIndividual')}</th>
                <th>{this.props.t('leadSnapshot.closedLeads.purchasedFromNonGaadiDealer')}</th>               
                <th >{this.props.t('leadSnapshot.closedLeads.purchasedUsedCar')}</th>               
                <th>{this.props.t('leadSnapshot.closedLeads.attemptElapsed')}</th>                
                <th>{this.props.t('leadSnapshot.closedLeads.newCar')}</th>  
                <th>{this.props.t('leadSnapshot.closedLeads.blocked')}</th>  
                <th>{this.props.t('leadSnapshot.closedLeads.outOfCity')}</th>
            </tr>
        </thead>

            <tbody>
            {(this.state.searchResult.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="11"><h6 className="text-center text-danger text-bold">{this.props.t('salesFunnel.NoRecordFound')}</h6></td></tr> : null}
        {(this.state.searchResult && this.state.searchResult.length !== 0) && this.state.searchResult.map((reportData, k) =>
                <tr key={k}>
                    <td>{reportData.cluster_name}</td>
                    <td>{(reportData.totalClosedInt) ? reportData.totalClosedInt : ''}{(reportData.totalClosedInt && reportData.allLeads) ? ' ('+((reportData.totalClosedInt/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalClosedWrong) ? reportData.totalClosedWrong : ''}{(reportData.totalClosedWrong && reportData.allLeads) ? ' ('+((reportData.totalClosedWrong/reportData.allLeads)*100)+'%' : ''}</td>
                    <td>{(reportData.totalClosedGaadiIndividual) ? reportData.totalClosedGaadiIndividual : ''}{(reportData.totalClosedGaadiIndividual && reportData.allLeads) ? ' ('+((reportData.totalClosedGaadiIndividual/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalClosedNonGaadiIndividual) ? reportData.totalClosedNonGaadiIndividual : ''}{(reportData.totalClosedNonGaadiIndividual && reportData.allLeads) ? ' ('+((reportData.totalClosedNonGaadiIndividual/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalClosedNonGaadiDealer) ? reportData.totalClosedNonGaadiDealer : ''}{(reportData.totalClosedNonGaadiDealer && reportData.allLeads) ? ' ('+((reportData.totalClosedNonGaadiDealer/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalClosedPurchaseCar) ? reportData.totalClosedPurchaseCar : ''}{(reportData.totalClosedPurchaseCar && reportData.allLeads) ? ' ('+((reportData.totalClosedPurchaseCar/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalClosedAttemptElapsed) ? reportData.totalClosedAttemptElapsed : ''}{(reportData.totalClosedAttemptElapsed && reportData.allLeads) ? ' '+((reportData.totalClosedAttemptElapsed/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalPurchasedNewCar) ? reportData.totalPurchasedNewCar : ''}{(reportData.totalPurchasedNewCar && reportData.allLeads) ? ' ('+((reportData.totalPurchasedNewCar/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalClosedBlocked) ? reportData.totalClosedBlocked : ''}{(reportData.totalClosedBlocked && reportData.allLeads) ? ' ('+((reportData.totalClosedBlocked/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalClosedOutCity) ? reportData.totalClosedOutCity : ''}{(reportData.totalClosedOutCity && reportData.allLeads) ? ' ('+((reportData.totalClosedOutCity/reportData.allLeads)*100)+'%)' : ''}</td>
                   
                </tr>
                )}
                <tr className="totalvalue">
                    <td>{this.props.t('salesFunnel.total')}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    
                </tr>
                {
                    (this.state.loading) ? <tr><td className="loading" colSpan="11"></td></tr> : null
                }
            </tbody>
        </table>
                        
    );
  }
}
export default withTranslation('report') (ClosedLeadSnapshot);
