import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as helper from '../../../../config/helper';
import { default as config } from './../../../../config/config';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import { toast } from 'react-toastify';

class SearchSalesFunnel extends Component {
    constructor(props) {
        super(props);
        this.multiselectRef={
            'source':React.createRef(),
            'sub_source':React.createRef(),
            'city':React.createRef(),
            'cluster':React.createRef()
        }
        this.state = {
        selectedOption: null,
        date: new Date(),
        advanceSearchEnable: false,
        formData: {
            'report_type':'1',
            'lead_type':'1',
            from_date: new Date().toISOString().split('T')[0],
            to_date: new Date().toISOString().split('T')[0]

        },
        filterData: props.prefilledData,
        citieslist:[],
        clusterlist:[],
        sourceList:[],
        subSourceList:[],
        loading: true
      };
    }

    componentDidMount = () => {
        this.searchReport();
    }

    ToogleAdvanceSearch = () => {
        let advanceSearchEnable = this.state.advanceSearchEnable;
        if (advanceSearchEnable) {
          this.setState({ advanceSearchEnable: false });
        } else {
          this.setState({ advanceSearchEnable: true });
        }
      };

    handleDateChange = (selectedKey) => async e => {
        let formData = {...this.state.formData};
        formData[selectedKey] = e ? await helper.dateFormat(e) : '';
        this.setState({ formData:formData });
    }
    handleChange = (key, event) => {
        let formData = {...this.state.formData};

        if(key === 'source'){

            if(typeof this.props.getSubSourceList === 'function') {
                this.props.getSubSourceList({'source_id':event.value})
            } 
        }
        formData[key] = event ? event.value : ''
        this.setState({ formData: formData });      
        
      };
      searchFilter = async() => {
        //let formValid = await this.validateSearchInput();
        if(typeof this.props.getFilterList === 'function') {
            this.props.getFilterList(this.state.formData)
        }        
    }
    static getDerivedStateFromProps(nextprops, prevState) {
        var updatedObj = {formData: prevState.formData}
        if (prevState.citieslist !== nextprops.prefilledData.cities) {
            updatedObj['citieslist'] = helper.getDataInKeyValueFormat(nextprops.prefilledData.cities, 'city');
        }
        if (prevState.clusterlist !== nextprops.prefilledData.cluster) {
            updatedObj['clusterlist'] = helper.getDataInKeyValueFormat(nextprops.prefilledData.cluster, 'cluster');
        }
        if (prevState.sourceList !== nextprops.prefilledData.sourceList) {
            updatedObj['sourceList'] = helper.getDataInKeyValueFormat(nextprops.prefilledData.sourceList, 'source');
        }
        if (prevState.subSourceList !== nextprops.prefilledData.subSourceList) {
            updatedObj['subSourceList'] = helper.getDataInKeyValueFormat(nextprops.prefilledData.subSourceList, 'sub_source');
        }

        updatedObj['btnStatus'] = !nextprops.loading;

        if(prevState.formData){
            if(prevState.formData.from_date === ''){
                updatedObj['formData']['from_date'] = new Date().toISOString().split('T')[0]

            }
            if(prevState.formData.to_date === ''){
                updatedObj['formData']['to_date'] = new Date().toISOString().split('T')[0]
            }
            if(prevState.formData.report_type === ''){
                updatedObj['formData']['report_type'] = '1'
            }
            if(prevState.formData.lead_type === ''){
                updatedObj['formData']['lead_type'] = '1'
            }
        }
        return updatedObj;
    }

    onSelect(key,selectedList, selectedItem) {
        let formData = this.state.formData;
        let existingVal = (formData[key]) ? ((typeof formData[key] === 'string') ? formData[key].split(',') : formData[key]) : []
        existingVal.push(selectedItem.value);
        formData[key] = existingVal;
        if(key === 'source'){
            if(typeof this.props.getSubSourceList === 'function') {
                this.props.getSubSourceList({'source_id':selectedItem.value})
            } 
        }
        this.setState({ formData: formData });
    }
     
    onRemove(key,selectedList, removedItem) {
        let formData = this.state.formData;
        let existingVal = (formData[key]) ? formData[key] : [];
        existingVal = _.filter(existingVal, function(currentVal) {
            return currentVal !== removedItem.value;
        });
        if(key === 'source' && !selectedList.length){
            formData['sub_source'] = '';
            this.setState({subSourceList:[]})  
            this.multiselectRef.source.current.resetSelectedValues();   
        }
        if(key === 'cluster' && !selectedList.length){
            formData['city'] = '';
            this.multiselectRef.city.current.resetSelectedValues();   
        }

        formData[key] = existingVal;
        this.setState({ formData: formData });
        
    }

    searchReport = ()=>{
        let formData = this.state.formData;

        let postData = {}
        Object.keys(formData).forEach(el=>{
            if(formData[el] && formData[el].length){
                postData[el] = formData[el]
            }
        })

        if(postData['from_date'] > postData['to_date']){
            toast.error('Start date should be less than End date')
            return false;
        }

        if(typeof this.props.getReportData === 'function'){
            this.setState({btnStatus: false})
            this.props.getReportData(postData)
        }
        
    }

    reset = async() => {
        let formData = {
            'report_type':'1',
            'lead_type':'1',
            from_date: new Date().toISOString().split('T')[0],
            to_date: new Date().toISOString().split('T')[0]
        }
        // this.multiselectRef.city.current.resetSelectedValues();
        // this.multiselectRef.cluster.current.resetSelectedValues();
        this.multiselectRef.source.current.resetSelectedValues();
        // this.multiselectRef.sub_source.current.resetSelectedValues();
        await this.setState({formData:formData})
        this.searchReport();
    }

    render() {
        const {formData,sourceList,btnStatus}  = this.state;
        const {REPORT_TYPE,LEAD_TYPE} = config.constants;
        return (
            
            <div className="search-wrap">
            <ul className="search-flex">
            <li className="searchitems doublew form-group">
                    <label>{this.props.t('salesFunnel.search.startDate')}</label>
                    <DatePicker 
                        className="form-control"
                        onChange={this.handleDateChange('from_date')}
                        // selected={(formData && formData.from_date) ? formData.from_date : this.state.date}
                        dateFormat={config.constants.dateFormatDMY}
                        // value={formData.from_date}
                        onKeyDown={e => e.preventDefault()}
                        selected={formData.from_date ? new Date(formData.from_date) : null}
                        isClearable
                        maxDate={new Date()}
                    />  
                </li>
                <li className="searchitems doublew form-group">
                    <label>{this.props.t('salesFunnel.search.endDate')}</label>
                    <DatePicker 
                        className="form-control"
                        onChange={this.handleDateChange('to_date')}
                        // selected={(formData && formData.to_date) ? formData.to_date : this.state.date}
                        dateFormat={config.constants.dateFormatDMY}
                        // value={formData['to_date']}
                        onKeyDown={e => e.preventDefault()}
                        minDate={new Date(formData.from_date)}
                        selected={formData['to_date'] ? new Date(formData['to_date']) : null}
                        isClearable    
                        maxDate={new Date()}          
                    />
                </li>
                <li className="searchitems">
                    <label>{this.props.t('salesFunnel.search.reportType')}</label>
                    <Select
                        id="report_type"
                        onChange={this.handleChange.bind(this,'report_type')}
                        options={REPORT_TYPE}
                        value={formData && REPORT_TYPE && REPORT_TYPE.filter((type) => Number(type.value) === Number(formData.report_type))}
                        isClearable={true}
                    />
                </li>

                <li className="searchitems">
                    <label>{this.props.t('salesFunnel.search.leadType')}</label>
                    <Select
                        id="lead_type"
                        onChange={this.handleChange.bind(this,'lead_type')}
                        options={LEAD_TYPE}
                        value={formData && LEAD_TYPE && LEAD_TYPE.filter((type) => Number(type.value) === Number(formData.lead_type))}
                        isClearable={true}
                    />
                </li>
                {/* <li className={ this.state.advanceSearchEnable ? "searchitems doublew form-group " : "searchitems doublew form-group hide"} >
                    <label>{this.props.t('salesFunnel.search.cluster')}</label>
                    <Multiselect
                        id='cluster'
                        options={clusterlist}
                        onSelect={this.onSelect.bind(this,'cluster')} 
                        onRemove={this.onRemove.bind(this,'cluster')} 
                        displayValue="label" 
                        showCheckbox={false}
                        closeOnSelect={false}
                        //selectedValues={(formData && formData.selected_fuel && formData.selected_fuel.length) ? formData.selected_fuel : ''}
                        ref={this.multiselectRef.cluster}
                        
                    />
                </li>
                <li className={ this.state.advanceSearchEnable ? "searchitems doublew form-group " : "searchitems doublew form-group hide"} >
                    <label>{this.props.t('salesFunnel.search.city')}</label>
                    <Multiselect
                        id='city'
                        options={citieslist}
                        onSelect={this.onSelect.bind(this,'city')} 
                        onRemove={this.onRemove.bind(this,'city')} 
                        displayValue="label" 
                        showCheckbox={false}
                        closeOnSelect={false}
                        //selectedValues={(formData && formData.selected_fuel && formData.selected_fuel.length) ? formData.selected_fuel : ''}
                        ref={this.multiselectRef.city}
                    />
                </li> */}

               <li className="searchitems">
                    <label>{this.props.t('salesFunnel.search.source')}</label>
                    <Multiselect
                        id='source'
                        options={sourceList}
                        onSelect={this.onSelect.bind(this,'source')} 
                        onRemove={this.onRemove.bind(this,'source')} 
                        displayValue="label" 
                        showCheckbox={true}
                        closeOnSelect={false}
                        
                        //selectedValues={(formData && formData.selected_fuel && formData.selected_fuel.length) ? formData.selected_fuel : ''}
                        ref={this.multiselectRef.source}
                    />
                </li>
                {/* <li className={ this.state.advanceSearchEnable ? "searchitems doublew form-group " : "searchitems doublew form-group hide"} >
                    <label>{this.props.t('salesFunnel.search.subSource')}</label>
                    <Multiselect
                        id='sub_source'
                        options={subSourceList}
                        onSelect={this.onSelect.bind(this,'sub_source')} 
                        onRemove={this.onRemove.bind(this,'sub_source')} 
                        displayValue="label" 
                        showCheckbox={false}
                        closeOnSelect={false}
                        //selectedValues={(formData && formData.selected_fuel && formData.selected_fuel.length) ? formData.selected_fuel : ''}
                        ref={this.multiselectRef.sub_source}
                    />
                </li> */}
                <li className="searchitems form-group">
                    <label> &nbsp;</label>
                    <div>
                        {
                            btnStatus
                            ?
                            <button type="submit" className="btn btn-primary mrg-r15 undefined" onClick={this.searchReport.bind(this)}>{this.props.t('salesFunnel.search.search')}</button>
                            :
                            <button type="submit" className="btn btn-primary mrg-r15 undefined" disabled onClick={this.searchReport.bind(this)}>{this.props.t('salesFunnel.search.search')}</button>
                            
                        }
                        <button type="reset" className="btn btn-default btn-reset" onClick={this.reset.bind(this)}>{this.props.t('salesFunnel.search.reset')}</button>
                    </div>
                </li>
            </ul>
            {/* <span  className="advancesearch btn btn-link"  onClick={this.ToogleAdvanceSearch} >
                <span>{this.state.advanceSearchEnable ? ("- "+this.props.t('salesFunnel.search.lessSearch')) : ("+" + this.props.t('salesFunnel.search.advanceSearch'))}</span>
            </span> */}
        </div>
        );
    }
}

export default withTranslation('report')(SearchSalesFunnel);
