export const SITE_LOGO = require('../../webroot/images/logo-ub-ph-2x.png');
export const LOGIN_PAGE_LOGO = require('../../webroot/images/logo-ub-ph-2x.png');
export const GAADI_SOURCE = require('../../webroot/images/gaadi-source.png');
export const JUST_DIAL_IMG = require('../../webroot/images/ic_just dial.png');
export const KNOWLARITY_IMG = require('../../webroot/images/ic_knowlarity.png');
export const ZW_IMG = require('../../webroot/images/Zw_icon_16x16.png');
export const FB_IMG = require('../../webroot/images/facebook.png');

export const TITLE = 'UB :: Carmudi';

export const paidDetails = [
    { value: '1', label: 'Paid' },
    { value: '2', label: 'Organic' },
    { value: '3', label: 'Other' }
];

export const language = [
    { value: '1', label: 'English',iso_code :'en'}
];

export const priceRange = [
    { value: '250000', label: '250000' },
    { value: '500000', label: '500000' },
    { value: '750000', label: '750000' },
    { value: '1000000', label: '1M' },
    { value: '1500000', label: '1.5M' },
    { value: '2000000', label: '2.5M' }
];

export const status = [
	{ value: '', label: 'All' },
    { value: '1', label: 'Active' },
    { value: '0', label: 'Inactive' }
];

export const ISD_CODE = [
	{
        "isdCode": "+63",
        "isdCodeWithoutSign": "63",
        "startsFrom": 9,
        "minLength": 8,
        "maxLength": 12
    }
];

export const numAttemptArray = [
	{ value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: 'more', label: 'More' }
];

export const dateFormatDMY = 'dd-MM-yyyy';
export const dateFormatDMYHIS = 'dd-MM-yyyy HH:mm:ss';
export const dateFormatMDY = 'MM-dd-yyyy';
export const dateFormatDDMMMYYYYHIS = 'dd mmm yyyy hh:MM:ss tt'
export const dateFormatDDMMMYYYYHI = 'dd mmm yyyy, hh:MM tt'

export const sourceImageArr = function(){
    
    return {
        4   :   GAADI_SOURCE,
        // 5   :base_url + " ",
        6   :   JUST_DIAL_IMG,
        // 7   :base_url + " ",
        // 17  :base_url + " ",
        // 26  :base_url + " ",
        // 27  :base_url + " ",
        // 28  :base_url + " ",
        // 29  :base_url + " ",
        // 82  :base_url + " ",
        90  :   LOGIN_PAGE_LOGO,
        // 94  :base_url + " ",
        // 125 :base_url + " ",
        // 126 :base_url + '',
        131 :   KNOWLARITY_IMG,
        // 132 :base_url + " ",
        368 :   ZW_IMG,
        369 :   FB_IMG
    }
}()

export const inventoryModule = {
    ownerArr: [
        {value: "0", label: "Unregistered"},
        {value: "1", label: "First"},
        {value: "2", label: "Second"},
        {value: "3", label: "Third"},
        {value: "4", label: "Fourth"},
    ]
};

export const monthArray = ['Enero','Pebrero','Marso','Abril','Mayo','Hunyo','Hulyo','Agosto','Setyembre','oktubre','Nobyembre','Disyembre'];

export const currencySymbol = '₱';

export const fulfilmentStatus = [
    { value: '0', label: 'None' },
    { value: '1', label: 'In Process' },
    { value: '2', label: 'Completed' },
  ];

export const boostStatus = [
    { value: '0', label: 'No' },
    { value: '1', label: 'Yes' },
]

export const sendBackendLeads = [
    { value: '1', label: 'No' },
    { value: '2', label: 'Yes' },
]

export const PURPOSE_OF_PURCHASE_CAR = [
    {value:'1',label: 'Learning'},
    {value:'2',label: 'City Driving'},
    {value:'3',label: 'Family Car'},
    {value:'4',label: 'Out of Town Trips'},
    {value:'5',label: 'Upgrade'},
    {value:'6',label: 'Business Use'},
    {value:'7',label: 'Taxi'}
]

export const DRIVE_CAR = [
    {value:'1',label: 'Customer himself'},
    {value:'2',label: 'Driver'},
    {value:'3',label: 'Wife'},
    {value:'4',label: 'Parents'},
    {value:'5',label: 'Son/Daughter'}
]

export const AVERAGE_TRAVEL_CAR = [
    {value:'0-20',label: '0-20'},
    {value:'20-40',label: '20-40'},
    {value:'40-60',label: '40-60'},
    {value:'60-80',label:'60-80'},
    {value:'80-100',label: '80-100'},
    {value:'101',label: '101+'}
]

export const NO_SEAT_REQ = [
    {value:'2',label: 'Less Than 5'},
    {value:'5',label: '5'},
    {value:'6',label: '6'},
    {value:'7',label: '7'},
    {value:'8',label: '8'},
    {value:'9',label: 'More Than 8'}
]

export const BUYER_TYPE = [
    {value:'1', label:'First Time'},
    {value:'2',label: 'Second Time'},
    // {value:'1',label: 'Agent'},
    // {value:'2', label:'Dealer'},
    // {value:'3',label: 'Customer'},
    // {value:'4',label: 'DC'},
    // {value:'5',label: 'BM'},
    // {value:'6',label:'System'}
]

export const COLOUR_ARR = [
    {value: "Black", label: "Black"},
    {value: "Blue", label: "Blue"},
    {value: "White", label: "White"},
    {value: "Dark Silver", label: "Dark Silver"},
    {value: "Silver", label: "Silver"},
    {value: "Bright Green", label: "Bright Green"},
    {value: "Cobalt", label: "Cobalt"},
    {value: "Green", label: "Green"},
    {value: "Golden", label: "Golden"},
    {value: "Mineral White", label: "Mineral White"},
    {value: "Fiery Black", label: "Fiery Black"},
    {value: "Mint White", label: "Mint White"},
    {value: "Berry Red", label: "Berry Red"},
    {value: "Purple", label: "Purple"},
    {value: "Dark Blue", label: "Dark Blue"},
    {value: "Tan", label: "Tan"},
    {value: "Rosso Mugello", label: "Rosso Mugello"},
    {value: "Dark Red", label: "Dark Red"},
    {value: "Volcano Red", label: "Volcano Red"},
    {value: "Matt Black", label: "Matt Black"},
    {value: "Red", label: "Red"},
    {value: "Pink", label: "Pink"},
    {value: "Burgundy", label: "Burgundy"},
    {value: "Orange", label: "Orange"},
    {value: "Lavender", label: "Lavender"},
    {value: "Yellow", label: "Yellow"},
    {value: "Teal", label: "Teal"},
    {value: "Torquoise", label: "Torquoise"},
    {value: "Grey", label: "Grey"},
    {value: "Sand Drift Grey", label: "Sand Drift Grey"},
    {value: "Brown", label: "Brown"},
    {value: "Violet", label: "Violet"}
]

export const OWNER = [
    {value: "0", label: "Unregistered"},
    {value: "1", label: "First"},
    {value: "2", label: "Second"},
    {value: "3", label: "Third"},
    {value: "4", label: "Fourth"},
]

export const conversionSource = [
    {value: 1, label: 'Dealer'},
    {value: 2, label: 'Customer'},
    {value: 3, label: 'Inbound'},
    {value: 4, label: 'BM'},
    // {value: 5, label: 'DC'},
];

export const blockReasonMapping  = [
    {value: 0, label: 'Unblock'},
    {value: 1, label: 'Dealer'},
    {value: 2, label:'Broker'},
    {value: 3, label:'Scraping agent (Cars 24, etc)'},
    {value: 4, label:"Customer don't want any call/DND customer"}
];

export const conversionPanelStatus = [
    { value: '0', label: 'Pending'},
    { value: '1', label: 'Confirmed'},
    { value: '2', label: 'Denied'},
    { value: '3', label: 'Given Token'}
];

export const conversionPanelSearchBy = [
    { value: 'leadId', label: 'Lead ID'},
    { value: 'mobile_number', label: 'Mobile No.'}
];

export const conversionReason = [
    {value: 'Dealer Declined', label: 'Dealer Declined'},
    {value: 'Conversion done through CarTrade/CarWale', label: 'Conversion done through CarTrade/CarWale'},
    {value: 'Conversion done through Olx', label: 'Conversion done through Olx'},
    {value: 'Loan Rejected', label: 'Loan Rejected'},
    {value: 'Duplicate lead', label: 'Duplicate lead'},
    {value: 'Other', label: 'Other'},
];

export const ADMIN_ROLE_ID = 5;
export const CALL_CENTRE_HEAD_ROLE_ID = 1;
export const TEAM_LEAD_ROLE_ID = 2;
export const TELE_CALLER_ROLE_ID = 25;

export const AGENT_RATINGS = [
    { 'value' : 1,'label' : 'Hot' },
    { 'value' : 0.5,'label' : 'Warm' },
    { 'value' : 0.25,'label' : 'Cold' }
 ];
 
 export const DEALER_FLAG =[
    {'1' : 'Photo & car not matching'},
    {'2' : 'Very low price/Incorrect price'},
    {'3' : 'Registration No. & Registration city not matching'},
    {'4' : 'Phone No. visible on image'},
    {'5' : 'Blurred photo'},
    {'6' : 'Offensive content'},
    {'7' : 'Incorrect model year'},
    {'8' : 'Sunroof is not present'},
    {'9' : 'CNG kit is not available'}
];

export const leadFinderSearchBy = [
    { value: 'lead_id', label: 'Lead Id' },
    { value: 'name', label: 'Name' },
    { value: 'email', label: 'Email' },
    { value: 'mobile_number', label: 'Mobile Number' }
];

export const quickFilter = [
    { value: 'is_premium', label: 'Premium' },
    { value: 'is_finance_req', label: 'Finance' },
    { value: 'isExchange', label: 'Exchange' },
    { value: 'is_outstation', label: 'Outstation' },
];
export const dealerPriority = [
    { value: 1, label: 'Free' },
    { value: 2, label: 'Paid' },
    { value: 3, label: 'Paid Pro' }
];
export const chatSourceArr = [
    {value : '90' , label : 'Cardekho'},
    {value : '4'  , label :'Gaadi'}, 
    {value : '368', label : 'Zigwheels'}
];

export const DEALER_PRIORITY = [
    {value : '1', label : 'Free'},
    {value : '2', label :'Paid'}, 
    {value : '3', label : 'Paid Pro'}
];

export const DEALER_TYPE_PRIORITY = [
    {value : '1', label : 'Low'},
    {value : '2', label :'Medium'}, 
    {value : '3', label : 'High'}
];

export const CLOSED_GAADI_INDIVIDUAL = 15;
export const CLOSED_NON_GAADI_INDIVIDUAL = 16;
export const CLOSED_NON_GAADI_DEALER = 17;
export const PURCHASED_USED_CAR = 36;
export const CHAT_AGENT_ROLE_ID = 17;

export const LEAD_TYPE = [
    { value: '1', label: 'All' },
    { value: '2', label: 'Premium' },
    { value: '3', label: 'Non Premium' },
];

export const REPORT_TYPE = [
    { value: '1', label: 'Created Date' },
    { value: '2', label: 'Updated Date' }
];

export const DEALER_TYPE  = [
    { value: '1', label: 'PDP Dealer' },
    { value: '2', label: 'Key Program Dealer' },
];

export const DEALER_REPORT_QUICKFILTER = [
    { value: '1', label: '0 conversions MTD' },
    { value: '2', label: '0 leads FTD' },
    { value: '3', label: '0 backend leads in last 5 days' },
    { value: '3', label: '0 conversions MTD and total lead' }
  ];
  export const FOLLOW_UP_REASON = [
    {value:'mail_sms_not_checked', label : 'Mail or SMS not checked'},
    {value:'car_not_available', label : 'Car not available'},
    {value:'didnot_like_cars', label : 'Still looking for car'},
    {value:'set_by_dealer', label : 'Set By Dealer'}
]

export const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

export const SUBSTATUS = {
    "1": [{"value": 1, "label": "New"}],
    "2": [
            {"value": 2, "label": "Reached"},
            {"value": 37, "label": "Not Reachable"}
        ],
    "3": [
        {"value": 3, "label": "Interested"},
        {"value": 4, "label": "Undecided"},
        {"value": 5, "label": "Follow-up"},
        {"value": 22, "label": "Car not available"},
        {"value": 34, "label": "Updated by dealer"}
    ],
    "4": [
        {"value": 6, "label": "Pending"}
    ],
    "5": [
        {"value": 9, "label": "Scheduled"},
        {"value": 35, "label": "Scheduled by dealer"}
    ],
    "6": [
        {"value": 10, "label": "Pending"},
        {"value": 11, "label": "Follow-up"},
        {"value": 12, "label": "Converted"},
        {"value": 26, "label": "Reserved"}
    ],
    "7": [
        {"value": 13, "label": "Not interested"},
        {"value": 14, "label": "Wrong Number"},
        {"value": 15, "label": "Purchased from Private Seller"},
        {"value": 16, "label": "Purchased from Non-Carmudi/ZW Private Seller"},
        {"value": 17, "label": "Purchased from Non-Carmudi/ZW Dealer"},
        {"value": 18, "label": "Attempts Elapsed"},
        {"value": 19, "label": "Dealership Closed"},
        {"value": 20, "label": "Purchased New Car" },
        {"value": 21, "label": "Blocked" },
        {"value": 25, "label": "Out of city"},
        {"value": 27, "label": "Closed by Dealer" },
        {"value": 28, "label": "Looking for T-Permit Car" },
        {"value": 29, "label": "Test Lead" },
        {"value": 30, "label": "Duplicate Lead" },
        {"value": 31, "label": "Spam Leads" },
        {"value": 32, "label": "Broker" },
        {"value": 33, "label": "Motorcycle Lead" },
        {"value": 36, "label": "Purchased Used Car" }
    ]
};

export const CALSTATUS = {
    "1": [{"value": 1, "label": "NYCCC"}],
    "2": [{"value": 1, "label": "Language Problem"}]
};

export const DATA_COLOR = [
    { value: "A", label: "Option A" },
    { value: "B", label: "Option B" },
    { value: "C", label: "Option C" },
  ];

export const TIMINGS = [
    {
      id: "1",
      label: "10 mins",
      value: "10",
    },
    {
      id: "2",
      label: "20 mins",
      value: "20",
    },
    {
      id: "3",
      label: "30 mins",
      value: "30",
    },
  
    {
      id: "4",
      label: "Other (Please Specify)",
      value: "",
    },
  ];

export const YES_NO = [
    {
      id: "1",
      label: "Yes",
      value: "yes",
    },
  
    {
      id: "2",
      label: "No",
      value: "no",
    },
  ];
  
export const WANT_TO_BUY_CAR_OPTION = [
    {
      id: "1",
      label: "Yes",
      value: "1",
    },
  
    {
      id: "0",
      label: "No",
      value: "0",
    },
    {
      id: "2",
      label: "Want to explore a bit more",
      value: "2",
    }
  ];

export const l1ReportLeadType = [
    { value: '1', label: 'All' },
    { value: '2', label: 'Dealer' },
    { value: '3', label: 'Individual' },
    { value: '4', label: 'Only Dealer' },
    { value: '5', label: 'Only Individual' },
    { value: '6', label: 'Only Paid Dealer' }
];

export const manualSMSForEvent = [
    { value: 'recommended', label: 'Customer Inquiry'},
    { value: 'walkinscheduled', label: 'Customer Unit Viewing Schedule'}
];

export const l1ReportLeadDateFilter = [
    { value: '1', label: 'Created Date' },
    { value: '2', label: 'Updated Date' }
];

export const utm_source = [
	{ value: 'google', label: 'Google' },
    { value: 'direct', label: 'Direct' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'others', label: 'Others' }
];

export const utm_campaign = [
	{ value: 'search', label: 'Search' },
    { value: 'others', label: 'Others' }
];

export const utm_medium = [
	{ value: 'organic', label: 'Organic' },
    { value: 'none', label: 'None' },
    { value: 'cpc', label: 'CPC' },
    { value: 'others', label: 'Others' }
];

export const dialer_history_status = [
	{ value: 'connected', label: 'Connected' },
    { value: 'lst', label: 'LST' },
    { value: 'convrt', label: 'CONVRT' },
    { value: 'calbck', label: 'CALBCK' },
    { value: 'conn', label: 'CONN' },
    { value: 'notcon', label: 'NOTCON' },
    { value: 'dncc', label: 'DNCC' },
    { value: 'na', label: 'NA' },
    { value: 'adc', label: 'ADC' },
    { value: 'drop', label: 'DROP' },
    { value: 'b', label: 'B' },
    { value: 'nyc', label:'Not Yet Called'}
];