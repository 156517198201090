import React, { Component } from 'react';
import SearchLocationWiseLead from './search-location-wise-lead';
import LocationWiseLeadResult from './location-wise-lead-result';
//import InputField from '../../../elements/InputField';
import ReportService from './../../../../service/reportService';
import { bindActionCreators } from 'redux';
import {withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { LeadActions, InventoryActions } from "../../../../store/action";
import { CSVLink } from "react-csv";
import _ from 'lodash';

class LocationWiseLeadList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saveFilter: false,
            reportData: [],
            searchByList: [],
            page: 0,
            totalRecord:0,
            filterData: {},
            loading:false,
            pageloading:true,
            next_page:false,
            allFliterData: {
                cities: [],
                cluster: [],
                locationList: []
            },
            searchResult: [],
            totalCalculationObject:{},
            CsvJsonData:[]
        }
        this.handleChangetext = this.handleChangetext.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this._isMounted = true;
        this.onScroll('Mounter');
        window.addEventListener('scroll', this.onScroll);
        await this.getReportData({})
        await this.getCityList();
        await this.getClusterList();
        await this.getLocationList();
        
    }

    onScroll(event) {
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            if (this.flag) {
                let current_page = this.state.page;
                let loading=false,pageloading=false;
                if(current_page===0) {
                    pageloading = true;
                }else{
                    loading = true;
                }
                if(this._isMounted && this.state.next_page){
                    this.setState({ page: current_page + 1,loading:loading,pageloading:pageloading}, () => {
                        this.getReportData();
                    });
                }
            }
            this.flag = false;
        }
    }

    handleChangetext = () => {
        // let formData = { ...this.state.formData };   
    }
    ToogleSaveFilter = () => {
        this.setState({ saveFilter: event.target.value });
    };
    
    getCityList = async() => {
        const {allFliterData} = this.state;
        let result = await this.props.actions.listCity();
        let cityList = []
        if (result && result.status === 200) {
            cityList = (result && result.data && result.data.city && result.data.city.length) ? result.data.city : []
            allFliterData.cities = cityList
        }
        this.setState({ allFliterData: allFliterData });
    }

    getClusterList = async() => {
        const {allFliterData} = this.state;
        let result = await this.props.actions.getClusterList({});
        let clusterList = []
        if (result && result.status === 200) {
            clusterList = (result && result.data && result.data.length) ? result.data : []
            allFliterData.cluster = clusterList
        }
        this.setState({ allFliterData: allFliterData });
       
    }

    getLocationList = async() => {
        const {allFliterData} = this.state;
        ReportService.getLocationList({})
        .then((resp) => {
            if(resp && resp.data && resp.data.status === 200){
                allFliterData.locationList = resp.data.data
                this.setState({ allFliterData: allFliterData });
              }
            }
          );
        
    }

    getReportData = async (searchParams) => {
        var thisObj = this;
        var filterData = thisObj.state.filterData;
        filterData.page_number = (thisObj.state.page === 0) ? 1 : thisObj.state.page;
        thisObj.setState({page:filterData.page_number,loading:true})
        ReportService.getLocationWiseLeadReport(filterData)
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({loading:false,pageloading:false})
                if (response.data.status === 200) {
                    if (response.data.data.length) {
                        let reportData = response.data.data;
                        let pagination = (response.data && response.data.pagination) ? response.data.pagination : {}
                        let next_page = (pagination) ? pagination.next_page : false
                        let totalRecord = (pagination) ? pagination.total : 0
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ reportData: reportData ,loading:false,pageloading:false,next_page:next_page,totalRecord:totalRecord}, () => {
                                thisObj.flag = true;
                            });
                        }
                        else {
                            thisObj.setState({
                                reportData: thisObj.state.reportData.concat(reportData)
                            ,loading:false,pageloading:false,next_page:next_page,totalRecord:totalRecord}, () => {
                                thisObj.flag = true;
                            });
                        }
                        thisObj.getTotalObject();
                        thisObj.setJSONObjectForReport();
                    } else {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ reportData: [],next_page:false,totalRecord:0});
                        }
                    }
                }
            })
            .catch(function (response) {
                thisObj.setState({loading:false,pageloading:false})
            });
    };

    getFilterReport = async(searchParam) => {
        await this.setState({filterData : searchParam});
        this.getReportData();
    }

    getTotalObject = async()=>{
        let {reportData,totalCalculationObject} = this.state;
        totalCalculationObject['sumTotalLead'] = await this.calculateTotal('totalLeads',reportData);
        totalCalculationObject['sumTotalPremiumLead'] = await this.calculateTotal('totalPremiumLeads',reportData);
        totalCalculationObject['sumTotalNonPremiumLead'] = await this.calculateTotal('totalnonPremiumLeads',reportData);
        
        this.setState({totalCalculationObject});
         
    }

    calculateTotal = async(key,reportData) =>{
        let total=0
        let totalAllLeads = await (reportData && reportData.length && reportData).map(reportObj=>{
            return total += +(reportObj[key])
        })
        let totalVal = (totalAllLeads && totalAllLeads.length) ? totalAllLeads[totalAllLeads.length - 1] : 0;
        return totalVal;
    }

    setJSONObjectForReport=()=>{
        
        let reportList = Object.assign({},this.state.reportData);
        let reportObj={},LeadsList=[];
        _.forEach(reportList,(reportData)=>{
            reportObj={}
            reportObj['Cluster']=reportData.cluster_name;
            reportObj['City']=(reportData.city_name) ? reportData.city_name : '';
            reportObj['Location']=(reportData.location_name) ? reportData.location_name : '';
            reportObj['Total lead']=(reportData.totalLeads) ? reportData.totalLeads : '';
            reportObj['Premium Leads']=(reportData.totalPremiumLeads) ? reportData.totalPremiumLeads : '';
            reportObj['Non Premium Leads']=(reportData.totalnonPremiumLeads) ? reportData.totalnonPremiumLeads : '';
            LeadsList.push(reportObj);
            
        })
        this.setState({CsvJsonData:LeadsList})
    }
    

    render() {
        return (
            <div className="container-fluid">
                <div>
                    <div className="savefilterwrap">
                        <h1 className="mrg-rauto">{this.props.t('locationWiseLead.title')}</h1>
                        
                        {(this.state.CsvJsonData && this.state.CsvJsonData.length) ?          
                        <button type="button" className="btn btn-default btn-sm">
                        <CSVLink
                            data={this.state.CsvJsonData}
                            filename={"location-wise-lead-count-report.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            >{this.props.t('locationWiseLead.exportCsv')}
                        </CSVLink>
                        </button> :''}
                    </div>
                </div>
                <SearchLocationWiseLead prefilledData={this.state.allFliterData} getReportData={this.getFilterReport} getSubSourceList={this.getSubSourceList}/>
                <LocationWiseLeadResult pageloading={this.state.pageloading} loading={this.state.loading} searchResult={this.state.reportData} 
                totalRecord={this.state.totalRecord} totalCalculationObject={this.state.totalCalculationObject}/>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            listCity: bindActionCreators(
                InventoryActions.listCity,
                dispatch
            ),
            listDealer:bindActionCreators(
                LeadActions.listDealer,
                dispatch
            ),
            getClusterList:bindActionCreators(
                LeadActions.getClusterList,
                dispatch
            )
            
    }   }
}

export default  withTranslation('report')(connect(mapStateToProps, mapDispatchToProps)(LocationWiseLeadList));
