import React, { Component } from "react";
import Select from "react-select";
import { withTranslation } from 'react-i18next';

class ClosedNewPurchaseCar extends Component{
    constructor(props){
        super(props)
        this.state={
            vehicleList:[],
            error:{},
            selectedOptions:{},
            leadData : []
        }
        this.componentDidMount=()=>{
            let {mmvList:{make=[],model=[]}={},leadData} = this.props;
            let makeModelList = make.map(function(e){
                return model.filter(f=>f.mk_id===e.id).map(function(d){
                    return {label:`${e.make} ${d.m}`, value:d.id,mk_id:e.id}
                })
            });
            let tempMMVList = [];
            for(let i of makeModelList) tempMMVList.push(...i)
            this.setState({vehicleList:tempMMVList, leadData : leadData})
        }
    }
    validateDetails=(selectedOptions)=>{
        const {error}=this.state;
        if(!selectedOptions['make_model']) error['make_model'] = this.props.t('closedPurchaseCar.error.makeModel');
        if(Object.keys(error).length) this.setState({error:error})
        else return true;
        return false;
    }
    handleChangeItems=(key,value)=>{
        let {selectedOptions,error} = this.state
        delete(error[key]);
        let {target:{value:actualValue} = {}} = value
        value = actualValue || actualValue===""?actualValue:value
        selectedOptions[key] = value
        this.setState({selectedOptions:selectedOptions})
    }
    submitPurchasedDetails=(key,value)=>{
        let {selectedOptions}=this.state;
        let {leadData} =this.state;
        if(this.validateDetails(selectedOptions)){
            let data={
                make:selectedOptions['make_model'].hasOwnProperty('mk_id')?selectedOptions['make_model'].mk_id:'',
                model:selectedOptions['make_model'].hasOwnProperty('value')?selectedOptions['make_model'].value:'',
                lead_id: leadData.id
            }
            this.props.submitNewPurchaseDetails(data);
        }
    }
    render(){
        const {vehicleList,selectedOptions,error} = this.state
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12">
                            <label>{this.props.t('closedNewPurchaseCar.label.purchaseCar')}</label>  
                            <Select
                                id="makeModelList"
                                options={vehicleList}
                                onChange={this.handleChangeItems.bind(this,"make_model")}
                                value={selectedOptions.make_model?selectedOptions.make_model:''}
                            />
                            {error.make_model && <div className="error-block">{error.make_model}</div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <input type="button" className="btn btn-primary" value={this.props.t("closedNewPurchaseCar.label.submit")} onClick={this.submitPurchasedDetails}/>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation('lead')(ClosedNewPurchaseCar)