import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PrivilegeService from './../../../../service/privilegeService';
import DateFormat from 'dateformat';
import _  from 'lodash';

class SearchResult extends Component {
    privilegeData=[]
    constructor(props) {
        super(props);
        this.state = {
            privilegeListData: props.privilegeListData,
            loading: false,
            pageloading: false,
            statusClassname: {
                'active': 'nav-item active',
                'inactive': 'nav-item',
                'incomplete': 'nav-item',
                'blacklist': 'nav-item',
                'all': 'nav-item'
            },
            filterData: {
            },
            usersRecord:[],
            currentIndex: null,
            current_org_name: null
        };
    }
    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         privilegeListData: nextProps.privilegeListData,
    //         loading: nextProps.loading,
    //         pageloading: nextProps.pageloading
    //     });
    // }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};

        if(nextProps.privilegeListData){
            updatedStateData['privilegeListData'] = nextProps.privilegeListData;
        }

        if(nextProps.loading !== prevState.loading){
            updatedStateData['loading'] = nextProps.loading;
        }

        if(nextProps.pageloading !== prevState.pageloading){
            updatedStateData['pageloading'] = nextProps.pageloading;
        }

        return updatedStateData;

    }

    updateStatus(userData){
        if (typeof this.props.onUpdateStatus === 'function') {
            this.props.onUpdateStatus(userData);
        }

    }

    getUserName = (data,keyToMatch,keyToReturn) => {
        const user = _.find(this.state.usersRecord,{'id':data[keyToMatch]});
        if(user)
            return user[keyToReturn]
        else
            return null;
    }

    componentDidMount = async () => {
        await this.getUserList();
    }

    getUserList() {
        var thisObj = this;
        PrivilegeService.getAllUserList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                    thisObj.setState({ usersRecord: response.data.data });
                }else {
                    thisObj.setState({ usersRecord: []});
                }
            }
        })
        .catch(function (response) {
            
        });
    }
    
    render() {
        return (
            <div className="table-responsive">
                    <table className="table table-bordered table-hover table-striped table-category">
                        <thead>
                            <tr>
                                <th>{this.props.t('List.details.privilege_id') }</th>
                                <th>{this.props.t('List.details.privilege_name') }</th>
                                <th>{this.props.t('List.details.created_date') }</th>
                                <th>{this.props.t('List.details.created_by') }</th>
                                <th>{this.props.t('List.details.updated_date') }</th>
                                <th>{this.props.t('List.details.updated_by') }</th>
                                <th>{this.props.t('List.details.status') }</th>
                                <th>{this.props.t('List.details.action') }</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(this.state.privilegeListData.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="8"><h6 className="text-center text-danger text-bold">{this.props.t('List.Search_Privilege.No_Record_Found')}</h6></td></tr> : null}
                            {(this.state.privilegeListData && this.state.privilegeListData.length !== 0) && this.state.privilegeListData.map((privilegedata, k) =>
                            <tr key={k}>
                                <td>{privilegedata.id}</td>
                                <td>{privilegedata.name}</td>
                                <td>{DateFormat(privilegedata.created_at,"dd/mm/yyyy")}</td>
                                <td>{this.getUserName(privilegedata,'added_by','username')}</td>
                                <td>{(privilegedata.updated_at)? (DateFormat(privilegedata.updated_at,"dd/mm/yyyy")): ''}</td>
                                <td>{this.getUserName(privilegedata,'updated_by','username')}</td>
                                <td>
                                    <label className="switch-btn btn btn-link ml-5" htmlFor={"active"+privilegedata.id} >
                                        <input checked={(privilegedata.status === "1") ? true : false} className="switch-btn" id={"active"+privilegedata.id} value="open" name={"active"+privilegedata.id} type="checkbox"  onChange={this.updateStatus.bind(this,privilegedata)}  />
                                        <div className="slider round"></div>
                                        <span className="switch-label"></span>
                                        {(privilegedata.status === "1") ? this.props.t('formData.active'):this.props.t('formData.inactive')}
                                    </label>
                                </td>
                                <td>
                                    <div className="btn-group">
                                    <Link to={`/edit-privilege/${privilegedata.privilege_id_hash}`} className="btn btn-default"><i className="ic-createmode_editedit mrg-r5"></i></Link>
                                    </div>
                                </td>
                            </tr>
                            )}
                            {
                                (this.state.loading) ? <tr><td className="loading" colSpan="8"></td></tr> : null
                            }
                            
                        </tbody>
                    </table>
                </div>
                
            
        )
    }
}

export default withTranslation('privilege')(SearchResult);
