import React, { Component } from 'react';
import Select from 'react-select';
import InputField from '../../../elements/InputField';
import {UserService, CityService} from './../../../../service';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import DateFormat from 'dateformat';
import _  from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import { default as config } from './../../../../config/config';
import {debounce} from 'throttle-debounce';
import * as helper from '../../../../config/helper';
import { Redirect } from "react-router-dom";
import secureStorage from './../../../../config/encrypt';


class EditUser extends Component {
    user_id;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            validationreq:true,
            editFormData: {},
            successResponse: false,
            redirect: false,
            errors: {},
            show: false,
            loaderClass: '',
            searchParams:{},
            roleDetails:[],
            cityDetails:[],
            selectedCity:[],
            selectedPriceRange:[],
            selectedValues:[{"value":1,"label":"Patna"}],
            tempUserData:{},
            usersRecord:[],
            redirectTolist: false,
            tlList:[],
            logginUser: secureStorage.getItem('authUserInfo')['authUser']
        }
        this.user_id = this.props.match.params.id;
        if(this.user_id)
            this.getUser = this.getUser.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.isUserExist = debounce(700, this.isUserExist);
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.getRoleDetails({'status':'1'});
        if(this.user_id){
            let searchParams = this.state.searchParams;
            searchParams.id = this.user_id;
            this.setState({searchParams:searchParams})
            await this.getUser()
            await this.getUserList();
            
        }else
            this.setState({editFormData:{}})
        setTimeout(() => {
            this.getCityDetails({});
        },1000)
        await this.getTlList()
    }

    getUser() {
        var thisObj = this;
        UserService.getUserList(this.state.searchParams)
        .then(function (response) {
            thisObj.flag = false;
            thisObj.setState({loading:false,pageloading:false})
            if (response.data.status === 200) {
                if (response.data.data.length) {
                    thisObj.setState({ editFormData: response.data.data[0],tempUserData: response.data.data[0],loading:false,pageloading:false}, () => {
                        thisObj.flag = true;
                    });
                } else {
                    thisObj.setState({ editFormData: response.data.data});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    getTlList() {
        var thisObj = this;
        UserService.getTlList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                    thisObj.setState({ tlList: response.data.data});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    submitForm = async (event) => {
        event.preventDefault();
        if (this.validForm()) {
            this.setState({ loaderClass : 'loading' , loading: true});
            let postData = await this.filterPostData();
            if(postData.id){
                UserService.updateUserDetails(postData)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                        this.setState({ loading: false,redirectTolist:true  })
                    } else if (response.status === 401 && response.data.status === 401) {
                        this.setState({ redirect: true })
                    }else{
                        // toast.error(response.data.message);
                        this.setState({ loading: false })
                    }
                })
                .catch((error) => {
                    this.setState({ loading: false })
                });
            }else{
                UserService.saveUserDetails(postData)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                        this.setState({ loading: false,redirectTolist:true })
                    } else if (response.status === 401 && response.data.status === 401) {
                        this.setState({ redirect: true })
                    }else{
                        this.setState({ loading: false })
                    }
                })
                .catch((error) => {
                });
            }
        }
    }

    getRoleDetails(params) {
        var thisObj = this;
        UserService.getRoleList(params)
        .then(function (response) {
            if (response.data.status === 200) {
                const roleArr = [];
                if (response.data.data.length) {
                    _.forEach((response.data.data),element => {
                        let rolebj={}
                        rolebj.value = element.id;
                        rolebj.label = element.name;
                        roleArr.push(rolebj);
                    });
                    thisObj.setState({ roleDetails: roleArr });
                } else {
                    thisObj.setState({ roleDetails: roleArr});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    getCityDetails(params) {
        var thisObj = this;
        // params.isPaginationRequired=false;
        CityService.getCityState()
        .then(function (response) {
            if (response.data.status === 200) {
                let cityArr = [];
                if (response.data.data.city.length) {
                    cityArr = helper.getDataInKeyValueFormat(response.data.data.city,'');

                    thisObj.setState({ cityDetails: cityArr });
                    // if(thisObj.cluster_id)
                        thisObj.setPreSelectedValueToMultiselectInput()
                } else {
                    thisObj.setState({ cityDetails: cityArr});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    filterPostData = () => {
        let params={},selectedCity=[],selectedPriceRange=[];
        if(this.state.editFormData.id)
            params.id = this.state.editFormData.id;
        if(this.state.editFormData.role_id)
            params.role_id = this.state.editFormData.role_id;
        if(this.state.editFormData.name)
            params.name = this.state.editFormData.name;
        if(this.state.editFormData.username)
            params.username = this.state.editFormData.username;
        if(this.state.editFormData.password)
            params.password = this.state.editFormData.password;
        if(this.state.editFormData.email)
            params.email = this.state.editFormData.email;
        
        params.agent_id = 0
        if(this.state.editFormData.agent_id)
            params.agent_id = this.state.editFormData.agent_id;
        if(this.state.editFormData.status)
            params.status = this.state.editFormData.status;

        params.allowed_city_ids = '';
        if(this.state.selectedCity.length){
            selectedCity = this.state.selectedCity.map(function (obj) {
                return obj.value;
              });
            params.allowed_city_ids = selectedCity.toString();
        }
        params.price_range = '';
        if(this.state.selectedPriceRange.length){
            selectedPriceRange = this.state.selectedPriceRange.map(function (obj) {
                return obj.value;
            });
            params.price_range = selectedPriceRange.toString();
        }
        if(this.state.editFormData.head_id){
            params.head_id = this.state.editFormData.head_id
        }
        if(this.state.editFormData.is_premium){
            params.is_premium = this.state.editFormData.is_premium
        }
        return params;
    }

    validForm = () => {
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        let formIsValid = true;
        if (!editFormData["role_id"]) {
            errors['role_id'] = this.props.t('validation.roleRequired')
            formIsValid = false;
        }
        if (!editFormData["name"]) {
            errors['name'] = this.props.t('validation.nameRequired')
            formIsValid = false;
        }
        if (!editFormData["username"]) {
            errors['username'] = this.props.t('validation.userNameRequired')
            formIsValid = false;
        }
        if (!editFormData["password"] && !this.user_id) {
            errors['password'] = this.props.t('validation.passwordRequired')
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleOptionChange = (key,event) => {
        let editFormData = this.state.editFormData;
        let errors = {...this.state.errors};
        if(key && key === 'status')
            editFormData[key] = (editFormData[key] === '1') ? '0' : '1';
        else if(key && key === 'gender')
            editFormData[key] = event.target.value;
        else{
            editFormData[key] = event ? ((key === 'head_id') ? event.id : event.value) : '';
            if(key === 'role_id' && event && event.value){
                errors[key] = ''
            }
            if(key === 'head_id' && event && event.value){
                errors[key] = ''
            }
        }
        this.setState({editFormData:editFormData,errors:errors})
    };

    handleChangeEvent = event => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let editFormData = {...this.state.editFormData};
        let tempUserData = {...this.state.tempUserData};
        let errors = {...this.state.errors};
        let inputKey = event.target.name,searchParams={};
        let inputValue = event.target.value;
        if(inputKey === 'username')
            inputValue = inputValue.replace(/\s/g, '');
        editFormData[inputKey] = inputValue;
        if(fieldRequired === 'yes'){
            if (inputValue !== '') {
                delete errors[inputKey];
            } else {
                errors[inputKey] = this.props.t('validation.'+inputKey)+this.props.t('validation.isRequired');
            }
        }
        if((inputKey === 'username' || inputKey === 'email') && inputValue !== ''){
            searchParams[inputKey] = inputValue;
            if(tempUserData && tempUserData[inputKey] !== inputValue)
                this.isUserExist(searchParams);
        }
        this.setState({ editFormData: editFormData, errors: errors });
    }

    isUserExist = (searchParams) => {
        var thisObj = this;
        let errors = {...this.state.errors};
        let key = Object.keys(searchParams)[0];
        UserService.getUser(searchParams)
        .then(function (response) {
            thisObj.flag = false;
            if (response.data.status === 200) {
                if (response.data.data.length) {
                    errors[key] = thisObj.props.t('validation.'+key+'Exist');
                } else {
                    errors[key] = '';
                }
                thisObj.setState({ errors: errors });
            }
        })
        .catch(function (response) {
            
        });
    }

    onSelect(key,selectedList, selectedItem) {
        if(key === 'allowed_city_ids'){
            let selectedCity = this.state.selectedCity;
            if(!_.find(selectedCity,{'value':selectedItem.value})){
                selectedCity.push(selectedItem);
            }
            this.setState({selectedCity:selectedCity})
        }
        if(key === 'price_range'){
            let selectedPriceRange = this.state.selectedPriceRange;
            if(!_.find(selectedPriceRange,{'value':selectedItem.value})){
                selectedPriceRange.push(selectedItem);
            }

            this.setState({selectedPriceRange:selectedPriceRange})
        }
        let editFormData = this.state.editFormData;
        editFormData[key] = (editFormData[key]) ? editFormData[key]+','+selectedItem.value:selectedItem.value;
        this.setState({ editFormData: editFormData });
    }
     
    onRemove(key,selectedList, removedItem) {
        if(key === 'allowed_city_ids'){
            let selectedCity = this.state.selectedCity;
            selectedCity = _.filter(selectedCity, function(currentObject) {
                return currentObject.value !== removedItem.value;
            });
            this.setState({selectedCity:selectedCity})
        }
        if(key === 'price_range'){
            let selectedPriceRange = this.state.selectedPriceRange;
            selectedPriceRange = _.filter(selectedPriceRange, function(currentObject) {
                return currentObject.value !== removedItem.value;
            });
            this.setState({selectedPriceRange:selectedPriceRange})
        }
    
    }

    setPreSelectedValueToMultiselectInput(){
        const formData = this.state.editFormData;
        let selectedCity=[],selectedPriceRange=[],priceArr;
        if(formData.cityObj){
            // cityArr = formData.allowed_city_ids.split(',');
            // _.forEach(cityArr,(val) =>{
            //     let city = _.filter(this.state.cityDetails, function(currentVal) {
            //         return val === currentVal.value;
            //     });
            //     selectedCity.push(city[0])
            // })
            selectedCity = formData.cityObj;
            this.setState({selectedCity:selectedCity})
        }
        if(formData.price_range){
            priceArr = formData.price_range.split(',');
            _.forEach(priceArr,(val) =>{
                _.forEach(config.constants.priceRange, function(currentVal) {
                    let price = (val === currentVal.value) ? currentVal : [];
                    if(price.value) selectedPriceRange.push(price);
                });
            });
            this.setState({selectedPriceRange:selectedPriceRange || []})
        }
    }

    getUserName = (userData,keyToMatch,keyToReturn) => {
        const user = _.find(this.state.usersRecord,{'id':userData[keyToMatch]});
        if(user)
            return user[keyToReturn]
        else
            return null;
    }
    
    getUserList = () => {
        var thisObj = this;
        UserService.getAllUserList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                        thisObj.setState({ usersRecord: response.data.data});
                    
                } else {
                        thisObj.setState({ usersRecord: []});
                    
                }
            }
        })
        .catch(function (response) {
            
        });
    }

    //TEAM_LEAD_ROLE_ID
    render() {
          const   {editFormData, loading,errors,redirectTolist,logginUser}   = this.state;
          if(!editFormData.hasOwnProperty("status") && !this.user_id)
            editFormData.status = "1";
            const {TEAM_LEAD_ROLE_ID} =  config.constants;
          if (redirectTolist) return <Redirect to="/list-user" />;
        return (
            <div className="container-fluid">
                <h1>{(editFormData.id) ? this.props.t('formData.editUser'):this.props.t('formData.addUser') }</h1>                
                <div className="card">
                    <div className="card-body">
                        <form method="post" id="formDetails" onSubmit={this.submitForm}>
                        <div className="row">
                                <div className=" col-sm-4 form-group">
                                    <label>{this.props.t('formData.roleName')}</label>
                                    <span className="required">*</span>
                                    <Select
                                        id="role_id"
                                        options={this.state.roleDetails}
                                        name="role_id"
                                        onChange={this.handleOptionChange.bind(this,'role_id')}
                                        value={this.state.roleDetails.filter(({ value }) => value === editFormData.role_id)}
                                        
                                        
                                    />
                                    <span className="text-danger">{errors.role_id || ''}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "name",
                                    type: "text",
                                    name: "name",
                                    label: this.props.t('formData.name'),
                                    value: editFormData.name || '',
                                    dataerror: errors.name || '',
                                    validationreq: "yes"
                                    
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                   
                                </div>
                            </div>
                            
                            
                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "email",
                                    type: "text",
                                    name: "email",
                                    label: this.props.t('formData.email'),
                                    value: editFormData.email || '',
                                    dataerror: errors.email || '',
                                    autoComplete:"nope"
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                   
                                </div>
                            </div>
                            {(logginUser && Number(logginUser.role_id) === TEAM_LEAD_ROLE_ID) ? 
                            <div className="row">
                                <div className=" col-sm-4 form-group">
                                    <label>{this.props.t('formData.tlName')}</label>
                                    <span className="required">*</span>
                                    <Select
                                        id="head_id"
                                        options={this.state.tlList}
                                        name="head_id"
                                        onChange={this.handleOptionChange.bind(this,'head_id')}
                                        value={this.state.tlList.filter(({ id }) => Number(id) === Number(editFormData.head_id))}
                                        getOptionLabel={({ name }) =>
                                            name
                                        }
                                        getOptionValue={({ id }) =>
                                            id
                                        }
                                        
                                    />
                                    <span className="text-danger">{errors.head_id || ''}</span>
                                </div>
                            </div> : ''}

                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "username",
                                    type: "text",
                                    name: "username",
                                    label: this.props.t('formData.userName'),
                                    value: editFormData.username || '',
                                    dataerror: (editFormData && !editFormData.id && errors.username) ? errors.username : '',
                                    validationreq: "yes",
                                    autoComplete:"none"
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                   
                                </div>
                            </div>
                            {/* {(!editFormData.id) ?  */}
                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "password",
                                    type: "password",
                                    name: "password",
                                    label: this.props.t('formData.password'),
                                    // value: editFormData.password || '',
                                    dataerror: errors.password || '',
                                    autoComplete:"none"
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                   
                                </div>
                            </div>
                            {/* :''} */}
                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "agent_id",
                                    type: "text",
                                    name: "agent_id",
                                    label: this.props.t('formData.agentId'),
                                    value: editFormData.agent_id || '',
                                    dataerror: errors.agent_id || ''
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 form-group">
                                <label>{this.props.t('formData.cluster')}</label>
                                <Multiselect
                                    id="city"
                                    options={this.state.cityDetails || []} 
                                    selectedValues={(this.state.selectedCity.length) ? this.state.selectedCity : []} 
                                    onSelect={this.onSelect.bind(this,'allowed_city_ids')} 
                                    onRemove={this.onRemove.bind(this,'allowed_city_ids')} 
                                    displayValue="label" 
                                />
                                   
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-sm-4 form-group">
                                <label>{this.props.t('formData.priceRange')}</label>
                                <Multiselect
                                    id="range"
                                    options={config.constants.priceRange} 
                                    selectedValues={(this.state.selectedPriceRange) ? this.state.selectedPriceRange : []}
                                    onSelect={this.onSelect.bind(this,'price_range')}
                                    onRemove={this.onRemove.bind(this,'price_range')} 
                                    displayValue="label"
                                />
                                   
                                </div>
                            </div>

                            
                            {(editFormData.id) ?  
                            <div className="row form-group" >
                                <ul className="maindetail-list">
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.createdDate')}</div>
                                <div className="sub-value">{DateFormat(editFormData.created_at,"dd/mm/yyyy")}</div>
                                    </li>
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.createdBy')}</div>
                                        <div className="sub-value">{this.getUserName(editFormData,'added_by','username')}</div>
                                    </li>
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.updatedDate')}</div>
                                        <div className="sub-value">{(editFormData.updated_at) ? (DateFormat(editFormData.updated_at,"dd/mm/yyyy")) : ''}</div>
                                    </li>

                                </ul>
                            </div>
                            : ''}
                            
                            <div>
                                <label>{this.props.t('formData.status')} </label>
                                <div >
                                    
                                    <label className="switch-btn btn btn-link ml-5" htmlFor="active" >
                                        <input checked={(editFormData.status === "1") ? true : false} className="switch-btn" id="active" value="open" name="active" type="checkbox"  onChange={this.handleOptionChange.bind(this,'status')}  />
                                        <div className="slider round"></div>
                                        <span className="switch-label"></span>
                                        {(editFormData.status === "1") ? this.props.t('formData.active'):this.props.t('formData.inactive')}
                                    </label>
                                </div>
                            </div>
                           
                            <div className="row">
                                <div className=" col-sm-4 form-group text-center">
                                    {
                                        !loading
                                        ?
                                        <button type="submit" onClick={this.submitForm} className="btn btn-primary">{this.props.t('formData.save')}</button>
                                        :
                                        <button type="submit" className="btn btn-primary">{this.props.t('formData.please_wait')}</button>
                                    }
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

// const componentWillUnmount = () => {
//     this._isMounted = false;
// }

export default withTranslation('user')(EditUser);
