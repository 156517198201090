import React, { Component } from 'react';

import { default as config } from '../../../config/config';

import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import BulkUploadHelper from '../helpers/bulk-upload-helper';

class BulkUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // selectedFile: null,
            loading:false,
            headers:[
                {name:'blockingLead.customer_name'},
                {name:'blockingLead.customer_mobile'},
                {name:'blockingLead.customer_email'},
                {name:'blockingLead.customer_city'},
                {name:'blockingLead.source'},
                {name:'blockingLead.sub_source'},
                {name:'blockingLead.car_id'},
                {name:'blockingLead.platform'},
                {name:'blockingLead.gcd_code'},
            ],
            rows:[
                {name:'blockingLead.customer_name'},
                {name:'blockingLead.customer_mobile'},
                {name:'blockingLead.customer_email'},
                {name:'blockingLead.customer_city'},
                {name:'blockingLead.source_of_lead'},
                {name:'blockingLead.sub_source_of_lead'},
                {name:'blockingLead.car_id_of_interested_car'},
                {name:'blockingLead.web_wap_ios_android'},
                {name:'blockingLead.dealer_gcd_code'},
            ],
            action: 'leadBulkUpload',
            isPremiumField: true
        }
    }

    render() {
        const { SAMPLE_BULK_UPLOAD_CSV } = config.API_URL;
        const { headers, rows, action, isPremiumField } = this.state;

        return (
            <BulkUploadHelper bulkUploadData={{headers: headers, rows: rows, sampleFile: SAMPLE_BULK_UPLOAD_CSV, action: action, isPremiumField: isPremiumField} }/>
        )
    }
}


const mapStateToProps = state => ({
    // getBlockedClosedLeads: state.lead.makeModelVersionList
})

const mapDispatchToProps = dispatch => {
    return {
            actions: {
                submitBulkUpload: bindActionCreators(
                    LeadActions.submitBulkUpload,
                    dispatch
                )
            }
        }
}

export default withTranslation('lead') (withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkUpload)));