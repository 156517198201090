import React, { Component } from 'react';

import Brandlogo from "./../elements/Brandlogo";
import Navbar from "./Navbar";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { AuthActions } from "../../store/action";
import { bindActionCreators } from 'redux';
import secureStorage from '../../config/encrypt';
import { default as config } from './../../config/config';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      redirect: 0,
      loginuser: '',
      menuOpen:false,
      headerSettings: null,
      language:''
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    }); 
    
  }

  componentDidMount = () => {
    if(!this.state.headerSettings){
      this.getSiteSettings();
    }
  }

  handleMenuClick() {
    this.setState({menuOpen:!this.state.menuOpen});
    document.body.classList.toggle('body-hidden');
  }
  
  handleLinkClick() {
    this.setState({menuOpen: false});
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if(nextProps.siteSettings && !prevState.siteSettings ){
        return ({ headerSettings: nextProps.siteSettings.header, language: nextProps.siteSettings.language })
    }
    return null;
  }

  getSiteSettings() {
    const { i18n } = this.props;
    let langData = config.constants.language;

    this.props.actions.getSiteSettings().then(res => {
      if(res.status===200){
        if(res.data['language']){
          //MATCH IF STORED CODE IS IN CONFIG FILE MATCHED
          let matchLang = langData.find(l=>{ return (l.iso_code === secureStorage.getItem('lang')) ? true :false });
          
          //UPDATE LANGUAGE IF NOT IN STORAGE OR NOT IN CONFIG FILE
          if(!secureStorage.getItem('lang') || !matchLang){
            secureStorage.setItem('lang', (res.data['language']));
            i18n.changeLanguage(res.data['language']);
          }
        }
      }
    });
  }

  render() {
    const styles= 
      {

      }
      const { headerSettings } = this.state
      const currentPath = this.props.location.pathname;

      if(!currentPath.includes('error-page') && !currentPath.includes('view-purchase-feedback') && !currentPath.includes('purchase-feedback') && !currentPath.includes('view-feedback') && !currentPath.includes('thankyou-page') && !currentPath.includes('feedback-form') && !currentPath.includes('walkin-reschedule') && !currentPath.includes('login') && !currentPath.includes('-password') && headerSettings && headerSettings.menuLinks)
        {
        return (<header className="mainHeader header-fixed clearfix">
            <span className={ this.state.menuOpen ? "ic-clearclose hamburger-icon" : "ic-menu hamburger-icon" } open={this.state.menuOpen} onClick={()=>this.handleMenuClick()}></span>
            <Brandlogo siteLogo={config.constants.SITE_LOGO}/>
        
            <Navbar navClass={ this.state.menuOpen ? "open" : "" } style={styles.container} fromHeader={currentPath}  headerMenu={headerSettings.menuLinks}/>
            <div className={ this.state.menuOpen ? "modal-backdrop" : "modal-backdrop hide" } onClick={()=>this.handleMenuClick()}></div>
      </header>);
      }else{
        return("");
      }
  }
}



const mapStateToProps = state => {
  return {
     siteSettings: state.auth.siteSettings
  }
}

const mapDispatchToProps = dispatch => {
  return {
             actions: {
                getSiteSettings: bindActionCreators(
                  AuthActions.getSiteSettings,
                  dispatch
                )
             }
          }
}

export default withTranslation('common') (withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));