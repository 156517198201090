import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import { AuthReducer, InventoryReducer, DealerReducer, LeadReducer, SourceReducer } from './store/reducer';

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import secureStorage from './config/encrypt';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_id from "./translations/id/common.json";
// import common_ph from "./translations/ph/common.json";
import common_en from "./translations/en/common.json";
import category_id from "./translations/id/category.json";
// import category_ph from "./translations/ph/category.json";
import category_en from "./translations/en/category.json";
// import source_ph from "./translations/ph/source.json";
import source_id from "./translations/id/source.json";
import source_en from "./translations/en/source.json";
// import role_ph from "./translations/ph/role.json";
import role_id from "./translations/id/role.json";
import role_en from "./translations/en/role.json";
// import privilege_ph from "./translations/ph/privilege.json";
import privilege_id from "./translations/id/privilege.json";
import privilege_en from "./translations/en/privilege.json";
import user_id from "./translations/id/user.json";
// import user_ph from "./translations/ph/user.json";
import user_en from "./translations/en/user.json";
// import cluster_ph from "./translations/ph/cluster.json";
import cluster_id from "./translations/id/cluster.json";
import cluster_en from "./translations/en/cluster.json";
import city_id from "./translations/id/city.json";
// import city_ph from "./translations/ph/city.json";
import city_en from "./translations/en/city.json";
import lead_id from "./translations/id/lead.json";
// import lead_ph from "./translations/ph/lead.json";
import lead_en from "./translations/en/lead.json";
import report_en from "./translations/en/report.json";
import report_id from "./translations/id/report.json";

const loggerMiddleware = createLogger();
const current_lang = (secureStorage.getItem('lang')) ? secureStorage.getItem('lang') : process.env.REACT_APP_DEFAULT_LANG;
i18next.init({
    interpolation: { escapeValue: false },
    lng: current_lang,
    resources: {
      en: {
            common: common_en,
            category: category_en,
            source:source_en,
            role:role_en,
            privilege:privilege_en,
            user:user_en,
            cluster:cluster_en,
            city:city_en,
            lead:lead_en,
            report:report_en      
        },
        id: {
            common: common_id,
            category: category_id,
            source:source_id,
            role:role_id,
            privilege:privilege_id,
            user:user_id,
            cluster:cluster_id,
            city:city_id,
            lead:lead_id,
            report:report_id
        },
        // ph: {
        //     common: common_ph,
        //     category: category_ph,
        //     source:source_ph,
        //     role:role_ph,
        //     privilege:privilege_ph,
        //     user:user_ph,
        //     cluster:cluster_ph,
        //     city:city_ph,
        //     lead:lead_ph
        // }
    },
});

const rootReducer = combineReducers({ auth: AuthReducer, inventory: InventoryReducer,  dealer: DealerReducer,lead:LeadReducer,  source: SourceReducer });

let middleware = [];
if (process.env.NODE_ENV === 'dev') { //set development to show errors
  middleware = [...middleware, thunkMiddleware, loggerMiddleware];
} else {
  middleware = [...middleware, thunkMiddleware];
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(...middleware)),
);

ReactDOM.render(<I18nextProvider i18n={i18next}><Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider></I18nextProvider>, document.getElementById('root'));
//serviceWorker.unregister();
