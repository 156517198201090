import React from 'react';
class ToolTip extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hover:false,
        }
    }
    toggleHover = () => {
        this.setState({hover: !this.state.hover})
    }
    render() {
        var linkStyle;
        if (this.state.hover) {
            linkStyle = {'bottom':'20px', }
          } else {
            linkStyle = {}
          }
        const { id,  } = this.state;
        var {  children,  } = this.props;
        return (
            <div className="tooltip-wrap" onMouseOver={this.toggleHover} onMouseOut={this.toggleHover} >{children}
                <div id={id} className="tooltip bs-tooltip-top" style={linkStyle}  >
                    <div className="tooltip-arrow"></div>
                    <div className="tooltip-inner" >{this.props.title}</div>
                </div>
            </div>
        )
    }
}
export default ToolTip;