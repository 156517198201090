import { InventoryConstants } from '../constants/inventory.constants';

export default function InventoryReducer(state = {}, action) {
  switch (action.type) {
    case InventoryConstants.REQUEST_CAR_MAKE_SUCCESS:
      return {
        carMakeData: action.resp,  ...state 
      };
    case InventoryConstants.REQUEST_CAR_MAKE_FAILURE:
      return {
        carMakeData: {success: false, data: []} , ...state 
      };
    case InventoryConstants.REQUEST_CAR_MODEL_SUCCESS:
      return {
        carModelData:  action.resp , ...state 
      };
    case InventoryConstants.REQUEST_CAR_MODEL_FAILURE:
      return {
        carModelData: {success: true, data: []} , ...state 
      };
    case InventoryConstants.CITY_LIST_SUCCESS:
      return {
        cityListData:  action.resp , ...state 
      };
    case InventoryConstants.CITY_LIST_FAILURE:
      return {
        cityListData: action.resp , ...state
      };
    case InventoryConstants.FILTER_SUBMIT_SUCCESS:
      return {
         success: true, data: action.resp.data, pagination: action.resp.pagination , ...state 
      };
    case InventoryConstants.FILTER_SUBMIT_FAILURE:
      return {
         success: false, data: [], pagination: [] , ...state 
      };

    case InventoryConstants.FILTER_LIST_SUCCESS:
      return {
        inventoryFilters: action.resp, ...state 
    };
    case InventoryConstants.FILTER_LIST_FAILURE:
      return {
        inventoryFilters: action.resp, ...state 
    };
    default:
      return state
  }
}