import React, { Component } from 'react';
import InputField from '../../elements/Input';
import CheckBox from '../../elements/CheckBox';
import Radiobox from '../../elements/Radiobox';
import DatePicker from "react-datepicker";
import { withTranslation } from 'react-i18next';
import { default as config } from '../../../config/config';
import _ from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import * as HELPER from '../../../config/helper';
import LeadService from '../../../service/leadService'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LeadActions } from "../../../store/action";
import { toast } from 'react-toastify';
import secureStorage from '../../../config/encrypt';
import ExchangeInfo from './ExchangeInfo';
import ModalPopup from '../../elements/Popup';
import ClosedPurchaseCar from './ClosedPurchaseCar';
import ClosedNewPurchasedCar from './ClosedNewPurchasedCar';


const DAYS=[
    {value:'DAYS',label:'Days'},
    {value:'WEEKS',label:'Weeks'}
];
const DAYS_NUM=[
    {value:'1',label:'1'},
    {value:'2',label:'2'},
    {value:'3',label:'3'},
    {value:'4',label:'4'},
    {value:'5',label:'5'}
]
class SummaryForm extends Component {
    roleId=0;
    constructor(props) {
        
        super(props);
        this.state={
            open:false,
            openemail:false,
            openmobile:false,
            
        selectedOption: null,
        dateCalculation :{
            'days':'DAYS',
            'days_num' : 0
        },
        statusClassname: {
            'active': 'nav-item active',
            'removed': 'nav-item',
            'all': 'nav-item'
        },
        formData:{},
        leadDetails : props.leadDetails,
        statusList : props.statusList,
        subStatusList:[],
        callStatusList:[],
        timelineDetails:[],
        leadsCarList:[],
        isSaveMainLeadComment:props.isSaveMainLeadComment,
        reopenLead:{},
        conversionStatus:[],
        showExchangeModal : false,
        mmvList : [],
        filterData : {},
        leadData : [],
        displayFollowUp : false,
        displayBlocking : false,
        oldCarModal : {
            display : false,
            type : null
        },
        newCarModelDisplay:false,
        dealerList : [],
        summarySave: false,
        stateIterate:0,
        summaryStatusSubStatus:{},
        disableInputOnstatusClose: false
      };
      this.handleChange=this.handleChange.bind(this);
      this.togglePanel=this.togglePanel.bind(this);
      this.addemail=this.addemail.bind(this);
      let authUserInfo=secureStorage.getItem('authUserInfo');
      this.roleId=(authUserInfo && authUserInfo['authUser'] && authUserInfo['authUser']['role_id']) ? authUserInfo['authUser']['role_id'] : 0;
    }
    getDefaultDropdownStatus = async (statusId, subStatusId) => {
        // let subStatusListFrmConst = config.constants.SUBSTATUS;
        // let callStatusFrmCnst = config.constants.CALSTATUS;
        let { subStatusList, callStatusList,disableInputOnstatusClose  } = this.state;
        let subStatusListFrmConst = await this.getSubStatusList((statusId) ? {pagination:false} : {pagination:false});        
        
        // let substatus = (statusId) ? subStatusListFrmConst[statusId] : subStatusListFrmConst;
        if (subStatusListFrmConst && subStatusListFrmConst.length > 0) {
            let subStatusListFilter = subStatusListFrmConst.filter(el=> el.status_id === statusId);
            subStatusListFrmConst = (subStatusListFilter.length) ?  subStatusListFilter : subStatusListFrmConst; 
            subStatusList=HELPER.getOptionValueToDisplay(subStatusListFrmConst,'sub_status');
            // subStatusList = substatus;
        }
        let callstatus = await this.getCallStatusList((subStatusId) ? {pagination:false} : {pagination:false});
        
        
        // let callstatus = (subStatusId != "undefined") ? callStatusFrmCnst[subStatusId] : callStatusFrmCnst;
        if (callstatus && callstatus.length > 0) {
            let callstatusFilter = callstatus.filter(el=> el.sub_status_id === subStatusId);
            callstatus = (callstatusFilter.length) ?  callstatusFilter : callstatus; 
            callStatusList = HELPER.getOptionValueToDisplay(callstatus,'call_status');
        }

        disableInputOnstatusClose = false;
        if(statusId === 7){
            disableInputOnstatusClose = true;
        }
        
        this.setState({ subStatusList, callStatusList, disableInputOnstatusClose });
    }

    UNSAFE_componentWillReceiveProps=async(nextProps) => {
        
        let {statusList,leadDetails,formData,timelineDetails,leadsCarList,mmvList,filterList,leadData,dealerListData}=nextProps;
        let isSaveMainLeadComment=(nextProps && nextProps.isSaveMainLeadComment) ? nextProps.isSaveMainLeadComment : false
        if(leadDetails && leadDetails.length && JSON.stringify(leadDetails[0]) === JSON.stringify(this.state.leadData) && !this.state.stateIterate){
        
            formData=Object.assign({},leadDetails[0]);
            formData['lead_comment']='';
            formData['orig_status_id']=formData.status_id;
            formData['orig_sub_status_id']=formData.sub_status_id;

            this.getDefaultDropdownStatus(formData.status_id,formData.sub_status_id);
            this.setState({stateIterate:1,formData: formData})
        }
        if(nextProps.leadsCarList && nextProps.leadsCarList.length){
            
            this.calcSummaryStatus(nextProps.leadsCarList)
        }

        leadData=leadData && leadData.length?leadData[0]:[]
        let filterData={}
        filterData.fromYearRange=await this.getRequiredKeyValue(filterList,'min_make_year');
        
        ///////////////// change status options ///////////////
        let {reopen_lead}=nextProps.reopenLead?nextProps.reopenLead:{}
        
        statusList=this.filterStatusList(formData,reopen_lead);
        
        
        this.setState({
            // formData: formData,
            statusList : statusList,
            timelineDetails:timelineDetails,
            leadsCarList:leadsCarList,
            isSaveMainLeadComment : isSaveMainLeadComment,
            reopenLead : nextProps.reopenLead,
            conversionStatus : nextProps.conversionStatus,
            mmvList : mmvList,
            filterData : filterData,
            leadData : leadData,
            dealerList : dealerListData,
            // subStatusList : subStatusListData
        });
    }
    

    calcSummaryStatus = async (leadsCarList) => {
        let maxStatusSubStatus = {status_id:[], sub_status_id: []};
        leadsCarList.forEach(el=>{
            if(el['status_id'] !== 7){
                maxStatusSubStatus['status_id'].push({status:el['status_id'],sub_status: el['sub_status_id']});
                // maxStatusSubStatus['sub_status_id'].push({sub_status: el['sub_status_id']});
            }
        });

        let findMaxStatus = _.maxBy(maxStatusSubStatus['status_id'], 'status'); //FIND MAX STATUS
            findMaxStatus = maxStatusSubStatus['status_id'].filter(el=> el.status === findMaxStatus.status); //FILTER GET ALL REPEATED HIGHEST STATUS
            findMaxStatus = _.maxBy(findMaxStatus, 'sub_status'); //GET HIGHEST SUB STATUS FROM ABOVE ARRAY
        let formData=Object.assign({},this.state.formData);
        if(findMaxStatus && (formData['status_id'] !== 7 || (this.state.reopenLead &&  this.state.reopenLead.reopen_lead))){
            // formData['status_id'] = (formData.orig_status_id >= findMaxStatus['status']) ? formData.orig_status_id : findMaxStatus['status'];
            // formData['sub_status_id'] = findMaxStatus['sub_status']//(formData.orig_sub_status_id >= findMaxStatus['sub_status']) ? formData.orig_sub_status_id : findMaxStatus['sub_status'];
            
            if(formData.orig_status_id >= findMaxStatus['status']){
                formData['status_id'] = formData.orig_status_id;
                formData['sub_status_id'] = (formData.orig_sub_status_id < findMaxStatus['sub_status'])?findMaxStatus['sub_status']:formData.orig_sub_status_id;
            }else{
                formData['status_id'] = findMaxStatus['status'];
                formData['sub_status_id'] = findMaxStatus['sub_status']
            }

            this.getDefaultDropdownStatus(formData.status_id,formData.sub_status_id);
            await this.setState({formData: formData});
        }
    }

    addemail(e){
        this.setState({openemail: !this.state.openemail})
        }
    addmobile(e){
        this.setState({openmobile: !this.state.openmobile})
        }
    togglePanel(e){
        this.setState({open: !this.state.open})
        }
    

    getRequiredKeyValue=async (filterData,key) => {
        let retData=[];
        if (filterData && filterData.length) {
            const requiredData=HELPER.filterDataByKey(filterData, key);
            if(requiredData && requiredData.list && requiredData.list.length)
            retData=HELPER.getDataInKeyValueFormat(requiredData.list,key);
        }
        return retData;
    }
    toogleFollowUpStatus =(status = false)=> {
        const {displayFollowUp} = this.state;
        if(displayFollowUp !== status) this.setState({displayFollowUp : status})
    }
    toogleBlockingStatus =(status = false)=> {
        const {displayBlocking} = this.state;
        if(displayBlocking!==status) this.setState({displayBlocking : status})
    }
    togglePurchaseOldCarFromSeller=(status=false,sellerType="")=>{
        let {oldCarModal} = this.state
        oldCarModal.display=status
        oldCarModal.type=sellerType
        this.setState({oldCarModal:oldCarModal})
    }
    handleChange=async(selectedKey,selectedValue) => {
        let {subStatusList,callStatusList,formData}=this.state;
        formData =(typeof formData === 'undefined') ? {} : formData;
        
        if(selectedKey === 'status'){
            let substatus = await this.getSubStatusList({pagination: false});
            let callstatus = await this.getCallStatusList({pagination:false});

            // let substatus = subStatusListFrmConst[selectedValue.value];
            formData.call_status_id_copy=formData.call_status_id_copy || formData.call_status_id;
            formData.sub_status_id_copy=formData.sub_status_id_copy || formData.sub_status_id;
            formData.status_id_copy = formData.status_id_copy || formData.status_id;
            formData.status_id=selectedValue ? selectedValue.value : '';
            formData.sub_status_id = "";
            formData.call_status_id = "";

            this.toogleFollowUpStatus();
            this.toogleBlockingStatus();
            if(substatus.length && selectedValue) {

                //CALL SUB STATUS AND CALL STATUS
                substatus = substatus.filter(el=> el.status_id === selectedValue.value);
                callstatus = callstatus.filter(el=> el.sub_status_id === formData.sub_status_id);

                subStatusList=HELPER.getOptionValueToDisplay(substatus,'sub_status');
                callStatusList=HELPER.getOptionValueToDisplay(callstatus,'call_status');

                // callStatusList=[];
                // subStatusList = substatus;
            }else{
                subStatusList=[]; 
                callStatusList=[];
            }
            // //IF CLOSED SELECTED THEN REMOVE FOLLOW DATE
            // if(formData.status_id === 7){
            //     formData.due_date = null;
            // }
            
        }else if(selectedKey === 'sub_status'){ 
            formData.call_status_id_copy = formData.call_status_id_copy || formData.call_status_id;
            formData.sub_status_id_copy = formData.sub_status_id_copy || formData.sub_status_id;
            formData.sub_status_id=selectedValue ? selectedValue.value : '';
            let callstatus = await this.getCallStatusList({pagination:false});
            // let callstatus = config.constants.CALSTATUS[selectedValue.value];
            if(selectedValue && selectedValue.value === 5) this.toogleFollowUpStatus(true);else this.toogleFollowUpStatus();  ////display follow up reason
            if(selectedValue && selectedValue.value === 21) this.toogleBlockingStatus(true);else  this.toogleBlockingStatus(); ///open blocking status
            if(selectedValue && selectedValue.value === 20) this.togglePurchaseNewCarFromSeller(true);
           
            if(selectedValue && selectedValue.value === 15) this.togglePurchaseOldCarFromSeller(true, 15);
            if(selectedValue && selectedValue.value === 16) this.togglePurchaseOldCarFromSeller(true, 16);
            if(selectedValue && selectedValue.value === 17) this.togglePurchaseOldCarFromSeller(true, 17);
            
            if(callstatus && callstatus.length && selectedValue){
                callstatus = callstatus.filter(el=> el.sub_status_id === selectedValue.value);

                callStatusList=HELPER.getOptionValueToDisplay(callstatus,'call_status');
                // callstatusList = callstatus;
            }else
                callStatusList=[];
        }else if(selectedKey === 'call_status'){
            formData.call_status_id_copy = formData.call_status_id_copy || formData.call_status_id;
            formData.call_status_id=selectedValue ? selectedValue.value : '';
        }else if(selectedKey === 'comment'){
            formData['lead_comment']=selectedValue.target.value;
        }
        else if(selectedKey === 'email'){
            formData['customer_email']=selectedValue.target.value;
        }
        else if(selectedKey === 'alternate_email'){
            formData['alternate_email']=selectedValue.target.value;
        }
        else if(selectedKey === 'mobile'){
            formData['customer_mobile']=selectedValue.target.value;
        }
        else if(selectedKey === 'alternate_mobile'){
            formData['alternate_mobile']=selectedValue.target.value;
        }
        else if(selectedKey === 'is_premium'){
            formData['is_premium']=(Number(formData['is_premium']) === 1) ? '2' : '1';
        }
        else if(selectedKey === 'is_finance_req'){
            formData['is_finance_req']=(formData['is_finance_req'] === 'yes') ? 'no' : 'yes';
        }
        else if(selectedKey === 'is_outstation'){
            formData['is_outstation']=(Number(formData['is_outstation']) === 1) ? '0' : '1';
        }
        else if(selectedKey === 'isExchange'){
            formData['isExchange']=(Number(formData['isExchange']) === 1) ? '2' : '1';
            if(Number(formData['isExchange']) === 1){
                this.toggleExchangeModal();
            }
        }
        else if(selectedKey === 'Hot' || selectedKey === 'Warm' || selectedKey === 'Cold'){
            let ratingsVal=_.find(config.constants.AGENT_RATINGS,{'label':selectedKey});
            if(ratingsVal)
                formData['agent_rating']=ratingsVal.value;
        }
        else if(selectedKey === "due_date" ){
            formData['due_date']='';

            if(selectedValue instanceof Date){
                formData['due_date']=selectedValue;
            }
        }
        else if(selectedKey === "followup_reason"){
            formData['followup_reason']=selectedValue.value;
        }
        else if(selectedKey === "blocked_reason"){
            formData['blocked_reason']=selectedValue.value;
        }

        if(selectedKey === 'status'){
            await this.setState({disableInputOnstatusClose: false});
            if(selectedValue && selectedValue.value && selectedValue.value === 7) {
                await this.setState({disableInputOnstatusClose: true});
            }
        }        
        this.setState({ callStatusList:callStatusList,subStatusList:subStatusList,formData:formData });

    }

    saveCustomerInfo=async(key) => {
        let {formData}=this.state;
        let params={
            id:(formData && formData.customer_id) ? formData.customer_id : 0
        };
        if(formData[key]){
            params[key]=formData[key];
            if(key === 'customer_mobile' || key === 'alternate_mobile'){
                if((!params[key].startsWith(config.constants.ISD_CODE[0]['startsFrom']) || params[key].length < config.constants.ISD_CODE[0]['minLength'] || params[key].length > config.constants.ISD_CODE[0]['maxLength'])){
                    toast.error(this.props.t('search.validation.mobile_invalid'));
                    return false;
                }
            }
            this.props.actions.updateCustomer(params)
        }
    }

    getAgentRatings=(ratings,label) => {
        let res=false;
        _.forEach(config.constants.AGENT_RATINGS,(rate) => {
            if(Number(rate.value) === Number(ratings) && label === rate.label){
                res=true;
            }
        })
        return res;
    }
    filterStatusList=(formData,reopen_lead) =>{
        
        let statusList=this.props.statusList
        let allStatus=[]
        if(statusList && statusList.length){
            for(let i of statusList){
                if(reopen_lead !== undefined && typeof formData !== 'undefined' && reopen_lead !== 1 && formData.status_id === 6 && formData.sub_status_id === 12){
                    if(i.value!==6) continue;
                }else if( (typeof formData !== 'undefined' && formData.status_id>i.id && reopen_lead !== undefined && reopen_lead!==1) && !((formData.status_id === 5 || formData.status_id === 6 ) && (i.value === 3))  ){
                    continue;
                }
                allStatus.push(i)
            }
        }
        return allStatus;
    }
    
    getSubStatusList=async(params) => { 

        if(this.props.subStatusListData && this.props.subStatusListData.length){
            return this.props.subStatusListData;
        }else{
            let subStatusList = await this.props.actions.getSubStatusList(params);
            return subStatusList;            
        }
    }

    getCallStatusList=async(params) => {

        if(this.props.callStatusListData && this.props.callStatusListData.length){
            return this.props.callStatusListData;
        }else{
            let callStatusList = await this.props.actions.getCallStatusList(params);
            return callStatusList;            
        }
    }

    calculateFollowupDate=(key,selected)=>{
        let {dateCalculation,formData}=this.state;
        if(key === 'days'){
            dateCalculation.days=selected.value;
        }
        if(key === 'days_num'){
            dateCalculation.days_num=selected.value;
        }
        
        if(dateCalculation.days_num && dateCalculation.days){
            let noOfDays=0;
            if(dateCalculation.days === 'DAYS'){
                noOfDays= Number(dateCalculation.days_num)
            }else if(dateCalculation.days === 'WEEKS'){
                noOfDays=7 * Number(dateCalculation.days_num)
            }
            let  dt=moment().add(noOfDays,'days').toISOString();
            formData.due_date=dt;
        }
        this.setState({dateCalculation,formData})
        
    }
    toggleExchangeModal=() => {
        const {showExchangeModal}=this.state;
        this.setState({showExchangeModal : !showExchangeModal})
    }
    getPreviousStatusValue=async () => {
        const {timelineDetails}=this.state;
        let resp={prevStatus: 0, prevSubStatusId: 0, prevCallStatusId:0};
        for(let timeData of timelineDetails){
            if (Number(timeData['leads_cars_id']) === 0 && Number(timeData['status_id']) !== 0) {
                resp['prevStatus']=timeData['status_id'];
                resp['prevSubStatusId']=timeData['sub_status_id'];
                resp['prevCallStatusId']=timeData['calling_status_id'];
                break;
            }
        }
        return resp;
    }
    
    reopen=async() =>{
        let {formData,reopenLead}=this.state;
        let openFlag=0;
        let before6MonthDate   =moment().subtract(6, 'months').toISOString();
        let leadAddedDate=moment(formData['lead_added_Date']).toISOString();
        if(this.roleId !== config.constants.ADMIN_ROLE_ID && this.roleId !== config.constants.TELE_CALLER_ROLE_ID && formData['lead_added_Date'] && (leadAddedDate < before6MonthDate)){
            openFlag=1;
        }else{
            openFlag=0;
        }
        if(Number(openFlag) === 1){
            toast.error(this.props.t('summaryForm.validation.lead_created_six_month_back'));
            return false;
        }else{ 
            if ((Number(formData['status_id']) === 7 || (Number(formData['status_id']) === 6 && Number(formData['sub_status_id']) === 12 && Number(formData['is_main_lead_save']) === 1))) { 
                reopenLead['reopen_lead']=1;
                let statusList =this.filterStatusList(formData,1)
                this.setState({reopenLead,statusList:statusList})
                // this.togglePanel()
                
                this.props.updateReopenStatus(reopenLead);
                this.calcSummaryStatus(this.state.leadsCarList)

                await this.props.actions.setReopenLead(reopenLead)
            }
        }
    }

    isReopenButtonShow=() => {
        let {formData}=this.state;
        let resp=false;
        if(((this.roleId === config.constants.TEAM_LEAD_ROLE_ID || this.roleId === config.constants.CALL_CENTRE_HEAD_ROLE_ID ) && (Number(formData['sub_status_id']) === 12 && (this.state.conversionStatus && [1,2].indexOf(this.state.conversionStatus['0']['cnfm_status']) !== -1)))){
            resp=true;
        }
        return resp;
    }

    saveSummaryForm=async() => {
        let postData={}
        let {formData}=this.state;
        
        let requirementResponse = (this.props.requirementResponse && this.props.requirementResponse.data && Object.keys(this.props.requirementResponse.data).length) ? this.props.requirementResponse.data : {};

        postData['tempLead'] =0         //$('#tempLead').val();
        if(formData){
            postData['inStatus']                =    formData.status_id
            postData['inSubStatus']             =    formData.sub_status_id    
            postData['followup_reason']         =    formData.followup_reason    
            postData['blocked_reason']          =    formData.blocked_reason        
            postData['inCallstatus']            =    formData.call_status_id;
            postData['num_attempt']             =    formData.num_attempt;    
            postData['dueDate']                 =    formData.due_date;   
            postData['ratingValue']             =    formData.ratings     
            postData['leadId']                  =    formData.id ;                     
            postData['chkFinance']              =    (formData.is_finance_req === 'yes') ? true : false   //$('#Finance').prop('checked');
            postData['chkPremium']              =    (Number(formData.is_premium) === 1) ? true : false //$('#is_premium').prop('checked');       
            postData['leadCommentText']         =    formData.lead_comment;               
            postData['customer_city']           =    formData.customer_city_id;          
            postData['source_id']               =    formData.source_id;            
            postData['customerId']              =    formData.customer_id               
            postData['agent_rating']            =    formData.agent_rating              
            postData['budget']                  =    requirementResponse['budget']  || formData.budget;                  
            postData['chkExchange']             =    (Number(formData.isExchange) === 1) ? true : false;                  
            postData['customer_email']          =    formData.customer_email                         
            postData['customer_name']           =    formData.customer_name;              
            postData['customer_mobile']         =    formData.customer_mobile;                       //$.trim($('#cust_phone').val());
            postData['whatsNumberCheckbox']     =    (formData.isWhatsappNoChangeChecked) ? formData.isWhatsappNoChangeChecked : false      //$('#changeWhatsupNumber').is(':checked');
            postData['alternate_email']         =    formData.alternate_email                               //$('#alternate_email').val();
            postData['alternate_mobile']        =    formData.alternate_mobile                                   //$('#alternate_mobile').val();
            postData['whatsapp_mobile']         =    formData.whatsapp_mobile                                      //$('#whatsapp_mobile').val();
            postData['shareWhatsApp']           =    (formData.isWhatsappShareChecked) ? formData.isWhatsappShareChecked : false   //$('#shareWhatsApp').is(':checked');
            postData['outstation']              =    (Number(formData.is_outstation) === 1) ? true : false                //$("#outstation").is(":checked");
            postData['main_lead_dealer_id']     =    Number(formData.dealer_id) || 0
        }
        
        let prevState=await this.getPreviousStatusValue();
        postData['prevStatus']           =  formData.prevStatus || prevState.prevStatus; 
        postData['prevSubStatus']        =  formData.prevSubStatus || prevState.prevSubStatusId;
        postData['prevCallstatus']       =  prevState.prevCallStatusId;
        postData['chat_source' ]         ='' //$('#chat_source').val();
            
        postData['org_due_date']         =''      //$('#org_due_date').val();
        postData['wwwpath']              = ''               //$('#baseurl').val();
        postData['leadCarId']         ='';
        postData['isDialerSent']      ='';
        postData['language']=          ''                   //$('#cust_pre_lang').val();
        postData['locationId']=          ''         //$('#locationId').val();
        var emailPattern=/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;        
        var mob=/^[1-9]\d{7,11}$/;
        postData['valIsEmail']=2;
        postData['valIsSms']=2;
        postData['shareWhatsAppVal'] ='0';
        let {leadsCarList}=this.state;
        _.forEach(leadsCarList,(leadsCar)=>{
            let subleadId=leadsCar.id;
            postData.leadCarId=postData.leadCarId+subleadId+',';
            let dialerSentVal=2;
            //if($('#is_dealer_sent_'+subleadId).is(":checked")) {
            if(+leadsCar.is_dealer_sent === 1){
                dialerSentVal=1;
            }
            postData.isDialerSent=postData.isDialerSent+"&is_dealer_sent_"+subleadId+"="+dialerSentVal;
        });
        
        if(postData.leadCarId !== '') {
            postData.leadCarId=postData.leadCarId.slice(0,-1);
        }                         
        if(postData.chkFinance === true){
            postData['valFinance']=1;
        }else{
            postData['valFinance']=0;
        }
        if(postData.chkPremium === true){
            postData['valPremium']=1;
        }else{
            postData['valPremium']=2;
        }
        if(postData.chkExchange === true){
           postData['valExchange']=1;
        }else{
            postData['valExchange']=2;
        }
        if(postData.customer_email !== '') {
            postData['valIsEmail']=1;
        }
        if(postData.customer_mobile !== '') {
            postData['valIsSms']=1;
        }
        var i=0;
        var alerMsg="";

        if(mob.test(postData.customer_mobile) === false || (!postData.customer_mobile.startsWith(config.constants.ISD_CODE[0]['startsFrom']) || postData.customer_mobile.length < config.constants.ISD_CODE[0]['minLength'] || postData.customer_mobile.length > config.constants.ISD_CODE[0]['maxLength'])) {
            alerMsg=alerMsg+'  -'+ this.props.t('summaryForm.validation.please_enter_valid_customer_mobile_number')+'\n';
            i++;
        }
        if (postData.alternate_mobile) {
            if (mob.test(postData.alternate_mobile) === false) {
                alerMsg+= '  -'+this.props.t('summaryForm.validation.please_enter_vaild_alternate_mobile_number')+'\n';
                i++;
            }
        }
        // if (postData.whatsapp_mobile && Number(postData.source_id) === 90) {
        //     if ( (postData.whatsapp_mobile.length) < 10 || (postData.whatsapp_mobile.length) > 10 || isNaN(postData.whatsapp_mobile) ) {
        //         alerMsg+= '  -'+this.props.t('summaryForm.validation.please_enter_vaild_whatsapp_mobile_number')+'\n';
        //         i++;
        //     }
        // }
        if (postData.whatsNumberCheckbox === false && Number(postData.source_id) === 90 && postData.whatsapp_mobile === '' && postData.shareWhatsApp === true) {
            alerMsg+= '  -'+this.props.t('summaryForm.validation.please_enter_whatsapp_mobile_number')+'\n';
            i++;
        }
        if(postData.whatsNumberCheckbox === true && postData.customer_mobile !== ''  && postData.shareWhatsApp === true){
            postData.shareWhatsAppVal ='1';
        }
        if(postData.whatsNumberCheckbox === false && postData.whatsapp_mobile !=='' && postData.shareWhatsApp === true){
            postData.shareWhatsAppVal ='1';
        }
        
        if (postData.customer_email) {
            if ( !emailPattern.test(postData.customer_email)) {
                alerMsg+= '  -'+this.props.t('summaryForm.validation.please_enter_vaild_email')+'\n';
                i++;
            }
            // } else {
            //     if(!checkEmail(customer_email)) {              
            //         domainStr=customer_email.split('@').slice(1);
            //         domainName=domainStr[0].split('.').slice(0);//alert(domainName[0]);
            //         if(domainName[0] == 'gaadi') {
            //             $('#errEmail').text('Enter a valid email id.');
            //             $('#errEmail').show();
            //             return false;
            //         } else {
            //             $('#errEmail').text('Please cross check email');
            //         }
            //         $('#errEmail').show();
            //     } else {
            //         $('#errEmail').hide();
            //     }
            // }
        }    
        if (postData.alternate_email) {
            if ( !emailPattern.test(postData.alternate_email)) {
                alerMsg+= '  -'+this.props.t('summaryForm.validation.please_enter_vaild_alternate_email')+'\n';
                i++;
            }
        }   
        if(postData.inStatus === "" || postData.inStatus === null){
            alerMsg+= '  -'+this.props.t('summaryForm.validation.please_select_status')+'\n';
            i++;
        }
        if(postData.inSubStatus === "" || postData.inSubStatus === null){
            alerMsg+= '  -'+this.props.t('summaryForm.validation.please_select_sub_status')+'\n';
            i++;
        } 
        if(Number(postData.inStatus) === 3 || Number(postData.inStatus) === 5) {
            if(!postData.budget || Number(postData.budget) === -1) {
                alerMsg+= '  -'+this.props.t('summaryForm.validation.please_enter_customer_requirements')+'\n';
                i++;
            } 
            // else if(formData.req_body_type === null || formData.req_body_type === '') {
            //     alerMsg+= '  -'+this.props.t('summaryForm.validation.please_enter_customer_requirements')+'\n';
            //     i++;
            // }
        }
        if(Number(postData.inSubStatus) === 5 && (postData.followup_reason) === 'car_not_available') {
            if(postData.locationId === '') {
                alerMsg+= '  -'+this.props.t('summaryForm.validation.please_select_customer_locality')+'\n';
                i++;
            }
            // var otherLocVal=$('#custmer_location_name_other').val();//alert(otherLocVal);
            // if($('#locationId').val() == '-1' && (otherLocVal.length < '3'|| $.inArray(otherLocVal.toLowerCase(), arrInvalidLoc) !== -1 )) {
            //     alerMsg+='\t- Please enter valid customer locality.'+'\n';
            //     flag=2;
            // }
        }
        if(Number(postData.inStatus) !== 7 && Number(postData.inCallstatus) === ""){
            if(!(Number(postData.inStatus) === 6 && Number(postData.inSubStatus) === 12)) {
                alerMsg+= '  -'+this.props.t('summaryForm.validation.please_select_call_status')+'\n';
                i++;
            }
        }
        if(Number(postData.inSubStatus) === 5 && (postData.followup_reason) === '') {
            alerMsg+= '  -'+this.props.t('summaryForm.validation.please_select_follow_up_reason')+'\n';
            i++;
        }
        if(Number(postData.inSubStatus) === 21 && Number(postData.blocked_reason) === '') {
            alerMsg+= '  -'+this.props.t('summaryForm.validation.please_select_blocking_reason')+'\n';
            i++;
        }
        if((Number(postData.inStatus) !== 7) && !postData.dueDate){
            
            if(!(Number(postData.inStatus) === 6 && Number(postData.inSubStatus) === 12)) {
                alerMsg+= '  -'+this.props.t('summaryForm.validation.please_enter_follow_date')+'\n';
                i++;
            }
        } else if(postData.dueDate !== "" && (Number(postData.inStatus) !== 7)) {
            let dueDt=moment(postData.dueDate).toISOString();
            var today=moment().toISOString();
            let maxDate=moment().add(90,'days').toISOString();           
            if (today > dueDt) {
                alerMsg+= '  -'+this.props.t('summaryForm.validation.follow_date_should_be_greater_than_current_date_and_time')+'\n';
                i++;
            } else if(dueDt > maxDate) {
                alerMsg+= '  -'+this.props.t('summaryForm.validation.follow_date_should_be_less_than_90_days_from_current_date_and_time')+'\n';
                i++;
            }
        }
        let agentRatingValue = config.constants.AGENT_RATINGS.map(rating=>rating.value);
        if (!agentRatingValue.includes(+postData.agent_rating) ) {
            alerMsg += '  -' + this.props.t('summaryForm.validation.agent_rating_madatory') + '\n';
            i++;
        }
        if(i > 0){
            var msg=this.props.t('summaryForm.validation.sorry_your_request_cannot_be_completed_due_to_below_reasons')+" :\n\n";
            toast.error(msg+alerMsg);
            return false;
        }
        this.setState({summarySave:true});
    this.props.actions.saveMainLead(postData)
    .then((result) =>{        
        if (result.status === 200) {
            this.setState({summarySave:false});
            let strRes=_.cloneDeep(result.data) || ''; 

            this.props.mainSummaryStatus('success');
            
            //isMainSaveLeadFlag=false;
            var strResArr=strRes.split('_');
            if(strResArr['0'] === "yes"){

                toast.success(this.props.t('summaryForm.validation.lead_has_been_saved_successfully'));
                 this.setState({isSaveMainLeadComment:false, reopenLead:{...this.state.reopenLead, reopen_lead:0},formData:{...formData,status_id:postData['inStatus'],sub_status_id:postData['inSubStatus'],orig_status_id:postData['inStatus'],orig_sub_status_id:postData['inSubStatus'],prevStatus:postData['inStatus'],prevSubStatus:postData['inSubStatus'], status_id_copy: null, sub_status_id_copy: null, call_status_id_copy: null}});
                 this.setState({open:false});
                //window.location.reload();
                // createCookie('resend_flag_'+requestedLeadId, requestedLeadId, 30);
                // localStorage.setItem('comments_'+requestedLeadId, '');
                // redirectionUrl=wwwpath+"index.php?module=leadfinder&control=leaddetailcontrol&action=leaddetailinfo&leadId="+leadId;
                // showToastBox('snackbar', redirectionUrl);
            } else if(strResArr['0'] === 'invalidReq') {
              toast.error(this.props.t('summaryForm.validation.please_update_customer_requirements_they_updated_month_ago'));
            } else if(strResArr['0'] === 'emptyInvalidReq') {
              toast.error(this.props.t('summaryForm.validation.please_update_customer_requirements'));
            } else if(strResArr['0'] === "new"){
                toast.success(this.props.t('summaryForm.validation.lead_has_been_saved_successfully'));
                //window.location.reload();
                // createCookie('resend_flag_'+strResArr['1'], strResArr['1'], 30);
                // redirectionUrl=wwwpath+"index.php?module=leadfinder&control=leaddetailcontrol&action=leaddetailinfo&leadId="+strResArr['1'];
                // showToastBox('snackbar', redirectionUrl);
            } else {
                toast.error(this.props.t('summaryForm.validation.please_try_again_later'));
            }
            // $('#saveCommentStatus').prop('disabled', false);
            // $('#loadimage').html("");

        } else if (result.status === 401 && result.data.status === 401) {
            this.setState({ redirect: true })
        }else{
            this.setState({summarySave:false});
            toast.error('Error in save');
        }
    })
    .catch((error) => {
        this.setState({summarySave:true});
    });
    
    
    }
    makeExchangeRequest=async (data) => {
        
        let response =await LeadService.makeSellerLead(data);
        if(response.status === 200){
            
            toast.success(response.data.message)
        }else{
            toast.error("error")
        }
        this.setState({showExchangeModal:false})
    }
    submitPurchaseDetails=(data)=>{
        let {oldCarModal:{type} = {}} = this.state
        ////send data to api
        ////on success open toast and close modal
        data['closed_sub_status_id'] = type;
        this.togglePurchaseOldCarFromSeller();
        this.props.actions.saveClosedLeadInfo(data)
    }
    submitPurchaseDetailsNewCar=(data)=>{
        data['closed_sub_status_id'] = 20;
        this.togglePurchaseNewCarFromSeller();
        this.props.actions.saveClosedLeadInfo(data)
    }
    togglePurchaseNewCarFromSeller=()=>{
        const {newCarModelDisplay} = this.state
        this.setState({newCarModelDisplay:!newCarModelDisplay})
    }
    render() {
        const { formData,statusList,subStatusList,callStatusList,reopenLead,showExchangeModal,filterData,displayFollowUp,displayBlocking,oldCarModal,newCarModelDisplay,summarySave, disableInputOnstatusClose}=this.state;
        const {ADMIN_ROLE_ID, TEAM_LEAD_ROLE_ID, CALL_CENTRE_HEAD_ROLE_ID, TELE_CALLER_ROLE_ID}=config.constants;

        //REMOVE PREV STATUS OF MATCHED STATUS
        let matchedStatus = 0;
        let assignedListStatus = [];
         statusList.forEach(el=>{
            let currentCarStatus = formData.status_id_copy || formData.status_id;

            if( reopenLead && reopenLead['reopen_lead']){ //IF REOPEN LEAD THEN SHOW THESE STATUS
                if([1,2,3,5,6,7].includes(el.value) ){
                    assignedListStatus.push(el);
                }
            }
            //FOR STATUS 5(walkin) SHOW STATUS 3(verified)
            else if(( (el.value === 3 && currentCarStatus === 5) || (el.value === currentCarStatus || matchedStatus) ) && (!reopenLead || (reopenLead && !reopenLead['reopen_lead']))){
                matchedStatus = 1; 
                assignedListStatus.push(el);
            }
        });

        //FILTER SUB STATUS SELECT BOX, IF PRESELECTED THE REMOVE PREV OPTION FROM CURRENT SELECTED ELSE SHOW ALL MATCHED STATUS ID OPTIONS
        let matchedSubStatus = 0;
        let mismatchedSubStatus = 0;
        let assignedListSubStatus = [];
        subStatusList.forEach(el=>{
            if(el.status_id === formData.status_id){
                if( reopenLead && (reopenLead['reopen_lead'] || !reopenLead['reopen_lead']) ){// IF REOPEN LEAD THEN SHOW ALL SUB STATUS
                    assignedListSubStatus.push(el);
                }else if((!formData.sub_status_id_copy && el.value === formData.sub_status_id) || matchedSubStatus){ 
                    matchedSubStatus++; 
                    assignedListSubStatus.push(el);
                }else if((formData.status_id_copy && formData.status_id_copy === formData.status_id && formData.sub_status_id_copy === el.value) || matchedSubStatus){
                    matchedSubStatus++; 
                    assignedListSubStatus.push(el);
                }else if((formData.status_id_copy && formData.status_id_copy !== formData.status_id )){
                    mismatchedSubStatus++;
                    assignedListSubStatus.push(el);
                }
            }
            
        });

        //FILTER CALL STATUS SELECT BOX, IF PRESELECTED THE REMOVE PREV OPTION FROM CURRENT SELECTED ELSE SHOW ALL MATCHED SUB STATUS ID OPTIONS
        let matchedCallStatus = 0;
        let assignedListCallStatus = [];
        callStatusList.forEach(el=>{
            if(el.sub_status_id === formData.sub_status_id){
                if(!formData.call_status_id){
                    assignedListCallStatus.push(el);
                }else if((!formData.call_status_id_copy && el.value === formData.call_status_id) || matchedCallStatus){
                    matchedCallStatus++; 
                    assignedListCallStatus.push(el);
                }else if(!mismatchedSubStatus && ((formData.sub_status_id_copy && formData.sub_status_id_copy === formData.sub_status_id && formData.call_status_id_copy === el.value) || matchedCallStatus)){ 
                    matchedCallStatus++; 
                    assignedListCallStatus.push(el);
                }else if(!mismatchedSubStatus && (formData.sub_status_id_copy && formData.sub_status_id_copy !== formData.sub_status_id )){
                    assignedListCallStatus.push(el); 
                }else{
                    assignedListCallStatus.push(el); 
                }
            }
            
        });
        

        return (
            <div className="pd-15  summaryWrapper">
                <div className={this.state.open ? "panel summary-panel open" :"panel summary-panel "}>
                    <div className="panel-heading" onClick={(e)=>this.togglePanel(e)}>
                        <h2 className="panel-title">{this.props.t('summaryForm.title')}</h2>
                        {(this.state.isSaveMainLeadComment) ? <span className="saveMainLeadComment">Click on Submit to save this lead</span> : ''}
                    </div>
                    <div className="panel-body" >
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <InputField 
                                                type="email"
                                                name="email"
                                                id="email"
                                                value={(formData) ? formData.customer_email : ''}
                                                label={this.props.t('summaryForm.email')}
                                                onChange={this.handleChange.bind(this,'email')}
                                                onBlur={this.saveCustomerInfo.bind(this,'customer_email')}
                                            />
                                            <div className={this.state.openemail ? "" :"hide"}>
                                            <InputField 
                                                type="email"
                                                name="alternate_email"
                                                id="alternate_email"
                                                value={(formData) ? formData.alternate_email : ''}
                                                label={this.props.t('summaryForm.alternateEmail')}
                                                onChange={this.handleChange.bind(this,'alternate_email')}
                                                onBlur={this.saveCustomerInfo.bind(this,'alternate_email')}
                                            />
                                        </div>
                                            <div className="text-right addmore"><span className="text-link " onClick={(e)=>this.addemail(e)}> {this.state.openemail ? "- Remove Email" :"+ Add Email"}</span></div>
                                        </div>
                                       
                                        <div className="col-sm-12">
                                            <InputField 
                                                type="tel"
                                                name="mobile"
                                                id="mobile"
                                                value={(formData) ? formData.customer_mobile :''}
                                                label={this.props.t('summaryForm.mobile')}
                                                onChange={this.handleChange.bind(this,'mobile')}
                                                onBlur={this.saveCustomerInfo.bind(this,'customer_mobile')}
                                            />
                                             <div className={this.state.openmobile ? "" :"hide"}>
                                             <InputField 
                                                type="tel"
                                                name="alternate_mobile"
                                                id="alternate_mobile"
                                                value={(formData)?formData.alternate_mobile :''}
                                                label={this.props.t('summaryForm.alternateMobile')}
                                                onChange={this.handleChange.bind(this,'alternate_mobile')}
                                                onBlur={this.saveCustomerInfo.bind(this,'alternate_mobile')}
                                            />
                                           
                                        </div>
                                            <div className="text-right addmore"><span className="text-link " onClick={(e)=>this.addmobile(e)}> {this.state.openmobile ? "- Remove Mobile" :"+ Add mobile"}</span></div>
                                        </div>
                                       {/* ////// make whatsapp for temp lead and based on status  */}
                                       
                                  
                                        <div className="col-sm-12">
                                            <label>{this.props.t('summaryForm.interestedIn')}</label>
                                            <div className="clearfix rc-inline">
                                            <CheckBox type="checkbox" label="Premium" name="premium" id="premium" checked={(formData && Number(formData.is_premium) === 1) ? true : false} onChange={this.handleChange.bind(this,'is_premium')}/>
                                            <CheckBox type="checkbox" label="Finance" name="finance" id="finance" checked={(formData && (formData.is_finance_req) === 'yes') ? true : false} onChange={this.handleChange.bind(this,'is_finance_req')}/>
                                            <CheckBox type="checkbox" label="Exchange" name="exchange" id="exchange" checked={(formData && Number(formData.isExchange) === 1) ? true : false} onChange={this.handleChange.bind(this,'isExchange')}/>
                                            <CheckBox type="checkbox" label="Outstation" name="outstation" id="outstation" checked={(formData && Number(formData.is_outstation) === 1) ? true : false} onChange={this.handleChange.bind(this,'is_outstation')}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <label>{this.props.t('summaryForm.leadRating')}</label>
                                            <div className="clearfix rc-inline">
                                            <Radiobox type="radio" label="Hot" name="lead_rating" id="lead_rating" checked={(formData && formData.agent_rating)?this.getAgentRatings(formData.agent_rating,'Hot') : false} onChange={this.handleChange.bind(this,'Hot')} />
                                            <Radiobox type="radio" label="Warm" name="lead_rating" id="lead_rating" checked={(formData && formData.agent_rating)?this.getAgentRatings(formData.agent_rating,'Warm') : false} onChange={this.handleChange.bind(this,'Warm')} />
                                            <Radiobox type="radio" label="Cold" name="lead_rating" id="lead_rating" checked={(formData && formData.agent_rating)?this.getAgentRatings(formData.agent_rating,'Cold') : false} onChange={this.handleChange.bind(this,'Cold')}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 form-group">
                                            <label>{this.props.t('summaryForm.status')}</label>
                                            <Select
                                                className="mrg-b5"
                                                id="status"
                                                name="status"
                                                onChange={this.handleChange.bind(this,'status')}
                                                options={(assignedListStatus) ? assignedListStatus : []}
                                                value={formData && assignedListStatus && assignedListStatus.filter(({ value }) => value === formData.status_id)}
                                                
                                            />
                                        </div>
                                        <div className="col-sm-4 form-group">
                                            <label>{this.props.t('summaryForm.subStatus')}</label>
                                            <Select
                                                className="mrg-b5"
                                                id="status"
                                                name="status"
                                                onChange={this.handleChange.bind(this,'sub_status')}
                                                options={(assignedListSubStatus) ? assignedListSubStatus : []}
                                                value={formData && assignedListSubStatus && assignedListSubStatus.filter(({ value }) => value === formData.sub_status_id)}
                                                
                                            />
                                        </div>
                                        
                                        {displayFollowUp && <div className="col-sm-4 form-group">
                                            <label>{this.props.t('summaryForm.followUpReason')}</label>
                                            <Select
                                                className="mrg-b5"
                                                id="followup_reason"
                                                name="follow_up"
                                                onChange={this.handleChange.bind(this,'followup_reason')}
                                                options={(config.constants.FOLLOW_UP_REASON) ? config.constants.FOLLOW_UP_REASON : []}
                                                
                                                // value={formData && subStatusList && subStatusList.filter(({ value }) => value === formData.sub_status_id)}
                                            />
                                        </div>}
                                        {displayBlocking && <div className="col-sm-4 form-group">
                                            <label>{this.props.t('summaryForm.blockingReason')}</label>
                                            <Select
                                                className="mrg-b5"
                                                id="blocked_reason"
                                                name="blocked_reason"
                                                onChange={this.handleChange.bind(this,'blocked_reason')}
                                                options={(config.constants.blockReasonMapping) ? config.constants.blockReasonMapping : []}
                                                
                                                // value={formData && subStatusList && subStatusList.filter(({ value }) => value === formData.sub_status_id)}
                                            />
                                        </div>}
                                        <div className="col-sm-4 form-group">
                                            <label>{this.props.t('summaryForm.callingStatus')}</label>
                                            <Select
                                                className="mrg-b5"
                                                id="call_status"
                                                name="call_status"
                                                onChange={this.handleChange.bind(this,'call_status')}
                                                options={(assignedListCallStatus) ? assignedListCallStatus : []}
                                                value={formData && assignedListCallStatus && assignedListCallStatus.filter(({ value }) => value === formData.call_status_id)}
                                                
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-12 form-group">
                                            <label>{this.props.t('summaryForm.followupDate')}</label>
                                            <DatePicker
                                                id="followup_date"
                                                name="followup_date"
                                                dateFormat={config.constants.dateFormatDMYHIS}
                                                // onSelect={this.handleSelect} //when day is clicked
                                                onChange={this.handleChange.bind(this,"due_date")} //only when value has changed
                                                minDate={new Date()}
                                                minTime={new Date()}
                                                maxTime={new Date("23:00")}     
                                                selected={(formData && formData.due_date) ? moment(formData.due_date).toDate() : ''}
                                                className="form-control"
                                                autocompleate="off"
                                                placeholderText="Follow up date"
                                                showTimeSelect={true}
                                                disabled={disableInputOnstatusClose}
                                                
                                            />
                                            {/* <InputField 
                                                type="text"
                                                name="followup_date"
                                                id="followup_date"
                                                value={(formData && formData.due_date) ? formData.due_date.split('T')[0] : ''}
                                                label={this.props.t('summaryForm.followupDate')}
                                                onChange={this.handleChange.bind(this,'followupDate')}
                                                readonly={true}
                                            /> */}
                                        </div>
                                        <div className="col-sm-12 form-group">
                                            <label>{this.props.t('summaryForm.after')}</label>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                <Select
                                                    className="mrg-b5"
                                                    id="days"
                                                    name="days"
                                                    onChange={this.calculateFollowupDate.bind(this,'days')}
                                                    options={(DAYS) ? DAYS : []}
                                                    value={DAYS && DAYS.filter(({ value }) => value === this.state.dateCalculation.days)}
                                                    isDisabled={disableInputOnstatusClose}
                                                />
                                                    
                                                </div>
                                                <div className="col-sm-6">
                                                <Select
                                                    className="mrg-b5"
                                                    id="days_num"
                                                    name="days_num"
                                                    onChange={this.calculateFollowupDate.bind(this,'days_num')}
                                                    options={(DAYS_NUM) ? DAYS_NUM : []}
                                                    value={DAYS_NUM  && DAYS_NUM.filter(({ value }) => value === this.state.dateCalculation.days_num)}
                                                    isDisabled={disableInputOnstatusClose}

                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 form-group">
                                            <label>{this.props.t('summaryForm.comments')}</label>
                                            <textarea className="form-control" name="comments" value={(formData && formData['lead_comment']) ? formData['lead_comment'] : ''} onChange={this.handleChange.bind(this,'comment')}>

                                            </textarea>
                                        </div>
                                        {(formData && formData['sub_status_id'] && Number(formData['sub_status_id']) === 12 &&  this.state.conversionStatus && this.state.conversionStatus.length) ?
                                        <div className="col-sm-12 form-group">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="summary-label">
                                                        Confirmation Status
                                                    </div>
                                                </div>
                                                <div className="col-sm-6" >
                                                    {(this.state.conversionStatus && this.state.conversionStatus.length) ? this.state.conversionStatus['0']['confirmStatus'] : ''}
                                                </div>
                                            </div>
                                        </div> : ''}
                                        {(this.state.conversionStatus && this.state.conversionStatus.length && Number(this.state.conversionStatus['0']['cnfm_status']) === 2) ?
                                        <div className="col-sm-12 form-group">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="summary-label">
                                                    Reason
                                                    </div>
                                                </div>
                                                <div className="col-sm-6" >
                                                    {this.state.conversionStatus['0']['cnfm_reason']}
                                                </div>
                                            </div>
                                        </div> : '' }
                                    </div>

                                </div>
                            </div>

                            <div className="form-group text-center">
                            {/**check if not reopen leas and status is close */}
                            {(reopenLead && formData && reopenLead.reopen_lead !== 1  && (this.roleId === TEAM_LEAD_ROLE_ID || this.roleId === CALL_CENTRE_HEAD_ROLE_ID || this.roleId === ADMIN_ROLE_ID || this.roleId === TELE_CALLER_ROLE_ID) && (Number(formData['status_id']) === 7 || (Number(formData['status_id']) === 6 && Number(formData['sub_status_id']) === 12 && Number(formData['is_main_lead_save']) === 1))) ? 
                            
                            
                                (
                                    (this.isReopenButtonShow()) //IF TRUE DO NOTHING
                                        ? 
                                            ''
                                        : 

                                        (//CHECK IF STATUS OR SUBSTATUS IS CHANGED TO CLOSE THEN SHOW SAVE BUTTON
                                            ((formData['sub_status_id_copy'] && formData['status_id_copy']) && Number(formData['status_id']) === 7 && reopenLead )
                                            ?
                                                (summarySave
                                                    ?
                                                        <button type="submit" name="submit" disabled className="btn btn-primary" >{this.props.t('summaryForm.please_wait')}</button>
                                                    :
                                                        (formData['status_id'] === 7 && formData['status_id_copy'] === 7)
                                                        ?
                                                            <button type="button" className="btn btn-primary" id="saveCommentStatus" data-target="#" data-toggle="modal" onClick={this.reopen.bind(this)}>Reopen</button> 
                                                        :
                                                            <button type="submit" name="submit" className="btn btn-primary" onClick={this.saveSummaryForm.bind(this)}>{this.props.t('summaryForm.submit')}</button>)
                                                            


                                            : //ELSE SHOW REOPEN BUTTON OF STATUS IS CLOSE
                                                    
                                                (Number(formData['status_id']) === 6 && reopenLead.reopen_lead !== 1)
                                                ?
                                                    (summarySave
                                                    ?
                                                        <button type="submit" name="submit" disabled className="btn btn-primary" >{this.props.t('summaryForm.please_wait')}</button>
                                                    :
                                                        <button type="submit" name="submit" className="btn btn-primary" onClick={this.saveSummaryForm.bind(this)}>{this.props.t('summaryForm.submit')}</button>)
                                                :
                                                    <button type="button" className="btn btn-primary" id="saveCommentStatus" data-target="#" data-toggle="modal" onClick={this.reopen.bind(this)}>Reopen</button> 

                                        )
                                        
                                )

                                : //IF MAIN LEAD STATUS NOT CLOSED THEN CHECK OTHER CONDITIONS
                                
                                (
                                    
                                    ((!formData['sub_status_id_copy'] && !formData['status_id_copy']) && reopenLead &&  formData && reopenLead.reopen_lead !== 1 && Number(formData['status_id']) === 7) 
                                        ? 
                                            <span  className="saveMainLeadComment">Lead is marked closed.</span> 
                                        :
                                            (
                                                ((reopenLead && reopenLead.reopen_lead !== 1 ) &&  formData && (Number(formData['status_id']) === 6) && (Number(formData['sub_status_id']) === 12) && (Number(formData['is_main_lead_save']) === 1))

                                                ?
                                                <span  className="saveMainLeadComment">Lead is marked Purchased-Converted.</span> 
                                                
                                                : //ELSE DEFAULT SAVE BUTTON 

                                                    (summarySave
                                                    ?
                                                        <button type="submit" name="submit" disabled className="btn btn-primary" >{this.props.t('summaryForm.please_wait')}</button>
                                                    :
                                                        <button type="submit" name="submit" className="btn btn-primary" onClick={this.saveSummaryForm.bind(this)}>{this.props.t('summaryForm.submit')}</button>)
                                                
                                            ) 
                                    
                                    
                                )
                            }
                            </div>
                    </div>
                </div>
                {showExchangeModal && <ModalPopup id="exchangeModal" className="modal modal-lg" title="Exchange-Details"  modalClose={this.toggleExchangeModal}>
                        <ExchangeInfo
                            mmvList={this.props.makeModelVersionList}
                            yearRange={filterData.fromYearRange}
                            makeExchangeRequest={this.makeExchangeRequest}
                            leadData={this.state.leadData}
                        /> 
                </ModalPopup>}
                {oldCarModal && Object.keys(oldCarModal).length>0 && oldCarModal.display && <ModalPopup modalClose={this.togglePurchaseOldCarFromSeller} className="modal modal-lg main-lead-closedpopup" title="Closed - Purchased Used Car">
                    <ClosedPurchaseCar
                        mmvList={this.props.makeModelVersionList}
                        submitPurchaseDetails={this.submitPurchaseDetails} leadData={this.state.leadData}
                    />
                </ModalPopup>}
                
                {newCarModelDisplay && <ModalPopup modalClose={this.togglePurchaseNewCarFromSeller} className="modal modal-md" title="Closed - Purchased New Car">
                    <ClosedNewPurchasedCar
                        mmvList={this.props.makeModelVersionList}
                        submitNewPurchaseDetails={this.submitPurchaseDetailsNewCar} leadData={this.state.leadData}
                    />
                </ModalPopup>}
            </div>
            
        );
    }
}

const mapStateToProps=(state, ownProps) => ({
    leadData : state.lead.listLead,
    timelineDetails: state.lead.timelineDetails,
    leadsCarList:state.lead.leadsCarList,
    reopenLead:state.lead.leadReopenData,
    conversionStatus:state.lead.conversionStatus,
    makeModelVersionList: state.lead.makeModelVersionList,
    filterList: state.lead.filterList,
    dealerListData:state.lead.dealerListData,
    statusListData:state.lead.statusListData,
    subStatusListData:state.lead.subStatusListData,
    callStatusListData:state.lead.callStatusListData,
    requirementResponse: state.lead.requirementResponse
    
});
const mapDispatchToProps=dispatch => {
    return {
        actions: {
            saveMainLead:bindActionCreators(
                LeadActions.saveMainLead,
                dispatch
            ),
            updateCustomer:bindActionCreators(
                LeadActions.updateCustomer,
                dispatch
            ),
            setReopenLead:bindActionCreators(
                LeadActions.setReopenLead,
                dispatch
            ),
            saveClosedLeadInfo:bindActionCreators(
                LeadActions.saveClosedLeadInfo,
                dispatch
            ),
            getSubStatusList: bindActionCreators(
                // LeadActions.getSubStatusList,
                LeadActions.getSubStatus,
                dispatch
            ),
            getCallStatusList: bindActionCreators(
                LeadActions.getCallStatusList,
                dispatch
            )
    }   }
}

export default withTranslation('lead')(connect(mapStateToProps,mapDispatchToProps)(SummaryForm));
