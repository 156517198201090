import MasterService from './MasterService';

const DealerService = {
    ...MasterService,

    getCarMake(postData){
        return this.post('/carMake/listCarMake', postData)
    },

    listDealers(postData){
        return this.post('/dealer/listDealers', postData);
    },

    submitFilter(postData) {
        return this.post('/inventory/listInventoryStock', postData);
    },
    
    getDealerDeliveryReport(postData){
        return this.post('/report/dealerDeliveryReport', postData);
    },
    updateDealerBoost(postData) {
        return this.post('/dealer/updateDealerBoost', postData);
    },
    saveEmailAndFilters(postData) {
        return this.post('/report/saveEmailAndFilters', postData);
    },
    importDealerDeliveryCsv(postdata){
        return this.post('/report/importDealerDeliveryCsv', postdata);
    }


}
export default DealerService;