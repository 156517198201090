import MasterService from './MasterService';

const ClusterService = {
    ...MasterService,
    
    getClusterList(postdata){
        return this.post('/cluster/listCluster',postdata) 
    },
    updateClusterDetails(postdata){
        return this.post('/cluster/editCluster',postdata) 
    },
    saveClusterDetails(postdata){
        return this.post('/cluster/saveCluster',postdata) 
    },
    updateStatus(postdata){
        return this.post('/cluster/updateStatus',postdata) 
    },
    getAllUserList(){
        return this.post('/user/listAllUser') 
    },
    getCityListForCluser(postdata){
        return this.post('/city/listCityForCluster',postdata) 
    },
    getAllTelecallerUser(postdata){
        return this.post('/user/getAllUsersByRoleId', postdata) 
    }

}

export default ClusterService;
