import React, { Component } from 'react';
import SummaryForm from './SummaryForm';
import CheckBox from '../../elements/CheckBox';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { LeadActions } from "../../../store/action";
import { toast } from 'react-toastify';
import DateFormat from 'dateformat';
import { default as config } from '../../../config/config';
import Select from 'react-select';
import moment from 'moment';
import * as HELPER from '../../../config/helper';
import LeadService from '../../../service/leadService'
import WalkinDetails from './walkinPopup';
import ModalPopup from './../../elements/Popup';
import LeadFeedback from './../../popup/LeadFeedback';


class AssignedCarTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pricePopOver:{
                'show':false,
                'id':0
            },
            isSaveMainLeadComment:false,
            formData:{},
            leadsCarList:[],
            makeList:[],
            modelList:[],
            versionList:[],
            mmvList:{},
            dealerList:[],
            statusList:[],
            subStatusList:[],
            leadDetails:{},
            showWalkinFlag:false,
            walkingInfo : [],
            searchedCarList : [],
            loading:props.loading,
            reopenLead:{},
            citys : {},
            showLeadFeedbackPopup: false,
            refreshAssignedCarsData: false,
            leadsCarDetails:[],
            selectedLeadForPopup:[],
            smsForEvent: [],
            showManualSMSPopup: false,
            sendManualSMSSelected: '',
            leadDetailsForSMS: {}
        };
        this.pricepopShow = this.pricepopShow.bind(this);
      
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};
        let {mmvList,leadsCarDetails,dealerList,statusList,subStatusList,loading,reopenLead,citys} = prevState;
        const {makeModelVersionList,leadsCarList,dealerListData,statusListData,subStatusListData,leadDetails,cityStateList} = nextProps;
        
        if(makeModelVersionList !== mmvList){
            updatedStateData['makeList'] = makeModelVersionList;
        }
        if(!leadsCarDetails && leadsCarList && leadsCarList.length){
            updatedStateData['leadsCarList'] = leadsCarList || [];
        }
        else if(leadsCarDetails && leadsCarList && leadsCarDetails.length !== leadsCarList.length){
            updatedStateData['leadsCarList'] = leadsCarList || [];
        }
        if(dealerListData && dealerListData['data'] && dealerList !== dealerListData['data']){
            updatedStateData['dealerList'] = dealerListData['data'] || [];
        }
        if(statusList !== statusListData){
            if(statusListData && statusListData.length){
                updatedStateData['statusList'] = HELPER.getOptionValueToDisplay(statusListData,'status') || [];
            }
        }
        if(subStatusList !== subStatusListData){
            if(subStatusListData && subStatusListData.length){
                updatedStateData['subStatusList'] = HELPER.getOptionValueToDisplay(subStatusListData,'sub_status') || [];
            }
        }
        // if(leadDetail !== leadDetails){
            updatedStateData['leadDetails'] = leadDetails || [];
        // }
        if(loading !== nextProps.loading){
            updatedStateData['loading'] = nextProps.loading;
        }
        if(reopenLead !== nextProps.reopenLead){
            updatedStateData['reopenLead'] = nextProps.reopenLead;
        }
        if(reopenLead !== nextProps.reopenLead){
            updatedStateData['reopenLead'] = nextProps.reopenLead;
        }
        if(citys !== cityStateList){
            let {city} = cityStateList?cityStateList : {}
            updatedStateData['citys'] = city
        }
        if(nextProps.isSaveMainLeadComment !== prevState.isSaveMainLeadComment) {
            updatedStateData['isSaveMainLeadComment'] = nextProps.isSaveMainLeadComment;
        }

        return updatedStateData;

    }
    
    addemail(e){
        this.setState({openemail: !this.state.openemail})
    }

    pricepopShow(id){
        const {pricePopOver} = this.state;
        if(pricePopOver.id === id)
            pricePopOver.show = !pricePopOver.show;
        else{
            pricePopOver.show = !pricePopOver.show; 
            pricePopOver.id = id;
        }
        this.setState({pricePopOver})
    }

    getDealerName(leadData) {
        const {dealerList} = this.state;
        let dealerName='';

        if(leadData.user_type === 'D' || leadData.dealer_id) {
            let dealer = _.find((dealerList),{'id':leadData.dealer_id});
            
            if(dealer){
                
                dealerName = dealer.organization
            } 
        }else if(leadData.carDekhoIndividual === '1' || leadData.user_type === 'P') {
            //// used car list inividual name
        }
        return dealerName;
    }

    getUserType = (leadData) => {
        let userType='';
        if(Number(leadData.carDekhoIndividual) === 1 || (leadData.carDetails && leadData.carDetails.user_type === 'P')) {
            userType = 'Individual'; 
        }else if((leadData.carDetails && leadData.carDetails.user_type === 'D')) {
            userType =  'Dealer'; 
        }
        return userType;
    }
    getCity = (leadData)=> { 
        let cityName = "";
        if(leadData.dealer_id){
            /// get dealer location
        }
        // map leadData with city for city name
        return cityName;
    }
    saveOutstationLead = async (data, index) => {
        let {leadsCarList} = this.state;
        let leads = leadsCarList[index];
        let is_outstation = (Number(leads['is_outstation']) === 1) ? '0' : '1'
        leads['is_outstation'] = is_outstation;
        leadsCarList[index] = leads;
        this.setState({leadsCarList})
        let postData = {
            'lead_id' : leads['lead_id'],
            'sub_lead_id' : leads['id'],
            'is_outstation' : is_outstation
        }
        this.props.actions.saveSubLeadOutstation(postData)
    }

    handleChange = async(selectedKey,index,selectedValue) => {
        let {leadsCarList} = this.state;
        let leads = leadsCarList[index];
        let sendManualSmsOn = '';
        
        if(selectedKey === 'status'){
            leads.sub_status_id_copy = leads.sub_status_id_copy || leads.sub_status_id;
            leads.status_id_copy = leads.status_id_copy || leads.status_id;
            leads.status_id = selectedValue ? selectedValue.value : '';
            leads.sub_status_id = "";
            let substatusList = await this.getSubStatusList({pagination: false});
            if(substatusList.length){
                substatusList = substatusList.filter(el=> el.status_id === leads.status_id);
                leads.subStatusList = HELPER.getOptionValueToDisplay(substatusList,'sub_status');
            }
            else
                leads.subStatusList = [];
        }else if(selectedKey === 'sub_status'){
            leads.sub_status_id_copy = leads.sub_status_id_copy || leads.sub_status_id;
            leads.sub_status_id = selectedValue ? selectedValue.value : '';
        }
        //manualSMS       
        leadsCarList[index] = leads;
        this.setState({ leadsCarList: leadsCarList, subStatusList: leads.subStatusList });

        if (selectedKey === 'manualSMS') {
            sendManualSmsOn = selectedValue.value;
            this.setState({ sendManualSMSSelected: sendManualSmsOn });
        }
        
        //check for feedback popup
        if(['status', 'sub_status'].includes(selectedKey)){
            document.getElementById("saveIcon_"+leads.id).classList.add("new-assigned-car");
            this.openFeedbackPopup(leadsCarList[index], index);
        }
    }

    //OPEN FEEDBACK POPUP
    openFeedbackPopup = async (selectedLeadCar, index) =>{
        let leadCars = this.state.leadsCarList;

        if((Number(selectedLeadCar.status_id) === 6 && (Number(selectedLeadCar.sub_status_id) === 12 || Number(selectedLeadCar.sub_status_id) === 26))
            ||
            (Number(selectedLeadCar.status_id) === 5 && Number(selectedLeadCar.sub_status_id) === 38)
        ) {
            leadCars[index]['isWalkingDone'] = 1;

            await this.setState({showLeadFeedbackPopup: true, selectedLeadForPopup: selectedLeadCar,leadsCarList: leadCars}); 
        }else if((Number(selectedLeadCar.status_id) === 6 || Number(selectedLeadCar.status_id) === 7) && selectedLeadCar.sub_status_id && selectedLeadCar.isWalkingDone) {
            await this.setState({showLeadFeedbackPopup: true, selectedLeadForPopup: selectedLeadCar}); 
        }
    }

    handleDateChange = (index,date) => {
        let {leadsCarList} = this.state;
        let leads = leadsCarList[index];
        if(leads){
            leads.walkin_datetime = date;
        }else{
            leads['walkingDetails']={
                'walkin_datetime' : date
            };
        }
        leadsCarList[index] = leads;
        this.setState({ leadsCarList:leadsCarList });

    }

    getSubStatusList = async(params) => {
        
        if(this.props.subStatusListData && this.props.subStatusListData.length){
            return this.props.subStatusListData;
        }else{
            let subStatusList = await this.props.actions.getSubStatusList(params);
            return subStatusList;            
        }
    }

    getAddedByUser = (leadData) => {
        let addedByName = '';
        if(Number(leadData.added_by) === 0){
            addedByName = 'Customer'
        }else if(leadData.added_by === '-2') { 
            addedByName = leadData.leadcarUserType;
        }else{
            addedByName = leadData.name; 
        }
        return addedByName;
    }

    getWalkingDate = (lcData) => { 
        let walkinDate = '';
        if(lcData && lcData.walkin_datetime){
            walkinDate = lcData.walkin_datetime;
        }else if(lcData && lcData.walkingDetails && lcData.walkingDetails.walkin_datetime){
            walkinDate = lcData.walkingDetails.walkin_datetime;
        }

        return walkinDate;
    }
    saveWalkinPurchaseDetails = async (postData) => {
        let repsonse = await LeadService.saveWalkingPurchaseDetails(postData);
        if(repsonse && repsonse.status === 200)
        {
            let {data:{message}} = repsonse;
            
            toast.success(message);
            this.setState({showWalkinFlag:false})
        }
    }
    isSentToDealer = (leadData, index) => { 
        let isSentToDealer = false;

        // if((Number(leadData['is_dealer_sent']) === 1 || (Number(leadData['is_dealer_sent']) === 0 && Number(leadData.carDetails['car_status']) === 1)) && (!(Number(leadData['carDekhoIndividual']) === 1 || leadData['user_type'] === 'P'))) {
        //     isSentToDealer = true; 
        // }
        // if((Number(leadData['is_dealer_sent']) === 1 && Number(leadData['is_dealer_sent_checked']) !== 1) || ( Number(leadData['is_dealer_sent']) === 0 && Number(leadData['is_dealer_sent_checked']) === 1)){
        if( Number(leadData['is_dealer_sent_checked']) === 1){
            isSentToDealer = true; 
        }
        return isSentToDealer;
    }

    isFieldDisabled = (lcData,field) => {
        let isDisabled = false;
        if(field === 'walkin_done'){
            if(!(lcData['status_id'] === 6 || lcData['status_id'] === 7) || (Number(lcData['carDekhoIndividual']) === 1 || lcData.user_type === 'P')){
                isDisabled = true;
            }else if((lcData.walkingDetails && Number(lcData.walkingDetails.walkin_status) === 4) || (lcData.walkin_status && (+lcData.walkin_status === 4))){
                isDisabled = true;
            }
            
        }
        else if(field === 'send_to_dealer'){
            // if(lcData['is_new_assigned'] !== 1 && (Number(lcData['is_dealer_sent']) === 1) && !lcData['updating_car']){
            //     isDisabled = true;
            // }
            if(lcData['is_new_assigned'] !== 1 && (Number(lcData['is_dealer_sent_checked']) === 1)  && !lcData['updating_car']){
                isDisabled = true;
            }else if (Number(lcData['car_status']) !== 1 || (Number(lcData['status_id']) === 7)){
                isDisabled = true;
            }else if(lcData['is_new_assigned'] !== 1 && !(Number(lcData['is_dealer_sent_checked'])) && (Number(lcData['is_dealer_sent']))){
                isDisabled = true;
            }
        }
        // else if(field === 'send_to_dealer' && lcData['is_new_assigned'] === 0){
        //     if((Number(lcData['carDekhoIndividual']) === 1 || lcData.user_type === 'P') || (Number(lcData['is_dealer_sent']) === 1) || (Number(lcData.carDetails['car_status']) !== 1) || (Number(lcData['added_by']) === 0 && Number(lcData['is_dealer_sent']) !== 2)) {
        //         isDisabled = true;
        //     }
        // }
        return isDisabled;
    }

    isWalkingDone = (lcData) => {
        let isWalkingCompleted = false;
        if((lcData.walkingDetails && Number(lcData.walkingDetails.walkin_status) === 4) || (lcData.walkin_status && (+lcData.walkin_status === 4))){
            isWalkingCompleted = true;
        }
        return isWalkingCompleted;
    }

    showWalkinPopup = (index) => {
        let {leadsCarList,showWalkinFlag,walkingInfo} = this.state;
        let lcData = leadsCarList[index];
        if(lcData.hasOwnProperty('isWalkingDone')){
            lcData.isWalkingDone  = !lcData.isWalkingDone;
        }else{
            lcData['isWalkingDone'] = !this.isWalkingDone(lcData)
        }
        
        if(this.isWalkingDone(lcData) && lcData.isWalkingDone && !(Number(lcData.status_id) === 6 || Number(lcData.status_id) === 7)){
            showWalkinFlag = !showWalkinFlag;
            walkingInfo = lcData;
        }
        
        leadsCarList[index] = lcData;
        if(lcData.isWalkingDone){
            this.openFeedbackPopup(lcData, index);
        }
        this.setState({leadsCarList,showWalkinFlag : showWalkinFlag,walkingInfo:walkingInfo})
    }

    flagCloseModal = () => {
        this.setState({ showWalkinFlag: false });
    }
    flagshowModal = () => {
        this.setState({ showWalkinFlag: true });
    }
    closeManualSMSModal = () => {
        this.setState({showManualSMSPopupModal: false });
        //this.props.resetDialerResponse();
    }
    manualSMSsendPopUp = (leadDetail) => {
        this.setState({ showManualSMSPopupModal: true });
        this.setState({leadDetailsForSMS: leadDetail});
    }
    sendManualSMSSubLead = () => {        
        let leadDetails =  this.state.leadDetailsForSMS;
        let { sendManualSMSSelected } = this.state;
        let dataForSMS = {};
        dataForSMS.lead_id =  leadDetails.lead_id;
        dataForSMS.car_id = leadDetails.car_id;
        dataForSMS.dealer_id = leadDetails.dealer_id
        dataForSMS.smsFireFor = sendManualSMSSelected;
        dataForSMS.sub_lead_id = leadDetails.id;        
        //console.log('dataForSMS-----------------------', dataForSMS);
        var i = 0;
        var errStr = "";
        if (!sendManualSMSSelected) {
            errStr += "* " + this.props.t('assignedCar.manual_sms') + "\n";
            i++;
        }
        if (i > 0) {
            toast.error(errStr);
            return false;
        }
        
        LeadService.sendManualSMSForEvent(dataForSMS)
            .then(async (result) => {
                if (result.status === 200 ) {
                    toast.success(this.props.t('assignedCar.smsSuccessMsg'));
                     this.setState({showManualSMSPopupModal: false });
                }
            })
            .catch((error) => {
            });
    }
    saveSubLead = (leadDetails, index) => {
        let {reopenLead} = this.state;
        var subleadId = leadDetails.id;
        var mainleadId = leadDetails.lead_id;
        var carId = leadDetails.carDetails.id || leadDetails.car_id;  
        var walkin_status      = leadDetails.status_id;
        var walkin_sub_status  = leadDetails.sub_status_id;
        var walkin_call_status = '0';
        var dd                 = '';
        if(leadDetails && leadDetails.walkin_datetime) {
            dd = leadDetails.walkin_datetime;
        }else if(leadDetails && leadDetails.walkingDetails && leadDetails.walkingDetails.walkin_datetime){
            dd = leadDetails.walkingDetails.walkin_datetime;
        }

        var subleadDealerId = leadDetails.dealer_id;
        var dealerOrg = '';
        var walkinform_call_later_date = '';
        var purchaseTime = '';
        var isChecked = false;    
        var reopenFlag = (reopenLead) ? reopenLead.reopen_lead : 0;
        let carDetails = leadDetails.carDetails;
        let is_dealer_sent = leadDetails['is_dealer_sent_checked'];
        let isWalkingCompleted = (leadDetails.hasOwnProperty('isWalkingDone')) ? leadDetails.isWalkingDone : this.isWalkingDone(leadDetails)
        if ( Number(walkin_status) === 7 || (Number(walkin_status) === 6 && Number(walkin_sub_status) === 10) ) {
            isChecked = isWalkingCompleted
        } else if (Number(walkin_status) === 6 && Number(walkin_sub_status) === 12 ) {
            isChecked = isWalkingCompleted      
        } else if (Number(walkin_status) === 6 && (Number(walkin_sub_status) === 11 || Number(walkin_sub_status) === 26)) {
            isChecked = isWalkingCompleted      
        } else if ( Number(walkin_status) === 5 && Number(walkin_sub_status) === 38 ) {
            isChecked = isWalkingCompleted
        }  
        var i = 0;
        var errStr = "";
        if(walkin_status === ""){
            errStr += "* "+ this.props.t('assignedCar.search_validation.please_select_status')+"\n";
            i++;
        }
        if(walkin_sub_status === ""){
            errStr += "* "+ this.props.t('assignedCar.search_validation.please_select_sub_status')+"\n";
            i++;
        }
        if(Number(walkin_status) === 5){
            if(dd === ""){
                errStr += "* "+ this.props.t('assignedCar.search_validation.please_select_walkin_date')+"\n";
                i++; 
            }
        }
        var wiComp = '0';
        if (isChecked) {
            if ( dd === '' || dd ===null ) {
                errStr += "* "+ this.props.t('assignedCar.search_validation.select_walkin_completed_date')+"\n";
                i++;
            } else { 
                var today = new Date();
                var arrDateTime = [];
                try{
                    arrDateTime = dd.split(" ");
                }catch(err){
                    dd = new Date(dd).toLocaleDateString();            
                    dd = dd.split("/");
                    arrDateTime[0] = dd[2]+'-'+dd[1]+'-'+dd[0];
                    dd =  arrDateTime[0];
                }
                var arrDate = arrDateTime[0].split("-");
                // var arrDate = dd.split("/");
               
                var A = new Date(arrDate[0], arrDate[1] - 1, arrDate[2],'00','00');            
                if (today < A) {
                    errStr += "* "+ this.props.t('assignedCar.search_validation.walkin_completed_date_should_not_be_future_date')+"\n";
                    i++;    
                }            
            }
            wiComp = '1';        
        }
        if(i>0){
            toast.error(errStr);
            
            return false;
        }
        let isConfirm = 0;
        let leadCarComments = leadDetails['comments'];

        //let confirmMsg = "A walk-in has been marked as completed for this dealer. Do you want to de-activate the walk-in?";
         if(reopenFlag === 1 && (isWalkingCompleted)) {        
        //     $('.saveSubLead_'+subleadId).removeClass("disabledLink");
        //     var isConfirmWalkinComp = $.Zebra_Dialog(confirmMsg, {
        //         'type':     'question',
        //         'title':    'Confirmation',
        //         'buttons':  [
        //                 {caption: 'Yes', callback: function() {
        //                         isConfirm = 1;
        //                         $('#walkin-incompleted_'+subleadId).prop('checked', false);
        //                         $('#datetimepicker-'+subleadId).val('');
        //                         saveSubLeadDetail(walkin_status, walkin_sub_status, walkin_call_status, walkinform_call_later_date, dd, purchaseTime, wiComp, mainleadId, subleadId, reopenFlag, isConfirm, subleadDealerId, dealerOrg, carId);         
        //                         isConfirmWalkinComp.close();
        //                     }
        //                 },
        //                 {caption: 'No', callback: function() { 
        //                         isConfirm = 0;
        //                         saveSubLeadDetail(walkin_status, walkin_sub_status, walkin_call_status, walkinform_call_later_date, dd, purchaseTime, wiComp, mainleadId, subleadId, reopenFlag, isConfirm, subleadDealerId, dealerOrg, carId);         
        //                         isConfirmWalkinComp.close();
        //                     }
        //                 }, 
        //             ]
        //     });
        } else {
            this.saveSubLeadDetail(walkin_status, walkin_sub_status, walkin_call_status, walkinform_call_later_date, dd, purchaseTime, wiComp, mainleadId, subleadId, reopenFlag, isConfirm, subleadDealerId, dealerOrg, carId,carDetails, index,is_dealer_sent, leadCarComments);         
        }
    }

    saveSubLeadDetail = async (walkin_status, walkin_sub_status, walkin_call_status, walkinform_call_later_date, dd, purchaseTime, wiComp, mainleadId, subleadId, reopenFlag, isConfirm, subleadDealerId, dealerOrg, carId,carDetails, index, is_dealer_sent, leadCarComments) => {
        const {formData} = this.state;
        //SHOW SPINNER
        let leadListCars = this.state.leadsCarList;
            leadListCars[index].isSavingSubLead = true;
            // leadListCars[index].status_id = leadListCars[index].status_id_copy = walkin_status;
            // leadListCars[index].sub_status_id = leadListCars[index].sub_status_id_copy = walkin_sub_status;
        await this.setState({leadsCarList:leadListCars});

        let subLeadData = {
            'walkin_status' : walkin_status,
            'walkin_sub_status' : walkin_sub_status,
            'walkin_call_status' : walkin_call_status,
            'walkinform_call_later_date' : walkinform_call_later_date,
            'walkinform_walkin_date_time' : dd,
            'purchaseTime' : purchaseTime,
            'walkinCompleted' : wiComp,
            'main_lead_id' : mainleadId,
            'sub_lead_id' : subleadId,
            'reopenFlag' : reopenFlag,
            'isConfirm' : isConfirm,
            'subleadDealerId' : subleadDealerId,
            'carId':carId,
            'carDetails':carDetails,
            'is_dealer_sent':is_dealer_sent,
            'comments': leadCarComments
        }

        LeadService.saveSubLeadDetails(subLeadData)
        .then(async (result) => {
            //HIDE SPINNER
            leadListCars[index].isSavingSubLead = false;
            await this.setState({leadsCarList:leadListCars});

            if (result.status === 200 ) {
                
                let response = _.cloneDeep(result.data.data);
                
                let walkinPurchaseInfoMsg = '',purchaseInfoMsg='';
                if(typeof response === 'number' && response === 3) { 
                    walkinPurchaseInfoMsg = "* "+ this.props.t('assignedCar.search_validation.please_fill_walkin_purchase_info_in_popup');
                    toast.error(walkinPurchaseInfoMsg);
                    return false;
                }else if(typeof response === 'number' && response === 4) { 
                    purchaseInfoMsg = "* "+ this.props.t('assignedCar.search_validation.purchase_date_should_not_be_less_than_walkin_completed_date');
                    toast.error(purchaseInfoMsg);
                    return false;
                }else if(typeof response === 'number' && response === 5) { 
                    purchaseInfoMsg = "* "+ this.props.t('assignedCar.search_validation.car_already_marked_as_converted');
                    toast.error(purchaseInfoMsg);
                    return false;
                }else if(typeof response === 'number' && response === 2) { 
                    var purchaseConvertedMsg = "* "+ this.props.t('assignedCar.search_validation.this_lead_is_already_marked_for_purchase')+" \n";
                    if(reopenFlag === '1') {
                        purchaseConvertedMsg = "* "+ this.props.t('assignedCar.search_validation.this_lead_is_already_marked_for_purchase')+" "+ this.props.t('assignedCar.search_validation.please_change_status_of_already_purchased_sub_lead')+" \n";
                    }
                    toast.error(purchaseConvertedMsg);
                    return false;
                }else if(typeof response === 'number' && response === 34) { 
                    toast.error(this.props.t('assignedCar.search_validation.please_select_valid_sub_status'));
                    return false;
                } else {
                    //UPDATE DEALER CHECKBOX
                    leadListCars[index].is_dealer_sent = is_dealer_sent;
                    leadListCars[index].is_new_assigned = 0;
                    leadListCars[index].updating_car = 0;

                    // document.getElementById("sendtodealer"+leadListCars[index].id).disabled = true;

                    // leadListCars[index]['is_new_assigned'] = 0; //CHANGE SAVE ICON COLOR
                    document.getElementById("saveIcon_"+leadListCars[index].id).classList.remove("new-assigned-car");

                    await this.setState({isSaveMainLeadComment:true, leadsCarList:leadListCars});
                    
                    var responseData = response;
                    let statusId = Number(responseData['0'].status_id);
                    let subStatusId = Number(responseData['0'].sub_status_id);

                    var leadStatus = responseData['0'].status_name+' | '+responseData['0'].sub_status_name;
                    var mainLeadComVal = '';
                    if (mainLeadComVal !== '' && responseData['0'].mainLeadComment !== '') {
                        formData['leadCommentText'] = mainLeadComVal + ' | ' + responseData['0'].mainLeadComment;
                    } 
                    else if (responseData['0'].mainLeadComment !== '') {
                        formData['leadCommentText'] = responseData['0'].mainLeadComment; 
                    } 
                    formData['leadCommentText'] =  leadStatus; 
                    if(Number(statusId) === 6 && Number(wiComp) === 1) {
                        formData['leadCommentText'] = '1';
                    } else {
                        formData['leadCommentText'] = '0';
                    }                
                    if(Number(walkin_status) === 7) {
                        // if($('#isFeaturedFlag_'+subleadId).val() == '1') {
                        //     $('#isFeaturedFlag_'+subleadId).val('2');
                        // }
                        // if(isFeaturedSubLead == '1') { 
                        //     //recomendedTabDisp();
                        // }
                    }
                    if(Number(walkin_status) === 7 && Number(walkin_sub_status) !== 27) {
                       // $('#actionContainer'+subleadId).html('');
                    }
                    if(Number(statusId) === 6 && Number(subStatusId) === 12) {
                      //  $('.actionContainerClass').html('');                    
                    }
                    if(Number(statusId) === 1 || Number(statusId) === 2 || (Number(statusId) === 3 && Number(subStatusId) === 4)) {
                    } else if(Number(statusId) === 7 && Number(subStatusId) !== 27) {
                       // $('#actionContainer'+subleadId).html('');
                    } else {                    
                    }
                    
                    toast.success('Data saved successfully');
                    
                }
            
            } else if (result.status === 401 && result.data.status === 401) {
                this.setState({ redirect: true })
            }else{
                toast.error(result.data.message);
            }
        })
        .catch((error) => {
        });
        
    }

    saveMainLead = (postData) =>{
        

    }

    getSelectedValue = (id,key) => {
        const {statusList,subStatusList} = this.state;
        let selectedVal='';
        if(key === 'status' && statusList.length){
            let obj = _.find(statusList,{'value':id})
            selectedVal = (obj) ? obj.label : '';
        }
        if(key === 'sub_status' && subStatusList.length){
            let obj = _.find(subStatusList,{'value':id})
            selectedVal = (obj) ? obj.label : '';
        }
        return selectedVal;
    }
    handleSearch = (targetObj) => {
        let {target:{value}} = targetObj
        let {leadsCarList} = this.state;
        let searchedCarList = [...leadsCarList]
        //// can search by car name and seller name///
        
        searchedCarList = searchedCarList.filter(e=> ((`${e.make_name} ${e.model_name} ${e.variant_name} ${this.getDealerName(e)}`.toLowerCase()).includes(value.toLowerCase())) )
        this.setState({searchedCarList:searchedCarList})
    }

    //CLOSE FEEDBACK MODAL
    closeLeadFeedbackModal = () => {
        this.setState({ showLeadFeedbackPopup: false });
    }

    changeDealerCheck = (lcData, index) => {
        let leadsCarsList = this.state.leadsCarList;

        if(typeof lcData.is_dealer_sent_checked === 'undefined' || lcData.is_dealer_sent_checked){
            leadsCarsList[index]['is_dealer_sent_checked'] = 0;
            this.setState({leadsCarsList});
        }else {
            leadsCarsList[index]['is_dealer_sent_checked'] = 1;
            this.setState({leadsCarsList});
        }

        leadsCarsList[index]['updating_car'] = 1;
    }

    updatedLeadCarFromFeedbackPopup = (data) => {
        
        let leadsCarList = this.state.leadsCarList;
        leadsCarList = leadsCarList.map(el => el.id === data.id ? data : el )
        
        this.setState({leadsCarList})
    }

    mainSummaryStatus = (data) => {
        this.props.mainSummaryStatus(data);
    }

    handleComments = (index, data) => async e => {
        let comment = e.target.value;
        let leadsCarsList = this.state.leadsCarList;

        leadsCarsList[index]['comments'] = comment;
        this.setState({leadsCarsList});
    }

    render() {
        const {leadsCarList,statusList, subStatusList,searchedCarList} = this.state;
        const subStatusFilter = subStatusList.filter(st=>(st.value!==34 && st.value!==35 && st.value!==16 && st.value!==15 && st.value!==17));
        let displayList = (searchedCarList instanceof Array && searchedCarList.length === 0) ? leadsCarList: searchedCarList
        
        return (
            <div className="card">
                        <div className="searchcars">
                            <div className="clearfix pad-tb15">
                                {/* <div className="col-sm-4 col-md-3 pad-t7"> {this.props.t('assignedCar.showIndividualCars')}</div> */}
                                <div className="col-sm-6 col-md-4">
                                    <input type="search" className="form-control" onChange={this.handleSearch} placeholder={this.props.t('assignedCar.search_by_car_or_seller_name')}></input>
                                </div>
                            </div>
                            <div className="table-responsive" id="assignedCars-table">
                                <table className="table table-bordered table-hover table-striped assignedcar-table">
                                    <thead>
                                        <tr>
                                            <th width="20%">{this.props.t('assignedCar.carDetails')}</th>
                                            <th width="12%">{this.props.t('assignedCar.seller')}</th>
                                            <th width="18%">{this.props.t('assignedCar.other_info')}</th>
                                            <th width="15%">{this.props.t('assignedCar.comments')}</th>
                                            <th width="15%">{this.props.t('assignedCar.statusSubstatus')}</th>
                                            <th width="30%">{this.props.t('assignedCar.walk-inDate')}</th>
                                            <th width="7%">{this.props.t('assignedCar.save')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                    {(displayList && displayList.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="8"><h6 className="text-center text-danger text-bold">{this.props.t('search.NoRecordFound')}</h6></td></tr> : null}
                                    {displayList && displayList.length > 0 && displayList.map((lcData, k) =>    {
                                    // console.log('lcData____________________',k, '--------------', lcData);
                                    //REMOVE PREV STATUS OF MATCHED STATUS
                                    let matchedStatus = 0;
                                    let assignedListStatus = statusList;
                                    assignedListStatus = statusList.filter(el=>{
                                        let currentCarStatus = lcData.status_id_copy || lcData.status_id;
                                        //FOR STATUS 5(walkin) SHOW STATUS 3(verified)
                                        if((el.value === 3 && currentCarStatus === 5) || (el.value === currentCarStatus) || matchedStatus){                                            
                                            matchedStatus = 1; 
                                            return el;
                                        }
                                        return false;
                                    });

                                    let zigwheelUrl = '';
                                    let carmudiUrl = '';
                                    let otoUrl = '';
                                    //assign web url                                    
                                    if((lcData.web_url && Array.isArray(lcData.web_url))){
                                        let carmudi = lcData.web_url.filter(cars => cars.key === 'carmudi');
                                        carmudiUrl = carmudi[0].url;

                                        let zigwheel = lcData.web_url.filter(cars => cars.key === 'zigwheels');
                                        zigwheelUrl = zigwheel[0].url;
                                        
                                    }else if(lcData.web_url && (Array.isArray(lcData.web_url) === false)){
                                        otoUrl = lcData.web_url;
                                    }
                                    
                                    //REMOVE PREV SUB STATUS OF MATCHED SUB STATUS
                                    let matchedSubStatus = 0;
                                    let assignedListSubStatus = [];
                                    subStatusFilter.forEach(el=>{
                                        if(+el.status_id === +lcData.status_id){
                                            if((!lcData.sub_status_id_copy && el.value === lcData.sub_status_id) || matchedSubStatus){
                                                matchedSubStatus++; 
                                                assignedListSubStatus.push(el);
                                            }else if((lcData.status_id_copy && lcData.status_id_copy === lcData.status_id && lcData.sub_status_id_copy === el.value) || matchedSubStatus){
                                                matchedSubStatus++; 
                                                assignedListSubStatus.push(el);
                                            }else if((lcData.status_id_copy && lcData.status_id_copy !== lcData.status_id )){
                                                assignedListSubStatus.push(el);
                                            }else{
                                                assignedListSubStatus.push(el);
                                            }
                                        }
                                    });
                                    
                                    let walkinDatePickerDisable = (Number(lcData.status_id) === 5) ? false : true; 
                                        return (<tr key={k}>
                                            <td>
                                            <strong>{lcData.make_year?lcData.make_year+' ' : ''}{lcData.make_name?lcData.make_name+' ':''}{lcData.model_name?lcData.model_name+' ':''} </strong> 
                                            {(lcData.carDetails && Number(lcData.car_status) !== 1) ? <span className="label red-status label-sm">Sold</span> :''} 
                                                <div >{lcData.car_price ? config.constants.currencySymbol + ' ' +  Number(lcData.car_price).toLocaleString("in-ID") : ''}
                                                    {(lcData.car_price) ?
                                                        <div className="price-popover" id={"newCarInfoPopup_" + lcData.id} ><span onClick={this.pricepopShow.bind(this, lcData.id)}><i className="ic-error"></i></span>
                                                            <div className={(this.state.pricePopOver.show && this.state.pricePopOver.id === lcData.id) ? "popover bs-popover-bottom " : "popover bs-popover-bottom hide"} >
                                                                <span className="arrow"></span>
                                                                <div className="popover-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-3 col-md-3 mrg-b15">
                                                                            <div className="tt-label">{this.props.t('assignedCar.this_car')}</div>
                                                                            <div className="tt-detail"><span id="truePrice">{(lcData && lcData.car_price) ? config.constants.currencySymbol + ' ' +  Number(lcData.car_price).toLocaleString("in-ID") : ''}</span></div>
                                                                        </div>
                                                                        
                                                                        <div className="col-sm-3 col-md-3 mrg-b15">
                                                                            <div className="tt-label">{this.props.t('assignedCar.about_car')}</div>
                                                                            <div className="tt-detail">{(lcData.carDetails && lcData.carDetails.car_description) ? lcData.carDetails.car_description : 'N/A'}<span id="truePriceDiff"></span></div>
                                                                        </div>

                                                                        <div className="col-sm-3 col-md-3 mrg-b15">
                                                                            
                                                                            <div className="tt-label">See Car</div>
                                                                            {(carmudiUrl)?<div className="tt-detail"><a href={carmudiUrl} target="_blank"> Carmudi</a> <span id="truePriceDiff"></span></div>:''
                                                                            }
                                                                            {(zigwheelUrl)?<div className="tt-detail"><a href={zigwheelUrl} target="_blank"> ZigWheels</a> <span id="truePriceDiff"></span></div>:''
                                                                            }  
                                                                            {(otoUrl)?<div className="tt-detail"><a href={otoUrl} target="_blank"> Car url</a> <span id="truePriceDiff"></span></div>:''
                                                                            }                              
                                                                            
                                                                        </div>

                                                                    </div>
                                                                    <hr />
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                            <ul className="requirements-list">
                                            {(lcData && lcData.color)? <li> {lcData.color}</li>:''}
                                            {(lcData && lcData.km_driven)? <li>{ Number(lcData.km_driven).toLocaleString("in-ID")+" KM"}</li> : ''}
                                            {(lcData && lcData.fuel_type) ?<li>{lcData.fuel_type}</li> : ''}
                                            {(lcData && lcData.transmission) ?<li>{lcData.transmission}</li> : ''}
                                            </ul>                                            
                                            </td>
                                            <td>
                                                <div>
                                                    {
                                                         
                                                        (lcData.user_type === 'D')
                                                        ?
                                                            <React.Fragment>
                                                                <span>{(lcData.organization ? `${lcData.organization}(Dealer GCD${lcData.dealer_id})` : '(Dealer)')}</span>
                                                            
                                                                <p>{((lcData.seller_address) ? lcData.seller_address : '')}</p>
                                                            </React.Fragment>
                                                            
                                                        :
                                                            (lcData.organization ? `${lcData.organization}(Individual)` : '(Individual)')
                                                            
                                                    }
                                                </div>
                                                <div>
                                                    <CheckBox type="checkbox" label="Outstation" name={"outstation_"+lcData.id} id={"outstation_"+lcData.id} checked={(lcData.is_outstation === "1") ? true : false} onChange={()=> this.saveOutstationLead(lcData,k)} />
                                                </div>
                                            </td>
                                            <td>
                                            <strong>{this.props.t('assignedCar.chosenBy')}:</strong> {this.getAddedByUser(lcData)} <br/>
                                            <strong>{this.props.t('assignedCar.sentOn')}:</strong> {DateFormat(lcData.created_at,"dd-mm-yyyy")} <br/>{DateFormat(lcData.created_at,"h:MM:ss TT")}<br/>
                                            {
                                                lcData.dealer_type
                                                ?
                                                    <React.Fragment>
                                                        <strong>{this.props.t('assignedCar.dealer-priority')}:</strong> {lcData['dealer_type']} <br/>
                                                    </React.Fragment>
                                                :
                                                    ''
                                            }
                                            <strong>{this.props.t('assignedCar.dealer-type')}:</strong> {(lcData.user_type === 'D') ? lcData.paid_score : 'Free' }
                                            </td>
                                            <td>
                                                <textarea className="form-control" onChange={this.handleComments(k, lcData)} placeholder='Comment here' value={lcData.comments||''}>{lcData.comments}</textarea>
                                            </td>
                                            
                                            <td>
                                                
                                                <Select
                                                    className="mrg-b5"
                                                    id="status"
                                                    name="status"
                                                    onChange={this.handleChange.bind(this,'status',k)}
                                                    options={assignedListStatus}
                                                    value={assignedListStatus.filter((el) => el.value === lcData.status_id)}
                                                    
                                                />

                                                <Select
                                                    className="mrg-b5"
                                                    id="sub_status"
                                                    name="sub_status"
                                                    onChange={this.handleChange.bind(this,'sub_status',k)}
                                                    options={assignedListSubStatus}
                                                    value={assignedListSubStatus.filter(({ value }) => value === lcData.sub_status_id)}
                                                />
                                            </td>
                                            <td>
                                                <div className="mrg-b5">
                                                <DatePicker
                                                    id={'follow_up_to_date_'+lcData.id}
                                                    className="form-control"
                                                    onChange={this.handleDateChange.bind(this,k)}
                                                    selected={this.getWalkingDate(lcData) ? moment(this.getWalkingDate(lcData)).toDate() : null}
                                                    placeholderText={this.props.t('assignedCar.walk-inDate')}
                                                    onKeyDown={e => e.preventDefault()}
                                                    disabled={walkinDatePickerDisable}
                                                    minTime={new Date()}
                                                    maxTime={new Date("23:00")}     
                                                    showTimeSelect={true}
                                                    dateFormat={config.constants.dateFormatDMYHIS}
                                                />
                                                </div>
                                                <div>
                                                    <CheckBox className="op-0" type="checkbox" label="Walk-in Done" name={"walkdone"+lcData.id} id={"walkdone"+lcData.id} checked={('isWalkingDone' in lcData) ? lcData.isWalkingDone : this.isWalkingDone(lcData)} 
                                                    disabled={this.isFieldDisabled(lcData,'walkin_done')}
                                                    onChange={this.showWalkinPopup.bind(this,k)}/>
                                                </div>
                                                <div>
                                                    {/* <CheckBox className="op-0" type="checkbox" label="Send to Dealer" name={"sendtodealer"+lcData.id} id={"sendtodealer"+lcData.id} checked={this.isSentToDealer(lcData)} 
                                                    disabled={lcData.is_dealer_sent === 1} onChange={()=>this.changeDealerCheck(lcData,k)}/> */}
                                                    <CheckBox className="op-0" type="checkbox" label="Send to Dealer" name={"sendtodealer"+lcData.id} id={"sendtodealer"+lcData.id} checked={this.isSentToDealer(lcData, k)} 
                                                    disabled={this.isFieldDisabled(lcData,'send_to_dealer')} onChange={()=>this.changeDealerCheck(lcData,k)}/>
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    lcData.isSavingSubLead
                                                    ?
                                                            <i className="fa fa-users loading-spinner"></i>
                                                    :
                                                            <span onClick={()=> this.saveSubLead(lcData, k)}><i id={"saveIcon_"+lcData.id} className={(lcData.is_new_assigned) ? "ic-save icons-normal new-assigned-car" : "ic-save icons-normal"}></i></span>

                                                }
                                                <br/>
                                                {<span onClick={()=> this.manualSMSsendPopUp(lcData, k)}><i id={"manualSMS_"+lcData.id} className={(lcData.is_new_assigned) ? "ic-email icons-normal new-assigned-car" : "ic-email icons-normal"} title="Send SMS"></i></span>}
                                                {
                                                !this.state.showManualSMSPopupModal ? null :  
                                                <ModalPopup id="manualSmsPopup" className="modal modal-bg" title="Send SMS"  modalClose={this.closeManualSMSModal}>
                                                    <div className="pd-15">
                                                        <form>
                                                            <div className="form-group clearfix">
                                                                <Select
                                                                    className="mrg-b5"
                                                                    id="manualSMS"
                                                                    name="manualSMS"
                                                                    onChange={this.handleChange.bind(this, 'manualSMS', k)}
                                                                    options={config.constants.manualSMSForEvent}
                                                                    value={config.constants.manualSMSForEvent.value}
                                                                />
                                                            </div>
                                                            <div className=" form-group text-right">
                                                                <button type="button" name="cancel" className="btn btn-default mrg-r15" onClick={this.closeManualSMSModal}>{this.props.t('recomendTab.list.cancel')}</button>
                                                                <button type="button" name="submit" className="btn btn-primary" onClick={()=> this.sendManualSMSSubLead(lcData, k)}>{this.props.t('recomendTab.list.submit')}</button>
                                                            </div>
                                                        </form>
                                                    </div>
                            
                                                </ModalPopup>
                                                }
                                            </td>
                                        </tr>);

                                        }
                                    )}
                                    {
                                        (this.state.loading) ? <tr><td className="loading" colSpan="9"></td></tr> : null
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <SummaryForm leadDetails={this.state.leadDetails} statusList={this.state.statusList} isSaveMainLeadComment={this.state.isSaveMainLeadComment} mmvLis={this.state.makeList} updateReopenStatus={this.props.updateReopenStatus} mainSummaryStatus={this.mainSummaryStatus}/>
                        {this.state.showWalkinFlag === false ? null : <ModalPopup id="walkinPopup" className="modal modal-lg" title="Walkin Details"  modalClose={this.flagCloseModal}>
                            <WalkinDetails 
                                walkingInfo={this.state.walkingInfo}
                                saveWalkinPurchaseDetails={this.saveWalkinPurchaseDetails}
                            />
                        </ModalPopup>
                        }

                        {
                          !this.state.showLeadFeedbackPopup ? null :  <ModalPopup id="leadFeedback" className="modal modal-lg" title=""  modalClose={this.closeLeadFeedbackModal}>
                                <LeadFeedback 
                                    leadDetails={this.state.leadDetails}
                                    modalClose={this.closeLeadFeedbackModal}
                                    selectedLeadForPopup={this.state.selectedLeadForPopup}
                                    updatedLeadCarFromFeedbackPopup={this.updatedLeadCarFromFeedbackPopup}
                                />
                            </ModalPopup>
                        }
                    </div>
                                              
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    leadsCarList:state.lead.leadsCarList,
    makeModelVersionList: state.lead.makeModelVersionList,
    dealerListData:state.dealer.dealerListData,
    statusListData:state.lead.statusListData,
    subStatusListData:state.lead.subStatusListData,
    leadDetails:state.lead.listLead,
    reopenLead:state.lead.leadReopenData,
    cityStateList:state.lead.cityStateList
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            getWalkingData:bindActionCreators(
                LeadActions.getWalkingData,
                dispatch
            ),
            getSubStatusList: bindActionCreators(
                LeadActions.getSubStatus,
                dispatch
            ),
            saveSubLeadOutstation:bindActionCreators(
                LeadActions.saveSubLeadOutstation,
                dispatch
            ),
            
    }   }
}

export default withTranslation('lead')(connect(mapStateToProps, mapDispatchToProps)(AssignedCarTable));
