import { AuthService } from '../../service';
import {AuthConstants} from '../constants/auth.constants';
import { toast } from 'react-toastify';
import secureStorage from '../../config/encrypt';

export const AuthActions = {
    login,
    logout,
    loginCaptcha,
    forgotPassword,
    getRefreshToken,
    resendOtp,
    getResetPasswordToken,
    validateResetToken,
    resetPassword,
    getSiteSettings,
    getUserList,
    getRolePrivilege
};

function login(email, password, captcha,hash) {

    return dispatch => {
      return new Promise((resolve, reject) => {
        AuthService.login(email, password, captcha,hash)
          .then(
            user => {
              if(user.data.status === 200){
                user.data.data['authUser'] = Object.assign({},user.data.data.user_data);
                delete user.data.data.user_data;
                secureStorage.setItem('authUserInfo', (user.data.data));
                toast.success(user.data.message);
                dispatch(success(user.data.data));
                return resolve(user.data)
              }
              else{
                dispatch(failure('Invalid credentials '));
                return resolve(user && user.data)
              }
            },
            error => {
              dispatch(failure(error));
              return reject(error);
            }
          );
      });
    };

    function success(user) { return { type: AuthConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: AuthConstants.LOGIN_FAILURE, error } }

}

function logout() {
    AuthService.authLogout();
    secureStorage.removeItem('authUserInfo');
    secureStorage.removeItem('privilegeInfo');

    return { type: AuthConstants.LOGOUT };
}

function loginCaptcha() {
    return dispatch => {

      AuthService.loginCaptcha()
        .then(
          captcha => {
            if(captcha.data.status === 200){
              dispatch(success(captcha.data.data));
            }else{
              dispatch(failure(captcha.data.message));
            }
          },
          error => {
            dispatch(failure(error));
          }
        );
    };
  
    function success(captcha) { return { type: AuthConstants.LOGIN_CAPTCHA, captcha } }
    function failure(error) { return { type: AuthConstants.LOGIN_CAPTCHA, error } }
}

function forgotPassword(postData) {
  return dispatch => {

    AuthService.forgotPassword(postData)
      .then(
        user => {
          if(user.data.status === 200){
            toast.success(user.data.message);
            dispatch(success(user.data.data));
          }else{
            dispatch(failure(user.data.message));
          }
          
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(user) { return { type: AuthConstants.FORGOT_PASSWORD, user } }
  function failure(error) { return { type: AuthConstants.FORGOT_PASSWORD_ERROR, error } }
}

function  getRefreshToken(postData) {
  return dispatch => {

    AuthService.getRefreshToken(postData)
      .then(
        token => {
          if(token && token.data.status === 200){
            token.data.data['authUser'] = Object.assign({},token.data.data.user_data);
            delete token.data.data.user_data;
            secureStorage.setItem('authUserInfo', (token.data.data));
            dispatch(success(token.data.data));
          }else{
            dispatch(failure('token_invalid'));
          }
          
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(user) { return { type: AuthConstants.REFRESH_TOKEN, user } }
  function failure(error) { return { type: AuthConstants.REFRESH_TOKEN_ERROR, error } }
}

function resendOtp(postData) {
  return dispatch => {

    AuthService.resendOtp(postData)
      .then(
        user => {
          if(user.data.status === 200){
            toast.success(user.data.message);
            dispatch(success(user.data.data));
          }else{
            dispatch(failure(user.data.message));
          }
          
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(user) { return { type: AuthConstants.RESEND_OTP, user } }
  function failure(error) { return { type: AuthConstants.RESEND_OTP_ERROR, error } }
}

function getResetPasswordToken(postData) {
  return dispatch => {

    AuthService.getResetPasswordToken(postData)
      .then(
        user => {
          if(user.data.status === 200){
            toast.success(user.data.message);
            dispatch(success(user.data.data));
          }else{
            if(user.data.data && user.data.data.otpMatchPerform){
              dispatch(failure(user.data.data));
            }else{
              dispatch(failure(user.data.message));

            }
          }
          
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(user) { return { type: AuthConstants.RESET_PASSWORD_TOKEN, user } }
  function failure(error) { return { type: AuthConstants.RESET_PASSWORD_TOKEN_ERROR, error } }
}

function validateResetToken(postData) {
  return dispatch => {

    AuthService.validateResetToken(postData)
      .then(
        user => {
          if(user.data.status === 200){
            
            dispatch(success(user.data.data));
          }else{
            dispatch(failure(user.data.message));
          }
          
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(user) { return { type: AuthConstants.RESET_PASSWORD_TOKEN, user } }
  function failure(error) { return { type: AuthConstants.RESET_PASSWORD_TOKEN_ERROR, error } }
}

function resetPassword(postData) {
  return dispatch => {

    AuthService.resetPassword(postData)
      .then(
        user => {
          if(user.data.status === 200){
            toast.success(user.data.message);
            dispatch(success(user.data.data));
          }else{
            dispatch(failure(user.data.message));
          }
          
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(user) { return { type: AuthConstants.RESET_PASSWORD, user } }
  function failure(error) { return { type: AuthConstants.RESET_PASSWORD_ERROR, error } }
}

function getSiteSettings() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthService.getSiteSettings()
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              dispatch(success(resp.data.data));
              resolve(resp.data);
            }else{
              dispatch(failure('data_not_found'));
              resolve(resp ? resp.data : [])
            }
          },
          error => {
            reject(error)
            dispatch(failure(error));
          }
        );
    });
  };

  function success(data) { return { type: AuthConstants.SITE_SETTINGS_SUCCESS, data } }
  function failure(error) { return { type: AuthConstants.SITE_SETTINGS_ERROR, error } }
}

function getUserList() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthService.getAllUser()
        .then(
          resp => {
            if(resp.data.status === 200){
              dispatch(success(resp.data.data));
              resolve(resp.data);
            }else{
              dispatch(failure('data_not_found'));
              resolve(resp.data)
            }
          },
          error => {
            reject(error)
            dispatch(failure(error));
          }
        );
    });
  };

  function success(data) { return { type: AuthConstants.USER_LIST_SUCCESS, data } }
  function failure(error) { return { type: AuthConstants.USER_LIST_ERROR, error } }
}

function getRolePrivilege(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AuthService.getRolePrivilege(params)
        .then(
          resp => {
            if(resp.data && resp.status === 200){
              secureStorage.setItem('privilegeInfo', (resp.data.data));
              dispatch(success(resp.data.data));
              resolve(resp.data);
            }
            else{
              dispatch(failure('data_not_found'));
              resolve((resp && resp['data']) || []);
            }
          },
          error => {
            dispatch(failure('data_not_found'));
            reject(error);
          }
        ).catch(error=>{
            reject(error);
        });
      });
  };

  function success(data) { return { type: AuthConstants.ROLE_PRIVILEGE_SUCCESS, data } }
  function failure(error) { return { type: AuthConstants.ROLE_PRIVILEGE_ERROR, error } }
}