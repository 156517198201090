import MasterService from './MasterService';


export const AuthService = {
    ...MasterService,
    
    login(username, password,captcha, hash){
        return this.post('/user/login',{username, password,captcha, hash})
    },
    loginCaptcha(){
        return this.get('/user/getCaptchaImage')
    },

    forgotPassword(postData) {
        return this.post('/user/forgotPassword', postData)
    },

    authLogout() {
        return this.post('/user/logout', {});
    },

    getRefreshToken(postData) {
        return this.post('/user/getRefreshToken', postData);
    },

    resendOtp(postData) {
        return this.post('/user/resendOtp', postData);
    },

    getResetPasswordToken(postData) {
        return this.post('/user/getResetPasswordToken', postData);
    },

    validateResetToken(postData) {
        return this.post('/user/validateResetToken', postData);
    },

    resetPassword(postData) {
        return this.post('/user/resetPassword', postData);
    },

    getSiteSettings(){
        return this.get('/user/getSiteSettings');
    },
    getAllUser(){
        return this.post('/user/listAllUser');
    },
    getRolePrivilege(postData){
        return this.post('/privilege/getRolePrivilege',postData);
    }
    

}