import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import queryString from 'query-string'
import { toast } from 'react-toastify';
import LanguageSelector from '../../common/LanguageSelector';

class WalkinReschedule extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        
        this.state = {
            details: {},
            postData:{},
            queryParams:{},
            loading:false
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
        let queryParams = queryString.parse(this.props.location.search);
        await this.setState({queryParams});

        //GET NOTIFICATION DETAILS
        this.getDetails();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    getDetails = async () => {
        
        let details = await this.props.actions.getDetails(this.state.queryParams);
        if(details.status === 200 && details.data && details.data.success){
            this.setState({details:details.data});
        }else{ //REDIRECT ON ERROR
            this.props.history.push('/error-page?msg=link_expired');
        }
    }


    /**
     * Change Handler
     */
    handleChange  = async e => {

        let val = '';
        let {postData} = this.state;
        if(e){
            val = new Date(e).toISOString();
        }
        postData['rescheduled_datetime'] = val;
       

        if(this._isMounted) await this.setState({postData});

    }

    onSubmit = async () => {
        let {details, postData, queryParams} = this.state;

        const notificationId = queryParams['notificationId'];
       
        postData['notification_id']      = notificationId;
        postData['lead_id']              = details['lead_id'];
        postData['car_id']               = details['car_id'];
        postData['leads_cars_id']        = details['leads_cars_id'];
        postData['reschedule_link_id']   = details['reschedule_link_id'] || '';

        if(!postData['rescheduled_datetime']){
            toast.error('Re-Schedule Date Time is required');
        }else{
            this.setState({loading:true});
            let udpateStatus = await this.props.actions.updateRescheduledDate(postData);
            if(udpateStatus && udpateStatus.data && udpateStatus.data.success){
                this.setState({loading:false});
                this.props.history.push('/thankyou-page?msg=walkin_rescheduled_successfully');
            }else if(udpateStatus && udpateStatus.data && !udpateStatus.data.success){
                this.setState({loading:false});
                toast.error(udpateStatus.message);
            }else{
                this.setState({loading:false});
            }
        }

    }

    onCancel = async() => {
        if(this.props && this.props.siteSettings && this.props.siteSettings.frontendBaseUrl){
            window.location.href = this.props.siteSettings.frontendBaseUrl
        }
    }

    
    render() { 
        const { details, postData, loading } = this.state;

        return ( 
            <div className="container-fluid rescheduleForm">
                <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                        <h1 className="card-title text-center">{this.props.t('walkin_reschedule.title')}</h1>
                        <div className="card">
                            <div className="card-body">
                                <ul className="rescheduleList">
                                    <li>
                                        <i className="ic-person"></i>
                                        {details.customer_name || ''}
                                    </li>
                                    <li>
                                        <i className="ic-directions_car"></i>
                                        <div className="carmainDetails">
                                            <div className="carName">{details.make_model || ''}</div>
                                            <div className="carPrice">{details.display_price || ''}</div>
                                            <ul className="carDetails">
                                                <li>{details.make_year || ''}</li>
                                                <li>{details.km_driven || ''}</li>
                                                <li>{details.fuel_type || ''}</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="ic-person"></i>
                                            {details.dealer || ''} {(details.dealer_type === 'D') ? '(Dealer)' : '(Individual)'}
                                    </li>
                                    <li>
                                        <i className="ic-access_timequery_builderschedule"></i>
                                        {/*20 October 2020, 15:00:00*/}
                                        {details.walkin_datetime ? moment(details.walkin_datetime).format('DD MMMM YYYY, HH:mm:ss') : ''}
                                    </li>
                                </ul>
                                <div className="card formBox">
                                    <div className="card-heading">
                                        <h2 className="card-title text-bold">{this.props.t('walkin_reschedule.title')}</h2>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <DatePicker
                                                id="rescheduleDate"
                                                className="form-control"
                                                showTimeSelect={true}
                                                onChange={this.handleChange}
                                                dateFormat={"dd-MM-yyyy HH:mm"}
                                                placeholderText={"Select walk-in date"}
                                                selected={postData['rescheduled_datetime'] ? new Date(postData['rescheduled_datetime']) : null}
                                                minDate={new Date()}
                                                />
                                        </div>
                                        <div className="form-group">
                                           <div className="row">
                                               <div className="col-xs-6 col-sm-6">
                                                    {
                                                        loading
                                                        ?
                                                            <button type="button" className="btn btn-default btn-block"  disabled>{this.props.t('walkin_reschedule.cancel')}</button>
                                                        :
                                                           <button type="button" className="btn btn-default btn-block"  onClick={this.onCancel}>{this.props.t('walkin_reschedule.cancel')}</button>
                                                    }
                                               </div>

                                               <div className="col-xs-6 col-sm-6">
                                                   {
                                                        loading
                                                        ?
                                                            <button type="button" className="btn btn-primary  btn-block" disabled>{this.props.t('walkin_reschedule.please_wait')}</button>
                                                        :
                                                            <button type="button" className="btn btn-primary  btn-block" onClick={this.onSubmit}>{this.props.t('walkin_reschedule.submit')}</button>

                                                    }
                                               </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <footer>
                                <LanguageSelector />
                            </footer>
                    </div>
                </div>
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        tokenExpired: state.auth.tokenExpired,
        error: state.auth.error,
        siteSettings: state.auth.siteSettings

    }
 }

const mapDispatchToProps = dispatch => {
    return {
               actions: {
                getDetails: bindActionCreators(
                    LeadActions.getNotificationDetail,
                    dispatch
                  ),
                updateRescheduledDate: bindActionCreators(
                    LeadActions.updateRescheduledDate,
                    dispatch
                )
               }
            }
}

export default withTranslation('common') (connect(mapStateToProps, mapDispatchToProps)(WalkinReschedule));
