import { LeadService, ClusterService, CityService } from '../../service';
import {LeadConstants} from '../constants/lead.constants';
import _ from 'lodash';
import { toast } from 'react-toastify';

export const LeadActions = {
    addLead,    
    getMakeModelVersion,
    listCity,
    listLead,
    getFilter,
    updateCustomer,
    submitRequirementForm,
    getReqAgentDetails,
    listDealer,
    getStatus,
    getTimelineDetails,
    getLeadsCarList,
    getCarDetails,
    getCustomer,
    addConversion,
    getBlockedClosedLeads,
    unblockLead,
    submitBulkUpload,
    getSubStatus,
    getWalkingData,
    getConversionInfoList,
    updateConversion,
    importExcelFile,
    getLeadFilterStatusList,
    // getSubStatusList,
    getLeadList,
    saveMainLead,
    saveSubLeadOutstation,
    saveCarRecomend,
    copyCarDetails,
    listFeedback,
    getClusterCities,
    getCityState,
    getUserNameByType,
    getRTOList,
    getNewCarPriceInfo,
    saveDealerFlag,
    getCustomerLocality,
    setReopenLead,
    getConversionStatus,
    getClusterList,
    saveClosedLeadInfo,
    getWalkinFeedbackList,
    getStockListByIds,
    saveWalkinPurchaseInfo,
    getCallStatusList,
    getAllUserList,
    getUsersByRoleId,
    getCallingStatus,
    getDialerLeads,
    importLeadFinderData,
    getNotificationDetail,
    updateRescheduledDate,
    leadFeedbackSubmit,
    feedbackFormValidity,
    purchaseFeedbackSubmit,
    downloadReport,
    submitBulkUploadFbLeads
  };


function addLead(postData) {

    return dispatch => {
      return new Promise((resolve, reject) => {
      LeadService.addLead(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              toast.success(resp.data.message);
              dispatch(success(resp.data.data));
              return resolve((resp && resp.data) || []);
            }
            else{
              dispatch(failure('not_found'));
              return resolve((resp && resp['data']) || []);
            }
          },
          error => {
            dispatch(failure(error));
            return reject(error);
          }
        );
      });
    };

    function success(resp) { return { type: LeadConstants.ADD_LEAD_SUCCESS, resp } }
    function failure(error) { return { type: LeadConstants.ADD_LEAD_FAILURE, error } }

}


function getMakeModelVersion(postData) {

    return dispatch => {
      return new Promise((resolve, reject) => {
        LeadService.getMakeModelVersionList(postData)
          .then(
            resp => {
              if(resp && resp.data && resp.data.status === 200){
                dispatch(success(resp.data.data));
                resolve(resp.data.data);
              }
              else{
                resolve((resp && resp.data) || []);
                dispatch(failure('not_found'));
              }
            },
            error => {
              reject(error);
              dispatch(failure(error));
            }
          );
        });
    };

  
    function success(resp) { return { type: LeadConstants.REQUEST_MAKE_MODEL_VERSION_SUCCESS, resp } }
    function failure(error) { return { type: LeadConstants.REQUEST_MAKE_MODEL_VERSION_FAILURE, error } }
  
  }


function listCity(postData) {

  return dispatch => {

    LeadService.getCityList(postData)
      .then(
        resp => {
          if(resp && resp.data && resp.data.status === 200){
            const cityArr = [];
            if (resp.data.data.length) {
                _.forEach((resp.data.data),element => {
                    let cityObj={}
                    cityObj.value = element.id;
                    cityObj.label = element.name;
                    cityArr.push(cityObj);
                });
            }
            dispatch(success(cityArr));
          }
          else{
            dispatch(failure('not_found'));
          }
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(resp) { return { type: LeadConstants.CITY_LIST_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.CITY_LIST_FAILURE, error } }

}

function listLead(params) {
  
    return dispatch => {
 
    return new Promise((resolve, reject) => {
        LeadService.getLeadList(params)
          .then(
            resp => {
               
              if(resp && resp.data && resp.data.status === 200){
                
                dispatch(success(resp.data.data));
                resolve((resp && resp.data) || []);
              }
              else{
                dispatch(failure('not_found'));
                resolve((resp && resp.data) || []);
              }
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
    });
  };

  function success(resp) { return { type: LeadConstants.LEAD_LIST_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.LEAD_LIST_FAILURE, error } }

}

function getFilter(params) {
  
  return dispatch => {

  return new Promise((resolve, reject) => {
      LeadService.getfilterData(params)
        .then(
          resp => {
             
            if(resp && resp.data && resp.data.status === 200){
              dispatch(success(resp.data.data));
              resolve((resp && resp.data) || []);
            }
            else{
              dispatch(failure('not_found'));
              resolve((resp && resp.data) || []);
            }
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
  });
};

function success(resp) { return { type: LeadConstants.FILTER_LIST_SUCCESS, resp } }
function failure(error) { return { type: LeadConstants.FILTER_LIST_FAILURE, error } }

}

function updateCustomer(postData) {

  return dispatch => {

    LeadService.updateCustomer(postData)
      .then(
        resp => {
          if(resp && resp.data && resp.data.status === 200){
            dispatch(success(resp.data));
          }
          else{
            dispatch(failure('not_found'));
          }
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(resp) { return { type: LeadConstants.CUSTOMER_UPDATE_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.CUSTOMER_UPDATE_FAILURE, error } }

}

function submitRequirementForm(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.saveRequirementForm(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              dispatch(success(resp.data));
              return resolve(resp.data)
            }
            else{
              dispatch(failure('not_found'));
              return reject('not_found')
            }
          },
          error => {
            dispatch(failure(error));
            return reject(error)
          }
        );
    });
  };

  function success(resp) { return { type: LeadConstants.REQUIREMENT_SAVE_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.REQUIREMENT_SAVE_FAILURE, error } }

}

function getReqAgentDetails(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getAgentDetails(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              dispatch(success(resp.data.data));
              return resolve(resp.data.data)
            }
            else{
              dispatch(failure('not_found'));
            }
          },
          error => {
            dispatch(failure(error));
            return reject(error)
          }
        );
    });
  };

  function success(resp) { return { type: LeadConstants.GET_AGENT_DETAILS_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.GET_AGENT_DETAILS_FAILURE, error } }

}

function listDealer(params) {

  return dispatch => {
    
    return new Promise((resolve, reject) => {
        LeadService.listDealers(params)
          .then(
            resp => {
              if(resp.data && resp.data.status === 200){
                
                dispatch(success(resp.data.data));
                resolve(resp.data.data);
              }
              else{
                dispatch(failure('not_found'));
                resolve((resp && resp['data']) || []);
              }
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          ).catch(error=>{
              dispatch(failure(error));
              reject(error);
          });
    });
  };

  function success(resp) { return { type: LeadConstants.DEALER_LIST_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.DEALER_LIST_FAILURE, error } }

}

function getStatus(params) {

  return dispatch => {
 
    return new Promise((resolve, reject) => {
        LeadService.getStatusList(params)
          .then(
            resp => {
              if(resp.data && resp.data.status === 200){
                dispatch(success(resp.data.data));
                resolve(resp.data.data);
              }
              else{
                dispatch(failure('not_found'));
                resolve((resp && resp['data']) || []);
              }
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          ).catch(error=>{
              dispatch(failure(error));
              reject(error);
          });
    });
  };

  function success(resp) { return { type: LeadConstants.STATUS_LIST_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.STATUS_LIST_FAILURE, error } }

}

function getTimelineDetails(params){
  return dispatch => {
 
    return new Promise((resolve, reject) => {
        LeadService.getTimelineDetails(params)
          .then(
            async (resp) => {
              let timelineDetails={}
              if(resp.data && resp.data.status === 200){
                timelineDetails = resp.data.data;
                let leadDetails = await listDetails({'id':params.lead_id});
                leadDetails = leadDetails.data;
                if(leadDetails && leadDetails.length){
                   timelineDetails = await getTimeLineDataToDisplay(leadDetails,timelineDetails)
                }
                dispatch(success(timelineDetails));
                resolve(timelineDetails);
              }
              else{
                dispatch(failure('not_found'));
                resolve((resp && resp['data']) || []);
              }
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          ).catch(error=>{
              dispatch(failure(error));
              reject(error);
          });
    });
  };

  function success(resp) { return { type: LeadConstants.TIMELINE_DETAILS_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.TIMELINE_DETAILS_FAILURE, error } }

}

function getLeadsCarList(params) {

  return dispatch => {
 
    return new Promise((resolve, reject) => {
        LeadService.getLeadsCarList(params)
          .then(
            async (resp) => {
              if(resp.data && resp.data.status === 200){
                let leadsCarDetails = resp.data.data;
                let leadsCarData=[];
                if(leadsCarDetails && leadsCarDetails.length){
                  for(let leads of leadsCarDetails){
                    leads['carDetails']={};
                    leads['walkingDetails']={};
                    leads['subStatusList']=[];
                    leads['newCarPriceInfo']={};
                    // if(leads && leads.dealer_id && leads.car_id){
                    //   let carDetails = await getCarDetails(leads);
                    //   if(carDetails && carDetails.status === 200 && carDetails.data){
                    //     leads.carDetails = carDetails.data;
                    //   }
                    // }
                    //if(leads && leads.lead_id){
                      //let walkinDetails = await getWalkingData(leads);
                      //if(walkinDetails && walkinDetails.status === 200 && walkinDetails.data){
                        //leads.walkingDetails = walkinDetails.data[0];
                      //}
                   // }
                    // if(leads && leads.status_id){
                    //   let subStatus = await getSubStatus({'status_id':leads.status_id});
                    //   if(subStatus && subStatus.status === 200 && subStatus.data && subStatus.data.length){
                    //     leads.subStatusList = HELPER.getOptionValueToDisplay(subStatus.data,'sub_status');
                    //   }
                    // }
                    // if(leads && leads.variant_id && leads.car_city){
                    //   let newCarPriceInfo = await getNewCarPriceInfo({'city':leads.car_city,'version_id':leads.variant_id});
                    //   if(newCarPriceInfo && newCarPriceInfo.status === 200 && newCarPriceInfo.data && newCarPriceInfo.data.length){
                    //     leads.newCarPriceInfo = newCarPriceInfo.data[0];
                    //   }
                    // }
                    leadsCarData.push(leads)
                  }
                  
                }
                dispatch(success(leadsCarData));
                resolve(leadsCarData);
              }
              else{
                dispatch(failure('not_found'));
                resolve((resp && resp['data']) || []);
              }
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          ).catch(error=>{
              dispatch(failure(error));
              reject(error);
          });
    });
  };

  function success(resp) { return { type: LeadConstants.LEADS_CAR_LIST_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.LEADS_CAR_LIST_FAILURE, error } }

}

function getCarDetails(postData) {
  let params = {
    user_id : postData.dealer_id,
    stock_used_car_id : postData.car_id
  } 
    return new Promise((resolve, reject) => {
        LeadService.getUsedStockcarDetails(params)
          .then(
            resp => {
              if(resp && resp.data.status === 200){

                return resolve(resp.data);
              }
              else{
                return resolve((resp && resp['data']) || []);
              }
            },
            error => {
              return reject(error);
            }
          );
    });
}

function getCustomer(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getCustomers(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              
              return resolve(resp.data.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function addConversion(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.saveConversion(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function getBlockedClosedLeads(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getBlockedClosedLeads(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function unblockLead(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.unblockLead(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function submitBulkUpload(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.submitBulkUpload(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function getSubStatus(params) {

  return dispatch => {
 
    return new Promise((resolve, reject) => {
        LeadService.getSubStatusList(params)
          .then(
            resp => {
              if(resp.data && resp.data.status === 200){
                dispatch(success(resp.data.data));
                resolve(resp.data.data);
              }
              else{
                dispatch(failure('not_found'));
                resolve((resp && resp['data']) || []);
              }
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          ).catch(error=>{
              dispatch(failure(error));
              reject(error);
          });
    });
  };

  function success(resp) { return { type: LeadConstants.SUB_STATUS_LIST_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.SUB_STATUS_LIST_FAILURE, error } }

}

// function getSubStatus(params) {
//   return new Promise((resolve, reject) => {
//         LeadService.getSubStatusList(params)
//           .then(
//             resp => {
//               if(resp.data && resp.data.status === 200){
//                 resolve(resp.data);
//               }
//               else{
//                 resolve((resp && resp['data']) || []);
//               }
//             },
//             error => {
//               reject(error);
//             }
//           ).catch(error=>{
//               reject(error);
//           });
//     });
// }

function getWalkingData(leadData) {
  let params = {
    'lead_id':leadData.lead_id,
    'leads_car_id':leadData.id
  }
    return new Promise((resolve, reject) => {
        LeadService.getWalkingDetails(params)
          .then(
            resp => {
              if(resp.data && resp.data.status === 200){
                resolve(resp.data);
              }
              else{
                resolve((resp && resp['data']) || []);
              }
            },
            error => {
              reject(error);
            }
          ).catch(error=>{
              reject(error);
          });
    });
  
}
function getConversionInfoList(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getConversionInfoList(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function updateConversion(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.updateConversion(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function importExcelFile(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.importXlsFile(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function getLeadFilterStatusList(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {

        LeadService.getStatusList(postData)
          .then(
            resp => {
              if(resp && resp.data.status === 200){
               dispatch(success(resp.data));
                return resolve(resp.data);
              }
              else{
               dispatch(failure('not_found'));
                return resolve((resp && resp['data']) || []);
              }
            },
            error => {
              dispatch(failure(error));
              return reject(error);
            }
          );
    });
  };

  function success(resp) { return { type: LeadConstants.LEAD_FINDER_STATUS_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.LEAD_FINDER_STATUS_FAILURE, error } }
}

// function getSubStatusList(postData) {
//   return dispatch => {
//     return new Promise((resolve, reject) => {
//       LeadService.getSubStatusList(postData)
//         .then(
//           resp => {
//             if(resp && resp.data && resp.data.status === 200){
//               return resolve(resp.data)
//             }
//             else{
//               reject((resp && resp.data) || []);
//             }
//           },
//           error => {
//             return reject(error)
//           }
//         );
//     });
//   };
// }

function getLeadList(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getLeadList(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function saveMainLead(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.saveMainLeadDetails(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function saveSubLeadOutstation(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.saveSubLeadOutstation(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function copyCarDetails(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.copyCarDetails(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function saveCarRecomend(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.saveRecomendCar(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function listFeedback() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getWalkinFeedback()
      .then(
        resp => {
          if(resp && resp.data && resp.data.status === 200){
            return resolve(resp.data)
          }
          else{
            reject((resp && resp.data) || []);
          }
        },
        error => {
          return reject(error)
        }
      );
  });
};
}

function getClusterCities(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ClusterService.getCityListForCluser(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function getCityState(postData={}) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      CityService.getCityState(postData)
        .then(
          resp => {
            if(resp.data && resp.data.status === 200){
              resolve(resp.data.data);
              dispatch(success(resp.data.data));
            }
            else{
              resolve(resp.data);
              dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            dispatch(failure(error));
          }
        );
    });
  };

  function success(resp) { return { type: LeadConstants.CITY_STATE_LIST_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.CITY_STATE_LIST_FAILURE, error } }

}

function getUserNameByType(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getUserNameByType(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

async function getTimeLineDataToDisplay(leadDetails,timelineDetails) {
  let agentCommentCount = 1,BMCommentCount = 1,DealerCommentCount = 1,customerCommentCount = 1,systemCommentCount = 1;
  let followUpReasonValue = true;
  if(timelineDetails && timelineDetails.length) {
      for(let tlData of timelineDetails){
        tlData['followup_reason_value']='';
        tlData['commentedUserName']='';
        tlData['icon']='';
        tlData['leadAddedDate']='';
        tlData['commentedDealerName']='';
          if (followUpReasonValue && ( Number(leadDetails[0]['sub_status_id']) === 5) && (Number(leadDetails[0]['sub_status_id']) === Number(tlData['sub_status_id'])) ) {
              followUpReasonValue = false;
              tlData['followup_reason_value'] = leadDetails[0]['followup_reason'];
          }
          let dcUserType = tlData['dc_user_type'];
          
          if(agentCommentCount > 1 && BMCommentCount > 1 && DealerCommentCount > 1 && customerCommentCount > 1 && systemCommentCount > 1) {
              break;
            }

          tlData['commentedUserName'] = tlData['name'];
          
          if(tlData['added_by'] > 0 && Number(tlData['status_added_by_system']) !== 1 && agentCommentCount === 1) {
              tlData['icon'] = 'call';
              agentCommentCount++;
          } else {
              if(tlData['dc_user_id'] > 0 && (BMCommentCount === 1 || DealerCommentCount === 1)) {
                  let userInfo = await getUserNameByType({user_id:tlData['dc_user_id'],'user_type':dcUserType});
                  tlData['commentedUserName'] = (userInfo && userInfo.userName) ? userInfo.userName : '';
                  // tlData['commentedDealerName']= tlData.commentedUserName;
                  if(dcUserType === 'DEALER' && DealerCommentCount === 1) {
                      tlData['icon'] = 'car-user'; 
                      DealerCommentCount++;                                 
                  } else if(BMCommentCount === 1 && (dcUserType === 'DC' || dcUserType === 'BM')) {
                      tlData['icon'] = 'sales';
                      BMCommentCount++;
                  }
              } else if((Number(tlData['added_by']) === -1 || (Number(tlData['added_by']) > -1 && Number(tlData['status_added_by_system']) === 1) ) && Number(systemCommentCount) === 1){
                  tlData['icon'] = 'computer';
                  systemCommentCount++;
                  tlData['leadAddedDate'] = leadDetails[0]['created_at'];
                  tlData.commentedUserName = "System";
              } else if( Number(tlData['added_by']) === 0 && Number(customerCommentCount) === 1 && Number(tlData['status_added_by_system']) !== 1){
                  tlData['icon'] = 'user';
                  customerCommentCount++;
                  tlData['leadAddedDate'] = leadDetails[0]['created_at'];
              }
          }
          
      }
  }
  return timelineDetails;
}

function listDetails(params) {
  
  return new Promise((resolve, reject) => {
      LeadService.getLeadList(params)
        .then(
          resp => {
             
            if(resp && resp.data && resp.data.status === 200){
              
              resolve((resp && resp.data) || []);
            }
            else{
              resolve((resp && resp.data) || []);
            }
          },
          error => {
            reject(error);
          }
        );
  });


}

function getRTOList(postData={}) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getRTOList()
        .then(
          resp => {
            if(resp.data && resp.data.status === 200){
              resolve(resp.data.data);
              dispatch(success(resp.data.data));
            }
            else{
              resolve(resp.data);
              dispatch(failure('not_found'));
            }
          },
          error => {
            reject(error);
            dispatch(failure(error));
          }
        );
    });
  };

  function success(resp) { return { type: LeadConstants.RTO_LIST_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.RTO_LIST_FAILURE, error } }

}

function getNewCarPriceInfo(postData) {
  return new Promise((resolve, reject) => {
    LeadService.getNewCarPrice(postData)
      .then(
        resp => {
          if(resp && resp.data && resp.data.status === 200){
            return resolve(resp.data)
          }
          else{
            reject((resp && resp.data) || []);
          }
        },
        error => {
          return reject(error)
        }
      );
  });
}

function saveDealerFlag(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.saveDealerFlag(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function getCustomerLocality(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getCustomerLocality(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              dispatch(success(resp.data.data.data));
              return resolve(resp.data.data.data)
            }
            else{
              dispatch(failure('not_found'));
              reject((resp && resp.data) || []);
            }
          },
          error => {
            dispatch(failure(error));
            return reject(error)
          }
        );
    });
  };
  function success(resp) { return { type: LeadConstants.CUSTOMER_LOCATITY_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.CUSTOMER_LOCATITY_FAILURE, error } }
}

function setReopenLead(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(success(postData));
      return resolve(postData)
    });
  };
  function success(postData) { return { type: LeadConstants.LEAD_REOPEN_DATA, postData } }
}

function getConversionStatus(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getConversionStatus(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              dispatch(success(resp.data.data));
              return resolve(resp.data.data)
            }
            else{
              dispatch(failure('not_found'));
              reject((resp && resp.data) || []);
            }
          },
          error => {
            dispatch(failure(error));
            return reject(error)
          }
        );
    });
  };
  function success(resp) { return { type: LeadConstants.CONVERSION_STATUS_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.CONVERSION_STATUS_FAILURE, error } }
}

function getClusterList(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ClusterService.getClusterList(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function saveClosedLeadInfo(postData){
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.saveClosedLeadInfo(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}


function getWalkinFeedbackList(postData){
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getWalkinFeedbackList(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              dispatch(success(resp.data));
              return resolve(resp.data)
            }
            else{
              dispatch(failure('not_found'));
              reject((resp && resp.data) || []);
            }
          },
          error => {
            dispatch(failure(error));
            return reject(error)
          }
        );
    });
  };
  function success(resp) { return { type: LeadConstants.WALKIN_FEEDBACK_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.WALKIN_FEEDBACK_FAILURE, error } }
}


function getStockListByIds(postData){
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getStockListByIds(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}


function saveWalkinPurchaseInfo(postData){
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.saveWalkinPurchaseInfo(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function getCallStatusList(params) {

  return dispatch => {
 
    return new Promise((resolve, reject) => {
        LeadService.getCallStatusList(params)
          .then(
            resp => {
              if(resp.data && resp.data.status === 200){
                dispatch(success(resp.data.data));
                resolve(resp.data.data);
              }
              else{
                dispatch(failure('not_found'));
                resolve((resp && resp['data']) || []);
              }
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          ).catch(error=>{
              dispatch(failure(error));
              reject(error);
          });
    });
  };

  function success(resp) { return { type: LeadConstants.CALL_STATUS_LIST_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.CALL_STATUS_LIST_FAILURE, error } }

}


function getAllUserList(){
  return dispatch => {
    return new Promise((resolve, reject) => {
      ClusterService.getAllUserList()
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}
function getUsersByRoleId(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ClusterService.getAllTelecallerUser(params)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function getCallingStatus(params) {

  return dispatch => {
 
    return new Promise((resolve, reject) => {
        LeadService.getCallStatusList(params)
          .then(
            resp => {
              if(resp.data && resp.data.status === 200){
                dispatch(success(resp.data.data));
                resolve(resp.data.data);
              }
              else{
                dispatch(failure('not_found'));
                resolve((resp && resp['data']) || []);
              }
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          ).catch(error=>{
              dispatch(failure(error));
              reject(error);
          });
    });
  };

  function success(resp) { return { type: LeadConstants.CALLING_STATUS_LIST_SUCCESS, resp } }
  function failure(error) { return { type: LeadConstants.CALLING_STATUS_LIST_FAILURE, error } }

}

function getDialerLeads(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getDialerLeads(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function importLeadFinderData(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.importLeadFinderData(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function getNotificationDetail(queyParam) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getNotificationDetail(queyParam)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function updateRescheduledDate(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.updateRescheduledDate(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function leadFeedbackSubmit(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.leadFeedbackSubmit(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function feedbackFormValidity(queyParam) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.getFeedbackFormValidity(queyParam)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function purchaseFeedbackSubmit(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.purchaseFeedbackSubmit(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}

function downloadReport(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.downloadReportDetails(postData).then(
        resp => {
          if(resp && resp.data && resp.data.status === 200){
            return resolve(resp.data)
          }
          else{
            reject((resp && resp.data) || []);
          }
        },
        error => {
          return reject(error)
        }
      );
    });
  };
}

function submitBulkUploadFbLeads(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      LeadService.submitBulkUploadFbLeads(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}