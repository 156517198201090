import { ClusterService, DealerService } from '../../service';
import { DealerConstants } from '../constants/dealer.constants';

export const DealerActions = {
    getClusterList,
    submitLeadControlFilter,
    listDealer,
    updateDealerBoost,
    getDealerDeliveryReport,
    saveEmailAndFilters,
    importDealerDeliveryCsv
};

function getClusterList(postData) {

    return dispatch => {

      ClusterService.getClusterList(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              dispatch(success(resp.data));
            }
            else{
              dispatch(failure('not_found'));
            }
          },
          error => {
            dispatch(failure(error));
          }
        );
    };

    function success(resp) { return { type: DealerConstants.REQUEST_CLUSTER_LIST_SUCCESS, resp } }
    function failure(error) { return { type: DealerConstants.REQUEST_CLUSTER_LIST_FAILURE, error } }

}

function listDealer(params) {

  return dispatch => {
 
    return new Promise((resolve, reject) => {
        DealerService.listDealers(params)
          .then(
            resp => {
              if(resp && resp.data && resp.data.status === 200){
                dispatch(success(resp.data));
                resolve(resp.data);
              }
              else{
                dispatch(failure('not_found'));
                resolve((resp && resp['data']) || []);
              }
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          ).catch(error=>{
              dispatch(failure(error));
              reject(error);
          });
    });
  };

  function success(resp) { return { type: DealerConstants.DEALER_LIST_SUCCESS, resp } }
  function failure(error) { return { type: DealerConstants.DEALER_LIST_FAILURE, error } }

}

function submitLeadControlFilter(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
        DealerService.listDealers(postData)
          .then(
            resp => {
              if(resp && resp.data.status === 200){
                dispatch(success(resp.data));
                return resolve(resp.data);
              }
              else{
                dispatch(failure('not_found'));
                return resolve(resp.data || []);
              }
            },
            error => {
              dispatch(failure(error));
              return reject(error);
            }
          ).catch(error=>{
            dispatch(failure(error));
            reject(error);
        });
    });
  };

  function success(resp) { return { type: DealerConstants.FILTER_SUBMIT_SUCCESS, resp } }
  function failure(error) { return { type: DealerConstants.FILTER_SUBMIT_FAILURE, error } }

}


function updateDealerBoost(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
        DealerService.updateDealerBoost(postData)
          .then(
            resp => {
              if(resp && resp.data.status === 200){
                return resolve(resp.data);
              }
              else{
                return resolve(resp.data || []);
              }
            },
            error => {
              return reject(error);
            }
          ).catch(error=>{
            reject(error);
        });
    });
  };

}

function getDealerDeliveryReport(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
        DealerService.getDealerDeliveryReport(postData)
          .then(
            resp => {
              if(resp && resp.data.status === 200){
                return resolve(resp.data);
              }
              else{
                return resolve(resp.data || []);
              }
            },
            error => {
              return reject(error);
            }
          ).catch(error=>{
            reject(error);
        });
    });
  };
}

function saveEmailAndFilters(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
        DealerService.saveEmailAndFilters(postData)
          .then(
            resp => {
              if(resp && resp.data.status === 200){
                return resolve(resp.data);
              }
              else{
                return resolve(resp.data || []);
              }
            },
            error => {
              return reject(error);
            }
          ).catch(error=>{
            reject(error);
        });
    });
  };
}

function importDealerDeliveryCsv(postData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      DealerService.importDealerDeliveryCsv(postData)
        .then(
          resp => {
            if(resp && resp.data && resp.data.status === 200){
              return resolve(resp.data)
            }
            else{
              reject((resp && resp.data) || []);
            }
          },
          error => {
            return reject(error)
          }
        );
    });
  };
}