import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import secureStorage from "./../../config/encrypt";
import { default as config } from "./../../config/config";

class LanguageSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langOption: []
    };
  }

  componentDidMount = () => {
    let langData = config.constants.language;
    secureStorage.setItem("langOption", langData);
    this.setState({ langOption: langData });
  };

  render() {
    const { i18n } = this.props;
    const selLang = secureStorage.getItem("lang") || "en";
    const langOption = this.state.langOption;
    const currentPath = window.location.pathname;

    
      return (
        <div className="row">
          {
            (langOption.length > 1)
            ?
              <div className="col-sm-3">
                <div className="language-change language-login">
                  {
                  (!currentPath.includes('view-purchase-feedback') && !currentPath.includes('purchase-feedback') && !currentPath.includes('view-feedback') && !currentPath.includes('thankyou-page') && !currentPath.includes('feedback-form') && !currentPath.includes('walkin-reschedule'))
                  ?
                    langOption.map((lang, index) => (
                      <button
                        key={index}
                        type="button"
                        className={
                          selLang === lang.iso_code
                            ? "btn btn-primary"
                            : "btn btn-default"
                        }
                        onClick={() => {
                          secureStorage.setItem("lang", lang.iso_code);
                          i18n.changeLanguage(lang.iso_code);
                        }}
                      >
                        {lang.iso_code}
                      </button>
                    ))
                  :
                    ''
                }
                </div>
              </div>
            :
            ''
          }
          <i className="fa fa-spinner fa-spin"></i>

          <div className={(langOption.length>1) ? 'col-sm-9' : 'col-sm-12'}>
            <div className="copyright text-right">
              {this.props.t("site_content."+((langOption.length>1) ? 'oto_copyright' : 'carmudi_copyright'))}
            </div>
          </div>
        </div>
      );

  }
}

export default withTranslation("common")(LanguageSelector);
