import _ from 'lodash';
import secureStorage from './encrypt';
import { toast } from 'react-toastify';

export const filterDataByKey = (filterData,keyToFind) => {
    let retArray=[];
    if (filterData.length) 
        retArray = _.find(filterData, { 'key': keyToFind });
    return retArray;
}

export const getDataInKeyValueFormat = (params,keyToFind='', groupByKey=null, diffUnique=null) => {
    const arrObj = [];
    let arrObjNew = [];
    _.forEach((params),(data) => {
        let tempObj={};
        tempObj.value = (typeof data.key !== 'undefined') ? data.key : data.id;
        tempObj.label = ((data.value) ? data.value : ((data.key) ? data.key : ((data.make) ? data.make : ((data.organization) ? data.organization : (data.name || data.gcd_code))))) || data.key || data.id;
        if(groupByKey){
            tempObj['groupByLabel'] = (groupByKey === 'sub_status_name') ? data['status_name']+' - '+data[groupByKey] : data[groupByKey];
            
        }
        arrObj.push(tempObj);
    });

    arrObjNew = arrObj;
    if(diffUnique){
        arrObjNew = [];
        arrObjNew = arrObj.map(el=> { 
            if((arrObj.filter(elm=> (elm.label === el.label && elm.value !== el.value))).length){
                return {...el, label: el.groupByLabel+ ' - ' + el.label}
            }else{
                return el;
            }
        });
    }

    return arrObjNew;
}

export const getCarMakeModelList = (makeModelData) => {
    let retArr = [];
    if (makeModelData.make.length) {
        _.forEach((makeModelData.make),(makeObj) => {
            const modelObj = makeModelData.model.filter(el=> +el.mk_id===makeObj.id)//_.find(makeModelData.model, { 'mk_id': makeObj.id });

            if(modelObj){
                modelObj.forEach(elm=>{
                    let indexExists = retArr.filter(vl=> +vl.value === elm.id )
                    if(!indexExists.length){
                        let tempObj={};
                        tempObj.value = elm.id;
                        tempObj.label = makeObj.make+' '+elm.m;
                        retArr.push(tempObj);
                    }
                })
                
            }
        });
    }
    return retArr; 
}

export const getCarMakeList = (makeModelData) => {
    let retArr = [];
    if (makeModelData.make.length) {
        retArr = makeModelData.make;
    }
    return retArr; 
}
export const getCarModelList = (makeModelData) => {
    let retArr = [];
    if (makeModelData.model.length) {
        retArr = makeModelData.model
    }
    return retArr; 
}
export const getCarVersionList = (makeModelData) => {
    let retArr = [];
    if (makeModelData.version.length) {
        retArr = makeModelData.version
    }
    return retArr; 
}

export const getUserName = (id,keyToReturn) => {
    let retVal = ''
    const userList = secureStorage.getItem('userList');
    const userObj = _.find(userList, { 'id': id });
    if(userObj)
        retVal = userObj[keyToReturn];
    return retVal;
}

export const getValueByKey = (listObj,key) => {
    
    if(key!=="undefined" && !Number.isNaN(key)){
        // console.log(listObj,key);
        // key = Number.isNaN(+key)?key:key.split(",");
        // let filterObj = listObj.filter(e=>key.includes(e.value));
        // filterObj = filterObj.map(e=>e.label).join(",")
        // return filterObj;
        let tempKey = ""+key;
        tempKey = tempKey.split(",").map(e=>""+e)
        // console.log(listObj,key)
        let tempValues = listObj.filter(e=>tempKey.includes(""+e.value)).map(e=>e.label).join(", ");
        return tempValues;
        // console.log(tempValues);
        //     let retVal = ''
        //     const result = _.find(listObj, { 'value': key });
        //     if(result)
        //         retVal = result.label;
        //     return retVal;
    }
    

}

export const getBase64 = async (file, cb) => {
    let reader = new FileReader();
     reader.readAsDataURL(file);
    
    reader.onload =  () => { 
    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
    cb(encoded)
    };
    reader.onerror =  (error) => {
        cb(error);
    };
}

export const getOptionValueToDisplay = (list,key) =>{
    const arrObj = [];
    if(key === 'status' || key === 'sub_status' || key === 'city' || key === 'call_status'){
        _.forEach(list,(data) => {
            let obj={}
            obj.value = data.id
            obj.label = data.name;
            if(key === 'sub_status'){
                obj['status_id'] = data['status_id']
            }else if(key === 'call_status'){
                obj['sub_status_id'] = data['sub_status_id']
            }
            arrObj.push(obj)
        })
    }
    return arrObj;
}

export const dateFormat = async (d) => {
    return d.getFullYear()+'-'+((d.getMonth()<9)?'0'+(d.getMonth()+1):(d.getMonth()+1))+'-'+((d.getDate()<10)?'0'+(d.getDate()):(d.getDate()));
}

export const timeDifference = (fromTime, toTime) => {
    let d = Math.abs(fromTime - toTime) / 1000;
    let r = {};
    let s = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
    };

    Object.keys(s).forEach(function(key){
        r[key] = Math.floor(d / s[key]);
        d -= r[key] * s[key];
    });

    // for example: {year:0,month:0,week:1,day:2,hour:34,minute:56,second:7}
    return r;
}

export const isUserHasAccessPage = (module,controller,action,isCheckForTopLevel) => {
    let isUserHasRights = false;
    const rolePrivilege = secureStorage.getItem('privilegeInfo');
    const moduleArr = (rolePrivilege && rolePrivilege.moduleArr) ? rolePrivilege.moduleArr : [];
    const controllerArr = (rolePrivilege && rolePrivilege.controllerArr) ? rolePrivilege.controllerArr : [];
    const actionArr = (rolePrivilege && rolePrivilege.actionArr) ? rolePrivilege.actionArr : [];
    if(!action){
        let checkForTopLevelMenuRights = controllerArr.includes('manage'+controller.toLowerCase())
        if(checkForTopLevelMenuRights && isCheckForTopLevel){
            isUserHasRights = true;
        }
        else if(moduleArr.length && controllerArr.length && moduleArr.includes(module.toLowerCase()) && controllerArr.includes(controller.toLowerCase())){
            isUserHasRights = true;
        }
    }else{
        let actionName = action.split('/');
        if(actionName[1] && moduleArr.length && controllerArr.length && moduleArr.includes(module.toLowerCase()) && controllerArr.includes(controller.toLowerCase()) && actionArr.includes(actionName[1].toLowerCase())){
            isUserHasRights = true;
        }
    }
    return isUserHasRights;
}

export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied',{autoClose: 1000,hideProgressBar: true });
}

export const subSourceNames =  {
        "1": "Organic & Direct(All non paid)",
        "2": "CPC Search",
        "3": "CPC Others", 
        "4": "FB", 
        "5": "Others"
}