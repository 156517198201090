import React, { Component } from 'react';
import InputField from '../../../elements/InputField';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InventoryActions, DealerActions, LeadActions } from "../../../../store/action";
import { withTranslation } from 'react-i18next';
import { Multiselect } from 'multiselect-react-dropdown';
import * as helper from '../../../../config/helper';
import _  from 'lodash';
import queryString from 'query-string';


class InventoryFilter extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.multiselectRef = React.createRef();
    }

    initialState = () => {
        return {
            filterFields: [],
            loading: false,
            advanceSearchEnable: false,
            make : [],
            model : [],
            mmvList : [],
            cachedPostData:{}
        }
    }

    componentDidMount = async () => {
        this._isMounted = true;

        await this.inventoryFilters();
        await this.gerCarsFeature();

        const { childRef } = this.props;
        childRef(this);

    }
    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};
        if(nextProps.makeModelVersionList !== prevState.mmvList){
            updatedStateData['mmvList'] = nextProps.makeModelVersionList;
            
            if(updatedStateData['mmvList'] && updatedStateData['mmvList'].hasOwnProperty('make')){
                let {make} = updatedStateData['mmvList'];
                if(make && make.length){
                    let allmake = make.map(function(e){
                        return {label : e.make,value : e.id}
                    })
                    updatedStateData['make'] = allmake
                    
                }
            }
        }
        return updatedStateData;

    }
    inventoryFilters = async () => {
        let filterFields = this.state.filterFields;
        // debugger;
        
        let queryParams = queryString.parse(window.location.search);
        let getList = this.props.inventoryFilters || [];

        if(!getList.length){
            getList = await this.props.actions.inventoryFilters();
            getList = (getList['data'] && getList['data'].length) ? getList['data'] : [];  
        }

        if(getList.length){

            let colorObj = await getList.filter(el=> el.key==='color'); //GET COLOR OBJ
                getList = await getList.filter(el=> el.key!=='color');

                if(colorObj && colorObj.length){
                    getList.push(colorObj[0]);
                }

            filterFields =  await getList.map( (el,key) => { 
                if(el.type === 'Multi select'){
                    el.multiRef = React.createRef()//this.multiselectRef
                }
                let options = (el.list) ? helper.getDataInKeyValueFormat(el.list, '') : '';

                if(options){
                    el['option'] = options;
                    delete el.list;
                }
                return el;
            });
            ///////////////////////////// ADD CUSTOM FILTER FIELDS /////////////////
            

            filterFields.push(
                {
                    key: "make",
                    placeholder: "Car Make",
                    title: "Car Make",
                    type: "Multi select",
                    option : this.state.make,
                    multiRef: React.createRef()
                },
                {
                    key: "model",
                    placeholder: "Car Model",
                    title: "Car Model",
                    type: "Multi select",
                    option : this.state.model,
                    multiRef: React.createRef()
                }
            )

            //CITY LIST
            let listCity = await this.listCity();
            filterFields.push(
                {
                    key: "city",
                    placeholder: "City",
                    title: "City",
                    type: "Multi select",
                    option : helper.getDataInKeyValueFormat(listCity, ''),
                    multiRef: React.createRef()
                }
            )

            //ADD DEALER
            let getDealer = await this.listDealers();

            //CHECK IF did(dealer id) EXISTS IN URL
            let searchedDealer = [];
            if(queryParams.did){
                searchedDealer = await getDealer.filter(el=>+el.id === +queryParams.did);
                searchedDealer = helper.getDataInKeyValueFormat(searchedDealer, '');
            }
            filterFields.push(
                {
                    key: "dealer",
                    placeholder: "Search by Dealer",
                    title: "Dealer",
                    type: "Multi select",
                    option : helper.getDataInKeyValueFormat(getDealer, ''),
                    value: searchedDealer,
                    multiRef: React.createRef()
                }
            )

            //INVENTORY AGE
            // filterFields.push(
            //     {
            //         key: "car_age_before",
            //         placeholder: "Stock Age",
            //         title: "stock_age",
            //         type: "textbox",
            //         value : '',                
            //         inputType : 'number'                
            //     }
            // )

            
        }

        if(this._isMounted) this.setState({filterFields: filterFields});

    }


    gerCarsFeature = async () => {

        let makeModelData = this.props.makeModelVersionList || [];
        let filterFields = await this.state.filterFields;
        
        if(!makeModelData.make){
            makeModelData = await this.props.actions.getCarVariant();
        }
        const arrObj = [];

        if (makeModelData && makeModelData.make && makeModelData.make.length) {
            _.forEach((makeModelData.make),(makeObj) => {
                let tempObj={};
                const modelObj = _.find(makeModelData.model, { 'mk_id': makeObj.id });
                if(modelObj){
                    tempObj.value = makeObj.id;
                    tempObj.label = makeObj.make+' '+modelObj.m;
                    arrObj.push(tempObj);
                }
            });
        } else {
            // thisObj.setState({ carMakeModelList: arrObj});
        }

        filterFields = filterFields.map( (el,key) => {

            if(el.key === 'car_model'){
                el['option'] = arrObj;
            }
            return el;
        });
                
        if(this._isMounted) this.setState({'filterFields': filterFields});
    }


    listCity = async () => {
        if(this.props.cityList && this.props.cityList.data && this.props.cityList.data.city){
            return this.props.cityList.data.city;
        }else{
            let list = await this.props.actions.listCity();
            return (list && list.data && list.data.city) ? list.data.city : [];
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
        const { childRef } = this.props;
        childRef(undefined);
    }

    /**
     * List Dealers
     */

    listDealers = async () => {
        let params = {};
        params['_with'] = ['id', 'organization'];

        if(!this.props.listDealer || !Object.keys(this.props.listDealer).length){

            // await this.setState({loading: true});

            let dealersData = await this.props.actions.listDealer(params);
            return (dealersData && dealersData.data && dealersData.data.length) ? dealersData.data : []
            // .then(val=>{
            //     if(val['status'] === 200){
            //         if(this._isMounted) this.setState({loading: false});
            //         return val.data || []
            //     }
            // }).catch(err=>{
                // if(this._isMounted) this.setState({loading: false});
                // return [];
            // });
        }else{
            return (this.props.listDealer && this.props.listDealer.data)? this.props.listDealer.data : this.props.listDealer;
        }

    }

    /**
     * Handle select box change
     */
    handleChange =   (param, index) =>  e => {
        let val = '';
        val = e ? ((e.value) ? e : e.target.value) : '';
        this.updateStateFun(param, val, index);
    }
    /* change model value */
    handleChangeModelValue = (value) => {
        let {filterFields} = this.state;
        
  
        /////////////////// handle change for make id /////////////////
        let allModels = [],selectedMake = [];
        if(value && value.length){
            let {mmvList:{model}} = this.state;
            let selectedMakeId = value.map(e=>e.value);
            selectedMake  = selectedMakeId;
            allModels = model.filter(e=>selectedMakeId.includes(e.mk_id));
            allModels = allModels.map(function(e){
                return {label : e.m,value:e.id,mk_id : e.mk_id}
            })
           
        }
        ///////////////// handle removing of model that don't lie in make ////////////
        for(let i of filterFields){
            if(i.key==="model"){
                if(i.value instanceof Array && i.value.length>0){
                    i.value = i.value.filter(e=>selectedMake.includes(e.mk_id))
                }
            }
        }

        this.setState({model:allModels,filterFields:filterFields})
    }
    /**
     * Update state
     */
    updateStateFun = async (type, val, index) => {
        const updateState = this.state.filterFields;
        updateState[index]['value'] = val;
        if(type === "make"){
            this.handleChangeModelValue(val);
        }
        await this.setState({filters: updateState});
    }

    /**
     * On multi select option select
     */
    onSelect = (type, index) => (selectedList , selectedItem) => {
        this.updateStateFun(type, selectedList, index);
    }
    
    /**
     * On multi select option remove
     */
    onRemove = (type, index) => (selectedList , selectedItem) => {
        this.updateStateFun(type, selectedList, index);
    }

    /**
     * Filter Submit Handler
     */
    submitFilter =  async (pageNo=1, callingFrom='') => {
        // e.preventDefault();
        await this.setState({ submitted: true });
        let postData = {};
        this.state.filterFields.forEach((el) => { 
            let value = [];
            if(typeof el['value'] === 'object'){
                if(el['value']['value']){ 
                    value = el['value']['value'];
                }else{ //traverse through array
                    el['value'].forEach((v, k)=>{
                        value.push(((typeof v.value !== 'undefined') ? v.value : v.id));
                    })
                }
            }else{
                value = (typeof el.value !== 'undefined') ? el['value'] : '';
            }
            postData[el.key] = value;
        }); 
        //SUBMIT SEARCH FORM
        if(Object.keys(postData).length){
            if(callingFrom && callingFrom === 'pagination'){
                postData = Object.keys(this.state.cachedPostData).length ? this.state.cachedPostData : postData 
            }else{
                this.setState({cachedPostData:postData});
            }
            this.props.onSearchSubmit(postData, pageNo);
        }
    }

    /**
     * Reset Form
     */

    resetForm = async () => {
        let updateState = await this.state.filterFields.map(el=> {
            if(el.multiRef) {
                el.multiRef.current.resetSelectedValues();
            }
            
            el['value'] = (el.type === 'Multi select') ? [] : ''; return el; 
        });
        
        await this.setState({filterFields: updateState, model:[]});
        this.props.onSearchSubmit({action: 'resetAll'}, 1);
        // this.multiselectRef.current.resetSelectedValues();
    }

    render() {

        const { loading, filterFields } = this.state;
        
        return (
            <form>
                <div className="row filter-box-inventory">
                {
                    filterFields.length 
                    ?
                    
                        filterFields.map((el, key) => {
                            if(['car_make','car_model'].includes(el.key)){
                                return ""
                            }
                            if(el.type === 'Multi select' || ['uc_transmission_id','fuel_type_id'].includes(el.key)){
                                if(el.key === "make"){
                                    return (
                                        <div className="col-sm-12" key={el.key}>
                                            <label>{this.props.t('inventory.'+el.key)}</label>
                                            <Multiselect
                                                id={el.key}
                                                options={this.state.make} // Options to display in the dropdown
                                                selectedValues={el.value} // Preselected value to persist in dropdown
                                                onSelect={this.onSelect(el.key, key)} // Function will trigger on select event
                                                onRemove={this.onRemove(el.key, key)} // Function will trigger on remove event
                                                displayValue="label"
                                                ref={el.multiRef}
                                            />
                                        </div>
                                    )
                                }else if(el.key === "model"){
                                    return (
                                        <div className="col-sm-12" key={el.key}>
                                            <label>{this.props.t('inventory.'+el.key)}</label>
                                            <Multiselect
                                                id={el.key}
                                                options={this.state.model} // Options to display in the dropdown
                                                selectedValues={el.value} // Preselected value to persist in dropdown
                                                onSelect={this.onSelect(el.key, key)} // Function will trigger on select event
                                                onRemove={this.onRemove(el.key, key)} // Function will trigger on remove event
                                                displayValue="label"
                                                ref={el.multiRef}
                                            />
                                        </div>
                                    )
                                }else{
                                    return (
                                        <div className="col-sm-12" key={el.key}>
                                            <label>{this.props.t('inventory.'+el.key)}</label>

                                            <Multiselect
                                                id={el.key}
                                                options={el.option} // Options to display in the dropdown
                                                selectedValues={el.value} // Preselected value to persist in dropdown
                                                onSelect={this.onSelect(el.key, key)} // Function will trigger on select event
                                                onRemove={this.onRemove(el.key, key)} // Function will trigger on remove event
                                                displayValue="label"
                                                ref={el.multiRef}
            
                                            />
                                        </div>
                                    )
                                }
                                
                            }else if(el.type === 'textbox'){
                                return (
                                    <div className="col-sm-12" key={el.key}>
                                        <InputField
                                            inputProps={{
                                                id: el.key,
                                                type: el.inputType || "text",
                                                name: el.key,
                                                value: el.value || '',
                                                label: this.props.t('inventory.'+el.title)
                                            }}
                                            onChange={this.handleChange(el.key, key)}
                                    />
                                    </div>)
                            }else if(el.type === 'Select'){
                                        return (
                                        <div className="col-sm-12" key={el.key}>
                                            <label>{this.props.t('inventory.'+el.key)}</label>
                                            <Select
                                            id={el.key}
                                            name={el.key}
                                            value={el.value}
                                            onChange={this.handleChange(el.key, key)}
                                            options={el.option}
                                            isClearable={true}
                                        />
                                        </div>)
                            }else{
                                return ''
                            }
                            
                        })
                    :
                    ''
                    
                }
                <div className="filtersBtns">
                    <button type="button" onClick={this.resetForm} name="reset"  className="btn btn-default mrg-r15">{this.props.t('inventory.reset')}</button>

                    { 
                        (loading || this.props.dataToChild)
                        ?
                        <button type="button" name="submit" disabled className="btn btn-primary">{this.props.t('inventory.search')}</button>
                        :
                        <button type="button" name="submit" onClick={() => this.submitFilter(1)} className="btn btn-primary">{this.props.t('inventory.search')}</button>
                    }
                </div>                
            </div>
            </form>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    // carMakeList: state.inventory.carMakeData,
    // carModelList: state.inventory.carModelData,
    cityList: state.inventory.cityListData,
    listDealer: state.dealer.dealerListData,
    makeModelVersionList: state.lead.makeModelVersionList,
    inventoryFilters: state.inventory.inventoryFilters,

});

const mapDispatchToProps = dispatch => {
    return {
                actions: {
                    getCarMake: bindActionCreators(
                        InventoryActions.getCarMake,
                        dispatch
                    ),
                    getCarModel: bindActionCreators(
                        InventoryActions.getCarModel,
                        dispatch
                    ),
                    listCity: bindActionCreators(
                        InventoryActions.listCity,
                        dispatch
                    ),
                    listDealer: bindActionCreators(
                        DealerActions.listDealer,
                        dispatch
                    ),
                    getCarVariant: bindActionCreators(
                        LeadActions.getMakeModelVersion,
                        dispatch
                    ),
                    inventoryFilters: bindActionCreators(
                        InventoryActions.inventoryFilters,
                        dispatch
                    )
               }
            }
}
export default  withTranslation('common') (connect(mapStateToProps, mapDispatchToProps)(InventoryFilter));