import React, { Component } from 'react';
import FilterBox from '../../../lead/helpers/filter-box';
import FilterTable from '../../../lead/helpers/filter-table';
import { default as config } from '../../../../config/config';
import { connect } from 'react-redux';
import { DealerActions, LeadActions } from "../../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';

class DealerDeliveryList extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            filterData: {},
            searchResult: [],
            advanceSearchEnable: false,
            totalLengthWithoutPagination: 0,
            tableData: [],
            filterFieldsData:[
                {name: 'cluster', label: 'dealerDeliveryReport.cluster', multiRef: React.createRef(), type: 'multiselect', option:[], value: '', advanceSearch : false },
                {name: 'city', label: 'dealerDeliveryReport.city', dependent:'cluster', multiRef: React.createRef(),type: 'multiselect', option: [], value: '', advanceSearch : false },
                {name: 'dealer', label: 'dealerDeliveryReport.dealer', type: 'select', option: [], value: '', advanceSearch : false },
                {name: 'created_from_date', label: 'dealerDeliveryReport.created_from_date',  placeholder:'search.placeholder.startDate', type: 'date', value:'', advanceSearch : false },
                {name: 'created_to_date', label: 'dealerDeliveryReport.created_to_date', type: 'date', placeholder: 'search.placeholder.endDate',  value:'', advanceSearch : false },
                {name: 'status', label: 'dealerDeliveryReport.status', type: 'select', option: config.constants.status, value:'', advanceSearch : false },
                {name: 'priority', label: 'dealerDeliveryReport.dealerType', type: 'select', option: config.constants.DEALER_PRIORITY, value:'', advanceSearch : false },
                // {name: 'reportType', label: 'dealerDeliveryReport.reportType', type: 'select', option: config.constants.REPORT_TYPE, value:'', advanceSearch : true },
                // {name: 'dealertype', label: 'dealerDeliveryReport.dealerType', type: 'select', option: config.constants.DEALER_TYPE, value:'', advanceSearch : false },
                // {name: 'quickfilter', label: 'dealerDeliveryReport.filterType', type: 'select', option: config.constants.DEALER_REPORT_QUICKFILTER, value:'', advanceSearch : true },
                // {name: 'premium_only', label: 'dealerDeliveryReport.premium', type: 'checkbox', value: '', advanceSearch : true },
                {name: 'search', label: 'dealerDeliveryReport.search', type: 'button' },
                // {name: 'reset', label: 'dealerDeliveryReport.search', type: 'button' },
            ],
            tableHeaders: [
                {key:'dealerDeliveryReport.cluster'},
                {key:'dealerDeliveryReport.city'},
                {key:'dealerDeliveryReport.dealerId'},
                {key:'dealerDeliveryReport.gcdCode'},
                {key:'dealerDeliveryReport.dealer'},
                {key:'dealerDeliveryReport.dealerType'},
                {key:'dealerDeliveryReport.dealerStatus'},
                {key:'dealerDeliveryReport.totalCar'},
                // {key:'dealerDeliveryReport.organicLead&50km'},
                {key:'dealerDeliveryReport.organicLead50km'},
                {key:'dealerDeliveryReport.backend'},
                {key:'dealerDeliveryReport.total'},
                {key:'dealerDeliveryReport.walkinsScheduled'},
                {key:'dealerDeliveryReport.walkinsDone'},
                {key:'dealerDeliveryReport.booked'},
                {key:'dealerDeliveryReport.conversions'},
                {key:'dealerDeliveryReport.classfiedCount'}
                // {key:'dealerDeliveryReport.8km'},
                // {key:'dealerDeliveryReport.priority'},
            ],
            pagination: [],
            loading: false,
            importingData: false,
            importDataBtn: true,
            formAction:'',
            cachedFilterFields:[],
            cachedPostData:{},
            isCsvDownload:false
        }
        // this.handleChangetext = this.handleChangetext.bind(this);
        this.child = React.createRef();
    }

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    /**
     * Update function on click
     */
    updateConversion = async (data) => {
        this.props.actions.updateConversion(data).then(resp=>{
            if(resp.status === 200 && this._isMounted){
                toast.success('dealerDeliveryReport.conversion_updated_successfully');
            }
        }); //CALL ACTION TO UPDATE DATA
    }

    // importXls = async (data) => {
        
    //     let postData = {};

    //     this.state.filterFieldsData.forEach(el => {
    //         postData[el.name] = el.value;
    //     });
    //     if(this._isMounted) await this.setState({importingData: true});
    //     this.props.actions.importXls(postData).then(res=>{
    //         if(this._isMounted) this.setState({importingData: false});
    //         toast.success('dealerDeliveryReport.conversion_imported_successfully');
    //     });
    // }

    validateFilterObj = async (data) => {
        
        let isSearchFormValid = true;
        if(new Date(data.startDate).getTime() > new Date(data.endDate).getTime()){
            isSearchFormValid=false;
            toast.error(this.props.t('search.validation.end_date_should_be_greater_than_start_date'));
        }

        await Object.keys(data).forEach(el=> {
                    if(data[el] && data[el]['value']){
                        data[el] = data[el]['value']
                    }else if(data[el] && Array.isArray(data[el])){
                        data[el] = data[el].map(elm=>{ return elm.value});
                    }else{
                        data[el] = data[el]
                    }
            });
        
        if(this._isMounted) await this.setState({filterData:data, loading: (isSearchFormValid) ? true : false});
        return isSearchFormValid;
    }

    handleRequestData = async (data) => {
        let postData = await this.validateFilterObj(data);
        let page = data.page_number;

        if(data['action'] && data['action'] === 'resetAll'){
            await this.setState({formAction:'resetAll', loading: false,leadList: [],page: page, pagination: {}, totalLengthWithoutPagination:  0, runPaginationFunc: false});
            return;
        }

        if(postData && this._isMounted){
            let cachedFilterFields=[];
            this.state.filterFieldsData.forEach(el=>{
                cachedFilterFields.push({...el, value: data[el.name]});
            })

            await this.setState({loading: true,dealerDeliveryList: [],page: page, pagination: {}, totalLengthWithoutPagination:  0, runPaginationFunc: false});
            this.props.actions.getDealerDeliveryReport(this.state.filterData).then(searchResult=>{
                //if(this._isMounted) 
                this.setState({cachedPostData: Object.assign({}, data), cachedFilterFields, formAction:'',loading: searchResult.loading || false, dealerDeliveryList: searchResult.data || [], pagination: searchResult.pagination || {}, totalLengthWithoutPagination: ((searchResult['pagination'] && searchResult['pagination']['total']) || 0), runPaginationFunc: true}); 
            }).catch(err=>{
                //if(this._isMounted) 
                this.setState({cachedPostData: Object.assign({}, data), cachedFilterFields, loading:  false,dealerDeliveryList: [], pagination:  {}, totalLengthWithoutPagination: 0, runPaginationFunc: false});
            });
        }
        
    }


    //HANDLE PAGINATE DATA FLAG RECIEVED FROM FilterTable SEND IT TO FilterBox COMPONENT
    callbackFunction = async (childData) => {
        if(this._isMounted) await this.setState({runPaginationFunc: childData['runPaginationFunc']});
    }

    /**
     * Save email and Filter data
     */
    saveEmailAndFilters = async (params) => {
        this.props.actions.saveEmailAndFilters(params).then(result=>{
            toast.success(result.message);            
        }).catch(err=>{
        });
    }

    importXls = async () => {

        let postData = this.state.cachedPostData;

        if(postData['page_number']){
            delete postData['page_number'];
        }
        
        
        if(this._isMounted) await this.setState({importingData: true});
        
        this.props.actions.importXls(postData).then(res=>{
            if(this._isMounted) this.setState({importingData: false});
            if(res && res.data){
                // let host = (config.API_URL.GETWAY_API).replace('ub/','');
                const link = document.createElement('a');
                link.href = `${res.data}`;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                toast.success(res.message);
            }
            

        });
    }

    reportDetail = async(arg) => {
        let postData = {...this.state.filterData, ...arg};
        postData.page_name = "dealerdeliverypage";
        if(this._isMounted) await this.setState({isCsvDownload: true});

        this.props.actions.exportData(postData).then(res => {
            if(this._isMounted) this.setState({isCsvDownload: false});

            if(res && res.data){
                // let host = (config.API_URL.GETWAY_API).replace('ub/','');
                const link = document.createElement('a');
                link.href = `${res.data}`;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    }


    render() {
        const { cachedFilterFields, totalLengthWithoutPagination, dealerDeliveryList, tableHeaders, filterFieldsData,page, pagination , loading, importingData, runPaginationFunc, formAction, importDataBtn, isCsvDownload} = this.state;        
        return (
            <div className="container-fluid">
                <div>
                <h1>{this.props.t('dealerDeliveryReport.title')}</h1>
                {/* <div className="savefilterwrap">                      
                        
                        {(this.state.CsvJsonData && this.state.CsvJsonData.length) ?          
                        <button type="button" className="btn btn-default btn-sm">
                        <CSVLink
                            data={this.state.CsvJsonData}
                            filename={"dealer_delivery-report.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            >{this.props.t('dealer_delivery.exportCsv')}
                        </CSVLink>
                        </button> :''}
                    </div> */}
                    
                    <FilterBox filterBox={{filterFields: filterFieldsData, showAdvanceSearchToggle: false, page: page,runPaginationFunc: runPaginationFunc, loading: loading, 'tableData': dealerDeliveryList} } action='dealerDeliveryReport' searchResults={this.handleSearchedData} sendRequestDataToParent={this.handleRequestData} />
                    <div className="result-wrap">
                        <FilterTable filterTableData={{filterFields: cachedFilterFields, formAction: formAction, importDataBtn: importDataBtn,title:'dealerDeliveryReport.title', loading: loading,page: page, importingData: importingData, 'totalLengthWithoutPagination': totalLengthWithoutPagination, 'tableData': dealerDeliveryList, 'tableHeaders': tableHeaders, action:'dealerDeliveryReport', pagination: pagination, runPaginationFunc: runPaginationFunc, isCsvDownload: isCsvDownload }} parentCallback={this.callbackFunction} saveEmailAndFilters={this.saveEmailAndFilters} importExcelFile={this.importXls} exportCsv={this.reportDetail}/>
                    </div>
                    {/* parentCallback={this.callbackFunction} updateConversionData={this.updateConversion} importExcelFile={this.importXls} */}
                    
                </div>
            </div>)
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => {
    return {
            actions: {
                
                getDealerDeliveryReport: bindActionCreators(
                    DealerActions.getDealerDeliveryReport,
                    dispatch
                ),
                saveEmailAndFilters: bindActionCreators(
                    DealerActions.saveEmailAndFilters,
                    dispatch
                ),
                importXls: bindActionCreators(
                    DealerActions.importDealerDeliveryCsv,
                    dispatch
                ),
                exportData: bindActionCreators(
                    LeadActions.downloadReport,
                    dispatch
                ) 
            }
            }
}

export default withTranslation('lead') (withRouter(connect(mapStateToProps, mapDispatchToProps)(DealerDeliveryList)));