import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import UserService from './../../../../service/userService';
import DateFormat from 'dateformat';
import _  from 'lodash';

class SearchResult extends Component {
    userData=[]
    constructor(props) {
        super(props);
        this.state = {
            userListData: props.userListData,
            loading: false,
            pageloading: false,
            statusClassname: {
                'active': 'nav-item active',
                'inactive': 'nav-item',
                'incomplete': 'nav-item',
                'blacklist': 'nav-item',
                'all': 'nav-item'
            },
            filterData: {
            },
            usersRecord:[],
            currentIndex: null,
            current_org_name: null
        };
        this.updateStatus = this.updateStatus.bind(this)
    }
    // componentWillReceiveProps(nextProps) {
    //     this.setState({
            // userListData: nextProps.userListData,
            // loading: nextProps.loading,
            // pageloading: nextProps.pageloading
    //     });
    // }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {
            userListData: nextProps.userListData,
            loading: nextProps.loading,
            pageloading: nextProps.pageloading
        };

        return updatedStateData;
    }

    componentDidMount = async () => {
        await this.getUserList();
    }

    updateStatus(userData){
        if (typeof this.props.onUpdateStatus === 'function') {
            this.props.onUpdateStatus(userData);
        }

    }

    getUserName = (userData,keyToMatch,keyToReturn) => {
        const user = _.find(this.state.usersRecord,{'id':userData[keyToMatch]});
        if(user)
            return user[keyToReturn]
        else
            return null;
    }

    getUserList() {
        var thisObj = this;
        UserService.getAllUserList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                        thisObj.setState({ usersRecord: response.data.data });
                    
                } else {
                        thisObj.setState({ usersRecord: []});
                    
                }
            }
        })
        .catch(function (response) {
            
        });
    }
    
    render() {
        return (
            <div className="table-responsive">
                    <table className="table table-bordered table-hover table-striped table-category">
                        <thead>
                            <tr>
                                <th>{this.props.t('List.details.userName') }</th>
                                <th>{this.props.t('List.details.name') }</th>
                                <th>{this.props.t('List.details.email') }</th>
                                <th>{this.props.t('List.details.roleName') }</th>
                                <th>{this.props.t('List.details.createdDate') }</th>
                                <th>{this.props.t('List.details.createdBy') }</th>
                                <th>{this.props.t('List.details.updatedDate') }</th>
                                <th>{this.props.t('List.details.updatedBy') }</th>
                                <th>{this.props.t('List.details.status') }</th>
                                <th>{this.props.t('List.details.action') }</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(this.state.userListData.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="8"><h6 className="text-center text-danger text-bold">{this.props.t('List.searchUser.NoRecordFound')}</h6></td></tr> : null}
                            {(this.state.userListData && this.state.userListData.length !== 0) && this.state.userListData.map((userdata, k) =>
                            <tr key={k}>
                                <td>{userdata.username}</td>
                                <td>{userdata.name}</td>
                                <td>{userdata.email}</td>
                                <td>{userdata.role_name || ''}</td>
                                <td>{DateFormat(userdata.created_at,"dd/mm/yyyy")}</td>
                                <td>{this.getUserName(userdata,'added_by','username')}</td>
                                <td>{(userdata.updated_at)? (DateFormat(userdata.updated_at,"dd/mm/yyyy")): ''}</td>
                                <td>{userdata.admin_name}</td>
                                <td>
                                    <label className="switch-btn btn btn-link ml-5" htmlFor={"active"+userdata.id} >
                                        <input checked={(userdata.status === "1") ? true : false} className="switch-btn" id={"active"+userdata.id} value="open" name={"active"+userdata.id} type="checkbox"  onChange={this.updateStatus.bind(this,userdata)}  />
                                        <div className="slider round"></div>
                                        <span className="switch-label"></span>
                                        {(userdata.status === "1") ? this.props.t('formData.active'):this.props.t('formData.inactive')}
                                    </label>
                                </td>
                                <td>
                                    <div className="btn-group">
                                    <Link to={`/edit-user/${userdata.user_id_hash}`} className="btn btn-default"><i className="ic-createmode_editedit mrg-r5"></i></Link>
                                    </div>
                                </td>
                            </tr>
                            )}
                            {
                                (this.state.loading) ? <tr><td className="loading" colSpan="8"></td></tr> : null
                            }
                            
                        </tbody>
                    </table>
                </div>
                
            
        )
    }
}

export default withTranslation('user')(SearchResult);
