import React, { Component } from 'react';
// import FullTimeline from './FullTimeline';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as helper from '../../config/helper';
import { default as config } from '../../config/config';
import { bindActionCreators } from 'redux';
import { LeadActions } from "../../store/action";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import InputField from '../elements/InputField';
import Select from 'react-select';
import { toast } from 'react-toastify';
import CheckBox from './../elements/CheckBox';
import LeadService from '../../service/leadService'


class LeadFeedback extends Component {
    constructor(props) {
        super(props);
        
    
        this.state = {
            formData: {
                walkInDate: '',
                comments:'',
                negativeFeedback:[],
                positiveFeedback:[],
                customOffer:'',
                walkinAskingPrice: '',
                purchaseDate:'',
                purchaseAmount:'',
                conversionSource:'',
                find_car:'',
                noInformation: 0
            },
            walkinFeedback:[],
            showTab1: false,
            mannualCarAdd: false,
            inventoryStockList:[],
            availableModelsList:[],
            onlyShowTab1:true,
            getSavedPurchaseInfo:{},
            tabFirst: true,
            tabSecond: false
      };
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};
        if(nextProps.walkinFeedback && nextProps.walkinFeedback.data.length !== prevState.walkinFeedback.length){
            updatedStateData['walkinFeedback'] = nextProps.walkinFeedback.data;
        }
        if(nextProps.selectedLeadForPopup && nextProps.selectedLeadForPopup.status_id && nextProps.selectedLeadForPopup.sub_status_id){
            if(+nextProps.selectedLeadForPopup.status_id === 6 && +nextProps.selectedLeadForPopup.sub_status_id === 12){
                updatedStateData['onlyShowTab1'] = false;
            }
        }
        return updatedStateData;

    }


    componentDidMount = async () => {
        await this.props.actions.getWalkinFeedback();
        await this.listCarsByDealer();
        await this.getWalkinPurchaseInfo();
    }

    getWalkinPurchaseInfo = async () => {
        let postData = {};

        postData['lead_id'] = (this.props && this.props['selectedLeadForPopup']) ? this.props['selectedLeadForPopup']['id'] : '';

        let response=await LeadService.getWalkinPurchaseData(postData);
        if(response.data && response.data.status === 200){
                this.setState({getSavedPurchaseInfo: response.data.data || {}, formData: {...this.state.formData, noInformation: ((response.data.data && response.data.data.noInformation) || false )}});
        }
    }
    
    
    listCarsByDealer = () => {
        
        let leadsCars = [];
        
        const {leadsCarList, leadDetails} = this.props;
        const {formData} = this.state;
        
        if(leadsCarList && leadsCarList.length){
            leadsCars = leadsCarList.map(el=> el.car_id);
        }
        
        // this.props.actions.getStockListByIds({page:1, dealer_ids:[6736]/*[leadDetails[0]['dealer_id']]*/ ,stock_used_car_id_arr: [478758, 483530, 483532, 483510, 450686]}).then(list=>{
            this.props.actions.getStockListByIds({page:1, /*dealer_ids: [leadDetails[0]['dealer_id']]*/stock_used_car_id_arr: leadsCars}).then(list=>{
            
            if(list.status === 200){
                let assingnedStockList = [];
                list['data'].forEach((element, key) => {
                    assingnedStockList[key] = {value: element.id, label: element.make+ ' '+ element.modelVersion+ ' | '+ element.uc_colour+' | '+element.km_driven+' | '+element.make_year}
                    if(leadDetails[0]['car_id'] === element.id) {
                        this.setState({formData: {...formData, 'find_car':assingnedStockList[key]}});
                    }
                });
                this.setState({availableModelsList: assingnedStockList})
            }
        });
    }

    
    handleChange = (name, value="", label=null) => async e => {
        let updateState = this.state.formData;
        let val = '';
        if(name === 'noInformation'){
            val = !this.state.formData.noInformation;
            document.getElementById("customOffer").disabled = false;
            this.setState({getSavedPurchaseInfo: {...this.state.getSavedPurchaseInfo, noInformation: val}})
            if(val){
                document.getElementById("customOffer").disabled = true;
            }
        }else if(name.indexOf('Date') !== -1 || name.indexOf('date') !== -1){
            val = e ? await helper.dateFormat(e) : '';
        }else if(name === 'negativeFeedback' || name === 'positiveFeedback'){
            val = (this.state.formData[name] && this.state.formData[name].length) ? this.state.formData[name] : [];
            let comments = this.state.formData['comments'] || '';
            
            if(val.includes(value)){
                val =  val.filter(item => item !== value);
                comments = comments.replace(label+',', '');
            }else{
                val = [...val, value];
                comments += label+', ';

            }
            updateState['comments'] = comments.trim();
        }else if(name === 'find_car'){
            val =  e;
        }else if(name === 'conversionSource' && e){
            val =  e.value;
            updateState['dc_user_type'] = e.label;

        }else if(e && typeof e.value !== 'undefined'){
            val =  e.value;
        }else if(e && typeof e.target.value !== 'undefined'){
            val =  e.target.value;
        }

        updateState[name] = val;
        this.setState({formData: updateState});

        
    }

    onTabChange = async (type) => {
        let updatedLeadCar = this.props.selectedLeadForPopup;
        let { formData } = this.state;
        if(+formData.walkinAskingPrice < +formData.customOffer){
            type = '';
            toast.error('Customer Offer is greater then Dealer Asking Price');
         }
            updatedLeadCar['walkingDetails']={
                'walkin_datetime' : this.state.formData.walkInDate
            };
        // this.props.updatedLeadCarData = 
        
        this.props.updatedLeadCarFromFeedbackPopup(updatedLeadCar);
        
        await this.setState({showTab1: (type === 'next') ? true : false, tabFirst: (type === 'next') ? false : true, tabSecond: (type === 'next') ? true : false});
    }

    saveWalkinPurchaseInfo = async () => {
        let { formData } = this.state;        
        let errors = {};

        if(formData['walkInDate'] === ''){
            errors['walkInDate'] = 'Please select Walkin date';
        }
        //COMMENTED FOR GIN-1680
        // if(+formData.walkinAskingPrice < +formData.customOffer){            
        //     errors['customOffer'] = 'Customer Offer is greater then Dealer Asking Price';
        // }
        if(!formData['negativeFeedback'].length && !formData['positiveFeedback'].length){
            errors['feedback'] = 'Please select feedback';
        }


        //COMMENTED FOR GIN-1680
        // if(this.props.selectedLeadForPopup.sub_status_id !== 26 && this.props.selectedLeadForPopup.sub_status_id !== 38){
            // if(formData['conversionSource'] === ''){
                // errors['conversionSource'] = 'Please select conversion source';
            // }
            // if(purchaseDate < walkinDate){
            //     errors['purchaseDate'] = 'Purchase date cannot be less than walkin date';
            // }
        // }

        if(Object.keys(errors).length){
            let msg = '';
            msg = Object.keys(errors).map(el=> errors[el]);
                toast.error(msg.join(", "));
        }else{
            formData['purchase_samecar'] = 1;
            formData['rtype'] = 'walkin_purchase';
            formData['dealer_id'] = (this.props && this.props['selectedLeadForPopup']) ? this.props['selectedLeadForPopup']['dealer_id'] : '';
            formData['lead_car_id'] = (this.props && this.props['selectedLeadForPopup']) ? this.props['selectedLeadForPopup']['id'] : '';
            formData['lead_id'] = (this.props && this.props['selectedLeadForPopup']) ? this.props['selectedLeadForPopup']['lead_id'] : '';
            formData['walkin_dealer_name'] = (this.props && this.props['leadDetails']) ? this.props['leadDetails'][0]['organization'] : '';
            formData['status_id'] = (this.props && this.props['selectedLeadForPopup']) ? this.props['selectedLeadForPopup']['status_id'] : '';
            formData['sub_status_id'] = (this.props && this.props['selectedLeadForPopup']) ? this.props['selectedLeadForPopup']['sub_status_id'] : '';
            formData['find_car'] = formData['find_car']['value'] || this.props['leadDetails'][0]['car_id'];

            this.props.actions.saveWalkinPurchaseInfo(formData).then(res=>{
                if(res.status===200){
                    toast.success(res.message);
                    this.saveWalkinPurchaseInfoTab1Only();
                }
                this.props.modalClose();
            });
        }

    }

    saveWalkinPurchaseInfoTab1Only = () => {
        // if(this.state.onlyShowTab1){
        //     this.props.modalClose();
        // }

        let updatedLeadCar = this.props.selectedLeadForPopup;
            updatedLeadCar['walkingDetails']={
                'walkin_datetime' : this.state.formData.walkInDate
            };
        // this.props.updatedLeadCarData = 
        
        this.props.updatedLeadCarFromFeedbackPopup(updatedLeadCar);
    }

    render() {        
        const {walkinFeedback , formData, showTab1, getSavedPurchaseInfo, availableModelsList, tabFirst, tabSecond} = this.state;
        return (
            <div>
                <div className="row">
                        <div className="col-md-12 feedback-newdeisgn">
                            <div className="clearfix">
                                <ul className="list-inline nav-tabs walkin-tab " role="tablist" id="my_tabs">
                                    <li role="presentation" className={`nav-item ${tabFirst ? 'active':''} first-tab`}><a href="#walkin" aria-controls="walkin" role="tab" data-toggle="tab" onClick={()=> this.onTabChange('prev')}>1. Walk-In Feedback</a>
                                        <span className="done-ico"></span>
                                    </li>
                                    { this.props.selectedLeadForPopup.sub_status_id === 26 ? '' :
                                    <li role="presentation" className={`nav-item ${tabSecond ? 'active':''} second-tab ${tabFirst ? 'disabled':''}`}><a className="second-tab-anchor" href="#conversion" aria-controls="conversion" role="tab" data-toggle="tab"  >2. Conversion Details</a></li>
                                    }
                                </ul>
                                <div className="tab-content mrg-20">

                                    {
                                        !showTab1
                                        ?
                                            <div role="tabpanel" className="tab-pane active" id="walkin">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label htmlFor="" className="">Walk In Date</label>
                                                    <div className="row ">
                                                        <div className="col-xs-12">
                                                            <div className="date-option input-group date input-append">
                                                            <DatePicker 
                                                                className="form-control"
                                                                onChange={this.handleChange('walkInDate')}
                                                                selected={formData['walkInDate'] ? new Date(formData['walkInDate']) : null}
                                                                placeholderText="Enter Walk-in Date"
                                                                dateFormat={'yyyy-MM-dd'}
                                                                // startDate={el.startDate || null}
                                                                // endDate={el.endDate || null}
                                                                maxDate={new Date()}
                                                                
                                                                // minDate={el.minDate || null}
                                                            />
                                                            {/* <span className="reshedule-calendar-icon" style="z-index: 9;right: 5px;"></span> */}
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="" className="">Customer Offer</label>
                                                    <div className="row ">
                                                        <div className="col-xs-12 relative">
                                                            {/* <input className="form-control" name="walkin_offer_price" onKeyPress="return isNumberKey(event)" id="walkin_offer_price" type="text" placeholder=" Enter Customer Offer" /> */}
                                                            <InputField
                                                                inputProps={{
                                                                    id: 'customOffer',
                                                                    type: "text",
                                                                    placeholder: 'Enter custom offer',
                                                                    name: 'customOffer',
                                                                    label_before_input: 'no',
                                                                    disabled: (getSavedPurchaseInfo && getSavedPurchaseInfo.noInformation) || false
                                                                }}
                                                                onChange={this.handleChange('customOffer')}
                                                            /> 
                                                            {/* <span>&#8377;</span> */}
                                                        </div>
                                                        <span className="walkin-error-msg" /*style="color:red;font-size: 12px;line-height: 15px;padding: 5px 15px;width: 100%;float: left;"*/></span>
                                                    </div>
                                                    {/* <input onClick="CustomerOffer()" type="checkbox" name="no_information" id="no_information" defaultValue="1" className="walkin_feedback_sl no_info"  /> */}
                                                    {/* <InputField
                                                            inputProps={{
                                                                id: 'noInformation',
                                                                type: "text",
                                                                // placeholder: 'Enter custom offer',
                                                                name: 'noInformation',
                                                                // label: this.props.t(el.label),
                                                                // value: el.value,
                                                                // disabled: el.disabled || false
                                                            }}
                                                            onChange={this.handleChange('noInformation')}
                                                        />  */}
                                                        { <CheckBox type="checkbox" label="Didn't share any offer" onChange={ this.handleChange('noInformation') }  name={'noInformation'} id={'noInformation'} checked={((getSavedPurchaseInfo && getSavedPurchaseInfo.noInformation)) || false}></CheckBox> }
                                                     <label htmlFor="no_information" className="check-align">
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="" className="">Dealer Asking Price</label>
                                                    <div className="row ">
                                                        <div className="col-xs-12 relative">
                                                            {/* <input className="form-control" name="walkinAskingPrice" onKeyPress="return isNumberKey(event)" id="walkin_asking_price" type="text" placeholder="Enter Asking Price" /> */}
                                                            <InputField
                                                                    inputProps={{
                                                                        id: 'walkinAskingPrice',
                                                                        type: "text",
                                                                        placeholder: 'Enter asking price',
                                                                        name: 'walkinAskingPrice',
                                                                        label_before_input: 'no'
                                                                        // label: this.props.t(el.label),
                                                                        // value: el.value,
                                                                        // disabled: el.disabled || false
                                                                    }}
                                                                    onChange={this.handleChange('walkinAskingPrice')}
                                                            /> 
                                                            {/* <span>&#8377;</span> */}
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h6 className="walkin-head">Ask customer what happened during walk-in</h6>
                                                </div>
                                            </div>
                                            <div className="row mrg-20">
                                                <div className="col-md-6">
                                                <div className="">
                                                    <span className="pos"></span>
                                                    <h6 className="walkin-head text-success">Positive</h6>
                                                    <div className="pos-box">
                                                        {
                                                            walkinFeedback.length && walkinFeedback.map((wf,key)=>{
                                                                if(+wf['walkin_feedback_point'] === 1 || +wf['id'] === 8){  
                                                                    let selectedPoint = (formData['positiveFeedback'].includes(wf['id'])) ? 'selectedFeedback' : '';
                                                                    return (<span className="button-checkbox" key={key}>
                                                                        <button type="button" className={"btn btn-success-outline btn-sm mrg-r5 mrg-b5 my-chk dealer_feeback_button_" +wf['id'] + " " + selectedPoint } data-color="primary"  htmlFor={"dealer_feeback_"+wf['id'] } onClick={this.handleChange('positiveFeedback', wf['id'],wf['walkin_feedback'] )} /*onClick="prefilledComment('<?php echo $objFeedback['walkin_feedback']; ?>')"*/>{wf['walkin_feedback']}</button>
                                                                        <input type="checkbox"  name="walkin_dealer_msg_id[]" id={"dealer_feebacks_"+wf['id'] } defaultValue={ wf['id'] } data-walkin={ wf['walkin_feedback'] } className="walkin_feedback_sl walkin-checkbox hidden"/>
                                                                    </span>)
                                                                }
                                                                return null;
                                                            })
                                                        }
                                                        
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                <div className="">
                                                    <span className="neg"></span>
                                                    <h6 className="walkin-head text-danger">Negative</h6>
                                                    <div className="pos-box">
                                                        {
                                                            walkinFeedback.length && walkinFeedback.map((wf,key)=>{
                                                            if((['-1','0'].includes((wf['walkin_feedback_point']).toString())) && +wf['id'] !== 8) {  
                                                                let selectedPoint = (formData['negativeFeedback'].includes(wf['id'])) ? 'selectedFeedback' : '';
                                                                return(<span className="button-checkbox" key={key}>
                                                                <button type="button" className={"btn btn-danger-outline btn-sm  mrg-r5 mrg-b5 my-chk dealer_feeback_button_" +wf['id'] + " "+ selectedPoint } data-color="primary"  htmlFor={"dealer_feeback_"+wf['id'] } onClick={this.handleChange('negativeFeedback', wf['id'], wf['walkin_feedback'])} /*onClick="prefilledComment('<?php echo $objFeedback['walkin_feedback']; ?>')"*/>{wf['walkin_feedback']}</button>
                                                                <input type="checkbox"  name="walkin_dealer_msg_id[]" id={"dealer_feebacks_"+wf['id'] } defaultValue={ wf['id'] } data-walkin={ wf['walkin_feedback'] } className="walkin_feedback_sl walkin-checkbox hidden"/>
                                                            </span>)
                                                            }
                                                            return null;
                                                            })
                                                        }
                                                        
                                                    </div>
                                                    </div>
                                                </div>
                                                
                                                <input type="hidden" defaultValue="" id="typedhiddednfield" />
                                            </div>
                                            <div className="row ">
                                                <div className="col-md-12">
                                                    <span className="walkin-head">Additional Comments</span>
                                                </div>
                                                <div className="col-md-12 form-group walkin_dealer_feedback_container_<?php echo $recList['id']; ?>">
                                                    <textarea className="form-control" value={formData['comments']} name="walkin_feedback" id="walkin_feedback" placeholder="Comments" rows="1" onChange={this.handleChange('comments')} ></textarea>
                                                </div>
                                            </div>
                                            <div className="row" id="">
                                                <div className="col-md-12">
                                                    <div className="text-center">
                                                        <input type="hidden" name="wp_lead_car_id" id="wp_lead_car_id" />
                                                        <input type="hidden" id="dealerId" defaultValue="" />
                                                        <input type="hidden" id="sub_status"  name="sub_status" defaultValue="" />
                                                        <input type="hidden" id="walkin_dealer_name"  name="walkin_dealer_name" defaultValue="" />
                                                        {
                                                            showTab1 || this.props.selectedLeadForPopup.sub_status_id === 26
                                                            ?
                                                            <button type="button" className="btn btn-primary btn-save btn-save1 walkinSaveBtn" onClick={()=> this.saveWalkinPurchaseInfo()}>SAVE</button>
                                                            :
                                                            <button type="button" className="btn btn-primary btn-tab-nxt btnNext" onClick={()=> this.onTabChange('next')}>NEXT</button>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        :

                                        <div role="tabpanel" className="" id="conversion" >
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label htmlFor="" className="">Purchase Date</label>
                                                    <div className="row ">
                                                        <div className="col-xs-12">
                                                            <DatePicker 
                                                                className="form-control"
                                                                onChange={this.handleChange('purchaseDate')}
                                                                selected={formData['purchaseDate'] ? new Date(formData['purchaseDate']) : null}
                                                                placeholderText="Enter purchase Date"
                                                                dateFormat={'yyyy-MM-dd'}
                                                                
                                                                // startDate={el.startDate || null}
                                                                // endDate={el.endDate || null}
                                                                // maxDate={el.maxDate || null}
                                                                // minDate={el.minDate || null}
                                                            />
                                                            {/* <input className="form-control pad-L10  purchase_date dateTimeCalender" name="purchase_date" id="purchase_date" type="text" placeholder="Enter Purchase date" style="background-color: white;cursor: pointer"/> */}
                                                            <span className="reshedule-calendar-icon"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="" className="">Purchase Amount</label>
                                                    <div className="row ">
                                                        <div className="col-xs-12 relative">
                                                            {/* <input className="form-control" name="purchase_carprice" onKeyPress="return isNumberKey(event)" id="purchase_carprice" type="text" placeholder=" Enter Purchase Amount" /> */}
                                                            <InputField
                                                                    inputProps={{
                                                                        id: 'purchaseAmount',
                                                                        type: "text",
                                                                        placeholder: 'Enter purchase amount',
                                                                        name: 'purchaseAmount',
                                                                        label_before_input: 'no',
                                                                        // label: this.props.t(el.label),
                                                                        // disabled: el.disabled || false
                                                                    }}
                                                                    onChange={this.handleChange('purchaseAmount')}
                                                            />
                                                            {/* <span>&#8377;</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="" className="">Conversion Source</label>
                                                    <div className="row ">
                                                        <div className="col-xs-12">
                                                            {/* <select id="conversion_source" name="conversion_source"  className="form-control"> */}
                                                                {/* <option defaultValue="">Select Conversion Source</option> */}
                                                            {/* $convSourceOpt; */}
                                                            {/* </select> */}
                                                            <Select
                                                                id={'conversionSource'}
                                                                // value={el.value}
                                                                onChange={this.handleChange('conversionSource')}
                                                                options={config.constants.conversionSource}
                                                                
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>





                                            <div className="assign-box-main">
                                                <div className="row  search-box">
                                                    <div className="col-md-12">
                                                        <span className="walkin-head walkin-head-search"></span>
                                                    </div>

                                                    <div className="col-xs-12 form-group ">
                                                    <Select
                                                        id={'find_car'}
                                                        value={formData['find_car']}
                                                        onChange={this.handleChange('find_car')}
                                                        options={availableModelsList}
                                                        placeholder="Search by Brand, Model or Registration Number"
                                                    />
                                                        {/* <input type="text" className="form-control" placeholder="Search by Brand, Model or Registration Number" id="filter_car" name="filter_car" defaultValue="" /> */}
                                                    </div>
                                                </div>
                                                <div className="assign-box dealer_stock">
                                                            <div className="text-center" id="">
                                                                <button type="button" className="btn btn-primary  savePurchase walkinSaveBtn" onClick={this.saveWalkinPurchaseInfo} >SAVE</button>
                                                            </div>

                                                </div>
                                    
                                            </div>
                                        </div>
                                    }


                                    

                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                
            </div>
            
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    walkinFeedback: state.lead.walkinFeedback,
    inventoryStockList: state.inventory.inventoryList,
    leadsCarList:state.lead.leadsCarList
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            getWalkinFeedback:bindActionCreators(
                LeadActions.getWalkinFeedbackList,
                dispatch
            ),
            getStockListByIds: bindActionCreators(
                LeadActions.getStockListByIds,
                dispatch
            ),
            saveWalkinPurchaseInfo: bindActionCreators(
                LeadActions.saveWalkinPurchaseInfo,
                dispatch
            )
        }
    }
}

export default withTranslation('lead') (withRouter(connect(mapStateToProps,mapDispatchToProps)(LeadFeedback)));
