import React, { Component } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import DateFormat from 'dateformat';
import { bindActionCreators } from 'redux';
import { LeadActions } from "../../../store/action";

const searchby = [
    { value: '1', label: 'Agent' },
    { value: '2', label: 'Dealer' },
    { value: '3', label: 'Customer' },
    // { value: '4', label: 'DC' },
    { value: '5', label: 'BM' },
    { value: '6', label: 'System' },
  ];
class FullTimeline extends Component {
    _isMount = false;
    constructor(props) {
        super(props);
        this.state = { 
            selectedOption: null,
            date: new Date(),
            advanceSearchEnable: false,
            statusList:[],
            dealerList:[],
            timelineDetails:[],
            searchedTmelineDetails : [],
            searchableOptions : {},
            leadDetails:{},
            selectedDealer:'',
            loading: false
        }; 
    }

    getSearchedData = (timelineDetails = [],searchData = {}) => {
        timelineDetails = (timelineDetails && timelineDetails.length>0)?timelineDetails:this.state.timelineDetails
        let searchedDetails = [...timelineDetails];
        //////searching starts here///////////
        for(let i of searchedDetails){
            if(i.added_by>0 && +i.status_added_by_system !== 1 && (i.dc_user_type === '' || i.dc_user_type === '0')){
                i.type = 1
            }
            else if(i.dc_user_id>0 && +i.status_added_by_system !== 1 && (i.dc_user_type && i.dc_user_type.toUpperCase() === "DEALER")){
                i.type = 2;
            }else if((i.dc_user_id>0 && +i.status_added_by_system !== 1 && (i.dc_user_type && i.dc_user_type.toUpperCase() === "DC"))){
                i.type = 4;
            }else if((i.dc_user_id>0 && +i.status_added_by_system !== 1 && (i.dc_user_type && i.dc_user_type.toUpperCase() === "BM"))){
                i.type = 5;
            }else if(+i.added_by === -1 || (+i.added_by > -1 && +i.status_added_by_system === 1) ){
                i.type = 6;
            }else{ //customer
                i.type = 3
            }
        }
        if(searchData.hasOwnProperty('searchBy') && searchData['searchBy']){
            switch(+searchData['searchBy'].value){
                case 1:
                    ///agent timelime
                    searchedDetails = searchedDetails.filter(e=>e.type === 1)
                    break;
                case 2:
                    //dealer
                    searchedDetails = searchedDetails.filter(e=>e.type === 2 )
                    break
                case 3:
                    ///consumer
                    searchedDetails = searchedDetails.filter(e=>e.type === 3 )
                    break;
                case 4:
                    ////dc timeline
                    searchedDetails = searchedDetails.filter(e=>e.type === 4 )
                    break
                case 5:
                    searchedDetails = searchedDetails.filter(e=>e.type === 5 )
                    break;
                case 6:
                    searchedDetails = searchedDetails.filter(e=>e.type === 6 )
                    break;
                default:

            }
            
        }
        if(searchData.hasOwnProperty('status') && searchData['status']){
            searchedDetails = searchedDetails.filter(e=>+e.status_id === +searchData['status'].value)
        }

        if(searchData.hasOwnProperty('dealer') && searchData['dealer']){
            searchedDetails = searchedDetails.filter(e=>+e.dealer_id === +searchData['dealer'].value)
        }

        if(this._isMount) this.setState({searchedTmelineDetails:searchedDetails})
    }
    handleChange = (selectedKey,event) => { 
        let {searchableOptions, timelineDetails} = this.state;
        searchableOptions[selectedKey] = event;
        this.getSearchedData(timelineDetails,searchableOptions);
        if(this._isMount) this.setState({searchableOptions:searchableOptions});
        
    };

    componentDidMount = async() => {
        this._isMount = true;
        const {statusList, dealerList, leadDetails, leadsCarList} = this.props;
        
        let status = await this.getOptionVal(statusList,'status');

        let dealer = [];
        // let selectedDealer = {};
        let dealersLists = [];
        let dealersIDs = [];

        if(dealerList && dealerList['data']){
            if(leadsCarList && leadsCarList.length){
                dealersIDs = leadsCarList.map(car=> car.dealer_id);
                  dealerList['data'].forEach(el=>{
                      if(dealersIDs.includes(el.id)) dealersLists.push(el);    
                    });
    
                dealer = await this.getOptionVal(dealersLists,'dealer');
            }
        } 
        
        if(leadDetails && Object.keys(leadDetails).length){
            this.getTimelineData(leadDetails[0]['lead_id_hash']);
        }
        if(this._isMount) this.setState({statusList:status,dealerList:dealer})
    }

    getTimelineData = async (leadId, selectedDealer=[]) => {
        let params = {
            lead_id:leadId
        }
        if(this._isMount) await this.setState({loading:true});

        let timelineData = await this.props.actions.getTimelineDetails(params)
        if(timelineData && this._isMount){
            let filtersData = timelineData;
            if(selectedDealer && selectedDealer.length){
                filtersData = timelineData.filter(e=>+e.dealer_id === +selectedDealer[0].value) 
            }

            await this.setState({loading:false, searchedTmelineDetails:filtersData, timelineDetails: timelineData});
        }else{
            if(this._isMount) await this.setState({loading:false});
        }
    }

    componentWillUnmount = () => {
        this._isMount = false;
    }

    getOptionVal = (list,key) =>{
        const arrObj = [];
        _.forEach(list,(data) => {
            let obj={}
            obj.value = data.id
            obj.label = (key === 'dealer') ? data.organization : data.name
            arrObj.push(obj)
        })
        return arrObj;
    }
    
    
    render() {
      const { statusList,dealerList,searchedTmelineDetails} = this.state;
        return (
            <div>
                <div className="mrg-b30">
                    <div className="row">
                        <div className="col-sm-4">
                            <label>{this.props.t('timeline.searchBy')}</label>
                            <Select
                                id="searchBy"
                                name="searchBy"
                                onChange={this.handleChange.bind(this,'searchBy')}
                                options={searchby}
                                isClearable
                            />
                        </div>
                        <div className="col-sm-4">
                            <label>{this.props.t('timeline.status')}</label>
                            <Select
                                id="status"
                                name="status"
                                onChange={this.handleChange.bind(this,'status')}
                                options={statusList}
                                isClearable
                            />
                        </div>
                        <div className="col-sm-4">
                            <label>{this.props.t('timeline.dealer')}</label>
                            <Select
                                id="dealer"
                                name="dealer"
                                onChange={this.handleChange.bind(this,'dealer')}
                                options={dealerList}
                                isClearable
                            />
                        </div>
                    </div>
                        
                </div>
                <ul className="timeline large-timeline">
                {(searchedTmelineDetails.length === 0 && !this.state.loading) ? <li align="center"><h6 className="text-center text-danger text-bold">{this.props.t('search.NoRecordFound')}</h6></li> : null}
                {(searchedTmelineDetails && searchedTmelineDetails.length !== 0) && searchedTmelineDetails.map((tlData, k) => 
                    <li key={k}>
                        <div className="timeline-badge">
                        {(tlData.icon && tlData.icon === 'user')?<i className="ic-person text-primary"></i> :''}
                        {(tlData.icon && tlData.icon === 'call')?<i className="ic-call text-primary"></i> :''}
                        {(tlData.icon && tlData.icon === 'computer')?<i className="ic-desktop_windows text-primary"></i> :''}
                        </div>
                        <div className="timeline-panel">
                            <div className="timeline-heading border-BL clearfix">
                                <div className=" edit-secL width20">
                                    <h4 className="timeline-title">  {(tlData.status_name || '')+' '+ (tlData.sub_status_name || '')+' '}{(tlData.calling_status_name) ? tlData.calling_status_name : ''}{(tlData.commentedDealerName) ? tlData.commentedDealerName : ''} </h4>
                                </div>
                                <div className="edit-secR">
                                    <p dangerouslySetInnerHTML={{ __html:tlData.comment}}></p>
                                    <p className="listingdate">{this.props.t('timeline.addedOn')} {(tlData.added_on) ? (DateFormat(tlData.added_on,"dd-mm-yyyy")+' '+ DateFormat(tlData.added_on,"HH:MM:ss")):''} {(+tlData.status_added_by_system === 1)? ' by System': ((tlData.commentedUserName) ? ' by '+tlData.commentedUserName : '')}</p>
                                </div>   
                                              
                                {(tlData.due_date && +tlData.status_id !== 7 && DateFormat(tlData.due_date,"yyyy") !== 1980) ?<div className="edit-secR20 text-bold f12">{this.props.t('timeline.followUpOn')}<br/>{(DateFormat(tlData.due_date,"dd-mm-yyyy")+' '+ DateFormat(tlData.due_date,"HH:MM:ss"))}</div>:''}
                            </div>
                        </div>
                    </li>
                    )}
                    {
                        (this.state.loading) ? <li className="loading"></li> : null
                    }
                </ul>
            </div>
            
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    statusList:state.lead.statusListData,
    dealerList: state.dealer.dealerListData,
    timelineDetails: state.lead.timelineDetails,
    leadDetails: state.lead.listLead,
    leadsCarList:state.lead.leadsCarList,

    
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            getTimelineDetails:bindActionCreators(
                LeadActions.getTimelineDetails,
                dispatch
            ),
        }
    }
}

export default  withTranslation('lead')(connect(mapStateToProps, mapDispatchToProps)(FullTimeline));
