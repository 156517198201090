import React, { Component } from 'react';
import DateFormat from 'dateformat';
import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { default as config } from '../../../config/config';
import { NavLink } from "react-router-dom";
import InputField from '../../elements/InputField';
import Select from 'react-select';
import * as helper from '../../../config/helper';
import _ from 'lodash';
import ToolTip from './../../elements/Tooltip';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import AddLeadsDialer from './../../popup/AddLeadsDialer';
import ModalPopup from './../../elements/Popup';


class FilterTable extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            tableHeaders: [],
            isUnblock: false,
            page: 0,
            runPaginationFunc: false,
            totalLengthWithoutPagination: 0,
            action: '',
            loading: false,
            importingData: false,
            importDataBtn: false,
            title: '',
            dealerDetailPopOver:{
                'show':false,
                'id':0
            },
            addToDialerPleaseWait: false,
            showDialerLeadsModal: false,
            filterFields:{},
            addToDialerResp: {},
            showAddtoDialerBtn: false,
            showCsvDownloadBtn: false,
            dealerDeliveryEmail:'',
            dataSortObj:[],
            isCsvDownload:false
        }
    }

    unblockLead = async el => {

        if(this._isMounted) await this.setState({ isUnblock: true });

        let cnrm = confirm(this.props.t('blockingLead.are_you_sure_you_want_to_unblock') + " " + el.name + "?");
        if (cnrm) {
            const arrayCopy = this.state.tableData.map((row) => {
                if (row.id === el.id) {
                    row['isBlocking'] = true
                }
                return row;
            });

            if(this._isMounted) await this.setState({ tableData: arrayCopy });

            let unblockStatus = await this.props.actions.unblockLead(el);

            if (unblockStatus.status === 200) {
                toast.success('Lead unblocked successfully');
                const arrayCopy = this.state.tableData.filter((row) => row.id !== el.id );
                
                if(this._isMounted) await this.setState({ isUnblock: false, tableData: arrayCopy, refreshLead: true });
            }
        }
    }

    componentDidMount = () => {
        this._isMounted = true;
        //SHOW ADD TO DIALER BUTTON FOR ADMIN ONLY
        if(this.props.auth && this.props.auth.authUser && this.props.auth.authUser.authUser && this.props.auth.authUser.authUser.role_id === 5){
            this.setState({showAddtoDialerBtn: true, showCsvDownloadBtn: true});
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }


    //STATIC METHOD TO SENT DATA TO PARENT AFTER SUCCESSFULL STATE UPDATE
    static updateProps = (props, params) => {
        props.parentCallback(params);
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const updatedData = { 'loading': false };
        //IF PAGE 0 MEANS INITIAL CALL & AFTER IF runPaginationFunc IS TRUE UPDATE TABLE
        if((nextProps.filterTableData.formAction && nextProps.filterTableData.formAction === 'resetAll') || (nextProps.filterTableData.page === 1 && !nextProps.filterTableData.tableData.length)){
            updatedData['tableData'] = [];     
            updatedData['totalLengthWithoutPagination'] = 0;
            if(nextProps.filterTableData.formAction && nextProps.filterTableData.formAction === 'resetAll') {
                updatedData['dataSortObj'] = [];
            }
        }
        // CHECK IF DATA IS AVAILABLE FOR TABLE
        
        if ( (  ( nextProps.filterTableData.runPaginationFunc))  && (prevState.tableData.length !== nextProps.filterTableData.totalLengthWithoutPagination || nextProps.filterTableData.action === "leadControlPanel")) {
            updatedData['title'] = nextProps.filterTableData.title;

            if(prevState.refreshLead){ //FROM BLOCKED LEADS COMPONENT IF TRUE THEN RETURN PREV STATE
                updatedData['tableData'] = prevState.tableData;
            }else{
                //UPDATE TABLE DATA
                if(nextProps.filterTableData.page === 0 && !nextProps.filterTableData) {
                    updatedData['tableData'] = [];
                }else if((nextProps.filterTableData.page === 0 || nextProps.filterTableData.page === 1) && nextProps.filterTableData.tableData) {
                    updatedData['tableData'] = nextProps.filterTableData.tableData;
                }
                else{ //UPDATE IF NEW DATA AVAILABLE
                    updatedData['tableData'] = [...prevState.tableData, ...nextProps.filterTableData.tableData];
                }

                updatedData['page'] = nextProps.filterTableData.page;
            }
            //SEND runPaginationFunc VALUE FALSE TO PARENT WHICH WILL COMMUNICATE WITH FILTERBOX COMPONENT TO RESET THIS VALUE
            updatedData['updateProps'] = FilterTable.updateProps(nextProps, {runPaginationFunc: false});
            updatedData['runPaginationFunc'] = false;
            updatedData['totalLengthWithoutPagination'] = (prevState.refreshLead) ?(nextProps.filterTableData.totalLengthWithoutPagination - 1): nextProps.filterTableData.totalLengthWithoutPagination;
        }
        updatedData['importDataBtn'] = nextProps.filterTableData.importDataBtn;
       

        if (nextProps.filterTableData.loading) {
            updatedData['loading'] = true;
        }

        if (nextProps.filterTableData && nextProps.filterTableData.tableHeaders.length !== prevState.tableHeaders.length && !prevState.isUnblock) {
            updatedData['tableHeaders'] = nextProps.filterTableData.tableHeaders;
            updatedData['action'] = nextProps.filterTableData.action;
        }

        if (prevState.importingData !== nextProps.filterTableData.importingData) {
            updatedData['importingData'] = nextProps.filterTableData.importingData;
        }

        if (prevState.isCsvDownload !== nextProps.filterTableData.isCsvDownload) {
            updatedData['isCsvDownload'] = nextProps.filterTableData.isCsvDownload;
        }

        if (prevState.addToDialerPleaseWait !== nextProps.filterTableData.addToDialerPleaseWait) {
            updatedData['addToDialerPleaseWait'] = nextProps.filterTableData.addToDialerPleaseWait;
        }
        if (nextProps.filterTableData && nextProps.filterTableData.filterFields ) {
            updatedData['filterFields'] = nextProps.filterTableData.filterFields;
        }
        
        if (nextProps.filterTableData.addToDialerResp && Object.keys(prevState.addToDialerResp).length !== Object.keys(nextProps.filterTableData.addToDialerResp).length) {
            updatedData['addToDialerResp'] = nextProps.filterTableData.addToDialerResp;
        }
        if (Object.keys(updatedData).length) {
            return (updatedData)
        } else {
            return null;
        }
    }

    handleChange = (param, row, index) => async e => {

        let val = (param.indexOf('date') !== -1) ? await helper.dateFormat(e) : ((e.value) ? e : e.target.value);
        const updateState = this.state.tableData;

        updateState[index][param] = val;

        if(this._isMounted) await this.setState({ tableData: updateState });
    }

    // handleChangeEmail = (name) =>async e => {
    handleChangeEmail = async (e) => {
            await this.setState({dealerDeliveryEmail: e.target.value});

    }

    updateData = async (type, index) => {
        if (type === 'conversion') {
            let fetchRow = this.state.tableData[index];

            this.props.updateConversionData(fetchRow);
        }
    }

    importXls = async (type) => {

        this.props.importExcelFile();
    }

    /**
     * Inline edit toggle
     */
    inlineEditOption = async (type, index) => {
        let tableData = this.state.tableData;

        tableData[index]['inlineEdit'] = type;
        if(this._isMounted) await this.setState({ tableData: tableData });

    };

    /**
     * Inline Update
     * @data = data to post
     */
    saveInlineData = async (data, index) => {
        if (typeof index !== 'undefined') {
            let tableData = this.state.tableData;
            data.is_boost = (data.is_boost === '1') ? '0' : '1';
            tableData[index]['is_boost'] = data.is_boost;
            if(this._isMounted) await this.setState({ tableData: tableData });
        }
        this.props.updateInline(data);
    }
    getSourceImage = (source_id) => {
        const sourceImageArr = config.constants.sourceImageArr;

        if (sourceImageArr.hasOwnProperty(+source_id)) {
            return sourceImageArr[source_id]
        }
        return '';
    }

    pricepopShow(id){
        const {dealerDetailPopOver} = this.state;
        if(dealerDetailPopOver.id === id)
            dealerDetailPopOver.show = !dealerDetailPopOver.show;
        else{
            dealerDetailPopOver.show = !dealerDetailPopOver.show; 
            dealerDetailPopOver.id = id;
        }
        this.setState({dealerDetailPopOver})
    }

    addToDialer = async () => {
        await this.setState({addToDialerResp:{}})
        if(this.state.tableData.length){
            this.setState({showDialerLeadsModal: true})
        }else{
            toast.error(this.props.t('search.validation.no_leads_selected_to_add_to_dialer'));
        }
    }

    addEmail = async () => {
        if(this.state.tableData.length){
            this.setState({showCSVdialerModal: true})
        }else{
            toast.error(this.props.t('search.validation.no_record_found_for_csv_file_generation'));
        }
    }


    addToDialerSubmit = () => {
        this.props.addToDialer()
    }

    closeDialerLeadsModal = () => {
        this.setState({ showDialerLeadsModal: false });
        this.props.resetDialerResponse();
    }

    closeDealerDeliveryModal = () => {
        this.setState({dealerDeliveryEmail: '', showCSVdialerModal: false });
        //this.props.resetDialerResponse();
    }

    saveDealerDeliveryFilter = async () => {
        const {filterFields, dealerDeliveryEmail} = this.state;
        let sendMailParams = {email:'', params:{}};
        let emailRegex = new RegExp(/\S+@\S+\.\S+/);
        if(!emailRegex.test(dealerDeliveryEmail)){
            toast.error(this.props.t('addLead.email_invalid'));
        }else if(dealerDeliveryEmail && dealerDeliveryEmail.trim() !== ''){
            sendMailParams['email'] = dealerDeliveryEmail;
            filterFields.forEach(function (el) {  if(typeof el['value']!== 'undefined') { sendMailParams['params'][el['name']] =  (Array.isArray(el['value']) || typeof el['value'] !== 'object') ? el['value'] : (typeof el['value'] === 'object' ? el['value']['value'] : el['value']); }  } );
            sendMailParams['params'] = (Object.keys(sendMailParams['params']).length) ? JSON.stringify(sendMailParams['params']) : '';  
            this.props.saveEmailAndFilters(sendMailParams);
            this.closeDealerDeliveryModal();
        }else{
            toast.error(this.props.t('search.validation.email_is_required'));
        }
    }

    sortData = async(sortKey, page) => { 
        let { dataSortObj } = this.state;
        if(this.state.tableData && this.state.tableData.length){
            let findPrevSort = (dataSortObj).filter(el=> el.sort_by === sortKey );

            let updateSort = {};
            let updatedSorting = [];
            if(page === 'leadFinder') {
                updateSort['sort_by'] = sortKey;
                updateSort['sort_type'] = 'DESC';

                if(findPrevSort.length){
                    updateSort['sort_type'] = (findPrevSort[0]['sort_type'] === 'ASC') ? 'DESC' : 'ASC';
                }
                updatedSorting = [updateSort]
            }

            await this.setState({dataSortObj:updatedSorting, tableData: []});

            this.props.updateDataBySort(this.state.dataSortObj);
        }
    }

    getSortClass = (param) => {
        let { dataSortObj } = this.state;
        let findPrevSort = (dataSortObj).filter(el=> el.sort_by === param.sortKey );
        let classname = 'sort-by';
        if(findPrevSort && findPrevSort.length){
            if(findPrevSort[0]['sort_type'] === 'DESC'){
                classname = 'sort-by-desc';
            }else{
                classname = 'sort-by-asc';
            }
        }
        return classname
    }

    dealerReportLeadHref = (el) => {
        let { filterFields } = this.state;
        let searchString = '/leads-list?';
        let searchParams = {};

        filterFields.forEach(data=>{
            
            if(data['name'] === "cluster" && data['value']){
                searchParams['cluster'] = data['value'];
            }
            if(data['name'] === "city" && data['value']){
                searchParams['city'] = data['value'];

            }
            if(data['name'] === 'dealer' && data['value']){
                searchParams['did'] = data['value'];

            }
            if(data['name'] === 'created_from_date' && data['value']){
                searchParams['carEnquiryFrom'] = data['value'];

            }
            if(data['name'] === 'created_to_date' && data['value']){
                searchParams['carEnquiryTo'] = data['value'];

            }
            if(data['name'] === "priority" && data['value']){
                searchParams['priority'] = data['value'];

            }

        });

        if(Object.keys(searchParams).length){
            searchString += new URLSearchParams(searchParams).toString();
            if(!searchParams['did']){
                searchString += '&did='+el.id;   
            }
        }else{
            searchString += 'did='+el.id;
        }


        return searchString;
    }

    reportDetail = async (argument) => {        
        if(!this.state.isCsvDownload){
            this.props.exportCsv(argument); 
            this.setState({isCsvDownload: true})
        }
            
    }

    render() {
        let spanLinkCls = 'span-link';
        const { showCsvDownloadBtn, filterFields, tableData, tableHeaders, action, loading, importingData, importDataBtn,totalLengthWithoutPagination, addToDialerResp } = this.state;
        return (
            <div className="result-wrap">
                <div className="card">
                    <div className="card-heading">
                        <div className="row">
                            <div className="col-sm-8 col-md-6">
                                {/* <h2 className="card-title pad-t7">{((action === 'dealerDeliveryReport') ? tableData.length :totalLengthWithoutPagination) + this.props.t('results_found')}</h2> */}
                                <h2 className="card-title pad-t7">{((action === 'leadControlPanel') ? tableData.length : (totalLengthWithoutPagination||tableData.length||0)) + this.props.t('results_found')}</h2>
                            </div>
                            <div className="col-md-offset-2 col-sm-4 col-md-4 text-right" >
                                {   
                                    (importDataBtn && showCsvDownloadBtn)
                                        ?
                                        (!importingData
                                            ?
                                                !loading
                                                ?
                                                    <button  onClick={() => this.importXls(action)} className="btn btn-success mrgin-r-10"  >{this.props.t('blockingLead.export_csv')}</button>
                                                :
                                                    <button   className="btn btn-success mrgin-r-10"  disabled>{this.props.t('blockingLead.export_csv')}</button>
                                            :
                                            <button  className="btn btn-success mrgin-r-10"  disabled >{this.props.t('please_wait')}</button>
                                        )
                                        :
                                        ''

                                }
                            {/* </div>
                            <div className=" col-md-2 text-right"> */}
                            
                            {/* ADD TO DIALER COMMENTED BUTTON, RUNNING FROM NEW CRON */}
                                {/* {
                                    (action === 'leadFinder' && showAddtoDialerBtn)
                                    ?
                                        !addToDialerPleaseWait
                                        ?
                                            loading
                                            ?
                                                <button className="btn btn-success" disabled>{this.props.t('add_to_dialer')}</button>
                                            :
                                                <a className="btn btn-success"  onClick={this.addToDialer}>{this.props.t('add_to_dialer')}</a>
                                        :
                                        <button className="btn btn-success" disabled>{this.props.t('please_wait')}</button>

                                    :
                                        ''

                                } */}
                                
                                {/* {
                                    (action === 'dealerDeliveryReport')
                                    ?
                                        !addToDialerPleaseWait
                                        ?
                                            loading
                                            ?
                                                <button className="btn btn-success" disabled>{this.props.t('dealerDeliveryReport.send_csv')}</button>
                                            :
                                                <a className="btn btn-success"  onClick={this.addEmail}>{this.props.t('dealerDeliveryReport.send_csv')}</a>
                                        :
                                        <button className="btn btn-success" disabled>{this.props.t('please_wait')}</button>

                                    :
                                        ''

                                } */}
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover table-striped table-category">
                            <thead>
                                <tr>
                                    {
                                        tableHeaders.map(el => {
                                            return (<th key={el.key} width={el.width}>
                                                {
                                                    el.sort
                                                    ?
                                                    <a  className={this.getSortClass(el)} onClick={()=>this.sortData(el.sortKey, action)}>{this.props.t(el.key)}</a>
                                                    :
                                                    this.props.t(el.key)
                                                }
                                                </th>)
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    tableData.length
                                        ?
                                        tableData.map((el, key) => {
                                            if (action === 'blockingLeads') {
                                                return (<tr key={key} style={{display:el.display}}>
                                                    <td>
                                                        <br />{el.customer_mobile}
                                                        <div>{this.props.t('blockingLead.lead_id')}: <NavLink to={"/lead-details/" + el.lead_id_hash} >{el.lead_id}</NavLink></div>
                                                    </td>
                                                    <td>{config.constants.blockReasonMapping.map(elm => { return ('' + elm.value === el.blocked_reason) ? elm.label : '' })}
                                                        {(el.lead_comment) ? ' | ' + el.lead_comment : ''}
                                                    </td>
                                                    <td>{DateFormat(el.created_date, 'dd/mm/yyyy, h:MM:ss TT')}</td>
                                                    <td>{(el.name) ? el.name : ''}</td>
                                                    <td>
                                                        {
                                                            !el.isBlocking
                                                                ?
                                                                <button type="button" className="btn btn-default" onClick={() => this.unblockLead(el)} title={this.props.t('blockingLead.unblock')}>{this.props.t('blockingLead.unblock')}</button>
                                                                :
                                                                <button type="button" className="btn btn-default" title={this.props.t('blockingLead.unblocking')}>{this.props.t('blockingLead.unblocking')}</button>
                                                        }
                                                    </td>
                                                </tr>)
                                            } else if (action === 'conversionPanel') {
                                                let addedOn = new Date(el.added_on).getTime();
                                                let updatedOn = new Date(el.updated_date).getTime();
                                                let tat = helper.timeDifference(updatedOn, addedOn);

                                                // let cnfmStatus = '';
                                                if (typeof el.cnfm_status !== 'undefined') {
                                                    // el.cnfm_status = _.find(config.constants.conversionPanelStatus, (st) => {return (st.value === el.cnfm_status)});
                                                    tableData[key]['cnfm_status'] = _.find(config.constants.conversionPanelStatus, (st) => {
                                                        return (typeof el.cnfm_status === 'object' ? st.value === (el.cnfm_status['value']) : st.value === (el.cnfm_status).toString());
                                                    });
                                                }

                                                return (<tr key={key}>
                                                    <td>{el.cluster}</td>
                                                    <td>{el.name}
                                                        <br />{el.customer_mobile}
                                                        <div>{this.props.t('blockingLead.lead_id')}: <NavLink to={"/lead-details/" + el.lead_id_hash} >{el.leadId}</NavLink></div>
                                                    </td>
                                                    <td>{el.car_make ? el.car_make : ''}<br />{el.car_model ? el.car_model : ''} {el.car_variant ? el.car_variant : ''}</td>
                                                    <td>{el.dealer}</td>
                                                    <td>{DateFormat(el.added_on, 'dd/mm/yyyy, h:MM:ss TT')}</td>
                                                    <td>{el.name}</td>
                                                    <td>{(tat.year && tat.year + ((tat.year === 1) ? ' year' : ' years')) || (tat.month && tat.month + ((tat.year === 1) ? ' month' : ' months')) || (tat.week && tat.week + ((tat.year === 1) ? ' week' : ' weeks')) || (tat.day && tat.day + ((tat.year === 1) ? ' day' : ' days')) || ''}</td>
                                                    <td>
                                                        <InputField
                                                            inputProps={{
                                                                id: "regno-" + key,
                                                                type: "text",
                                                                placeholder: "Reg no",
                                                                name: "Regno",
                                                                value: el.registration_no
                                                            }}
                                                            onChange={this.handleChange('registration_no', el, key)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Select
                                                            id=''
                                                            value={el.cnfm_status}
                                                            onChange={this.handleChange('cnfm_status', el, key)}
                                                            options={config.constants.conversionPanelStatus}
                                                        />
                                                    </td>

                                                    <td>
                                                        <textarea className="form-control" onChange={this.handleChange('cnfm_comment', el, key)} value={el.cnfm_comment}>{el.cnfm_comment}</textarea>
                                                    </td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button className="btn btn-default" title={this.props.t('conversionPanel.save')} onClick={(e) => { this.updateData('conversion', key) }} ><i className="ic-save icons-normal"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>)
                                            } else if (action === 'leadFinder') {
                                                return (<tr key={key}>
                                                    <td><a href={`/lead-details/${el.lead_id_hash}`} target="_blank" title="Lead ID">{el.id}</a></td>
                                                    <td>
                                                        <div id="customer_name">
                                                            <strong className="mrg-r5">{el.customer_name}</strong>
                                                            {/* //////////source id mapped with image  */}
                                                            {
                                                                (this.getSourceImage(el.source_id))
                                                                ?
                                                                    <img src={this.getSourceImage(el.source_id)} width="25px" height="10x" className={el.source_id===369?'fb-img':''} alt={this.getSourceImage(el.source_id)} />
                                                                :
                                                                    ''
                                                            }
                                                            {+el.is_premium === 1 && <ToolTip title="Premium Lead" ><span className="label green-status mrg-r5">PR</span></ToolTip>}
                                                            {/* <i className="OTO source-icon"></i> */}
                                                        </div>
                                                        <div className="" id="customer_email">{el.customer_mobile}</div>
                                                        <div className="listingdate">{DateFormat(el.created_at, config.constants.dateFormatDDMMMYYYYHIS)}</div>
                                                    </td>
                                                    <td><strong>{(el.make_name || '') + ' ' + (el.model_name || '')}</strong><br /> {(el.budget && el.budget>0) ? config.constants.currencySymbol + ' ' + Number(el.budget).toLocaleString("in-ID") : ''}</td>
                                                    <td>{(el.user_type && el.user_type === 'D') ? el.customer_city_name : (el.reg_car_city_name || '')}</td>
                                                    <td>
                                                        <div className="mrg-B5">{
                                                            el.status_name
                                                        }</div>
                                                        <div className="mrg-B5">{
                                                            el.sub_status_name
                                                        }</div>
                                                        <div className="mrg-B5">{
                                                            el.call_status_name
                                                        }</div>
                                                    </td>
                                                    <td>{el.status_id !== 7 ? DateFormat(el.due_date, config.constants.dateFormatDDMMMYYYYHI) : ''}</td>
                                                    <td>{DateFormat(el.updated_at, config.constants.dateFormatDDMMMYYYYHI)}</td>
                                                    <td>{el.modified_by || ''}</td>
                                                    <td>{(el.score) ? parseFloat(el.score).toFixed(2) : 0}</td>
                                                </tr>)
                                                // )}
                                            } else if (action === 'leadControlPanel') {

                                                return (<tr key={key}>
                                                    <td>{el.organization} <br></br>{el.gcd_code}
                                                        {/* <div>Gowa</div> */}
                                                    </td>
                                                    <td>
                                                        {el.inlineEdit ?
                                                            <div>
                                                                <Select
                                                                    id='priority'
                                                                    value={el.priorityObj}
                                                                    onChange={this.handleChange('priorityObj', el, key)}
                                                                    options={config.constants.DEALER_TYPE_PRIORITY}
                                                                />
                                                            </div>
                                                            :
                                                            (el.priorityObj ? el.priorityObj['label'] : '')
                                                        }
                                                    </td>
                                                    <td>
                                                        {el.inlineEdit ?
                                                            <div>
                                                                <Select
                                                                    id='boost_active'
                                                                    value={el.boost_active_obj}
                                                                    onChange={this.handleChange('boost_active_obj', el, key)}
                                                                    options={config.constants.boostStatus}
                                                                />
                                                            </div>
                                                            :
                                                            (el.boost_active_obj ? el.boost_active_obj['label'] : '')
                                                        }
                                                    </td>
                                                    <td>
                                                        {el.inlineEdit ?
                                                            <div>
                                                                {this.props.t('leadControl.boost_target_ftd')} <input name="boost_target_ftd" type="number" id="btf-1" value={el.boost_target_ftd || 0} className="form-control" onChange={this.handleChange('boost_target_ftd', el, key)} />
                                                                {this.props.t('leadControl.boost_end_ftd')} <DatePicker
                                                                    name="bef"
                                                                    className="form-control"
                                                                    onChange={this.handleChange('end_date', el, key)}
                                                                    value={el.end_date}

                                                                />
                                                                {this.props.t('leadControl.after_boost_ftd')} <input name="after_boost_ftd" type="number" id="abf-1" value={el.after_boost_ftd || 0} className="form-control" onChange={this.handleChange('after_boost_ftd', el, key)} />
                                                            </div>
                                                            :
                                                            <div>
                                                                <p>{this.props.t('leadControl.boost_target_ftd') + ': ' + (el.boost_target_ftd || 0)}</p>
                                                                <p>{this.props.t('leadControl.boost_end_ftd') + ': ' + (el.end_date || '')}</p>
                                                                <p>{this.props.t('leadControl.after_boost_ftd') + ': ' + (el.after_boost_ftd || 0)}</p>
                                                            </div>
                                                        }
                                                    </td>

                                                    <td>{el.achieved_ftd}</td>
                                                    <td>{el.fulfillment_status}</td>
                                                    <td>
                                                        {el.inlineEdit ?
                                                            <div >
                                                                {/* <select name="boost" id="boost-1" className="form-control">
                                                            <option value="1">Yes</option>
                                                            <option value="2">No</option>
                                                        </select> */}

                                                                <Select
                                                                    id='is_sent_leads'
                                                                    value={el.is_sent_leads_obj}
                                                                    onChange={this.handleChange('is_sent_leads_obj', el, key)}
                                                                    options={config.constants.sendBackendLeads}
                                                                />
                                                            </div>
                                                            :
                                                            (el.is_sent_leads_obj ? el.is_sent_leads_obj['label'] : '')
                                                        }
                                                    </td>
                                                    <td>
                                                        {el.inlineEdit ?
                                                            <div className="btn-group mrg-r5">
                                                                <button className="btn btn-default btn-sm" onClick={() => this.saveInlineData(el)} title="Save"><i className="ic-save"></i></button>
                                                                <button className="btn btn-default btn-sm" title="Close" onClick={() => this.inlineEditOption(false, key)} ><i className="ic-clearclose"></i></button>
                                                            </div>
                                                            :
                                                            <div className="btn-group mrg-r5">
                                                                <button className="btn btn-default btn-sm" title="Edit" onClick={() => this.inlineEditOption(true, key)}><i className="ic-createmode_editedit"></i></button>
                                                            </div>
                                                        }
                                                        <div className="btn-group boostUp">
                                                            <button className={(el.is_boost === '1') ? 'active btn btn-default btn-sm' : "btn btn-default btn-sm"} onClick={() => this.saveInlineData({ is_boost: el.is_boost, dealer_id: el.id }, key)} title={(el.is_boost === '1') ? this.props.t('leadControl.boostDown') : this.props.t('leadControl.boostUp')}><i className="ic-bump-up"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>)

                                            }else if (action === 'dealerDeliveryReport') {                                            
                                                return (<tr key={key}>
                                                        <td>{el.cluster_name}</td>
                                                        <td>{el.city_name || ''}</td>
                                                        <td>{el.id || ''}</td>
                                                        <td>{el.gcd_code || ''}</td>
                                                        <td>{el.organization || ''} 
                                                        
                                                        <div className="price-popover" id={"newCarInfoPopup_"} ><span onClick={()=> this.pricepopShow(el.id)}><i className="ic-error"></i></span>
                                                            <div className={(this.state.dealerDetailPopOver.show && this.state.dealerDetailPopOver.id === el.id) ? "popover bs-popover-bottom " : "popover bs-popover-bottom hide"} >
                                                                <span className="arrow"></span>
                                                                <div className="popover-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 col-md-12 mrg-b15">
                                                                            <div className="dealer_detail">
                                                                                <span className="dealer-heading"><strong>Dealer Detail</strong></span><br/>
                                                                                <span>{el.organization}<br/>Mobile: {el.dealership_contact }
                                                                                {
                                                                                    el.dealership_contact 
                                                                                    ? 
                                                                                    <i className="fa fa-files-o pad-L5" aria-hidden="true" title="Copy phone number" onClick={() => {helper.copyToClipboard(el.dealership_contact)}}></i> 
                                                                                    : 
                                                                                    ''
                                                                                }
                                                                                    
                                                                                </span>                                
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        </td>
                                                        <td>{(typeof el.paid_score !== 'undefined') ? ((el.paid_score === 1) ? 'Free' : 'Paid') : ''} </td>
                                                        <td>{(''+el.status==='1') ? 'Active' : 'InActive'}</td>
                                                        <td><a  href={`/inventory?did=${el.id}`} target="_blank">{el.total_car || ''}</a></td>
                                                        <td><span className={spanLinkCls} onClick={()=>this.reportDetail({dealer:el.id, column:'organic'})}>{el.sumOrganic || ''}</span></td>
                                                        {/* <td>{el.sumOrganicGreater100 || ''}</td> */}
                                                        <td><span className={spanLinkCls} onClick={()=>this.reportDetail({dealer:el.id, column:'backend'})}>{el.sumBackend || ''}</span></td>
                                                        <td>{(el.totalLead)?<a  href={this.dealerReportLeadHref(el)} target="_blank">{el.totalLead || '' }</a>:''}</td>
                                                        <td><span className={spanLinkCls} onClick={()=>this.reportDetail({dealer:el.id, column:'walkinschedule'})}>{el.sumWalkinSchedule || ''}</span></td>
                                                        <td><span className={spanLinkCls} onClick={()=>this.reportDetail({dealer:el.id, column:'walkindone'})}>{el.sumWalkinDone || ''}</span></td>   
                                                        <td><span className={spanLinkCls} onClick={()=>this.reportDetail({dealer:el.id, column:'booked'})}>{el.sumBooked || ''}</span></td>
                                                        <td><span className={spanLinkCls} onClick={()=>this.reportDetail({dealer:el.id, column:'conversion'})}>{el.sumConversion || ''}</span></td>
                                                        <td><span className={spanLinkCls} onClick={()=>this.reportDetail({dealer:el.id, column:'classfied'})}>{el.sumClassifiedCount || ''}</span></td>
                                                        {/* <td>{el.sumDistLead + (el.sumBackend ? "("+((el['sumDistLead'] / el['sumBackend']) * 100, 2).toFixed(2) + "%)" : "") }</td>
                                                        <td>{el.priority || ''}</td> */}
                                                </tr>)
                                            } else {
                                                return ''
                                            }

                                        })
                                        :
                                        (
                                            (!tableData.length && !loading)
                                                ?
                                                <tr><td colSpan={tableHeaders.length} className="text-center">{this.props.t('search.NoRecordFound')}</td></tr>
                                                :
                                                <tr></tr>
                                        )
                                }

                            </tbody>
                        </table>
                        {
                            (loading) ? <div className="loading" ></div> : ''
                        }
                    </div>
                    {
                    !this.state.showDialerLeadsModal ? null : <ModalPopup id="leadFeedback" className="modal modal-lg" title=""  modalClose={this.closeDialerLeadsModal}>
                        <AddLeadsDialer 
                            modalData={{filterFields: filterFields, selectedLeads: totalLengthWithoutPagination, addToDialerResp: addToDialerResp}}
                            modalClose={this.closeDialerLeadsModal}
                            addToDialer={this.addToDialerSubmit}
                            // selectedLeadForPopup={this.state.selectedLeadForPopup}
                            // updatedLeadCarFromFeedbackPopup={this.updatedLeadCarFromFeedbackPopup}
                        />
                    </ModalPopup>
                    }
                    {
                        !this.state.showCSVdialerModal? null : <ModalPopup id="dealerDeliveryCSV" className="modal modal-lg" title=""  modalClose={this.closeDealerDeliveryModal}>
                         <div className="pd-15">
                                <form>
                                    <div className="form-group clearfix">

                                        <InputField
                                            inputProps={{
                                                id: 'dealerDeliveryEmail',
                                                type: "text",
                                                placeholder: 'Enter email',
                                                name: 'dealerDeliveryEmail',
                                                label_before_input: 'no',
                                                // value: this.state.dealerDeliveryEmail,

                                                // label: this.props.t(el.label),
                                                // disabled: el.disabled || false
                                            }}
                                            onChange={this.handleChangeEmail}
                                        />
                                    </div>
                                    <div className=" form-group text-right">
                                        <button type="button" name="cancel" className="btn btn-default mrg-r15" onClick={this.closeDealerDeliveryModal}>{this.props.t('recomendTab.list.cancel')}</button>
                                        <button type="button" name="submit" className="btn btn-primary" onClick={this.saveDealerDeliveryFilter}>{this.props.t('recomendTab.list.submit')}</button>
                                    </div>
                                </form>
            </div>
                    </ModalPopup>
                    }
                    

                </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
       auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            unblockLead: bindActionCreators(
                LeadActions.unblockLead,
                dispatch
            )
        }
    }
}

export default withTranslation('lead')(withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterTable)));