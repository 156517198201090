import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DealerActions } from "../../../../store/action";
import { withTranslation } from 'react-i18next';
import { default as config } from '../../../../config/config';
import FilterBox from '../../../lead/helpers/filter-box';
import FilterTable from '../../../lead/helpers/filter-table';
import { toast } from 'react-toastify';

class LeadControlPanel extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            advanceSearchEnable: false,
            totalLengthWithoutPagination: 0,
            tableData: [],
            leadControlPanelList:[],
            filterFieldsData:[
                {name: 'cluster', label: 'leadControl.cluster', multiRef: React.createRef(), type: 'multiselect', option: [], value: '', advanceSearch : false },
                {name: 'city', label: 'leadControl.city', dependent:'cluster', multiRef: React.createRef(), type: 'multiselect', option: [], value: '', advanceSearch : false },
                {name: 'dealer', label: 'leadControl.dealer', type: 'select', option: [], value: '', advanceSearch : false },
                {name: 'boostActive', label: 'leadControl.boost_active',  placeholder:'', type: 'select',option:config.constants.boostStatus, value:'', advanceSearch : false },
                {name: 'fulfillmentStatus', label: 'leadControl.fulfillment_status', type: 'select', option:config.constants.fulfilmentStatus, value:'', advanceSearch : false },
                {name: 'dealerPriorityType', label: 'dealerDeliveryReport.dealerPriority', multiRef: React.createRef(), option: config.constants.DEALER_TYPE_PRIORITY, placeholder:'search.placeholder.dealerType', value:'', type: 'multiselect', advanceSearch : false }
            ],
            tableHeaders: [
                {key:'leadControl.dealer_info', width:'15%'},
                {key:'leadControl.priority', width:'10%'},
                {key:'leadControl.boost_active', width:'10%'},
                {key:'leadControl.target_ftd' , width:'15%'},
                {key:'leadControl.achieved_ftd'},
                {key:'leadControl.fulfillment_status'},
                {key:'leadControl.send_backend_leads'},
                {key:'leadControl.action'}
            ],
            pagination: [],
            loading: false,
            importingData: false,
            filterSubmit:false,
            page: 0,
            formAction:''
        }
    }

    componentDidMount = async () => {
        this._isMounted = true;
    }

    componentWillUnmount = async () => {
        this._isMounted = false;
    }

    importXls = async (data) => {

    }

    validateData = async data => {
        await Object.keys(data).forEach(el=> {
                        if(data[el]['value']){
                            data[el] = data[el]['value']
                        }else if(Array.isArray(data[el])){
                            data[el] = data[el].map(elm=>{ return elm.value});
                        }else{
                            data[el] = data[el]
                        }
                });
                return data;
    }

    handleRequestData = async (data) => { 
        let page = data.page_number;

        if(data['action'] && data['action'] === 'resetAll' && this._isMounted){
            await this.setState({formAction:'resetAll', loading: false,leadList: [],page: page, pagination: {}, totalLengthWithoutPagination:  0, runPaginationFunc: false});
            return;
        }

        let postData = await this.validateData(data);
        if(this._isMounted) await this.setState({formAction:'',page: page,filterSubmit:true,loading: true,leadControlPanelList: [], pagination: {}, totalLengthWithoutPagination:  0,  runPaginationFunc: false});
        
        //DISPATCH ACTION
        postData = {...postData, city_ids:postData['city']}
        if(postData && this._isMounted){
            await this.setState({formAction:'',loading: true,leadControlPanelList: [],page: page, pagination: {}, totalLengthWithoutPagination:  0, runPaginationFunc: false});
            this.props.actions.onSearchSubmit(postData).then(async searchResult=>{
                if(searchResult.status===200){
                       if(this._isMounted)  await this.setState({loading:  false, leadControlPanelList: searchResult.data || [], pagination: searchResult.pagination || {}, totalLengthWithoutPagination:  ((searchResult['pagination'] && searchResult['pagination']['total']) || searchResult.data.length), runPaginationFunc: true});
                }
                
            }).catch(err=>{
                if(this._isMounted) this.setState({loading: false,leadControlPanelList: [], pagination: {}, totalLengthWithoutPagination: 0, runPaginationFunc: false});
            });
        }
        
    }

    updateTableData = async (data) => {

        let result = await this.props.actions.updateDealerBoost(data);
        if(result.status && result.status === 200){
            let leadControlPanelList = this.state.leadControlPanelList.map(el=> {
                if(el.id === data.id) { 
                    el.inlineEdit = false; 
                }
                return el;
            })
            this.setState({leadControlPanelList})
            toast.success(result.message)
        }
    }

    //HANDLE PAGINATE DATA FLAG RECIEVED FROM FilterTable SEND IT TO FilterBox COMPONENT
    callbackFunction = async (childData) => {
        if(this._isMounted) await this.setState({runPaginationFunc: childData['runPaginationFunc']});
    }

    // static getDerivedStateFromProps = (nextProps, prevState) => {
    //     let updatedStateData = {};
    //     if(nextProps.listDealerData  && nextProps.listDealerData['data'] && nextProps.listDealerData['data'].length ){
            
    //         // updatedStateData.totalLengthWithoutPagination = nextProps.listDealerData['data'].length;
            
    //     }
    //     return updatedStateData;
    // }

    render() {

        const { totalLengthWithoutPagination, leadControlPanelList, tableHeaders, filterFieldsData, pagination , loading, importingData,  page, runPaginationFunc, formAction} = this.state;
        return (
            <div className="container-fluid">
                <h1>{this.props.t('leadControl.title')}</h1>
                <FilterBox filterBox={{filterFields: filterFieldsData, showAdvanceSearchToggle: false, page: page,runPaginationFunc: runPaginationFunc, loading: loading, 'tableData': leadControlPanelList} } action='leadControlPanel' searchResults={this.handleSearchedData} sendRequestDataToParent={this.handleRequestData} />
                <div className="result-wrap">
                    <FilterTable filterTableData={{formAction: formAction, importDataBtn: false,title:'leadControl.title', loading: loading, page: page, importingData: importingData, 'totalLengthWithoutPagination': totalLengthWithoutPagination, 'tableData': leadControlPanelList, 'tableHeaders': tableHeaders, action:'leadControlPanel', pagination: pagination, runPaginationFunc:runPaginationFunc }} parentCallback={this.callbackFunction}  importExcelFile={this.importXls} updateInline={this.updateTableData}/>
                </div>

            </div>)
    }
}

const mapStateToProps = (state, ownProps) => ({
    listDealerData: state.dealer.dealerListData || {},

});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            onSearchSubmit: bindActionCreators(
                DealerActions.submitLeadControlFilter,
                dispatch
            ),
            updateDealerBoost: bindActionCreators(
                DealerActions.updateDealerBoost,
                dispatch
            )
        }
    }
}

export default  withTranslation('lead') (connect(mapStateToProps, mapDispatchToProps)(LeadControlPanel));