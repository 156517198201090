import React, { Component } from 'react';
import Select from 'react-select';
import InputField from '../../../elements/InputField';
import PrivilegeService from './../../../../service/privilegeService';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import DateFormat from 'dateformat';
import _  from 'lodash';
import { Redirect } from "react-router-dom";

class EditPrivilege extends Component {
    privilege_id;
    constructor(props) {
        super(props);
        this.state = {
            editFormData: {},
            successResponse: false,
            redirect: false,
            errors: {},
            show: false,
            loaderClass: '',
            searchParams:{},
            categoryDetails:[],
            usersRecord:[],
            redirectTolist: false,
        }
        this.privilege_id = this.props.match.params.id;
        if(this.privilege_id)
            this.getPrivilege = this.getPrivilege.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.getCategoryDetails({'status':'1'});
    }

    componentDidMount = async () => {
        if(this.privilege_id){
            let searchParams = this.state.searchParams;
            searchParams.id = this.privilege_id;
            this.setState({searchParams:searchParams})
            await this.getPrivilege();
            await this.getUserList();
        }else
            this.setState({editFormData:{}})
    }

    getPrivilege() {
        var thisObj = this;
        PrivilegeService.getPrivilegeList(this.state.searchParams)
        .then(function (response) {
            thisObj.flag = false;
            thisObj.setState({loading:false,pageloading:false})
            if (response.data.status === 200) {
                if (response.data.data.length) {
                    thisObj.setState({ editFormData: response.data.data[0] ,loading:false,pageloading:false}, () => {
                        thisObj.flag = true;
                    });
                } else {
                    thisObj.setState({ editFormData: response.data.data});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    submitForm = async (event) => {
        event.preventDefault();
        if (this.validForm()) {
            this.setState({ loaderClass : 'loading' });
            let postData = await this.filterPostData();
            if(postData.id){
                PrivilegeService.updatePrivilegeDetails(postData)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                        this.setState({ redirectTolist: true })
                    } else if (response.status === 401 && response.data.status === 401) {
                        this.setState({ redirect: true })
                    }
                })
                .catch((error) => {
                    
                });
            }else{
                PrivilegeService.savePrivilegeDetails(postData)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                        this.setState({ redirectTolist: true })
                    } else if (response.status === 401 && response.data.status === 401) {
                        this.setState({ redirect: true })
                    }
                })
                .catch((error) => {
                });
            }
        }
    }

    getCategoryDetails(params) {
        var thisObj = this;
        PrivilegeService.getCategoryList(params)
        .then(function (response) {
            if (response.data.status === 200) {
                const categoryArr = [];
                if (response.data.data.length) {
                    _.forEach((response.data.data),element => {
                        let categorybj={}
                        categorybj.value = element.id;
                        categorybj.label = element.name;
                        categoryArr.push(categorybj);
                    });
                    thisObj.setState({ categoryDetails: categoryArr });
                } else {
                    thisObj.setState({ categoryDetails: categoryArr});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    filterPostData = () => {
        let params={};
        if(this.state.editFormData.id)
            params.id = this.state.editFormData.id;
        if(this.state.editFormData.category_id)
            params.category_id = this.state.editFormData.category_id;
        if(this.state.editFormData.name)
            params.name = this.state.editFormData.name;
        if(this.state.editFormData.description)
            params.description = this.state.editFormData.description;
        if(this.state.editFormData.status)
            params.status = this.state.editFormData.status;
        if(this.state.editFormData.module)
            params.module = this.state.editFormData.module;
        if(this.state.editFormData.controller)
            params.controller = this.state.editFormData.controller;
        return params;
    }

    validForm = () => {
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        let formIsValid = true;
        if (!editFormData["name"]) {
            errors['name'] = this.props.t('validation.name_required')
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleOptionChange = (key,event) => {
        let editFormData = this.state.editFormData;
        if(key && key === 'status')
            editFormData[key] = (editFormData[key] === '1') ? '0' : '1';
        else
            editFormData[key] = event ? event.value : '';
        this.setState({editFormData:editFormData})
    };

    handleChangeEvent = event => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        editFormData[event.target.name] = event.target.value;
        if(fieldRequired === 'yes'){
            if (event.target.value !== '') {
                delete errors[event.target.name];
            } else {
                errors[event.target.name] = this.props.t('validation.'+event.target.name)+this.props.t('validation.is_required');
            }
        }
        this.setState({ editFormData: editFormData, errors: errors });
    }

    getUserName = (userData,keyToMatch,keyToReturn) => {
        const user = _.find(this.state.usersRecord,{'id':userData[keyToMatch]});
        if(user)
            return user[keyToReturn]
        else
            return null;
    }
    
    getUserList = () => {
        var thisObj = this;
        PrivilegeService.getAllUserList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                        thisObj.setState({ usersRecord: response.data.data });
                    
                } else {
                        thisObj.setState({ usersRecord: []});
                    
                }
            }
        })
        .catch(function (response) {
            
        });
    }

    
    render() {
          const {editFormData,errors,redirectTolist}   = this.state;
          if(!editFormData.hasOwnProperty("status") && !this.privilege_id)
            editFormData.status = "1";
          if (redirectTolist) return <Redirect to="/list-privilege" />;
        return (
            <div className="container-fluid">
                <h1>{(editFormData.id) ? this.props.t('formData.editPrivilege'):this.props.t('formData.addPrivilege') }</h1>                
                <div className="card">
                    <div className="card-body">
                        <form method="post" id="formDetails" onSubmit={this.submitForm}>
                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "name",
                                    type: "text",
                                    name: "name",
                                    label: this.props.t('formData.name'),
                                    value: editFormData.name || '',
                                    dataerror: errors.name || '',
                                    validationreq: "yes"
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-sm-4 form-group">
                                    <label>{this.props.t('formData.category')}</label>
                                    <Select
                                        id="category_id"
                                        options={this.state.categoryDetails}
                                        name="category_id"
                                        onChange={this.handleOptionChange.bind(this,'category_id')}
                                        value={this.state.categoryDetails.filter(({ value }) => value === editFormData.category_id)}
                                        
                                    />
                                    </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "module",
                                    type: "text",
                                    name: "module",
                                    label: this.props.t('formData.module'),
                                    value: editFormData.module || '',
                                    dataerror: errors.module || ''
                                
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "controller",
                                    type: "text",
                                    name: "controller",
                                    label: this.props.t('formData.controller'),
                                    value: editFormData.controller || '',
                                    dataerror: errors.controller || ''
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-sm-4 form-group">
                            <label>{this.props.t('formData.description')}</label>
                                    <textarea
                                        className="form-control" 
                                        type="text"
                                        name="description"
                                        id="description"
                                        value={editFormData.description || ''}
                                        onChange={this.handleChangeEvent}
                                    />
                                </div>
                            </div>
                            {(editFormData.id) ?  
                            <div className="row form-group" >
                                <ul className="maindetail-list">
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.created_date')}</div>
                                <div className="sub-value">{DateFormat(editFormData.created_at,"dd/mm/yyyy")}</div>
                                    </li>
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.created_by')}</div>
                                        <div className="sub-value">{this.getUserName(editFormData,'added_by','username')}</div>
                                    </li>
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.updated_date')}</div>
                                        <div className="sub-value">{(editFormData.updated_at) ? (DateFormat(editFormData.updated_at,"dd/mm/yyyy")) : ''}</div>
                                    </li>

                                </ul>
                            </div>
                            : ''}
                            
                            <div>
                                <label>{this.props.t('formData.status')} </label>
                                <div >
                                    
                                    <label className="switch-btn btn btn-link ml-5" htmlFor="active" >
                                        <input checked={(editFormData.status === "1") ? true : false} className="switch-btn" id="active" value="open" name="active" type="checkbox"  onChange={this.handleOptionChange.bind(this,'status')}  />
                                        <div className="slider round"></div>
                                        <span className="switch-label"></span>
                                        {(editFormData.status === "1") ? this.props.t('formData.active'):this.props.t('formData.inactive')}
                                    </label>
                                </div>
                            </div>
                           
                            <div className="row">
                                <div className=" col-sm-4 form-group text-center">
                                    <button type="submit" onClick={this.submitForm} className="btn btn-primary">{this.props.t('formData.save')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('privilege') (EditPrivilege);
