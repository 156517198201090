import React, { Component } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { default as config } from '../../../config/config';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { LeadActions } from "../../../store/action";
import { Multiselect } from 'multiselect-react-dropdown';
import { toast } from 'react-toastify'; 
class EditRequirements extends Component {
    constructor(props) {
        super(props);
      
        this.state = {
            reqform:false,
            selectedOption: null,
            statusClassname: {
              'active': 'nav-item active',
              'removed': 'nav-item',
              'all': 'nav-item'
            },
            filterData:props.filterData || {},
            formData:{},
            errors:{},
            makeList:[],
            models : [],
            selectedMake : [],
            selectedModel : [],
            cityListData: props.cityList,
            selected_city_id:''
        };
      }
    
    componentDidMount = async () => {
        let {filterData} = this.state;      
        let leadInfo = (filterData.leadDetails && filterData.leadDetails.length) ? filterData.leadDetails[0] :{}
        // let {mmvList} = this.props;
     
        if(leadInfo){
            
            let updatedLeadInfo = await this.setMultiSelectValue(leadInfo)
            this.setState({formData:updatedLeadInfo})
        }
        
        // if(mmvList && Object.keys(mmvList).length>0){
            
        //     let {make} = mmvList;
        //     make = make.map(function(e){
        //         return {label : e.make,value:e.id}
        //     })
        //     this.setState({mmvList : mmvList,makeList:make})
        // }
    }

    // componentWillReceiveProps(nextProps) {
    //     let {filterData} = nextProps;
    //     if(filterData !== this.state.filterData)
    //         this.setState({filterData})
    // }
    setMultiSelectValue = async(leadInfo) => {        
        let {mmvList:{make,model} = {}} = this.props;        
        let {filterData} = this.state;         
        let req_body_type = (leadInfo && leadInfo['req_body_type'] && filterData.bodyType && filterData.bodyType.length) ? leadInfo['req_body_type'].split(',') : []
        // leadInfo['selected_req_body_type'] = (req_body_type && req_body_type.length) ? (req_body_type && req_body_type.length && req_body_type.map(value => { console.log(value)
        //     // return _.find((filterData.bodyType),{'value': Number(value)})
        //     return (filterData.bodyType).filter(el=> (el.value === Number(value)))
        // })) : [];
        leadInfo['selected_req_body_type'] = [];
        if(req_body_type && req_body_type.length){
            let bodyTypeIds = []
            bodyTypeIds = req_body_type.map(el=> +el);

            leadInfo['selected_req_body_type'] = (filterData.bodyType).filter(el=> (bodyTypeIds.includes(el.value) ))
        }
        
        // leadInfo['selected_req_body_type'] = leadInfo['selected_req_body_type'].filter( Number ); //REMOVE UNDEFINED FROM ARRAY

        // let makeModel = (leadInfo && leadInfo['model_ids']  && filterData.makeModel && filterData.makeModel.length) ? leadInfo['model_ids'].split(',') : []
        // leadInfo['selected_model_ids'] = (makeModel && makeModel.length && makeModel.map(value => {
        //     return _.find((filterData.makeModel),{'value': Number(value)})
        // }));
        if(make && leadInfo['make_ids']){
            
            let tempMake = leadInfo['make_ids'];
            if(!(tempMake instanceof Array)) tempMake = tempMake.split(",")
            if(tempMake instanceof Array){
                tempMake = tempMake.map(e=>+e)
                tempMake = make.filter(e=>tempMake.includes(e.id)).map(function(e){
                    return {label : e.make,value : e.id}
                })
                leadInfo['selected_make'] = tempMake;
            }
            if(!(leadInfo['make_ids'] instanceof Array)) leadInfo['make_ids'] = leadInfo['make_ids'].split(",").map(e=>+e)
            /////////// set models ///////
            let dropDownModels = model.filter(e=>leadInfo['make_ids'].includes(e.mk_id)).map(function(e){
                return {label : e.m,value : e.id,mk_id:e.mk_id}
            });
            this.setState({models:dropDownModels})
        }
        if(model && leadInfo['model_ids']){
          
            let tempModel = leadInfo['model_ids'];
            if(!(tempModel instanceof Array)) tempModel = tempModel.split(",")
            if(tempModel instanceof Array){
                tempModel = tempModel.map(e=>+e)
                tempModel = model.filter(e=>tempModel.includes(e.id)).map(function(e){
                    return {label : e.m,value : e.id,mk_id:e.mk_id}
                })
                leadInfo['selected_model'] = tempModel;
            }
            if(!(leadInfo['model_ids'] instanceof Array)) leadInfo['model_ids'] = leadInfo['model_ids'].split(",").map(e=>+e)
        }
        
        // leadInfo['selected_models'] = [];
        let fuelType = (leadInfo && leadInfo['fuel'] && filterData.fuelType && filterData.fuelType.length) ? leadInfo['fuel'].split(',') : []
        leadInfo['selected_fuel'] = (fuelType && fuelType.length && fuelType.map(value => {
            return _.find((filterData.fuelType),{'value': Number(value)})
        }));
        if(leadInfo['selected_fuel'].length){
            leadInfo['selected_fuel'] = leadInfo['selected_fuel'].filter(el=> el)
        }
        let owner = (leadInfo && leadInfo['owner'] && filterData.owner && filterData.owner.length) ? leadInfo['owner'].split(',') : []
        leadInfo['selected_owner'] = (owner && owner.length && owner.map(value => {
            return _.find((filterData.owner),{'value': (value)})
        }));
        let color = (leadInfo && leadInfo['color'] && filterData.color && filterData.color.length) ? leadInfo['color'].split(',') : [];
        leadInfo['selected_color'] = (color && color.length && color.map(value => {
            return _.find((filterData.color),{'value': (value)})
        }));
       
        let cities = (filterData && filterData.agentDetails && filterData.agentDetails.city_ids) ? filterData.agentDetails.city_ids.split(',') : [];
        let city_val = cities.map(function (x) { 
            return parseInt(x, 10); 
          });  
        leadInfo['selected_city_id'] = (city_val && city_val.length && city_val.map(value => {
            return _.find((this.state.cityListData),{'value': (value)})
        }));
        leadInfo['city_ids'] = cities;
        return leadInfo;
    }

    getKeyValueFormat = async(listKey,listObj) =>{
        let respList=[]
        _.forEach(listKey,(key) =>{
            let res = _.find(listObj,{'value':Number(key)});
            if(res)
            respList.push(res);
        })
        return respList;
    }
    static getDerivedStateFromProps = async(nextProps, prevState) => {
        //console.log('-------------nextProps---------', nextProps);
        let updatedStateData = {};
        
        if(nextProps.filterData !== prevState.filterData){
            updatedStateData['filterData'] = nextProps.filterData;
        }
        // if(nextProps.mmvList != prevState.mmvList){
        //     updatedStateData['mmvList'] = nextProps.mmvList;
        // }
        // if(nextProps.makeList != prevState.makeList){
        //     updatedStateData['makeList'] = nextProps.makeList;
        // }
       // this.setState({selected_city_id: nextProps.filterData.agentDetails.city_ids})
        return updatedStateData;

    }

    handleChange = (selectedKey,event) => {
        // debugger;
        let {formData,filterData} = this.state;
        if(selectedKey === 'max_km'){
            formData['min_km']= filterData.kmRange[0].value
        }else if(selectedKey === 'max_avg_daily_travel'){
            let travel_avg = (event.value).split("-")
            formData['min_avg_daily_travel_km']= travel_avg[0]
            formData['max_avg_daily_travel_km']= travel_avg[1]
        }
        if(selectedKey !== 'max_avg_daily_travel')
            formData[selectedKey] = event ? event.value : ''; 
        this.setState({ formData:formData });
    };

    submitForm = async (e) => {
        const { filterData, formData} = this.state;
        this.setState({reqform:true});
        if(filterData.leadDetails && filterData.leadDetails[0].customer_id){
            formData.customer_id = filterData.leadDetails[0].customer_id;
            formData.lead_id = filterData.leadDetails[0].id;
        }

        let postData = await this.getPostData();
        let isFormValid = await this.validateInputField(postData);

        if(typeof this.props.submitReqForm === 'function' && isFormValid)
            this.props.submitReqForm(postData)
        
    }
    handleModalCall = (selectedItem) => {
        if(selectedItem){
            selectedItem = selectedItem.map(e=>+e)
        }
        
        let allModels = []
        let {mmvList:{model}} = this.props;
    
        if(model && model.length){
            allModels = model.filter(e=>selectedItem.includes(e.mk_id))
            allModels = allModels.map(function(e){
                return {label : e.m,value : e.id,make_id : e.mk_id}
            })
        };
        this.setState({models:allModels});
    }
    handleModelSelect = (selectedValue,makeSelected = null) => {
        // debugger;
        let {formData} = this.state;

        let {mmvList:{model:models} = {}} = this.props
        // debugger;
        let selectedModels = formData.selected_model;
        //let selectedCities = formData.selected_city_id;
        if(selectedValue){
        
            let getAllModels = models.map(function(e){
                return {label : e.m,value : e.id,mk_id:e.mk_id}
            })
            selectedValue = selectedValue.map(e=>+e);
            selectedModels = getAllModels.filter(e=>selectedValue.includes(e.value));
        }
        if(makeSelected && selectedModels && selectedModels.length){
            
            // let getAllModels = models.filter(e=>makeSelected.includes(e.mk_id)).map(e=>e.id)
            selectedModels=selectedModels.filter(e=>makeSelected.includes(e.mk_id))   
        }
         
        selectedModels = selectedModels && selectedModels.length>0?selectedModels:[]
        formData['model_ids'] = selectedModels.map(e=>e.value)
        formData['selected_model'] = selectedModels
        this.setState({formData:formData})
    }
    onSelect(key,selectedList, selectedItem) {
        
        let formData = this.state.formData;
        let existingVal = (formData[key]) ? ((typeof formData[key] === 'string') ? formData[key].split(',') : formData[key]) : []
        if(!existingVal.includes(selectedItem.value)) existingVal.push(!Number.isNaN(+selectedItem.value)?+selectedItem.value:selectedItem.value);
        formData[key] = existingVal;
        
        if(key === "make_ids"){
            this.handleModalCall(existingVal);
        }
        if(key==='model_ids'){
            this.handleModelSelect(existingVal);
         }
        //  existingVal = existingVal.join(",")

        this.setState({ formData: formData });
        
    }
     
    onRemove(key,selectedList, removedItem) {
        let formData = this.state.formData;
        let existingVal = (formData[key]) ? formData[key] : '';
        if(!(existingVal instanceof Array)) existingVal = existingVal.split(",")
        // existingVal = existingVal.split(",")

        if(Number.isNaN(+removedItem.value)) existingVal = existingVal.filter(e => (e.toString() !== (removedItem.value).toString()) ) 
        else existingVal = existingVal.filter(e => ((e).toString() !== (removedItem.value).toString()) ) 

        formData[key] = existingVal;
        if(key==='make_ids'){
            this.handleModalCall(existingVal);
            this.handleModelSelect(null,existingVal);
        }
        if(key==='model_ids'){
            this.handleModelSelect(existingVal);
        }
        
        this.setState({ formData: formData });
      
    }

    getPostData = () => {
        const {formData} = this.state;     
        let postData = {}
        if(formData.lead_id)
            postData['lead_id'] = formData.lead_id 
        if(formData.customer_id)
            postData['customer_id'] = formData.customer_id
        if(formData.min_price)
            postData['min_price'] = formData.min_price;
        if(formData.max_price)
            postData['max_price'] = formData.max_price;        
        if(formData.min_km)
            postData['min_km'] = formData.min_km;
        if(formData.model_ids && formData.model_ids.length)
            postData['model_ids'] = (formData.model_ids).toString();
        if(formData.budget)
             postData['budget'] = formData.budget;        
        if(formData.seller_type)
            postData['seller_type'] = formData.seller_type;
        if(formData.max_km)
            postData['max_km'] = formData.max_km;        
        if(formData.min_year)
            postData['min_year'] = formData.min_year;
        if(formData.max_year)
            postData['max_year'] = formData.max_year;
        if(formData.fuel && formData.fuel.length)
            postData['fuel'] = (formData.fuel).toString(); 
        if(formData.owner)
            postData['owner'] = (formData.owner).toString();
        if(formData.color)
            postData['color'] = (formData.color).toString();       
        if(formData.transmission)
            postData['transmission'] = formData.transmission;
        if(formData.purpose)
            postData['purpose'] = formData.purpose;
        if(formData.req_body_type)
            postData['req_body_type'] = (formData.req_body_type).toString();
        if(formData.drive_car)
            postData['drive_car'] = formData.drive_car;
        if(formData.min_avg_daily_travel_km)
            postData['min_avg_daily_travel_km'] = formData.min_avg_daily_travel_km;
        if(formData.max_avg_daily_travel_km)
            postData['max_avg_daily_travel_km'] = formData.max_avg_daily_travel_km;
        if(formData.seat_num)
            postData['seat_num'] = formData.seat_num;
        if(formData.buyer_type)
            postData['buyer_type'] = formData.buyer_type;
        if(formData.make_ids instanceof Array && formData.make_ids.length)
            postData['make_ids'] = formData.make_ids.join(",")
        if(formData.model_ids instanceof Array && formData.model_ids.length)
            postData['model_ids'] = formData.model_ids.join(",")
        if(formData.city_ids)
            postData['city_ids'] = formData.city_ids.join();
        return postData

    }

    validateInputField = () => {
        let {formData} = this.state;
        let isFormValid = true;
        
        if(!formData.budget){
            isFormValid=false;
            toast.error('Budget range is required.');
        }
        if(!formData.max_km){
            isFormValid=false;
            toast.error('Km range is required.');
        }
        if(!formData.min_year){
            isFormValid=false;
            toast.error('From year range is required.');
        }
        if(!formData.max_year){
            isFormValid=false;
            toast.error('To year range is required.');
        }
        if(formData.min_year > formData.max_year) {
            isFormValid=false;
            toast.error('To year range must be greater than from year range.');
        }
        // if(!formData.fuel){
        //     isFormValid=false;
        //     toast.error('Fuel type is required.');
        // }
        return isFormValid;
    }
    
      
    render() {
          const { filterData,formData, cityListData} = this.state;
          console.log('filterData---------------', filterData);
          const leadInfo = (filterData && filterData.leadDetails && filterData.leadDetails.length) ? filterData.leadDetails[0] : []
          const {PURPOSE_OF_PURCHASE_CAR, DRIVE_CAR, AVERAGE_TRAVEL_CAR, NO_SEAT_REQ, BUYER_TYPE} = config.constants;
          return (
              
                <div className="edit-requirements">
                    {/* <form method="post" id="reqForm" onSubmit={this.submitForm}> */}
                        <div className="row">
                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.budgetRange')}<span className="required" style={{ color: "red" }}>*</span></label>
                                <Select
                                    id="budget"
                                    name="budget"
                                    onChange={this.handleChange.bind(this,'budget')}
                                    options={filterData.priceRange || []}
                                    value={leadInfo && filterData && filterData['priceRange'].filter((budget) => Number(budget.value) === Number(leadInfo.budget))}
                                    
                                />
                                {/* <span className="text-danger">{errors.budget || ''}</span> */}
                            </div>
                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.maxKm')}<span className="required" style={{ color: "red" }}>*</span></label>
                                <Select
                                    id="max_km"
                                    name="max_km"
                                    onChange={this.handleChange.bind(this,'max_km')}
                                    options={filterData.kmRange || []}
                                    value={leadInfo && filterData && filterData['kmRange'].filter((kmRange) => Number(kmRange.value) === Number(leadInfo.max_km))}
                                    
                                />
                            </div>
                            <div className="col-sm-6">
                                <label>{this.props.t('requirement.yearRange')}<span className="required" style={{ color: "red" }}>*</span></label>
                                <div className="row">
                                    <div className="col-sm-6 form-group">
                                        
                                        <Select
                                            id="min_year"
                                            name="min_year"
                                            onChange={this.handleChange.bind(this,'min_year')}
                                            options={filterData.fromYearRange || []}
                                            value={leadInfo && filterData && filterData['fromYearRange'].filter((fromYearRange) => Number(fromYearRange.value) === Number(leadInfo.min_year))}
                                            
                                        />
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <Select
                                            id="max_year"
                                            name="max_year"
                                            onChange={this.handleChange.bind(this,'max_year')}
                                            options={filterData.toYearRange || []}
                                            value={leadInfo && filterData && filterData['toYearRange'].filter((toYearRange) => Number(toYearRange.value) === Number(leadInfo.max_year))}
                                            
                                        />
                                    </div>
                                </div>
                                
                            </div>

                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.bodyType')}</label>
                                <Multiselect
                                    id='req_body_type'
                                    options={filterData.bodyType ||[]}
                                    onSelect={this.onSelect.bind(this,'req_body_type')} 
                                    onRemove={this.onRemove.bind(this,'req_body_type')} 
                                    displayValue="label" 
                                    showCheckbox={true}
                                    closeOnSelect={false}
                                    selectedValues={(formData && formData.selected_req_body_type && formData.selected_req_body_type.length) ? formData.selected_req_body_type : []}
                                />
                            </div>
                            {/* <div className="col-sm-12 form-group">
                                <label>{this.props.t('requirement.makeModel')}</label>
                                <Multiselect
                                    id='make_ids'
                                    options={filterData.makeModel}
                                    onSelect={this.onSelect.bind(this,'model_ids')} 
                                    onRemove={this.onRemove.bind(this,'model_ids')} 
                                    displayValue="label" 
                                    showCheckbox={true}
                                    closeOnSelect={false}
                                    selectedValues={(formData.selected_model_ids && formData.selected_model_ids.length) ? formData.selected_model_ids : []}
                                />
                            </div> */}
                            <div className="col-sm-12 form-group">
                                <label>{this.props.t('requirement.make')}</label>
                                <Multiselect
                                    id='car_make_id'
                                    options={this.props.makeList || []}
                                    onSelect={this.onSelect.bind(this,'make_ids')} 
                                    onRemove={this.onRemove.bind(this,'make_ids')} 
                                    displayValue="label" 
                                    showCheckbox={true}
                                    closeOnSelect={false}
                                    selectedValues={(formData && formData.selected_make && formData.selected_make.length) ? formData.selected_make : []}
                                />
                            </div>
                            <div className="col-sm-12 form-group">
                                <label>{this.props.t('requirement.model')}</label>
                                
                                <Multiselect
                                    id='model_id'
                                    options={this.state.models || []}
                                    onSelect={this.onSelect.bind(this,'model_ids')} 
                                    onRemove={this.onRemove.bind(this,'model_ids')} 
                                    displayValue="label" 
                                    showCheckbox={true}
                                    closeOnSelect={false}
                                    selectedValues={(formData && formData.selected_model && formData.selected_model.length) ? formData.selected_model : []}
                                    // selectedValues={this.state.selectedModel}
                                />
                            </div>
                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.fuelType')}</label>
                                
                                <Multiselect
                                    id='fuel'
                                    options={filterData.fuelType || []}
                                    onSelect={this.onSelect.bind(this,'fuel')} 
                                    onRemove={this.onRemove.bind(this,'fuel')} 
                                    displayValue="label" 
                                    showCheckbox={true}
                                    closeOnSelect={false}
                                    selectedValues={(formData && formData.selected_fuel && formData.selected_fuel.length) ? formData.selected_fuel : []}
                                />
                            </div>
                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.transmission')}</label>
                                <Select
                                    id="transmission"
                                    name="transmission"
                                    onChange={this.handleChange.bind(this,'transmission')}
                                    options={filterData.transmission || []}
                                    value={leadInfo && filterData && filterData['transmission'].filter((transmission) => Number(transmission.value) === Number(leadInfo.transmission))}
                                    
                                    
                                />
                            </div>
                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.owner')}</label>
                                <Multiselect
                                    id='owner'
                                    options={filterData.owner || []}
                                    onSelect={this.onSelect.bind(this,'owner')} 
                                    onRemove={this.onRemove.bind(this,'owner')} 
                                    displayValue="label" 
                                    showCheckbox={true}
                                    closeOnSelect={false}
                                    selectedValues={(formData && formData.selected_owner && formData.selected_owner.length) ? formData.selected_owner : []}
                                />
                            </div>

                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.noOfSetRequired')}</label>
                                <Select
                                    id="seat_num"
                                    name="seat_num"
                                    onChange={this.handleChange.bind(this,'seat_num')}
                                    options={NO_SEAT_REQ || []}
                                    value={leadInfo && NO_SEAT_REQ && NO_SEAT_REQ.filter((noOfSeats) => Number(noOfSeats.value) === Number(leadInfo.seat_num))}
                                    
                                />
                            </div>
                            <div className="col-sm-12 form-group">
                                <label>{this.props.t('requirement.color')}</label>
                                <Multiselect
                                    id='color'
                                    options={filterData.color || []}
                                    onSelect={this.onSelect.bind(this,'color')} 
                                    onRemove={this.onRemove.bind(this,'color')} 
                                    displayValue="label" 
                                    showCheckbox={true}
                                    closeOnSelect={false}
                                    selectedValues={(formData && formData.selected_color && formData.selected_color.length) ? formData.selected_color : []}
                                />
                            </div>                            
                            <div className="col-sm-12 form-group">
                                <label>{this.props.t('requirement.customer_city')}</label>
                               <Multiselect
                                    id='city_ids'
                                    options={cityListData || []}
                                    onSelect={this.onSelect.bind(this,'city_ids')} 
                                    onRemove={this.onRemove.bind(this,'city_ids')} 
                                    displayValue="label" 
                                    showCheckbox={true}
                                    closeOnSelect={false}
                                    selectedValues={(formData && formData.selected_city_id && formData.selected_city_id.length) ? formData.selected_city_id : []}
                                />
                            </div>                            
                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.purposeOfPurchasing')}</label>
                                <Select
                                    id="purpose"
                                    name="purpose"
                                    onChange={this.handleChange.bind(this,'purpose')}
                                    options={PURPOSE_OF_PURCHASE_CAR || []}
                                    value={leadInfo && PURPOSE_OF_PURCHASE_CAR && PURPOSE_OF_PURCHASE_CAR.filter((pop) => Number(pop.value) === Number(leadInfo.purpose))}
                                    
                                />
                            </div>
                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.whoWillDriveCar')}</label>
                                <Select
                                    id="drive_car"
                                    name="drive_car"
                                    onChange={this.handleChange.bind(this,'drive_car')}
                                    options={DRIVE_CAR || []}
                                    value={leadInfo && DRIVE_CAR && DRIVE_CAR.filter((driveCar) => Number(driveCar.value) === Number(leadInfo.drive_car))}
                                    
                                />
                            </div>
                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.averageDailyTravelKms')}</label>
                                <Select
                                    id="max_avg_daily_travel"
                                    name="max_avg_daily_travel"
                                    onChange={this.handleChange.bind(this,'max_avg_daily_travel')}
                                    options={AVERAGE_TRAVEL_CAR || []}
                                    value={leadInfo && AVERAGE_TRAVEL_CAR && AVERAGE_TRAVEL_CAR.filter((avgTravel) => (avgTravel.value) === (leadInfo.min_avg_daily_travel_km+'-'+leadInfo.max_avg_daily_travel_km))}
                                    
                                />
                            </div>
                            <div className="col-sm-6 form-group">
                                <label>{this.props.t('requirement.buyerType')}</label>
                                <Select
                                    id="buyer_type"
                                    name="buyer_type"
                                    onChange={this.handleChange.bind(this,'buyer_type')}
                                    options={BUYER_TYPE || []}
                                    value={leadInfo && BUYER_TYPE && BUYER_TYPE.filter((buyerType) => Number(buyerType.value) === Number(leadInfo.buyer_type))}
                                    
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 form-group text-right">
                                <button type="reset" name="cancel" className="btn btn-default" onClick={this.props.cancelBtn}>{this.props.t('requirement.cancel')}</button>
                            </div>
                            <div className="col-sm-6 form-group">
                                <button type="submit" name="submit" className="btn btn-primary btn-block" onClick={this.submitForm}>{this.props.t('requirement.save')}</button>
                            </div>
                        </div>
                    {/* </form> */}
                </div>

            
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    filterList: state.lead.filterList,
    makeModelVersionList: state.lead.makeModelVersionList,
    leadDetails: state.lead.listLead
});
// ,
//             getCarVariant: bindActionCreators(
//                 LeadActions.getMakeModelVersion,
//                 dispatch
//             )
const mapDispatchToProps = dispatch => {
    return {
        actions: {            
            submitRequirementForm:bindActionCreators(
                LeadActions.submitRequirementForm,
                dispatch
            )
        }
    }
}

export default withTranslation('lead')(connect(mapStateToProps, mapDispatchToProps) (EditRequirements));
