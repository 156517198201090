import { SourceService } from '../../service';
import { SourceConstants } from '../constants/source.constants';

export const SourceActions = {
    listSource,
    listSubSource    
};

function listSource(postData) {

    return dispatch => {
      return new Promise((resolve, reject) => {
        SourceService.getSourceListData(postData)
          .then(
            resp => {
              if(resp && resp.data.status === 200){
                dispatch(success(resp.data));
                resolve(resp.data);
              }
              else{
                resolve(resp ? resp.data : []);
                dispatch(failure('not_found'));
              }
            },
            error => {
              reject(error);
              dispatch(failure(error));
            }
          );
        });
    };

    function success(resp) { return { type: SourceConstants.REQUEST_SOURCE_LIST_SUCCESS, resp } }
    function failure(error) { return { type: SourceConstants.REQUEST_SOURCE_LIST_FAILURE, error } }

}

function listSubSource(postData) {

  return dispatch => {
    return new Promise((resolve, reject) => {
      SourceService.getSubSourceList(postData)
        .then(
          resp => {
            if(resp.data.status === 200){
              dispatch(success(resp.data));
              resolve((resp && resp['data']) || []);
            }
            else{
              dispatch(failure('not_found'));
              resolve((resp && resp['data']) || []);
            }
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        ).catch(error=>{
          dispatch(failure(error));
          reject(error);
      });
    });
  };

  function success(resp) { return { type: SourceConstants.REQUEST_SUB_SOURCE_LIST_SUCCESS, resp } }
  function failure(error) { return { type: SourceConstants.REQUEST_SUB_SOURCE_LIST_FAILURE, error } }

}