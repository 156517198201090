import React, { Component } from "react";

import CheckBox from "../../elements/Radiobox";
import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../../common/LanguageSelector';
import queryString from 'query-string';
import { default as config } from '../../../config/config';


class PurchaseFeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackForm: {
        satisfiedBuyingExperience: '',
        testDriveBeforeBuy: '',
        freelyInspectVehicleAllowed: '',
        serviceImprovement:''
      },
      errors:{},
      loading: false,
      queryParams:{},
      siteSettings:null,
      notificationData: {}
    };
  }


  componentDidMount = async () => {
    this._isMounted = true;
    let queryParams = queryString.parse(this.props.location.search);
    await this.setState({queryParams});

    //GET NOTIFICATION DETAILS
    this.getDetails();
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if(nextProps.siteSettings && !prevState.siteSettings ){
        return ({ siteSettings: nextProps.siteSettings })
    }
    return null;
  }

  handleChange = (name, value) => async (event) => {

  }

  getDetails = async () => {
    let updatedValue = {...this.state.feedbackForm};

    let details = await this.props.actions.getDetails(this.state.queryParams);

    if(details.status === 200 && details.data && +details.data.status === 200){
      let feedbackData = details['data']['data'][0]['feedback_data'] ? JSON.parse(details['data']['data'][0]['feedback_data']) : {};
      updatedValue['satisfiedBuyingExperience'] = feedbackData['satisfiedBuyingExperience'];
      updatedValue['testDriveBeforeBuy'] = feedbackData['testDriveBeforeBuy'];
      updatedValue['freelyInspectVehicleAllowed'] = feedbackData['freelyInspectVehicleAllowed'];
      updatedValue['serviceImprovement'] = feedbackData['serviceImprovement'];
      updatedValue['notificationData'] = details['data']['notificationData']
    }

    await this.setState({feedbackForm: updatedValue});

  }


  render() {
    const { feedbackForm, siteSettings } = this.state;
    const { YES_NO } = config.constants;

    return (
      <div className="container">
        <div className="card feedbackForm">
          <div className="card-heading">
            <h2 className="card-titile">Car Purchase Satisfactory Survey</h2>
          </div>
          <div className="card-body">
            <h3>{`Please answer the following questions so we at ${siteSettings && siteSettings['language'] === 'id' ? 'OTO.com' : 'Carmudi.com'} can serve you better:`} </h3>
            <br></br>
            <form className="feedback-form">
              <div className="form-group">
                <label className="headLabel">
                Are you satisfied with the overall buying experience at <strong>{(feedbackForm['notificationData'] && feedbackForm['notificationData']['dealer_name']) ? feedbackForm['notificationData']['dealer_name'] : ''}</strong>?
                <span>*</span>
                </label>
                <ul>
                  {YES_NO.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="satisfiedBuyingExperience"
                        id="satisfiedBuyingExperience"
                        checked={item.value === feedbackForm['satisfiedBuyingExperience'] ? true : false}
                        onChange={this.handleChange('satisfiedBuyingExperience', item)}

                      />
                    </li>
                  ))}
                </ul>

              </div>
              <div className="form-group">
                <label className="headLabel">
                Did you test drive this car before buying it?
                <span>*</span>
                </label>
                <ul>
                  {YES_NO.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="testDriveBeforeBuy"
                        id="testDriveBeforeBuy"
                        checked={item.value === feedbackForm['testDriveBeforeBuy'] ? true : false}
                        onChange={this.handleChange('testDriveBeforeBuy', item)}

                      />
                    </li>
                  ))}
                </ul>

              </div>
              <div className="form-group">
                <label className="headLabel">
                Were you allowed to freely inspect the vehicle at the time of delivery?
                <span>*</span>
                </label>
                <ul>
                  {YES_NO.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="freelyInspectVehicleAllowed"
                        id="freelyInspectVehicleAllowed"
                        checked={item.value === feedbackForm['freelyInspectVehicleAllowed'] ? true : false}
                        onChange={this.handleChange('freelyInspectVehicleAllowed', item)}

                      />
                    </li>
                  ))}
                </ul>

              </div>
              <div className="form-group">
                <label className="headLabel"> 
                  Is there any way we could improve our service? Please specify below.
                </label>
                <br></br>
                    <textarea
                        id="serviceImprovement"
                        name="serviceImprovement"
                        type="text"
                        value={feedbackForm['serviceImprovement']}
                        className="form-control"
                        disabled
                    />
              </div>
            </form>
          </div>
        </div>

        <footer>
            <LanguageSelector />
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenExpired: state.auth.tokenExpired,
    siteSettings: state.auth.siteSettings
  }
}

const mapDispatchToProps = dispatch => {
  return {
            actions: {
              getDetails: bindActionCreators(
                LeadActions.feedbackFormValidity,
                dispatch
              )
            }
          }
}

export default withTranslation('common') (connect(mapStateToProps, mapDispatchToProps)(PurchaseFeedbackForm));
