import React from 'react';
import InputField from '../../../elements/InputField';
import { connect } from 'react-redux';
import { AuthActions } from "../../../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import Captcha from '../../../common/Captcha';
import LanguageSelector from '../../../common/LanguageSelector';
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import secureStorage from './../../../../config/encrypt';
import { default as config } from './../../../../config/config';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();

        this.state = {
            fields: {
                "userId": "",
                "password": "",
                "captcha": "",
            },
            errors: {},
            submitted: false
        };
    }

    /**
     * Input Handler
     * @param e (synthatic element)
     */
    handleInputChange = async (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;

        await this.setState({
          fields
        });
    }

    /**
     * Login Submit Handler
     */    
    submitLogin = async (e) => {
        e.preventDefault();
        this.setState({ submitted: true });
        const { userId, password, captcha} = this.state.fields;
        if (await this.validateForm()) {
            this.props.actions.loginSuccess(userId, password,captcha,this.props.captchaValue).then(async(resp) => {
                if(resp.status === 200){
                    await this.getRolePrivilege({'role_id':resp.data.authUser.role_id});
                    this.props.actions.getSiteSettings();
                    this.getUserList();
                }
            });
        }
    }

    captchaFieldHandler = (childData) => {
        let fields = this.state.fields;
        fields['captcha'] = childData;

        this.setState({
          fields
        });
    }

    /**
     * Form Validations
     */

    validateForm = async function() {
        
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        this.child.resetCaptchaError(); // RESET CAPTCHA ERROR

        if (!fields["userId"].trim()) {
            formIsValid = false;
            errors["userId"] = this.props.t('login.enter_user_id');
        }
        
        if (!fields["password"].trim()) {
            formIsValid = false;
            errors["password"] = this.props.t('login.enter_password');
        }
        if (!fields['captcha'].trim()) {
            formIsValid = false;
            errors["captcha"] = this.props.t('login.captcha_not_matched');
            
            this.child.loadCaptchaImage(errors);  //Load captcha again
        }
        // if (fields['captcha'].trim() !== window.atob(this.props.captchaValue)) {
        //     formIsValid = false;
        //     errors["captcha"] = this.props.t('login.captcha_not_matched');
            
        //     this.child.loadCaptchaImage(errors);  //Load captcha again
        // }

        await this.setState({
            errors: errors,
            submitted: formIsValid
        });

        return formIsValid;
    }

    componentDidUpdate(){
        const { invalidLogin } = this.props;

        if(invalidLogin){
            this.child.loadCaptchaImage();  //Load captcha again
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if(nextProps.invalidLogin !== prevState.invalidLogin){
            return ({ submitted: false })
        }
        return null;
    }

    getUserList = () =>{
        this.props.actions.getUserList()
        .then(result =>{
            secureStorage.setItem('userList',result.data)
        })
    }

    getRolePrivilege = async (params) =>{
        await this.props.actions.getRolePrivilege(params)
        
    }

    render() {
        const { redirectTo } = this.props;
        const { submitted } = this.state;
        
        if(redirectTo){
            return <Redirect to="/" />
        }

        return (
            <div className="container-fluid" id="homePage">
                <div className="loginwrap">
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-sm-offset-4 col-sm-offset-6">
                            <div className="mrg-b15 text-center">
                                <img src={config.constants.LOGIN_PAGE_LOGO} className="brand-logo" alt="Loan Box" />
                            </div>
                            <div className="card">
                                <div className="card-body pd-30">
                                    <div className="signUp-container text-left">
                                        <h1 className="login-h1">{this.props.t('login.login')}</h1>
                                        <p className="login-p">{this.props.t('login.login_title')}</p>
                                        <form method="post" className="text-left os-animation animated fadeInDown">
                                            <div className="loginbox">
                                                <div className={'form-group' + (!submitted && this.state.errors.userId ? ' has-error' : '')}>
                                                    <InputField
                                                        inputProps={{
                                                            id: "user_name",
                                                            type: "text",
                                                            name: "userId",
                                                            label: this.props.t('login.username'),
                                                            placeholder: this.props.t('login.username_placeholder'),
                                                            value: this.state.fields.userId
                                                            // dataerror: "Enter email address"
                                                        }}
                                                        onChange={this.handleInputChange}
                                                        />
                                                        {!submitted && this.state.errors.userId && <div className="error-block">{this.state.errors.userId}</div> }
                                                </div>
                                                <div className={'form-group' + (!submitted && this.state.errors.password ? ' has-error' : '')}>
                                                    <InputField
                                                        inputProps={{
                                                            id: "password",
                                                            type: "password",
                                                            name: "password",
                                                            label: this.props.t('login.password'),
                                                            placeholder: this.props.t('login.password_placeholder'),
                                                            value: this.state.fields.password
                                                            // dataerror: "Enter email address"
                                                        }}
                                                        onChange={this.handleInputChange}
                                                        />
                                                        {!submitted && this.state.errors.password && <div className="error-block">{this.state.errors.password}</div> }
                                                </div>
                                                        <Captcha onKeyPressCaptcha={this.captchaFieldHandler} childRef={Ref => this.child=Ref } />
                                                        <div className="row hide">
                                                            <div className="col-sm-6"></div>
                                                            <div className="col-sm-6 text-right">
                                                                <label className="forgotP" id="forgotPassword">
                                                                    {/*<Link to={`/forgot-password`} data-key="forgot">{this.props.t('login.forgot_password')}</Link>*/}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            {
                                                                !submitted 
                                                                ?
                                                                <button type="button" onClick={this.submitLogin} className="btn btn-primary btn-block">{this.props.t('login.login')}</button>
                                                                :
                                                                <button type="button" className="btn btn-primary btn-block">{this.props.t('login.please_wait')}</button>
                                                            }
                                                        </div>
                                                </div>
                                            {/* </div> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <footer>
                                <LanguageSelector />
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
       auth: state.auth.auth,
       loggingIn: state.auth.loggingIn,
       redirectTo: state.auth.redirectTo,
       captchaImage: state.auth.captchaImage,
       captchaValue: state.auth.captchaValue,
       captchaError: state.auth.captchaError,
       invalidLogin: state.auth.invalidLogin,
       siteSettings: state.auth.siteSettings
    }
 }

const mapDispatchToProps = dispatch => {
    return {
               actions: {
                  loginSuccess: bindActionCreators(
                    AuthActions.login,
                    dispatch
                  ),
                  getSiteSettings: bindActionCreators(
                    AuthActions.getSiteSettings,
                    dispatch
                  ),
                  getUserList: bindActionCreators(
                    AuthActions.getUserList,
                    dispatch
                  ),
                  getRolePrivilege :bindActionCreators(
                    AuthActions.getRolePrivilege,
                    dispatch
                  )
               }
            }
}

export default withTranslation('common') (withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));