import React, { Component } from 'react';
import './webroot/css/main.css';
import CustomRouter from './routes/route';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { default as config } from './config/config';

class App extends Component {

	// constructor(props) {
	// 	super(props);
	// }
	componentDidMount = ()=>{
		const favicon = document.getElementById('favicon');
		const title = document.getElementsByTagName('title')[0];

		if(process.env.REACT_APP_COUNTRY_CODE === 'id'){
			favicon.href='favicon.ico';
		}else{
			favicon.href='favicon_ph.ico';
		}

		//SET TITLE
		title.innerHTML = config.constants.TITLE;
	}
	
  render() {
    return (
      <div className="App">
        <ToastContainer position={toast.POSITION.TOP_RIGHT} />
        <CustomRouter />
      </div>
    );
  }
}

export default App;
