import React, { Component } from 'react';
import SearchSnapShot from './search-lead-snapshot';
import SnapShotResult from './lead-snapshot-result';
//import InputField from '../../../elements/InputField';
import ReportService from './../../../../service/reportService';
import { bindActionCreators } from 'redux';
import {withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { LeadActions, InventoryActions } from "../../../../store/action";
import { CSVLink } from "react-csv";
import _ from 'lodash'

class LeadSnapshotList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saveFilter: false,
            reportData: [],
            searchByList: [],
            page: 0,
            totalRecord:0,
            filterData: {},
            loading:false,
            pageloading:true,
            next_page:false,
            totalCalculationObject:{},
            allFliterData: {
                cities: [],
                cluster: [],
                sourceList: [],
                subSourceList:[]
            },
            searchResult: [],
            selectedTab:'TAB-1',
            L1LeadsJson:[],
            L2LeadsJson:[],
            ClosedLeadsJson:[]
        }
        this.handleChangetext = this.handleChangetext.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this._isMounted = true;
        this.onScroll('Mounter');
        window.addEventListener('scroll', this.onScroll);
        await this.getClusterList();
        await this.getReportData({})
        
    }

    onScroll(event) {
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            if (this.flag) {
                let current_page = this.state.page;
                let loading=false,pageloading=false;
                if(current_page===0) {
                    pageloading = true;
                }else{
                    loading = true;
                }
                if(this._isMounted && this.state.next_page){
                    this.setState({ page: current_page + 1,loading:loading,pageloading:pageloading}, () => {
                        this.getReportData();
                    });
                }
            }
            this.flag = false;
        }
    }

    handleChangetext = () => {
        // let formData = { ...this.state.formData };   
    }
    ToogleSaveFilter = () => {
        this.setState({ saveFilter: event.target.value });
    };
    
    getCityList = async() => {
        const {allFliterData} = this.state;
        let result = await this.props.actions.listCity();
        let cityList = []
        if (result && result.status === 200) {
            cityList = (result && result.data && result.data.city && result.data.city.length) ? result.data.city : []
            allFliterData.cities = cityList
        }
        this.setState({ allFliterData: allFliterData });
    }

    getClusterList = async() => {
        const {allFliterData} = this.state;
        let result = await this.props.actions.getClusterList({});
        let clusterList = []
        if (result && result.status === 200) {
            clusterList = (result && result.data && result.data.length) ? result.data : []
            allFliterData.cluster = clusterList
        }
        this.setState({ allFliterData: allFliterData });
       
    }

    getSourceList = async() => {
        const {allFliterData} = this.state;
        ReportService.getSourceList({})
        .then((resp) => {
              if(resp && resp.data && resp.data.status === 200){
                allFliterData.sourceList = resp.data.data
                this.setState({ allFliterData: allFliterData });
              }
            }
          );
        
    }

    getSubSourceList = async(postData) => {
        const {allFliterData} = this.state;
        ReportService.getSubSourceList(postData)
        .then((resp) => {
              if(resp && resp.data && resp.data.status === 200){
                allFliterData.subSourceList = resp.data.data;
                this.setState({ allFliterData: allFliterData });
              }
            }
          );
    }

    getReportData = async (searchParams) => {
        var thisObj = this;
        var filterData = thisObj.state.filterData;
        filterData.page_number = (thisObj.state.page === 0) ? 1 : thisObj.state.page;
        thisObj.setState({page:filterData.page_number,loading:true})
        ReportService.getLeadSnapshotReport(filterData)
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({loading:false,pageloading:false})
                if (response.data.status === 200) {
                    if (response.data.data.length) {
                        let reportData = response.data.data;
                        let pagination = (response.data && response.data.pagination) ? response.data.pagination : {}
                        let next_page = (pagination) ? pagination.next_page : false
                        let totalRecord = (pagination) ? pagination.total : 0
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ reportData: reportData ,loading:false,pageloading:false,next_page:next_page,totalRecord:totalRecord}, () => {
                                thisObj.flag = true;
                            });
                        }
                        else {
                            thisObj.setState({
                                reportData: thisObj.state.reportData.concat(reportData)
                            ,loading:false,pageloading:false,next_page:next_page,totalRecord:totalRecord}, () => {
                                thisObj.flag = true;
                            });
                        }
                        thisObj.getTotalObject();
                        thisObj.setJSONObjectForReport();
                    } else {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ reportData: [],next_page:false,totalRecord:0});
                        }
                    }
                }
            })
            .catch(function (response) {
                thisObj.setState({loading:false,pageloading:false})
            });
    };

    getTotalObject = async()=>{
        let {reportData,totalCalculationObject} = this.state;
        totalCalculationObject['sumTotalAllLeads'] = await this.calculateTotal('allLeads',reportData);
        totalCalculationObject['sumTotalNyc'] = await this.calculateTotal('totalNyc',reportData);
        totalCalculationObject['sumTotalFirst'] = await this.calculateTotal('totalFirst',reportData);
        totalCalculationObject['sumTotalSec'] = await this.calculateTotal('totalSec',reportData);
        totalCalculationObject['sumTotalThird'] = await this.calculateTotal('totalThird',reportData);
        totalCalculationObject['sumTotalFourth'] = await this.calculateTotal('totalFourth',reportData);
        totalCalculationObject['sumTotalMore'] = await this.calculateTotal('totalMore',reportData);
        totalCalculationObject['sumTotalReached'] = await this.calculateTotal('totalReached',reportData);
        totalCalculationObject['sumTotalReachedFirst'] = await this.calculateTotal('totalReachedFirst',reportData);
        totalCalculationObject['sumTotalReachedSec'] = await this.calculateTotal('totalReachedSec',reportData);
        totalCalculationObject['sumTotalReachedThird'] = await this.calculateTotal('totalReachedThird',reportData);
        totalCalculationObject['sumTotalReachedFourth'] = await this.calculateTotal('totalReachedFourth',reportData);
        totalCalculationObject['sumTotalReachedMore'] = await this.calculateTotal('totalReachedMore',reportData);
        totalCalculationObject['sumTotalVerifyInt'] = await this.calculateTotal('totalVerifyInt',reportData);
        totalCalculationObject['sumTotalVerifyFollowup'] = await this.calculateTotal('totalVerifyFollowup',reportData);
        totalCalculationObject['sumTotalVerifyPostponed'] = await this.calculateTotal('totalVerifyPostponed',reportData);
        totalCalculationObject['sumTotalWalkinSchd'] = await this.calculateTotal('totalWalkinSchd',reportData);
        totalCalculationObject['sumTotalPurchasePending'] = await this.calculateTotal('totalPurchasePending',reportData);
        totalCalculationObject['sumTotalPurchaseFollowup'] = await this.calculateTotal('totalPurchaseFollowup',reportData);
        totalCalculationObject['sumTotalPurchaseConverted'] = await this.calculateTotal('totalPurchaseConverted',reportData);
        totalCalculationObject['sumTotalClosedInt'] = await this.calculateTotal('totalClosedInt',reportData);
        totalCalculationObject['sumTotalClosedWrong'] = await this.calculateTotal('totalClosedWrong',reportData);
        totalCalculationObject['sumTotalClosedGaadiIndividual'] = await this.calculateTotal('totalClosedGaadiIndividual',reportData);
        totalCalculationObject['sumTotalClosedNonGaadiIndividual'] = await this.calculateTotal('totalClosedNonGaadiIndividual',reportData);
        totalCalculationObject['sumTotalClosedNonGaadiDealer'] = await this.calculateTotal('totalClosedNonGaadiDealer',reportData);
        totalCalculationObject['sumTotalClosedPurchaseCar'] = await this.calculateTotal('totalClosedPurchaseCar',reportData);
        totalCalculationObject['sumTotalClosedAttemptElapsed'] = await this.calculateTotal('totalClosedAttemptElapsed',reportData);
        totalCalculationObject['sumTotalPurchasedNewCar'] = await this.calculateTotal('totalPurchasedNewCar',reportData);
        totalCalculationObject['sumTotalClosedBlocked'] = await this.calculateTotal('totalClosedBlocked',reportData);
        totalCalculationObject['sumTotalClosedOutCity'] = await this.calculateTotal('totalClosedOutCity',reportData);
        this.setState({totalCalculationObject});
         
    }

    calculateTotal = async(key,reportData) =>{
        let total=0
        let totalAllLeads = await (reportData && reportData.length && reportData).map(reportObj=>{
            return total += +(reportObj[key])
        })
        let totalVal = (totalAllLeads && totalAllLeads.length) ? totalAllLeads[totalAllLeads.length - 1] : 0;
        return totalVal;
    } 

    getFilterReport = async(searchParam) => {
        await this.setState({filterData : searchParam});
        this.getReportData();
    }

    setSelectedTab = (tab) =>{
        this.setState({selectedTab:tab})
    }

    setJSONObjectForReport=()=>{
        
        let reportList = this.state.reportData;
        let l1LeadsObj={},l2LeadsObj={},closedLeadsObj={},L1List=[],L2List=[],ClosedLeadsList=[]
        _.forEach(reportList,(reportData)=>{
            l1LeadsObj['Cluster']=reportData.cluster_name;
            l1LeadsObj['NYC']=(reportData.totalNyc) ? reportData.totalNyc : null+(reportData.totalNyc && reportData.allLeads) ? ' ('+((Number(reportData.totalNyc)/Number(reportData.allLeads))*100)+'%)' : null;
            l1LeadsObj['1st']=(reportData.totalFirst) ? reportData.totalFirst : null+(reportData.totalFirst && reportData.allLeads) ? ' ('+((reportData.totalFirst/reportData.allLeads)*100)+'%)' : null;
            l1LeadsObj['2nd']=(reportData.totalSec) ? reportData.totalSec : null+(reportData.totalSec && reportData.allLeads) ? ' ('+((reportData.totalSec/reportData.allLeads)*100)+'%)' : null;
            l1LeadsObj['3rd']=(reportData.totalThird) ? reportData.totalThird : null+(reportData.totalThird && reportData.allLeads) ? ' ('+((reportData.totalThird/reportData.allLeads)*100)+'%)' : null;
            l1LeadsObj['4th']=(reportData.totalFourth) ? reportData.totalFourth : null+(reportData.totalFourth && reportData.allLeads) ? ' ('+((reportData.totalFourth/reportData.allLeads)*100)+'%)' : null;
            l1LeadsObj['More']=(reportData.totalMore) ? reportData.totalMore : null+(reportData.totalMore && reportData.allLeads) ? ' ('+((reportData.totalMore/reportData.allLeads)*100)+'%)' : null;
            l1LeadsObj['Reached']=(reportData.totalReached) ? reportData.totalReached : null+(reportData.totalReached && reportData.allLeads) ? ' ('+((reportData.totalReached/reportData.allLeads)*100)+'%)' : null;
            l1LeadsObj['Reached 1st']=(reportData.totalReachedFirst) ? reportData.totalReachedFirst : null+(reportData.totalReachedFirst && reportData.allLeads) ? ' ('+((reportData.totalReachedFirst/reportData.allLeads)*100)+'%)' : null;
            l1LeadsObj['Reached 2nd']=(reportData.totalReachedSec) ? reportData.totalReachedSec : null+(reportData.totalReachedSec && reportData.allLeads) ? ' ('+((reportData.totalReachedSec/reportData.allLeads)*100)+'%)' : null;
            l1LeadsObj['Reached 3rd']=(reportData.totalReachedThird) ? reportData.totalReachedThird : null+(reportData.totalReachedThird && reportData.allLeads) ? ' ('+((reportData.totalReachedThird/reportData.allLeads)*100)+'%)' : null;
            l1LeadsObj['Reached 4th']=(reportData.totalReachedFourth) ? reportData.totalReachedFourth : null+(reportData.totalReachedFourth && reportData.allLeads) ? ' ('+((reportData.totalReachedFourth/reportData.allLeads)*100)+'%)' : null;
            l1LeadsObj['Reached More']=(reportData.totalReachedMore) ? reportData.totalReachedMore : null+(reportData.totalReachedMore && reportData.allLeads) ? ' ('+((reportData.totalReachedMore/reportData.allLeads)*100)+'%)' : null;
            L1List.push(l1LeadsObj);
            // L2Leads object 
            l2LeadsObj['Cluster'] = reportData.cluster_name;
            l2LeadsObj['Interested'] = (reportData.totalVerifyInt) ? reportData.totalVerifyInt : null+(reportData.totalVerifyInt && reportData.allLeads) ? ' ('+((reportData.totalVerifyInt/reportData.allLeads)*100)+'%)' : null;
            l2LeadsObj['Verified Follow-up'] = (reportData.totalVerifyFollowup) ? reportData.totalVerifyFollowup : null+(reportData.totalVerifyFollowup && reportData.allLeads) ? ' ('+((reportData.totalVerifyFollowup/reportData.allLeads)*100)+'%)' : null;
            l2LeadsObj['Postponed'] = (reportData.totalVerifyPostponed) ? reportData.totalVerifyPostponed : null +(reportData.totalVerifyPostponed && reportData.allLeads) ? ' ('+((reportData.totalVerifyPostponed/reportData.allLeads)*100)+'%)' : null;
            l2LeadsObj['Scheduled'] = (reportData.totalWalkinSchd) ? reportData.totalWalkinSchd : null+(reportData.totalWalkinSchd && reportData.allLeads) ? ' ('+((reportData.totalWalkinSchd/reportData.allLeads)*100)+'%)' : null;
            l2LeadsObj['Pending'] = (reportData.totalPurchasePending) ? reportData.totalPurchasePending : null+(reportData.totalPurchasePending && reportData.allLeads) ? ' ('+((reportData.totalPurchasePending/reportData.allLeads)*100)+'%)' : null;
            l2LeadsObj['Purchase Follow-up'] = (reportData.totalPurchaseFollowup) ? reportData.totalPurchaseFollowup : null+(reportData.totalPurchaseFollowup && reportData.allLeads) ? ' ('+((reportData.totalPurchaseFollowup/reportData.allLeads)*100)+'%)' : null;
            l2LeadsObj['Converted'] = (reportData.totalPurchaseConverted) ? reportData.totalPurchaseConverted : null+(reportData.totalPurchaseConverted && reportData.allLeads) ? ' ('+((reportData.totalPurchaseConverted/reportData.allLeads)*100)+'%)' : null;
            L2List.push(l2LeadsObj);

            closedLeadsObj['Cluster'] = reportData.cluster_name;
            closedLeadsObj['Not Interested'] = (reportData.totalClosedInt) ? reportData.totalClosedInt : null+(reportData.totalClosedInt && reportData.allLeads) ? ' ('+((reportData.totalClosedInt/reportData.allLeads)*100)+'%)' : null;
            closedLeadsObj['Wrong Number'] = (reportData.totalClosedWrong) ? reportData.totalClosedWrong : null+(reportData.totalClosedWrong && reportData.allLeads) ? ' ('+((reportData.totalClosedWrong/reportData.allLeads)*100)+'%' : null;
            closedLeadsObj['Purchased From Gaadi Individual'] = (reportData.totalClosedGaadiIndividual) ? reportData.totalClosedGaadiIndividual : null+(reportData.totalClosedGaadiIndividual && reportData.allLeads) ? ' ('+((reportData.totalClosedGaadiIndividual/reportData.allLeads)*100)+'%)' : null;
            closedLeadsObj['Purchased From Non-Gaadi Individual'] = (reportData.totalClosedNonGaadiIndividual) ? reportData.totalClosedNonGaadiIndividual : null+(reportData.totalClosedNonGaadiIndividual && reportData.allLeads) ? ' ('+((reportData.totalClosedNonGaadiIndividual/reportData.allLeads)*100)+'%)' : null;
            closedLeadsObj['Purchased From Non-Gaadi Dealer'] = (reportData.totalClosedNonGaadiDealer) ? reportData.totalClosedNonGaadiDealer : null+(reportData.totalClosedNonGaadiDealer && reportData.allLeads) ? ' ('+((reportData.totalClosedNonGaadiDealer/reportData.allLeads)*100)+'%)' : null;
            closedLeadsObj['Purchased Used Car'] = (reportData.totalClosedPurchaseCar) ? reportData.totalClosedPurchaseCar : null+(reportData.totalClosedPurchaseCar && reportData.allLeads) ? ' ('+((reportData.totalClosedPurchaseCar/reportData.allLeads)*100)+'%)' : null;
            closedLeadsObj['Attempt Elapsed'] = (reportData.totalClosedAttemptElapsed) ? reportData.totalClosedAttemptElapsed : null+(reportData.totalClosedAttemptElapsed && reportData.allLeads) ? ' '+((reportData.totalClosedAttemptElapsed/reportData.allLeads)*100)+'%)' : null;
            closedLeadsObj['New Car'] = (reportData.totalPurchasedNewCar) ? reportData.totalPurchasedNewCar : null+(reportData.totalPurchasedNewCar && reportData.allLeads) ? ' ('+((reportData.totalPurchasedNewCar/reportData.allLeads)*100)+'%)' : null;
            closedLeadsObj['Blocked'] = (reportData.totalClosedBlocked) ? reportData.totalClosedBlocked : null+(reportData.totalClosedBlocked && reportData.allLeads) ? ' ('+((reportData.totalClosedBlocked/reportData.allLeads)*100)+'%)' : null;
            closedLeadsObj['Out of City'] = (reportData.totalClosedOutCity) ? reportData.totalClosedOutCity : null+(reportData.totalClosedOutCity && reportData.allLeads) ? ' ('+((reportData.totalClosedOutCity/reportData.allLeads)*100)+'%)' : null;
            ClosedLeadsList.push(closedLeadsObj);
        })
        this.setState({L1LeadsJson:L1List,L2LeadsJson:L2List,ClosedLeadsJson:ClosedLeadsList})
    }
    

    render() {
        const {L1LeadsJson,L2LeadsJson,ClosedLeadsJson,selectedTab} = this.state;
        const selectedTabJSON = (selectedTab === 'TAB-1') ? L1LeadsJson : (selectedTab === 'TAB-2') ? L2LeadsJson : ClosedLeadsJson;
        
        return (
            <div className="container-fluid">
                <div>
                    <div className="savefilterwrap">
                        <h1 className="mrg-rauto">{this.props.t('leadSnapshot.title')}</h1>
                        
                        {(selectedTabJSON && selectedTabJSON.length) ?          
                        <button type="button" className="btn btn-default btn-sm">
                        <CSVLink
                            data={selectedTabJSON}
                            filename={"lead-snapshot-report.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            >{this.props.t('leadSnapshot.exportCsv')}
                        </CSVLink>
                        </button> :''}
                    </div>
                </div>
                <SearchSnapShot prefilledData={this.state.allFliterData} getReportData={this.getFilterReport} getSubSourceList={this.getSubSourceList}/>
                <SnapShotResult pageloading={this.state.pageloading} loading={this.state.loading} searchResult={this.state.reportData} 
                totalRecord={this.state.totalRecord} totalCalculationObject={this.state.totalCalculationObject} setSelectedTab={this.setSelectedTab}/>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            listCity: bindActionCreators(
                InventoryActions.listCity,
                dispatch
            ),
            listDealer:bindActionCreators(
                LeadActions.listDealer,
                dispatch
            ),
            getClusterList:bindActionCreators(
                LeadActions.getClusterList,
                dispatch
            )
            
    }   }
}

export default  withTranslation('report')(connect(mapStateToProps, mapDispatchToProps)(LeadSnapshotList));
