import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { InventoryActions } from "../../../../store/action";
import { connect } from 'react-redux';
import * as helper from '../../../../config/helper';
import Select from 'react-select';

class SearchCluster extends Component {
  	constructor(props) {
		super(props);
		this.state = {
			filterData: {
				'name':'',
				'city':''
			},
			cityList:[]
			
		};
   }

   componentDidMount = () => {
	   this.listCities();
   }

handleChangeEvent = (key,event) => {
	let filterData = {...this.state.filterData};
	if(key === 'name'){
		filterData.name = event.target.value;
	}else if(key === 'status'){
		filterData.status = event.value;
	}else if(key === 'city'){
		filterData.city = event ? event.value : ''
	}
	this.setState({ filterData:filterData });
	if (typeof this.props.filterNameChange === 'function') {
		this.props.filterNameChange(filterData);
	}
	
}

listCities = async (param={}) => {
	this.props.actions.listCity().then(cty=>{
		if(cty.status === 200 && cty.data && cty.data.city){
			let response = helper.getDataInKeyValueFormat(cty.data.city,'');
			this.setState({cityList: response});

		}
	});

	
}

submitFilterForm = (event) => {
	event.preventDefault();
	if (typeof this.props.onSubmitFilter === 'function') {
		this.props.onSubmitFilter(event);
	}
}
  
resetFilterForm = (event) => {
	window.location.reload(false); 
}

render() {
	const  {filterData, cityList}  = this.state;
	return (
        <div className="card-heading">
			<div className="row">
				<div className="col-sm-4 col-md-6">
					<h2 className="card-title pad-t7">{this.props.t('List.title')}</h2>
				</div>
				<div className="col-sm-4 col-md-3">
						<input
							className="form-control"
							type="text"
							id="name"
							value={filterData.name}
							name="name"
							placeholder={this.props.t('List.Search.cluster')}
							onChange={this.handleChangeEvent.bind(this,'name')}
						/>
				</div>
				
				<div className="col-sm-4 col-md-3">
					<Select
						id='city'
						onChange={this.handleChangeEvent.bind(this,'city')}
						options={cityList}
						isClearable={true}
					/>
				</div>
			</div>
		</div>
       
    )
  }
}


 const mapStateToProps = state => ({
    cityListData: state.inventory.cityListData || {}

});

const mapDispatchToProps = dispatch => {
    return {
            actions: {
                listCity: bindActionCreators(
                    InventoryActions.listCity,
                    dispatch
                )
            }
            }
}

export default withTranslation('cluster') (withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchCluster)));
