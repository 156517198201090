import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class L2LeadSnapshot extends Component {
  constructor(props) {
    super(props);
    this.state = {
        searchResult:props.searchResult,
        totalRecord:props.totalRecord,
        loading: false,
        pageloading: false,
        totalCalculationObject:props.totalCalculationObject
    }
  }
  static getDerivedStateFromProps(nextprops, prevState) {
    var updatedObj = {}
    if (prevState.searchResult !== nextprops.searchResult) {
      updatedObj['searchResult'] = nextprops.searchResult;
    }
    if (prevState.totalRecord !== nextprops.totalRecord) {
        updatedObj['totalRecord'] = nextprops.totalRecord;
    }
    if(nextprops.loading !== prevState.loading){
        updatedObj['loading'] = nextprops.loading;
    }

    if(nextprops.pageloading !== prevState.pageloading){
        updatedObj['pageloading'] = nextprops.pageloading;
    }

    if(nextprops.totalCalculationObject !== prevState.totalCalculationObject){
        updatedObj['totalCalculationObject'] = nextprops.totalCalculationObject;
    }
    return updatedObj;
}


  render() {
   // const {searchResult} = this.state;
    return (
      
        <table className="table table-bordered table-hover table-striped deliveryTable">
            <thead>
            <tr>
            	<th>{this.props.t('leadSnapshot.l2Lead.cluster')}</th>
                <th colSpan="3">{this.props.t('leadSnapshot.l2Lead.verified')}</th> 
            	<th>{this.props.t('leadSnapshot.l2Lead.walkin')}</th>                
                <th colSpan="3">{this.props.t('leadSnapshot.l2Lead.purchase')}</th>  
                                 
            </tr>
            <tr>
            	<th>{this.props.t('leadSnapshot.l2Lead.cluster')}</th>
            	<th>{this.props.t('leadSnapshot.l2Lead.interested')}</th>
                <th>{this.props.t('leadSnapshot.l2Lead.followup')}</th>
                <th>{this.props.t('leadSnapshot.l2Lead.postponed')}</th>               
                <th>{this.props.t('leadSnapshot.l2Lead.scheduled')}</th>                
                <th>{this.props.t('leadSnapshot.l2Lead.pending')}</th>  
                <th>{this.props.t('leadSnapshot.l2Lead.followup')}</th>  
                <th>{this.props.t('leadSnapshot.l2Lead.converted')}</th>  
            </tr>
        </thead>

            <tbody>
            {(this.state.searchResult.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="8"><h6 className="text-center text-danger text-bold">{this.props.t('salesFunnel.NoRecordFound')}</h6></td></tr> : null}
        {(this.state.searchResult && this.state.searchResult.length !== 0) && this.state.searchResult.map((reportData, k) =>
                <tr key={k}>
                    <td>{reportData.cluster_name}</td>
                    <td>{(reportData.totalVerifyInt) ? reportData.totalVerifyInt : ''}{(reportData.totalVerifyInt && reportData.allLeads) ? ' ('+((reportData.totalVerifyInt/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalVerifyFollowup) ? reportData.totalVerifyFollowup : ''}{(reportData.totalVerifyFollowup && reportData.allLeads) ? ' ('+((reportData.totalVerifyFollowup/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalVerifyPostponed) ? reportData.totalVerifyPostponed : ''}{(reportData.totalVerifyPostponed && reportData.allLeads) ? ' ('+((reportData.totalVerifyPostponed/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalWalkinSchd) ? reportData.totalWalkinSchd : ''}{(reportData.totalWalkinSchd && reportData.allLeads) ? ' ('+((reportData.totalWalkinSchd/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalPurchasePending) ? reportData.totalPurchasePending : ''}{(reportData.totalPurchasePending && reportData.allLeads) ? ' ('+((reportData.totalPurchasePending/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalPurchaseFollowup) ? reportData.totalPurchaseFollowup : ''}{(reportData.totalPurchaseFollowup && reportData.allLeads) ? ' ('+((reportData.totalPurchaseFollowup/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalPurchaseConverted) ? reportData.totalPurchaseConverted : ''}{(reportData.totalPurchaseConverted && reportData.allLeads) ? ' ('+((reportData.totalPurchaseConverted/reportData.allLeads)*100)+'%)' : ''}</td>
                   
                </tr>
                )}
                <tr className="totalvalue">
                    <td>{this.props.t('salesFunnel.total')}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    
                </tr>
                {
                    (this.state.loading) ? <tr><td className="loading" colSpan="8"></td></tr> : null
                }
            </tbody>
        </table>
                        
    );
  }
}
export default withTranslation('report') (L2LeadSnapshot);
