import React, { Component } from 'react';
import InputField from '../../../elements/InputField';
import {ClusterService, CityService } from './../../../../service';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import DateFormat from 'dateformat';
import _  from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import * as helper from '../../../../config/helper';
import { Redirect } from "react-router-dom";
class EditCluster extends Component {
    cluster_id;
    constructor(props) {
        super(props);
        this.state = {
            editFormData: {},
            successResponse: false,
            redirect: false,
            errors: {},
            show: false,
            loaderClass: '',
            searchParams:{},
            cityDetails:[],
            selectedCity:[],
            cityClusterDetails:[],
            usersRecord:[],
            loading: false,
            redirectTolist: false,
        }
        this.cluster_id = this.props.match.params.id;
        if(this.cluster_id)
            this.getClusterDetails = this.getClusterDetails.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
    }

    componentDidMount = async () => {
        if(this.cluster_id){
            let searchParams = this.state.searchParams;
            searchParams.id = this.cluster_id;
            this.setState({searchParams:searchParams})
            await this.getClusterDetails();
            await this.getUserList();
        }else
           this.setState({editFormData:{}})
        setTimeout(() => {
            this.getCityDetails({});
        },700)
    }

    getClusterDetails() {
        var thisObj = this;
        ClusterService.getClusterList(this.state.searchParams)
        .then(function (response) {
            thisObj.flag = false;
            thisObj.setState({loading:false,pageloading:false})
            if (response.data.status === 200) {
                if (response.data.data.length) {
                    thisObj.setState({ editFormData: response.data.data[0] ,loading:false,pageloading:false}, () => {
                        thisObj.flag = true;
                    });
                } else {
                    thisObj.setState({ editFormData: response.data.data});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    
    handleChange = (event) => {
        let editFormData = this.state.editFormData;
        if(event && event === 'status')
            editFormData.status = (editFormData.status === '0') ? '1' : '0';
        else
          editFormData.lang_id = event.value
        this.setState({editFormData:editFormData})
    };
    
    submitForm = async (event) => {
        event.preventDefault();
        if (this.validForm()) {
            this.setState({ loaderClass : 'loading', loading: true });
            let postData = await this.filterPostData();
            if(postData.id){
                ClusterService.updateClusterDetails(postData)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                        this.setState({ loading: false ,redirectTolist:true})
                    } else if (response.status === 401 && response.data.status === 401) {
                        this.setState({ redirect: true })
                    }else{
                        // toast.error(response.data.message);
                        this.setState({ loading: false })

                    }
                })
                .catch((error) => {
                    this.setState({ loading: false })
                });
            }else{
                ClusterService.saveClusterDetails(postData)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                        this.setState({ loading: false,redirectTolist: true })
                    } else if (response.status === 401 && response.data.status === 401) {
                        this.setState({ redirect: true })
                    }else{
                        // toast.error(response.data.message);
                        this.setState({ loading: false })

                    }
                })
                .catch((error) => {
                    this.setState({ loading: false })
                });
            }
        }
    }

    filterPostData = () => {
        let params={},selectedCity=[];
        if(this.state.editFormData.id)
            params.id = this.state.editFormData.id;
        if(this.state.editFormData.lang_id)
            params.lang_id = this.state.editFormData.lang_id;
        if(this.state.editFormData.name)
            params.name = this.state.editFormData.name;
        if(this.state.editFormData.description)
            params.description = this.state.editFormData.description;
        if(this.state.editFormData.status)
            params.status = this.state.editFormData.status;
        if(this.state.editFormData.city_id.length){
            selectedCity = this.state.selectedCity.map(function (obj) {
                return obj.value;
            });
            params.city_id=selectedCity;
        }
        return params;
    }

    validForm = () => {
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        let formIsValid = true;
        if (!editFormData["name"]) {
            errors['name'] = this.props.t('validation.name_required')
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleChangeEvent = event => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        editFormData[event.target.name] = event.target.value;
        if(fieldRequired === 'yes'){
            if (event.target.value !== '') {
                delete errors[event.target.name];
            } else {
                errors[event.target.name] = this.props.t('validation.'+event.target.name)+this.props.t('validation.is_required');
            }
        }
        this.setState({ editFormData: editFormData, errors: errors });
    }

    getCityDetails(params) {
        var thisObj = this;
        // params.isPaginationRequired=false;
        CityService.getCityState()
        .then(function (response) {
            if (response.data.status === 200) {
                let cityArr = [];
                if (response.data.data.city.length) {
                    cityArr = helper.getDataInKeyValueFormat(response.data.data.city,'');

                    thisObj.setState({ cityDetails: cityArr });
                    if(thisObj.cluster_id)
                        thisObj.setPreSelectedValueToMultiselectInput()
                } else {
                    thisObj.setState({ cityDetails: cityArr});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    // getCityDetailsForCluster(params) {
    //     var thisObj = this;
    //     ClusterService.getCityListForCluser(params)
    //     .then(function (response) {
    //         if (response.data.status === 200) {
    //             const cityArr = [];
                
    //             if (response.data.data.length) {
    //                 _.forEach((response.data.data),element => {
    //                     let cityObj={}
    //                     cityObj.value = element.id;
    //                     cityObj.label = element.name;
    //                     cityArr.push(cityObj);
    //                 });
    //                 thisObj.setState({ cityClusterDetails: cityArr });
                    
    //             } else {
    //                 thisObj.setState({ cityClusterDetails: cityArr});
    //             }
    //         }
    //     })
    //     .catch(function (response) {
    //         thisObj.setState({loading:false,pageloading:false})
    //     });
    // }

    onSelect(key,selectedList, selectedItem) {
        let selectedCity = this.state.selectedCity;
        if(key === 'city_id'){
            if(!_.find(selectedCity,{'value':selectedItem.value})){
                selectedCity.push(selectedItem);
            }
            this.setState({selectedCity:selectedCity})
        }
        let editFormData = this.state.editFormData;
        editFormData[key] =this.state.selectedCity;
        this.setState({ editFormData: editFormData });
    }
     
    onRemove(key,selectedList, removedItem) {
        if(key === 'city_id'){
            let selectedCity = this.state.selectedCity;
            selectedCity = _.filter(selectedCity, function(currentObject) {
                return currentObject.key !== removedItem.key;
            });
            this.setState({selectedCity:selectedCity})
        }
        let cityClusterDetails = this.state.cityClusterDetails;
        if(!_.find(cityClusterDetails,{'value':removedItem.value})){
            cityClusterDetails.push(removedItem);
        }
        this.setState({cityClusterDetails:cityClusterDetails})
        
    }

    setPreSelectedValueToMultiselectInput(){
        const formData = this.state.editFormData;
        let selectedCity=[];
        // if(formData.city_id){
            // cityArr = formData.city_id.split(',');
            // _.forEach(cityArr,(val) =>{
            //     let city = _.filter(this.state.cityDetails, function(currentVal) {
            //         return val === currentVal.value;
            //     });
            //     if(city.length)
                    // selectedCity.push(city[0])
            // })
            selectedCity = formData.cityObj;
            this.setState({selectedCity:selectedCity})
        // }
        
    }

    getUserName = (userData,keyToMatch,keyToReturn) => {
        const user = _.find(this.state.usersRecord,{'id':userData[keyToMatch]});
        if(user)
            return user[keyToReturn]
        else
            return null;
    }
    
    getUserList = () => {
        var thisObj = this;
        ClusterService.getAllUserList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                        thisObj.setState({ usersRecord: response.data.data });
                    
                } else {
                        thisObj.setState({ usersRecord: []});
                    
                }
            }
        })
        .catch(function (response) {
            
        });
    }

    
    render() {
        const   {editFormData,redirectTolist,errors,loading}   = this.state;
        if (redirectTolist) return <Redirect to="/list-cluster" />;
        return (
            <div className="container-fluid">
                <h1>{(editFormData.id) ? this.props.t('formData.editCluster'):this.props.t('formData.addCluster') }</h1>                
                <div className="card">
                    <div className="card-body">
                        <form method="post" id="categoryDetails" onSubmit={this.submitForm}>
                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "name",
                                    type: "text",
                                    name: "name",
                                    label: this.props.t('formData.name'),
                                    value: editFormData.name || '',
                                    dataerror: errors.name || '',
                                    validationreq: "yes"
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 form-group">
                                <label>{this.props.t('formData.city')}</label>
                                <Multiselect
                                    id="city"
                                    options={this.state.cityDetails} 
                                    selectedValues={this.state.selectedCity || ''}
                                    onSelect={this.onSelect.bind(this,'city_id')} 
                                    onRemove={this.onRemove.bind(this,'city_id')} 
                                    displayValue="label" 
                                />
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-sm-4 form-group">
                            <label>{this.props.t('formData.description')}</label>
                                    <textarea
                                        className="form-control" 
                                        type="text"
                                        name="description"
                                        id="description"
                                        value={editFormData.description || ''}
                                        onChange={this.handleChangeEvent}
                                    />
                                </div>
                            </div>
                            
                            {(editFormData.id) ?  
                            <div className="row form-group" >
                                <ul className="maindetail-list">
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.createdDate')}</div>
                                    <div className="sub-value">{DateFormat(editFormData.created_at,"dd/mm/yyyy")}</div>
                                    </li>
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.createdBy')}</div>
                                        <div className="sub-value">{this.getUserName(editFormData,'added_by','username')}</div>
                                    </li>
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.updatedDate')}</div>
                                        <div className="sub-value">{(editFormData.updated_at) ? (DateFormat(editFormData.updated_at,"dd/mm/yyyy")) : ''}</div>
                                    </li>

                                </ul>
                            </div>
                            : ''}
                            
                            {(editFormData.id) ?
                            <div>
                                <label>{this.props.t('formData.status')} </label>
                                <div >
                                    
                                    <label className="switch-btn btn btn-link ml-5" htmlFor="active" >
                                        <input defaultChecked={(editFormData.status === '1') ? true : false} className="switch-btn" id="active" value="open" name="active" type="checkbox" onClick={this.handleChange.bind(this,'status')}  />
                                        <div className="slider round"></div>
                                        <span className="switch-label"></span>
                                        {(editFormData.status === "1") ? this.props.t('formData.active'):this.props.t('formData.inactive')}
                                    </label>
                                    {/* <CheckBox type="checkbox" checked={(editFormData.status === '1') ? true : false} name="status" id="status"  onChange={this.handleChange.bind(this,'status')} label={(editFormData.status)? "Active":"Inactive"}></CheckBox> */}
                                </div>
                            </div>
                           :''}
                            <div className="row">
                                <div className=" col-sm-4 form-group text-center">
                                    {
                                        !loading
                                        ?
                                        <button type="submit" onClick={this.submitForm} className="btn btn-primary">{this.props.t('formData.save')}</button>
                                        :
                                        <button type="submit" className="btn btn-primary">{this.props.t('formData.please_wait')}</button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('cluster')(EditCluster);
