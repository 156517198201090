import React, { Component } from 'react';

import PreviousActivity from './PreviousActivity';
import RequirmentCard from './RequirmentCard';
import AssignedCarTable from './AssignedCarTable';

class AssignedCarTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            refreshAssignedCarsData:false,
            isSaveMainLeadComment: false,
            filterData:{},
            leadList:[]
        };
        
      
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};
        if(nextProps.loading !== prevState.loading){
            updatedStateData['loading'] = nextProps.loading;
        }
        if(nextProps.refreshAssignedCarsData !== prevState.refreshAssignedCarsData){
            updatedStateData['refreshAssignedCarsData'] = nextProps.refreshAssignedCarsData;
        }
        if(nextProps.isSaveMainLeadComment !== prevState.isSaveMainLeadComment){
            updatedStateData['isSaveMainLeadComment'] = nextProps.isSaveMainLeadComment;
        }
        updatedStateData['leadList'] = nextProps.leadList || []

        return updatedStateData;

    }

    updatedLeadCarFromFeedbackPopupToDetail = (data) => {
        this.props.updatedLeadCarFromFeedbackPopupToDetail(data);
    }

    updateSearchDetails = (filterData) => {
        if(filterData)
         this.props.updateSearchDetails(filterData)
    }

    mainSummaryStatus = (data) => {
        this.props.mainSummaryStatus(data);
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    <RequirmentCard updateSearchDetails={this.updateSearchDetails}/>
                    <PreviousActivity />
                </div>
                <div className="col-sm-8">
                    <AssignedCarTable loading={this.state.loading} updateReopenStatus={this.props.updateReopenStatus} refreshAssignedCarsData={this.state.refreshAssignedCarsData} isSaveMainLeadComment={this.state.isSaveMainLeadComment} updatedLeadCarFromFeedbackPopupToDetail={this.updatedLeadCarFromFeedbackPopupToDetail} mainSummaryStatus={this.mainSummaryStatus} leadDetails={this.state.leadList} listLead={this.state.leadList}/>
                </div>
            </div>           
        );
    }
}
export default AssignedCarTab;