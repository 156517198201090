import React, { Component } from 'react';
import { default as config } from '../../../../config/config';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Carousel from '../../../elements/Img-ListingCarousal';
import InfiniteScroll from 'react-infinite-scroll-component'


class FilterTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayCarDetails: {
                'show': false,
                'id': 0
            }
        }
    }

    carDetailShow(id) {
        const { displayCarDetails } = this.state;
        if (displayCarDetails.id === id)
            displayCarDetails.show = !displayCarDetails.show;
        else {
            displayCarDetails.show = true;
            displayCarDetails.id = id;
        }
        this.setState({ displayCarDetails })
    }

    numberFormat = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    importXls = async () => {

        this.props.importExcelFile();
    }

    render() {
        const { inventoryModule } = config.constants;
        const { inventoryList, pagination, loading, csvDownloading, cachedPostData, onScroll } = this.props.filterTableData;

        const getStockAgeInDays = (createdDate) => {
            let d1 = new Date().getTime();
            let d2 = new Date(createdDate).getTime();
            return Math.ceil((d1 - d2) / (1000 * 60 * 60 * 24));
        }
        let hasMore = true;
        if (pagination) {
            hasMore = (+pagination.total === inventoryList.length) ? false : true;
        }

        return (
            <div className="col-md-12">
                <div className="invFilter">
                    <div className="invFilterwrap">
                        {/* <h4 className="mrg-r15 mrg-b0">{this.props.t('inventory.filter')}</h4> */}
                        <div className="rc-inline">
                            {/* <CheckBox type="checkbox" label={this.props.t('inventory.trust_mark_certified')} name="filtercheck" id="filtercheck-1"  /> */}
                            {/* <CheckBox type="checkbox" label={this.props.t('inventory.result_with_pictures')} name="filtercheck" id="filtercheck-2"  /> */}
                            {/* <CheckBox type="checkbox" label={this.props.t('inventory.result_without_pictures')} name="filtercheck" id="filtercheck-3"  /> */}
                        </div>
                        <span className="text-primary mrgin-r-10 filterresult">{(pagination && pagination.total) || 0}</span> {this.props.t('inventory.result')}
                        {/* <button type="button" className="btn  btn-sm"><i className="ic-excel-file icons-normal"></i></button> */}

                        {
                            (cachedPostData && cachedPostData['dealer_ids'] && inventoryList && inventoryList.length)
                                ?
                                <div className="mrg-l10">
                                    {
                                        csvDownloading
                                            ?
                                            <button className="btn btn-success mrgin-r-10" disabled>{this.props.t('inventory.please_wait')}</button>
                                            :
                                            <button onClick={() => this.importXls()} className="btn btn-success mrgin-r-10"  >{this.props.t('inventory.export_csv')}</button>
                                    }
                                </div>
                                :
                                ''
                        }

                    </div>
                </div>

                <div className="right-bar-body">
                    <div className="table-responsive">
                        <InfiniteScroll
                            dataLength={inventoryList.length}
                            next={onScroll}
                            hasMore={hasMore}
                            loader={loading ? <div className="loader loader-default is-active loader-main" data-text="loading"></div> : null}
                            endMessage={!loading ?
                                <p style={{ textAlign: 'center', color: 'red' }}>
                                    <b>You have seen it all</b>
                                </p> : null
                            }
                        >
                            <table className="table table-bordered table-hover table-striped assignedcar-table">
                                <thead>
                                    <tr>
                                        <th>{this.props.t('inventory.car_details')}</th>
                                        <th>{this.props.t('inventory.month_year')}</th>
                                        <th>{this.props.t('inventory.price')}</th>
                                        <th>{this.props.t('inventory.kms')}</th>
                                        <th>{this.props.t('inventory.seller')}</th>
                                        <th>{this.props.t('inventory.city')}</th>
                                        <th>{this.props.t('inventory.image')}</th>
                                        <th>{this.props.t('inventory.age')}</th>
                                        <th>{this.props.t('inventory.ol')}</th>
                                        <th>{this.props.t('inventory.bl')}</th>
                                        <th>{this.props.t('inventory.tl')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inventoryList.length
                                            ?
                                            inventoryList.map((object, i) => {
                                                let ownerType = inventoryModule.ownerArr.filter((el) => (el['value'] === '' + object.owner_type));
                                                return (<React.Fragment key={i}>
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="f14">
                                                                <strong>{object.make} </strong>
                                                                {object.modelVersion}  <span onClick={() => this.carDetailShow(object.id)}><i className="ic-error"></i></span>
                                                            </div>
                                                            <ul className="requirements-list">
                                                                {
                                                                    (object.uc_colour)
                                                                        ?
                                                                        <li>{object.uc_colour}</li>
                                                                        :
                                                                        ''
                                                                }
                                                                {
                                                                    (object.fuel_type)
                                                                        ?
                                                                        <li>{object.fuel_type}</li>
                                                                        :
                                                                        ''
                                                                }
                                                                {
                                                                    (ownerType && ownerType.length && ownerType[0]['label'])
                                                                        ?
                                                                        <li>{ownerType[0]['label']}</li>
                                                                        :
                                                                        ''
                                                                }
                                                                {
                                                                    (object.transmission)
                                                                        ?
                                                                        <li>{object.transmission}</li>
                                                                        :
                                                                        ''
                                                                }



                                                            </ul>
                                                        </td>
                                                        <td>{object.display_reg_date || ''}</td>
                                                        <td>{object.display_price || ''}</td>
                                                        <td>{this.numberFormat(object.km_driven)}</td>
                                                        <td>
                                                            {(object['dealer_detail'] ? object['dealer_detail']['organization'] : '')}<br />

                                                            {
                                                                ((object && object.dealer_detail && object.dealer_detail['organization']))
                                                                    ?
                                                                    (
                                                                        (object.user_type === 'D')
                                                                            ?
                                                                            <React.Fragment>
                                                                                <span>{'(Dealer) '}</span>

                                                                                <p>{((object.dealer_detail && object.dealer_detail.address) ? object.dealer_detail.address : '')}</p>
                                                                            </React.Fragment>

                                                                            :
                                                                            '(Individual)'
                                                                    )
                                                                    :
                                                                    ''
                                                            }

                                                        </td>
                                                        <td>{object.city_name || ''}</td>
                                                        <td>{(object.car_image && object.car_image.length) ? object.car_image.length : 0}</td>
                                                        <td>{getStockAgeInDays(object.created_date)} Days</td>
                                                        <td>{object.totalOrganicLeads}</td>
                                                        <td>{object.totalBackendLeads}</td>
                                                        <td>{object.totalLeads}</td>
                                                    </tr>

                                                    <tr className={(this.state.displayCarDetails.id === object.id && this.state.displayCarDetails.show) ? " " : "hide"}>
                                                        <td colSpan="9" className="recCarDetails">
                                                            <div className="row">
                                                                <span className="arrow"></span>
                                                                {
                                                                    object.car_image && object.car_image.length
                                                                        ?
                                                                        <div className="col-sm-4">
                                                                            <Carousel id="recmimg" slides={object.car_image} />
                                                                        </div>
                                                                        :
                                                                        ''
                                                                }
                                                                <div className="col-sm-8">
                                                                    <div>
                                                                        <h5>{(object.make) ? object.make : ''} {(object.modelVersion) ? object.modelVersion : ''}</h5>
                                                                        <div className="row mrg-b15">
                                                                            <div className="col-md-3">
                                                                                <div className="tt-label">{this.props.t('recomendTab.list.registrationNo')}</div>
                                                                                <div className="tt-detail"><strong>{(object.reg_no) ? object.reg_no : ''}</strong></div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="tt-label">{this.props.t('recomendTab.list.insurance')}</div>
                                                                                <div className="tt-detail">
                                                                                    <strong>{(object.insurance) ? object.insurance : ''}</strong></div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="tt-label">{this.props.t('recomendTab.list.transmission')}</div>
                                                                                <div className="tt-detail"><strong>{(object.transmission) ? object.transmission : ''}</strong></div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="tt-label">&nbsp;</div>
                                                                                <div className="tt-detail hf-clr"></div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className=" mrg-b5"><span><i className="ic-location_onplaceroom"></i></span>{this.props.t('recomendTab.list.address')}  : {(object.dealer_detail && object.dealer_detail.address) ? object.dealer_detail.address : ''}</div>
                                                                            <div className=" mrg-b5"><span><i className="ic-call"></i></span> {(object.dealer_detail && object.dealer_detail.dealership_contact) ? object.dealer_detail.dealership_contact : ''}</div>
                                                                            <div className=" mrg-b5"><span><i className="ic-person"></i></span>  {(object.dealer_detail && object.dealer_detail.organization) ? object.dealer_detail.organization : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </React.Fragment>)
                                            })
                                            :
                                            (
                                                (!loading && !inventoryList.length)
                                                    ?
                                                    <tr><td colSpan="11" className="text-center">{this.props.t('inventory.no_record_found')}</td></tr>
                                                    :
                                                    <tr></tr>
                                            )
                                    }

                                </tbody>
                            </table>
                        </InfiniteScroll>
                        {
                            (loading) ? <div className="loading" ></div> : ''
                        }
                    </div>
                </div>

            </div>)

    }

}



const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {}
    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(FilterTable));


