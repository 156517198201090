import React, { Component } from 'react';
import FilterBox from '../helpers/filter-box';
import FilterTable from '../helpers/filter-table';
import { default as config } from '../../../config/config';
import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

class ConversionPanel extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            page:0,
            selectedOption: null,
            advanceSearchEnable: false,
            totalLengthWithoutPagination: 0,
            tableData: [],
            conversionPanelList:[],
            filterFieldsData:[
                {name: 'cluster', label: 'conversionPanel.cluster', multiRef: React.createRef(), type: 'multiselect', option:[], value: '', advanceSearch : false },
                {name: 'city', label: 'conversionPanel.city', dependent:'cluster', multiRef: React.createRef(),type: 'multiselect', option: [], value: '', advanceSearch : false },
                {name: 'dealer', label: 'conversionPanel.dealer', type: 'select', option: [], value: '', advanceSearch : false },
                {name: 'startDate', label: 'conversionPanel.startDate',  placeholder:'search.placeholder.startDate', type: 'date', value:'', advanceSearch : false },
                {name: 'endDate', label: 'conversionPanel.endDate', type: 'date', placeholder: 'search.placeholder.endDate',  value:'', advanceSearch : false },
                {name: 'cnfm_status', label: 'conversionPanel.status', type: 'select', option: config.constants.conversionPanelStatus, value:'', advanceSearch : true },
                {name: 'searchBy', label: 'conversionPanel.searchBy', type: 'select', option: config.constants.conversionPanelSearchBy, value: '', advanceSearch : true },
                {name: 'search_input', label: 'conversionPanel.searched_by_lead_id',  placeholder:'search.search_by_lead_id', value:'', type: 'input', advanceSearch : true, disabled: true },
                {name: 'search', label: 'conversionPanel.search', type: 'button' },
            ],
            tableHeaders: [
                {key:'conversionPanel.cluster'},
                {key:'conversionPanel.customer_detail'},
                {key:'conversionPanel.car_detail'},
                {key:'conversionPanel.dealer'},
                {key:'conversionPanel.conversion_date'},
                {key:'conversionPanel.approved_denied_by'},
                {key:'conversionPanel.tat'},
                {key:'conversionPanel.registration_no'},
                {key:'conversionPanel.confirmation_status'},
                {key:'conversionPanel.comments'},
                {key:'conversionPanel.action'},
            ],
            pagination: [],
            loading: false,
            importingData: false,
            formAction:''

        }
    }

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    /**
     * Update function on click
     */
    updateConversion = async (data) => {
        this.props.actions.updateConversion(data).then(resp=>{
            if(resp.status === 200){
                toast.success(resp.message);
            }
        }); //CALL ACTION TO UPDATE DATA
    }

    importXls = async (data) => {
        
        let postData = {};

        this.state.filterFieldsData.forEach(el => {
            postData[el.name] = el.value;
        });
        if(this._isMounted) await this.setState({importingData: true});
        this.props.actions.importXls(postData).then(res=>{
            if(this._isMounted) this.setState({importingData: false});
            toast.success(this.props.t('conversionPanel.conversion_imported_successfully'));
        });
    }

    validateFilterObj = async (data) => {
        
        let isSearchFormValid = true;
        if(new Date(data.startDate).getTime() > new Date(data.endDate).getTime()){
            isSearchFormValid=false;
            toast.error(this.props.t('search.validation.end_date_should_be_greater_than_start_date'));
        }

        await Object.keys(data).forEach(el=> {
                    if(data[el] && data[el]['value']){
                        data[el] = data[el]['value']
                    }else if(data[el] && Array.isArray(data[el])){
                        data[el] = data[el].map(elm=>{ return elm.value});
                    }else{
                        data[el] = data[el]
                    }
            });
        
        if(this._isMounted) await this.setState({filterData:data, loading: (isSearchFormValid) ? true : false});
        return isSearchFormValid;
    }

    handleRequestData = async (data) => {
        let postData = await this.validateFilterObj(data);
        let page = data.page_number;
        
        if(data['action'] && data['action'] === 'resetAll'){
            await this.setState({formAction:'resetAll', loading: false,leadList: [],page: page, pagination: {}, totalLengthWithoutPagination:  0, runPaginationFunc: false});
            return;
        }

        let filterData = this.state.filterData;
        if(filterData['cnfm_status'])   filterData['cnfm_status'] = [+filterData['cnfm_status']];

        if(postData && this._isMounted){
            await this.setState({formAction:'',loading: true,conversionPanelList: [],page: page, pagination: {}, totalLengthWithoutPagination:  0, runPaginationFunc: false});
            this.props.actions.getConversionInfoList(this.state.filterData).then(searchResult=>{
                 this.setState({loading: searchResult.loading || false,conversionPanelList: searchResult.data || [], pagination: searchResult.pagination || {}, totalLengthWithoutPagination: ((searchResult['pagination'] && searchResult['pagination']['total']) || 0), runPaginationFunc: true});
            }).catch(err=>{
                 this.setState({loading:  false,conversionPanelList: [], pagination:  {}, totalLengthWithoutPagination: 0, runPaginationFunc: false});
            });
        }
        
    }


    //HANDLE PAGINATE DATA FLAG RECIEVED FROM FilterTable SEND IT TO FilterBox COMPONENT
    callbackFunction = async (childData) => {
        if(this._isMounted) await this.setState({runPaginationFunc: childData['runPaginationFunc']});
    }

    render() {
        const { totalLengthWithoutPagination, conversionPanelList, tableHeaders, filterFieldsData,page, pagination , loading, importingData, runPaginationFunc, formAction} = this.state;
        
        return (
            <div className="container-fluid">
                <h1>{this.props.t('conversionPanel.title')}</h1>
                <FilterBox filterBox={{filterFields: filterFieldsData, showAdvanceSearchToggle: true, page: page,runPaginationFunc: runPaginationFunc, loading: loading, 'tableData': conversionPanelList} } action='conversionPanel' searchResults={this.handleSearchedData} sendRequestDataToParent={this.handleRequestData} />
                <div className="result-wrap">
                    <FilterTable filterTableData={{formAction: formAction, importDataBtn: false,title:'conversionPanel.title', loading: loading,page: page, importingData: importingData, 'totalLengthWithoutPagination': totalLengthWithoutPagination, 'tableData': conversionPanelList, 'tableHeaders': tableHeaders, action:'conversionPanel', pagination: pagination , runPaginationFunc: runPaginationFunc}} parentCallback={this.callbackFunction} updateConversionData={this.updateConversion} importExcelFile={this.importXls}/>
                </div>

            </div>)
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => {
    return {
            actions: {
                updateConversion: bindActionCreators(
                    LeadActions.updateConversion,
                    dispatch
                ),
                importXls: bindActionCreators(
                    LeadActions.importExcelFile,
                    dispatch
                ),
                getConversionInfoList: bindActionCreators(
                    LeadActions.getConversionInfoList,
                    dispatch
                )
            }
            }
}

export default withTranslation('lead')(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConversionPanel)));