import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class L1LeadSnapshot extends Component {
  constructor(props) {
    super(props);
    this.state = {
        searchResult:props.searchResult,
        totalRecord:props.totalRecord,
        loading: false,
        pageloading: false,
        totalCalculationObject:props.totalCalculationObject
    }
  }
  static getDerivedStateFromProps(nextprops, prevState) {
    var updatedObj = {}
    if (prevState.searchResult !== nextprops.searchResult) {
      updatedObj['searchResult'] = nextprops.searchResult;
    }
    if (prevState.totalRecord !== nextprops.totalRecord) {
        updatedObj['totalRecord'] = nextprops.totalRecord;
    }
    if(nextprops.loading !== prevState.loading){
        updatedObj['loading'] = nextprops.loading;
    }

    if(nextprops.pageloading !== prevState.pageloading){
        updatedObj['pageloading'] = nextprops.pageloading;
    }

    if(nextprops.totalCalculationObject !== prevState.totalCalculationObject){
        updatedObj['totalCalculationObject'] = nextprops.totalCalculationObject;
    }

    return updatedObj;
}


  render() {
    const {totalCalculationObject} = this.state;
    return (
      
        <table className="table table-bordered table-hover table-striped deliveryTable">
            <thead>
            <tr>
            	<th>{this.props.t('leadSnapshot.l1Lead.cluster')}</th>
            	<th colSpan="6">{this.props.t('leadSnapshot.l1Lead.nonContacted')}</th>
                <th colSpan="6">{this.props.t('leadSnapshot.l1Lead.contacted')}</th>
            </tr>
            <tr>
            	<th>{this.props.t('leadSnapshot.l1Lead.cluster')}</th>
            	<th>{this.props.t('leadSnapshot.l1Lead.NYC')}</th>
                <th>{this.props.t('leadSnapshot.l1Lead.1st')}</th>
                <th>{this.props.t('leadSnapshot.l1Lead.2nd')}</th>               
                <th>{this.props.t('leadSnapshot.l1Lead.3rd')}</th>                
                <th>{this.props.t('leadSnapshot.l1Lead.4th')}</th>  
                <th>{this.props.t('leadSnapshot.l1Lead.more')}</th>  
                <th>{this.props.t('leadSnapshot.l1Lead.reached')}</th>  
                <th>{this.props.t('leadSnapshot.l1Lead.1st')}</th>
                <th>{this.props.t('leadSnapshot.l1Lead.2nd')}</th>               
                <th>{this.props.t('leadSnapshot.l1Lead.3rd')}</th>                
                <th>{this.props.t('leadSnapshot.l1Lead.4th')}</th>  
                <th>{this.props.t('leadSnapshot.l1Lead.more')}</th> 
            </tr>
        </thead>

            <tbody>
            {(this.state.searchResult.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="11"><h6 className="text-center text-danger text-bold">{this.props.t('salesFunnel.NoRecordFound')}</h6></td></tr> : null}
            {(this.state.searchResult && this.state.searchResult.length !== 0) && this.state.searchResult.map((reportData, k) =>
                <tr key={k}>
                    <td>{reportData.cluster_name}</td>
                    <td>{(reportData.totalNyc) ? reportData.totalNyc : ''}{(reportData.totalNyc && reportData.allLeads) ? ' ('+((reportData.totalNyc/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalFirst) ? reportData.totalFirst : ''}{(reportData.totalFirst && reportData.allLeads) ? ' ('+((reportData.totalFirst/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalSec) ? reportData.totalSec : ''}{(reportData.totalSec && reportData.allLeads) ? ' ('+((reportData.totalSec/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalThird) ? reportData.totalThird : ''}{(reportData.totalThird && reportData.allLeads) ? ' ('+((reportData.totalThird/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalFourth) ? reportData.totalFourth : ''}{(reportData.totalFourth && reportData.allLeads) ? ' ('+((reportData.totalFourth/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalMore) ? reportData.totalMore : ''}{(reportData.totalMore && reportData.allLeads) ? ' ('+((reportData.totalMore/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalReached) ? reportData.totalReached : ''}{(reportData.totalReached && reportData.allLeads) ? ' ('+((reportData.totalReached/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalReachedFirst) ? reportData.totalReachedFirst : ''}{(reportData.totalReachedFirst && reportData.allLeads) ? ' ('+((reportData.totalReachedFirst/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalReachedSec) ? reportData.totalReachedSec : ''}{(reportData.totalReachedSec && reportData.allLeads) ? ' ('+((reportData.totalReachedSec/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalReachedThird) ? reportData.totalReachedThird : ''}{(reportData.totalReachedThird && reportData.allLeads) ? ' ('+((reportData.totalReachedThird/reportData.allLeads)*100)+'%)' : ''}</td>
                    
                    <td>{(reportData.totalReachedFourth) ? reportData.totalReachedFourth : ''}{(reportData.totalReachedFourth && reportData.allLeads) ? ' ('+((reportData.totalReachedFourth/reportData.allLeads)*100)+'%)' : ''}</td>
                    <td>{(reportData.totalReachedMore) ? reportData.totalReachedMore : ''}{(reportData.totalReachedMore && reportData.allLeads) ? ' ('+((reportData.totalReachedMore/reportData.allLeads)*100)+'%)' : ''}</td>
                </tr>
                )}
                {(totalCalculationObject.sumTotalAllLeads) ? 
                <tr className="totalvalue">
                    <td>{this.props.t('salesFunnel.total')}</td>
                    <td>{(totalCalculationObject.sumTotalNyc) ? totalCalculationObject.sumTotalNyc : ''}{(totalCalculationObject.sumTotalNyc && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalNyc/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalFirst) ? totalCalculationObject.sumTotalFirst : ''}{(totalCalculationObject.sumTotalFirst && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalFirst/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalSec) ? totalCalculationObject.sumTotalSec : ''}{(totalCalculationObject.sumTotalSec && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalSec/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalThird) ? totalCalculationObject.sumTotalThird : ''}{(totalCalculationObject.sumTotalThird && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalThird/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalFourth) ? totalCalculationObject.sumTotalFourth : ''}{(totalCalculationObject.sumTotalFourth && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalFourth/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalMore) ? totalCalculationObject.sumTotalMore : ''}{(totalCalculationObject.sumTotalMore && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalMore/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalReached) ? totalCalculationObject.sumTotalReached : ''}{(totalCalculationObject.sumTotalReached && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalReached/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalReachedFirst) ? totalCalculationObject.sumTotalReachedFirst : ''}{(totalCalculationObject.sumTotalReachedFirst && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalReachedFirst/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalReachedSec) ? totalCalculationObject.sumTotalReachedSec : ''}{(totalCalculationObject.sumTotalReachedSec && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalReachedSec/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalReachedThird) ? totalCalculationObject.sumTotalReachedThird : ''}{(totalCalculationObject.sumTotalReachedThird && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalReachedThird/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalReachedFourth) ? totalCalculationObject.sumTotalReachedFourth : ''}{(totalCalculationObject.sumTotalReachedFourth && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalReachedFourth/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                    <td>{(totalCalculationObject.sumTotalReachedMore) ? totalCalculationObject.sumTotalReachedMore : ''}{(totalCalculationObject.sumTotalReachedMore && totalCalculationObject.sumTotalAllLeads) ? ' ('+((totalCalculationObject.sumTotalReachedMore/totalCalculationObject.sumTotalAllLeads)*100).toFixed(2)+'%)' : ''}</td>
                </tr> : null}
                {
                    (this.state.loading) ? <tr><td className="loading" colSpan="11"></td></tr> : null
                }
            </tbody>
        </table>
                        
    );
  }
}
export default withTranslation('report') (L1LeadSnapshot);
