import MasterService from './MasterService';

const UserService = {
    ...MasterService,
    
    getUserList(postdata){
        return this.post('/user/listUser',postdata) 
    },
    updateUserDetails(postdata){
        return this.post('/user/editUser',postdata) 
    },
    saveUserDetails(postdata){
        return this.post('/user/saveUser',postdata) 
    },
    getRoleList(postdata){
        return this.post('/role/listRole',postdata) 
    },
    getCityList(postdata){
        return this.post('/city/listCity',postdata) 
    },
    updateStatus(postdata){
        return this.post('/user/updateStatus',postdata) 
    },
    getAllUserList(){
        return this.post('/user/listAllUser') 
    },
    getUser(postdata){
        return this.post('/user/getUser',postdata) 
    },
    getTlList(postdata){
        return this.get('/user/get_tl_list',postdata) 
    }

}

export default UserService;
