import React, { Component } from 'react';
import ModalPopup from './../../elements/Popup';
import CheckBox from '../../elements/CheckBox';
import Carousel from '../../elements/Img-ListingCarousal';
//import FlagReasons from './Flag-Popup';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import secureStorage from '../../../config/encrypt';
import { default as config } from '../../../config/config';
import { toast } from 'react-toastify';
// const carouselSlidesData = [
//     {
//       src:"https://ik.imagekit.io/hj8sm3kk7/otousedcar/car_image/02020/1579693367387.jpg",
//     }, 
//     {
//       src:"./../../img/gallaryImg.jpg",
//     }, 
//     {
//       src:"https://ik.imagekit.io/hj8sm3kk7/otousedcar/car_image/02020/1579693367387.jpg",
//     }, 
//     {
//       src:"./../../img/gallaryImg.jpg",
//     }, 
//     {
//       src:"./../../img/gallaryImg.jpg",
//     }, 
//   ];
class RecommendcarsTable extends Component {
    roleId = 0;
    constructor(props) {
        super(props);
        this.state = {
            displayCarDetails:{
                'show':false,
                'id':0
            },
            showFlag: false,
            recomendCarList:props.recomendCarList,
            leadDetailsData : props.leadDetailsData,
            postData:[],
            filterData:props.filterData,
            isClearAll : false,
            leadInfo:{},
            inventoryFlagList: config.constants.DEALER_FLAG,
            filter: 'ALL',
            selectedFlagCarId:0,
            formData : props.formData,
            dealerFlag:[],
            loading:false,
            pageloading:false,
            mmvList : [],
            leadsCarList:[],
            refreshRecommendsCarsData: false
        }
        this.carDetailShow = this.carDetailShow.bind(this);
        let authUserInfo = secureStorage.getItem('authUserInfo');
        this.roleId = (authUserInfo && authUserInfo['authUser'] && authUserInfo['authUser']['role_id']) ? authUserInfo['authUser']['role_id'] : 0;
      
    }

    componentDidMount = () => {
    }

    UNSAFE_componentWillReceiveProps(nextProps) { 
        let leadInfo = (nextProps.leadDetailsData && nextProps.leadDetailsData.length) ? nextProps.leadDetailsData[0] : {};
// console.log('nextProps', nextProps);
        //EXCLUDE CARS WHICH ARE PRESENT IN ASSIGNED CARS
        // let recommendedListsUpdated = nextProps.recomendCarList;
        // if(nextProps.recomendCarList && nextProps.recomendCarList.length){
        //     if(nextProps.leadsCarList && nextProps.leadsCarList.length){
        //         let leadsCarsIds = nextProps.leadsCarList.map(cars=> cars.car_id);
        //         recommendedListsUpdated = nextProps.recomendCarList.filter(el=> !leadsCarsIds.includes(el.id) )
        //     }
        // }
        //UPDATE RECOMMEND CAR LENGTH COUNT
        // this.props.recommendCarlen(recommendedListsUpdated.length);
        let stateLoading = (nextProps.loading !== this.state.loading) ? nextProps.loading : this.state.loading;
        this.setState({
            //|| nextProps.refreshRecommendsCarsData || !nextProps.recomendCarList.length
            loading: stateLoading,
            // recomendCarList: ((stateLoading && !this.state.recomendCarList.length) || (!nextProps.recomendCarList.length && !this.state.recomendCarList.length) || (nextProps.recomendCarList.length && this.state.recomendCarList.length && nextProps.recomendCarList[0]['id'] != this.state.recomendCarList[0]['id'])) ? [] : nextProps.recomendCarList,
            recomendCarList: (((stateLoading && nextProps.formData.page_no===1) || (!stateLoading && nextProps.formData.page_no===1 && !nextProps.recomendCarList.length))  || nextProps.refreshRecommendsCarsData || !nextProps.recomendCarList.length) ? [] : nextProps.recomendCarList,
            leadDetailsData : leadInfo,
            leadInfo: leadInfo,
            filterData:nextProps.filterData,
            formData:nextProps.formData,
            // loading:nextProps.loading,
            pageloading:nextProps.pageloading,
            mmvList : nextProps.mmvList,
            leadsCarList: nextProps.leadsCarList,
            refreshRecommendsCarsData: (nextProps.refreshRecommendsCarsData !== this.state.refreshRecommendsCarsData) ? nextProps.refreshRecommendsCarsData : this.state.refreshRecommendsCarsData
        });

        if((nextProps.refreshRecommendsCarsData !== this.state.refreshRecommendsCarsData)){
            this.props.updateRefreshRecommendsCarsDataFlag();
        }
    }

    renderCheckboxes = () => {
        const {inventoryFlagList, filter} = this.state;
    
        return inventoryFlagList
            .filter(checkbox =>
                (filter === 'ALL') ||
                (filter === 'CHECKED' && checkbox.checked) ||
                (filter === 'UNCHECKED' && !checkbox.checked)
            )
            .map((checkbox, index) =>
                <CheckBox key={index} type="checkbox" label={checkbox.label} name={"iussue_"+checkbox.value} id={"iussue_"+checkbox.value} checked={('checked' in checkbox) ? checkbox.checked : false}
                onChange={this.handleFlagChange.bind(this, index)} />
                
            );
    }

    handleFlagChange = (index) => {
        const {inventoryFlagList} = this.state;
        let inventoryFlag = inventoryFlagList[index];
        inventoryFlag['checked'] = (inventoryFlag.hasOwnProperty('checked')) ? !inventoryFlag.checked : true;
        inventoryFlagList[index] = inventoryFlag;
    
        this.setState({
            inventoryFlagList
        });
    }

    carDetailShow(id){
        const {displayCarDetails} = this.state;
        if(displayCarDetails.id === id)
            displayCarDetails.show = !displayCarDetails.show;
        else{
            displayCarDetails.show = true; 
            displayCarDetails.id = id;
        }
        this.setState({displayCarDetails})
    }

    flagCloseModal = () => {
        this.setState({ showFlag: false });
    }

    flagShowModal = (carId) => {
        let {inventoryFlagList} = this.state;
        _.forEach(inventoryFlagList,(flagObj)=>{
            flagObj['checked']=false;
        })
        this.setState({ showFlag: true,selectedFlagCarId:carId,inventoryFlagList: inventoryFlagList});
    }

    handleChange = async (index) => {
        let {recomendCarList,postData,leadDetailsData} = this.state;
        let carDetails = recomendCarList[index];
        if(carDetails.hasOwnProperty('isCarChecked')){
            carDetails.isCarChecked = !carDetails.isCarChecked;
        }else{
            carDetails['isCarChecked'] = true;
        }        
        if(carDetails['isCarChecked']){
            //if(postData.length){
                let use_car_ids ={
                    'car_id' : carDetails.id,
                    'dealer_id' : carDetails.dealer_id,
                    'lead_id' : leadDetailsData.id,
                    'customer_id' : leadDetailsData.customer_id
                }
                let isCarExist = _.find((postData),{'car_id':carDetails.id});
                if(!isCarExist)
                    postData.push(use_car_ids)
           // }
        }else{
            postData = _.filter(postData, function(currentObject) {
                return currentObject.car_id !== carDetails.id;
            });
        }
         
        recomendCarList[index] = carDetails;
        this.setState({recomendCarList,postData})
        
    }

    copyCarDetails = (carDetails) => {
        let tempLead = 0;
        let {postData} = this.state;
        if (typeof this.props.copyCarDetails === 'function') {
            this.props.copyCarDetails({'used_car_ids':postData,'temp_lead_id':tempLead});
        }
        
    }

    saveRecomendCar = async () => {
        let {postData} = this.state;
        if (typeof this.props.saveRecomendCar === 'function') {
            if(postData.length){
                this.props.saveRecomendCar({'used_car_ids':postData});
                await this.setState({postData:[], loading: true, recomendCarList: []});
            }else{
                toast.error('Please select at least one car')
            }
        }

        
    }
    clearFilterAndGetUpdatedList = async (key,clearAll) => {
        let {formData,isClearAll} = this.state;
        
        if(clearAll){
            isClearAll = true;
            formData={}
        }else{
            delete formData[key];
        }
       
        await this.setState({formData,isClearAll}); 
        
        if (typeof this.props.clearFilterAndGetUpdatedList === 'function') {
            this.props.clearFilterAndGetUpdatedList(formData, (clearAll?'clearAll':''));
        }
        
    }

    getLabelByKey = (key,value) => {
        // let label = '';
        // if(key === 'color'){
        //     const {COLOUR_ARR} = config.constants;
        //     let filterObj = _.find(COLOUR_ARR,{'value':value})
        //     label = (filterObj) ? filterObj.label : ''
        // }else{
        //     let filterList = this.state.filterData;
        //     let filterObj = _.find(filterList[key],{'value':value})
        //     label = (filterObj) ? filterObj.label : ''
        // }
        
        // return label;
        let label = '';
        let filterList = this.state.filterData;
        
        switch(key){            
            case "car_make":
                let {mmvList:{make} = {}} = this.state;
                if(make){
                    label = make.filter(e=>value.includes(e.id)).map(e=>e.make)
                    label = label.join(" , ")
                }
                break;
            case "car_model":
                let {mmvList:{model} = {}} = this.state;
                if(model){
                    label = model.filter(e=>value.includes(e.id)).map(e=>e.m)
                    label = label.join(" , ")
                }
                break;
            case 'min_price':
            case 'max_price':
                if(filterList.hasOwnProperty('priceRange')){
                   
                    let record = filterList['priceRange'].find(e=>+e.value === +value) 
                    if(record){
                        label = record.label;
                    }
                }
                break;
            case 'max_km_driven':
                if(filterList.hasOwnProperty('kmRange')){
                    let record = filterList['kmRange'].find(e=>e.value === value) 
                    if(record){
                        label = record.label;
                    }
                }
                break;
            case 'min_make_year':
                if(filterList.hasOwnProperty('fromYearRange')){
                    let record = filterList['fromYearRange'].find(e=>e.value === value) 
                    if(record){
                        label = record.label;
                    }
                }
                break;
            case 'max_make_year':
                if(filterList.hasOwnProperty('toYearRange')){
                    let record = filterList['toYearRange'].find(e=>e.value === value) 
                    if(record){
                        label = record.label;
                    }
                }
                break;
            case 'fuel_type_id':
                
                if(filterList.hasOwnProperty('fuelType')){
                    let record = filterList['fuelType'].filter(e=>value.includes(e.value)).map(e=>e.label) 
                    if(record){
                        label = record.join(" , ");
                    }
                }
                break;
            case 'uc_transmission_id':
                
                if(filterList.hasOwnProperty('transmission')){
                    let record = filterList['transmission'].filter(e=>value.includes(e.value)).map(e=>e.label) 
                    if(record){
                        label = record.join(" , ");
                    }
                }
                break;
            case 'color' :
                if(filterList.hasOwnProperty('color')){
                    let record = filterList['color'].filter(e=>value.includes(e.value)).map(e=>e.label) 
                    if(record){
                        label = record.join(" , ");
                    }
                }
                break;
            case 'body_type_id' : 
                if(filterList.hasOwnProperty('bodyType')){
                    let record = filterList['bodyType'].filter(e=>value.includes(e.value)).map(e=>e.label) 
                    if(record){
                        label = record.join(" , ");
                    }
                }
                break; 
            case 'city' :   
                if(filterList.hasOwnProperty('cityList')){
                    let record = filterList['cityList'].filter(e=>value.includes(e.value)).map(e=>e.label) 
                    if(record){
                        label = record.join(" , ");
                    }
                }
                break;  
            case 'dealer_ids' :   
                if(filterList.hasOwnProperty('dealers')){
                    let record = filterList['dealers'].filter(e=>value.includes(e.value)).map(e=>e.label) 
                    if(record){
                        label = record.join(" , ");
                    }
                }
                break;
            case 'dealer_type' : 
                let record = config.constants.DEALER_TYPE_PRIORITY.filter(e=> value.join().split(',').indexOf(e.value) > -1).map(e=>e.label) ;
                    if(record){
                        label = record.join(" , ");
                    }
                break; 
            case 'customer_locality' :
                    if(filterList.hasOwnProperty('customerLocalityList')) {
                        let record = filterList['customerLocalityList'].find(e=>+e.value === +value) ;
                        if(record) {
                            label = record.label;           
                        }
                    }
                break; 
            default:                
                
        }
        return label;
    }

    getFilterList = (key) => {
        let {formData} = this.state;
        formData[key]= (key in formData) ? !formData[key] : true;
        this.setState(formData);
    }

    saveInventoryFlag =() => {
        const {inventoryFlagList,selectedFlagCarId} = this.state;
        let selectedFlag = [];
        _.forEach(inventoryFlagList,(flagObj)=>{
            if(flagObj.checked)
                selectedFlag.push(flagObj.value)
        })
        if(selectedFlag.length && typeof this.props.saveDealerFlag === 'function'){
            this.flagCloseModal()
            this.props.saveDealerFlag({'car_id':selectedFlagCarId,'dealer_flag':selectedFlag});
        }else{
            toast.warning(this.props.t('recomendTab.list.please_select_atleast_one_dealer_flag'));
        }
    }
        
    render() {
      const {recomendCarList,isClearAll,formData} = this.state;
      
      const {inventoryModule} = config.constants; 
      
      return (
          
        <div>
            <div className="card">
                <div className="card-heading">
                    <div className="rc-inline">
                        {/* <CheckBox type="checkbox" label={this.props.t('recomendTab.list.include_similar_cars')} name="similarInventory" id="similarInventory" onChange={this.getFilterList.bind(this,'similar_inventory')} /> */}
                        {/* <CheckBox type="checkbox" label={this.props.t('recomendTab.list.inventory_with_leads_grt_35')} name="inventoryLeadGreater" id="inventoryLeadGreater" onChange={this.getFilterList.bind(this,'inventory_lead_greater_35')}/> */}
                        {/* {(Number(this.roleId) === Number(ADMIN_ROLE_ID) || Number(this.roleId) === Number(CHAT_AGENT_ROLE_ID)) ? 
                            <button type="button" disabled={(this.state.postData.length > 1) ? true:false} className="btn btn-primary mrg-r15" onClick={this.copyCarDetails}>{this.props.t('recomendTab.list.copyDetails')}</button>
                        : ''} */}
                        <button type="button" className="btn btn-primary" onClick={this.saveRecomendCar}>{this.props.t('recomendTab.list.recommendCars')} {(this.state.postData.length) ? '('+this.state.postData.length+')' : ''}</button>
                    </div>

                   
                </div>
                <div className="clearfix">
                    {(!isClearAll) ?
                    <div className="chip-set">
                        
                        {(formData.car_make && formData.car_make.length)   ?   <span className="chips">{this.getLabelByKey('car_make',formData.car_make)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'car_make',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.car_model && formData.car_model.length)   ?   <span className="chips">{this.getLabelByKey('car_model',formData.car_model)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'car_model',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.min_price && formData.min_price)   ?   <span className="chips">{this.getLabelByKey('min_price',formData.min_price)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'min_price',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.max_price && formData.max_price)   ?   <span className="chips">{this.getLabelByKey('max_price',formData.max_price)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'max_price',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.max_km_driven && formData.max_km_driven)   ?   <span className="chips">{this.getLabelByKey('max_km_driven',formData.max_km_driven)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'max_km_driven',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.min_make_year && formData.min_make_year)   ?   <span className="chips">{this.getLabelByKey('min_make_year',formData.min_make_year)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'min_make_year',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.max_make_year && formData.max_make_year)   ?   <span className="chips">{this.getLabelByKey('max_make_year',formData.max_make_year)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'max_make_year',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.fuel_type_id && formData.fuel_type_id.length)   ?   <span className="chips">{this.getLabelByKey('fuel_type_id',formData.fuel_type_id)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'fuel_type_id',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.uc_transmission_id && formData.uc_transmission_id.length)   ?   <span className="chips">{this.getLabelByKey('uc_transmission_id',formData.uc_transmission_id)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'uc_transmission_id',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.body_type_id && formData.body_type_id.length)   ?   <span className="chips">{this.getLabelByKey('body_type_id',formData.body_type_id)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'body_type_id',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.color && formData.color.length)   ?  <span className="chips">{this.getLabelByKey('color',String(formData.color))} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'color',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.city && formData.city.length)   ?   <span className="chips">{this.getLabelByKey('city',formData.city)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'city',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.dealer_ids && formData.dealer_ids.length)   ?   <span className="chips">{this.getLabelByKey('dealer_ids',formData.dealer_ids)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'dealer_ids',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.dealer_type && formData.dealer_type.length)   ?  <span className="chips">{this.getLabelByKey('dealer_type',formData.dealer_type)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'dealer_type',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.customer_locality)   ?  <span className="chips">{this.getLabelByKey('customer_locality',formData.customer_locality)} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'customer_locality',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        

                        {/* {(formData.max_price_range) ?   <span className="chips">Under {this.getLabelByKey('priceRange',String(formData.max_price_range))} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'max_price_range',false)}><i className="ic-clearclose"></i></span></span> : ''}
                        {(formData.km_range) ?   <span className="chips">Under {this.getLabelByKey('kmRange',String(formData.km_range))} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'km_range',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.fuel_type && formData.fuel_type.length)   ?   <span className="chips">{this.getLabelByKey('fuelType',Number(formData.fuel_type[0]))} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'fuel_type',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.model_ids && formData.model_ids.length)   ?   <span className="chips">{this.getLabelByKey('makeModel',Number(formData.model_ids[0]))} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'model_ids',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        
                        {(formData.owner && formData.owner.length)  ?   <span className="chips">{this.getLabelByKey('owner',String(formData.owner[0]))} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'owner',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.req_body_type && formData.req_body_type.length)   ?   <span className="chips">{this.getLabelByKey('bodyType',Number(formData.req_body_type[0]))} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'req_body_type',false)}><i className="ic-clearclose"></i></span></span> : '' }
                        {(formData.transmission)   ? <span className="chips">{this.getLabelByKey('transmission',Number(formData.transmission))} <span className="mrg-l5" onClick={this.clearFilterAndGetUpdatedList.bind(this,'transmission',false)}><i className="ic-clearclose"></i></span></span> : '' }
                         */}
                        {
                            (
                                (formData.car_make && formData.car_make.length)
                                ||
                                (formData.car_model && formData.car_model.length)
                                ||
                                (formData.min_price && formData.min_price)
                                ||
                                (formData.max_price && formData.max_price)
                                ||
                                (formData.max_km_driven && formData.max_km_driven)
                                ||
                                (formData.min_make_year && formData.min_make_year)
                                ||
                                (formData.max_make_year && formData.max_make_year)
                                ||
                                (formData.fuel_type_id && formData.fuel_type_id.length)
                                ||
                                (formData.uc_transmission_id && formData.uc_transmission_id.length)
                                
                            )
                            ?
                                <span className="text-link" onClick={this.clearFilterAndGetUpdatedList.bind(this,'clearAll',true)}>{this.props.t('recomendTab.list.clearAll')}</span>
                            :
                                ''
                        }
                    </div> : '' }
                </div>
                <div className="table-responsive" id="recommendedCars">
                    <table className="table table-bordered table-hover table-striped assignedcar-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{this.props.t('recomendTab.list.carDetails')}</th>
                                <th>{this.props.t('recomendTab.list.mkYear')}</th>
                                <th>{this.props.t('recomendTab.list.priceRp')}</th>
                                <th>{this.props.t('recomendTab.list.seller')}</th>
                                <th>{this.props.t('recomendTab.list.priority')}</th>
                                <th>{this.props.t('recomendTab.list.location')}</th>
                                <th>{this.props.t('recomendTab.list.distance')}</th>
                                <th>{this.props.t('recomendTab.list.leadCount')}</th>
                                <th>{this.props.t('recomendTab.list.ftd')}</th>
                                <th>{this.props.t('recomendTab.list.flag')}</th>
                                <th>{this.props.t('recomendTab.list.seller_type')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(recomendCarList && recomendCarList.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="11"><h6 className="text-center text-danger text-bold">{this.props.t('search.NoRecordFound')}</h6></td></tr> : null}
                        {(recomendCarList && recomendCarList.length !== 0) && recomendCarList.map((carList, k) => {
                            let ownerType = inventoryModule.ownerArr.filter((el)=> (el['value'] === ''+carList.owner_type) );
                            let zigwheelUrl = '';
                            let carmudiUrl = '';
                            let otoUrl = '';
                            //web url                                    
                            if ((carList.web_url && Array.isArray(carList.web_url))) {
                                let carmudi = carList.web_url.filter(cars => cars.key === 'carmudi');
                                carmudiUrl = carmudi[0].url;
                                let zigwheel = carList.web_url.filter(cars => cars.key === 'zigwheels');
                                zigwheelUrl = zigwheel[0].url;
                            } else if (carList.web_url && (Array.isArray(carList.web_url) === false)) {
                                otoUrl = carList.web_url;
                            }

                            return (<React.Fragment  key={k}>
                                
                            <tr>
                                <td><CheckBox type="checkbox"  name={"Trustmark"+carList.id} id={"Trustmark"+carList.id} checked={(carList.isCarChecked) ? true : false} onChange={this.handleChange.bind(this,k)}/></td>
                                <td>
                                {(carList.make) ? carList.make :''} {(carList.modelVersion) ? carList.modelVersion : ''} <span onClick={this.carDetailShow.bind(this,carList.id)}><i className="ic-error"></i></span>
                                    <ul className="requirements-list">
                                        <li>{(carList.uc_colour) ? carList.uc_colour:carList.other_colour}</li>
                                            {
                                                (carList.km_driven)
                                                ?
                                                <li>{carList.km_driven}</li>
                                                :
                                                ''
                                            }

                                            {
                                                (carList.fuel_type)
                                                ?
                                                <li>{carList.fuel_type}</li>
                                                :
                                                ''
                                            }
                                            {
                                                (ownerType && ownerType.length && ownerType[0]['label'])
                                                ?
                                                <li>{ownerType[0]['label']}</li>
                                                :
                                                ''
                                            }

                                            {
                                                (carList.transmission)
                                                ?
                                                <li>{carList.transmission}</li>
                                                :
                                                ''
                                            }
                                    </ul>
                                </td>
                                <td>{carList.make_year}</td>
                                <td>
                                    <div className="price-pop"><span>{carList.display_price}</span>
                                        {/* <div className="popover bs-popover-bottom" >
                                            <span className="arrow"></span>
                                            <div className="popover-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="tt-label">{this.props.t('recomendTab.list.truePrice')}</div>
                                                    <div className="tt-detail"><span id="truePrice">{(carList.newCarPriceInfo && carList.newCarPriceInfo.newCarPrice) ? carList.newCarPriceInfo.newCarPrice : 'N/A'}</span></div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="tt-label">{this.props.t('recomendTab.list.difference')}</div>
                                                    <div className="tt-detail">{(carList.newCarPriceInfo && carList.newCarPriceInfo.newCarDiffPrice) ? carList.newCarPriceInfo.newCarDiffPrice : 'N/A'}<span id="truePriceDiff"></span></div>
                                                </div>
                                            </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </td>
                                
                                <td>{(carList && carList.dealer_detail) ? carList.dealer_detail['organization']+ ((carList.user_type === 'D') ? `(Dealer GCD${carList.dealer_id})` : '')  : ''}<br/>
                                    {
                                        ((carList && carList.dealer_detail && carList.dealer_detail['organization']))
                                        ? 
                                            (
                                                (carList.user_type === 'D')
                                                ?
                                                    <React.Fragment>
                                                        <span>{'(Dealer) '}</span>
                                                    
                                                        <p>{((carList.dealer_detail && carList.dealer_detail.address) ? carList.dealer_detail.address : '')}</p>
                                                    </React.Fragment>
                                                    
                                                : 
                                                    '(Individual)'
                                            ) 
                                        : 
                                            ''
                                    }
                                </td>
                                {/* <td>{(carList.dealerDetails && carList.dealerDetails.dealer_locality_name) ? carList.dealerDetails.dealer_locality_name : ''}{(carList.dealerDetails && carList.dealerDetails.city) ? ', '+carList.dealerDetails.city : ''}</td> */}
                                <td>{(carList.dealer_detail)?carList.dealer_detail.dealer_type:''}</td>                                
                                <td>{carList.city_name || ''}</td>
                                <td>{(carList && carList.dealer_detail) ? (carList.dealer_detail['dis'] > 10)?' >10 ': carList.dealer_detail['dis'] : ''}</td>
                                <td>{carList.totalUniqueLeadsCount}</td>
                                <td>{carList.ftdCount}</td>
                                <td><span onClick={this.flagShowModal.bind(this,carList.id)}><i className="ic-flagassistant_photo icons-normal"></i></span></td>
                                <td>{

                                    (carList.user_type === 'D')
                                    ?
                                        (carList.paid_score === 1)? "Free" : ((carList.paid_score === 2) ? "Paid" : "Paid Pro")
                                    :
                                    "Free"
                                }</td>
                            </tr>
                            
                            <tr className={ (this.state.displayCarDetails.id === carList.id && this.state.displayCarDetails.show) ? " " : "hide"}>
                                <td colSpan="9" className="recCarDetails">
                                    <div className="row">
                                        <span className="arrow"></span>
                                            {
                                            carList.car_image.length
                                            ?
                                                <div className="col-sm-4">
                                                    <Carousel id="recmimg" slides={carList.car_image} />
                                                </div>
                                            :
                                                ''
                                            }
                                        <div className="col-sm-8">
                                            <div>
                                                <h5>{(carList.make) ? carList.make :''} {(carList.modelVersion) ? carList.modelVersion : ''}</h5>
                                                <div className="row mrg-b15">
                                                    <div className="col-md-3">
                                                        <div className="tt-label">{this.props.t('recomendTab.list.registrationNo')}</div>
                                                        <div className="tt-detail"><strong>{(carList.reg_no)? carList.reg_no:''}</strong></div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="tt-label">{this.props.t('recomendTab.list.insurance')}</div>
                                                        <div className="tt-detail">
                                                        <strong>{(carList.insurance) ? carList.insurance : ''}</strong></div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="tt-label">{this.props.t('recomendTab.list.transmission')}</div>
                                                        <div className="tt-detail"><strong>{(carList.transmission) ? carList.transmission : ''}</strong></div>
                                                    </div>
                                                    
                                                    <div className="col-md-3">
                                                        <div className="tt-label">See Car</div>
                                                        {(carmudiUrl) ? <div className="tt-detail"><strong><a href={carmudiUrl} target="_blank"> Carmudi</a></strong></div>:''}
                                                        {(zigwheelUrl) ? <div className="tt-detail"><strong><a href={zigwheelUrl} target="_blank"> ZigWheels</a></strong></div>:''}
                                                        {(otoUrl)?<div className="tt-detail"><strong> <a href={otoUrl} target="_blank">Car Url</a></strong></div>:''}
                                                        
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="tt-label">&nbsp;</div>
                                                        <div className="tt-detail hf-clr"></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className=" mrg-b5"><span><i className="ic-location_onplaceroom"></i></span>{this.props.t('recomendTab.list.address')}  : {(carList.dealer_detail && carList.dealer_detail.address) ? carList.dealer_detail.address : ''}</div>
                                                    <div className=" mrg-b5"><span><i className="ic-call"></i></span> {(carList.dealer_detail && carList.dealer_detail.dealership_contact) ? carList.dealer_detail.dealership_contact : ''}</div>
                                                    <div className=" mrg-b5"><span><i className="ic-person"></i></span>  {(carList.dealer_detail && carList.dealer_detail.organization) ? carList.dealer_detail.organization : ''}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            
                            </React.Fragment>)
    }
                            )}
                            {
                                (this.state.loading) ? <tr><td className="loading" colSpan="9"></td></tr> : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {this.state.showFlag === false ? null :
            <ModalPopup id="timelinePopup" className="modal" title={this.props.t('recomendTab.list.reportTheIssueWithThisListing')}  modalClose={this.flagCloseModal}>
            <div className="pd-15">
                <form>  
                    <div className="form-group clearfix">
                        {this.renderCheckboxes.call(this)}
                        {/* <CheckBox type="checkbox" label="Photo & car not matching" name="iussue" id="iussue-1" />
                        <CheckBox type="checkbox" label="Very low price/Incorrect price" name="iussue" id="iussue-2" />
                        <CheckBox type="checkbox" label="Registration No. & Registration city not matching" name="iussue" id="iussue-3" />
                        <CheckBox type="checkbox" label="Phone No. visible on image" name="iussue" id="iussue-4" />
                        <CheckBox type="checkbox" label="Blurred photo" name="iussue" id="iussue-5" />
                        <CheckBox type="checkbox" label="Offensive content" name="iussue" id="iussue-6" />
                        <CheckBox type="checkbox" label="Incorrect model year" name="iussue" id="iussue-7" />
                        <CheckBox type="checkbox" label="Sunroof is not present" name="iussue" id="iussue-8" />
                        <CheckBox type="checkbox" label="Outstation" name="iussue" id="iussue-9" />
                        <CheckBox type="checkbox" label="CNG kit is not available" name="iussue" id="iussue-10" /> */}
                    </div>
                    <div className=" form-group text-right">
                        <button type="button" name="cancel" className="btn btn-default mrg-r15" onClick={this.flagCloseModal.bind(this)}>{this.props.t('recomendTab.list.cancel')}</button>
                        <button type="button" name="submit" className="btn btn-primary" onClick={this.saveInventoryFlag.bind(this)}>{this.props.t('recomendTab.list.submit')}</button>
                    </div>
                </form>
            </div>
            </ModalPopup>
            }
            {/* {this.state.showFlag === false ? null : <ModalPopup id="timelinePopup" className="modal" title="Report the issue with this listing"  modalClose={this.flagCloseModal}>
                <FlagReasons />
                </ModalPopup>
                } */}
        </div>
         
        );
    }
}

export default withTranslation('lead')(RecommendcarsTable);