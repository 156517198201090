import MasterService from './MasterService';

const RoleService = {
    ...MasterService,
    
    getRoleList(postdata){
        return this.post('/role/listRole',postdata) 
    },
    updateRoleDetails(postdata){
        return this.post('/role/editRole',postdata) 
    },
    saveRoleDetails(postdata){
        return this.post('/role/saveRole',postdata) 
    },
    updateStatus(postdata){
        return this.post('/role/updateStatus',postdata) 
    },
    getAllUserList(){
        return this.post('/user/listAllUser') 
    }

}

export default RoleService;
