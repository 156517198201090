import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class LocationWiseLeadResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
        searchResult:props.searchResult,
        totalRecord:props.totalRecord,
        loading: false,
        pageloading: false,
        totalCalculationObject:props.totalCalculationObject
    }
  }
  static getDerivedStateFromProps(nextprops, prevState) {
    var updatedObj = {}
    if (prevState.searchResult !== nextprops.searchResult) {
      updatedObj['searchResult'] = nextprops.searchResult;
    }
    if (prevState.totalRecord !== nextprops.totalRecord) {
        updatedObj['totalRecord'] = nextprops.totalRecord;
    }
    if(nextprops.loading !== prevState.loading){
        updatedObj['loading'] = nextprops.loading;
    }

    if(nextprops.pageloading !== prevState.pageloading){
        updatedObj['pageloading'] = nextprops.pageloading;
    }

    if(nextprops.totalCalculationObject !== prevState.totalCalculationObject){
        updatedObj['totalCalculationObject'] = nextprops.totalCalculationObject;
    }

    return updatedObj;
}


  render() {
    const {totalCalculationObject} = this.state;
    return (
      <div className="result-wrap">
                    <div className="card">
                        <div className="card-heading">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title pad-t7"> {this.state.totalRecord+' '+this.props.t('salesFunnel.resultsFound')}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover table-striped deliveryTable">
                                <thead>
                                    <tr>
                                        <th>{this.props.t('locationWiseLead.cluster')}</th>
                                        <th>{this.props.t('locationWiseLead.city')}</th>
                                        <th>{this.props.t('locationWiseLead.location')}</th>
                                        <th>{this.props.t('locationWiseLead.totalLead')}</th>
                                        <th>{this.props.t('locationWiseLead.premiumLeads')}</th>
                                        <th>{this.props.t('locationWiseLead.nonPremiumLeads')}</th>
                                        
                                    </tr>
                                </thead>

                                <tbody>
                                {(this.state.searchResult.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="6"><h6 className="text-center text-danger text-bold">{this.props.t('salesFunnel.NoRecordFound')}</h6></td></tr> : null}
                                {(this.state.searchResult && this.state.searchResult.length !== 0) && this.state.searchResult.map((reportData, k) =>
                                    <tr key={k}>
                                        <td>{(reportData.cluster_name) ? reportData.cluster_name : ''}</td>
                                        <td>{(reportData.city_name) ? reportData.city_name : ''}</td>
                                        <td>{(reportData.location_name) ? reportData.location_name : ''}</td>
                                        <td>{(reportData.totalLeads) ? reportData.totalLeads : ''}</td>
                                        <td>{(reportData.totalPremiumLeads) ? reportData.totalPremiumLeads : ''}</td>
                                        <td>{(reportData.totalnonPremiumLeads) ? reportData.totalnonPremiumLeads : ''}</td>
                                        
                                    </tr>
                                    )}
                                    {
                                        (this.state.loading) ? <tr><td className="loading" colSpan="6"></td></tr> : null
                                    }
                                    {(this.state.searchResult.length) ?
                                    <tr className="totalvalue">
                                        <td>{this.props.t('salesFunnel.total')}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalLead) ? totalCalculationObject.sumTotalLead : ''}</td>
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalPremiumLead) ? totalCalculationObject.sumTotalPremiumLead : ''}</td>
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalNonPremiumLead) ? totalCalculationObject.sumTotalNonPremiumLead : ''}</td>
                                    </tr> : null }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
    );
  }
}
export default withTranslation('report') (LocationWiseLeadResult);
