import { LeadConstants } from '../constants/lead.constants';
import secureStorage from '../../config/encrypt';
// secureStorage.clear();

let authUser = '';
let initialState = '';
try{
  authUser = secureStorage.getItem('authUserInfo');
  initialState = authUser ? { loggedIn: true, authUser } : {};
}catch(err){
  
}


export default function LeadReducer(state = initialState, action) {
  switch (action.type) {
    case LeadConstants.REQUEST_MAKE_MODEL_VERSION_SUCCESS:
      return {
        success: true,
        makeModelVersionList: action.resp,
        ...state
      };
    case LeadConstants.REQUEST_MAKE_MODEL_VERSION_FAILURE:
      return {
        success: false,
        makeModelVersionList: [],
        ...state
      };
    case LeadConstants.CITY_LIST_SUCCESS:
      return {
        cityList: action.resp,
        ...state
      };
    case LeadConstants.CITY_LIST_FAILURE:
      return {
        cityList: [],
        ...state
      };
    case LeadConstants.LEAD_LIST_SUCCESS:
      return {
        success: true,
        listLead: action.resp,
        ...state
      };
    case LeadConstants.LEAD_LIST_FAILURE:
      return {
        success: false,
        listLead: [],
        ...state
      };

    case LeadConstants.FILTER_LIST_SUCCESS:
      return {
        filterList: action.resp,
        ...state
      };
    case LeadConstants.FILTER_LIST_FAILURE:
      return {
        filterList: [],
        ...state
      };

    case LeadConstants.CUSTOMER_UPDATE_SUCCESS:
      return {
        customerUpdateResponse: action.resp,
        ...state
      };
    case LeadConstants.CUSTOMER_UPDATE_FAILURE:
      return {
        updateResponse: {},
        ...state
      };

    case LeadConstants.REQUIREMENT_SAVE_SUCCESS:
      return {
        requirementResponse: action.resp,
        ...state
      };
    case LeadConstants.REQUIREMENT_SAVE_FAILURE:
      return {
        requirementResponse: {},
        ...state
      };
    
    case LeadConstants.GET_AGENT_DETAILS_SUCCESS:
      return {
        agentDetails: action.resp,
        ...state
      };
    case LeadConstants.GET_AGENT_DETAILS_FAILURE:
        return {
          agentDetails: action.resp,
          ...state
        };
    case LeadConstants.DEALER_LIST_SUCCESS:
      return {
         dealerListData: action.resp , ...state
      };
    case LeadConstants.DEALER_LIST_FAILURE:
      return {
        dealerListData: {success: false, data: []} , ...state 
      };
    case LeadConstants.STATUS_LIST_SUCCESS:
      return {
         statusListData: action.resp , ...state
      };
    case LeadConstants.STATUS_LIST_FAILURE:
      return {
        statusListData: {success: false, data: []} , ...state 
      };
    case LeadConstants.TIMELINE_DETAILS_SUCCESS:
      return {
         timelineDetails: action.resp , ...state
      };
    case LeadConstants.TIMELINE_DETAILS_FAILURE:
      return {
        timelineDetails: {success: false, data: []} , ...state 
      };
    case LeadConstants.LEADS_CAR_LIST_SUCCESS:
      let oldState = state;
      delete oldState['leadsCarList']; //REMOVE OLD DATA FROM STATE
      return {
         leadsCarList: action.resp , ...oldState
      };
    case LeadConstants.LEADS_CAR_LIST_FAILURE:
      return {
        leadsCarList: {success: false, data: []} , ...state 
      };

    case LeadConstants.STOCK_CAR_DETAILS_SUCCESS:
      return {
         carDetails: action.resp , ...state
    };
    case LeadConstants.STOCK_CAR_DETAILS_FAILURE:
      return {
        carDetails: {success: false, data: []} , ...state 
      };

    case LeadConstants.SUB_STATUS_LIST_SUCCESS:
      return {
          subStatusListData: action.resp , ...state
      };
    case LeadConstants.SUB_STATUS_LIST_FAILURE:
      return {
        subStatusListData: {success: false, data: []} , ...state 
      };
    case LeadConstants.LEAD_FINDER_STATUS_SUCCESS:
      return {
        leadStatus: action.resp , ...state
    };
    case LeadConstants.LEAD_FINDER_STATUS_FAILURE:
      return {
        leadStatus: {success: false, data: []} , ...state 
    };
    case LeadConstants.CITY_STATE_LIST_SUCCESS:
      return {
        cityStateList: action.resp , ...state
    };
    case LeadConstants.CITY_STATE_LIST_FAILURE:
      return {
        cityStateList: {success: false, data: []} , ...state 
    };
    case LeadConstants.RTO_LIST_SUCCESS:
      return {
        rtoList: action.resp , ...state
    };
    case LeadConstants.RTO_LIST_FAILURE:
      return {
        rtoList: {success: false, data: []} , ...state 
    };
    case LeadConstants.CUSTOMER_LOCATITY_SUCCESS:
      return {
        customerLocalityList: action.resp , ...state
    };
    case LeadConstants.CUSTOMER_LOCATITY_FAILURE:
      return {
        customerLocalityList: {success: false, data: []} , ...state 
    };

    case LeadConstants.LEAD_REOPEN_DATA:
      return {
        leadReopenData: action.postData , ...state
    };

    case LeadConstants.CONVERSION_STATUS_SUCCESS:
      return {
        conversionStatus: action.resp , ...state
    };
    case LeadConstants.CONVERSION_STATUS_FAILURE:
      return {
        conversionStatus: {success: false, data: []} , ...state 
    };
    
    case LeadConstants.WALKIN_FEEDBACK_SUCCESS:
      return {
        walkinFeedback: action.resp , ...state
    };
    case LeadConstants.WALKIN_FEEDBACK_FAILURE:
      return {
        walkinFeedback: {success: false, data: []} , ...state 
    };

    case LeadConstants.CALL_STATUS_LIST_SUCCESS:
      return {
          callStatusListData: action.resp , ...state
      };
    case LeadConstants.CALL_STATUS_LIST_FAILURE:
      return {
        callStatusListData: {success: false, data: []} , ...state 
      };

    default:
      return state
  }
}