import React, { Component } from 'react';
import RecommendSearchcar from './RecommendSearchCar';
import RecommendcarsTable from './RecommendcarsTable';
import LeadService from './../../../service/leadService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LeadActions } from "../../../store/action";
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { toast } from 'react-toastify';
import * as helper from '../../../config/helper';
import { default as config } from '../../../config/config';


class RecommendCarTab extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            pageloading:true,
            recomendCarList: [],
            searchByList: [],
            totalRecord:0,
            page: 0,
            nextPage:false,
            searchDate: {
                'from_date':66666,
                'record_per_page':10
            },
            redirect: false,
            leadDetails:[],
            filterData:{
                priceRange:[],
                fromYearRange:[],
                toYearRange:[],
                kmRange:[],
                bodyType:[],
                fuelType:[],
                owner:[],
                transmission:[],
                makeModel:[],
                cityList:[],
                rtoList:[],
                dealerList:[],
                customerLocalityList:[],
                isRequirementFormUpdated:props.isRequirementFormUpdated
            },
            formData:{},
            updateFilterForm:false,
            activeStatus : false,
            totalCarCount:0,
            leadsCarList: [],
            refreshRecommendsCarsData:false,
            lead_id: props.lead_id,
            resetSearchForm:false
        };
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.onScroll('Mounter');
        window.addEventListener('scroll', this.onScroll);
        // await this.getRecomendCarList();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    UNSAFE_componentWillReceiveProps = async(nextProps) => {
        let {leadDetailsData,makeModelVersionList, filterList, cityList,dealerListData,activeStatus,isRequirementFormUpdated} = nextProps;
        let {leadDetails,filterData,formData, leadsCarList, refreshRecommendsCarsData} = this.state;
        if(leadDetailsData && leadDetailsData.length && (leadDetailsData !== leadDetails || isRequirementFormUpdated)){
            leadDetails = leadDetailsData;
            formData = await this.setFormData(leadDetailsData[0],makeModelVersionList)
        }
        if(filterList && filterList.length){
            filterData.priceRange = await this.getRequiredKeyValue(filterList,'max_car_price');
            filterData.fromYearRange = await this.getRequiredKeyValue(filterList,'min_make_year');
            filterData.toYearRange = await this.getRequiredKeyValue(filterList,'max_make_year');
            filterData.kmRange = await this.getRequiredKeyValue(filterList,'max_km_driven');
            filterData.bodyType = await this.getRequiredKeyValue(filterList,'body_type');
            filterData.fuelType = await this.getRequiredKeyValue(filterList,'fuel_type_id');
            let ownerType = await this.getRequiredKeyValue(filterList,'owner_type');
            filterData.transmission = await this.getRequiredKeyValue(filterList,'uc_transmission_id');
            filterData.owner = await this.getOwnerLabel(ownerType);
            
        }

        // if(nextProps.leadsCarList && nextProps.leadsCarList.length && leadsCarList.length !== nextProps.leadsCarList.length){
        //     leadsCarList = nextProps.leadsCarList; 
        //     let recommendTabPage = (nextProps.refreshRecommendsCarsData) ? {page_no:1} : {};
        //     this.getRecomendCarList(recommendTabPage);
        // }

        // if(nextProps.leadsCarList && (!nextProps.leadsCarList.length || nextProps.leadsCarList.length)){
        if(nextProps.activeStatus){
            leadsCarList = nextProps.leadsCarList; 
            this.getRecomendCarList({...formData, page_no: 1});
        }

        if(nextProps.refreshRecommendsCarsData !== this.state.refreshRecommendsCarsData){
            refreshRecommendsCarsData = nextProps.refreshRecommendsCarsData;
        }

        //filterData.rtoList = (nextProps.rtoList && nextProps.rtoList.length) ? nextProps.rtoList : [];
        filterData.customerLocalityList = (nextProps.customerLocalityList && nextProps.customerLocalityList.length) ? helper.getDataInKeyValueFormat(nextProps.customerLocalityList) : [];
        filterData.makeModel = await this.getCarMakeModelList(makeModelVersionList);
        filterData.cityList = (cityList && cityList.city && cityList.city.length) ? helper.getDataInKeyValueFormat(cityList['city'],'city') : [];
        filterData.dealerList = (dealerListData && dealerListData.length) ? helper.getDataInKeyValueFormat(dealerListData) : [];
        filterData.make = (makeModelVersionList && makeModelVersionList.make) ? makeModelVersionList.make : [] 
        filterData.model = (makeModelVersionList && makeModelVersionList.make) ? makeModelVersionList.model : [] 
        
        if(this._isMounted)
            this.setState({filterData:filterData,leadDetails:leadDetails,activeStatus:activeStatus, leadsCarList: leadsCarList, refreshRecommendsCarsData: refreshRecommendsCarsData,formData:formData,isRequirementFormUpdated:isRequirementFormUpdated});
        
    }

    onScroll(event) {
        if (this.state.activeStatus && (window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            if (this.flag) {
                let current_page = this.state.page;
                let loading=false,pageloading=false;
                if(current_page===0) {
                    pageloading = true;
                }else{
                    loading = true;
                }
                
                if(this._isMounted){
                    this.setState({ page: current_page + 1,loading:loading,pageloading:pageloading, formData: {...this.state.formData, ...{page_no: current_page + 1}}}, (state) => {
                        
                        // this.state.formData['page_no'] = this.state.page;
                        this.getRecomendCarList(this.state.formData);
                    });
                }
            }
            this.flag = false;
        }
    }

    setFormData = async(leadInfo,makeModelVersionList) => {
        // filterData
        let {formData} = this.state; 
        // let make = (makeModelVersionList && makeModelVersionList.make) ? makeModelVersionList.make : []
        // let model = (makeModelVersionList && makeModelVersionList.model) ? makeModelVersionList.mode : []
        
        formData['min_price'] = (leadInfo.min_price) ? leadInfo.min_price : '';
        formData['max_price'] = (leadInfo.budget) ? leadInfo.budget : '';
        formData['max_km_driven']        = (leadInfo.max_km) ? leadInfo.max_km : '';
        formData['min_make_year'] = (leadInfo.min_year) ? leadInfo.min_year : ''
        formData['max_make_year'] = (leadInfo.min_year) ? leadInfo.max_year : ''
        formData['fuel_type_id'] = (leadInfo.fuel && typeof leadInfo.fuel === 'string') ? leadInfo.fuel.split(",").map(e=>+e) : leadInfo.fuel
        formData['body_type_id'] = (leadInfo.req_body_type && typeof leadInfo.req_body_type === 'string') ? leadInfo.req_body_type.split(",").map(e=>+e) : leadInfo.req_body_type
        formData['car_make'] = (leadInfo.car_make) ? leadInfo.car_make : []
        formData['car_model'] = (leadInfo.car_model) ? leadInfo.car_model : []
        formData['uc_transmission_id'] = (leadInfo.transmission) ? leadInfo.transmission.split(",").map(e=>+e) : [];
        await this.setState({formData:formData})
        return formData;
    }

    getRequiredKeyValue = async (filterData,key) => {
        let retData=[];
        if (filterData && filterData.length) {
            const requiredData = helper.filterDataByKey(filterData, key);
            if(requiredData && requiredData.list && requiredData.list.length)
            retData = helper.getDataInKeyValueFormat(requiredData.list,key);
        }
        return retData;
    }

    getOwnerLabel = async (ownerArr) => {
        const OWNER = config.constants.OWNER;
        let retArr=[];
        if (ownerArr && ownerArr.length) {
            _.forEach((ownerArr),(ownObj) =>{
                let tempObj={};
                const result = _.find(OWNER, { 'value': ownObj.value });
                if(result){
                    tempObj.value = ownObj.value;
                    tempObj.label = result.label;
                    retArr.push(tempObj);
                }
            })
        }
        return retArr;
    }

    getCarMakeModelList = async (params) => {
        let retData=[];
        if (params && params.make && params.make.length && params.model && params.model.length) {
            retData = helper.getCarMakeModelList(params);
        }
        return retData;
    }    

    getRecomendCarList = async(filterData = {}) =>{
        var thisObj = this;
        // var filterData = thisObj.state.searchDate;
        filterData.lead_id = (this.state.leadDetails && this.state.leadDetails[0] && this.state.leadDetails[0]['id']) || '';
        // filterData.city = filterData.city && filterData.city.length? filterData.city : (this.state.leadDetails && this.state.leadDetails[0] && this.state.leadDetails[0]['customer_city_id']?[this.state.leadDetails[0]['customer_city_id']]:[]);
        filterData.page_no = (filterData.page_no) ? filterData.page_no : ((thisObj.state.page === 0) ? 1 : thisObj.state.page);
        if(filterData.city && filterData.city.length){
            filterData.city_ids = filterData.city;
        }
         
        await thisObj.setState({recomendCarList: (filterData.page_no<=1 ? [] : [...this.state.recomendCarList]), page:filterData.page_no,loading:true,formData:filterData});
        //GET ASSIGNED CARS ID 
        if(this.props.leadsCarList && this.props.leadsCarList.length){
            let leadsCarsIds = this.props.leadsCarList.map(cars=> cars.car_id);
            filterData['not_included_car_id'] = leadsCarsIds;
        }
        if(filterData['dealer_ids'] && !filterData['dealer_ids'].length){
            delete filterData['dealer_ids'];
        }

        let postData = {}
        Object.keys(filterData).forEach(el =>{
            if(el === 'city_ids' || el === 'city' || el === 'uc_transmission_id' || el === 'car_make' || el === 'car_model'){
                filterData[el] = filterData[el].filter(el => { return (el && el !=='')})
                if(filterData[el].length){
                    postData[el] = (filterData[el]);
                }
            }else if(filterData[el]){ 
                postData[el] = (filterData[el]);
            }
        })
        
        LeadService.getStockList(postData)
            .then(async(response) =>{ 
                //thisObj.setState({loading:false,pageloading:false});
                if (response.data.status === 200) {
                    let recomendList = [];
                    if (response.data.data.length) {
                        recomendList = response.data.data;
                        thisObj.updateTitleCount(recomendList.length)
                        for(let recomendObj of recomendList){
                            //recomendObj['newCarPriceInfo']={};
                            recomendObj['dealerDetails']={};
                            if(recomendObj.car_image.length){
                                recomendObj.car_image = (recomendObj.car_image).map(image_url => {
                                    return {'src' : image_url}
                                })
                            }
                            // if(recomendObj && recomendObj.city && recomendObj.carversion){
                            //     let newCarPrice = await thisObj.getNewCarPriceInfo({'city':recomendObj.city,'version_id':recomendObj.carversion});
                            //     if(newCarPrice && newCarPrice.data)
                            //         recomendObj.newCarPriceInfo = newCarPrice.data;
                            // }
                            // if(recomendObj && recomendObj.dealer_id && recomendObj.dealer_id){
                            //     let dealerInfo = await thisObj.getDealerDetailsById({'dealer_id':recomendObj.dealer_id});
                            //     if(dealerInfo && dealerInfo.data)
                            //         recomendObj.dealerDetails = dealerInfo.data;
                            // }
                        }
                        
                        if (postData.page_no <= 1) {
                            thisObj.setState({ recomendCarList: recomendList ,loading:false,pageloading:false,refreshRecommendsCarsData:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                        else { 
                            thisObj.setState({
                                recomendCarList: thisObj.state.recomendCarList.concat(recomendList)
                            ,loading:false,pageloading:false,refreshRecommendsCarsData:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                    } else {  
                        if (postData.page_no <= 1) {
                            thisObj.setState({loading:false,pageloading:false, recomendCarList: recomendList,refreshRecommendsCarsData:false});
                        }else{
                            thisObj.setState({loading:false,pageloading:false,refreshRecommendsCarsData:false});
                        }
                        thisObj.updateTitleCount(0);
                    }
                }
            })
            .catch(function (response) {
                thisObj.setState({loading:false,pageloading:false})
            });
    }

    // getNewCarPriceInfo = async(params) => {
    //     let response = await LeadService.getNewCarPrice(params);
    //     if (response.data.status === 200) {
    //         return response.data
    //     }else{
    //         return {}
    //     }
    // }
    getDealerDetailsById = async(params) => {
        let response = await LeadService.getDealerDetailsById(params);
        if (response.data.status === 200) {
            return response.data
        }else{
            return {}
        }
    }

    copyCarDetails = (postData) => {
        this.props.actions.copyCarDetails(postData)
        .then((result) =>{
            
            if (result.status === 200) {
                toast.success('Car details copied.');
                

            } else if (result.status === 401 || result.data.status === 401) {
                if(this._isMounted) this.setState({ redirect: true })
            }else{
               // toast.error('Error in save');
            }
        })
        .catch((error) => {
        });
        
    }

    saveRecomendCar = (postData) => {
        this.props.actions.saveCarRecomend(postData)
        .then((result) =>{
            
            if (result.status === 200) {
                toast.success('Recomended car saved.');   
                this.props.onSaveRecommendation(); 

            } else if (result.status === 401 || result.data.status === 401) {
                if(this._isMounted) this.setState({ redirect: true })
            }else{
               // toast.error('Error in save');
            }
        })
        .catch((error) => {
        });
    }

    updateTitleCount = async(count) =>{
        let totalCarCount = this.state.totalCarCount;
        if(this.state.page > 1){
            totalCarCount = count+totalCarCount;
        }else{
            totalCarCount = count;
        }
        if(this._isMounted) this.setState({totalCarCount});
        if (typeof this.props.updateTitleCount === 'function') {            
            this.props.updateTitleCount(totalCarCount);
            
        }
    }

    clearFilterAndGetUpdatedList = (formData, type='') => {
        formData = Object.assign({},formData)
        formData['page_no'] = 1;
        if(this._isMounted) this.setState({formData:formData});

        if(type==='clearAll' && this._isMounted) this.setState({resetSearchForm:true});
        else if(type==='' && this._isMounted) this.setState({resetSearchForm:false});

        this.getRecomendCarList(formData);
    }

    getSearchList = (params) => { 

        this.setState({'isRequirementFormUpdated':false})
        if(!(params['uc_transmission_id'] instanceof Array)){
            params['uc_transmission_id'] = [params['uc_transmission_id']]
        }
        params['min_km_driven'] = 1

        if(!params['city'] || (params['city'] && !params['city'].length)){
            if(params['dealer_ids']){
                delete params['dealer_ids'];
            }

            if(params['customer_locality']){
                delete params['customer_locality']
            }
        }

        let postData = {}
        Object.keys(params).forEach(el =>{
            if(el === 'city_ids' || el === 'city' || el === 'uc_transmission_id' || el === 'car_make' || el === 'car_model'){
                params[el] = params[el].filter(el => { return (el && el !=='')})
            }
            if(params[el] && params[el].length ){
                postData[el] = (params[el]);
            }else if(params[el] && typeof params[el] === 'number') {
                postData[el] = (params[el]);
            }
        });

        postData['page_no'] = params['page_no']

        this.getRecomendCarList(postData);
    }

    saveDealerFlag = (postData) => {
        
        this.props.actions.saveDealerFlag(postData)
        .then((result) =>{
            if (result.status === 200) {
                toast.success(result.message);
                

            } else if (result.status === 401 || result.data.status === 401) {
                if(this._isMounted) this.setState({ redirect: true })
            }else{
               // toast.error('Error in save');
            }
        })
        .catch((error) => {
        });
    }

    updateRefreshRecommendsCarsDataFlag = () => { 
        if(this._isMounted){
            this.setState({refreshRecommendsCarsData: false});
        }

    }


    render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    {/* {console.log("new form data ",this.state.formData)} */}
                    <RecommendSearchcar leadDetailsData={this.state.leadDetails} filterData={this.state.filterData} 
                    formData={this.state.formData} getSearchList={this.getSearchList} isRequirementFormUpdated={this.state.isRequirementFormUpdated || false} resetSearchForm={this.state.resetSearchForm}  loading={this.state.loading} />
                </div>
                <div className="col-sm-8">
                    <RecommendcarsTable  recomendCarList={this.state.recomendCarList} leadDetailsData={this.state.leadDetails} loading={this.state.loading} 
                    saveRecomendCar={this.saveRecomendCar} copyCarDetails={this.copyCarDetails} filterData={this.state.filterData}
                    clearFilterAndGetUpdatedList={this.clearFilterAndGetUpdatedList} formData={this.state.formData} saveDealerFlag={this.saveDealerFlag} mmvList={this.props.makeModelVersionList} leadsCarList={this.state.leadsCarList} refreshRecommendsCarsData={this.state.refreshRecommendsCarsData} updateRefreshRecommendsCarsDataFlag={this.updateRefreshRecommendsCarsDataFlag}/>
                </div>
            </div>             
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    leadDetailsData:state.lead.listLead,
    filterList: state.lead.filterList,
    makeModelVersionList: state.lead.makeModelVersionList,
    cityList: state.lead.cityStateList,
    //rtoList:state.lead.rtoList,
    dealerListData:state.lead.dealerListData,
    customerLocalityList:state.lead.customerLocalityList,
    leadsCarList:state.lead.leadsCarList,
    
});
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            saveCarRecomend:bindActionCreators(
                LeadActions.saveCarRecomend,
                dispatch
            ),
            copyCarDetails:bindActionCreators(
                LeadActions.copyCarDetails,
                dispatch
            ),
            saveDealerFlag:bindActionCreators(
                LeadActions.saveDealerFlag,
                dispatch
            )
    }   }
}
export default withTranslation('lead')(connect(mapStateToProps,mapDispatchToProps) (RecommendCarTab));