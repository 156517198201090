import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import DateFormat from 'dateformat';
import { default as config } from './../../../../config/config';
import _ from 'lodash';
import SourceService from '../../../../service/sourceService';

class SearchResult extends Component {
    sourceData=[]
    constructor(props) {
        super(props);
        this.state = {
            sourceData: props.sourceData,
            loading: false,
            pageloading: false,
            statusClassname: {
                'active': 'nav-item active',
                'inactive': 'nav-item',
                'incomplete': 'nav-item',
                'blacklist': 'nav-item',
                'all': 'nav-item'
            },
            filterData: {
            },
            usersRecord:[],
            currentIndex: null,
            current_org_name: null
        };
        this.updateStatus = this.updateStatus.bind(this)
    }
    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         sourceData: nextProps.sourceData,
    //         loading: nextProps.loading,
    //         pageloading: nextProps.pageloading
    //     });
    // }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};

        if(nextProps.sourceData){
            updatedStateData['sourceData'] = nextProps.sourceData;
        }

        if(nextProps.loading !== prevState.loading){
            updatedStateData['loading'] = nextProps.loading;
        }

        if(nextProps.pageloading !== prevState.pageloading){
            updatedStateData['pageloading'] = nextProps.pageloading;
        }

        return updatedStateData;

    }

    getPaidDetails(key){
        let res = '';
        res = _.find(config.constants.paidDetails,{'value':key})
        if(res)
            return res.label;
    }

    updateStatus(userData){
        if (typeof this.props.onUpdateStatus === 'function') {
            this.props.onUpdateStatus(userData);
        }
    }

    getUserName = (data,keyToMatch,keyToReturn) => {
        const user = _.find(this.state.usersRecord,{'id':data[keyToMatch]});
        if(user)
            return user[keyToReturn]
        else
            return null;
    }

    componentDidMount = async () => {
        await this.getUserList();
    }

    getUserList() {
        var thisObj = this;
        SourceService.getAllUserList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                        thisObj.setState({ usersRecord: response.data.data });
                    
                } else {
                        thisObj.setState({ usersRecord: []});
                    
                }
            }
        })
        .catch(function (response) {
            
        });
    }
    
    render() {
        return (
            <div className="table-responsive">
                    <table className="table table-bordered table-hover table-striped table-category">
                        <thead>
                            <tr>
                                <th>{this.props.t('subSource.details.id') }</th>
                                <th>{this.props.t('subSource.details.sourceName') }</th>
                                <th>{this.props.t('subSource.details.friendlyName') }</th>
                                <th>{this.props.t('subSource.details.paidDetails') }</th>
                                <th>{this.props.t('subSource.details.createdDate') }</th>
                                <th>{this.props.t('subSource.details.createdBy') }</th>
                                <th>{this.props.t('subSource.details.updatedDate') }</th>
                                <th>{this.props.t('subSource.details.updatedBy') }</th>
                                <th>{this.props.t('subSource.details.status') }</th>
                                <th>{this.props.t('subSource.details.action') }</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(this.state.sourceData.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="10"><h6 className="text-center text-danger text-bold">{this.props.t('sourceType.search.No_Record_Found')}</h6></td></tr> : null}
                            {(this.state.sourceData && this.state.sourceData.length !== 0) && this.state.sourceData.map((sdata, k) =>
                            <tr key={k}>
                                <td>{sdata.id}</td>
                                <td>{sdata.name}</td>
                                <td>{sdata.friendly_name}</td>
                                <td>{this.getPaidDetails(sdata.paid_details)}</td>
                                <td>{DateFormat(sdata.created_at,"dd/mm/yyyy")}</td>
                                <td>{this.getUserName(sdata,'added_by','username')}</td>
                                <td>{(sdata.updated_at)? (DateFormat(sdata.updated_at,"dd/mm/yyyy")): ''}</td>
                                <td>{this.getUserName(sdata,'updated_by','username')}</td>
                                <td>
                                    <label className="switch-btn btn btn-link ml-5" htmlFor={"active"+sdata.id} >
                                        <input checked={(sdata.status === "1") ? true : false} className="switch-btn" id={"active"+sdata.id} value="open" name={"active"+sdata.id} type="checkbox"  onChange={this.updateStatus.bind(this,sdata)}  />
                                        <div className="slider round"></div>
                                        <span className="switch-label"></span>
                                        {(sdata.status === "1") ? this.props.t('source.formDetails.active'):this.props.t('source.formDetails.inactive')}
                                    </label>
                                </td>
                                <td>
                                    <div className="btn-group">
                                    <Link to={`/edit-sub-source/${sdata.sub_source_id_hash}`} className="btn btn-default"><i className="ic-createmode_editedit mrg-r5"></i></Link>
                                    </div>
                                </td>
                            </tr>
                            )}
                            {
                                (this.state.loading) ? <tr><td className="loading" colSpan="10"></td></tr> : null
                            }
                            
                        </tbody>
                    </table>
                </div>
                
            
        )
    }
}

export default withTranslation('source') (SearchResult);
