import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AuthActions } from "../../store/action";
import _  from 'lodash';
import { withTranslation } from 'react-i18next';
import secureStorage from './../../config/encrypt';
import { default as config } from './../../config/config';
// import ModalPopup from '../elements/Popup';
 import * as HELPER from './../../config/helper';
class Navbar extends Component {
    constructor(props) {
        // if(!secureStorage.getItem('authUserInfo'))
        //     window.location = '/login';
        super(props)
        this.state = {
            langOption : []
        }
    }

    componentDidMount = ()=>{
        let langData = config.constants.language;
        secureStorage.setItem('langOption', langData);
        this.setState({langOption: langData});
        
    }

    /**
     * Logout
     */
    authLogout = (e) => {
        e.preventDefault();
        this.props.actions.authLogout();
        // window.location = '/login';
    }
    
    /**
     * Active Nav on route change
     */
    checkActiveBtn = (routeArray) => {
        let curPath = window.location.pathname.replace(/\//g, '');
        return routeArray.includes(curPath);
    }

    getCurrentClass = (menuData) => {
        let currentPath = window.location.pathname;
        let activeClass = '';
        Object.keys(menuData).forEach(el=> {
            if(typeof menuData[el] === 'object'){
                Object.keys(menuData[el]).forEach(elm=> {
                    if(elm === currentPath){
                    activeClass = 'active';
                    return;
                }
                });
            }else{
                if(el === currentPath){
                    activeClass = 'active';
                    return;
                }
            }
        });

        return activeClass;
    }

    
    
    render() {
        const { loggedIn, authUserInfo, loggingIn, i18n } = this.props;
        const setLang = secureStorage.getItem('lang');
        const langOption = this.state.langOption;

        return (
            <nav className={this.props.navClass}>
                <ul className="navbar-nav">
                {
                                    this.props.headerMenu && Object.keys(this.props.headerMenu).length
                                    ?
                                    Object.keys(this.props.headerMenu).map((el, i) => {
                                        if(typeof this.props.headerMenu[el] !== 'string'){
                                            let topLevelMenuArr = el.split('.');
                                            let isUserHasAccessToTopMenu = HELPER.isUserHasAccessPage(topLevelMenuArr[0],topLevelMenuArr[1],false,true) ;

                                            if(isUserHasAccessToTopMenu) {
                                                return (<li className="dropdown" key={el}>
                                                    <a className={this.getCurrentClass(this.props.headerMenu[el][el])}>{this.props.t('menu.'+el)}<span className="caret"></span></a>
                                                    <ul className="dropdown-menu" key={el}>
                                                    {
                                                        Object.keys(this.props.headerMenu[el][el]).map((elm)=>{
                                                            if(typeof this.props.headerMenu[el][el][elm] === 'object')
                                                            {   
                                                                let menuarr = elm.split('.');
                                                                if(HELPER.isUserHasAccessPage(menuarr[0],menuarr[1],false,false)){
                                                                    return ( <li className="level2" key={elm}><a href="#">{this.props.t('menu.'+elm)}</a>
                                                                        <ul className="dropdown-menu" key={elm}>
                                                                            
                                                                            {
                                                                                Object.keys(this.props.headerMenu[el][el][elm]).map((elmn)=>{
                                                                                    if(HELPER.isUserHasAccessPage(menuarr[0],menuarr[1],elmn,false))
                                                                                        return (<li key={elmn}><NavLink to={elmn}>{this.props.t('menu.'+this.props.headerMenu[el][el][elm][elmn])}</NavLink></li>)
                                                                                    else
                                                                                        return ''
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </li>)
                                                                }else{
                                                                    return ''
                                                                }
                                                            }else{
                                                                if(HELPER.isUserHasAccessPage(topLevelMenuArr[0],topLevelMenuArr[1],elm,false)){
                                                                    
                                                                    return (<li key={elm}><NavLink to={elm}  >{this.props.t('menu.'+this.props.headerMenu[el][el][elm])}</NavLink></li>)
                                                                }else
                                                                    return ''
                                                        }
                                                        })
                                                    }
                                                    </ul>
                                                </li>)
                                            }else{
                                                return ''
                                            }
                                        }else{
                                            if(HELPER.isUserHasAccessPage(el,el,false,true))
                                                return (<li key={el}><NavLink to={'/leads-list'}>{this.props.t('menu.'+el)}</NavLink></li>)
                                            else
                                                return ''
                                        }
                                    })
                                    :
                                    (
                                        <li></li>
                                    )
                                }

                
                    {
                        (loggedIn || loggingIn)
                        ?
                        <li className="dropdown myac" title={(authUserInfo.authUser ? authUserInfo.authUser.name : '') + ' (GCD69) | Co-Owner'}>
                            <a href="#!"><span className="userac"><i className="ic-person"></i></span>{this.props.t('menu.hi')}, {(authUserInfo.authUser ? authUserInfo.authUser.name : '') }<span className="caret"></span></a>
                            <ul className="dropdown-menu">
                                <li><NavLink to="/my-account/basic-details">{this.props.t('menu.myAccount')}</NavLink></li>
                                <li><NavLink aria-current="page"  to="/logout" >{this.props.t('menu.logout')}</NavLink></li>
                            </ul>
                        </li>
                        :
                        ""
                    }
                    
                    {
                        (langOption.length>1)
                        ?
                        <li>
                            <div className="language-change">
                                {langOption.map((lang, index) =>
                                    <button key={index} type='button' className={setLang===lang.iso_code?'btn btn-primary':'btn btn-default'} onClick={() => {
                                        secureStorage.setItem('lang', lang.iso_code);
                                        i18n.changeLanguage(lang.iso_code)
                                    }}>{lang.iso_code}</button>
                                )}
                            </div>
                        </li>
                        :
                        ""
                    }
                    
                </ul>
            </nav>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    authUserInfo: state.auth.authUser,
    loggedIn: state.auth.loggedIn,
    loggingIn: state.auth.loggingIn,
    redirectTo: state.auth.redirectTo,
    location: ownProps.location
});

const mapDispatchToProps = dispatch => {
    return {
               actions: {
                  authLogout: bindActionCreators(
                    AuthActions.logout,
                    dispatch
                  )
               }
            }
}
export default  withTranslation('common') (connect(mapStateToProps, mapDispatchToProps)(Navbar));
