import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as helper from '../../../../config/helper';
import { default as config } from './../../../../config/config';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import CheckBox from '../../../elements/CheckBox';

class SearchLeadSnapshot extends Component {
    constructor(props) {
        super(props);
        this.multiselectRef={
            'cluster':React.createRef()
        }
        this.state = {
            selectedOption: null,
            date: new Date(),
            advanceSearchEnable: false,
            formData: {
            },
            filterData: props.prefilledData,
            clusterlist:[]
        };
    }
    
    handleDateChange = (selectedKey,date) =>{
        let formData = {...this.state.formData};
        formData[selectedKey] = date;
        this.setState({ formData:formData });
    }
    handleChange = (key ,event) => {
        let formData = {...this.state.formData};
        
        formData[key] = ('is_premium' in formData) ? !formData[key] : true;
        this.setState({ formData: formData });      
        
      };
      searchFilter = async() => {
        //let formValid = await this.validateSearchInput();
        if(typeof this.props.getFilterList === 'function') {
            this.props.getFilterList(this.state.formData)
        }        
    }
    static getDerivedStateFromProps(nextprops, prevState) {
        var updatedObj = {}
        if (prevState.clusterlist !== nextprops.prefilledData.cluster) {
            updatedObj['clusterlist'] = helper.getDataInKeyValueFormat(nextprops.prefilledData.cluster, 'cluster');
        }
        return updatedObj;
    }

    onSelect(key,selectedList, selectedItem) {
        let formData = this.state.formData;
        let existingVal = (formData[key]) ? ((typeof formData[key] === 'string') ? formData[key].split(',') : formData[key]) : []
        existingVal.push(selectedItem.value);
        formData[key] = existingVal;
        this.setState({ formData: formData });
        
        
    }
     
    onRemove(key,selectedList, removedItem) {
        let formData = this.state.formData;
        let existingVal = (formData[key]) ? formData[key] : [];
        existingVal = _.filter(existingVal, function(currentVal) {
            return currentVal !== removedItem.value;
        });
        formData[key] = existingVal;
        this.setState({ formData: formData });
        
    }

    searchReport = ()=>{
        let formData = this.state.formData;
        if(typeof this.props.getReportData === 'function'){
            this.props.getReportData(formData)
        }
        
    }

    reset = async() => {
        let formData = {
            
        }
        this.multiselectRef.cluster.current.resetSelectedValues();
        await this.setState({formData:formData})
        this.searchReport();
    }

    render() {
        const {formData,clusterlist}  = this.state;
        return (
            
            <div className="search-wrap">
            <ul className="search-flex">
            <li className="searchitems doublew form-group " >
                <label>{this.props.t('leadSnapshot.cluster')}</label>
                <Multiselect
                    id='cluster'
                    options={clusterlist}
                    onSelect={this.onSelect.bind(this,'cluster')} 
                    onRemove={this.onRemove.bind(this,'cluster')} 
                    displayValue="label" 
                    showCheckbox={false}
                    closeOnSelect={false}
                    //selectedValues={(formData && formData.selected_fuel && formData.selected_fuel.length) ? formData.selected_fuel : ''}
                    ref={this.multiselectRef.cluster}
                />
            </li>
            <li className="searchitems doublew form-group">
                    <label>{this.props.t('leadSnapshot.addedDate')}</label>
                    <DatePicker 
                        className="form-control"
                        onChange={this.handleDateChange.bind(this,'added_date_from')}
                        selected={(formData && formData.added_date_from) ? formData.added_date_from : this.state.date}
                        dateFormat={config.constants.dateFormatDMY}                                    
                        onKeyDown={e => e.preventDefault()}
                        placeholderText={this.props.t('leadSnapshot.from')}
                    />  
                </li>
                <li className="searchitems doublew form-group">
                    <label>{this.props.t('leadSnapshot.clear')}</label>
                    <DatePicker 
                        className="form-control"
                        onChange={this.handleDateChange.bind(this,'added_date_to')}
                        selected={(formData && formData.added_date_to) ? formData.added_date_to : this.state.date}
                        dateFormat={config.constants.dateFormatDMY}                                    
                        onKeyDown={e => e.preventDefault()}
                        placeholderText={this.props.t('leadSnapshot.to')}
                
                    />
                </li>
                <li className="searchitems doublew form-group">
                    <label>{this.props.t('leadSnapshot.dueDate')}</label>
                    <DatePicker 
                        className="form-control"
                        onChange={this.handleDateChange.bind(this,'due_date_from')}
                        selected={(formData && formData.due_date_from) ? formData.due_date_from : ''}
                        dateFormat={config.constants.dateFormatDMY}                                    
                        onKeyDown={e => e.preventDefault()}
                        placeholderText={this.props.t('leadSnapshot.from')}
                    />  
                </li>
                <li className="searchitems doublew form-group">
                    <label>{this.props.t('leadSnapshot.clear')}</label>
                    <DatePicker 
                        className="form-control"
                        onChange={this.handleDateChange.bind(this,'due_date_to')}
                        selected={(formData && formData.due_date_to) ? formData.due_date_to :''}
                        dateFormat={config.constants.dateFormatDMY}                                    
                        onKeyDown={e => e.preventDefault()}
                        placeholderText={this.props.t('leadSnapshot.to')}
                
                    />
                </li>
                <li className="searchitems doublew form-group">
                    <CheckBox type="checkbox" label="Premium" onChange={this.handleChange.bind(this,'is_premium')}/>
                </li>
                <li className="searchitems form-group">
                    <label> &nbsp;</label>
                    <div>
                        <button type="submit" className="btn btn-primary mrg-r15 undefined" onClick={this.searchReport.bind(this)}>{this.props.t('leadSnapshot.getDetails')}</button>
                        
                    </div>
                </li>
            </ul>
            
        </div>
        );
    }
}

export default withTranslation('report')(SearchLeadSnapshot);
