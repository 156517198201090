import React, { Component } from "react";

import CheckBox from "../../elements/Radiobox";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../../common/LanguageSelector';
import queryString from 'query-string';
import { default as config } from '../../../config/config';

class PurchaseFeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackForm: {
        satisfiedBuyingExperience: '',
        testDriveBeforeBuy: '',
        freelyInspectVehicleAllowed: '',
        serviceImprovement:''
      },
      errors:{},
      loading: false,
      queryParams:{},
      siteSettings:null,
      notificationData: {}
    };
  }


  componentDidMount = async () => {
    this._isMounted = true;
    let queryParams = queryString.parse(this.props.location.search);
    await this.setState({queryParams});

    //GET NOTIFICATION DETAILS
    this.getDetails();
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if(nextProps.siteSettings && !prevState.siteSettings ){
        return ({ siteSettings: nextProps.siteSettings })
    }
    return null;
  }


  getDetails = async () => {
      
    let details = await this.props.actions.getDetails(this.state.queryParams);

    if(details.status === 200 && details.data && +details.data.status === 200){
      this.props.history.push('/thankyou-page?msg=feedback_already_submitted');
    }else if(details.status === 200 && details.data && +details.data.status === 300){
      this.props.history.push('/error-page?msg=link_expired');
    }else if(details.status === 200 && details.data){
      this.setState({notificationData: details['data']['notificationData']})
    }
  }

  handleStarClick(event) {
    this.setState({ value: undefined });
  }

  handleChange = (name, value) => async (event) => {
    let updatedValue = {...this.state.feedbackForm};
    if(name === 'serviceImprovement'){
      updatedValue[name]         = event.target.value
    }else{
      updatedValue[name]         = value.value 
    }
    // updatedValue[name] = (updatedValue[name]).toString();
    await this.setState({feedbackForm: updatedValue});
  };


  submitForm = async () => {
    let { feedbackForm, errors, queryParams } = this.state;
    errors = {};
    Object.keys(feedbackForm).forEach((el,key)=>{
      if(!['serviceImprovement'].includes(el) && !feedbackForm[el]){
          errors[el] = true
      }
    });
    this.setState({errors});

    if(Object.keys(errors).length){
      toast.error('Please answer all the mandatory questions')
    }else{
      this.setState({loading:true});
      feedbackForm = {...feedbackForm, ...queryParams}
      this.props.actions.purchaseFeedbackSubmit(feedbackForm).then(udpateStatus=>{

        if(udpateStatus && udpateStatus.data && +udpateStatus.data.status === 100){
            this.setState({loading:false});
            this.props.history.push('/thankyou-page?msg=feedback_submitted');
        }else if(udpateStatus && udpateStatus.data && +udpateStatus.data.status === 200){
            this.setState({loading:false});
            this.props.history.push('/thankyou-page?msg=feedback_already_submitted');
        }else if(udpateStatus && udpateStatus.data && +udpateStatus.data.status === 300){
          this.setState({loading:false});
          this.props.history.push('/error-page?msg=link_expired');
        }else{
            this.setState({loading:false});
        }
      }).catch(err=>{
        this.setState({loading:false});
      });
      
    }
  }

  render() {
    const { feedbackForm, loading, siteSettings, notificationData } = this.state;
    const { YES_NO } = config.constants;

    return (
      <div className="container">
        <div className="card feedbackForm">
          <div className="card-heading">
            <h2 className="card-titile">Car Purchase Satisfactory Survey</h2>
          </div>
          <div className="card-body">
            <h3>{`Please answer the following questions so we at ${siteSettings && siteSettings['language'] === 'id' ? 'OTO.com' : 'Carmudi.com'} can serve you better:`} </h3>
            <br></br>
            <form className="feedback-form">
              <div className="form-group">
                <label className="headLabel">
                Are you satisfied with the overall buying experience at <strong>{notificationData['dealer_name'] || ''}</strong>?
                <span>*</span>
                </label>
                <ul>
                  {YES_NO.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="satisfiedBuyingExperience"
                        id={'satisfiedBuyingExperience'+item.id}
                        checked={item.value === feedbackForm['satisfiedBuyingExperience'] ? true : false}
                        value={feedbackForm['satisfiedBuyingExperience']}
                        onChange={this.handleChange('satisfiedBuyingExperience', item)}
                      />
                    </li>
                  ))}
                </ul>
                {!loading && this.state.errors.satisfiedBuyingExperience && <div className="error-block">This field is required</div> }

              </div>
              <div className="form-group">
                <label className="headLabel">
                Did you test drive this car before buying it?
                <span>*</span>
                </label>
                <ul>
                  {YES_NO.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="testDriveBeforeBuy"
                        id={'testDriveBeforeBuy'+item.id}
                        checked={item.value === feedbackForm['testDriveBeforeBuy'] ? true : false}
                        value={feedbackForm['testDriveBeforeBuy']}
                        onChange={this.handleChange('testDriveBeforeBuy', item)}
                      />
                    </li>
                  ))}
                </ul>
                {!loading && this.state.errors.testDriveBeforeBuy && <div className="error-block">This field is required</div> }

              </div>
              <div className="form-group">
                <label className="headLabel">
                Were you allowed to freely inspect the vehicle at the time of delivery?
                <span>*</span>
                </label>
                <ul>
                  {YES_NO.map((item, index) => (
                    <li key={index}>
                      <CheckBox
                        type="radio"
                        label={item.label}
                        name="freelyInspectVehicleAllowed"
                        id={'freelyInspectVehicleAllowed'+item.id}
                        checked={item.value === feedbackForm['freelyInspectVehicleAllowed'] ? true : false}
                        value={feedbackForm['freelyInspectVehicleAllowed']}
                        onChange={this.handleChange('freelyInspectVehicleAllowed', item)}
                      />
                    </li>
                  ))}
                </ul>
                {!loading && this.state.errors.freelyInspectVehicleAllowed && <div className="error-block">This field is required</div> }

              </div>
              <div className="form-group">
                <label className="headLabel"> 
                  Is there any way we could improve our service? Please specify below.
                </label>
                <br></br>
                    <textarea
                        id="serviceImprovement"
                        name="serviceImprovement"
                        type="text"
                        value={feedbackForm['serviceImprovement']}
                        onChange={this.handleChange('serviceImprovement')}
                        className="form-control"
                    />
              </div>
              
              <div className="form-group">
                {
                  loading
                  ?
                    <button type="button" name="submit" className="btn btn-primary btn-lg" disabled>
                      {this.props.t('walkin_reschedule.please_wait')}
                    </button>
                  :
                    <button type="button" name="submit" className="btn btn-primary btn-lg" onClick={this.submitForm}>
                      {this.props.t('walkin_reschedule.submit')}
                    </button>
                }
              </div>
            </form>
          </div>
        </div>

        <footer>
            <LanguageSelector />
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tokenExpired: state.auth.tokenExpired,
    siteSettings: state.auth.siteSettings
  }
}

const mapDispatchToProps = dispatch => {
  return {
            actions: {
              getDetails: bindActionCreators(
                LeadActions.feedbackFormValidity,
                dispatch
              ),
              purchaseFeedbackSubmit: bindActionCreators(
                LeadActions.purchaseFeedbackSubmit,
                dispatch
              )
            }
          }
}

export default withTranslation('common') (connect(mapStateToProps, mapDispatchToProps)(PurchaseFeedbackForm));
