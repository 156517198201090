import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class L1CallingResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
        searchResult:props.searchResult,
        totalRecord:props.totalRecord,
        grandTotal: props.grandTotal,
        loading: false,
        pageloading: false,
        totalCalculationObject:props.totalCalculationObject,
        isCsvDownload: false
    }
  }
  static getDerivedStateFromProps(nextprops, prevState) {
    var updatedObj = {}
    if (prevState.searchResult !== nextprops.searchResult) {
      updatedObj['searchResult'] = nextprops.searchResult;
    }
    if (prevState.totalRecord !== nextprops.totalRecord) {
        updatedObj['totalRecord'] = nextprops.totalRecord;
    }
    if (prevState.grandTotal !== nextprops.grandTotal) {
        updatedObj['grandTotal'] = nextprops.grandTotal;
    }
    if(nextprops.loading !== prevState.loading){
        updatedObj['loading'] = nextprops.loading;
    }

    if(nextprops.pageloading !== prevState.pageloading){
        updatedObj['pageloading'] = nextprops.pageloading;
    }

    if(nextprops.totalCalculationObject !== prevState.totalCalculationObject){
        updatedObj['totalCalculationObject'] = nextprops.totalCalculationObject;
    }

    if(prevState.isCsvDownload && !nextprops.importingData){
        updatedObj['isCsvDownload'] = nextprops.importingData;
    }

    return updatedObj;
}
reportDetail = async (argument) => {  

    if(!this.state.isCsvDownload){
        this.props.exportCsv(argument); 
        this.setState({isCsvDownload: true})
    }

}

  render() { 
    const {totalCalculationObject, isCsvDownload } = this.state;
    let spanLinkCls = 'span-link';
    //Remove link class when downloading is in process
    if(isCsvDownload){
        spanLinkCls = ''
    }

    return (
      <div className="result-wrap">
                    <div className="card">
                        <div className="card-heading">
                            <div className="row">
                                <div className="col-sm-4">
                                    <h2 className="card-title pad-t7"> {this.state.totalRecord+' '+this.props.t('salesFunnel.resultsFound')}</h2>
                                </div>
                                <div className="col-sm-6">
                                    <h4 className="card-title downloader-loading pad-t7 text-right"> 
                                        {
                                            isCsvDownload
                                            ?
                                                <React.Fragment>
                                                    <span className="l1calling-results loading" ></span> 
                                                    <span>{this.props.t('l1Calling.processing')}</span>
                                                </React.Fragment>
                                            :
                                                ''
                                        }
                                    </h4>
                                </div>
                                <div className="col-sm-2 text-right" >
                                {(this.state.isCsvDownload)? 
                                <button  className="btn btn-success mrgin-r-10"  disabled >{this.props.t('please_wait')}</button>
                                :
                                <button  onClick={() => this.reportDetail({export_all:1})} className="btn btn-success mrgin-r-10"  >{this.props.t('l1Calling.export_csv')}</button>
                                }
                            </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover table-striped deliveryTable">
                                <thead>
                                    <tr>
                                        <th>{this.props.t('l1Calling.agentName')}</th>
                                        <th>{this.props.t('l1Calling.totalLead')}</th>
                                        <th>{this.props.t('l1Calling.contactedLeads')}</th>
                                        <th>{this.props.t('l1Calling.verified')}</th>
                                        <th>{this.props.t('l1Calling.XsellLeads')}</th>
                                        <th>{this.props.t('l1Calling.walkinScheduled')}</th>
                                        <th>{this.props.t('l1Calling.walkinDone')}</th> 
                                        <th>{this.props.t('l1Calling.closed')}</th>
                                        <th>{this.props.t('l1Calling.lostPurchased')}</th>
                                        <th>{this.props.t('l1Calling.purchased')}</th>
                                        {/* <th>{this.props.t('l1Calling.location')}</th>
                                        <th>{this.props.t('l1Calling.language')}</th> */}
                                    </tr>
                                </thead>

                                <tbody>
                                {(this.state.searchResult.length === 0 && !this.state.loading) ? <tr><td align="center" colSpan="12"><h6 className="text-center text-danger text-bold">{this.props.t('salesFunnel.NoRecordFound')}</h6></td></tr> : null}
                                {(this.state.searchResult && this.state.searchResult.length !== 0) && this.state.searchResult.map((reportData, k) =>
                                    <tr key={k}>
                                        <td>{(reportData.name) ? reportData.name : ''}</td>
                                        
                                        <td>{(reportData.totalLead) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({agent_id: reportData.id})}>{reportData.totalLead}</span> : ''}</td>

                                        <td>{(reportData.totalContactedLead) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({agent_id: reportData.id, status_id: 2})}>{reportData.totalContactedLead}</span> : ''} {(reportData.totalLead && reportData.totalContactedLead) ? ' ('+((reportData.totalContactedLead/reportData.totalLead)*100).toFixed(2)+'%)' : ''}</td>

                                        <td>{(reportData.totalVerifiedLead) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({agent_id: reportData.id, status_id: 3})}>{reportData.totalVerifiedLead}</span> : ''}{(reportData.totalVerifiedLead && reportData.totalContactedLead) ? ' ('+((reportData.totalVerifiedLead/reportData.totalContactedLead)*100).toFixed(2)+'%)' : ''}</td>

                                        <td>{(reportData.totalXsell) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({agent_id: reportData.id, status_id: 3, xsell: 1})}>{reportData.totalXsell}</span> : ''}{(reportData.totalVerifiedLead && reportData.totalXsell) ? ' ('+((reportData.totalXsell/reportData.totalVerifiedLead)*100).toFixed(2)+'%)' : ''}</td>

                                        <td>{(reportData.totalWalkinSchd) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({agent_id: reportData.id, status_id: 5, sub_status_id: 9})}>{reportData.totalWalkinSchd}</span> : ''}{(reportData.totalVerifiedLead && reportData.totalWalkinSchd) ? ' ('+((reportData.totalWalkinSchd/reportData.totalVerifiedLead)*100).toFixed(2)+'%)' : ''}</td>

                                        <td>{(reportData.totalWalkinDone) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({agent_id: reportData.id, status_id: 5, sub_status_id: 38})}>{reportData.totalWalkinDone}</span> : ''} {(reportData.totalWalkinSchd && reportData.totalWalkinDone) ? ' ('+((reportData.totalWalkinDone/reportData.totalWalkinSchd)*100).toFixed(2)+'%)' : ''}</td>

                                        <td>{(reportData.totalClose) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({agent_id: reportData.id, status_id: 7})}>{reportData.totalClose}</span> : ''}{(reportData.totalClose && reportData.totalLead) ? ' ('+((reportData.totalClose/reportData.totalLead)*100).toFixed(2)+'%)' : ''}</td>

                                        <td>{(reportData.totalLostPurchased) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({agent_id: reportData.id, status_id: 7, sub_status_id: [15, 16, 17, 20, 36]})}>{reportData.totalLostPurchased}</span> : ''}{(reportData.totalClose && reportData.totalLostPurchased) ? ' ('+((reportData.totalLostPurchased/reportData.totalClose)*100).toFixed(2)+'%)' : ''}</td>

                                        <td>{(reportData.totalPurchased) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({agent_id: reportData.id, status_id: 6, sub_status_id: 12})}>{reportData.totalPurchased}</span> : ''}{(reportData.totalWalkinDone && reportData.totalPurchased) ? ' ('+((reportData.totalPurchased/reportData.totalWalkinDone)*100).toFixed(2)+'%)' : ''}</td>
                                        {/* <td>{(reportData.totalLocVerifiedLead) ? reportData.totalLocVerifiedLead : ''}{(reportData.totalVerifiedLead && reportData.totalLocVerifiedLead) ? ' ('+((reportData.totalLocVerifiedLead/reportData.totalVerifiedLead)*100).toFixed(2)+'%)' : ''}</td>
                                        <td>{(reportData.totalLangVerifiedLead) ? reportData.totalLangVerifiedLead : ''}{(reportData.totalVerifiedLead && reportData.totalLangVerifiedLead) ? ' ('+((reportData.totalLangVerifiedLead/reportData.totalVerifiedLead)*100).toFixed(2)+'%)' : ''}</td> */}
                                        
                                    </tr>
                                    )}
                                    {
                                        (this.state.loading) ? <tr><td className="loading" colSpan="12"></td></tr> : null
                                    }
                                    {(this.state.searchResult.length) ?
                                    <tr className="totalvalue">
                                        <td>{this.props.t('salesFunnel.total')}</td>
                                        
                                        <td>{(this.state.grandTotal)?<span className={spanLinkCls} onClick={()=>this.reportDetail()}>{this.state.grandTotal}</span>:''}</td>
                                        {/* Grand total Contacted Leads */}
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalContacted) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({status_id: 2})}>{totalCalculationObject.sumTotalContacted}</span> : ''} { (totalCalculationObject && totalCalculationObject.sumTotalLead && totalCalculationObject.sumTotalContacted) ? ' ('+(((totalCalculationObject.sumTotalContacted/totalCalculationObject.sumTotalLead)*100).toFixed(2)+'%)') : ''}</td>
                                        {/* Grand total Verified Leads */}
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalVerifiedLead) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({status_id: 3})}>{totalCalculationObject.sumTotalVerifiedLead}</span> : ''} { (totalCalculationObject.sumTotalVerifiedLead && totalCalculationObject.sumTotalContacted) ? ' ('+(((totalCalculationObject.sumTotalVerifiedLead/totalCalculationObject.sumTotalContacted)*100).toFixed(2)+'%)') : ''}</td>
                                        {/* Grand total cross sell Leads */}
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalXsell) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({status_id: 3, xsell: 1})}>{totalCalculationObject.sumTotalXsell}</span> : ''} { (totalCalculationObject && totalCalculationObject.sumTotalVerifiedLead && totalCalculationObject.sumTotalXsell) ? ' ('+(((totalCalculationObject.sumTotalXsell/totalCalculationObject.sumTotalVerifiedLead)*100).toFixed(2)+'%)') : ''}</td>                                        
                                        {/* Grand total Walkin Scheduled Leads */}
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalWalkinSchd) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({status_id: 5, sub_status_id: 9})}>{totalCalculationObject.sumTotalWalkinSchd}</span> : ''} { (totalCalculationObject && totalCalculationObject.sumTotalWalkinSchd && totalCalculationObject.sumTotalVerifiedLead) ? ' ('+(((totalCalculationObject.sumTotalWalkinSchd/totalCalculationObject.sumTotalVerifiedLead)*100).toFixed(2)+'%)') : ''}</td>
                                        {/* Grand total Walkin Done Leads */}
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalWalkinDone) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({status_id: 5, sub_status_id: 38})}>{totalCalculationObject.sumTotalWalkinDone}</span> : ''} { (totalCalculationObject && totalCalculationObject.sumTotalWalkinDone && totalCalculationObject.sumTotalWalkinSchd) ? ' ('+(((totalCalculationObject.sumTotalWalkinDone/totalCalculationObject.sumTotalWalkinSchd)*100).toFixed(2)+'%)') : ''}</td>
                                        {/* Grand total Closed Leads */}
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalClose) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({status_id: 7})}>{totalCalculationObject.sumTotalClose}</span> : ''} { (totalCalculationObject && totalCalculationObject.sumTotalLead && totalCalculationObject.sumTotalClose) ? ' ('+(((totalCalculationObject.sumTotalClose/totalCalculationObject.sumTotalLead)*100).toFixed(2)+'%)') : ''}</td>
                                        {/* Total lost purchased calculation*/}
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalLostPurchased) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({status_id: 7, sub_status_id: [15, 16, 17, 20, 36]})}>{totalCalculationObject.sumTotalLostPurchased}</span> : ''} { (totalCalculationObject && totalCalculationObject.sumTotalLostPurchased && totalCalculationObject.sumTotalClose) ? ' ('+(((totalCalculationObject.sumTotalLostPurchased/totalCalculationObject.sumTotalClose)*100).toFixed(2)+'%)') : ''}</td>
                                        {/* Grand total Purchased Leads */}    
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalPurchased) ? <span className={spanLinkCls} onClick={()=>this.reportDetail({status_id: 6, sub_status_id: 12})}>{totalCalculationObject.sumTotalPurchased}</span> : ''} { (totalCalculationObject && totalCalculationObject.sumTotalPurchased && totalCalculationObject.sumTotalWalkinDone) ? ' ('+(((totalCalculationObject.sumTotalPurchased/totalCalculationObject.sumTotalWalkinDone)*100).toFixed(2)+'%)') : ''}</td>

                                        {/* <td>{(totalCalculationObject && totalCalculationObject.sumTotalLoc) ? totalCalculationObject.sumTotalLoc : ''}</td>
                                        <td>{(totalCalculationObject && totalCalculationObject.sumTotalLang) ? totalCalculationObject.sumTotalLang : ''}</td> */}
                                        
                                    </tr> : null }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
    );
  }
}
export default withTranslation('report') (L1CallingResult);
