import React, { Component } from 'react';
//import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import * as helper from '../../../config/helper';
import { default as config } from '../../../config/config';
import _ from 'lodash';
import DatePicker from "react-datepicker";
//import DateFormat from 'dateformat';
import "react-datepicker/dist/react-datepicker.css";
import InputField from '../../elements/InputField';
import CheckBox from '../../elements/CheckBox';
import { bindActionCreators } from 'redux';
import { LeadActions } from "../../../store/action";
import Select from 'react-select';
import moment from "moment";
import LeadService from '../../../service/leadService'

class WalkinDetails extends Component {
    constructor(props) {
        super(props);
        this.state={
            selectedOption: {

            },
            date: new Date(),
            positiveFeedback: [],
            negativeFeedback: [],
            activeComponentIndex: 1,
            activeMakeModelVariantIndex: 1,
            addCarSelectorDisplay : false,
            makeModelLangTabberDisplay : false,
            customerOfferEnable : true,
            additionalFeedbackComment : '',
            pageValidated : false,
            leadDetails : {},
            make : [],
            model : [],
            version : [],
            makeList : [],
            usedCars : [],
            error : {

            },
            walkingInformation : []
        };
        this.showMakeModelLangTabber=this.showMakeModelLangTabber.bind(this);
        this.handleOutsideClick=this.handleOutsideClick.bind(this);
    }

    handleChange=(selectedKey, event) => {

    };
    // static getDerivedStateFromProps=async (nextProps, prevState) => {
    //     let updatedStateData={};
    //     const {makeList,walkingInformation,selectedOption}=prevState
    //     let {makeModelVersionList,walkingInfo}=nextProps;
    //     if(makeModelVersionList !== makeList){
    //         updatedStateData['makeList']=makeModelVersionList;
    //     }
    //     // if(walkingInformation!==walkingInfo){
    //         // updatedStateData['walkingInformation']=walkingInfo;
    //         // const {id}=walkingInfo;
    //         // let queryData={
    //         //     lead_id : id
    //         // }
    //         // let response=await LeadService.getWalkinPurchaseData(queryData)
    //         // let {data:{data}={}}=response
    //         // console.log(data);
    //         // if(!updatedStateData.hasOwnProperty('selectedOption')) updatedStateData['selectedOption']={}
    //         // let {make,model,version}=makeModelVersionList
    //         // if(data.walkin_make && data.walkin_model && data.walkin_variant){
    //         //     let selected_make=make.find(e=>e.id === data.walkin_make);
    //         //     let selected_model=model.find(e=>e.id === data.walkin_model);
    //         //     let selected_variant=version.find(e=>e.id === data.walkin_variant);
                
    //         //     updatedStateData['selectedOption']['selected_make']=selected_make?selected_make:''
    //         //     updatedStateData['selectedOption']['selected_model']=selected_model?selected_model:''
    //         //     updatedStateData['selectedOption']['selected_variant']=selected_variant?selected_variant:''
                
    //         // }
    //         // if(data.conversion_source){
    //         //     updatedStateData['selectedOption']['conversion_source']=config.constants.conversionSource.filter(e=>e.value === data.conversion_source)
                
    //         // }
    //         // if(data.purchase_carprice){
    //         //     updatedStateData['selectedOption']['purchase_amount']=data.purchase_carprice
    //         // }
    //         // if(data.purchase_date){
    //         //     updatedStateData['selectedOption']['purchase_date']=data.purchase_date
    //         // }
    //         // if(data.walkin_asking_price){
    //         //     updatedStateData['selectedOption']['dealer_currency_input']=data.walkin_asking_price
    //         // }
    //         // if(data.walkin_date_popup){
    //         //     updatedStateData['selectedOption']['walkin_date']=data.walkin_date_popup
    //         // }
    //         // if(data.walkin_offer_price){
    //         //     updatedStateData['selectedOption']['customer_currency_input']=data.walkin_offer_price
    //         // }
    //         // if(data.walkin_dealer_msg_id){
                
    //         // }
            
    //     // }
    //     return updatedStateData
    // }
 
    componentDidMount=async () => {
        document.addEventListener('click', this.handleOutsideClick, false);
        await this.getFeedbackList();
        let {makeModelVersionList,walkingInfo}= this.props;
        ///feed details of walking info 
        
        this.getSelectedPurchaseData(makeModelVersionList,walkingInfo);
        this.getactiveCarList(walkingInfo,makeModelVersionList);
        this.setState({makeList:makeModelVersionList,walkingInformation:walkingInfo})
        
        
        // const {statusList, dealerList,timelineDetails}=this.props;
        // let status=await this.getOptionVal(statusList,'status');
        // let dealer=await this.getOptionVal(dealerList,'dealer');
        // this.setState({statusList:status,dealerList:dealer,timelineDetails:timelineDetails})
    }
    getactiveCarList=async (walkingInfo,makeModelVersionList) => {
        ///// get active car list
        let {car_id,id,dealer_id}=walkingInfo; 
        if(id && dealer_id){
            car_id=car_id?car_id:''
            let requestData={
                // sub_lead_id : id,
                user_id : dealer_id,
                stock_used_car_id : car_id,
                // lead_id : lead_id
            }
            let response=await LeadService.getUsedStockcarDetails(requestData);
            if(response.status === 200){
                let {data:{data}}=response;
                if(data){
                    /////// map wtih mmv data and show as list in options /////
                    // let {make_id,model_id,version_id}=data;
                    // let usedCars=[];
                    // let {make,model,version}=makeModelVersionList;
                    // if(make_id && model_id && version_id){
                    //     let thisMake=make.find(e=>e.id === make_id)
                    //     let thisModel =
                    // }
                    // this.setState({usedCars:usedCars})
                }
            }
        }
    }
    getSelectedPurchaseData=async (mmv,walkingInfo) => {
        let {selectedOption,positiveFeedback,negativeFeedback}=this.state;
        let {id}=walkingInfo ;
        let additionalComment=[]
        let queryData={
            lead_id :id
        }
        let response=await LeadService.getWalkinPurchaseData(queryData);
        let {data:{data}={}}=response;

        if(data && Object.keys(data)){
            let {make,model,version}=mmv
            if(data.walkin_make && data.walkin_model && data.walkin_variant){
                
                let selected_make=make.find(e=>e.id === data.walkin_make);
                let selected_model=model.find(e=>e.id === data.walkin_model);
                let selected_variant=version.find(e=>e.vn_id === data.walkin_variant);
                
                selectedOption['selected_make']=selected_make?{value : selected_make.id,label:selected_make.make}:''
                selectedOption['selected_model']=selected_model?{value:selected_model.id,label:selected_model.m}:''
                selectedOption['selected_variant']=selected_variant?{value:selected_variant.vn_id,label:selected_variant.vn}:''
                
            }
            if(data.conversion_source){
                
                selectedOption['conventional_source']=config.constants.conversionSource.filter(e=>+e.value === +data.conversion_source)
                if(selectedOption['conventional_source']){
                    
                    selectedOption['conventional_source']=selectedOption['conventional_source'][0]
                }
                
            }
            if(data.purchase_carprice){
                selectedOption['purchase_amount']=data.purchase_carprice
            }
            if(data.purchase_date){
                selectedOption['purchase_date']=moment(data.purchase_date).toDate()
            }
            if(data.walkin_asking_price){
                selectedOption['dealer_currency_input']=data.walkin_asking_price
            }
            if(data.walkin_date_popup){
                selectedOption['walkin_date']=moment(data.walkin_date_popup).toDate()
            }
            if(data.walkin_offer_price){
                selectedOption['customer_currency_input']=data.walkin_offer_price
            }
            if(data.walkin_dealer_msg_id){
                let idArray=[...data.walkin_dealer_msg_id]

                for(let i of positiveFeedback){
                    if(idArray.includes(i.id)){
                        i.selected=true;
                        additionalComment.push(i.walkin_feedback)
                    }
                }
                for(let i of negativeFeedback){
                    if(idArray.includes(i.id)){
                        i.selected=true;
                        additionalComment.push(i.walkin_feedback)
                    }
                }
                additionalComment=additionalComment.join(" | ")

            }
            this.shouldButtonEnable();
        }
        this.setState({selectedOption:selectedOption,positiveFeedback:positiveFeedback,negativeFeedback:negativeFeedback,additionalFeedbackComment:additionalComment})
    }
  
    handleValidateForm=() => {
        ////// check validation here ////////
        let {activeComponentIndex,selectedOption:{walkin_date,dealer_currency_input,customer_currency_input,customerOfferEnable,purchase_date,selected_make,selected_model,selected_variant,purchase_amount,conventional_source,registration_number},negativeFeedback,positiveFeedback,error} =this.state; 
        if(activeComponentIndex === 1 || activeComponentIndex === 2){
            if(!walkin_date){
                error['walkin_date']="Please enter walkin date"
            }
            if(!dealer_currency_input){
                error['dealer_currency_input']="Please enter dealer currency"
            }
            if(dealer_currency_input && !Number.isFinite(+dealer_currency_input)){
                error['dealer_currency_input']="Please enter number only"
            }
            if(customerOfferEnable && !customer_currency_input){
                error['customer_currency_input']="Please enter customer currency"
            }
            if(customerOfferEnable && customer_currency_input && !Number.isFinite(+customer_currency_input)){
                error['customer_currency_input']="Please enter number only"
            }
            
            if(!(negativeFeedback.some(e=>e.selected === true) || positiveFeedback.some(e=>e.selected === true))){
                error['feedback']="Please choose a feedback";
            }
            
        }
        if(activeComponentIndex === 2){
            if(!purchase_date){
                error['purchase_date']="Please enter purchase date"
            }
            if(!purchase_amount){
                error['purchase_amount']="Please enter purchase amount"
            }
            if(purchase_amount && !Number.isFinite(+purchase_amount)){
                error['purchase_amount']="Purchase amount should be a number"
            }
            if(!conventional_source){
                error['conventional_source']="Please enter conventional Source"
            }
            if(!selected_make || !selected_model || !selected_variant){
                error['make_model_version']="Please enter make,model,version"
            }
            if(!registration_number){
                error['registration_number']="Please enter registration number"
            }
        }
        if(Object.keys(error).length){
            this.setState({error:error})
            return false;
        }
        return true;
    }
    submitForm=() => {
        if(this.handleValidateForm()){
            ///submit form here
            
            const {walkingInformation:{id},selectedOption:{walkin_date,dealer_currency_input,purchase_date,purchase_amount,conventional_source,selected_make,selected_model,selected_variant,customer_currency_input},negativeFeedback,positiveFeedback}=this.state;
            
            let feebackSelected=[]
            feebackSelected.push(...positiveFeedback.filter(e=>e.selected).map(e=>e.id))
            feebackSelected.push(...negativeFeedback.filter(e=>e.selected).map(e=>e.id))
            let data={
                wp_lead_car_id : id,
                walkin_date_popup : moment(walkin_date).format("DD-MM-YYYY"),
                walkin_offer_price : +customer_currency_input,
                walkin_asking_price : +dealer_currency_input,
                purchase_date : moment(purchase_date).format("DD-MM-YYYY"),
                purchase_carprice : purchase_amount,
                conversion_source : conventional_source.value,
                walkin_make : selected_make?selected_make.value:'',
                walkin_model : selected_model?selected_model.value:'',
                walkin_variant : selected_variant?selected_variant.value:'',
                walkin_dealer_msg_id : feebackSelected,
                // walkin_status : status_id,
                // walkin_sub_status :sub_status_id
            }
            // console.log(data);
            this.props.saveWalkinPurchaseDetails(data);
        }
    }
    getFeedbackList=async () => {
        let { positiveFeedback, negativeFeedback }=this.state;
        let feedbackList=await this.props.actions.listFeedback();
        _.forEach(feedbackList.data, (feedback) => {
            feedback.selected=false;
            if (Number(feedback.walkin_feedback_point) === 1 || Number(feedback.id) === 8) {
                positiveFeedback.push(feedback)
            } else if ([0, -1].indexOf(Number(feedback.walkin_feedback_point) !== -1) || Number(feedback.id) !== 8) {
                negativeFeedback.push(feedback)
            }

        })
        this.setState({ positiveFeedback, negativeFeedback })
    }
    handleChangeActiveItem=(e) => {
        ////////////// get data element and change state for perticular index //////////////
        // console.log(e.target);
        let { target: { dataset: { index,type }={} }={} }=e;
        
        if (index) {
            if(type && type==="mmv"){
                this.setState({ activeMakeModelVariantIndex: +index })
            }else {
                
                if(this.handleValidateForm()){
                    this.setState({ activeComponentIndex: +index })
                }
            }
            
        }
    }
    handleInputChange=(e,inputName) => {
        let {selectedOption,customerOfferEnable,error}=this.state
        const {target}=e
        delete(error['apiError'])
        delete(error['make_model_version'])
        if(target && !inputName){
            const {name,value}=target;
            delete(error[name])
            const conditional=['nooffer'].includes(name)
            if(conditional){
                selectedOption[name]=selectedOption[name]?false:true;
                if(name === "nooffer") {
                    customerOfferEnable=!selectedOption[name]
                    if(!customerOfferEnable){
                        ////delete property
                        delete(error['customer_currency_input'])
                        delete(selectedOption['customer_currency_input']);
                    }
                }
                
            }else{
                selectedOption[name]=value;
            }
            
        }else if(inputName){
            delete(error[inputName])
            // if(e instanceof Date) selectedOption[inputName]=e;
            if(['selected_make','selected_model'].includes(inputName)){
                if(inputName === 'selected_make'){
                    delete(selectedOption['selected_model']);
                    delete(selectedOption['selected_variant']);
                }
             
                delete(selectedOption['selected_variant']);
                this.getMakeModelVersionList();
            }
            
            selectedOption[inputName]=e;
          
        }
        this.shouldButtonEnable();//////enabling a button when fields are filled ///////////
        this.setState({selectedOption:selectedOption,customerOfferEnable:customerOfferEnable,error:error})
    }
    handleFeedbackChange=(id,point) =>{
        let {positiveFeedback,negativeFeedback,additionalFeedbackComment,error}=this.state;
        let comments=[];
        delete(error['feedback']);
            ////// positive feeedback //////////
        positiveFeedback= positiveFeedback.map(function(e){
            if(e.id === id) e.selected=!e.selected 
            if(e.selected) comments.push(e.walkin_feedback)
            return e;
        })
    
        negativeFeedback = negativeFeedback.map(function(e){
            if(e.id === id) e.selected=!e.selected 
            if(e.selected) comments.push(e.walkin_feedback)
            return e;
        })
    
        additionalFeedbackComment=comments.join(" | ")
        this.shouldButtonEnable();
        this.setState({positiveFeedback:positiveFeedback,negativeFeedback:negativeFeedback,additionalFeedbackComment:additionalFeedbackComment,error:error})
    }
    slideCarChooseDetail=() => {
        this.setState({addCarSelectorDisplay:true})
    }
    shouldButtonEnable=(e) => {
        let {pageValidated,selectedOption,negativeFeedback,positiveFeedback}=this.state;
        if(selectedOption['walkin_date'] && selectedOption['dealer_currency_input'] && (negativeFeedback.some(e=>e.selected === true) || positiveFeedback.some(e=>e.selected === true))){
            this.setState({pageValidated:true});
        }else if(pageValidated){
            this.setState({pageValidated:false});
        }
        
    }
    handleOutsideClick=(e) => {
        
        if (this.node && this.node.contains(e.target)) {
            return;
        }
          
          this.showMakeModelLangTabber();
    }
    showMakeModelLangTabber=() => {
        let {makeModelLangTabberDisplay}=this.state;
        
        if (!makeModelLangTabberDisplay) {
          // attach/remove event handler
          document.addEventListener('click', this.handleOutsideClick, false);
        } else {
          document.removeEventListener('click', this.handleOutsideClick, false);
        }
        ////// call make model version /////
        this.getMakeModelVersionList();
        this.setState({
            makeModelLangTabberDisplay : !makeModelLangTabberDisplay
        })
    }
    getMakeModelVersionList=() => {
        // console.log("here getting things",this.props.makeModelVersionList);
        let { makeList : {make:all_make,model:all_model,version:all_version}={},selectedOption}=this.state;

        let make=[],model=[],version=[]
        if(make.length===0 && all_make && all_make.length >0){
            make=all_make.map(function(e){
                return {label : e.make,value : e.id}
            })
        }
        if(selectedOption.selected_make){
            
            model=all_model.filter(e => e.mk_id === selectedOption.selected_make.value).map(function(e){
                return {label : e.m,value : e.id}
            })
        }
        if(selectedOption.selected_model){
            
            version=all_version.filter(e => (e.md_id === +selectedOption.selected_model.value)).map(function(e){
                return {label : e.vn,value : e.vn_id}
            })
            
            
        }
        this.setState({make:make,model:model,version:version})
    }
    
    render() {

        const { positiveFeedback, negativeFeedback, activeComponentIndex,selectedOption,addCarSelectorDisplay,makeModelLangTabberDisplay,activeMakeModelVariantIndex,customerOfferEnable,additionalFeedbackComment ,pageValidated,error,make,model,version,walkingInformation:{status_id,sub_status_id,usedCars} ={}}=this.state;
    
        let selectedMake=selectedOption.selected_make?selectedOption.selected_make.label:'',
        selectedModel=selectedOption.selected_model?selectedOption.selected_model.label:'',
        selectedVariant=selectedOption.selected_variant?selectedOption.selected_variant.label:'';
        let selectedMakeModelVariant=selectedMake + " " + selectedModel + " " + selectedVariant
        let existingComment=selectedOption.additional_comment?selectedOption.additional_comment:''

        let showSave=false;
        
        if((status_id && status_id === 7 && sub_status_id && sub_status_id !== 27) ){
            showSave=true;
        }
        let comments=additionalFeedbackComment?additionalFeedbackComment.concat(",",existingComment) :existingComment;
        return (
            <div className="row">
                <div className="container-fluid">
                    <ul className="tabbed-walking-items list-inline nav-tabs">
                        <li className={activeComponentIndex === 1 ? "nav-item active" : "nav-item"} data-index="1" onClick={this.handleChangeActiveItem}>1. Walk-in Feedback</li>
                        <li className={activeComponentIndex === 2 ? "nav-item active" : "nav-item disabled"} data-index="2" onClick={this.handleChangeActiveItem} > 2. Coversion Details</li>
                    </ul>
                    <div className="tabbed-list-content">
                        {activeComponentIndex === 1 && <div>
                            {/* ///// for index 1 /////////// */}
                            <div className="row leave-margin-30">
                                <div className="col-sm-4">
                                    <label>Walk In Date</label>
                                    
                                    <DatePicker
                                        className="form-control"
                                        name="walkin_date"
                                        onChange={(e) => this.handleInputChange(e,"walkin_date")}
                                        dateFormat={config.constants.dateFormatDMY}
                                        onKeyDown={e => e.preventDefault()}
                                        selected={selectedOption.walkin_date}
                                        minDate={moment().subtract(4,"months").toDate()}
                                        maxDate={new Date()}
                                    />
                                    
                                    {error.walkin_date && <div className="error-block">{error.walkin_date}</div>}
                                </div>
                                <div className="col-sm-4">
                                    <InputField
                                        inputProps={{
                                            
                                            id: "customer_currency_input",
                                            type: "text",
                                            name: "customer_currency_input",
                                            label: "Customer offer",
                                            value : selectedOption.customer_currency_input?selectedOption.customer_currency_input:'',
                                            disabled : customerOfferEnable?false:true,
                                            dataerror : error.customer_currency_input
                                        }}
                                        onChange={this.handleInputChange}
                                    />
                                    <span className="add-currency-symbol">$</span>
                                </div>
                                <div className="col-sm-4">
                                   
                                    
                                    <InputField
                                        inputProps={{
                                            
                                            id: "dealer_currency_input",
                                            type: "text",
                                            name: "dealer_currency_input",
                                            label: "Dealer asking price",
                                            value : selectedOption.dealer_currency_input?selectedOption.dealer_currency_input:'',
                                            dataerror : error.dealer_currency_input
                                        }}
                                        onChange={this.handleInputChange}
                                    />
                                    <span className="add-currency-symbol">$</span>
                                </div>
                                <div className="col-sm-12 no-offer-row">
                                    <CheckBox type="checkbox" name="nooffer"  onChange={this.handleInputChange} className="text-center" label="Didn't share any offer" checked={selectedOption.nooffer?true:false} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="container-fluid">
                                    <h6>Ask customer what happened during walk-in</h6>
                                    <div className="">
                                        {positiveFeedback instanceof Array && positiveFeedback.length && <div className="col-sm-6">
                                        <div className="alert alert-success">
                                            <div>
                                                <span id="positive-item-options-label">Positive</span>
                                            </div>
                                            <ul className="list-inline walkin-review-options">
                                                {positiveFeedback.map(e => <li onClick={() => this.handleFeedbackChange(e.id,e.walkin_feedback_point)} id={e.id} key={e.id} point={e.walkin_feedback_point} name="walkin-postive-reason"  className={e.selected?"active":''}>{e.walkin_feedback}</li>)}
                                            </ul>
                                            </div>
                                        </div>}
                                        {negativeFeedback instanceof Array && negativeFeedback.length && <div className="col-sm-6">
                                        <div className="alert alert-danger">
                                            <div>
                                                <span>Negative</span>
                                            </div>
                                            <ul className="list-inline walkin-review-options">
                                                {negativeFeedback.map(e=><li onClick={()=>this.handleFeedbackChange(e.id,e.walkin_feedback_point)} id={e.id} key={e.id} point={e.walkin_feedback_point} name="walkin-postive-reason"  className={e.selected?"active":''}>{e.walkin_feedback}</li>)}
                                            </ul>
                                            </div>
                                        </div>}
                                        {error.feedback && <div className="error-block">{error.feedback}</div>}
                                    </div>
                                    
                                    <InputField
                                        inputProps={{
                                            className:"form-control",
                                            type : "text",
                                            label : "Additional Comments",
                                            name : "additional_comment",
                                            value : comments?comments:''
                                        }}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="text-center" id="next-btn-tabber">
                                       <div className="col-sm-4">

                                            {!showSave && <input type="button" value="Next" className={pageValidated?"active":''} data-index="2" onClick={this.handleChangeActiveItem}/>}

                                            {showSave && <input type="button" value="Save" className={pageValidated?"active":''} onClick={this.submitForm}/>}
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {activeComponentIndex === 2 && <div>
                            {/* ///// for index 2 /////////// */}
                            <div className="row leave-margin-30">
                                <div className="col-sm-4">
                                    <label>Purchase Date</label>
                                    <DatePicker
                                        className="form-control"
                                        name="purchase_date"
                                        onChange={(e) => this.handleInputChange(e,"purchase_date")}
                                        dateFormat={config.constants.dateFormatDMY}
                                        onKeyDown={e => e.preventDefault()}
                                        selected={selectedOption.purchase_date}
                                        minDate={moment().subtract(1,"day").toDate()}
                                        maxDate={new Date()}
                                    />
                                    {error.purchase_date && <div className="error-block">{error.purchase_date}</div>}
                                </div>
                                <div className="col-sm-4">
                                    <InputField
                                        inputProps={{
                                            className: "form-control currency-input",
                                            id: "purchase_amount",
                                            type: "text",
                                            name: "purchase_amount",
                                            label: "Purchase Amount",
                                            value : selectedOption.purchase_amount?selectedOption.purchase_amount:'',
                                            dataerror : error.purchase_amount
                                        }}
                                        onChange={this.handleInputChange}
                                    />
                                    <span className="add-currency-symbol">$</span>
                                </div> 
                                
                                <div className="col-sm-4">
                                    <label>Conversion Source</label>
                                    <Select
                                        className="mrg-b5"
                                        id="status"
                                        name="conventional_source"
                                        value={selectedOption.conventional_source?selectedOption.conventional_source:''}
                                        onChange={(e) => this.handleInputChange(e,"conventional_source")}
                                        options={config.constants.conversionSource}
                                    />
                                    {error.conventional_source && <div className="error-block">{error.conventional_source}</div>}
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="container-fluid">
                                    <h6>Search the car name has bought from</h6>
                                    {!addCarSelectorDisplay && <Select
                                        id="used_car_id"
                                        options={usedCars}
                                    />}
                                    
                                    <div className="add-car-box" ref={node=>{ this.node=node; }}>
                                        {!addCarSelectorDisplay && <p>Can’t Find? Try Adding Manually. <span onClick={this.slideCarChooseDetail}>ADD CAR</span></p>}
                                        {addCarSelectorDisplay && <div className="container-fluid text-left">
                                            <div className="col-sm-6">
                                                <InputField
                                                    inputProps={{
                                                        id : "make_model_variant",
                                                        onClick:this.showMakeModelLangTabber,
                                                        label : "Select Vehicle",
                                                        value : selectedMakeModelVariant?selectedMakeModelVariant:'',
                                                        readOnly : true,
                                                        dataerror : error.make_model_version?error.make_model_version:''
                                                    }}
                                                />
                                                {makeModelLangTabberDisplay && <div className="container-fluid">
                                                    <ul className="list-inline make-model-variant">
                                                        <li className={activeMakeModelVariantIndex ===1?"active":""} data-index="1" data-type="mmv" onClick={this.handleChangeActiveItem}>Brand</li>
                                                        <li className={activeMakeModelVariantIndex ===2?"active":""} data-index="2" data-type="mmv" onClick={this.handleChangeActiveItem} >Model</li>
                                                        <li className={activeMakeModelVariantIndex ===3?"active":""} data-index="3" data-type="mmv" onClick={this.handleChangeActiveItem}>Variant</li>
                                                    </ul>
                                                    {activeMakeModelVariantIndex ===1 && <Select
                                                        id="select-make"
                                                        name="selected_make"
                                                        placeholder="Select Make"
                                                        className="mmv-select"
                                                        value={selectedOption.selected_make?selectedOption.selected_make:''}
                                                        onChange={(e)=>this.handleInputChange(e,"selected_make")}
                                                        options={make?make:''}
                                                    />}
                                                    {activeMakeModelVariantIndex ===2 && <Select
                                                        id="select-make"
                                                        name="selected_model"
                                                        value={selectedOption.selected_model?selectedOption.selected_model:''}
                                                        placeholder="Select Model"
                                                        className="mmv-select"
                                                        onChange={(e) => this.handleInputChange(e,"selected_model")}
                                                        options={model?model:''}
                                                    />}
                                                    {activeMakeModelVariantIndex ===3 && <Select
                                                        id="select-variant"
                                                        name="selected_variant"
                                                        placeholder="Select variant"
                                                        className="mmv-select"
                                                        value={selectedOption.selected_variant?selectedOption.selected_variant:''}
                                                        onChange={(e) => this.handleInputChange(e,"selected_variant")}
                                                        options={version?version:''}
                                                    />}
                                                    
                                                </div>}
                                            </div>
                                            <div className="col-sm-6">
                                                <InputField
                                                    inputProps={{
                                                        id : "registration_number",
                                                        label : "Registration Number",
                                                        name : "registration_number",
                                                        dataerror : error.registration_number
                                                    }}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center" id="next-btn-tabber">
                                       <div className="col-sm-4">
                                            <input type="button" value="Save" className={pageValidated?"active":''} onClick={this.submitForm}/>
                                       </div>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps=(state, ownProps) => ({
    makeModelVersionList: state.lead.makeModelVersionList,
    // statusList:state.lead.statusListData,
    // dealerList: state.lead.dealerListData,
    // timelineDetails: state.lead.timelineDetails

});

const mapDispatchToProps=dispatch => {
    return {
        actions: {
            listFeedback: bindActionCreators(
                LeadActions.listFeedback,
                dispatch
            )
        }
    }
}

export default withTranslation('lead')(connect(mapStateToProps, mapDispatchToProps)(WalkinDetails));
