import { SourceConstants } from '../constants/source.constants';

export default function SourceReducer(state = {}, action) {
  switch (action.type) {
    case SourceConstants.REQUEST_SOURCE_LIST_SUCCESS:
      return {
        sourceListData: action.resp , ...state 
      };
    case SourceConstants.REQUEST_SOURCE_LIST_FAILURE:
      return {
        sourceListData: action.resp , ...state 
      };
      case SourceConstants.REQUEST_SUB_SOURCE_LIST_SUCCESS:
      return {
        subSourceListData: action.resp , ...state
      };
      case SourceConstants.REQUEST_SUB_SOURCE_LIST_FAILURE:
      return {
        subSourceListData: action.resp , ...state 
      };
    default:
      return state
  }
}