import React, { Component } from 'react';
import Select from 'react-select';
import InputField from '../../../elements/InputField';
import CityService from './../../../../service/cityService';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
//import { connect } from 'react-redux';
import DateFormat from 'dateformat';
import { default as config } from './../../../../config/config';
import _ from 'lodash';

class EditCity extends Component {
    city_id_hash;
    constructor(props) {
        super(props);
        this.state = {
            editFormData: {},
            successResponse: false,
            redirect: false,
            errors: {},
            show: false,
            loaderClass: '',
            searchParams:{},
            usersRecord:[]
        }
        this.city_id_hash = this.props.match.params.id;
        if(this.city_id_hash)
            this.getCityDetails = this.getCityDetails.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
    }

    componentDidMount = async () => {
        if(this.city_id_hash){
            let searchParams = this.state.searchParams;
            searchParams.id = this.city_id_hash;
            this.setState({searchParams:searchParams})
            await this.getCityDetails();
            await this.getUserList()
        }else
            this.setState({editFormData:{}})
    }

    getCityDetails() {
        var thisObj = this;
        CityService.getCityList(this.state.searchParams)
        .then(function (response) {
            thisObj.flag = false;
            thisObj.setState({loading:false,pageloading:false})
            if (response.data.status === 200) {
                if (response.data.data.length) {
                    thisObj.setState({ editFormData: response.data.data[0] ,loading:false,pageloading:false}, () => {
                        thisObj.flag = true;
                    });
                } else {
                    thisObj.setState({ editFormData: response.data.data});
                }
            }
        })
        .catch(function (response) {
            thisObj.setState({loading:false,pageloading:false})
        });
    }

    
    handleChange = (event) => {
        let editFormData = this.state.editFormData;
        let errors = this.state.errors;
        if(event && event === 'status')
            editFormData.status = (editFormData.status === '0') ? '1' : '0';
        else
            editFormData.lang_id = event.value;
            if(event.value){
                errors.lang_id = '';
            }
        this.setState({editFormData:editFormData})
    };
    
    submitForm = async (event) => {
        event.preventDefault();
        if (this.validForm()) {
            this.setState({ loaderClass : 'loading' });
            let postData = await this.filterPostData();
            if(postData.id){
                CityService.updateCityDetails(postData)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                    } else if (response.status === 401 && response.data.status === 401) {
                        this.setState({ redirect: true })
                    }
                })
                .catch((error) => {
                    
                });
            }else{
                CityService.saveCityDetails(postData)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                    } else if (response.status === 401 && response.data.status === 401) {
                        this.setState({ redirect: true })
                    }
                })
                .catch((error) => {
                });
            }
        }
    }

    filterPostData = () => {
        let params={};
        if(this.state.editFormData.id)
            params.id = this.state.editFormData.id;
        if(this.state.editFormData.lang_id)
            params.lang_id = this.state.editFormData.lang_id;
        if(this.state.editFormData.name)
            params.name = this.state.editFormData.name;
        if(this.state.editFormData.region_code)
            params.region_code = this.state.editFormData.region_code;
        if(this.state.editFormData.state_id)
            params.state_id = this.state.editFormData.state_id;
        if(this.state.editFormData.city_order)
            params.state_id = this.state.editFormData.city_order;
        if(this.state.editFormData.status)
            params.status = this.state.editFormData.status;
        return params;
    }

    validForm = () => {
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        let formIsValid = true;
        if (!editFormData["name"]) {
            errors['name'] = this.props.t('validation.name_required')
            formIsValid = false;
        }
        if (!editFormData["lang_id"]) {
            errors['lang_id'] = this.props.t('validation.lang_id_required')
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleChangeEvent = event => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        editFormData[event.target.name] = event.target.value;
        if(fieldRequired === 'yes'){
            if (event.target.value !== '') {
                delete errors[event.target.name];
            } else {
                errors[event.target.name] = this.props.t('validation.'+event.target.name)+this.props.t('validation.is_required');
            }
        }
        this.setState({ editFormData: editFormData, errors: errors });
    }

    getUserName = (userData,keyToMatch,keyToReturn) => {
        const user = _.find(this.state.usersRecord,{'id':userData[keyToMatch]});
        if(user)
            return user[keyToReturn]
        else
            return null;
    }
    
    getUserList = () => {
        var thisObj = this;
        CityService.getAllUserList()
        .then(function (response) {
            if (response.data.status === 200) {
                if (response.data.data.length) {
                        thisObj.setState({ usersRecord: response.data.data });
                    
                } else {
                        thisObj.setState({ usersRecord: []});
                    
                }
            }
        })
        .catch(function (response) {
            
        });
    }

    
    render() {
          const   editFormData   = this.state.editFormData;
          const errors = this.state.errors;
        return (
            <div className="container-fluid">
                <h1>{(editFormData.id) ? this.props.t('formData.editCity'):this.props.t('formData.addCity') }</h1>                
                <div className="card">
                    <div className="card-body">
                        <form method="post" id="cityDetails" onSubmit={this.submitForm}>
                            <div className="row">
                                <div className="col-sm-4 ">
                                <InputField
                                inputProps={{
                                    id: "name",
                                    type: "text",
                                    name: "name",
                                    label: this.props.t('formData.name'),
                                    value: editFormData.name || '',
                                    dataerror: errors.name || '',
                                    validationreq: "yes"
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-sm-4">
                                <InputField
                                inputProps={{
                                    id: "region_code",
                                    type: "text",
                                    name: "region_code",
                                    label: this.props.t('formData.regionCode'),
                                    value: editFormData.region_code || '',
                                    dataerror: errors.region_code || ''
                                    
                                }}
                                onChange={this.handleChangeEvent}
                                />
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-sm-4 form-group">
                                    <label>{this.props.t('formData.state')}</label>
                                    <Select
                                        id="state_id"
                                        options={[]}
                                        name="state_id"
                                        onChange={this.handleChange.bind(this)}
                                        value={editFormData.state_id || ''}
                                        
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-sm-4 form-group">
                                    <label>{this.props.t('formData.language')}</label>
                                    <span className="required">*</span>
                                    <Select
                                        id="lang_id"
                                        options={config.constants.language}
                                        name="lang_id"
                                        onChange={this.handleChange.bind(this)}
                                        value={config.constants.language.filter(({ value }) => value === editFormData.lang_id)}
                                    />
                                    <span className="text-danger">{errors.lang_id || ''}</span>
                                </div>
                            </div>
                            {(editFormData.id) ?  
                            <div className="row form-group" >
                                <ul className="maindetail-list">
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.createdDate')}</div>
                                <div className="sub-value">{DateFormat(editFormData.created_at,"dd/mm/yyyy")}</div>
                                    </li>
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.createdBy')}</div>
                                        <div className="sub-value">{this.getUserName(editFormData,'added_by','username')}</div>
                                    </li>
                                    <li className="maindetail-item">
                                        <div className="subheading">{this.props.t('formData.updatedDate')}</div>
                                        <div className="sub-value">{(editFormData.updated_at) ? (DateFormat(editFormData.updated_at,"dd/mm/yyyy")) : ''}</div>
                                    </li>

                                </ul>
                            </div>
                            : ''}
                            
                            {(editFormData.id) ?
                            <div>
                                <label>{this.props.t('formData.status')} </label>
                                <div >
                                    
                                    <label className="switch-btn btn btn-link ml-5" htmlFor="active" >
                                        <input defaultChecked={(editFormData.status === '1') ? true : false} className="switch-btn" id="active" value="open" name="active" type="checkbox" onClick={this.handleChange.bind(this,'status')}  />
                                        <div className="slider round"></div>
                                        <span className="switch-label"></span>
                                        {(editFormData.status === "1") ? this.props.t('formData.active'):this.props.t('formData.inactive')}
                                    </label>
                                </div>
                            </div>
                           :''}
                            <div className="row">
                                <div className=" col-sm-4 form-group text-center">
                            <button type="submit" onClick={this.submitForm} className="btn btn-primary">{this.props.t('formData.save')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslation('city')(EditCity);
