import React, { Component } from 'react';

import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import * as helper from '../../../config/helper';
import { toast } from 'react-toastify';

class BulkUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            loading:false,
            headers: [],
            rows: [],
            sampleFile:'',
            fileData:[],
            action: '',
            isPremiumField:false
        }
    }

    
    /**
     * Change Handler
     */
    onChangeHandler=event=>{
        
        this.setState({
            selectedFile: event.target.files[0]
          })
    
    }

    /**
     * On Submit filter
     */
    submitUpload = async () => {
        this.setState({loading: true});
        
        let postData = {};
        let fileName = (this.state.selectedFile) ? this.state.selectedFile.name : '';
        
        if(this.state.selectedFile && fileName && (/\.(csv|xls|xlsx)$/.test(fileName))){
            await helper.getBase64(this.state.selectedFile, async (res)=>{
            
                postData['myfile'] = res;
                postData['filename'] = this.state.selectedFile.name;
                postData['isPremium'] = this.state.isPremium || false;
                postData['action'] = this.state.action;
    
    
                // this.props.sendRequestDataToParent(postData);
                let searchResult = await this.props.actions.submitBulkUpload(postData);
                
                if(searchResult.status === 200){
                    if(searchResult['data']['imported']){
                        toast.success(searchResult.message);
                    }else{
                        toast.error(searchResult.message);
                    }
                    this.setState({loading: false});
                    this.fileInput.value = "";
                }
    
            });  
        }else{
            toast.error(this.props.t('blockingLead.please_select_file_to_upload'));
            this.setState({loading:false});
            this.fileInput.value = "";
        }
          
    }

    /**
     * On Input Change Handler
     */
    onInputChange = (name) => async e => {
        await this.setState({isPremium: !this.state.isPremium});
    }

    /**
     * Update State
     */
    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};

        updatedStateData['headers'] = (nextProps.bulkUploadData && nextProps.bulkUploadData.headers) || [];
        updatedStateData['rows'] = (nextProps.bulkUploadData && nextProps.bulkUploadData.rows) || [];
        updatedStateData['sampleFile'] = (nextProps.bulkUploadData && nextProps.bulkUploadData.sampleFile) || [];
        updatedStateData['action'] = (nextProps.bulkUploadData && nextProps.bulkUploadData.action) || [];
        updatedStateData['isPremiumField'] = (nextProps.bulkUploadData && nextProps.bulkUploadData.isPremiumField) || false;
        return updatedStateData;
    }



    render() {
        const { loading, headers, rows, sampleFile,isPremiumField } = this.state;

        return (
            <div className="container-fluid">
            <h1>{this.props.t('blockingLead.import_data')}</h1>
                <div className="col-md-8 col-md-offset-2 bulkUploadLeads">
                    <div className="row">
                        <div className=" clearfix  mrg-T20">
                            <div className="col-sm-3">
                                <label><strong>{this.props.t('blockingLead.select_file_to_upload')}</strong><span >*</span></label>
                            </div>
                                                
                            <div className="col-sm-4">
                                <div className=" form-group">                                                     
                                    <input type="file" id="file" name="myfile" onChange={this.onChangeHandler} ref={ref=> this.fileInput = ref}/>   
                                    <span ></span><div id="fileErr" ></div> 
                                </div>
                            </div>

                            {
                                isPremiumField
                                ?
                                <div className="col-sm-3 clearfix form-group">
                                        <input type="checkbox" name="isPemium" onChange={this.onInputChange('isPremium')} id="Gaadi" value="1" /><label><span></span>&nbsp;{ this.props.t('blockingLead.isPremium')}</label>
                                </div>
                                :
                                ''
                            }
                            

                            <div className="col-sm-3 clearfix form-group">
                                <span className="mrg-L40"><a href={sampleFile}>{this.props.t('blockingLead.download_sample_file')}</a></span>
                            </div>
                        </div>

                     

                                <div className="clearfix mrg-T20">
                                    <div className="col-sm-12">
                                        <label><strong>{this.props.t('blockingLead.required_format')}</strong><span>*</span></label>                                    
                                    </div>  
                                </div>            
                                
                                <div className="clearfix">
                                    <div className="col-sm-12 table-responsive">
                                        <table className="table table-bordered table-striped table-hover enquiry-table">
                                            <tbody>
                                                <tr>
                                                    {
                                                        headers && headers.map((el, key)=>{
                                                            return(<th key={key}>{this.props.t(el.name)}</th>);
                                                        })
                                                    }
                                                    
                                                    {/* <th>{this.props.t('blockingLead.customer_mobile')}</th> */}
                                                    {/* <th>{this.props.t('blockingLead.blocking_reason_code')}</th> */}
                                                </tr>
                                                <tr>
                                                    {
                                                        rows && rows.map((el, key)=>{
                                                            return(<td key={key} dangerouslySetInnerHTML={{__html: this.props.t(el.name)}}></td>);
                                                        })
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                <div className="clearfix">
                                    <div className="col-sm-8">
                                        <p><b>{this.props.t('blockingLead.note')} :</b><span className="note"> {this.props.t('blockingLead.csv_file_should_be_separated_by_tab')}.</span></p>
                                    </div>
                                </div>
                                
                                <div className="clearfix">
                                    <div className="col-sm-10"> 
                                        <div id="status"></div>
                                    </div>
                                </div>               
                                
                                <div className="clearfix mrg-T20 form-group">
                                    <div className="col-sm-3 text-right">
                                    </div>
                                    <div className="col-sm-4">
                                        {
                                            loading
                                            ?
                                            <button type="submit" className="btn btn-lg btn-primary btn- pull-right" disabled>{this.props.t('blockingLead.uploading')}</button>
                                            :
                                            <button type="submit" className="btn btn-lg btn-primary btn- pull-right" onClick={this.submitUpload}>{this.props.t('blockingLead.upload')}</button>
                                            
                                        }
                                    </div>
                                </div>
                            </div>	                                
                        </div>
                    </div>
        )
    }
}


const mapStateToProps = state => ({
    // getBlockedClosedLeads: state.lead.makeModelVersionList
})

const mapDispatchToProps = dispatch => {
    return {
            actions: {
                submitBulkUpload: bindActionCreators(
                    LeadActions.submitBulkUpload,
                    dispatch
                )
            }
        }
}

export default withTranslation('lead') (withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkUpload)));