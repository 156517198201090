import React, { Component } from 'react';

import { default as config } from '../../../config/config';

import { connect } from 'react-redux';
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import BulkUploadHelper from '../helpers/bulk-upload-helper';

class BlockingLeadBulkUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // selectedFile: null,
            loading:false,
            headers:[
                {name:'blockingLead.customer_name'},
                {name:'blockingLead.customer_mobile'},
                {name:'blockingLead.blocking_reason_code'}
            ],
            rows:[
                {name:'blockingLead.customer_name'},
                {name:'blockingLead.customer_mobile'},
                {name:'blockingLead.blocking_reason_code_details'}
            ],
            action: 'blockUploadBlockedLeads',
            isPremiumField: false
        }
    }


    render() {
        const { SAMPLE_LEAD_BLOCK_CSV } = config.API_URL;
        const { headers, rows, action, isPremiumField } = this.state;

        return(
        <BulkUploadHelper bulkUploadData={{headers: headers, rows: rows, sampleFile: SAMPLE_LEAD_BLOCK_CSV, action: action, isPremiumField: isPremiumField} }/>
        )

    }
}


const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
    return {
            actions: {
                submitBulkUpload: bindActionCreators(
                    LeadActions.submitBulkUpload,
                    dispatch
                )
            }
        }
}

export default withTranslation('lead') (withRouter(connect(mapStateToProps, mapDispatchToProps)(BlockingLeadBulkUpload)));